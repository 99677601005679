import Keycloak from 'keycloak-js';

const keycloak = Keycloak('/keycloak.json');

keycloak.saveTokensInLocalStorage = () => {
  localStorage.setItem('kc_token', keycloak.token);
  localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
};

keycloak.getTokensFromLocalStorage = () => {
  const token = localStorage.getItem('kc_token');
  const refreshToken = localStorage.getItem('kc_refreshToken');
  return { token, refreshToken };
};

keycloak.onAuthLogout = () => {
  sessionStorage.clear(); // pulisci la session storage per i tabs e future storicizzazioni appartenenti all'utente
};

export default keycloak;
