import React, { Component } from 'react';
import * as d3 from 'd3';
import { isEqual } from 'lodash';
import moment from 'moment';
import { lastValueUpdated, rateGreaterThanAggregation } from '../common';
import { numberToLocaleString } from 'app/utils/formatNumber';


class Gauge extends Component {
  constructor(props) {
    super(props);

    this.svgRef = React.createRef();

    this.degrees = 270;
  }


  componentDidMount() {
    this.create();
    this.updateSize();
  }

  componentDidUpdate(prevProps) {
    const { width, height, data, shouldUpdate } = this.props;

    if (shouldUpdate) {
      if (width !== prevProps.width || height !== prevProps.height) {
        this.updateSize();
      }

      if (width !== prevProps.width || height !== prevProps.height || !isEqual(data, prevProps.data) || shouldUpdate !== prevProps.shouldUpdate) {
        this.updateData();
      }
    }
  }

  valueToDegrees = (value) => {
    const data = this.props.data[0] || {};
    const { min, max } = data.properties;
    const range = max - min;
    return value / range * this.degrees - (min / range * this.degrees + (360 - this.degrees) / 2);
  }

  valueToRadians = (value) => {
    return this.valueToDegrees(value) * Math.PI / 180;
  }

  valueToPoint = (value, factor) => ({
    x: this.cx - this.radius * factor * Math.cos(this.valueToRadians(value)),
    y: this.cy - this.radius * factor * Math.sin(this.valueToRadians(value)),
  });


  drawBands = () => {
    const data = this.props.data[0] || {};
    const { color } = data;
    const { min, max } = data.properties;

    let bandsData;
    if (min < 0 && max > 0) {
      bandsData = [{ start: min, end: 0, color: '#ff6666' }, { start: 0, end: max, color: '#47E706' }];
    } else {
      bandsData = [{ start: min, end: max, color }];
    }

    const svg = d3.select(this.svgRef.current);

    const bands = svg.selectAll('.band').data(bandsData);

    bands.exit().remove();

    const enter = bands.enter()
      .append('path')
      .attr('class', 'band')
      .style('fill', d => d.color);

    bands.merge(enter)
      .attr('d', d3.arc()
        .startAngle(d => this.valueToRadians(d.start))
        .endAngle(d => this.valueToRadians(d.end))
        .innerRadius(0.75 * this.radius)
        .outerRadius(0.85 * this.radius))
      .attr('transform', `translate(${this.cx},${this.cy}) rotate(270)`)
      .style('fill', d => d.color);
  }


  drawTicks = (values) => {
    const svg = d3.select(this.svgRef.current)
    svg.selectAll('.tickline').remove();
    svg.selectAll('.ticktext').remove();
    values.forEach((value) => {
      const point1 = this.valueToPoint(value, 0.75);
      const point2 = this.valueToPoint(value, 0.85);

      svg.append('line')
        .attr('class', 'tickline')
        .attr('x1', point1.x)
        .attr('y1', point1.y)
        .attr('x2', point2.x)
        .attr('y2', point2.y)
        .style('stroke', '#fff')
        .style('stroke-width', '2px');

      const point = this.valueToPoint(value, 0.6);

      svg.append('text')
        .attr('class', 'ticktext')
        .attr('x', point.x)
        .attr('y', point.y)
        .attr('text-anchor', 'middle')
        .text(value)
        .style('font-size', this.fontSize + 'px')
        .style('fill', '#333')
        .style('stroke-width', '0px');
    });
  };

  drawPointer = () => {
    const svg = d3.select(this.svgRef.current)
    const pointer = svg.append('g')
      .attr('class', 'pointer')
      .style('fill', '#444');

    pointer.append('line')
      .attr('stroke-width', 2)
      .attr('stroke', '#333');

    pointer.append('circle')
      .attr('stroke', '#444');
  }

  create = () => {
    const { width, height } = this.props;

    const svg = d3.select(this.svgRef.current)
      .attr('width', width)
      .attr('height', height);

    this.drawBands();

    this.drawPointer();

    svg.append('text').attr('class', 'value').attr('text-anchor', 'middle');
    svg.append('text').attr('class', 'unit').attr('text-anchor', 'middle');
  }

  updateSize = () => {
    const { width, height } = this.props;
    const { min, max } = (this.props.data[0] || {}).properties;
    const size = Math.min(width / 2, height / 2);
    this.radius = size;
    this.cx = width / 2;
    this.cy = height / 2;
    this.fontSize = Math.round(size / 8);

    const svg = d3.select(this.svgRef.current)
      .attr('width', width)
      .attr('height', height);

    this.drawBands();

    const scale = d3.scaleLinear().domain([min, max]);
    this.drawTicks(scale.ticks(4));

    const pointer = svg.select('.pointer').attr('transform', `translate(${this.cx}, ${this.cy})`)
    pointer.select('line').attr('x1', this.radius * 0.1).attr('x2', -this.radius * 0.9);
    pointer.select('circle').attr('r', this.radius * 0.05);

    svg.select('text.value').attr('transform', `translate(${this.cx}, ${this.cy + this.radius - size / 4})`).attr('font-size', size / 4);
    svg.select('text.unit').attr('transform', `translate(${this.cx}, ${this.cy + this.radius - size / 1.7})`).attr('font-size', size / 6);
  }


  updateData = () => {
    const data = this.props.data[0] || { value: {} };
    const { widget } = this.props;
    const { period, variables } = widget;
    const { _unit, value, rate } = data;
    const { valueType, physicalQuantity } = data;
    const { interval, aggregation } = period;
    const decimalDigits = variables && variables[0] && variables[0].decimalDigits;
    const isBool = valueType === 'binary' || (physicalQuantity === 'state' || physicalQuantity === 'event');
    const greaterBtwRateAndAgg = rate ? rateGreaterThanAggregation(rate, aggregation) : aggregation;
    const oldValue = !isBool && lastValueUpdated(interval, greaterBtwRateAndAgg, value);

    const { v, t } = value;
    const localValue = numberToLocaleString(v,decimalDigits);

    const svg = d3.select(this.svgRef.current);

    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('background-color', '#fff')
      .style('box-shadow', '2px 2px 4px gray')
      .style('color', '#666')
      .style('font-weight', '600')
      .style('font-size', '0.8rem')
      .style('max-width', '20rem')
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));

    svg.select('.pointer line')
      .transition()
      .attr('transform', `rotate(${this.valueToDegrees(v) || 0})`);

    svg.select('text.value').text(v && (oldValue ? `*${localValue}` : `${localValue}`))
      .on('mouseover', () => tooltip.style('visibility', 'visible'))
      .on('mousemove', () => tooltip.style('top', (window.event.pageY - 40) + 'px').style('left', (window.event.pageX + 10) + 'px')
        .style('padding', '0.4rem')
        .text(`Valore del ${moment(t).format('DD/MM/YYYY, HH:mm:ss')}`))
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));
    svg.select('text.unit').text(_unit);
  }


  render() {
    return (
      <svg ref={this.svgRef} />
    );
  }
}

export default Gauge;
