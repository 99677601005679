/* eslint-disable import/prefer-default-export */
import api from 'api';
import logger from 'app/common/log';

async function createUpdateDeleteOPs(method, observedPropertyList, deviceId) {
  const opWorkingPromises = observedPropertyList.map(op => {
    const url =
      method === 'post'
        ? `/FieldDevices/${deviceId}/observedProperties`
        : `observedProperties/${op.id}`;
    return api[method](url, op).catch(err => {
      const errMsg = `Could not ${method} OP: ${JSON.stringify(op, null, 2)}, ${err}`;
      logger.error(errMsg);
      return { _err: errMsg };
    });
  });
  const outcomes = await Promise.all(opWorkingPromises);
  const _err = outcomes.filter(action => action._err).length > 0;
  const count = outcomes.filter(action => !action._err).length;
  return { count, _err };
}

export async function deleteOPs(observedPropertyList) {
  const opList = observedPropertyList.map(op => ({ ...op, fieldDeviceId: null }));
  const { count, _err } = await createUpdateDeleteOPs('patch', opList);
  return { count, _err };
}

export async function patchOPs(observedPropertyList) {
  const { count, _err } = await createUpdateDeleteOPs('patch', observedPropertyList);
  return { count, _err };
}

export async function createOPs(observedPropertyList, deviceId) {
  const { count, _err } = await createUpdateDeleteOPs('post', observedPropertyList, deviceId);
  return { count, _err };
}

export async function createDevice(gatewayId, device) {
  let result = { _err: null };
  try {
    const res = await api.post(`/FieldGateways/${gatewayId}/FieldDevices`, device);
    result = res && res.data;
  } catch (ex) {
    result._err = ex;
    result._err._message =
      (ex.response &&
        ex.response.data &&
        ex.response.data.error &&
        ex.response.data.error.message) ||
      ex;
  }
  return result;
}

export async function createSupportedDevice({ make, model, productCode, domainId }) {
  let result = { _err: null };
  try {
    
    const res = await api.post('/SupportedDevices', { make, model, productCode, domainId });
    result = res && res.data;
    
  } catch (ex) {
    result._err = ex;
    result._err._message =
      (ex.response &&
        ex.response.data &&
        ex.response.data.error &&
        ex.response.data.error.message) ||
      ex;
  }
  return result;
}

export async function updateDevice(device) {
  let result = { _err: null };
  try {
    const res = await api.patch(`/FieldDevices/${device.id}`, device);
    result = res && res.data;
  } catch (ex) {
    result._err = ex;
  }
  return result;
}

export async function upsertTemplate(template) {
  let result = { _err: null };
  result._err = template ? null : 'no template provided';
  if (template && !template.id && !template.supportedDeviceId) {
    result._err = `No supportedDeviceId provided to upsert template: ${JSON.stringify(
      template,
      null,
      2
    )}`;
  }
  if (!result._err) {
    try {
      const apiCall =
        template && template.id
          ? api.patch(`/DeviceTemplates/${template.id}`, template)
          : api.post(`/SupportedDevices/${template.supportedDeviceId}/DeviceTemplates`, template);
      const res = await apiCall;
      result = res && res.data;
    } catch (ex) {
      result._err = ex;
      result._err._message =
        (ex.response &&
          ex.response.data &&
          ex.response.data.error &&
          ex.response.data.error.message) ||
        ex;
    }
  }
  return result;
}
