import React, { useState, useEffect } from 'react';
import api from 'api';
import { connect } from 'react-redux';
import moment from 'moment';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { getPeriodReference, getReportsHistory } from 'app/pages/reports/ReportHelper';

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
`;

const ReportHeader = (props) => {
  const {
    user,
    widget,
    report
  } = props;

  const {
    content,
    image,
    documentId,
    showImage,
    showTitle,
    showDatepub,
    showPeriod,
    showCreator,
    note
  } = widget;

  const maxWidthHeight = {
    width: '100%',
    height: '100%',
  };

  const [periodReference, setPeriodReference] = useState();
  const [cont, setCont] = useState(content);

  useEffect(() => {
    if (documentId) {
      api.get(`documents/${documentId}/download`, { responseType: 'arraybuffer' }).then(res => {
        const { data, headers } = res;
        const arr = new Uint8Array(data);
        // Convert the int array to a binary string
        // We have to use apply() as we are converting an *array*
        // and String.fromCharCode() takes one or more single values, not
        // an array.
        const CHUNK_SZ = 0x8000;
        let raw = [];
        for (let i = 0; i < arr.length; i += CHUNK_SZ) {
          raw.push(String.fromCharCode.apply(null, arr.subarray(i, i + CHUNK_SZ)));
        }
        const b64 = btoa(raw.join(""));
        setCont(`data:${headers['content-type']};base64,${b64}`);
      }).catch(err => console.error(err));
    }
  }, [documentId])


  useEffect(() => {
    /**
     * Periodo riferimento rispetto alla data creazione primo documento nello storico
     */
    if (report && report.id) {
      getReportsHistory(report).then(res => {
        const rep = res.data[0]; // take the first (last in the list) record
        if (rep.otherMetadata && !isEmpty(rep.otherMetadata.reportTargetPeriod)) {
          const periodString = getPeriodReference(
            rep.created,
            rep.otherMetadata.reportTargetPeriod.reference,
            rep.otherMetadata.reportTargetPeriod.dateFrom
          );
          setPeriodReference(periodString);
        }
      }).catch(() => {
        // Unable to detect report history
      });
    }
  }, []);

  const getDefaultPeriodReference = () => {
    if (report.period || (report.configuration && report.configuration.period)) {
      const currentReportPeriod = (report.period) ? report.period : report.configuration.period.reference;
      const periodString = getPeriodReference(
        new Date(),
        currentReportPeriod,
        // dateFrom non percepibile da form
      );
      return (currentReportPeriod === 'from') ? i18n._(t`Dal`) + '#' : periodString;
    }
    return '-';
  }

  return (
    <div className="row m-0" style={maxWidthHeight}>
      <div className="col-8 pt-2" style={maxWidthHeight}>
        {showTitle && (
          <div>
            <h3>{(report && report.name) ? report.name : '[TITOLO]'}</h3>
          </div>
        )}

        {showDatepub && (
          <div>
            <strong><Trans>Data pubblicazione</Trans></strong>: { (report && report.created) ? moment(report.created).format('DD/MM/YYYY') : '[DATA]'}
          </div>
        )}

        {showPeriod && (
          <div>
            <strong><Trans>Periodo di competenza</Trans></strong>: { (periodReference) ? periodReference : getDefaultPeriodReference()}
          </div>
        )}

        {showCreator && (
          <div>
            <strong><Trans>Creato da</Trans>:</strong> { (report && report.updater) ? report.updater.name : user.name}
          </div>
        )}

        {note && (
          <div>{note}</div>
        )}
      </div>
      <div className="col-4" style={{ ...maxWidthHeight, textAlign: 'right', padding: 0 }}>
        {showImage && cont &&
          <Image src={cont} alt={image} />
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(ReportHeader);
