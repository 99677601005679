import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOnClickOutside from 'use-onclickoutside';

const InputColor = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${props => props.background};
  cursor: pointer;
  margin: 0.3rem;
  flex-shrink: 0;
  title: ${props => props.title};
`;

const Wrapper = styled.div`
  outline: none;
  width: auto;
  max-height: 10rem;
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CaretIcon = styled(FontAwesomeIcon)`
  margin-left: 0.3rem;
  transition: all 0.1s;

  ${props => props.dropdownOpen && 'transform: rotate(180deg);'}
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  && {
    overflow-y: auto;
    max-height: ${props => props.maxHeight};
    position: absolute;
    bottom: ${props => (props.directionUp ? '2.5rem' : 'auto')};
    width: 100%;
    z-index: 10;
    background-color: ${props => props.theme.common.background.primaryColor};
    color: ${props => props.theme.common.text.primaryColor};
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.navbar.borderColor};
    }
  }
`;

const DropdownItem = styled.button`
  && {
    display: block;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    ${props =>
      props.active &&
      `
      background-color: ${props.theme.common.background.secondaryColor};
    `};
  }
`;

const RoundSelect = props => {
  const { name, value, items, maxHeight, directionUp, className, onChange, disabled } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const componentRef = useRef(null);

  const openDropDown = () => {
    if(disabled){
      return;
    }
    setDropdownOpen(true);
    console.log('On focus!');
  };

  const closeDropDown = () => {
    setDropdownOpen(false);
  };

  useOnClickOutside(componentRef, () => {
    setDropdownOpen(false);
  });

  const selectItem = value => {
    console.log('new value ', value);
    onChange({ target: { name, value } });
    closeDropDown();
  };

  return (
    <Wrapper ref={componentRef} tabIndex="0" onFocus={openDropDown} className={className}>
      <Toggle>
        <InputColor
          background={items.find(item => item.value === value).color}
          title={items.find(item => item.value === value).text}
        />
        {!disabled && <CaretIcon dropdownOpen={dropdownOpen} icon="angle-down" />}
      </Toggle>
      {dropdownOpen && (
        <Dropdown maxHeight={maxHeight} directionUp={directionUp}>
          {items.map(item => (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <InputColor background={item.color} onClick={() => selectItem(item.value)} />
              <DropdownItem title={item.text} onClick={() => selectItem(item.value)}>
                {item.text}
              </DropdownItem>
            </div>
          ))}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default RoundSelect;
