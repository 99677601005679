import moment from 'moment';
import 'moment/locale/it';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const calcRollingDate = (p) => {
  if (p)
  {
    const { rollingDiff, rollingShift, rolling, rollingStartDate, rollingEndDate } = p;
    let { startDate, endDate } = p;
    if (rolling)
    {
      if (!rollingEndDate || rollingEndDate === 'dynamic') {
        endDate = moment().subtract(rollingShift, 'days').format(DATE_FORMAT);
      } else if (rollingEndDate === '2isoWeek') {
        endDate = moment().endOf('isoWeek').subtract(1, 'week').format(DATE_FORMAT);
      } else if (rollingEndDate === '2month') {
        endDate = moment().endOf('month').subtract(1, 'month').format(DATE_FORMAT);
      } else if (rollingEndDate === '2year') {
        endDate = moment().endOf('year').subtract(1, 'year').format(DATE_FORMAT);
      } else {
        endDate = moment().endOf(rollingEndDate).format(DATE_FORMAT);
      }

      startDate = (!rollingStartDate || rollingStartDate === 'dynamic') ? moment().subtract(rollingDiff + rollingShift, 'days').format(DATE_FORMAT) : moment(endDate, DATE_FORMAT).startOf(rollingStartDate).format(DATE_FORMAT);
    }
    return ({ startDate, endDate });
  }

  const startDate = moment().format(DATE_FORMAT);
  const endDate = moment().format(DATE_FORMAT);
  return ({ startDate, endDate });
};