import React, { useState, useEffect } from 'react';
import api from 'api';
import { CustomInput } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { DynamicForm } from 'app/common';
import i18n from 'app/i18n';

const Image = styled.img`
  max-width: 100%;
  max-height: 20rem;
  object-fit: ${props => props.fit || 'scale-down'};
`;

const ReportFooterForm = (props) => {
  const { onClose, onSave, selectedDomain, selectedCompany, selectedSite } = props;

  const [widget, setWidget] = useState(props.widget);
  const [error, setError] = useState();
  const [showImageField, setShowImageField] = useState();
  const [content, setContent] = useState();

  const edit = widget.key !== undefined;

  useEffect(() => {
    setShowImageField(widget.showImage);
    if (widget.documentId) {
      api.get(`documents/${widget.documentId}/download`, { responseType: 'arraybuffer' }).then(res => {
        const { data, headers } = res;
        const arr = new Uint8Array(data);
        // Convert the int array to a binary string
        // We have to use apply() as we are converting an *array*
        // and String.fromCharCode() takes one or more single values, not
        // an array.
        const CHUNK_SZ = 0x8000;
        let raw = [];
        for (let i = 0; i < arr.length; i += CHUNK_SZ) {
          raw.push(String.fromCharCode.apply(null, arr.subarray(i, i + CHUNK_SZ)));
        }
        const b64 = btoa(raw.join(""));
        setContent(`data:${headers['content-type']};base64,${b64}`);
      }).catch(err => console.error(err));
    }
  }, []);

  const submitWidget = (data) => {
    onSave({ ...widget, ...data });
    onClose();
  };

  const getPath = () => {
    if (selectedSite) {
      return `sites/${selectedSite.id}`;
    } else if (selectedCompany) {
      return `companies/${selectedCompany.id}`;
    }
    return `domains/${selectedDomain.id}`;
  }

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onChangeHandler = async (event) => {
    try {
      const { target } = event;
      const file = target.files[0];
      const { type } = file;

      if (type.split('/')[0] !== 'image') {
        setError({ message: i18n._(t`Selezionare un file di tipo immagine`) });
        return;
      }

      const cont = await readFileAsync(file);
      setContent(cont);
      if (cont.length > 5000000) {
        setError({ message: i18n._(t`File troppo grande. La dimensione dell'immagine non può eccedere i 5000kB complessivamente`) });
      } else {
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('tags', ['dashboard-image']);
        const path = getPath();
        const res = await api.post(`/${path}/documents`, formData);
        if (res.status === 200) {
          const { originalName, id } = res.data;
          setWidget({ ...widget, filename: originalName, documentId: id });
        }
        setError(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getImageField = () => {
    const fieldName = 'image';
    return {
      name: fieldName,
      type: 'custom',
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
      hidden: !showImageField,
      label: <Trans>Immagine</Trans>,
      render: () => <CustomInput type="file" id="fileBrowser" name="imageFile" accept="image/*" label={widget.filename || i18n._(t`Seleziona file`)} onChange={onChangeHandler} />,
    };
  };

  const getImagePreview = () => {
    const fieldName = 'content';

    if (showImageField && widget && content) {
      return {
        name: fieldName,
        type: 'custom',
        render: () => <Image src={content} alt={widget.filename} />,
      };
    }

    // Empty field cell
    return {
      name: fieldName,
      type: 'custom',
      hidden: true,
      render: () => <></>
    };
  };

  return (
    <DynamicForm
      card
      edit={edit}
      title={<Trans>Piè di pagina</Trans>}
      initialValues={widget}
      onSubmit={submitWidget}
      onCancel={onClose}
      error={error}
      fields={[
        {
          name: 'showPageNumber',
          type: 'checkbox',
          xs: 6,
          sm: 6,
          md: 6,
          lg: 6,
          label: <Trans>Mostra numero pagina</Trans>,
          onChange: (value, values) => {
            if (value === true) {
              values.showPageNumberTotal = true;
            }
          }
        },
        {
          name: 'showPageNumberTotal',
          type: 'checkbox',
          xs: 6,
          sm: 6,
          md: 6,
          lg: 6,
          label: <Trans>Mostra totale pagine</Trans>
        },
        {
          name: 'showCreator',
          type: 'checkbox',
          sm: 12,
          md: 6,
          lg: 6,
          label: <Trans>Mostra creatore report</Trans>
        },
        {
          name: 'emptyCell_1',
          type: 'custom',
          sm: 12,
          md: 6,
          lg: 6,
          render: () => <></>
        },
        {
          name: 'showImage',
          type: 'checkbox',
          label: <Trans>Mostra immagine</Trans>,
          sm: 2,
          md: 6,
          lg: 6,
          onChange: (value) => {
            setShowImageField(value);
          }
        },
        getImageField(),
        getImagePreview(),
        {
          name: 'note',
          type: 'textarea',
          label: <Trans>Note</Trans>,
        },
      ]}
    />
  );
}

export default ReportFooterForm;
