import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import TreeContext from './TreeContext';
import LeafNode from './LeafNode';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const Li = styled.li`
  margin-left: 1.5rem;
`;

const EmptyLabel = styled.li`
  padding: .2rem;
  margin-left: 1.5rem;
  color: ${props => props.theme.common.text.secondaryColor};
`;


const Item = styled.div`
  display: inline-block;
  padding: .2rem;
  cursor: pointer;

  &:hover {
    span.label {
      color: #666;
    }
    svg {
      color: #aaa;
    }
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  min-width: 1rem;
  color: #999;
  transform: rotate(-90deg);
  transition: all .1s;

  ${props => props.open && 'transform: rotate(0deg)'};
`;

const Label = styled.span`
  margin-left: .5rem;
`;

const StyledBadge = styled(Badge)`
  && {
    margin-left: .5rem;
    color: ${props => props.color || props.theme.common.buttons.primary.color};
    background-color: ${props => props.bgcolor || props.theme.common.buttons.primary.background};
    border-radius: 15px;
    min-width: 1.3rem;
  }
`;

const GreyStyledBadge = styled(Badge)`
  && {
    margin-left: .5rem;
    color: ${props => props.color || props.theme.common.buttons.secondary.color};
    background-color: ${props => props.bgcolor || props.theme.common.buttons.secondary.background};
    border-radius: 15px;
    min-width: 1.3rem;
  }
`;

const StyledLink = styled(Link)`
  padding-left: 0.5rem;
`


// const EmptyItem = styled.div`
//   padding: .2rem;
//   cursor: default;
// `;

// const SpinnerIcon = styled(FontAwesomeIcon)`
//   min-width: 1rem;
//   margin-left: 1.5rem;
//   cursor: default;
//   color: #666;
// `;

// const LoadingLabel = styled.span`
//   margin-left: .5rem;
//   cursor: default;
// `;

// const LoadingItem = () => {
//   return (
//     <EmptyItem>
//       <SpinnerIcon icon="spinner" spin />
//       <LoadingLabel>Caricamento...</LoadingLabel>
//     </EmptyItem>
//   );
// };


const TreeNode = ({ node }) => {
  const { leaves, totalLeaves, nLeaves, openNode, openNodes, checkedNodes, isAlarm, onLink } = useContext(TreeContext);

  const { key, type, id, name, children, showLeafChildren, severitiesCount, severityColor, rootCount } = node;
  const nodeChildren = children || [];


  const defaultOpen = nLeaves < 100;
  const { open } = (openNodes.find(x => x.type === type && x.id === id) || { open: defaultOpen });


  const compareItemName = (a, b) => {
    if (a.item.name.toLowerCase() < b.item.name.toLowerCase()) {
      return -1;
    }
    if (a.item.name.toLowerCase() > b.item.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };


  const handleClick = () => {
    openNode(node, !open);
  };


  const leafChildren = useMemo(() => {
    if (type === 'asset') {
      const { opIds } = node;
      const siteKey = key.slice(0, key.indexOf('/asset'));
      return (leaves[siteKey] || []).filter(x => opIds.includes(x.id) || x.item.assetId === id.toString());
    }
    if (type === 'assetgroup') {
      return (leaves[key] || []).filter(x => x.item.assetGroupId === id.toString());
    }
    return leaves[key] || [];
  }, [leaves, node]);

  const totalLeafChildren = useMemo(() => {
    if (type === 'asset') {
      const { opIds } = node;
      const siteKey = key.slice(0, key.indexOf('/asset'));
      return ((totalLeaves && totalLeaves[siteKey]) || []).filter(x => opIds.includes(x.id) || x.item.assetId === id.toString());
    }
    if (type === 'assetgroup') {
      return ((totalLeaves && totalLeaves[key]) || []).filter(x => x.item.assetGroupId === id.toString());
    }
    return leaves[key] || [];
  }, [totalLeaves, node]);

  // number of checked children to show in the badge
  // k === key || k.includes(key + "/")  either k is equal to the key, or k needs to have "/" after to avoid cases like k = "domain_1/company_48" and key "domain_1/company_4"
  const nCheckedChildren = useMemo(() => {
    if(totalLeaves){
      const descendats = Object.entries(totalLeaves).filter(([k]) => k === key || k.includes(key + "/")).map(([, list]) => list).flat();
      return descendats.filter(x => checkedNodes.findIndex(y =>  y && x && y.id === x.id) >= 0).length;
    }else{
      const descendats = Object.entries(leaves).filter(([k]) => k === key || k.includes(key + "/")).map(([, list]) => list).flat();
      return descendats.filter(x => checkedNodes.findIndex(y =>  y && x && y.id === x.id) >= 0).length;
    }
  }, [totalLeaves, leaves, checkedNodes]);

  const nTotalChildren = useMemo(() => {
    if(totalLeaves){
      return Object.entries(totalLeaves).filter(([k]) => k === key || k.includes(key + "/")).map(([, list]) => list).flat().length;
    }else{
      return Object.entries(leaves).filter(([k]) => k === key || k.includes(key + "/")).map(([, list]) => list).flat().length;
    }
   
  }, [totalLeaves, leaves]);

  const nChildren = useMemo(() => {
    return Object.entries(leaves).filter(([k]) => k === key || k.includes(key + "/")).map(([, list]) => list).flat().length;
  }, [leaves]);

  let visible = false;
  if (type === 'asset') {
    visible = totalLeafChildren.length > 0;
  } else {
    if(totalLeaves){
      if(Object.keys(totalLeaves).some(x => x.includes(key))){
        visible = true;
      }
    }else{
      if(Object.keys(leaves).some(x => x.includes(key))){
        visible = true;
      };
    }
  }

  if (!visible) {
    return null;
  }

  const getType = _type => {
    let type = _type
    switch (_type) {
      case 'domain':
        type = 'domains';
        break;
      case 'company':
        type = 'companies';
        break;
      case 'sitegroup':
        type = 'sitegroups';
        break;
      case 'site':
        type = 'sites';
        break;
      default:
        break;
    }
    return type;
  }


  return (isAlarm ?
    (severitiesCount || rootCount > 0) && <Li>
      <Item onClick={handleClick}>
        {nodeChildren.length > 0 && <ArrowIcon open={open} icon="chevron-circle-down" />}
        <StyledLink to={{
          pathname: `/${getType(type)}/${id}/alarms/log`,
          state: {
            fromPopover: true
          }
      }}
      onClick={() => onLink()}
      >{name}</StyledLink>
        {(open || type === 'site' || type === 'sitegroup') && severitiesCount &&
          severitiesCount.map((severityObj, i) => {
            return <StyledBadge
              key={i}
              bgcolor={severityColor ? severityColor.bgcolor : severityObj[Object.keys(severityObj)[0]].bgcolor}
              color={severityColor && severityColor.color ? severityColor.color : severityObj[Object.keys(severityObj)[0]].color} >
              {severityObj[Object.keys(severityObj)[0]].count}
            </StyledBadge>
          })
        }
        {!open && type !== 'site' && type !== 'sitegroup' && rootCount > 0 && <StyledBadge
          bgcolor={severityColor ? severityColor.bgcolor : '#BF5298DD'}
          color={severityColor && severityColor.color} >
          {rootCount < 100 ? rootCount : '99+'}
        </StyledBadge>
        }
      </Item>
      {open && (
        <Ul>
          {nodeChildren.map((child, index) => <TreeNode key={child.key || index} node={child} />)}
          {showLeafChildren && leafChildren.sort(compareItemName).map((child, index) => <LeafNode key={child.key || index} node={child} />)}
        </Ul>
      )}
    </Li>
    :
    <Li>
      <Item onClick={handleClick}>
        <ArrowIcon open={open} icon="chevron-circle-down" />
        <Label className="label">{name}</Label>
        <GreyStyledBadge title={i18n._(t`Visibili / Totali`)}>{nChildren} / {nTotalChildren}</GreyStyledBadge>
        {nCheckedChildren > 0 && <StyledBadge title={i18n._(t`Selezionati`)}>{nCheckedChildren}</StyledBadge>}        
      </Item>
      {open && (
        <Ul>
          {nodeChildren.map((child, index) => <TreeNode key={child.key || index} node={child} />)}
          {showLeafChildren && leafChildren.sort(compareItemName).map((child, index) => <LeafNode key={child.key || index} node={child} />)}
        </Ul>
      )}
    </Li>
  );
};

export default TreeNode;
