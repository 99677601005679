import React, { Component } from 'react';
import { DynamicForm, Select } from 'app/common';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import VectorBlock from './VectorBlock';

const SelectAddon = styled(Select)`
  && {
    display: inline-block;
    width: 8rem;
  }
`;

const getScaledValues = (initialValues, unit) => {
  const ret = {};
  if (initialValues.avgPeriodicCost) {
    ret.avgPeriodicCost = initialValues.avgPeriodicCost * initialValues.avgPeriodicCostFactor;
  }
  const { exactoConversionFactor } = unit;
  if (initialValues.consThreshold1) {
    ret.consThreshold1 = initialValues.consThreshold1 / exactoConversionFactor;
  }
  if (initialValues.consThreshold2) {
    ret.consThreshold2 = initialValues.consThreshold2 / exactoConversionFactor;
  }
  return ret;
};

class Vector extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialValues !== undefined && prevState.initialValues === undefined) {
      const avgPeriodicCostFactor = nextProps.initialValues.avgPeriodicCostFactor || 1;
      let initialValues = { ...nextProps.initialValues };
      if (nextProps.initialValues.avgPeriodicCostFactor) {
        const scaledValues = getScaledValues(nextProps.initialValues, nextProps.vector._defaultUnit);
        initialValues = { ...initialValues, ...scaledValues };
      }
      return { initialValues, additionalData: { avgPeriodicCostFactor } };
    }
    return null;
  }


  getValues = () => {
    const v = this.form.current.getValues();
    return v;
  };

  validateAndGetValues = async () => {
    const { vector } = this.props;
    const { exactoConversionFactor } = vector._defaultUnit;
    const { additionalData } = this.state;
    const { avgPeriodicCostFactor } = additionalData;
    const v = await this.form.current.validateAndGetValues();
    if (v.avgPeriodicCost) {
      v.avgPeriodicCost /= avgPeriodicCostFactor;
    }
    if (v.consThreshold1) {
      v.consThreshold1 *= exactoConversionFactor;
    }
    if (v.consThreshold2) {
      v.consThreshold2 *= exactoConversionFactor;
    }
    if (v.systemCharges) {
      v.systemCharges = +v.systemCharges;
    }
    return { vNK: vector.naturalKey, ...v, avgPeriodicCostFactor };
  };

  updateAdditionalData = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState(prevState => ({ additionalData: { ...prevState.additionalData, [name]: value } }));
  };

  getFields = () => {
    const { vector, costOptions } = this.props;
    const { additionalData } = this.state;
    const { _defaultUnit } = vector;

    if (vector.naturalKey === 'energia-elettrica') {
      return [
        {
          name: 'avgPeriodicCost',
          type: 'text',
          label: <Trans>Costo periodico medio (energia elettrica)</Trans>,
          validation: { number: true, minVal: 0 },
          md: 6,
          addon: <SelectAddon className="input-group-text" name="avgPeriodicCostFactor" value={additionalData.avgPeriodicCostFactor} options={costOptions} onChange={this.updateAdditionalData} />,
        },
        {
          name: 'avgUnitCost',
          type: 'text',
          label: <Trans>Costo unitario medio (energia elettrica)</Trans>,
          validation: { number: true, minVal: 0 },
          md: 6,
          addon: `€/${_defaultUnit.symbol}`,
        },
        {
          name: 'systemCharges',
          type: 'text',
          label: <Trans>Oneri di sistema (energia elettrica)</Trans>,
          validation: { number: true, minVal: 0 },
          md: 6,
          addon: `€/${_defaultUnit.symbol}`,
        },
      ];
    }

    return [
      {
        name: 'avgPeriodicCost',
        type: 'text',
        label: <Trans>Costo periodico medio (combustibile)</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: <SelectAddon className="input-group-text" name="avgPeriodicCostFactor" value={additionalData.avgPeriodicCostFactor} options={costOptions} onChange={this.updateAdditionalData} />,
      },
      {
        name: 'avgUnitCost',
        type: 'text',
        label: <Trans>Costo unitario medio (combustibile)</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: `€/${_defaultUnit.symbol}`,
      },
      {
        name: 'consThreshold1',
        type: 'text',
        label: <Trans>1a soglia di consumo (ingresso scaglione 2)</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: _defaultUnit.symbol,
      },
      {
        name: 'thresh1ExceedPenalty',
        type: 'text',
        label: <Trans>Penalità per consumo in scaglione 2</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: `€/${_defaultUnit.symbol}`,
      },
      {
        name: 'consThreshold2',
        type: 'text',
        label: <Trans>2a soglia di consumo (ingresso scaglione 3)</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: _defaultUnit.symbol,
      },
      {
        name: 'thresh2ExceedPenalty',
        type: 'text',
        label: <Trans>Penalità per consumo in scaglione 3</Trans>,
        validation: { number: true, minVal: 0 },
        md: 6,
        addon: `€/${_defaultUnit.symbol}`,
      },
    ];
  }


  render() {
    const { vector } = this.props;
    const { initialValues } = this.state;

    return (
      <VectorBlock vector={vector}>
        <DynamicForm
          ref={this.form}
          hideButtons
          initialValues={initialValues}
          fields={this.getFields()}
        />
      </VectorBlock>
    );
  }
}

export default Vector;
