import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { DynamicForm, Button } from 'app/common';
import { Trans, t } from '@lingui/macro';
import i18n from 'app/i18n';
import { getTranslatedString } from '../TranslateData';

const SLabel = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.theme.common.text.headerColor};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Divider = styled.div`
  clear: both;
  border-bottom: 1px solid #ccc;
  width: 98%;
  margin-left: 1%;
  margin-bottom: 12px;
`;

class NodeEdit2 extends React.Component {
  state = {
    groups: [],
    assetGroups: [],
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { assetGroups, assets } = this.props;
    this.setState({
      groups: assetGroups,
      assets,
    });
  }

  save = (parameters) => {
    const { productDetailSave, nodeDataArray, selectedNodes, isComplexGraph, linkDataArray } = this.props;
    const node = nodeDataArray.find(node => node.key === selectedNodes);
    const { staticParameters } = node.item;
    const { otherStaticParameters } = node.item;
    const { model, inventory, make, name, qualifier } = parameters;

    let groupsList = [];
    if (!isComplexGraph) {
      ({ groupsList } = parameters);
    }
    let cnt = 0;
    const technicalData = {};
    staticParameters.forEach((staticPar) => {
      if (staticPar.group !== undefined || staticPar.perFlow !== undefined) {
        linkDataArray.forEach((flow) => {
          let flowOK = false;
          if (flow.vectorItem.category === staticPar.perFlow.category) {
            flowOK = (staticPar.perFlow.direction === "in" && flow.to === node.key) || (staticPar.perFlow.direction === "out" && flow.from === node.key)
          }
          if (flowOK) {
            let grpCnt = 0;
            staticPar.group.forEach((elem) => {
              let name = `par_${cnt}_${grpCnt}_${flow.id}`;
              if (!technicalData[staticPar.name] || typeof technicalData[staticPar.name] !== "object") {
                technicalData[staticPar.name] = {};
              }
              if (!technicalData[staticPar.name][elem.name] || typeof technicalData[staticPar.name][elem.name] !== "object") {
                technicalData[staticPar.name][elem.name] = {};
              }
              technicalData[staticPar.name][elem.name][flow.id] = parameters[name];
              grpCnt += 1;
            });
            cnt += 1;
          }
        })
      } else {
        technicalData[staticPar.name] = parameters[`par_${cnt}`];
        cnt += 1;
      }
    });
    if (otherStaticParameters && otherStaticParameters.length > 0) {
      otherStaticParameters.forEach((staticPar) => {
        if (staticPar.group !== undefined || staticPar.perFlow !== undefined) {
          linkDataArray.forEach((flow) => {
            let flowOK = false;
            if (flow.vectorItem.category === staticPar.perFlow.category) {
              flowOK = (staticPar.perFlow.direction === "in" && flow.to === node.key) || (staticPar.perFlow.direction === "out" && flow.from === node.key)
            }
            if (flowOK) {
              let grpCnt = 0;
              staticPar.group.forEach((elem) => {
                let name = `par_${cnt}_${grpCnt}_${flow.id}`;
                if (!technicalData[staticPar.name] || typeof technicalData[staticPar.name] !== "object") {
                  technicalData[staticPar.name] = {};
                }
                if (!technicalData[staticPar.name][elem.name] || typeof technicalData[staticPar.name][elem.name] !== "object") {
                  technicalData[staticPar.name][elem.name] = {};
                }
                technicalData[staticPar.name][elem.name][flow.id] = parameters[name];
                grpCnt += 1;
              });
              cnt += 1;
            }
          })
        } else {
          technicalData[staticPar.name] = parameters[`par_${cnt}`];
          cnt += 1;
        }
      });
    }

    const technicalData2 = {};

    let otherNodeKey = null;
    if (node.addedTo !== undefined) {
      otherNodeKey = node.addedTo;
    }
    if (node.addon !== undefined) {
      otherNodeKey = node.addon;
    }
    if (otherNodeKey !== null) {
      const node2 = nodeDataArray.find(node => node.key === otherNodeKey);
      const staticParameters2 = node2.item.staticParameters;
      staticParameters2.forEach((staticPar) => {
        technicalData2[staticPar.name] = parameters[`par_${cnt}`];
        cnt += 1;
      });
    }
    productDetailSave(model, inventory, make, name, qualifier, groupsList, technicalData, technicalData2);
  }

  changedGroups = (data) => {
    this.setState({
      assetGroups: data.target.value,
    });
  }

  getMeasureUnitFromUnitNaturalKey = (unitNaturalKey) => {
    const { catalogs } = this.props;
    const { units } = catalogs;
    const unit = units.find(un => un.naturalKey === unitNaturalKey);
    return unit ? unit.symbol : null;
  }

  getMeasureUnitFromPhysicalQuantity = (phdNaturalKey) => {
    const { catalogs } = this.props;
    const { physicalQuantities, units } = catalogs;
    const physicalQuantity = physicalQuantities.find(pq => pq.naturalKey === phdNaturalKey);
    if (physicalQuantity == null) {
      return null;
    }
    const phunit = physicalQuantity.defaultVisualizationUnit ? physicalQuantity.defaultVisualizationUnit : units[0];
    const unit = units.find(un => un.naturalKey === phunit);
    if (unit == null) {
      return physicalQuantity.units[0] ? this.getMeasureUnitFromUnitNaturalKey(physicalQuantity.units[0]) : null;
    }
    return unit.symbol;
  }

  addStaticValue = (staticPar, cnt, technicalData, initialValues, param, grpCnt, flowId, parentName) => {
    let data = null;
    if (grpCnt === undefined) {
      if (staticPar.default) {
        initialValues[`par_${cnt}`] = staticPar.default;
      }
      if (technicalData[staticPar.name]) {
        initialValues[`par_${cnt}`] = technicalData[staticPar.name];
      }
      data = {
        name: `par_${cnt}`,
        label: getTranslatedString('staticParameters', staticPar.name),
        type: 'text',
        hidden: (values) => {
          let hidden = false;
          if (staticPar.if) {
            Object.keys(staticPar.if).forEach(k => {
              if (staticPar.if[k].filter(val => Object.values(values).includes(val)).length < 1) {
                hidden = true;
              }
            });
          }
          return hidden;
        }
      };
    } else {
      let name = `par_${cnt}_${grpCnt}_${flowId}`;
      if (technicalData[parentName] && typeof technicalData[parentName] === "object" && technicalData[parentName][staticPar.name] && typeof technicalData[parentName][staticPar.name] === "object" && technicalData[parentName][staticPar.name][flowId]) {
        initialValues[name] = technicalData[parentName][staticPar.name][flowId];
      }
      data = {
        name: name,
        label: getTranslatedString('staticParameters', staticPar.name),
        type: 'text'
      };
    }

    let validation = {};
    let hasValidation = false;
    if (staticPar.type === 'float') {
      hasValidation = true;
      validation = { number: true };
    } else if (staticPar.type === 'integer' || staticPar.type === 'int') {
      hasValidation = true;
      validation = { integer: true };
    } else if (staticPar.type === 'select' || staticPar.type === 'multiselect') {
      const items = [];
      const { values } = staticPar;
      values.forEach((value) => {
        const label = getTranslatedString('values', value, staticPar.name);
        if (!isNaN(value)) {
          value = value === parseInt(value) ? parseInt(value) : parseFloat(value);
        }
        items.push({
          value,
          label
        });
      });
      data.type = staticPar.type === 'select' ? 'select' : 'tags';
      data.options = items;
      data.valueProperty = 'value';
      data.labelProperty = 'label';
    }

    if (staticPar.required) {
      hasValidation = true;
      validation.required = true;
    }
    if (staticPar.validation) {
      staticPar.validation.forEach((val) => {
        const keys = Object.keys(val);
        if (keys[0]) {
          hasValidation = true;
          validation[keys[0]] = val[keys[0]];
        }
      });
    }
    if (hasValidation) {
      data.validation = validation;
      data.validationOptions = { validateOnBlur: true };
    }
    let um = false;

    if (staticPar.physicalQUantityNK) {
      if (staticPar.physicalQUantityNK === 'custom') {
        const { selectedNodes, graphData, catalogs } = this.props;
        const { assets, assetFlows } = graphData;
        const { vectors } = catalogs;
        let combustibile = "?";
        const currentAsset = assets.find(ass => ass.id === selectedNodes);
        if (currentAsset && currentAsset.inFlowIds) {
          for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
            const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
            if (flow) {
              const vector = vectors.find(vec => vec.naturalKey === flow.vector);
              if ((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili") {
                const naturalkey = vector.variables && vector.variables[0] && vector.variables[0];
                if (naturalkey) {
                  combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                }
              }
            }
          }
        }
        um = staticPar.customUnit.replace("{combUM}", combustibile);
      } else {
        um = staticPar.unitNK !== undefined ? this.getMeasureUnitFromUnitNaturalKey(staticPar.unitNK) : this.getMeasureUnitFromPhysicalQuantity(staticPar.physicalQUantityNK);
      }

      if (um) {
        data.addon = um;
      }
    }
    param.push(data);
    return {
      param,
      initialValues
    };
  }

  addOtherStaticValue = (staticPar, cnt, technicalData, initialValues, param, grpCnt, flowId, parentName) => {
    let data = null;
    if (grpCnt === undefined) {
      if (staticPar.default) {
        initialValues[`par_${cnt}`] = staticPar.default;
      }
      if (technicalData && technicalData[staticPar.name]) {
        initialValues[`par_${cnt}`] = technicalData[staticPar.name];
      }
      data = {
        name: `par_${cnt}`,
        label: getTranslatedString('otherStaticParameters', staticPar.name),
        type: 'text',
      };
    } else {
      let name = `par_${cnt}_${grpCnt}_${flowId}`;
      if (technicalData && technicalData[parentName] && typeof technicalData[parentName] === "object" && technicalData[parentName][staticPar.name] && typeof technicalData[parentName][staticPar.name] === "object" && technicalData[parentName][staticPar.name][flowId]) {
        initialValues[name] = technicalData[parentName][staticPar.name][flowId];
      }
      data = {
        name: name,
        label: getTranslatedString('otherStaticParameters', staticPar.name),
        type: 'text',
      };
    }

    let validation = {};
    let hasValidation = false;
    if (staticPar.type === 'float' || staticPar.type === 'integer' || staticPar.type === 'int') {
      hasValidation = true;
      validation = staticPar.type === 'float' ? { number: true } : { integer: true };
    } else if (staticPar.type === 'select' || staticPar.type === 'multiselect') {
      const items = [];
      const { values } = staticPar;
      values.forEach((value) => {
        const label = getTranslatedString('values', value, staticPar.name)
        if (!isNaN(value)) {
          value = value === parseInt(value) ? parseInt(value) : parseFloat(value);
        }
        items.push({
          value,
          label
        });
      });
      data.type = staticPar.type === 'select' ? 'select' : 'tags';
      data.options = items;
      data.valueProperty = 'value';
      data.labelProperty = 'label';
    }

    if (staticPar.required) {
      hasValidation = true;
      validation.required = true;
    }
    if (staticPar.validation) {
      staticPar.validation.forEach((val) => {
        const keys = Object.keys(val);
        if (keys[0]) {
          hasValidation = true;
          validation[keys[0]] = val[keys[0]];
        }
      });
    }

    if (hasValidation) {
      data.validation = validation;
      data.validationOptions = { validateOnBlur: true };
    }
    if (staticPar.physicalQUantityNK) {
      let um = false;
      if (staticPar.physicalQUantityNK === 'custom') {
        const { selectedNodes, graphData, catalogs } = this.props;
        const { assets, assetFlows } = graphData;
        const { vectors } = catalogs;
        let combustibile = "?";
        const currentAsset = assets.find(ass => ass.id === selectedNodes);
        if (currentAsset && currentAsset.inFlowIds) {
          for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
            const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
            if (flow) {
              const vector = vectors.find(vec => vec.naturalKey === flow.vector);
              if ((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili") {
                const naturalkey = vector.variables && vector.variables[0] ? vector.variables[0] : false;
                if (naturalkey) {
                  combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                }
              }
            }
          }
        }
        um = staticPar.customUnit.replace("{combUM}", combustibile);
      } else {
        um = staticPar.unitNK !== undefined ? this.getMeasureUnitFromUnitNaturalKey(staticPar.unitNK) : this.getMeasureUnitFromPhysicalQuantity(staticPar.physicalQUantityNK);
      }
      if (um) {
        data.addon = um;
      }
    }
    param.push(data);
    return {
      param,
      initialValues,
    };
  }

  getStaticField = () => {
    const { nodeDataArray, selectedNodes, linkDataArray } = this.props;
    const node = nodeDataArray.find(node => node.key === selectedNodes);

    let { staticParameters, otherStaticParameters } = node.item;

    let technicalData = node.property !== undefined && node.property.technicalData !== undefined ? node.property.technicalData : {};
    let otherNodeKey = null;
    if (node.addedTo !== undefined) {
      otherNodeKey = node.addedTo;
    }
    if (node.addon !== undefined) {
      otherNodeKey = node.addon;
    }

    if (otherNodeKey !== null) {
      const node2 = nodeDataArray.find(node => node.key === otherNodeKey);
      const technicalData2 = node2.property !== undefined && node2.property.technicalData !== undefined ? node2.property.technicalData : {};
      const staticParameters2 = node2.item.staticParameters;
      const otherStaticParameters2 = node2.item.otherStaticParameters;
      staticParameters = staticParameters.concat(staticParameters2);
      otherStaticParameters = otherStaticParameters && otherStaticParameters != null ? otherStaticParameters.concat(otherStaticParameters2) : otherStaticParameters2;

      technicalData = Object.assign({}, technicalData, technicalData2);
    }
    let param = [];
    let initialValues = {};
    let cnt = 0;
    let blockCnt = 0;
    staticParameters.forEach((staticPar) => {
      if (staticPar.group !== undefined || staticPar.perFlow !== undefined) {
        linkDataArray.forEach((flow) => {
          let flowOK = false;
          if (flow.vectorItem.category === staticPar.perFlow.category) {
            flowOK = (staticPar.perFlow.direction === "in" && flow.to === node.key) || (staticPar.perFlow.direction === "out" && flow.from === node.key)
          }
          if (flowOK) {
            const data = {
              name: `grp${cnt}`,
              label: getTranslatedString('staticParameters', staticPar.name),
              flowName: flow.vectorItem.name,
              flowColor: flow.color
            };
            param.push(data);
            let grpCnt = 0;
            staticPar.group.forEach((elem) => {
              const ret = this.addStaticValue(elem, cnt, technicalData, initialValues, param, grpCnt, flow.id, staticPar.name);
              param = ret.param;
              initialValues = ret.initialValues;
              grpCnt += 1;
            });
            cnt += 1;
          }
        })
      }
      else if (staticPar.block) {
        const label = getTranslatedString('staticParameters', staticPar.block);
        const index = param.findIndex(p => p.label === label);
        if (index === -1) {
          const data = {
            name: `block${blockCnt}`,
            label: label
          };
          blockCnt += 1;
          param.push(data);
        }
        const ret = this.addStaticValue(staticPar, cnt, technicalData, initialValues, param, undefined, undefined, staticPar.name, blockCnt);
        cnt += 1;
        param = ret.param;
        initialValues = ret.initialValues;
      }

      else {
        const ret = this.addStaticValue(staticPar, cnt, technicalData, initialValues, param);
        cnt += 1;
        param = ret.param;
        initialValues = ret.initialValues;
      }
    });

    if (otherStaticParameters && otherStaticParameters != null && otherStaticParameters.length > 0) {
      const data = {
        name: `other`,
        label: ''
      };

      param.push(data);
      otherStaticParameters.forEach((staticPar) => {
        if (staticPar.group !== undefined || staticPar.perFlow !== undefined) {
          linkDataArray.forEach((flow) => {
            let flowOK = false;
            if (flow.vectorItem.category === staticPar.perFlow.category) {
              flowOK = (staticPar.perFlow.direction === "in" && flow.to === node.key) || (staticPar.perFlow.direction === "out" && flow.from === node.key)
            }
            if (flowOK) {
              const data = {
                name: `grp${cnt}`,
                label: getTranslatedString('otherStaticParameters', staticPar.name),
                flowName: flow.vectorItem.name,
                flowColor: flow.color,
              };

              param.push(data);
              let grpCnt = 0;
              staticPar.group.forEach((elem) => {
                let ret = this.addOtherStaticValue(elem, cnt, technicalData, initialValues, param, grpCnt, flow.id, staticPar.name);
                grpCnt += 1;
                param = ret.param;
                initialValues = ret.initialValues;
              });
              cnt += 1;
            }
          })
        } else {
          const ret = this.addOtherStaticValue(staticPar, cnt, technicalData, initialValues, param);
          cnt += 1;
          param = ret.param;
          initialValues = ret.initialValues;
        }
      });
    }
    const retData = {
      fields: param,
      initialValues
    };

    return retData;
  }

  checkError = () => {
    const { editError, clearError } = this.props;
    if (editError === null || editError === '') {
      return true;
    }
    const lastError = editError;
    clearError();
    return new Error(lastError);
  }

  nuovoGruppo = (name) => {
    if (name === '') {
      return;
    }
    const description = '';
    const { graphData, selectedNodes, nodeDataArray, onPatch } = this.props;
    const { assetGroups, assets } = graphData;
    graphData.assetGroupIdCounter++;
    let groupId = graphData.assetGroupIdCounter;
    const node = nodeDataArray.find(nd => nd.key === selectedNodes);
    const group = {
      name,
      description,
      assetIds: [],
      id: parseInt(groupId, 10),
    };

    const groupkey = graphData.assetGroups.findIndex(grp => grp.id === parseInt(groupId, 10));
    if (groupkey === -1) {
      graphData.assetGroups.push(group);
    } else {
      graphData.assetGroups[groupkey] = group;
    }
    const { groups } = this.state;
    const assetIndex = assets.findIndex(ass => ass.id === node.key);
    assets[assetIndex].groupIds.push(groupId);
    onPatch(graphData);
    this.setState({ assetGroups, groups, assets });
    //addItem('groupsList', group);
  }

  render() {
    const { nodeDataArray, selectedNodes, editError, parentGraphId, isComplexGraph } = this.props;
    const { assets, groups } = this.state;
    if (editError !== '' && editError !== null) {
      this.formRef.current.checkFormIsValid();
    }

    const node = nodeDataArray.find(nd => nd.key === selectedNodes);
    const make = node.property !== undefined && node.property.make !== undefined ? node.property.make : '';
    const inventory = node.property !== undefined && node.property.inventory !== undefined ? node.property.inventory : '';
    const model = node.property !== undefined && node.property.model !== undefined ? node.property.model : '';
    const { name } = node;

    let { fields, initialValues } = this.getStaticField();
    initialValues.name = name;
    initialValues.make = make;
    initialValues.model = model;
    initialValues.inventory = inventory;
    initialValues.groupsList = [];
    if (assets) {
      const asset = assets.find(ass => ass.id === node.key);
      if (asset && asset.groupIds) {
        const { groupIds } = asset;
        groupIds.forEach((id) => {
          const group = groups.find(gr => gr.id === id);
          initialValues.groupsList.push(group);
        });
      }
    }

    const primaryFields = [
      {
        name: 'name',
        label: i18n._(t`Nome`),
        type: 'text',
        validation: { required: true, func: this.checkError },
        validationOptions: { validateOnBlur: true },
      },
      {
        name: 'make',
        label: i18n._(t`Produttore`),
        type: 'text',
      },
      {
        name: 'model',
        label: i18n._(t`Modello`),
        type: 'text',
      },
      {
        name: 'inventory',
        label: i18n._(t`Numero di inventario`),
        type: 'text',
      },
    ];

    if (!isComplexGraph) {
      primaryFields.push({
        type: 'tags',
        label: i18n._(t`Gruppi`),
        name: 'groupsList',
        options: groups,
        keyProperty: 'id',
        labelProperty: 'name',
        col: 8,
        showCreate: i18n._(t`Crea un nuovo gruppo di assets`),
        onCreate: this.nuovoGruppo,
        props: { minCharToShowCreate: 2 },
      });
    }

    fields = primaryFields.concat(fields);
    let nodeId = JSON.parse(JSON.stringify(node.key));

    let nodeName = getTranslatedString('asset', JSON.parse(JSON.stringify(node.item.name)));
    if (node.addedTo !== undefined) {
      const node2 = nodeDataArray.find(nd => nd.key === node.addedTo);
      nodeId = JSON.parse(JSON.stringify(node2.key));
      nodeName = JSON.parse(JSON.stringify(node2.item.name));
    }
    if (parentGraphId) {
      nodeId = `${parentGraphId}-${node.key}`;
    }
    if (node.qualifier) {
      const field = {
        name: 'qualifier',
        label: i18n._(t`Nome specifico`),
        type: 'text',
        validation: { required: true },
        validationOptions: { validateOnBlur: true },
      }
      fields.splice(1, 0, field);
      fields[5].col = 12;
      initialValues.qualifier = node.qualifier;
    }
    const { onUndoDoubleClick } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Bold>id:&nbsp;</Bold>
            {nodeId}
            <br />
            <Bold>Tipo:&nbsp;</Bold>
            {nodeName}
          </Col>
        </Row>
        <SLabel><Trans>Dati generali</Trans></SLabel>
        <DynamicForm
          ref={this.formRef}
          hideButtons
          fields={fields}
          initialValues={initialValues}
          onSubmit={this.save}
          layout={(listaFields) => {
            const fieldsArray = Object.entries(listaFields);
            const htmlFields = [];
            fieldsArray.forEach((val, key) => {
              let colw = 4;
              if (fields[key].type === 'tags') {
                colw = val[0] === 'groupsList' ? fields[key].col : 12;
              }
              if (val[0].substring(0, 3) === "grp" || val[0].substring(0, 5) === "block") {
                htmlFields.push(
                  <Col md="12">
                    {val[0].substring(0, 3) === "grp" && <>
                      < Divider />
                      <label>
                        {fields[key].label}-<span style={{ display: "inline-block", marginLeft: "5px", marginRight: "5px", backgroundColor: fields[key].flowColor, height: "10px", width: "10px" }}></span>
                        {fields[key].flowName}
                      </label>
                    </>
                    }
                  </Col>
                );
              } else if (val[0] === "other") {
                htmlFields.push(
                  <Col md="12">
                    <SLabel><Trans>Altri parametri di funzionamento</Trans></SLabel>
                    <label>{fields[key].label}</label>
                  </Col>
                );
              } else {
                htmlFields.push(<Col md={colw}>{val[1].field}</Col>);
                if (val[0] === 'groupsList') {
                  htmlFields.push(
                    <Col md="12">
                      <SLabel><Trans>Parametri di funzionamento</Trans></SLabel>
                    </Col>
                  );
                } else if (isComplexGraph && val[0] === 'inventory') {
                  htmlFields.push(<Divider />);
                }
              }
            });
            return <Row>{htmlFields}</Row>;
          }}
        />
        <Button role="button" tabIndex="0" onClick={() => { this.formRef.current.submit(); }} onKeyDown={() => { }} color='primary' className="btn btn-primary">
          <Trans>Salva</Trans>
        </Button>

        <Button role="button" tabIndex="0" onClick={() => { onUndoDoubleClick(); }} onKeyDown={() => { }} color='link' className="btn btn-link">
          <Trans>Annulla</Trans>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return { catalogs };
};

export default connect(mapStateToProps)(NodeEdit2);
