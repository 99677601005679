import React from 'react';
import { connect } from 'react-redux';
import {  DataTable, DynamicForm} from 'app/common';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';

class AddGroup extends React.Component {

  state = {
    assetIds: [],
    name: '',
    description: '',
  }

  componentDidMount() {
    const { groupId, graphData } = this.props;
    if(groupId == null){
      return this.setState({name: '', description: '', assetIds: []});
    }
    const { assetGroups } = graphData;
    const assetGroup = assetGroups.find(ag => ag.id === groupId);
    const { name, description } = assetGroup;
    const { assetIds } = assetGroup
    this.setState({name , description, assetIds});


  }

  saveGroup = (params) => {
    const { graphData, onSave } = this.props;
    let { groupId } = this.props;
    const { assetIds } = this.state;
    const { name, description } = params;
    const { assets } = graphData;
    
    const listaIds = [];
    if (groupId === null) {
      graphData.assetGroupIdCounter++;
      groupId = graphData.assetGroupIdCounter;
      assetIds.forEach((assId) => {
        const asset = assets.find(ass => ass.id === assId);
        if (!asset.groupIds) {
          asset.groupIds = [];
        }
        listaIds.push(assId);
        asset.groupIds.push(parseInt(groupId,10));
      });
    }else{
       assets.forEach((asset) => {
        const groupIds = [];
        asset.groupIds.forEach((thisId) => {
          if (thisId !== parseInt(groupId,10)) {
            groupIds.push(thisId);
          }
        });
        asset.groupIds = groupIds;
       
      });

      assetIds.forEach((assId) => {
        const asset = assets.find(ass => ass.id === assId);
        if (!asset.groupIds) {
          asset.groupIds = [];
        }
        listaIds.push(assId);
        asset.groupIds.push(parseInt(groupId,10));
      });
    }

    const group = {
      name,
      description,
      assetIds: listaIds,
      id: parseInt(groupId,10),
    };

    const groupkey = graphData.assetGroups.findIndex(grp => grp.id === parseInt(groupId,10));
    if (groupkey === -1) {
      graphData.assetGroups.push(group);
    } else {
      graphData.assetGroups[groupkey] = group;
    }
    onSave(graphData);
  }

  isNameValid = (name) => {
    const { graphData, groupId } = this.props;
    const { assetGroups } = graphData;
    const assetGroup = assetGroups.find(ag => ag.name === name);
    if (assetGroup != null && assetGroup.id !== groupId) {
      return new Error(i18n._(t`Esiste già un gruppo di asset con questo nome`));
    }
    return true;
  }

  toggleAsset = (asset) => {
    const { id } = asset;
    const { assetIds } = this.state;
    const index = assetIds.indexOf(id);
    if (index >= 0) {
       assetIds.splice(index, 1); 
    } else {
      assetIds.push(id);
    }
    console.log(assetIds);
    this.setState({ assetIds });
  }

  getParsedData = () => {
    const { graphData, energyAsset } = this.props;
    const { assets } = graphData;   
    const { assetIds } = this.state;
    console.log(assetIds);
    let data = [];
    assets.forEach((asset) => {
      const nAsset = JSON.parse(JSON.stringify(asset));
      const assetType = energyAsset.find(at => at.id === nAsset.supportedAssetId);
      nAsset.assetType = assetType.name;
      if (nAsset.qualifier !== undefined) {
        nAsset.assetType = `${assetType.name} - ${nAsset.qualifier}`;
      }
      nAsset._checked = assetIds.indexOf(nAsset.id)>=0;
      data.push(nAsset)
    });
    return data;
  }

	render() {
    const { onUndo } = this.props;
    const { name, description } = this.state;
    const initialValues = {name, description};
    const fields = [
    { name: 'name', type: 'text', label: <Trans>Nome</Trans>, validation: { required: true, func: name => this.isNameValid(name) } },
    { name: 'description', type: 'text', label: <Trans>Descrizione</Trans> },
    {
      name: 'sites',
      type: 'custom',
      render: () => (
        <DataTable
          keyField="id"
          search
          checkbox
          width="100%"
          sortby="name"
          headers={[
            { title: <Trans>Nome</Trans>, property: 'name', index: 1, dataType: 'string', canSearch: true },
            { title: <Trans>Tipologia</Trans>, property: 'assetType', index: 2, dataType: 'string', canSearch: true },
          ]}
            data={this.getParsedData()}
            onCheckBoxToggle={this.toggleAsset}
            noData={<Trans>Nessun asset</Trans>}
          />
        ),
    },
    ];

    return (
      <DynamicForm
        
        title={<Trans>Nuovo gruppo</Trans>}
        fields={fields}
        initialValues={initialValues}
        onSubmit={this.saveGroup}
        onCancel={onUndo}

      />
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

const mapDispatchToProps = dispatch => ({
 // updateAssetGraph: assetGraph => dispatch(updateAssetGraph(assetGraph)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
