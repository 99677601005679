import api, { getAxiosError } from 'api';
import { formatDomain } from './helpers';
import logger from 'app/common/log';

const getChainEventTypeList = async () => {
  let data = [];
  let err;
  try {
    const req = await api.get('/EventChains/allTypes');
    data = req.data;
    logger.debug('getChainEventTypeList ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('getChainEventTypeList %o', err);
  }
  return { data, err };
};

const loadUserPreferences = async () => {
  let data = {};
  let err;
  try {
    const req = await api.get('/UserPreferences/own');
    data = req.data;
    logger.debug('loadUserPreferences ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('loadUserPreferences %o', err);
  }
  return { data, err };
};

const loadTree = async domainId => {
  let data = {};
  let err;

  try {
    const params = {
      filter: { include: { companies: ['siteGroups', 'sites'] }, where: { id: domainId } }
    };
    const req = await api.get('/Domains', { params });
    data = req.data.map(formatDomain);
    logger.debug('PlantSelection loadTree', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('PlantSelection loadTree %o', err);
  }
  return { data, err };
};

const savePreferences = async (preferences) => {
  logger.debug('savePreferences ', preferences);
  let data = {};
  let err;
  try {
    const response = await api.patch('/UserPreferences/own', { preferences });
    data = response.data;
    // changeNotifications(notificationPreferences);
    // setNotificationPreferences(notificationPreferences);
    logger.debug('savePreferences', response);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('savePreferences %o', err);
  }
  return { data, err };
};

const saveDomainPlantList = async (domainId, beaDomainPlants) => {
  let data = {};
  let err;

  try {
    const req = await api.patch('/Domains');
    data = req.data.map(formatDomain);
    logger.debug('saveDomainPlantList loadTree', data);
  } catch (ex) {
    const { msg } = getAxiosError(ex);
    err = msg;
    logger.error('saveDomainPlantList loadTree %s', err);
  }
  return { data, err };
};

export {
  loadTree,
  getChainEventTypeList,
  loadUserPreferences,
  saveDomainPlantList,
  savePreferences
};
