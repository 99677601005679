import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import VariableConfig from './VariableConfig';
import { widgetTypes } from '../common';
import logger from 'app/common/log';


const ErrorMessage = styled.span`
  display: block;
  color: red;
`;

const InputColor = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  background-color: ${props => props.background};
  cursor: pointer;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

class LayoutConfig extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    const { widget } = props;
    const { widgetType, variables, period, properties } = widget;

    const hasWidgetColor = widget.properties && widget.properties.firstRowColor;
    const varsHasDifferentUnitMeasure = this.variablesHasDifferentUnitMeasure(variables);

    this.state = {
      variables: variables.map(item => ({ item, ref: React.createRef() })),
      error: '',
      firstRowPickerOpen: false,
      firstRowColor: hasWidgetColor ? properties.firstRowColor : '#4A90E2',
      isTableWidget: widgetType === widgetTypes.TABLE,
      varStatsIsDisabled: variables.length === 1 || varsHasDifferentUnitMeasure,
      periodStatsIsDisabled: period.rows && period.rows.length > 1
    };
  }

  handleClickColor = picker => {
    if (picker === 'firstRowColor') {
      this.setState(prevState => ({ firstRowPickerOpen: !prevState.firstRowPickerOpen }));
    }
  };

  handleCloseColor = picker => {
    if (picker === 'firstRowColor') {
      this.setState({ firstRowPickerOpen: false });
    }
  };

  handleFirstRowChangeColor = color => {
    this.setState({ firstRowColor: color.hex });
  };

  getColorPicker = (picker, color, isOpenPicker, onCompleteCallback) => {
    return (
      <>
        <InputColor id={picker} background={color} onClick={() => this.handleClickColor(picker)} />
        {isOpenPicker && (
          <PopOver>
            <Cover onClick={() => this.handleCloseColor(picker)} />
            <SketchPicker id={picker} color={color} onChangeComplete={onCompleteCallback} />
          </PopOver>
        )}
      </>
    );
  };

  /**
   * Check if all variables have different unit measures
   */
  variablesHasDifferentUnitMeasure = variables => {
    const unitMeasures = [];
    if (Array.isArray(variables)) {
      variables.map(variable => {
        if (!unitMeasures.includes(variable.unit)) {
          unitMeasures.push(variable.unit);
        }
        return null;
      });
    }

    return unitMeasures.length > 1;
  };

  getFields = widget => {
    const { widgetType } = widget;
    const {
      isTableWidget,
      firstRowPickerOpen,
      firstRowColor,
      varStatsIsDisabled,
    } = this.state;

    const fields = [
      {
        name: 'title',
        type: 'text',
        label: <Trans>Titolo</Trans>,
        md: isTableWidget ? 6 : 12,
        validation: { required: isTableWidget }
      }
    ];

    if (isTableWidget) {
      fields.push({
        name: 'properties.disposition',
        type: 'select',
        label: <Trans>Disposizione</Trans>,
        xs: 6,
        sm: 12,
        md: 6,
        validation: { required: true },
        options: [
          { value: 'colrow', label: i18n._(t`Variabili su colonne, periodi su righe`) },
          { value: 'rowcol', label: i18n._(t`Periodi su colonne, variabili su righe`) }
        ]
      });

      fields.push({
        name: 'properties.firstRowColor',
        type: 'custom',
        xs: 6,
        sm: 12,
        md: 6,
        label: <Trans>Colore prima riga (intestazione colonne)</Trans>,
        render: () =>
          this.getColorPicker(
            'firstRowColor',
            firstRowColor,
            firstRowPickerOpen,
            this.handleFirstRowChangeColor
          )
      });

      fields.push({
        name: 'properties.centralcolumns',
        type: 'select',
        label: <Trans>Colore righe successive</Trans>,
        xs: 6,
        sm: 12,
        md: 6,
        validation: { required: true },
        options: [
          { value: 'colorsUniform', label: i18n._(t`Colore uniforme`) },
          { value: 'colorsForRows', label: i18n._(t`Colori alternati`) }
        ]
      });

      fields.push({
        name: 'properties.varStats',
        type: 'select',
        label: <Trans>Statistiche sull'insieme delle variabili</Trans>,
        xs: 12,
        validation: { required: true },
        disabled: varStatsIsDisabled,
        // disabled: varStatsIsDisabled,
        options: [
          { value: 'total', label: i18n._(t`Totale`) },
          { value: 'medium', label: i18n._(t`Media`) },
          { value: 'notshow', label: i18n._(t`Non mostrare`) }
        ]
      });

      // fields.push({
      //   name: 'properties.varPeriod',
      //   type: 'select',
      //   label: <Trans>Statistiche sul periodo</Trans>,
      //   xs: 6,
      //   sm: 12,
      //   md: 6,
      //   lg: 6,
      //   validation: { required: true },
      //   disabled: periodStatsIsDisabled,
      //   options: [
      //     { value: 'total', label: i18n._(t`Totale`) },
      //     { value: 'medium', label: i18n._(t`Media`) },
      //     { value: 'notshow', label: i18n._(t`Non mostrare`) },
      //   ],
      // });
    }

    if (widgetType === widgetTypes.PIE) {
      fields.push({
        name: 'type',
        type: 'select',
        label: <Trans>Tipo di grafico</Trans>,
        onChange: (value, values) => {
          if (value.indexOf('bar') > -1) {
            values.legend = 'none';
          }
        },
        validation: { required: true },
        md: 6,
        options: [
          { value: 'donut', label: i18n._(t`Ciambella (anello)`) },
          { value: 'pie', label: i18n._(t`Torta`) },
          { value: 'vertical-bar', label: i18n._(t`Barre verticali`) },
          { value: 'horizontal-bar', label: i18n._(t`Barre orizzontali`) }
        ]
      });
    }

    if (widgetType === widgetTypes.SERIES || widgetType === widgetTypes.PIE) {
      fields.push({
        name: 'legend',
        type: 'select',
        label: <Trans>Legenda</Trans>,
        md: widgetType === widgetTypes.PIE ? 6 : 12,
        disabled: (value, values) => values.type && values.type.indexOf('bar') > -1,
        validation: { required: true },
        options: [
          { value: 'none', label: i18n._(t`Non mostrare`) },
          { value: 'right', label: i18n._(t`Mostra a destra`) },
          { value: 'bottom', label: i18n._(t`Mostra in basso`) }
        ]
      });
    }

    if (widgetType === widgetTypes.PIE) {
      fields.push(
        {
          name: 'format',
          type: 'select',
          md: 6,
          label: <Trans>Formato valori</Trans>,
          options: [
            { value: 'value', label: i18n._(t`Valori assoluti`) },
            { value: 'ratio', label: i18n._(t`Valori percentuali`) }
          ]
        },
        {
          name: 'showTotal',
          type: 'select',
          label: <Trans>Mostra totale dei valori</Trans>,
          md: 6,
          options: [
            { value: true, label: i18n._(t`Sì`) },
            { value: false, label: i18n._(t`No`) }
          ]
        }
      );
    }

    if (widgetType === widgetTypes.SERIES) {
      fields.push({
        name: 'grid',
        type: 'select',
        label: <Trans>Griglia</Trans>,
        options: [
          { value: 'none', label: i18n._(t`Non mostrare`) },
          { value: 'x', label: i18n._(t`Solo griglia verticale`) },
          { value: 'y', label: i18n._(t`Solo griglia orizzontale`) },
          { value: 'both', label: i18n._(t`Griglia verticale e orizzontale`) }
        ]
      });
    }

    if (widgetType === widgetTypes.LED || widgetType === widgetTypes.SWITCH) {
      fields.push(
        {
          name: 'properties.iconSize',
          type: 'select',
          label: <Trans>Dimensione icona</Trans>,
          md: widgetType === widgetTypes.SWITCH ? 4 : 6,
          options: [
            { value: 1, label: '1x' },
            { value: 1.5, label: '1.5x' },
            { value: 2, label: '2x' },
            { value: 3, label: '3x' },
            { value: 4, label: '4x' }
          ]
        },
        {
          name: 'properties.showLabel',
          type: 'select',
          label: <Trans>Mostra etichetta</Trans>,
          md: widgetType === widgetTypes.SWITCH ? 4 : 6,
          options: [
            { value: true, label: i18n._(t`Sì`) },
            { value: false, label: i18n._(t`No`) }
          ]
        }
      );
    }

    if (widgetType === widgetTypes.SWITCH || widgetType === widgetTypes.SLIDER) {
      fields.push({
        name: 'properties.askConfirm',
        type: 'select',
        label: <Trans>Chiedi conferma</Trans>,
        md: widgetType === widgetTypes.SWITCH ? 4 : 6,
        options: [
          { value: true, label: i18n._(t`Sì`) },
          { value: false, label: i18n._(t`No`) }
        ]
      });
    }

    return fields;
  };

  validate = async data => {
    this.setState({ error: '' });
    const { variables } = this.state;

    const promises = variables.map(item => item.ref.current.getValues(data));
    try {
      // Execute vaiidation
      await this.form.current.validateAndGetValues();

      // TODO: Properties fields are not validated, check validation methods

      // Validate variables
      const values = await Promise.all(promises);
      if (values.some(x => x === null)) {
        throw new Error(i18n._(t`Sono presenti errori di validazione`));
      }

      return true;
    } catch (e) {
      console.log('Layout config validation error:', e);
      this.setState({ error: e.message });
      return false;
    }
  };

  getValues = async data => {
    const { variables, isTableWidget, firstRowColor } = this.state;
    const promises = variables.map(item => item.ref.current.getValues(data));
    try {
      const formProps = await this.form.current.validateAndGetValues();
      const formValues = await Promise.all(promises);
      logger.debug('variable LayoutConfig getValues %o', formValues);
      if (isTableWidget && formProps.properties) {
        formProps.properties.firstRowColor = firstRowColor; // update color picker value
      }
      return { variables: formValues, ...formProps };
    } catch (e) {
      this.setState({ error: e.message });
      return null;
    }
  };

  getInitialValues(widget) {
    const { firstRowColor, isTableWidget, varStatsIsDisabled } = this.state;

    if (isTableWidget) {
      // Avoid errors on properties
      if (!widget.properties) {
        widget.properties = {};
      }

      if (!widget.properties.title) {
        widget.properties.title = 'Tabella';
      }
      widget.title = widget.title || widget.properties.title;
      if (!widget.properties.disposition) {
        widget.properties.disposition = 'colrow';
      }
      if (!widget.properties.centralcolumns) {
        widget.properties.centralcolumns = 'colorsUniform';
      }
      if (!widget.properties.firstRowColor) {
        widget.properties.firstRowColor = firstRowColor;
      }
      if (!widget.properties.varStats || varStatsIsDisabled) {
        widget.properties.varStats = 'notshow';
      }
      // if (!widget.properties.varPeriod || periodStatsIsDisabled) {
      //   widget.properties.varPeriod = 'notshow';
      // }
    }

    if (widget.widgetType === widgetTypes.PIE && !widget.type) {
      widget.type = 'donut';
    }

    return widget;
  }

  onChangeUnitMeasure = async (value, formValues) => {
    const { widget } = this.props;

    if (widget.widgetType === widgetTypes.TABLE) {
      const result = await this.getValues();
      const varsDifferentUnitMeasures = this.variablesHasDifferentUnitMeasure(result.variables);
      if (varsDifferentUnitMeasures) {
        // Statistiche sulle variabili disabilita campo
        this.setState({ varStatsIsDisabled: true });
        // Change value to notshow
        this.form.current.changeValue('properties.varStats', 'notshow', undefined);
      } else {
        // Enable properties.varStats
        this.setState({ varStatsIsDisabled: false });
        // Change value to notshow
        this.form.current.changeValue('properties.varStats', 'notshow', undefined);
      }
    }
  };

  render() {
    const { widget, physicalQuantities, units, physicaldimensions } = this.props;
    const { variables, isTableWidget, error } = this.state;

    return (
      <div>
        <DynamicForm
          ref={this.form}
          hideButtons
          fields={this.getFields(widget)}
          initialValues={this.getInitialValues(widget)}
          hideRequiredLabel={isTableWidget}
        />
        {variables.map(variable => {
          const { type, id, physicalQuantity, customQuantityId } = variable.item;
          const showStatistic =
            widget.period.rows &&
            widget.period.rows.length === 1 &&
            (widget.period.rows[0].periodValue === 'values-period' ||
              widget.period.rows[0].periodValue === 'period');
          let unitList;
          if (customQuantityId) {
            unitList = [
              {
                naturalKey: 'adim',
                symbol: (physicaldimensions.find(x => x.id === customQuantityId) || {}).unitSymbol
              }
            ];
          } else {
            const unitNaruralKeys = physicalQuantities.find(x => x.naturalKey === physicalQuantity)
              .units;
            unitList = units.filter(x => unitNaruralKeys.includes(x.naturalKey));
          }
          return (
            <VariableConfig
              onChangeUnitMeasure={this.onChangeUnitMeasure}
              ref={variable.ref}
              key={`${type}_${id}`}
              widgetType={widget.widgetType}
              variable={variable.item}
              units={unitList}
              showStatistic={showStatistic}
            />
          );
        })}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  }
}

LayoutConfig.propTypes = {
  widget: PropTypes.object.isRequired,
  physicalQuantities: PropTypes.array.isRequired,
  physicaldimensions: PropTypes.array,
  units: PropTypes.array.isRequired
};

LayoutConfig.defaultProps = {
  physicaldimensions: []
};

export default LayoutConfig;
