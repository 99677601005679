import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DeleteModal } from 'app/common';
import api from 'api';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import i18n from 'app/i18n';
import { getUrl } from 'app/utils/navigation';
import ReportsContext from './ReportsContext';
import ManageReports from './ManageReports/ManageReports';
import EditReport from './EditReport/EditReport';
import ShareModal from './ShareModal';
import ReportHistory from './ReportHistory';
import {
  ReportLoading,
  ReportError,
  getReportsByTypeAndId,
  getBeaUsersEndpoint,
  getReportHistoryPeriodsList,
  getReportsHistory,
  getBeaUsersTags
} from './ReportHelper';

const Reports = (props) => {
  const {
    match,
    location: { pathname }
  } = props;
  const { url } = match;
  const { id, type } = match.params;

  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false });
  const [shareModal, setShareModal] = useState({ isOpen: false });
  const [distributionListOptions, setDistributionListOptions] = useState([]);

  const loadReports = () => {
    setIsLoaded(false);

    // Check if the route is default route comparing the URL
    const currentUrl = getUrl('/reports', type, id);
    let path = pathname;
    if (currentUrl !== path) {
      setIsLoaded(true);
      return;
    }

    const reportPromise = getReportsByTypeAndId(type, id);
    const historyPromises = reportPromise.then(res => {
      return Promise.all( res.data.map(report => getReportsHistory(report)) );
    });
    const internalUserPromise = api.get(getBeaUsersEndpoint(type, id));

    Promise.all([reportPromise, historyPromises, internalUserPromise]).then(res => {
      const repostsList = res[0].data;
      const reportPeriods = getReportHistoryPeriodsList(res[1], repostsList);

        // Merge period references list with reports list
        const reportsWithPeriodReference = [];
        repostsList.map((report, index) => {
          // Same index of reports because arrays length must be equals
          reportsWithPeriodReference.push({
            ...report,
            periodReference: (reportPeriods[index] && reportPeriods[index].periodReference) ? reportPeriods[index].periodReference : undefined,
            lastDateEmission: (reportPeriods[index] && reportPeriods[index].lastDateEmission) ? reportPeriods[index].lastDateEmission : undefined,
            updatedInfo: `${report.updater.name} ${new Date(report.updated).toLocaleString()}`
          });
          return null;
        });

        // Set reports list
        setReports(reportsWithPeriodReference);

        // Set users tags
        setDistributionListOptions( getBeaUsersTags(res[2].data) );

        setIsLoaded(true);

    }).catch(err => {
      setError('Errore caricamento reports');

      setIsLoaded(true);
    });

  };

  useEffect(() => {

    loadReports();
    return;
  }, []);

  useEffect(() => {
    loadReports();
    return;
  }, [pathname]);

  const onEditReport = async () => {
    loadReports();
  };

  const onAddReport = async () => {
    loadReports();
  };

  const deleteModalToggle = () => {
    setDeleteModal({ isOpen: !deleteModal.isOpen });
  };

  const deleteReport = (reportId) => {
    api.delete(`/Reports/${reportId}`).then(resp => {
      setReports(reports.filter(x => x.id !== reportId));
      deleteModalToggle();
    }).catch(err => {
      toast.error( i18n._(t`Errore cancellazione report`) );
    });
  };

  const renderDeleteModal = () => (
    <DeleteModal
      askPassword
      isOpen={deleteModal.isOpen}
      toggleModal={deleteModalToggle}
      header={<Trans>Conferma cancellazione {deleteModal.report ? deleteModal.report.name : '' }</Trans>}
      onDelete={() => deleteReport(deleteModal.report.id)}
    />
  );

  const shareModalToggle = () => {
    setShareModal({ isOpen: !shareModal.isOpen });
  };

  const refreshReports = () => {
    loadReports();
    shareModalToggle();
  };

  const downloadPdfFromApi = (reportData, report) => {
    const url = URL.createObjectURL(new Blob([reportData], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    if (report && report.id) {
      link.setAttribute('download', 'report-'+ report.id + '.pdf');
    } else {
      link.setAttribute('download', 'report-preview.pdf');
    }
    
    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank');
    }

    document.body.appendChild(link);
    link.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 200);
  }

  const onGeneratePdf = async (report, isPost, previewCallback) => {
    const apiOptionTimeout = 30000;

    if (isPost && typeof previewCallback === 'function') {
      // Download anteprima report da schermata di editing per nuovo report o report esistente
      const reportPreview = await previewCallback();

      toast.success( i18n._(t`Il report ${reportPreview.name} sarà disponibile tra qualche secondo...`) );

      // Ho un id per cui la richiesta immediata la inoltro in PATCH altrimenti otterrei un
      // errore di validazione su BEA dovuto alla presenza dell'id ed al name non univoco
      reportPreview.id = null;
      reportPreview.name += " - DRAFT";
      reportPreview.personalInstance = false;
      reportPreview.generationDay = reportPreview.generationDay || 'everyday';

      api.post(
        `/reports/immediate/`,
        reportPreview, 
		{ responseType: 'blob', timeout: apiOptionTimeout }
      ).then(
        (response) => {
          downloadPdfFromApi(response.data, reportPreview);
        }
      ).catch(
        error => {
          // console.log(error.message);
          toast.error( i18n._(t`Errore generazione report`) + ' ' + reportPreview.name );
        }
      );
    } else if (report) {

      toast.success( i18n._(t`Il report ${report.name} sarà disponibile tra qualche secondo...`) );

      // Download report esistente
      api.get(
		`/reports/${report.id}/immediate/`, { responseType: 'blob', timeout: apiOptionTimeout }).then((response) => {
        downloadPdfFromApi(response.data, report);
      }).catch(error => {
        // console.log(error.message);
        toast.error(i18n._(t`Errore generazione report`) + ' ' + report.name);
      });

    } else {
      toast.error( i18n._(t`Errore generazione report: report non rilevato`) );
    }

  };

  const renderShareModal = () => {
    return (
      <ShareModal
        {...props}
        isOpen={shareModal.isOpen}
        report={shareModal.report}
        distributionListOptions={distributionListOptions}
        toggleModal={shareModalToggle}
        refreshReports={refreshReports}
        header={<Trans>Condividi report</Trans>}
      />
    );
  };

  const contextValue = { reports, onEditReport, onAddReport };

  if (error) {
    return <ReportError error={error} />
  } else if (!isLoaded) {
    return <ReportLoading />
  } else {
    return (
      <>
        { deleteModal && deleteModal.isOpen && renderDeleteModal() }
        { shareModal &&  shareModal.report && shareModal.isOpen === true && renderShareModal() }
        <ReportsContext.Provider value={contextValue}>
          <Switch>
            <Route
              exact
              path={url}
              render={props => (
                <ManageReports
                  {...props}
                  reports={reports}
                  url={url}
                  onDelete={report => setDeleteModal({ isOpen: true, report: report })}
                  onShare={report => setShareModal({ isOpen: true, report: report })}
                  onGeneratePdf={onGeneratePdf}
                />
              )}
            />
            <Route exact path={`${url}/:reportid/history`} render={props => <ReportHistory {...props} type={type} id={id} />} />
            <Route exact path={`${url}/new`} render={props => <EditReport {...props} isNew onGeneratePdf={onGeneratePdf} />} />
            <Route exact path={`${url}/:reportid/edit`} render={props => <EditReport {...props} edit onGeneratePdf={onGeneratePdf} />} />
            <Redirect to={getUrl('/reports', type, id)} />
          </Switch>
        </ReportsContext.Provider>
      </>
    );
  }

};

Reports.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup
  } = state.navigation;
  return {
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup
   };
}

export default connect(mapStateToProps)(Reports);
