import React, { useContext } from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components';
import TreeContext from './TreeContext';

const Li = styled.li`
  margin-left: 1.5rem;
`;

const Item = styled.div`
  display: inline-flex;
  padding: .2rem;
  ${props => props.checked && 'color: #1F71B7'};
  cursor: pointer;

  &:hover {
    background-color: transparent !important;
    background-image: linear-gradient(to right,rgba(31,113,183,0.2),transparent);
    span {
      color: #1F71B7;
    }
    svg {
      fill: #1F71B7;
    }
  }
`;

const OpIcon = styled(ReactSVG)`
  width: 1.2rem;

  & svg {
    fill: ${props => props.checked ? '#1F71B7' : '#212529'};
  }
`;

const Label = styled.span`
  margin-left: .5rem;
`;


const TreeNode = ({ node }) => {
  const { toggle, checkedNodes } = useContext(TreeContext);

  const { subtype, type, id, item } = node;
  const checked = checkedNodes.some(x => x !== null && x.id === id);

  const toggleItem = () => {
    toggle(node, !checked);
  };


  return (
    <Li>
      <Item onClick={toggleItem} checked={checked}>
        <OpIcon src={`/assets/icons/opselector/${subtype}.svg`} checked={checked}/>
        <Label>{item.name}</Label>
      </Item>
    </Li>
  );
};

export default TreeNode;
