export default [
  { name: 'today' },
  { name: 'thismonth' },
  { name: 'thisweek' },
  { name: 'thisyear' },
  { name: '15m' },
  { name: '1h' },
  { name: '1dy' }
]


