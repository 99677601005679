let promise;
let resolve;
let reject;

export const getPromise = () => {
  if (promise) {
    console.log('confirmation getPromise old', promise);
    return { promise, resolve, reject };
  } else {
    console.log('confirmation getPromise new', promise);
    promise = new Promise((freshResolve, freshReject) => {
      resolve = freshResolve;
      reject = freshReject;
    });
  }
  return { promise, resolve, reject };
};

export const clearPromise = () => (promise = null);
