/* eslint-disable no-underscore-dangle */

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { DataTable, IconSelect, SuggestInput } from 'app/common';
import { Trans } from '@lingui/macro';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { getLedStates, widgetTypes } from '../common';


const InputColor = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  background-color: ${props => props.background};
  cursor: pointer;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const TitleInput = styled.input`
  width: 100%;
  padding: .3rem .5rem;
  background-color: #03c3cc16;
  border: 1px solid #ddd;
  border-radius: .3rem;
  font-size: 1.1rem;
  font-weight: 500;
`;


const ledIcons = [
  'bell-empty.svg',
  'bell-filled.svg',
  'bell-ringing.svg',
  'bell-snoozed.svg',
  'chain-linked.svg',
  'chain-unlinked.svg',
  'circular-empty.svg',
  'circular-filled.svg',
  'elevator-doors-closed.svg',
  'elevator-doors-opened.svg',
  'eye-barred.svg',
  'eye-open.svg',
  'empty-rnd-check.svg',
  'empty-rnd-cross.svg',
  'filled-rnd-check.svg',
  'filled-rnd-cross.svg',
  'filled-sqrd-check.svg',
  'filled-sqrd-cross.svg',
  'large-check.svg',
  'large-cross.svg',
  'light-bulb.svg',
  'light-bulb-empty.svg',
  'light-bulb-filled.svg',
  'padlock-locked.svg',
  'padlock-unlocked.svg',
  'rnd-pdl-locked.svg',
  'rnd-pdl-unlocked.svg',
  'rounded-square-empty.svg',
  'rounded-square-filled.svg',
  'smiley-frown.svg',
  'smiley-meh-face.svg  ',
  'smiley-smile.svg',
  'star-empty.svg',
  'star-filled.svg',
  'thumb-up-empty.svg',
  'thumb-down-empty.svg',
  'thumb-up-filled.svg',
  'thumb-down-filled.svg',
  'warning-filled.svg',
];

const switchcons = [
  'large-play.svg',
  'large-stop.svg',
  'power-button-off.svg',
  'rect-switch-left.svg',
  'rect-switch-right.svg',
  'rnd-play.svg',
  'rnd-power-empty.svg',
  'rnd-power-full.svg',
  'rnd-stop.svg',
  'rnd-switch-left.svg',
  'rnd-switch-right.svg',
  'switch-down.svg',
  'switch-off.svg',
  'switch-on.svg',
  'switch-up.svg',
];


const StateConfig = forwardRef((props, ref) => {
  const { widgetType, variable } = props;

  const [label, setLabel] = useState(variable.label);
  const [colorPickerOpen, setColorPickerOpen] = useState({});
  const [states, setStates] = useState(() => {
    // existing values
    if (variable.properties.states && variable.properties.states.length > 0) {
      const ledStates = getLedStates();
      return variable.properties.states.map(x => ({ ...x, _label: (ledStates.find(s => s.label === x.label) || { _label: x.label })._label }));
    }

    // default values
    return [
      { state: '0', value: false, color: '#999', image: widgetType === widgetTypes.SWITCH ? 'rnd-power-empty.svg' : 'circular-filled.svg', label: '', _label: '' },
      { state: '1', value: true, color: '#1F71B7', image: widgetType === widgetTypes.SWITCH ? 'rnd-power-full.svg' : 'circular-filled.svg', label: '', _label: '' },
    ];
  });

  const ledStates = getLedStates();
  const ledStatesTranslated = ledStates.map(x => x._label);

  const handleClickColor = (state) => {
    setColorPickerOpen(prevState => ({ ...prevState, [state]: !prevState[state] }));
  };

  const handleCloseColor = (state) => {
    setColorPickerOpen(prevState => ({ ...prevState, [state]: false }));
  };


  const updateRow = (e, state) => {
    const { target } = e;
    const { name, value } = target;
    setStates(prevStates => prevStates.reduce((rows, current) => {
      if (current.state === state) {
        return rows.concat({ ...current, [name]: value });
      }
      return rows.concat(current);
    }, []));
  };

  const handleChangeColor = (color, state) => {
    const target = { name: 'color', value: color.hex };
    updateRow({ target }, state);
  };

  const updateLabel = (e, state) => {
    const { value } = e.target;
    updateRow({ target: { name: '_label', value } }, state);

    const valueBase = ledStates.find(x => x._label === value);
    if (valueBase) {
      updateRow({ target: { name: 'label', value: valueBase.label } }, state);
    } else {
      updateRow({ target: { name: 'label', value } }, state);
    }
  };

  useImperativeHandle(ref, () => ({
    getValues() {
      return { label, states };
    },
  }));

  const icons = widgetType === widgetTypes.SWITCH ? switchcons : ledIcons;

  const changeVariableLabel = (e) => {
    const { target } = e;
    const { value } = target;
    setLabel(value);
  };

  return (
    <div>
      <TitleInput value={label} placeholder={variable.name} onChange={changeVariableLabel} />
      <DataTable
        keyField="state"
        data={states}
        width="100%"
        sortby="state"
        headers={[
          { title: <Trans>Stato</Trans>, property: 'state', index: 1 },
          { title: <Trans>Colore</Trans>, property: 'color', index: 2, cell: row => (
            <>
              <InputColor id="pickColor" background={row.color} onClick={() => handleClickColor(row.state)} />
              {colorPickerOpen[row.state] && (
                <PopOver>
                  <Cover onClick={() => handleCloseColor(row.state)} />
                  <SketchPicker color={row.color} onChangeComplete={color => handleChangeColor(color, row.state)} />
                </PopOver>
              )}
            </>
          ) },
          { title: <Trans>Immagine</Trans>, property: 'image', index: 3, cell: row => <IconSelect name="image" value={row.image} onChange={e => updateRow(e, row.state)} path="/assets/icons/led" icons={icons} color={row.color} /> },
          { title: <Trans>Etichetta</Trans>, property: 'label', index: 4, cell: row => <SuggestInput value={row._label} name="label" onChange={e => updateLabel(e, row.state)} suggestions={ledStatesTranslated} maxNumber={10} /> },
        ]}
      />
    </div>
  );
});

export default StateConfig;
