import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import { Col, Container, Row, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';

const BorderContainer = styled(Container)`
  && {
    border: 1px solid #ddd;
    /* padding: 0 1rem .5rem 1rem; */
    background-color: ${props => props.theme.common.background.secondaryColor};
  }
`;

const RowAlignEnd = styled(Row)`
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;

  & input {
    margin-bottom: 0;
  }
`;

class SerialInterface extends Component {
  constructor(props) {
    super(props);
    this.interfaceform = React.createRef();

    const { serialInterface, initialValues, isNew } = props;
    this.state = {
      initialValues: { ...this.getDefaultValues(serialInterface, isNew, initialValues), ...initialValues },
      options: this.getOptions(serialInterface),
    };
  }

  getDefaultValues = (item, isNew, initialValues) => (
    {
      enabled: isNew ? item.enabled : (initialValues !== undefined), // forse se initialValues sono undefined va messo false e non preso dalle opzioni // forse se newItem va messo item.enabled, altrimenti va messo false
      portLabel: item.portLabel.value,
      name: item.portLabel.value,
      baudrate: item.baudrate.value,
      timeout: item.timeout.value,
      bytesize: item.bytesize.value,
      parity: item.parity.value,
      stopbits: item.stopbits.value,
    }
  );

  getOptions = (item) => {
    const options = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in item) {
      if (item[prop].values) {
        options[prop] = item[prop].values.map(x => ({ value: x, label: x.toString() }));
      }
    }
    return options;
  }

  validateTimeout = (timeout) => {
    const { serialInterface } = this.props;
    const { min, max } = serialInterface.timeout;
    if (+timeout < min || +timeout > max) {
      return new Error(i18n._(t`Il valore deve essere compreso tra ${min} e ${max}`));
    }
    return true;
  }

  getValues = async () => {
    try {
      const values = await this.interfaceform.current.validateAndGetValues();
      values.timeout = +values.timeout;
      return values;
    } catch (e) {
      return null;
    }
  };

  render() {
    const { readOnly } = this.props;
    const { initialValues, options } = this.state;

    return (
      <DynamicForm
        hideButtons
        hideRequiredLabel
        readOnly={readOnly}
        ref={this.interfaceform}
        fields={[
          { name: 'enabled', type: 'checkbox' },
          { name: 'portLabel', type: 'text', disabled: true, hidden: true },
          { name: 'name', type: 'text', label: <Trans>Nome</Trans>, validation: { conditional: (value, values) => (values.enabled ? { required: true } : {}) } },
          { name: 'baudrate', type: 'select', label: <Trans>Velocità di trasmissione [bps]</Trans>, options: options.baudrate },
          { name: 'timeout', type: 'number', label: <Trans>Timeout di trasmissione [ms]</Trans>, validation: { conditional: (value, values) => (values.enabled ? { required: true, func: this.validateTimeout } : {}) } },
          { name: 'bytesize', type: 'select', options: options.bytesize, disabled: true },
          { name: 'parity', type: 'select', label: <Trans>Parità</Trans>, options: options.parity },
          { name: 'stopbits', type: 'select', label: <Trans>Bit di stop</Trans>, options: options.stopbits },
        ]}
        initialValues={initialValues}
        layout={fields => (
          <BorderContainer fluid>
            <Header>
              {fields.enabled.field}
              <FormGroup>{fields.portLabel.data.value}</FormGroup>
            </Header>
            {fields.enabled.data.value && (
              <RowAlignEnd form>
                <Col lg={3} sm={6}>
                  {fields.name.field}
                </Col>
                <Col lg={3} sm={6}>
                  {fields.baudrate.field}
                </Col>
                <Col lg={2} sm={6}>
                  {fields.timeout.field}
                </Col>
                <Col lg={2} sm={6}>
                  {fields.parity.field}
                </Col>
                <Col lg={2} sm={6}>
                  {fields.stopbits.field}
                </Col>
              </RowAlignEnd>
            )}
          </BorderContainer>
        )}
      />
    );
  }
}

export default SerialInterface;
