import { Roles } from 'app/utils/auth';

export const requiredRoles = [
  { url: '/config/provisioning', systemRole: Roles.SYS },
  { url: '/users', systemRole: Roles.CSU },
  { url: '/managedomains', systemRole: Roles.CSU },
  { url: '/systools', systemRole: Roles.SSYS },
  { url: '/systools/getUUID', systemRole: Roles.SSYS },
  { url: '/systools/AggregationJob', systemRole: Roles.SSYS }

];

export const getHiddenItems = (userSystemRoles, userCurrentRoles) => {
  if (!requiredRoles || !userSystemRoles || !userCurrentRoles) {
    return [];
  }
  const hiddenItems = requiredRoles.reduce((hidden, requirement) => {
    const { url, systemRole, currentRole } = requirement;
    if (systemRole && !userSystemRoles.includes(systemRole)) {
      return hidden.concat(url);
    }
    if (currentRole && !userCurrentRoles.includes(currentRole)) {
      return hidden.concat(url);
    }
    return hidden;
  }, []);
  return hiddenItems;
};
