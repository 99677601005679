import React from 'react';

import Selector from '../Helpers/Selector';
import { Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const FristSelector = ({ nodeDataArray,assetMouseEnter,assetMouseOut,onSelect,undoAddNode }) => {
  const items = [];
  nodeDataArray.forEach((nda) => {
    let added = false;
    const { vectors } = nda.item.outFlow;
    let vectorsArray = [];
    if (vectors !== null && vectors !== undefined) {
      vectorsArray = Object.values(vectors);
    }
    vectorsArray.forEach((vtr) => {
      if (vtr.vector === undefined || vtr.instances === undefined || vtr.instances == null || vtr.instances > vtr.vector.length) {
        if (!added) {
          items.push(nda);
        }
        added = true;
      }
    });
  });
  if (items.length === 1) {
    onSelect(items[0]);
  }

  return (
    <div className="selection show node">
      <div>
        <strong style={{ marginLeft: '15px' }}><Trans>Seleziona il primo energy assets</Trans></strong>
        <Selector
          style={{ paddingTop: '0px' }}
          items={items}
          onMouseIn={assetMouseEnter}
          onMouseOut={assetMouseOut}
          onBlur={() => {}}
          onSelect={onSelect}
          searchText={i18n._(t`Cerca il primo energy assets per il link`)}
        />
      </div>
    </div>
  );
};
export default FristSelector;
