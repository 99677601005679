/** Author: Stefano Chessa*/
import React, { useEffect, useState } from 'react';
import { Button } from 'app/common';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from 'api/api';
import { ToastContainer, toast } from '../../../../../../../node_modules/react-toastify/index';

const ResetPassword = (props) => {
    const { userid, userData, closeModal, onSuccess, onError } = props;
    const thisUserData = userData && userData.filter(user => user.id === userid);
    const userName = thisUserData && thisUserData[0] && thisUserData[0].name;

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [userDisabled, setUserDisabled] = useState(false);

    useEffect(() => {
        getUserStatus();
    }, []);

    const submit = async () => {

        const res = await api.post('/UserInfos/' + userid + '/passwordReset', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setButtonDisabled(true);
            onSuccess(userName + ' - ' +  i18n._(t`Password resettata`));
        } else if (res.status >= 300) {
            onError(userName + " - " + res.data.error.message);
        }
    };

    const getUserStatus = async () => {
        const res = await api.get('/UserInfos/' + userid + '/disabled', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            if (res.data.disabled) {
                setButtonDisabled(true);
                setUserDisabled(true);
            }
        } else if (res.status >= 300) {
            toast.warn(res.data.error.message)
        }
    }
    const cancel = () => {
        closeModal();
    };

    if (!thisUserData || !userName)
        return null;

    return (
        <>
            <ToastContainer></ToastContainer>
            <Modal size="xl" centered isOpen={true}>
                <ModalHeader toggle={closeModal}><Trans>Reset password dell'utente</Trans>&nbsp;{userName}</ModalHeader>
                <ModalBody>
                    {userDisabled ?

                        (<div>
                            <Trans>Attenzione! L'utente selezionato è</Trans> <b><Trans>disabilitato</Trans></b><Trans>. Non è possibile procedere al reset della password.</Trans>
                        </div>) :
                        (<div>
                            <Trans>Vuoi procedere al reset della password dell'utente</Trans>&nbsp;<b>{userName}</b>?
                        </div>)
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submit} disabled={buttonDisabled}><Trans>Conferma</Trans></Button>
                    <Button color="link" onClick={cancel}><Trans>Annulla</Trans></Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

ResetPassword.propTypes = {
    userId: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired, 
    onSuccess: PropTypes.func.isRequired, 
    onError: PropTypes.func.isRequired
};


export default ResetPassword;