
import { setupI18n } from '@lingui/core';
import catalogEn from '../locales/en/messages';
import catalogEs from '../locales/es/messages';
import catalogFr from '../locales/fr/messages';
import catalogIt from '../locales/it/messages';

const i18n = setupI18n({ catalogs: { en: catalogEn, es: catalogEs, fr: catalogFr, it: catalogIt }, missing: '🚨' });

export default i18n;
