import React from 'react';
import api from 'api';
import Api from './Api';
import * as go from 'gojs';
import cGraph from '../ConfigGraph';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import Varie from '../Helpers/Varie';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.Api = new Api();
    this.varie = new Varie();

  }

  componentDidMount() {
    this.assetListSet = false;
    this.graphDataSet = false;
    this.meterAssetDataSet = false;
    this.virtualMeterDataSet = false;
  }

  loadGraph = (siteId, parentGraphId, callback) => {
    this.assetListSet = false;
    this.graphDataSet = false;
    this.virtualMeterDataSet = false;
    this.meterAssetDataSet = false;
    this.siteId = siteId;
    this.callback = callback;
    this.parentGraphId = parentGraphId;
    this.getEnergyAssetsList();
    this.getGraphData();
  }

  setPatchModelCallback = (callback) => {
    this.patchModelCallback = callback;
  }

  getEnergyAssetsList = () => {
    let jsonVector = [];
    api.get('SupportedAssets').then((result) => {
      const jsonEas = result.data;
      api.get('Catalogs/Vectors').then((result) => {
        jsonVector = result.data;
        let cnt = 0;
        jsonEas.forEach((jsonEa) => {
          jsonEa.index = cnt;
          cnt += 1;
          const inVectors = jsonEa.inFlow.vectors ? jsonEa.inFlow.vectors : [];
          inVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
          const outVectors = jsonEa.outFlow.vectors ? jsonEa.outFlow.vectors : [];
          outVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
        });
        //  const { callbackAssetList } = this.props;
        this.setEnergyAssetList(jsonEas, jsonVector);
        //  this.setEnergyAssetList(jsonEas);
      });
    });
  }

  setEnergyAssetList = (assetList, jsonVector) => {
    this.energyAsset = assetList;
    this.vectors = jsonVector;
    this.assetListSet = true;
    this.dataSettet();
  }

  getGraphData = () => {
    this.getMeterAssetsData();
    const { siteId } = this;
    this.asset = null;
    const newThis = this;
    api.get(`Sites/${siteId}/assetGraph`).then((result) => {
      if (result.data.length !== 0) {
        const parentId = result.data.id;
        api.get(`AssetGraphs/${parentId}/draft`).then((draftResult) => {
          newThis.setGraphData(draftResult.data);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              newThis.createSecondaryGraph(parentId, siteId);
            }
          }
        });
      }
    }).catch((error) => {
      if (error.response && error.response.status === 404) {
        newThis.createPrimaryGraph(siteId);
      }
    });
  }

  createSecondaryGraph = (parentId, siteId) => {
    const dt = {
      graphType: 'main',
      assetIdCounter: 0,
      flowIdCounter: 0,
      assets: [],
      assetGroups: [],
      assetFlows: [],
      parentId,
      siteId,
      draft: true,
    };
    api.post(`AssetGraphs/${parentId}/draft`, dt).then(() => {
      this.getGraphData();
    });
  }

  dataSettet = () => {
    if (!this.assetListSet || !this.graphDataSet || !this.meterAssetDataSet || !this.virtualMeterDataSet) {
      return;
    }
    let ret = {
      parentGraphData: this.parentGraphData,
      energyAsset: this.energyAsset,
      vectors: this.vectors,
      meterAsset: this.meterAsset,
      observedProperties: this.observedProperties,
      graphData: this.graphData,
      virtualMeter: this.virtualMeter,
    }
    this.callback(ret);
  }

  createPrimaryGraph = (siteId) => {
    const dt = {
      graphType: 'main',
      assetIdCounter: 0,
      flowIdCounter: 0,
      assets: [],
      assetGroups: [],
      assetFlows: [],
      parentId: null,
    };
    api.post(`Sites/${siteId}/assetGraph`, dt).then(() => {
      this.getGraphData();
    }).catch(() => { });
  }

  patch = (callback) => {
    let cb = typeof callback === 'function' ? callback : () => { };
    this.Api.patchGraphData(this.parentGraphData, cb);
  }

  setGraphData = (data) => {
    if (this.parentGraphId !== null && this.parentGraphId !== undefined) {
      this.graphData = data.assets.find(ass => ass.id === parseInt(this.parentGraphId, 10)).childGraph;
    } else {
      this.graphData = data;
    }
    this.parentGraphData = data;
    this.graphId = data.id;
    this.graphDataSet = true;
    this.dataSettet();
  }

  getMeterAssetsData = () => {
    const { siteId } = this;
    api.get(`Sites/${siteId}/fieldDevices`).then((result) => {
      const meterAssets = result.data;
      // const conditions = [];
      meterAssets.forEach((ma) => {
        // conditions.push(`{"fieldDeviceId": ${ma.id}}`);
        ma.observedProperties = [];
      });
      // const implodedConditions = conditions.join(', ');

      // api.get(`Sites/${siteId}/observedProperties?filter={"where": {"fieldDeviceId": {"inq": [${meterAssets.map(ma => ma.id)}]}}}`).then((result) => {
      api.get(`Sites/${siteId}/observedProperties?filter={"where": {"fieldDeviceId": {"neq": null}}}`).then((result) => {
        const observedProperties = result.data;
        observedProperties.forEach((observedProperty) => {
          const { fieldDeviceId } = observedProperty;
          const meterAsset = meterAssets.find(ma => ma.id === fieldDeviceId);
          if (meterAsset) {
            meterAsset.observedProperties.push(observedProperty);
          }
        });

        api.get(`Sites/${siteId}/observedProperties?filter={"where": {"virtualMeterId": {"neq": null}}}`).then((result) => {

          const allObservedProperties = result.data;
          this.getVirtualMetersData(allObservedProperties, () => {
            this.setMeterAssets(meterAssets, observedProperties);
          })
        })
      });
    });
  }


  getVirtualMetersData = (allObservedProperties, callback) => {
    const { siteId } = this;
    api.get(`VirtualMeters?filter={"where": {"siteId":${siteId}}}`).then((result) => {
      const virtualMeter = result.data;
      virtualMeter.forEach((vm) => {
        const observedProperty = allObservedProperties.find(op => op.virtualMeterId === vm.id)
        if (observedProperty) {
          //observedProperties.push(observedProperty);
          vm.id = `vm-${observedProperty.id}`;
        }
      })
      this.virtualMeter = virtualMeter;
      this.virtualMeterDataSet = true;
      callback();
    });
  }

  setMeterAssets = (meterAsset, observedProperties) => {
    this.meterAsset = meterAsset;
    this.observedProperties = observedProperties;
    this.meterAssetDataSet = true;
    this.dataSettet();
  }

  getMeterAsset = (nodeKey) => {
    const fieldDevicesList = [];
    const { assets, assetFlows } = this.graphData;
    const asset = assets.find(ass => ass.id === parseInt(nodeKey, 10));
    if (asset == null) {
      return null;
    }
    const measureAssetsList = [];
    const { measures, inFlowIds, outFlowIds } = asset;
    if (measures) {

      measures.forEach((meas) => {

        const ret = this.getMeasureData(meas);
        if (ret) {
          const { measure, fieldDeviceId } = ret;
          measure.direction = 'ASSET';
          measure.flowId = null;
          measureAssetsList.push(measure);
          if (fieldDeviceId != null && fieldDevicesList.indexOf(fieldDeviceId) < 0) {
            fieldDevicesList.push(fieldDeviceId);
          }
        }
      });
    }
    outFlowIds.forEach((flowId) => {
      const flow = assetFlows.find(af => af.id === flowId);
      if (flow) {
        const { sourceMeasures } = flow;
        if (sourceMeasures) {
          sourceMeasures.forEach((meas) => {
            if (meas.assetId === nodeKey) {
              const ret = this.getMeasureData(meas);
              if (ret) {
                const { measure, fieldDeviceId } = ret;
                measure.direction = 'OUT';
                measure.flowId = flowId;
                measureAssetsList.push(measure);
                if (fieldDevicesList.indexOf(fieldDeviceId) < 0) {
                  fieldDevicesList.push(fieldDeviceId);
                }
              }
            }
          });
        }
      }
    });

    inFlowIds.forEach((flowId) => {
      const flow = assetFlows.find(af => af.id === flowId);
      if (flow) {
        const { destinationMeasures } = flow;
        if (destinationMeasures) {
          destinationMeasures.forEach((meas) => {
            if (meas.assetId === nodeKey) {
              const ret = this.getMeasureData(meas);
              if (ret) {
                const { measure, fieldDeviceId } = ret;
                measure.direction = 'IN';
                measure.flowId = flowId;
                measureAssetsList.push(measure);
                if (fieldDevicesList.indexOf(fieldDeviceId) < 0) {
                  fieldDevicesList.push(fieldDeviceId);
                }
              }
            }
          });
        }
      }
    });
    return {
      fieldDevicesList,
      measureAssetsList,
    };
  }

  getMeasureData = (measure) => {
    const { observedPropertyId } = measure;
    const { observedProperties, virtualMeter } = this;
    let fieldDeviceId = null;
    let opId;
    if (typeof (measure.observedPropertyId) === "string" && measure.observedPropertyId.substring(0, 3) === "vm-") {
      measure.type = "vm";
    }
    if (measure.type && measure.type === "vm") {
      if (typeof (measure.observedPropertyId) !== "string" || measure.observedPropertyId.substring(0, 3) !== "vm-") {
        measure.observedPropertyId = `vm-${measure.observedPropertyId}`;
      }
      opId = parseInt(measure.observedPropertyId.substring(3, measure.observedPropertyId.length));
      const observedProperty = virtualMeter.find(obs => obs.id === measure.observedPropertyId);
      measure.vNk = observedProperty && observedProperty.physicalQuantity;
    } else {
      opId = measure.observedPropertyId;
      const observedProperty = observedProperties.find(obs => obs.id === observedPropertyId);
      if (!observedProperty) {
        return null;
      }
      measure.vNk = observedProperty.physicalQuantity;
      fieldDeviceId = observedProperty.fieldDeviceId
    }
    api.get(`/ObservedProperties/${opId}`).then(res => {
      if (res.data.formerVirtualMeterId != null) {
        measure = null
      }
    }).catch(err => {
      return err
    })
    return {
      measure,
      fieldDeviceId,
    };
  }

  setFlowAssetIcon = (nodo, flow) => {
    const { measureAssetsList } = nodo;
    for (let j = 0; j < flow.length; j += 1) {
      if (flow[j].from === nodo.key) {
        flow[j].visibleIconOut = false;
        flow[j].visibleMoreOut = false;
        flow[j].meterNumberOut = 0;
        flow[j].moreTextOut = "";
      } else if (flow[j].to === nodo.key) {
        flow[j].visibleIconIn = false;
        flow[j].visibleMoreIn = false;
        flow[j].meterNumberIn = 0;
        flow[j].moreTextIn = "";
      }
    }
    for (let i = 0; i < measureAssetsList.length; i += 1) {
      if (measureAssetsList[i].flowId == null) {
        continue;
      }
      const flowIndex = flow.findIndex(f => f.id === measureAssetsList[i].flowId);
      if (!flow[flowIndex]) {
        continue;
      }
      if (flow[flowIndex].from === nodo.key) {
        flow[flowIndex].meterNumberOut += 1;
        flow[flowIndex].visibleIconOut = true;
        flow[flowIndex].visibleMoreOut = flow[flowIndex].meterNumberOut > 1;
        flow[flowIndex].moreTextOut = "";
        if (flow[flowIndex].visibleMoreOut) {
          const number = flow[flowIndex].meterNumberOut - 1;
          flow[flowIndex].moreTextOut = `+ ${number}`;
        }
      } else if (flow[flowIndex].to === nodo.key) {
        flow[flowIndex].meterNumberIn += 1;
        flow[flowIndex].visibleIconIn = true;
        flow[flowIndex].visibleMoreIn = flow[flowIndex].meterNumberIn > 1;
        if (flow[flowIndex].visibleMoreIn) {
          const number = flow[flowIndex].meterNumberIn - 1;
          flow[flowIndex].moreTextIn = `+ ${number}`;
        }
      }
    }
  }

  setNodeMetersIcon = (nodo, flow) => {
    if (nodo.isGroup) {
      return nodo;
    }
    this.setFlowAssetIcon(nodo, flow);
    const { measureAssetsList } = nodo;
    nodo.visible_ma_panel = measureAssetsList.length > 0;
    nodo.visible_ma1 = false;
    nodo.visible_ma2 = false;
    nodo.visible_ma3 = false;
    nodo.visible_ma4 = false;
    nodo.visible_more = false;
    let cnt = 0;
    for (let i = 0; i < measureAssetsList.length; i += 1) {
      if (measureAssetsList[i].flowId != null) {
        continue;
      }
      cnt += 1;
      if (cnt <= 4) {
        const flowIndex = flow.findIndex(f => f.id === measureAssetsList[i].flowId);
        const mcount = parseInt(i, 10) + 1;
        nodo[`visible_ma${mcount}`] = true;
        if (flowIndex === -1) {
          nodo[`color_ma${mcount}`] = 'white';
        } else if (flow[flowIndex].vector !== undefined) {
          nodo[`color_ma${mcount}`] = flow[flowIndex].vector.type.color;
        } else {
          nodo[`color_ma${mcount}`] = flow[flowIndex].vectorItem.type.color;
        }

        nodo[`source_ma${mcount}`] = `${cGraph.symbol_path}dashboard.svg`;
      }
    }
    //cnt -= 1;
    if (cnt > 4) {
      nodo.visible_ma4 = false;
      nodo.visible_more = true;
      const more = cnt - 3;
      nodo.more = `+${more}`;
    }
    return nodo;
  }


  getAssetInOut = (assetId) => {
    const { assets } = this.parentGraphData;
    const { energyAsset } = this;
    const { supportedAssetId } = assets.find(ass => ass.id === parseInt(assetId, 10));
    const assetType = energyAsset.find(at => at.id === supportedAssetId);
    let { inFlow, outFlow } = assetType;
    inFlow = JSON.parse(JSON.stringify(inFlow));
    outFlow = JSON.parse(JSON.stringify(outFlow));
    return { inFlow, outFlow };
  }


  validGraph = (fcurrentGrpah, parentGraphId, model) => {
    let currentGrpah = fcurrentGrpah;
    if (currentGrpah === undefined) {
      currentGrpah = true;
    }
    const { nodeDataArray } = model;
    if (this.parentGraphId && parentGraphId === undefined) {
      return this.validGraph(currentGrpah, this.parentGraphId, model);
    }
    let inFlowDisp = null;
    let outFlowDisp = null;
    if (parentGraphId !== undefined) {
      const InOut = this.getAssetInOut(parentGraphId);
      inFlowDisp = InOut.inFlow;
      outFlowDisp = InOut.outFlow;
    }
    let result = true;
    const { energyAsset } = this;
    let { assets, assetFlows } = this.graphData;
    if (!currentGrpah) {
      const childAsset = assets.find(ass => ass.id === parentGraphId);
      ({ assets, assetFlows } = childAsset.childGraph);
    }
    for (let i = 0; i < assets.length; i += 1) {
      const assetType = energyAsset.find(at => at.id === assets[i].supportedAssetId);
      const naIndex = nodeDataArray.findIndex(nd => nd.key === parseInt(assets[i].id, 10));
      let isComplex = false;
      if (currentGrpah) {
        nodeDataArray[naIndex].border = 'black';
        nodeDataArray[naIndex].has_error = false;
        nodeDataArray[naIndex].error_message = '';
        ({ isComplex } = nodeDataArray[naIndex]);
      }
      let { min } = assetType.outFlow;
      if (min) {
        const tot = assets[i].outFlowIds.length;

        if (min > tot) {
          let error = true;
          if (outFlowDisp != null) {
            if (outFlowDisp.max && outFlowDisp.max > 0) {
              error = false;
              outFlowDisp.max -= 1;
            } else if (outFlowDisp.max === undefined) {
              error = false;
            }
          }
          if (error) {
            if (currentGrpah) {
              nodeDataArray[naIndex].border = 'red';
              nodeDataArray[naIndex].has_error = true;
              nodeDataArray[naIndex].error_message += i18n._(t` Il numero minimo di flussi in uscita è ${min}`);
            }
            result = false;
          }
        }
      }
      let { vectors } = assetType.outFlow;
      for (let k = 0; k < vectors.length; k += 1) {
        const vec = vectors[k];
        const minVec = vec.min;
        if (minVec && minVec > this.countFlowOfType(assets[i].outFlowIds, vec, assetFlows)) {
          if (currentGrpah) {
            nodeDataArray[naIndex].border = 'red';
            nodeDataArray[naIndex].has_error = true;
            let name = '';
            if (vec.vNK) {
              ({ name } = vec.detail);
            } else {
              const names = [];
              vec.details.forEach((detail) => {
                names.push(detail.name);
              });
              name = names.join(', ');
            }
            nodeDataArray[naIndex].error_message += i18n._(t` Il numero minimo di flussi in uscita di tipo ${name} è ${min}`);
          }
          result = false;
        }
      }

      ({ min } = assetType.inFlow);
      if (min) {
        const tot = assets[i].inFlowIds.length;
        if (min > tot) {
          let error = true;
          if (inFlowDisp != null) {
            if (inFlowDisp.max && inFlowDisp.max > 0) {
              error = false;
              inFlowDisp.max -= 1;
            } else if (inFlowDisp.max === undefined) {
              error = false;
            }
          }
          if (error) {
            if (currentGrpah) {
              nodeDataArray[naIndex].border = 'red';
              nodeDataArray[naIndex].has_error = true;
              nodeDataArray[naIndex].error_message += i18n._(t` Il numero minimo di flussi in ingresso è ${min}`);
            }
            result = false;
          }
        }
      }

      ({ vectors } = assetType.inFlow);
      for (let k = 0; k < vectors.length; k += 1) {
        const vec = vectors[k];
        const minVec = vec.min;
        if (minVec && minVec > this.countFlowOfType(assets[i].inFlowIds, vec, assetFlows)) {
          if (currentGrpah) {
            nodeDataArray[naIndex].border = 'red';
            nodeDataArray[naIndex].has_error = true;
            let name = '';
            if (vec.vNK) {
              ({ name } = vec.detail);
            } else {
              const names = [];
              vec.details.forEach((detail) => {
                names.push(detail.name);
              });
              name = names.join(', ');
            }
            nodeDataArray[naIndex].error_message += i18n._(t` Il numero minimo di flussi in ingresso di tipo ${name} è ${min}`);
          }
          result = false;
        }
      }
      if (currentGrpah && isComplex && !this.validGraph(false, nodeDataArray[naIndex].key, model)) {
        nodeDataArray[naIndex].border = 'red';
        nodeDataArray[naIndex].has_error = true;
        nodeDataArray[naIndex].error_message += i18n._(t` Controllare il sottografo prima di continuare`);
      }
    }
    /*if (!result && currentGrpah) {
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodeDataArray],
        linkDataArray: [...model.linkDataArray],
      });
      this.setState({
        model: model2,
        showError: i18n._(t` Ci sono degli errori nella validazione del grafo`),
      });
    }*/
    return { result, nodeDataArray };
  }

  generaAssetComplesso = (selectedNodeKeys, model) => {
    const { nodeDataArray, linkDataArray } = model;
    if (selectedNodeKeys.length === 1) {
      const nodeKey = selectedNodeKeys[0];
      const nodeIndex = nodeDataArray.findIndex(node => node.key === nodeKey);
      const node = JSON.parse(JSON.stringify(nodeDataArray[nodeIndex]));
      if (node.isComplex) {
        this.patchModelCallback({
          redirectTo: `/sites/${this.siteId}/management/editgraph/${nodeKey}`,
        });
      } else {
        const { assets } = this.graphData;

        const asset = assets.find(ass => ass.id === nodeKey);
        const newAsset = JSON.parse(JSON.stringify(asset));
        newAsset.id = 1;
        newAsset.groupIds = [];
        newAsset.inFlowIds = [];
        newAsset.measures = [];
        newAsset.outFlowIds = [];

        const assetSubGraph = {
          id: 2,
          assetIdCounter: 1,
          flowIdCounter: 0,
          assets: [],
          draft: true,
          graphType: 'sub',
        };
        asset.childGraph = assetSubGraph;
        this.Api.patchGraphData(this.parentGraphData, () => {
          this.patchModelCallback({
            redirectTo: `/sites/${this.siteId}/management/editgraph/${nodeKey}`,
          });
        });
      }
    } else if (selectedNodeKeys.length > 1) {
      const outFlow = [];
      const inFlow = [];
      for (let i = 0; i < selectedNodeKeys.length; i += 1) {
        const key = selectedNodeKeys[i];
        const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === key);
        const inFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.to === key);
        for (let j = 0; j < outFlowIndex.length; j += 1) {
          if (linkDataArray[outFlowIndex[j]].vectorItem !== undefined && outFlow.indexOf(linkDataArray[outFlowIndex[j]].vectorItem.naturalKey) === -1) {
            outFlow.push(linkDataArray[outFlowIndex[j]].vectorItem.naturalKey);
          }
        }
        for (let j = 0; j < inFlowIndex.length; j += 1) {
          if (linkDataArray[inFlowIndex[j]].vectorItem !== undefined && inFlow.indexOf(linkDataArray[inFlowIndex[j]].vectorItem.naturalKey) === -1) {
            inFlow.push(linkDataArray[inFlowIndex[j]].vectorItem.naturalKey);
          }
        }
      }

      const assetTypes = [];
      for (let i = 0; i < this.energyAsset.length; i += 1) {
        const inFlow2 = JSON.parse(JSON.stringify(inFlow));
        const outFlow2 = JSON.parse(JSON.stringify(outFlow));
        for (let j = 0; j < this.energyAsset[i].inFlow.vectors.length; j += 1) {
          const { vNK, vNKs } = this.energyAsset[i].inFlow.vectors[j];
          if (vNK) {
            const index = inFlow2.indexOf(vNK);
            if (index > -1) {
              inFlow2.splice(index, 1);
            }
          } else if (vNKs) {
            for (let k = 0; k < vNKs.length; k += 1) {
              const index = inFlow2.indexOf(vNKs[k]);
              if (index > -1) {
                inFlow2.splice(index, 1);
              }
            }
          }
        }
        for (let j = 0; j < this.energyAsset[i].outFlow.vectors.length; j += 1) {
          const { vNK } = this.energyAsset[i].outFlow.vectors[j];
          const index = outFlow2.indexOf(vNK);
          if (index > -1) {
            outFlow2.splice(index, 1);
          }
        }
        if (inFlow2.length === 0) {
          assetTypes.push(JSON.parse(JSON.stringify(this.energyAsset[i])));
        }
        // const { model } = this.state;
        const model2 = go.GraphObject.make(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort',
          linkToPortIdProperty: 'toPort',
          nodeDataArray: [...model.nodeDataArray],
          linkDataArray: [...model.linkDataArray],
        });
        this.patchModelCallback({
          model: model2,
          assetTypesForComplex: assetTypes,
        });
      }
    }
  }

  countFlowOfType = (flowIds, currentVector, assetFlows) => {
    let count = 0;
    for (let i = 0; i < flowIds.length; i += 1) {
      const { vector } = assetFlows.find(af => af.id === flowIds[i]);
      if (currentVector.vNK && currentVector.vNK === vector) {
        count += 1;
      }
      if (currentVector.vNKs && currentVector.vNKs.indexOf(vector) >= 0) {
        count += 1;
      }
    }
    return count;
  }
}

export default Loader;
