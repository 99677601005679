import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import styled from '@emotion/styled';
import ReactExport from 'react-data-export';
import { CSVLink } from 'react-csv';
import { IconButton } from 'app/common';
const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ModalStyled = styled(Modal)`
  width: 320px;
  height: 300px;
`;

const Option = styled.div`
  align-items: center;
  border-top: 1px solid #c8ced3;
  padding: 0.75rem;
  display: flex;
  color: #212529;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const DataExport = props => {
  const {
    title,
    excelData,
    csvHeaders,
    fileName,
    csvData,
    dismiss,
    isOpen,
    exportPng,
    exportSvg,
    exportJpeg
  } = props;
  return (
    <ModalStyled isOpen={isOpen}>
      <ModalHeader style={{ padding: '0.5rem 0.5rem', fontSize: '0.75em' }} toggle={dismiss}>
        {title}
      </ModalHeader>
      <ModalBody scroll>
        <h6>Seleziona il tipo di file da esportare</h6>
        <ExcelFile
          filename={fileName}
          element={
            <Option onClick={dismiss}>
              <IconButton icon="file-excel" />
              Excel
            </Option>
          }
        >
          <ExcelSheet data={excelData} name="data">
            {csvHeaders.map(element => (
              <ExcelColumn
                key={`${element.key}`}
                label={i18n._(t`${element.label}`)}
                value={`${element.key}`}
              />
            ))}
          </ExcelSheet>
        </ExcelFile>
        {csvData && csvHeaders && Array.isArray(csvHeaders) && Array.isArray(csvData) && (
          <CSVLink data={csvData} headers={csvHeaders} filename={`${fileName}.csv`}>
            <Option onClick={dismiss}>
              <IconButton icon="file-csv" />
              CSV
            </Option>
          </CSVLink>
        )}
        <Option onClick={exportJpeg}>
          <IconButton icon="file-image" />
          JPEG
        </Option>
        <Option onClick={exportPng}>
          <IconButton icon="file-image" />
          PNG
        </Option>
        <Option onClick={exportSvg}>
          <IconButton icon="file-image" />
          SVG
        </Option>
      </ModalBody>
      <ModalFooter style={{ padding: '1rem', border: '0px' }}></ModalFooter>
    </ModalStyled>
  );
};

export default DataExport;
