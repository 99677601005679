import React, { useEffect, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { lastValueUpdated, rateGreaterThanAggregation } from '../common';
import styled from 'styled-components';
import logger from 'app/common/log';
import { numberToLocaleString } from 'app/utils/formatNumber';

const StyledTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #fff;
    box-shadow: 2px 2px 4px gray;
    color: grey;
    max-width: 20rem;
  }

  & .tooltip.show {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  background-color: ${props => props.color};
  border: 0.1rem solid #fff;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${props => `${props.fontSize}`};
  width: 100%;
  height: 100%;
`;

const Display = props => {
  const { width, height, data, shouldUpdate, dataLength, direction } = props;
  const { value } = data;
  const [valueTxt, setValueTxt] = useState('');
  const [labelTxt, setLabelTxt] = useState('');
  const [unitTxt, setUnitTxt] = useState('');
  const [colorTxt, setColorTxt] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [topMargins, setTopMargins] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipId] = useState(() => `display-${uuidv4()}`);

  useEffect(() => {
    updateSize();
    // window.addEventListener('resize', updateSize);
  }, [width, height]);

  useEffect(() => {
    updateData();
  }, [width, height, data, shouldUpdate]);

  const fontWidthCalc2 = (refFontSize, size, maxSize) => {
    const fontSize = (refFontSize * size) / maxSize;
    return fontSize < 1 ? 1 : fontSize > 4 ? 4 : fontSize;
  };

  const fontSizeCalc = ({ k, maxFont, minFont, size, maxSize }) => {
    const fontSize = (k * size) / maxSize;
    return fontSize < minFont ? minFont : fontSize > maxFont ? maxFont : fontSize;
  };

  const getSize = (width, height, dataLength, direction) => {
    if (dataLength === 1) {
      return width;
    }
    return direction === 'row' ? width / dataLength : Math.min(height, width);
  };

  const updateSize = () => {
    const { width, height } = props;
    const margins = { top: 0, right: 3, bottom: 3, left: 3 };
    const chartWidth = width - margins.left - margins.right;
    let fontSize = 16;

    const size = chartWidth;
    const fontSizeConstant = 9;

    const heightRatio = height / window.innerHeight;

    logger.debug('width/height: %s fontSize: %s ', size, fontSize);
    console.log(
      'height heightRatio innerWidth innerHeight ',
      heightRatio,
      height,
      window.innerWidth,
      window.innerHeight
    );

    fontSize = fontWidthCalc2(fontSizeConstant, width, window.innerWidth);

    const _size = getSize(width, height, dataLength, direction);

    const MAX_FONT_SIZE = 4;
    const MIN_FONT_SIZE = 1;
    fontSize = fontSizeCalc({
      k: fontSizeConstant,
      maxFont: MAX_FONT_SIZE,
      minFont: MIN_FONT_SIZE,
      size: _size,
      maxSize: window.innerWidth
    });

    const valueMargin = fontSize === MAX_FONT_SIZE ? -1 : -0.5 * fontSize;
    const labelMargin = fontSize === MAX_FONT_SIZE ? -5 : -0.5 * fontSize;
    setFontSize(`${fontSize}vw`);
    setTopMargins({ value: valueMargin, label: labelMargin });
  };

  const updateData = () => {
    const { period, data } = props;
    const { _unit, value, label, color, rate, valueType, physicalQuantity, decimalDigits } = data;
    const { interval, aggregation } = period;
    const isBool =
      valueType === 'binary' || physicalQuantity === 'state' || physicalQuantity === 'event';
    const greaterBtwRateAndAgg = rate ? rateGreaterThanAggregation(rate, aggregation) : aggregation;
    const oldValue = !isBool && lastValueUpdated(interval, greaterBtwRateAndAgg, value);

    const { v } = value;
    logger.debug('Display:updateData v: %s %d', v, decimalDigits);
    const localeValue = numberToLocaleString(v, decimalDigits);
    const newValue = v && (oldValue ? `*${localeValue}` : localeValue);

    setValueTxt(newValue);
    setUnitTxt(_unit);
    setLabelTxt(label);
    setColorTxt(color);
  };

  return (
    <Wrapper fontSize={fontSize} color={colorTxt}>
      <div id={tooltipId} style={{ alignSelf: 'center', marginTop: `${topMargins.value}vh` }}>
        <span style={{ margin: '0.5em 0.125em 0.5em 0.125em', fontSize: '1em' }}>{valueTxt}</span>
        <span style={{ margin: '0.125em 0.5em 0.5em 0.125em', fontSize: '0.75em' }}>{unitTxt}</span>
      </div>
      <div
        style={{
          fontSize: '0.6em',
          marginTop: `${topMargins.label}vh`,
          textAlign: 'center'
        }}
      >
        {labelTxt}
      </div>
      <StyledTooltip
        placement="top-start"
        isOpen={showTooltip}
        target={tooltipId}
        toggle={() => setShowTooltip(show => !show)}
      >
        {`Valore del ${moment(value.t).format('DD/MM/YYYY, HH:mm:ss')}`}
      </StyledTooltip>
    </Wrapper>
  );
};

export default Display;
