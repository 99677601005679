/* Author: Stefano Chessa */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, ButtonGroup } from 'reactstrap';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Button, DataTable } from 'app/common';
import api from 'api';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Views = Object.freeze({
  FD: Symbol('fd'),
  GW: Symbol('gw'),
  VM: Symbol('vm')
});

/** Devo mostrare l'UUID dalle variabili di un dato dominio/sito */
const UUIDList = (props) => {

  const { match, observableproperties, sites, virtualmeters} = props;
  const { type, id, subtype, subid } = match.params;

  const [view, setView] = useState(Views.FD);
  const [modal, setModal] = useState(false);
  const [modalOp, setModalOp] = useState(false);
  const [modalVm, setModalVm] = useState(false);
  const [fdData, setFdData] = useState({});
  const [opData, setOpData] = useState({});
  const [vmData, setVmData] = useState({});

  let content;

  useEffect(() => {
    switch (view) {
      case view === Views.FD:
        Table('fd');
        break;
      case view === Views.GW:
        Table('gw');
        break;
      case view === Views.VM:
        Table('vm');
        break;
      default:
        break;
    }

  }, [type, id, subtype, subid]);

  const toggle = () => { setModal(!modal) };
  const toogleOp = () => { setModalOp(!modalOp) };
  const toogleVm = () => { setModalVm(!modalVm) };

  const Table = (input) => {

    let data;
    let custom_property;
    let custom_title;

    switch (input) {
      case 'fd':
        custom_property = 'fieldDeviceId';
        custom_title = 'Nome dispositivo di campo';
        data = observableproperties.filter(el => {
          if (el.fieldDeviceId && el.fieldDeviceId > 0)
            return el;
          return null;
        }).map(op => ({...op, updatedInfo: `${op.updater.name} ${new Date(op.updated).toLocaleString()}`}));
        //console.log("fd", data);
        break;
      case 'gw':
        //non viene usata
        custom_property = 'fieldDeviceId';
        custom_title = 'Field Device Id';
        data = observableproperties;
        break;
      case 'vm':
        custom_property = 'virtualMeterId';
        custom_title = 'Id misuratore virtuale';
        data = observableproperties.filter(el => {
          if (el.virtualMeterId && el.virtualMeterId > 0)
            return el;
          return null;
        }).map(vm => ({...vm, updatedInfo: `${vm.updater.name} ${new Date(vm.updated).toLocaleString()}`}));
        //console.log("vm", data);
        break;
      default:
        break;
    }

    return (
      <DataTable
        keyField="id"
        search
        data={data}
        width="100%"
        pagination={{
          enabled: true,
          pageLength: 20
        }}
        sortby="name"
        headers={[
          { title: <Trans>id</Trans>, property: 'id', index: 1, width: '5%', dataType: 'string' },
          {
            title: <Trans>Nome</Trans>,
            property: 'name',
            index: 2, width: '25%',
            dataType: 'string',
            canSearch: true,
            cell: row => {
              return (<Link onClick={() => {
                setOpData(row);
                toogleOp();
              }}>
                {row.name}
              </Link>
              )
            }
          },
          {
            title: <Trans>IoTUID</Trans>,
            property: 'IoTUID',
            width: '25%',
            index: 3,
            canSearch: true,
            cell: row => {
              if (row.IoTUID)
                return (
                  <span>
                    {row.IoTUID}
                  </span>
                );
              else
                return <span>N/A</span>
            }
          },
          {
            title: custom_title,
            property: custom_property,
            width: '20%',
            index: 4,
            cell: row => {
              switch (input) {
                case "fd":
                  if (row[custom_property])
                    return (
                      <Link onClick={async () => {
                        try {
                          let field_gateway_id = await api.get('/FieldGateways/' + row['fieldDevice']['fieldGatewayId']);
                          field_gateway_id = field_gateway_id['data']['gatewayId'];

                          const gateway = await api.get('/Gateways/' + field_gateway_id);
                          row['fieldDevice']['gateway'] = {
                            'gatewayId': field_gateway_id,
                            'IoTUID': gateway['data'].IoTUID,
                            'model': gateway['data'].model,
                            'productCode': gateway['data'].productCode,
                            'serialNumber': gateway['data'].serialNumber,
                            'make': gateway['data'].make
                          };
                        } catch (e) {
                          console.log('Error, getGatewayInfo, ', e);
                        }
                        setFdData(row['fieldDevice']);
                        toggle();
                      }}>
                        {row['fieldDevice']['name']}
                      </Link>);
                  else
                    return <span>N/A</span>
                case "vm":
                  if (row[custom_property])
                    return (<Link onClick={() => {
                      const vm = virtualmeters.find(x => x.id === row.virtualMeterId)
                      if (vm) {
                        setVmData(vm);
                        toogleVm();
                      }
                    }}>
                      {row[custom_property]}
                    </Link>);
                  else
                    return <span>N/A</span>
                default:
                  break;
              }
            }
          },
          {
            title: <Trans>Impianto</Trans>,
            property: 'siteId',
            index: 5,
            dataType: 'string',
            canSearch: true,
            cell: row => {
              const site = sites.find(x => x.id === row.siteId);
              if (site)
                return site.name;
              else
                return 'N/A';
            }
          },
          { title: <Trans>Ultima modifica</Trans>, property: 'updatedInfo', dataType: 'lastupdate', index: 6, canSearch: true, disableSort: true }
        ]}
        noData={<Trans>Nessun dato trovato.</Trans>}
      />
    );
  }

  switch (view) {
    case Views.FD:
      content = Table('fd');
      break;
    case Views.GW:
      content = Table('gw');
      break;
    case Views.VM:
      content = Table('vm');
      break;
    default:
      break;
  }

  return (
    <Card>
      <CardHeader>
        <ButtonContainer>
          <Trans>Recupera UUID</Trans>
          <ButtonGroup>
            <Button size="sm" active={view === Views.FD} onClick={() => setView(Views.FD)}>
              Op dispositivi di campo
            </Button>
            <Button size="sm" active={view === Views.VM} onClick={() => setView(Views.VM)}>
              Op misuratori virtuali
            </Button>
          </ButtonGroup>
        </ButtonContainer>
      </CardHeader>
      <CardBody>
        {content}
        <Modal isOpen={modalOp} toggle={() => toogleOp()} size="xl">
          <ModalHeader>
            Info aggiuntive sulla OP
          </ModalHeader>
          <ModalBody>
            <p>
              <pre>
                {JSON.stringify(opData, null, 2) || 'N/A'}
              </pre>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => toogleOp()}>Chiudi</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal} toggle={() => toggle()} size="lg">
          <ModalHeader>
            Info aggiuntive sul dispositivo di campo
          </ModalHeader>
          <ModalBody>
            <p>
              <pre>
                {JSON.stringify(fdData, null, 2) || 'N/A'}
              </pre>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => toggle()}>Chiudi</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalVm} toggle={() => toogleVm()} size="lg">
          <ModalHeader>
            Info aggiuntive sul misuratore virtuale
          </ModalHeader>
          <ModalBody>
            <p>
              <pre>
                {JSON.stringify(vmData, null, 2) || 'N/A'}
              </pre>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => toogleVm()}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = (state) => {
  const { observableproperties, virtualmeters } = state.domain;
  const { selectedDomain, selectedCompany, selectedSite, sites } = state.navigation;
  return { selectedDomain, selectedCompany, selectedSite, sites, observableproperties, virtualmeters };
};

export default connect(mapStateToProps)(UUIDList);