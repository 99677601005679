import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'app/i18n';
import PropTypes from 'prop-types';
import api from 'api';
import { Button, DynamicForm } from 'app/common';

const ShareModal = (props) => {
  const {
    isOpen,
    header,
    toggleModal,
    report,
    distributionListOptions,
    refreshReports,
  } = props;

  const currentUserTag = [{
    userInfoId: report.updater.id,
    name: report.updater.name
  }];

  const [error, setError] = useState(null);
  const [personalInstance, setPersonalInstance] = useState(report.personalInstance);

  const formRef = React.createRef();

  const onShare = () => {
    const formData = formRef.current.getValues();
    if (report) {
      // Update report and reload grid
      const newReport = { ...report, ...formData };
      api.patch(`/Reports/${newReport.id}`, newReport).then((result) => {
        if (refreshReports) {
          refreshReports(result); // refresh reports grid event
        }
        setError(null);
      }).catch(err => {
        const errorMessage = 'Errore aggiornamento report';
        setError(errorMessage);
        toast.error(errorMessage);
      });
    }

  };

  const resetState = () => {
    setError(null);
  };

  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={toggleModal} onOpened={resetState}>
      <ModalHeader toggle={toggleModal}>
        {header}
      </ModalHeader>
      <ModalBody>
        <DynamicForm
          hideButtons
          ref={formRef}
          label={ t`Condividi report` }
          fields={[
            {
              name: 'personalInstance',
              type: 'select',
              label: <Trans>Visibilità</Trans>,
              options: [
                { value: false, label: i18n._(t`Report condiviso`) },
                { value: true, label: i18n._(t`Report privato`) }
              ],
              validation: { required: true },
              nullable: false,
              canSearch: false,
              disabled: (report.personalInstance === false),
              onChange: (value) => {
                setPersonalInstance(value);
              }
            },
            {
              name: 'distributionList',
              type: 'tags-report',
              label: i18n._(t`Destinatari per invio report via email`),
              options: (distributionListOptions && !personalInstance) ? distributionListOptions : currentUserTag,
              keyProperty: 'userInfoId',
              labelProperty: 'name',
              emailTextTags: true,
              canChangeText: !personalInstance,
              mustSetDefaultValue: true,
              defaultValue: (personalInstance) ? currentUserTag : report.distributionList
            },
          ]}
          initialValues={{
            personalInstance: personalInstance,
            distributionList: report.distributionList
          }}
          error={error}
          onCancel={toggleModal}
        />
      </ModalBody>
      <ModalFooter>
        <Button marginright="true" color="success" onClick={onShare}><Trans>Salva</Trans></Button>
        <Button color="link" onClick={toggleModal}><Trans>Annulla</Trans></Button>
      </ModalFooter>
    </Modal>
  );
};

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  children: PropTypes.element,
  toggleModal: PropTypes.func.isRequired,
};

ShareModal.defaultProps = {
  children: null,
  askPassword: false,
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(ShareModal);
