import React from 'react';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & input {
    position: relative;
    margin: 0 .5rem 0 0;
  }

  & > label {
    margin-bottom: 0;
  }
`;

const Checkbox = (props) => {
  const { name, checked, onChange, label, ...rest } = props;
  return (
    <Wrapper>
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={name}
        name={name}
        {...rest}
      />
      <Label htmlFor={name}>{label}</Label>
    </Wrapper>
  );
};

export default Checkbox;
