import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { DynamicForm, Spinner } from 'app/common';
import { distinct } from 'app/utils/array';
import api from 'api';
import { getUrlFromPath } from 'app/utils/navigation';
import { Trans, t } from '@lingui/macro';
import { Roles } from 'app/utils/auth';
import TemplatesContext from '../TemplatesContext';

const EditSupportedDevice = props => {
  const {
    isNew,
    match,
    history,
    selectedDomain,
    userSystemRoles,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [supportedDevice, setSupportedDevice] = useState(undefined);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [productCodeList, setProductCodeList] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("fromFilter");

  const {
    supportedDevices,
    onEditSupportedDevice,
    templates
  } = useContext(TemplatesContext);

  const updateMakeList = () => {
    setMakeList(distinct(supportedDevices.map(x => x.make)));
  };

  const updateModelList = make => {
    setModelList(distinct(supportedDevices.filter(x => x.make === make).map(x => x.model)));
  };

  const updateProductCodeList = (make, model) => {
    setProductCodeList(
      distinct(
        supportedDevices.filter(x => x.make === make && x.model === model).map(x => x.productCode)
      )
    );
  };

  const loadData = async () => {
    if (isNew) {
      return;
    }

    const { deviceid } = match.params;
    const selectedDevice = supportedDevices && supportedDevices.find(x => x.id === +deviceid);
    console.log('selectedDevice ', selectedDevice);
    setSupportedDevice(selectedDevice);

    if (selectedDevice) {
      updateModelList(selectedDevice.make);
      updateProductCodeList(
        selectedDevice.make,
        selectedDevice.model
      );
    }
  };

  useEffect(() => {
    updateMakeList();
  }, [supportedDevices]);

  useEffect(() => {
    loadData();
  }, [supportedDevices]);

  const submitDevice = async (device) => {
    setLoading(true);
    try {
        const { make, model, productCode, _systemDevice } = device;
        const domainId = _systemDevice ? null : selectedDomain.id;
        const res = await api.patch(`/SupportedDevices/${selectedDevice.id}`, { make, model, productCode, domainId });
        onEditSupportedDevice();
        setLoading(false);
        history.push(getUrlFromPath('/config/templates' + (filter ? ("?filter=" + filter) : "") + '#' + res.data.id));
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const cancel = () => {
      history.push(getUrlFromPath('/config/templates' + (filter ? ("?filter=" + filter) : "") + '#' + supportedDevice.id));
  };

  const fields = [
    {
      name: '_systemDevice',
      type: 'checkbox',
      label: <Trans>Template di sistema</Trans>,
      hidden: () => !userSystemRoles.includes(Roles.SYS) || ( supportedDevice && templates && templates.find(temp => temp.supportedDevice.id === supportedDevice.id &&temp.domainId === null ))
    },
    {
      name: 'make',
      type: 'text',
      label: <Trans>Marca</Trans>,
      validation: { required: true },
      suggestions: makeList,
      onChange: (value, values) => {
        updateModelList(value);
        updateProductCodeList(null, null);
        values.model = '';
        values.productCode = '';
      }
    },
    {
      name: 'model',
      type: 'text',
      label: <Trans>Modello</Trans>,
      validation: { required: true },
      suggestions: modelList,
      canSearch: true,
      onChange: (value, values) => {
        updateProductCodeList(values.make, value);
        values.productCode = '';
      }
    },
    {
      name: 'productCode',
      type: 'text',
      label: <Trans>Codice prodotto</Trans>,
      suggestions: productCodeList,
      canSearch: true
    }
  ];

  const { deviceid } = match.params;
  const selectedDevice = supportedDevices && supportedDevices.find(x => x.id === +deviceid);

  return (
    <>
      {loading && <Spinner />}
        <DynamicForm
            card
            title={supportedDevice ? <Trans>Modifica {supportedDevice.make}</Trans> : <Trans>Modifica</Trans>}
            fields={fields}
            initialValues={supportedDevice}
            error={error}
            onSubmit={submitDevice}
            onCancel={cancel}
        />
    </>
  );
};

const mapStateToProps = state => {
  const { selectedDomain, type, id } = state.navigation;
  const { userSystemRoles, userCurrentRoles } = state.auth;
  const { catalogs } = state;
  return {
    selectedDomain,
    catalogs,
    type,
    id,
    userSystemRoles,
    userCurrentRoles
  };
};

export default connect(mapStateToProps)(EditSupportedDevice);
