import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem } from '../Dropdown';

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  height: 100%;
`;

const NoBorderInput = styled.input`
  &&& {
    width: 2.5rem;
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    padding-left: .75rem;
    box-shadow: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 4rem;
    max-width: 4rem;
    /* top: 2.5rem; */
  }
`;

const DayPicker = (props) => {
  const { allYearLabel, value, days, onChange, dropdownOpen, setDropdownOpen, className, ...rest } = props;

  const [inputText, setInputText] = useState();
  const [highlightedOption, setHighlightedOption] = useState(null);

  const componentRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputText(value);
  }, [value]);


  const findOption = (text) => {
    if (text) {
      return days.find(x => x.toString().startsWith(text));
    }
    return null;
  };

  const highlightItem = (item) => {
    setHighlightedOption(item);
    const element = document.getElementById(item);
    element.scrollIntoView();
  };

  useEffect(() => {
    if (dropdownOpen) {
      inputRef.current.focus();
      if (inputText) {
        const bestOption = findOption(inputText);
        if (bestOption) {
          highlightItem(bestOption);
        }
      }
    } else {
      setHighlightedOption(null);
    }
  }, [dropdownOpen, inputText]);


  const closeDropdown = () => {
    setDropdownOpen(false);
    if (!days.find(x => x.toString() === inputText)) {
      setInputText(value);
    } else if (value.toString() !== inputText) {
      onChange(+inputText);
    }
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useOnClickOutside(componentRef, () => {
    if (dropdownOpen) {
      closeDropdown();
    }
  });


  const selectItem = (day) => {
    onChange(day);
    closeDropdown();
  };

  const handleKey = (e) => {
    const { length } = days;
    const index = days.indexOf(highlightedOption);

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        highlightItem(index >= 0 ? days[(index + length - 1) % length] : days[length - 1]);
        break;
      case 'ArrowDown':
        e.preventDefault();
        highlightItem(index >= 0 ? days[(index + 1) % length] : days[0]);
        break;
      case 'Tab':
      case 'Enter':
        if (highlightedOption) {
          selectItem(highlightedOption);
          closeDropdown();
        }
        break;
      case 'Escape':
        closeDropdown();
        break;
      default:
    }
  };

  const focus = () => {
    setDropdownOpen(true);
    inputRef.current.focus();
    inputRef.current.select();
  };

  const changeText = (e) => {
    const newInputText = e.target.value;
    setInputText(newInputText);
    setDropdownOpen(true);
  };


  return (
    <Wrapper ref={componentRef} className={className}>
      <NoBorderInput className="form-control" ref={inputRef} value={inputText} onChange={changeText} onFocus={focus} onKeyDown={handleKey} {...rest} />
      {dropdownOpen && (
        <StyledDropdown className="dropdown-menu show" ref={dropdownRef}>
          {days.map(day => (
            <DropdownItem
              id={day}
              type="button"
              className="dropdown-item"
              key={day}
              value={day}
              onClick={() => selectItem(day)}
              onMouseOver={() => setHighlightedOption(day)}
              onFocus={() => {}}
              active={highlightedOption === day}
            >
              {day}
            </DropdownItem>
          ))}
        </StyledDropdown>
      )}
    </Wrapper>
  );
};


DayPicker.propTypes = {
  value: PropTypes.number.isRequired,
  days: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DayPicker.defaultProps = {
  className: undefined,
};

export default DayPicker;
