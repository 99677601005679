import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import TreeView from './TreeView';

const Container = styled.div`
  background-color: #fff;
  min-height: 25rem;
  position: relative;
  max-height: 50vh;

  ${props => props.scroll && `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
      background: ${props.theme.navbar.borderColor}; 
    }
  `}
`;

const EmptyLabel = styled.p`
  padding: .2rem;
  margin-left: 1.5rem;
  color: ${props => props.theme.common.text.siteGroupColor};
`;

const options = {
    keyProperty: 'name',
};

const AlarmsNodesTree = (props) => {
    const { selectedDomain, companies, sites, sitegroups, scroll, className, count, structure, openNode, openNodes, onLink } = props;

    const { groupedLeaves } = useMemo(() => {
        const groupedLeaves = {};

        sites.forEach(site => {
            const key = `domain_${selectedDomain.id}/company_${site.companyId}/site_${site.id}`;
            if (!groupedLeaves[key]) {
                groupedLeaves[key] = [];
            }
        });
        sitegroups.forEach(sitegroup => {
            const key = `domain_${selectedDomain.id}/company_${sitegroup.companyId}/sitegroup_${sitegroup.id}`;
            if (!groupedLeaves[key]) {
                groupedLeaves[key] = [];
            }
        });
        companies.forEach(company => {
            const key = `domain_${selectedDomain.id}/company_${company.id}`;
            if (!groupedLeaves[key]) {
                groupedLeaves[key] = [];
            }
        });
        const key = `domain_${selectedDomain.id}`;
        if (!groupedLeaves[key]) {
            groupedLeaves[key] = [];
        }
        return { groupedLeaves };
    }, [selectedDomain]);

    return (
        <Container scroll={scroll} className={className}>
            {count > 0
                ? <TreeView options={options} structure={structure} leaves={groupedLeaves} openNodes={openNodes} open={openNode} isAlarm onLink={onLink} />
                : <EmptyLabel><Trans>Nessun allarme attivo nel periodo selezionato</Trans></EmptyLabel>}
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { companies, sites, sitegroups, selectedDomain } = state.navigation;
    return {
        companies,
        sites,
        sitegroups,
        selectedDomain
    };
};

export default connect(mapStateToProps)(AlarmsNodesTree);
