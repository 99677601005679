import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getObjectWithoutKeys } from 'app/utils/object';
import SerialInterface from './SerialInterface';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';


const BottomLabel = styled.div`
  padding-left:1rem;
  padding-top:0.5rem;
`;


class FieldInterfaces extends Component {
  constructor(props) {
    super(props);

    const { fieldGateway } = props;
    this.state = {
      // all the available interfaces for the selected serial
      availableInterfaces: fieldGateway.gateway.configurationOptions && fieldGateway.gateway.configurationOptions.serialInterfaces ? fieldGateway.gateway.configurationOptions.serialInterfaces.map(item => ({ item, ref: React.createRef() })) : [],
    };
  }


  getSerialInterfaces = async (data) => {
    const { availableInterfaces } = this.state;
    const promises = availableInterfaces.map(item => item.ref.current.getValues(data));
    try {
      const values = await Promise.all(promises);
      if (values.some(x => x === null)) {
        return null;
      }
      const serialInterfaces = values.filter(x => x.enabled).map(x => getObjectWithoutKeys(x, 'enabled'));
      return { serialInterfaces };
    } catch (e) {
      return null;
    }
  }

  getGreenstickConfig = () => {
    const { fieldGateway } = this.props;
    const { configurationOptions } = fieldGateway.gateway;
    if (configurationOptions) {
      const { internalGSMeter, internalLVPPole } = configurationOptions;
      if (internalGSMeter) {
        return { internalGSMeter: { stickBaudRate: internalGSMeter.stickBaudRate.value } };
      } else if (internalLVPPole) {
        return { internalLVPPole: { mpuType: internalLVPPole.mpuType.value } };
      }
    }
    return null;
  }

  getValues = async (data) => {
    const serialInterfacesConfig = await this.getSerialInterfaces(data);
    if (!serialInterfacesConfig) {
      throw new Error();
    }
    return { configuration: { ...serialInterfacesConfig, ...this.getGreenstickConfig() } };
  }


  render() {
    const { fieldGateway, readOnly, isNew } = this.props;
    const { availableInterfaces } = this.state;

    const serialInterfaces = fieldGateway && fieldGateway.configuration ? fieldGateway.configuration.serialInterfaces : [];

    return (
      <>
      <div>
        {availableInterfaces.map((serialInterface, index) => {
          const initialValues = serialInterfaces.find(x => x.portLabel === serialInterface.item.portLabel.value);
          return (
            <div data-index={index}>
              <SerialInterface readOnly={readOnly} isNew={isNew} serialInterface={serialInterface.item} initialValues={initialValues} key={serialInterface.item.portLabel} ref={serialInterface.ref} />
            </div>
          );
        })}
      </div>
      <BottomLabel>
        <Trans>NB: la configurazione non è necessaria per dispositivi connessi via TCP/IP</Trans>
      </BottomLabel>
    </>
    );
  }
}

FieldInterfaces.propTypes = {
  fieldGateway: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FieldInterfaces.defaultProps = {
  readOnly: false,
};

export default FieldInterfaces;
