import React from 'react';
import styled from 'styled-components';


const BrandWrapper = styled.div`
  flex-shrink: 0;
  width: ${props => (props.compact ? props.theme.sidebar.compactWidth : props.theme.sidebar.width)};
  background-color: ${props => props.theme.navbar.brandBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;

  @media (max-width: 992px) {
    display: none;
  }
`;

const NavbarBrand = (props) => {
  const { compact, logo, smallLogo, altText } = props;
  return (
    <BrandWrapper compact={compact}>
      {!compact && <img src={logo} width={140} alt={altText} />}
      {compact && <img src={smallLogo} width={32} height={32} alt={altText} />}
    </BrandWrapper>
  );
};

export default NavbarBrand;
