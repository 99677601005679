import React from 'react';
import styled from 'styled-components';
import { ButtonGroup } from 'reactstrap';
import { Button } from 'app/common';

const TabGroup = styled(ButtonGroup)`
  float: right;
  margin-top: 3px;
  margin-right: 5px;
`;
const TabButton = styled(Button)`
  && {
    text-transform: none;
    font-weight: 600;
    font-size: 0.8rem;
  }
`;

const SwitchButtons = props => {
  const { actives, labels, onClick1, onClick2 } = props;
  const voidFn = () => null;
  return (
    <>
      <TabGroup>
        <TabButton onClick={actives[0] ? voidFn : onClick1} active={actives[0]}>
          {labels[0]}
        </TabButton>
        <TabButton onClick={actives[1] ? voidFn : onClick2} active={actives[1]}>
          {labels[1]}
        </TabButton>
      </TabGroup>
    </>
  );
};

export default SwitchButtons;
