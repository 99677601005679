import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
    Row, Col, CardHeader, Card, CardBody, ButtonGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import api from 'api';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { isEqual, update } from 'lodash';

import { addReader, removeReader } from 'ducks/actions/catalogs';
import { changeAlarmFilters } from 'ducks/actions/preferences';
import { translateDetail, translateTags, translateType } from '../helpers/CatalogsTranslate';
import EventDetails from '../helpers/EventDetails';
import ModalConfirm from '../helpers/ModalConfirm';
import { DataTable, Button, IconButton, Select, MultiSelectTag, Tag, CalendarOptions, Spinner } from 'app/common';
import Alarms from 'app/pages/alarms/Alarms';
import { getUrlFromPath } from 'app/utils/navigation';
import { MemoryRouter } from 'react-router-dom';


const TabGroup = styled(ButtonGroup)`
    float: right; 
    margin-top: 3px;
    margin-right: 5px;
`;
const TabButton = styled(Button)`
  && {
    text-transform: none;
    font-weight: 600;
    font-size: 0.8rem;
  }
`;
const StyledRow = styled(Row)`
  && {
    margin-bottom: 1rem;
  }
`;
const ConditionRow = styled.div`
  font-size: 0.85rem;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const NomeSito = styled.div`
  font-size: 0.75rem;
  opacity: 0.6;
`;
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.color};
`;
const ChildAlarm = styled.div`
  background-color: ${props => props.color};
  width: 1.3em;
  height: 1.3em;
  position: absolute;
  border-radius: 1em;
  top: calc(50% - 0.65em);
  left: 1.5em;
`;
const StyledName = styled.span`
  font-weight: ${props => props.isBold && '700'};
`;

const AlarmEventLog = props => {
    const { match, catalogs, alarmFilters, userInfoId, addReader, removeReader, location } = props;
    const { id, type } = match.params;
    const { state } = location;

    const getInitialDates = () => {
        let startDate = '';
        let endDate = '';
        if (state && state.fromPopover && alarmFilters && alarmFilters.periodRecap) {
            switch (alarmFilters.periodRecap) {
                case 'yesterdayandtoday':
                    startDate = moment().subtract(1, 'days').format('DD/MM/YYYY');
                    break;
                case '7d':
                    startDate = moment().subtract(6, 'days').format('DD/MM/YYYY');
                    break;
                case '15d':
                    startDate = moment().subtract(14, 'days').format('DD/MM/YYYY');
                    break;
                case '30d':
                    startDate = moment().subtract(29, 'days').format('DD/MM/YYYY');
                    break;
                default:
                    break;
            }
            endDate = moment().format('DD/MM/YYYY');
        }
        return { startDate, endDate };
    }

    const [activeAlarms, setActiveAlarms] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({
        selectedSeverity: state && state.fromPopover && alarmFilters && alarmFilters.severity ? alarmFilters.severity : '',
        selectedTags: state && state.fromPopover && alarmFilters && alarmFilters.tags ? alarmFilters.tags : [],
        selectedDates: getInitialDates(),
        selectedType: '',
        selectedRead: ''
    })
    const [tags, setTags] = useState([]);
    const [types, setTypes] = useState([]);
    const [events, setEvents] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState({ show: false, alarm: null });
    const [skip, setSkip] = useState(0);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [reopeningModal, setReopeningModal] = useState(false);
    const [modalAlarm, setModalAlarm] = useState();
    const [readers, setReaders] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [readerToAdd, setReaderToAdd] = useState(null);
    const [readerToRemove, setReaderToRemove] = useState(null);
    const [alarmModal, setAlarmModal] = useState(null);

    const severities = useMemo(() => catalogs['event/severities'] && [
        {
            value: '',
            label: i18n._(t`Tutti i livelli di criticità`)
        },
        ...catalogs['event/severities'].sort((a, b) => b.weight - a.weight).map(s => {
            const severity = {
                label: s.naturalKey === 'info' ? i18n._(t`Nessuna criticità`) : i18n._(t`Criticità ${s._label.toLowerCase()}`),
                value: s.naturalKey
            }
            return severity;
        })
    ], [catalogs['event/severities']]);

    useEffect(() => {
        if (catalogs['event/tags']) {
            const catalogsTags = catalogs['event/tags'].map(t => {
                const tag = {
                    label: t.name,
                    value: t.naturalKey
                }
                return tag;
            })
            api.get(`/EventChains/allTags`).then(res => {
                const { data } = res;
                const updatedTags = [
                    ...catalogsTags,
                    ...data.filter(t => !catalogsTags.map(ct => ct.value).includes(t) && !catalogsTags.map(ct => ct.label).includes(t))
                        .map(dt => {
                            return {
                                value: 'custom',
                                label: dt
                            }
                        })
                ];
                setTags(updatedTags.sort((a, b) => {
                    const labelA = a.label.toUpperCase(); // ignora maiuscole e minuscole
                    const labelB = b.label.toUpperCase(); // ignora maiuscole e minuscole
                    if (labelA === labelB) { // le label sono uguali
                        return 0;
                    }
                    return labelA < labelB ? -1 : 1;
                }));
            }).catch(err => {
                console.error(`Non è stato possibile caricare i tag custom`);
                setTags(catalogsTags);
            });
        }
    }, [catalogs['event/tags']]);

    useEffect(() => {
        if (catalogs['event/types']) {
            const catalogsTypes = [
                {
                    value: '',
                    label: i18n._(t`Tutti i tipi di allarme`)
                },
                ...catalogs['event/types'].map(t => {
                    const type = {
                        label: translateType(t.naturalKey, catalogs),
                        value: t.naturalKey
                    }
                    return type;
                })]
            api.get(`/EventChains/allTypes`).then(res => {
                const { data } = res;
                const updatedTypes = [
                    ...catalogsTypes,
                    ...data.filter(t => !catalogsTypes.map(ct => ct.value).includes(t) && !catalogsTypes.map(ct => ct.label).includes(t))
                        .map(dt => {
                            return {
                                value: dt,
                                label: dt
                            }
                        })
                ];
                setTypes(updatedTypes.sort((a, b) => {
                    const firstString = 'TUTTI I TIPI DI ALLARME';
                    const A = a.label.toUpperCase();
                    const B = b.label.toUpperCase();
                    const labelA = A === firstString ? '' : A; // ignora maiuscole e minuscole
                    const labelB = B === firstString ? '' : B; // ignora maiuscole e minuscole
                    const test = labelA < labelB ? -1 : 1;
                    return test;
                }));
            }).catch(err => {
                console.error(`Non è stato possibile caricare i types custom`);
                setTypes(catalogsTypes);
            });
        }
    }, [catalogs['event/types']]);

    useEffect(() => {
        if (catalogs['event/readers']) {
            setReaders(catalogs['event/readers']);
        }
    }, [catalogs['event/readers']]);

    const readOptions = [
        {
            value: '',
            label: i18n._(t`Letti e non letti`)
        },
        {
            value: 'read',
            label: i18n._(t`Già letti`)
        },
        {
            value: 'unread',
            label: i18n._(t`Non letti`)
        },
    ];

    useEffect(() => {
        if (alarmFilters && (alarmFilters.severity || alarmFilters.severity === '') && alarmFilters.severity !== selectedFilters.selectedSeverity) {
            return setSelectedFilters({ ...selectedFilters, selectedSeverity: alarmFilters.severity });
        }
        if (alarmFilters && alarmFilters.tags && !isEqual(alarmFilters.tags, selectedFilters.selectedTags)) {
            return setSelectedFilters({ ...selectedFilters, selectedTags: alarmFilters.tags });
        }
        if (alarmFilters && !isEqual(getInitialDates(alarmFilters.periodRecap), selectedFilters.selectedDates)) {
            return setSelectedFilters({ ...selectedFilters, selectedDates: getInitialDates(alarmFilters.periodRecap) });
        }
    }, [alarmFilters])

    useEffect(() => {
        setSelectedRows([]);
        setSkip(0);
        loadEvents(0);
    }, [id, type, activeAlarms, selectedFilters])

    useEffect(() => {
        if(readerToAdd != null){
            addReader('event/readers', readers, readerToAdd);
        setReaderToAdd(null);
        }
    }, [readerToAdd]);

    useEffect(() => {
        if(readerToRemove != null){
            removeReader('event/readers', readers, readerToRemove);
        setReaderToAdd(null);
        }
    }, [readerToRemove]);

    const loadEvents = (_skip, isScroll = false) => {
        setLoading(true);
        let node;
        switch (type) {
            case 'companies':
                node = 'companyId'
                break;
            case 'sitegroups':
                node = 'siteGroupId'
                break;
            case 'sites':
                node = 'siteId'
                break;
            case 'assets':
                node = 'assetId'
                break;
            default: node = 'domainId'
                break;
        }

        let whereFilters = `"${node}":${id},"wholeBranch":true`;
        if (activeAlarms) {
            whereFilters += `,"ongoing":${activeAlarms},"final":{"neq":true}`;
        }
        if (selectedFilters.selectedSeverity && selectedFilters.selectedSeverity !== '') {
            whereFilters += activeAlarms ? `,"severity":"${selectedFilters.selectedSeverity}"` : `,"peakSeverity":"${selectedFilters.selectedSeverity}"`;
        }
        if (selectedFilters.selectedTags && selectedFilters.selectedTags.length > '') {
            whereFilters += `,"tags":${JSON.stringify([...selectedFilters.selectedTags.map(t => t.label), ...selectedFilters.selectedTags.filter(tg => tg.value !== 'custom').map(t => t.value)])}`;
        }
        if (selectedFilters.selectedType && selectedFilters.selectedType !== '') {
            whereFilters += `,"type":"${selectedFilters.selectedType}"`;
        }
        if (selectedFilters.selectedRead === 'read') {
            whereFilters += `,"unread":false`;
        } else if (selectedFilters.selectedRead === 'unread') {
            whereFilters += `,"unread":true`;
        }
        if (selectedFilters.selectedDates.startDate !== '' && selectedFilters.selectedDates.endDate !== '') {
            whereFilters += `,"begin":{"between":["${moment(selectedFilters.selectedDates.startDate, 'DD/MM/YYYY').toISOString()}","${moment(selectedFilters.selectedDates.endDate, 'DD/MM/YYYY').endOf('day').toISOString()}"]}`;
        }
        const filter = `?filter={"where":{${whereFilters}}, "include":["tailEvents","rootEvent","node","entity"], "order":"begin DESC", "limit": 10, "skip":${_skip}}`;

        api.get(`/EventChains${filter}`).then(result => {
            let { data } = result;
            const newEvents = formatData(data);
            setEvents(isScroll ? [...events, ...newEvents] : newEvents);
            setLoading(false);
        }).catch(error => {
            console.log(i18n._(t`Errore nel caricamento degli eventi`));
            setLoading(false);
        });
    }

    const changeRanger = (startDate, endDate) => {
        setSelectedFilters({ ...selectedFilters, selectedDates: { startDate: startDate, endDate: endDate } });
        setShowCalendar(false);
    }

    const getHeader = () => {
        return [
            {
                title: '',
                property: 'priority',
                index: 2,
                dataType: 'string',
                canSearch: false,
                width: '3rem',
            },
            {
                title: <Trans>Allarme</Trans>,
                property: 'name',
                index: 3,
                dataType: 'custom',
                canSearch: true,
                cell: row =>
                    <a href='javascript:void(0)' onClick={() => setEventDetails({ show: true, alarm: row })}>
                        <StyledName
                            isBold={!readers || readers.map(reader => reader.eventId).filter(id => row.detailsEvent.filter(ev => ev.type !== '--statechange--').map(e => e.id).includes(id)).length
                                !== row.detailsEvent.filter(ev => ev.type !== '--statechange--').length}>
                            {row.name}
                        </StyledName>
                    </a>
            },
            {
                title: <Trans>Condizione</Trans>,
                property: 'condition',
                index: 4,
                dataType: 'custom',
                canSearch: true,
                cell: row => <ConditionRow>{row.condition}</ConditionRow>,
                disableSort: true
            },
            {
                title: <span><Trans>Impianto</Trans><br /><Trans>Entità</Trans></span>,
                property: 'completeEntity',
                properties: ['entity', 'rootEntity'],
                index: 5,
                dataType: 'custom',
                canSearch: true,
                cell: row => <ConditionRow>{row.rootEntity && <NomeSito>{row.rootEntity}</NomeSito>}{row.entity}</ConditionRow>
            },
            {
                title: <Trans>Tag</Trans>,
                property: 'tags',
                index: 6,
                dataType: 'string',
                canSearch: false,
                cell: row => row.tags.map((tag, i) => <Tag key={i}>{translateTags(tag, catalogs)}</Tag>)
            },
            {
                title: <span><Trans>Data e Orario</Trans></span>,
                property: 'date',
                index: 7,
                dataType: 'string',
                canSearch: false
            },
            {
                title: <span ><Trans>Durata</Trans> </span>,
                property: 'duration',
                index: 8,
                dataType: 'string',
                canSearch: true,
                disableSort: true
            },
        ];
    }

    // TODO: edit method to get dates difference
    const dateDiff = (d1, d2) => {
        let second = d1.getTime() - d2.getTime();
        second /= 1000;
        let minute = parseInt(second / 60);
        second = parseInt(second - minute * 60)
        let hour = parseInt(minute / 60);
        minute = minute - hour * 60;
        let days = parseInt(hour / 24);
        hour = hour - days * 24;
        days = days === 0 ? "" : `${days}d `;
        second = second < 10 ? `0${second}` : second;
        minute = minute < 10 ? `0${minute}` : minute;
        hour = hour < 10 ? `0${hour}` : hour;
        return `${days}${hour}:${minute}:${second}`;
    }

    const getSeverity = ev => {
        let priority;
        if (ev.final || !ev.alarm || ev.severity === '---') {
            priority = <ChildAlarm color="#00965A" />;
        } else {
            switch (ev.severity) {
                case 'critical':
                    priority = <ChildAlarm color="#D0021B" />
                    break;
                case 'medium':
                    priority = <ChildAlarm color="#F5A623" />
                    break;
                case 'info':
                    priority = <ChildAlarm color="#9bddf2" />
                    break;
                default:
                    priority = <ChildAlarm color="#F8E71C" />
                    break;
            }
        }
        return priority;
    }

    const resetFilters = () => {
        setSelectedFilters({
            selectedSeverity: '',
            selectedTags: [],
            selectedDates: { startDate: '', endDate: '' },
            selectedType: '',
            selectedRead: ''
        })
        // const updatedAlarmFilters = { ...alarmFilters, tags: [], severity: '' };
        // changeAlarmFilters(updatedAlarmFilters);
    }

    const setAsRead = (alarm, userInfoId, multipleRows = false) => {
        let readEvents = [];
        const readersId = readers.map(r => r.eventId);
        const getReadEvents = al => {
            return al.detailsEvent ?
                al.detailsEvent.filter(ev => ev.type !== '--statechange--').map(e => { return { eventId: e.id, userInfoId } })
                : [{ eventId: al.id, userInfoId }];
        }
        if (multipleRows) {
            alarm.forEach(al => {
                readEvents = [...readEvents, ...getReadEvents(al)]
            });
        } else {
            readEvents = getReadEvents(alarm);
        }
        const readerToAdd = readEvents.filter(re => !readersId.includes(re.eventId));
        if (readerToAdd.length > 0) {
            api.post('/EventReaders', readerToAdd).then(res => {
                const { data } = res;
                const newReaders = data.map(e => { return { eventId: parseInt(e.eventId), userInfoId: e.userInfoId } })
                setReaderToAdd(newReaders);
                toast.success(i18n._(t`Allarme impostato come letto`));
            }).catch(err => {
                toast.error(i18n._(t`Non è stato possibile impostare l'allarme come letto`));
            });
        }
    }

    const setAsUnread = (alarm, multipleRows = false) => {
        let unreadIds = [];
        if (multipleRows) {
            alarm.forEach(al => {
                if (al.detailsEvent) {
                    unreadIds = [...unreadIds, ...al.detailsEvent.map(a => a.id)];
                } else {
                    unreadIds.push(al.id);
                }
            });
        } else {
            unreadIds = alarm.detailsEvent ? alarm.detailsEvent.map(a => a.id) : [alarm.id];
        }
        const readersId = readers.map(r => r.eventId);
        unreadIds = unreadIds.filter(id => readersId.includes(id));
        api.delete(`/EventReaders?where={"eventId": {"inq": [${unreadIds}]}}`).then(res => {
            setReaderToRemove(unreadIds);
            toast.success(i18n._(t`Allarme impostato come non letto`));
        }).catch(err => {
            toast.error(i18n._(t`Non è stato possibile impostare l'allarme come non letto`));
        });
    }

    const getModalConfirm = (alarm, multipleRows = false, reopening = false) => {
        setModalAlarm(multipleRows ? [...alarm] : [alarm]);
        setReopeningModal(reopening);
        setOpenModalConfirm(true);
    }

    const viewAlarm = alarm => {
        const { history, observableproperties } = props;
        const opUID = alarm.detailsEvent && alarm.detailsEvent[0] && alarm.detailsEvent[0].opUID;
        if(opUID){
            const idOp = observableproperties.find(op => op.IoTUID === opUID).id;
            setAlarmModal({idOp: idOp, alarmId: alarm.id});
        }else{
            toast.error(i18n._(t`L'allarme non è configurabile`));
        }
    }

    const getRowBtns = alarm => {
        const eventId = alarm.id;
        const rowButtons = [];
        rowButtons.push({
            icon:  "eye",
            label: <Trans>Impostazione</Trans>,
            onClick: () => viewAlarm(alarm)
        });
        if(alarm.workflow && alarm.state === "WK-1"){
            rowButtons.push({
                icon: ['far', 'check-circle'],
                label: <Trans>Conferma</Trans>,
                onClick: () => getModalConfirm(alarm)
            });
        }
        if(alarm.state === 'WK-2' && !alarm.auto){
            rowButtons.push({
                icon: ['far', 'bell'],
                label: <Trans>Conferma</Trans>,
                onClick: () => getModalConfirm(alarm, false, true)
            });
        }
        if(readers && readers.find(r => r.userInfoId === userInfoId && r.eventId === eventId)){
            rowButtons.push({
                icon: "envelope",
                label: <Trans>Segna come non letto</Trans>,
                onClick: () => setAsUnread(alarm)
            });
        }else{
            rowButtons.push({
                icon: ['far', 'envelope-open'],
                label: <Trans>Segna come letto</Trans>,
                onClick: () => setAsRead(alarm, userInfoId,false, readers)
            });
        }

        return rowButtons;

    }

    const getHeaderBtns = () => {
        const headerButtons = [];
        if (selectedRows.length > 0) {
            if(selectedRows.find(sr => sr.state === 'WK-1')){
                headerButtons.push({
                    icon: ['far', 'check-circle'],
                    label: <Trans>Conferma</Trans>,
                    onClick: () => getModalConfirm(selectedRows, true)
                })
            };
            if(selectedRows.find(sr => sr.state === 'WK-2' && !sr.auto)){
                headerButtons.push({
                    icon: ['far', 'bell'],
                    label: <Trans>Conferma</Trans>,
                    onClick: () => getModalConfirm(selectedRows, true, true)
                })
            };
            if(readers && selectedRows.find(sr => readers.map(reader => reader.eventId).includes(sr.id))){
                headerButtons.push({
                    icon: "envelope",
                    label: <Trans>Segna come non letta</Trans>,
                    onClick: () => setAsUnread(selectedRows, true)
                })
            };
            if(readers && selectedRows.find(sr => !readers.map(reader => reader.eventId).includes(sr.id))){
                headerButtons.push({
                    icon: ['far', 'envelope-open'],
                    label: <Trans>Segna come letta</Trans>,
                    onClick: () => setAsRead(selectedRows, userInfoId, true)
                })
            };
        }else{
            return null;
        }
                
        return headerButtons;
    }

    const formatData = _events => {
        let eventsData = [];
        _events.forEach(ev => {
            const id = ev.rootEventId;
            let date = <>
                {ev.rootEvent && ev.rootEvent.agentName && ev.rootEvent.agentType &&
                    <>{ev.rootEvent.agentName}<br /></>
                }
                {ev.lastUpdate && ev.tailEvents && ev.tailEvents.length > 0 && (ev.final || ev.auto) && <>{new Date(ev.lastUpdate).toLocaleString()}<br /></>}
                {ev.begin && <>{new Date(ev.begin).toLocaleString()}<br /></>}
            </>
            const duration = ev.lastUpdate && ev.begin && ev.lastUpdate !== ev.begin ?
                dateDiff(new Date(ev.lastUpdate), new Date(ev.begin)) :
                dateDiff(new Date(), new Date(ev.begin));
            const tags = ev.tags;
            const name = ev.rootEvent ? translateDetail(ev.rootEvent.name, '', catalogs) : <>&nbsp;</>;
            const entity = ev.entity ? ev.entity.name : <>&nbsp;</>;
            const rootEntity = ev.node ? ev.node.name : <>&nbsp;</>;
            const completeEntity = rootEntity + " " + entity;
            const condition = ev.rootEvent ? ev.rootEvent.condition : <>&nbsp;</>;
            const description = ev.rootEvent ? ev.rootEvent.description : <>&nbsp;</>;
            const begin = ev.begin;
            const lastUpdate = ev.lastUpdate;
            const severity = ev.severity;
            const ongoing = ev.ongoing;
            const alarm = ev.alarm;
            let priority = ev.severity === 'info' ? <StyledIcon color="#9bddf2" icon={ev.state === 'WK-2' && !ev.auto ? ['far', 'check-circle'] : "check-circle"} size="2x" />
                : <StyledIcon color="#00965A" icon={ev.state === 'WK-2' && !ev.auto ? ['far', 'check-circle'] : "check-circle"} size="2x" />;
            if ((!ev.workflow && ev.ongoing) || (ev.workflow && !ev.final)) {
                switch (ev.severity) {
                    case 'critical':
                        priority = <StyledIcon color="#D0021B" icon="bell" size="2x" />;
                        break;
                    case 'medium':
                        priority = <StyledIcon color="#F5A623" icon="bell" size="2x" />;
                        break;
                    case 'info':
                        priority = <StyledIcon color="#9bddf2" icon="bell" size="2x" />;
                        break;
                    default:
                        priority = <StyledIcon color="#F8E71C" icon="bell" size="2x" />;
                        break;
                }
            }
            const key = `${ev.rootEventId}-${Math.random()}`;
            const childEvents = ev.tailEvents ? ev.tailEvents.filter(e => e.name !== '' && e.type !== '--statechange--').map(al => {
                return {
                    ...al,
                    rootEntity: ev.node && ev.node.name,
                    entity: ev.entity && ev.entity.name
                }
            }) : [];
            const workflow = ev.workflow;
            const auto = ev.auto;
            const state = ev.state;
            const collapsedRow = true;
            const detailsEvent = [ev.rootEvent, ...ev.tailEvents];
            if (childEvents.length > 0 && ev.rootEvent) {
                childEvents.push(ev.rootEvent);
            }
            let item = {
                date,
                duration,
                tags,
                name,
                entity,
                rootEntity,
                completeEntity,
                condition,
                priority,
                childEvents,
                id,
                workflow,
                state,
                collapsedRow,
                description,
                begin,
                lastUpdate,
                severity,
                ongoing,
                detailsEvent,
                key,
                auto,
                alarm
            }
            eventsData.push(item);
        });
        return eventsData;
    }

    const onModalSubmit = ids => {
        const getColor = severity => {
            let color;
            switch (severity) {
                case 'critical':
                    color = "#D0021B";
                    break;
                case 'medium':
                    color = "#F5A623";
                    break;
                case 'info':
                    color = "#9bddf2";
                    break;
                default:
                    color = "#F8E71C";
                    break;
            }
            return color;
        }
        const readersId = readers.map(r => r.eventId);
        const readerEvents = ids.filter(id => !readersId.includes(id)).map(id => { return { eventId: id, userInfoId } });
        const patchChains = ids.map(id => api.patch(`/EventChains/${id}/workflowState`, reopeningModal ? { state: 'WK-1' } : { state: 'WK-2' }));
        // add events to readers if not present
        readerEvents.length > 0 && patchChains.push(api.post('/EventReaders', readerEvents.filter(re => !readersId.includes(re.eventId))));
        Promise.all(patchChains).then(res => {
            addReader('event/readers', readers, readerEvents);
            toast.success(reopeningModal ? i18n._(t`Allarme riattivato`) : i18n._(t`Allarme chiuso manualmente`));
            setOpenModalConfirm(false);
            if (res.length > 0) {
                const updatedEvents = res.map(r => r.data);
                const resIds = updatedEvents.map(d => d.rootEventId);
                let updatedEventsList;
                if (reopeningModal) {
                    updatedEventsList = events.map(ev => {
                        return !resIds.includes(ev.id) ? ev : {
                            ...ev,
                            priority: <StyledIcon color={getColor(ev.severity)} icon='bell' size="2x" />,
                            auto: false,
                            state: 'WK-1',
                            date: <>
                                {ev.rootEvent && ev.rootEvent.agentName && ev.rootEvent.agentType && <>{ev.rootEvent.agentName}<br /></>}
                                {ev.begin && <>{new Date(ev.begin).toLocaleString()}<br /></>}
                            </>
                        }
                    });
                } else {
                    updatedEventsList = activeAlarms ? events.filter(ev => !resIds.includes(ev.id))
                        : events.map(ev => {
                            return !resIds.includes(ev.id) ? ev : {
                                ...ev,
                                priority: <StyledIcon color="#00965A" icon={['far', 'check-circle']} size="2x" />,
                                auto: false,
                                state: 'WK-2',
                                date: <>
                                    {ev.rootEvent && ev.rootEvent.agentName && ev.rootEvent.agentType && <>{ev.rootEvent.agentName}<br /></>}
                                    <>{new Date(res.find(r => resIds.includes(r.data.rootEventId)).data.lastUpdate).toLocaleString()}<br /></>
                                    {ev.begin && <>{new Date(ev.begin).toLocaleString()}<br /></>}
                                </>
                            }
                        });
                }
                setEvents(updatedEventsList);
                setSelectedRows([]);
            }
        }).catch(err => {
            toast.error(reopeningModal ? i18n._(t`Errore nella riattivazione dell'allarme`) : i18n._(t`Errore nella chiusura manuale dell'allarme`));
            setOpenModalConfirm(false);
        });
    }

    const toggleRow = row => {
        if (selectedRows.map(r => r.id).includes(row.id)) {
            setSelectedRows(selectedRows.filter(r => r.id !== row.id));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    }

    const toggleRows = () => {
        setSelectedRows(selectedRows.length === events.length ? [] : events);
    }

    const closeDetail = event => {
        setAsRead(event, userInfoId);
        setEventDetails({ show: false, alarm: null });
    }

    const closeModal = () => {
        setAlarmModal(null);
    }
    console.log('selectedFilters',selectedFilters, tags);
    return (
        <Card>
            <Modal size="xl" centered isOpen={alarmModal}>
                <ModalBody>
                <MemoryRouter initialEntries={[getUrlFromPath('/alarms/log/')+(alarmModal && alarmModal.idOp)]} initialIndex={0}>
                    <Alarms match={match} isModal closeModal={closeModal}/>
                </MemoryRouter>
                </ModalBody>
            </Modal>
            <CardHeader>
                <Trans>Storico eventi e allarmi</Trans>
                <TabGroup>
                    <TabButton
                        onClick={() => setActiveAlarms(true)}
                        active={activeAlarms} >
                        <FontAwesomeIcon icon="bell" /> Attivi
                </TabButton>
                    <TabButton
                        onClick={() => setActiveAlarms(false)}
                        active={!activeAlarms} >
                        <FontAwesomeIcon icon="list" /> Tutti
                </TabButton>
                </TabGroup>
            </CardHeader>
            <CardBody>
                {showCalendar && (
                    <CalendarOptions
                        saveAction={(startDate, endDate) => changeRanger(startDate, endDate)}
                        dismissAction={() => setShowCalendar(false)}
                        rangeCalendar={true}
                        token={selectedFilters.selectedDates.startDate && selectedFilters.selectedDates.endDate ? selectedFilters.selectedDates : null}
                    />
                )}
                {eventDetails.show && (
                    <EventDetails
                        closeDetail={alarm => closeDetail(alarm)}
                        alarm={eventDetails.alarm} />
                )}
                <StyledRow>
                    <Col md="4">
                        <Select
                            options={severities}
                            canSearch={true}
                            value={selectedFilters.selectedSeverity}
                            onChange={event => { return setSelectedFilters({ ...selectedFilters, selectedSeverity: event.target.value })/*, changeAlarmFilters({ ...alarmFilters, severity: event.target.value }) */ }} />
                    </Col>
                    <Col md='8'>
                        <MultiSelectTag
                            placeholder={i18n._(t`Eventi con qualsiasi tag`)}
                            options={tags}
                            value={selectedFilters.selectedTags}
                            keyProperty="value"
                            labelProperty="label"
                            onChange={event => { return setSelectedFilters({ ...selectedFilters, selectedTags: event.target.value })/*, changeAlarmFilters({ ...alarmFilters, tags: event.target.value }) */ }} />
                    </Col>
                </StyledRow>
                <StyledRow>
                    {/* ------------- Types select ------------- */}
                    <Col md='4'>
                        <div>
                            <Select
                                options={types}
                                value={selectedFilters.selectedType}
                                keyProperty="value"
                                labelProperty="label"
                                onChange={event => setSelectedFilters({ ...selectedFilters, selectedType: event.target.value })} />
                        </div>
                    </Col>
                    {/* ------------- Read/Unread select ------------- */}
                    <Col md='4'>
                        <div>
                            <Select
                                options={readOptions}
                                value={selectedFilters.selectedRead}
                                keyProperty="value"
                                labelProperty="label"
                                onChange={event => setSelectedFilters({ ...selectedFilters, selectedRead: event.target.value })} />
                        </div>
                    </Col>
                    {/* ------------- Period input ------------- */}
                    <Col md='2'>
                        <InputGroup onClick={() => setShowCalendar(true)}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText><Trans>Da:</Trans></InputGroupText>
                            </InputGroupAddon>
                            <Input style={{ backgroundColor: '#fff', color: '#29363d' }} type="text" value={selectedFilters.selectedDates.startDate} readOnly />
                        </InputGroup>
                    </Col>
                    <Col md='2'>
                        <InputGroup onClick={() => setShowCalendar(true)}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText><Trans>A:</Trans></InputGroupText>
                            </InputGroupAddon>
                            <Input style={{ backgroundColor: '#fff', color: '#29363d' }} type="text" value={selectedFilters.selectedDates.endDate} readOnly />
                        </InputGroup>
                    </Col>
                </StyledRow>
                {loading && <Spinner overlay={false} />}
                <InfiniteScroll
                    dataLength={events.length} //This is important field to render the next data
                    next={() => { loadEvents(skip + 10, true); setSkip(skip + 10) }}
                    hasMore={true}>
                    <DataTable
                        search
                        keyField='key'
                        title=''
                        width='100%'
                        collapsed
                        selectedRows={selectedRows}
                        hoverCheckbox
                        onCheckBoxToggle={row => toggleRow(row)}
                        onHeaderCheckBoxToggle={() => toggleRows()}
                        headers={getHeader()}
                        buttons={(
                            selectedFilters.selectedSeverity ||
                            selectedFilters.selectedTags.length > 0 ||
                            (selectedFilters.selectedDates.startDate && selectedFilters.selectedDates.endDate) ||
                            selectedFilters.selectedType ||
                            selectedFilters.selectedRead
                        ) &&
                            < Button onClick={() => resetFilters()}><Trans>Elimina filtri</Trans></Button>}
                        data={events}
                        onArrowClick={(row => row.collapsedRow = !row.collapsedRow)}
                        nestedData={{
                            field: 'childEvents',
                            headers: [
                                {
                                    title: '',
                                    property: '_checkbox',
                                    index: 1,
                                    dataType: 'custom',
                                    canSearch: false,
                                },
                                {
                                    title: '',
                                    property: 'severity',
                                    index: 2,
                                    dataType: 'custom',
                                    canSearch: false,
                                    cell: row => getSeverity(row)
                                },
                                {
                                    title: <Trans>Allarme</Trans>,
                                    property: 'description',
                                    index: 3,
                                    dataType: 'custom',
                                    canSearch: true,
                                    cell: row =>
                                        <StyledName isBold={!readers || !readers.find(r => r.userInfoId === userInfoId && r.eventId === row.id)}>
                                            <a href='javascript:void(0)' onClick={() => setEventDetails({ show: true, alarm: row })}>{translateDetail(row.name, row.alarm, catalogs)}</a>
                                        </StyledName>
                                },
                                {
                                    title: <Trans>Condizione</Trans>,
                                    property: 'condition',
                                    index: 4,
                                    dataType: 'custom',
                                    canSearch: true,
                                    cell: row => <ConditionRow>{row.condition}</ConditionRow>
                                },
                                {
                                    title: <span><Trans>Impianto</Trans></span>,
                                    property: '_entity',
                                    index: 5,
                                    dataType: 'string'
                                },
                                {
                                    title: <Trans>Tag</Trans>,
                                    property: 'tags',
                                    index: 6,
                                    dataType: 'custom',
                                    cell: row => {
                                        const tagList = [];
                                        if (row.tags) {
                                            row.tags.forEach(tag => {
                                                tagList.push(<Tag>{translateTags(tag, catalogs)}</Tag>)
                                            });
                                        }
                                        return tagList;
                                    }
                                },
                                {
                                    title: <Trans>Data e Orario</Trans>,
                                    property: 'occurred',
                                    index: 7,
                                    dataType: 'custom',
                                    cell: row => {
                                        return <>
                                            {row.agentName && row.agentType &&
                                                <>{row.agentName}<br /></>}
                                            {new Date(row.occurred).toLocaleString()}
                                        </>
                                    }
                                },
                                {
                                    title: <span ><Trans>Durata</Trans> </span>,
                                    property: '_duration',
                                    index: 8,
                                    dataType: 'string'
                                },
                            ],
                            noData: <Trans>Nessun allarme figlio</Trans>,
                            rowButtons: row => getRowBtns(row)
                        }
                        }
                        rowButtons={row => getRowBtns(row)}
                        headerButtons={() => getHeaderBtns()}
                        noData={<Trans>Nessun allarme</Trans>}
                    />
                </InfiniteScroll>
                {openModalConfirm &&
                    <ModalConfirm alarms={modalAlarm} reopening={reopeningModal} closeModal={() => setOpenModalConfirm(false)} openModalConfirm={openModalConfirm} onModalSubmit={ids => onModalSubmit(ids)} />}
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => {
    const { preferences, catalogs, domain } = state;
    const { alarmFilters, userInfoId, language } = preferences;
    const { observableproperties } = domain;
    const { userSystemRoles } = state.auth;
    const { selectedDomain, selectedCompany, companies } = state.navigation;
    return { alarmFilters, catalogs, userInfoId, observableproperties, userSystemRoles,language, selectedDomain, selectedCompany, companies };
};

const mapDispatchToProps = dispatch => ({
    changeAlarmFilters: alarmFilters => dispatch(changeAlarmFilters(alarmFilters)),
    addReader: (name, readers, newReaders) => dispatch(addReader(name, readers, newReaders)),
    removeReader: (name, readers, removedReadersIds) => dispatch(removeReader(name, readers, removedReadersIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlarmEventLog);
