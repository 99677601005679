import React from 'react';

// set the defaults
const TimeDefinitionsContext = React.createContext({
  timeDefinitions: [],
  onEditTimeDefinition: () => {},
  onAddTimeDefinition: () => {},
});

export default TimeDefinitionsContext;
