/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, ButtonGroup, Collapse } from 'reactstrap';
import { AuthControl, Button, IconButton } from 'app/common';
import { addItem, editItem, deleteItem } from 'ducks/actions/navigation';
import { Roles } from 'app/utils/auth';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { getAddress } from 'app/utils/data';
import { getUrlFromPath } from 'app/utils/navigation';
import MeasuresPanel from './MeasuresPanel';
import ForecastLinks from './ForecastLinks';
import LoaderView from './graph/Helpers/LoaderView';
import NodeGraphViewer from './graph/Components/NodeGraphViewer';
import RealTimeProvider from 'app/common/RealTimeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import GroupList from './graph/Components/Group/GroupList';
import { concatUrl } from 'app/utils/navigation'



const EmptyGraphWrap = styled.div`
  padding: 70px;
  text-align: center;
`;

const EmptyGraphIcon = styled(FontAwesomeIcon)`
  &&&& {
    width: 200px;
    height: 200px;
    margin: 5px;
    color: #bdbdbd;
  }
`;

const InfoContainer = styled.div`
  margin: -${props => props.theme.card.padding};
  margin-bottom: 1rem;
  margin-top: 0;
  padding: 0.5rem ${props => props.theme.card.padding};
  background-color: rgba(31, 113, 183, 0.05);
`;

const CollapseBlock = styled.div``;

const Header = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.common.text.headerColor};
  font-weight: bold;
`;

const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  &:hover {
    cursor: pointer;
  }
`;

const CollapseHeaderTitle = styled.span`
  color: ${props => props.theme.common.text.primaryColor};
  font-weight: 500;
`;

const StyledCollapse = styled(Collapse)`
  margin-left: 1.7rem;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
  font-size: 1.2rem;
  color: #999;
  transform: rotate(-90deg);
  transition: all 0.1s;

  ${props => props.open && 'transform: rotate(0)'};

  ${CollapseHeader}:hover & {
    color: #aaa;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 0.5rem;
`;

const ButtonContainerFooter = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;


class ManageAssets extends Component {
  constructor(props) {
    super(props);

    this.LoaderView = new LoaderView();
    
    this.state = {
      editNode: null,
      view: 'graph',
      isLoaded: false,
      energyAsset: null,
      meterAsset: null,
      observedProperties: null,
      parentGraphData: null,
      vectors: null,
      graphData: null,
      redirectToEdit: false,
      virtualMeter: null,
    };
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    const { parentGraphId, id } = params;
    if (this.state.isLoaded && (parentGraphId !== this.parentGraphId || id !== this.siteId)) {
      this.setState({ isLoaded: false });
      this.loadData();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { match } = this.props;
    const { params } = match;
    const { parentGraphId, id } = params;
    this.parentGraphId = parentGraphId;
    this.siteId = id;
    this.LoaderView.loadGraph(id, parentGraphId, ret => {
      const { graphData, observedProperties, energyAsset, vectors, meterAsset, virtualMeter } = ret;
      this.setState({
        graphData,
        observedProperties,
        energyAsset,
        vectors,
        meterAsset,
        virtualMeter,
        isLoaded: true
      });
    });
  };

  redirectToEdit = () => {
    this.setState({redirectToEdit: true});
  }
  
  renderGraph = () => {
    let ret = null;
    const { match } = this.props;
    const { params } = match;
    const { parentGraphId, id } = params;
    const {
      graphData,
      observedProperties,
      energyAsset,
      vectors,
      meterAsset,
      isLoaded,
      redirectToEdit,
      virtualMeter
    } = this.state;
    if (!isLoaded) {
      return <div />;
    }


    if (redirectToEdit !== undefined && redirectToEdit) {
      let target = `/sites/${this.siteId}/management/editgraph`;
      if (this.parentGraphId) {
        target = `/sites/${this.siteId}/management/editgraph/${this.parentGraphId}`;
      }
      return <Redirect push to={target} />;
    }
    
    if(graphData.assets.length === 0){
      ret = (
        <EmptyGraphWrap>
          <EmptyGraphIcon icon={faProjectDiagram} />
          <br />
          <Trans>Il MODELLO ENERGETICO permette di definire la struttura energetica del sito e monitorare gli scambi di energia tra i vari asset presenti</Trans>
          <br />
          <br />
          <Button color="primary" onClick={this.redirectToEdit}>
            <Trans>Crea modello energetico</Trans>
          </Button>
        </EmptyGraphWrap>
      );
    }else  if (parentGraphId !== undefined) {
    
      ret = (
        <RealTimeProvider>
          <NodeGraphViewer
            {...this.props}
            siteId={id}
            parentGraphId={parentGraphId}
            graphData={graphData}
            observedProperties={observedProperties}
            energyAsset={energyAsset}
            vectors={vectors}
            meterAsset={meterAsset}
            LoaderView={this.LoaderView}
            virtualMeter={virtualMeter}
          />
          </RealTimeProvider>
      );
    } else {
      ret = (
        <RealTimeProvider>
          <NodeGraphViewer
            {...this.props}
            siteId={id}
            graphData={graphData}
            observedProperties={observedProperties}
            energyAsset={energyAsset}
            vectors={vectors}
            meterAsset={meterAsset}
            virtualMeter={virtualMeter}
          />
        </RealTimeProvider>
      );
    }
    return ret;
  };

  toggleSiteData = () => {
    this.setState(prevState => ({ siteDataOpen: !prevState.siteDataOpen }));
  };

  toggleAdditionalData = () => {
    this.setState(prevState => ({ additionalDataOpen: !prevState.additionalDataOpen }));
  };

  toggleCaricamentoDati = () => {
    this.setState(prevState => ({ addCaricamentoData: !prevState.addCaricamentoData }));
  };

  renderSiteData = () => {
    const { selectedSite } = this.props;
    const { siteDataOpen } = this.state;
    return (
      <CollapseBlock>
        <CollapseHeader onClick={this.toggleSiteData}>
          <ArrowIcon open={siteDataOpen} icon="chevron-circle-down" />
          <CollapseHeaderTitle>
            <Trans>Anagrafica del sito</Trans>
          </CollapseHeaderTitle>
        </CollapseHeader>
        <StyledCollapse isOpen={siteDataOpen}>
          <Row>
            <Col>
              <div>
                <Header>
                  <Trans>Indirizzo</Trans>
                </Header>
                <div>{getAddress(selectedSite)}</div>
              </div>
            </Col>
            <Col>
              <div>
                <Header>
                  <Trans>Gruppi</Trans>
                </Header>
                <div>
                  {selectedSite && selectedSite.siteGroups ? (
                    selectedSite.siteGroups.map(x => x.name).join(', ')
                  ) : (
                    <Trans>Nessun gruppo</Trans>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <Header>
                  <Trans>Referente</Trans>
                </Header>
                <div>
                  {selectedSite && selectedSite.contactPerson
                    ? `${selectedSite.contactPerson.name} `
                    : ''}
                  {selectedSite &&
                  selectedSite.contactPerson &&
                  selectedSite.contactPerson.email ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${selectedSite.contactPerson.email}`}
                    >
                      {selectedSite.contactPerson.email}
                    </a>
                  ) : (
                    ''
                  )}
                </div>
                {selectedSite &&
                  selectedSite.contactPerson &&
                  selectedSite.contactPerson.phoneNumber && (
                    <div>{selectedSite.contactPerson.phoneNumber}</div>
                  )}
              </div>
            </Col>
          </Row>
        </StyledCollapse>
      </CollapseBlock>
    );
  };

  renderAdditionalData = () => {
    const { selectedSite, userCurrentRoles } = this.props;
    if (!selectedSite) {
      return null;
    }
    const { params, siteForecasts } = selectedSite;
    const measures = (params || {}).measures || [];
    const { additionalDataOpen } = this.state;

    return (
      <CollapseBlock>
        <CollapseHeader onClick={this.toggleAdditionalData}>
          <ArrowIcon open={additionalDataOpen} icon="chevron-circle-down" />
          <CollapseHeaderTitle>
            <Trans>Dati tecnici, operativi ed economici del sito</Trans>
          </CollapseHeaderTitle>
        </CollapseHeader>
        <StyledCollapse isOpen={additionalDataOpen}>
          {measures.filter(x => x.observedProperty).length > 0 && (
            <MeasuresPanel measures={measures.filter(x => x.observedProperty)} />
          )}
          <ForecastLinks forecasts={siteForecasts} userCurrentRoles={userCurrentRoles} />
          {(userCurrentRoles.includes(Roles.SUP) ||
            userCurrentRoles.includes(Roles.ADM) ||
            userCurrentRoles.includes(Roles.OPE_Commissioning)) && (
            <StyledLink to={getUrlFromPath('/management/data')}>
              <Button color="primary" size="sm">
                {params ? <Trans>Modifica i dati</Trans> : <Trans>Inserisci i dati</Trans>}
              </Button>
            </StyledLink>
          )}
        </StyledCollapse>
      </CollapseBlock>
    );
  };

  render() {
    const { isLoaded, view, graphData, energyAsset } = this.state;
    
    const { selectedSite, match } = this.props;
    const { params, url } = match;
    
    const { id } = params;
    return (
      <Card id="diagramBodyCard">
        <CardHeader>
          <ButtonGroup style={{ float: 'right', marginTop: '3px', marginRight: '5px' }}>
            <Button
              size="sm"
              onClick={() => this.setState({ view: 'graph' })}
              active={view === 'graph'}
            >
              <FontAwesomeIcon icon={faProjectDiagram} />
            </Button>
            <Button
              size="sm"
              onClick={() => this.setState({ view: 'group' })}
              active={view === 'group'}
            >
              <FontAwesomeIcon icon="sitemap" />
            </Button>
          </ButtonGroup>
          {selectedSite && selectedSite.name}

        </CardHeader>
        <CardBody>
          <InfoContainer>
            {this.renderSiteData()}
            {this.renderAdditionalData()}
          </InfoContainer>
          {!isLoaded ? null : (
            <div>
              {view === 'graph' ? (
                this.renderGraph()
              ) : (
                <GroupList
                  {...this.props}
                  graphData={graphData}
                  energyAsset={energyAsset}
                  onChangeGroup={() => {}}
                  showCommand={false}
                  siteId={id}
                />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  const { selectedSite, assets } = state.navigation;
  const { userCurrentRoles } = state.auth;

  return {
    selectedSite,
    assets,
    userCurrentRoles
  };
};

const mapDispatchToProps = dispatch => ({
  addItem: (type, item) => dispatch(addItem(type, item)),
  editItem: (type, item) => dispatch(editItem(type, item)),
  deleteItem: (type, id) => dispatch(deleteItem(type, id))
});

ManageAssets.propTypes = {
  userCurrentRoles: PropTypes.object.isRequired, // from redux
  selectedSite: PropTypes.object, // from redux
  assets: PropTypes.arrayOf(PropTypes.object).isRequired // from redux
};

ManageAssets.defaultProps = {
  selectedSite: { id: undefined }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAssets);
