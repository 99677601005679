import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import moment from 'moment';
import { DynamicForm } from 'app/common';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { checkPassword } from 'app/utils/auth';
import log from 'app/common/log';

const AlertMessage = styled(Alert)`
  && {
    text-align: justify;
  }
`;

const RestartVirtualMeter = props => {
  const { vm } = props;
  const [initialValues, setInitialValues] = useState({});
  const [restartDate, setRestartDate] = useState();
  const [restartEndDate, setRestartEndDate] = useState();

  const minDate = moment()
    .startOf('year')
    .subtract(3, 'years');

  const validationFunction = async password => {
    const isPasswordValid = await checkPassword(password);
    if (isPasswordValid) {
      return true;
    }
    throw new Error(i18n._(t`Password errata`));
  };

  useEffect(() => {
    setInitialValues({ restartDate, restartEndDate });
  }, [restartDate, restartEndDate]);

  useEffect(() => {
    const endDate = moment()
      .tz(vm.timeZone)
      .startOf('day')
      .subtract(1, 'days');
    const startDate = moment(vm.startDate)
      .tz(vm.timeZone)
      .startOf('day');
    const actualStartDate = startDate.toISOString() > endDate.toISOString() ? endDate : startDate;
    setRestartDate(actualStartDate);
    setRestartEndDate(endDate);
  }, []);

  const fields = [
    {
      name: 'restartDate',
      type: 'date',
      label: <Trans>Data inizio ricalcolo</Trans>,
      validation: {
        required: true,
        func: async date => {
          if (date.isAfter(moment().subtract(1, 'days'))) {
            throw new Error(
              i18n._(t`La data di inizio ricalcolo non può coincidere con la data di oggi.`)
            );
          }
          return true;
        }
      },
      props: {
        minDate,
        maxDate: moment().subtract(1, 'days'),
        showTime: false,
        showDateInput: true,
        disabled: false,
        defaultCalendarValue: moment(vm.startDate).startOf('day')
      }
    },
    {
      name: 'restartEndDate',
      type: 'date',
      label: <Trans>Data fine ricalcolo</Trans>,
      validation: {
        required: true,
        func: async (date, dates) => {
          log.debug(
            'VM restart dates validation (begin,end) ',
            dates.restartDate.toISOString(),
            dates.restartEndDate.toISOString()
          );
          if (date.isAfter(moment().subtract(1, 'days'))) {
            throw new Error(
              i18n._(t`La data di fine ricalcolo non può coincidere con la data di oggi`)
            );
          } else if (date.isBefore(dates.restartDate)) {
            throw new Error(
              i18n._(
                t`La data di fine ricalcolo non può essere antecedente alla data di inizio ricalcolo.`
              )
            );
          }
          return true;
        }
      },
      props: {
        minDate,
        maxDate: moment().subtract(1, 'days'),
        showTime: false,
        showDateInput: true,
        disabled: false,
        defaultCalendarValue: moment()
          .subtract(1, 'days')
          .startOf('day')
      }
    },
    {
      name: 'pwd',
      type: 'password',
      label: <Trans>Password</Trans>,
      validation: { required: true, func: validationFunction }
    }
  ];

  return (
    <>
      <AlertMessage color="light">
        <Trans>
          Questa funzionalità permette di effettuare il ricalcolo dei valori del contatore Virtuale,
          a partire dalla data selezionata. Sei sicuro di voler procedere? L'operazione non è
          reversibile e sovrascriverà gli eventuali valori della variabile di destinazione giò
          presenti in tale intervallo. Per maggiore sicurezza ti preghiamo di inserire la password
          del tuo account
        </Trans>
      </AlertMessage>
      <DynamicForm
        card={false}
        title=""
        fields={fields}
        initialValues={initialValues}
        submitButtonText={i18n._(t`Ricalcola`)}
        cancelButtonText={i18n._(t`Chiudi`)}
        onSubmit={props.restartVM}
        onCancel={() => props.clickClose()}
      />
    </>
  );
};

RestartVirtualMeter.propTypes = {
  restartVM: PropTypes.func.isRequired,
  clickClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps)(RestartVirtualMeter);
