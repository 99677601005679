import PropTypes from 'prop-types';

export const TokenPropType = PropTypes.shape({
  id: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  background: PropTypes.string,
  textcolor: PropTypes.string,
  disable: PropTypes.bool,
  optionIcon: PropTypes.object,
});