import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Popup from '../helpers/Popup';
import { DataTable, Switch, IconButton, Spinner, Tag, Select } from 'app/common';
import { Row, Col } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import i18n from 'app/i18n';
import logger from 'app/common/log';
import SelectedPlantsList from './SelectedPlantsList';
import TagSelectionButton from './TagSelectionButton';
import TrashButton from './TrashButton';
import PhoneEdit from './PhoneEdit';

const StarExplanation = styled.div`
  margin: 1rem 0rem;
  font-size: 0.8rem;
`;
const TagPreferences = (props = {}) => {
  const {
    selectedDomain,
    userPreferences,
    onSubmit,
    match,
    getPlantName,
    eventSeverities
  } = props;
  const { url } = match;
  const { eventTagsCatalog } = props;
  const { notifications: notificationPreferences } = userPreferences || {};
  const { tags: notificationPreferencesTags } = notificationPreferences || {};

  const tagsCompare = (a, b) => {
    const diffStrings = (a, b) => {
      if (a != null && a === b) {
        return 0;
      }
      return a && b && a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    };
    if (a.companyId == null) {
      return b.companyId == null ? diffStrings(a.name, b.name) : -1;
    } else return b.companyId == null ? 1 : diffStrings(a.name, b.name);
  };

  const minSeverityWeight = eventSeverities && Math.min(...eventSeverities.map(s => s.weight));
  const ALL_SEVERITY_LEVELS = '__all_levels__';
  const eventSeveritiesExtended = eventSeverities && [
    { value: ALL_SEVERITY_LEVELS, label: i18n._(t`Tutti i livelli di criticità`) },
    ...eventSeverities
      .filter(s => s.weight !== minSeverityWeight)
      .map(s => ({ value: s.naturalKey, label: s.localName }))
  ];

  const eventTagList =
    eventTagsCatalog &&
    eventTagsCatalog.sort(tagsCompare).map(tag => ({
      name: tag.localName,
      id: tag.id,
      key: tag.naturalKey,
      marked: !tag.companyId
    }));

  const optionEventTagList =
    eventTagList &&
    eventTagList.filter(
      catalogTag =>
        !notificationPreferencesTags ||
        (notificationPreferencesTags &&
          !Object.keys(notificationPreferencesTags).includes(catalogTag.key))
    );

  logger.debug('TagPreferences props ', props);
  logger.trace('TagPreferences notificationPreferencesTags ', notificationPreferencesTags);
  logger.trace('TagPreferences eventTagList ', eventTagList);
  logger.trace('TagPreferences optionEventTagList ', optionEventTagList);
  logger.debug('TagPreferences eventSeveritiesExtended ', eventSeveritiesExtended);

  const [error, setError] = useState([]);
  const [showPhoneEdit, setShowPhoneEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  logger.debug('notificationPreferences %o ', notificationPreferences);

  const submitNotifications = notificationPreferences => {
    setLoading(true);
    onSubmit(notificationPreferences).then(result => {
      setLoading(false);
      if (result && result.err) {
        const str = result.err.msg || JSON.stringify(result.err);
        toast.error(str);
      }
    });
  };
  const onPhoneChange = event => {
    const value = event.target.value;
    setPhoneNumber(value);
  };

  const changeOptionHO = (channel, tagKey) => ({ target }) => {
    const { checked } = target;
    const newNotificationPreferencesTags = cloneDeep(notificationPreferencesTags);
    logger.debug(
      'changeOption channel %s, eventType %s, target: %o, userPreferences %o, notificationPreferences %o, newNotificationPreferences %o',
      channel,
      tagKey,
      userPreferences,
      notificationPreferences
    );
    if (newNotificationPreferencesTags) {
      if (!newNotificationPreferencesTags[tagKey]) {
        newNotificationPreferencesTags[tagKey] = { email: false, telegram: false, toast: false };
      }
      newNotificationPreferencesTags[tagKey][channel] = checked;
      submitNotifications({ ...notificationPreferences, tags: newNotificationPreferencesTags });
    }
  };

  const changeSeverity = (tagKey, severity) => {
    logger.debug('changeSeverity  tagKey %s, severity: %s', tagKey, severity);
    const newNotificationPreferencesTags = cloneDeep(notificationPreferencesTags);

    if (newNotificationPreferencesTags) {
      if (!newNotificationPreferencesTags[tagKey]) {
        newNotificationPreferencesTags[tagKey] = { email: false, telegram: false, toast: false };
      }
      newNotificationPreferencesTags[tagKey]['minSeverity'] =
        severity === ALL_SEVERITY_LEVELS ? undefined : severity;
      submitNotifications({ ...notificationPreferences, tags: newNotificationPreferencesTags });
    }
  };

  const addNotificationTag = tagKey => {
    logger.debug('addNotificationTag ', tagKey);
    const changeEmail = changeOptionHO('email', tagKey);
    const changeTelegram = changeOptionHO('telegram', tagKey);
    const changeToast = changeOptionHO('toast', tagKey);
    const dummyTarget = { target: { checked: false } };
    changeEmail(dummyTarget);
    changeTelegram(dummyTarget);
    changeToast(dummyTarget);
  };

  const removeNotificationTag = tagKey => {
    logger.debug('removeNotificationTag ', tagKey);

    const newNotificationPreferences = { ...notificationPreferences };
    if (notificationPreferencesTags && notificationPreferencesTags[tagKey]) {
      delete notificationPreferencesTags[tagKey];
      setLoading(true);
      onSubmit(newNotificationPreferences).then(result => {
        setLoading(false);
        if (result && result.err) {
          toast.error(result.err);
        }
      });
    }
  };

  const validatePhone = phone => {
    if (phone === '' || phone === null) {
      return true;
    }
    const expression = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;

    return expression.test(String(phone).toLowerCase());
  };

  const getEventTagPlantsList = (notificationPreferences, domainId, tagKey) => {
    logger.debug('getEventTagPlantsList ', notificationPreferences, domainId, tagKey);
    const tagPreferences = notificationPreferencesTags && notificationPreferencesTags[tagKey];
    const eventTagDomainSelectedPlants =
      tagPreferences && tagPreferences.allowedFrom && tagPreferences.allowedFrom[domainId];
    return eventTagDomainSelectedPlants;
  };

  const generaData = (eventTagList, notificationPreferencesTags) => {
    if (!notificationPreferencesTags) {
      return null;
    }
    const eventTagPreferencesList =
      eventTagList &&
      eventTagList.filter(
        catalogTag =>
          notificationPreferencesTags &&
          Object.keys(notificationPreferencesTags).includes(catalogTag.key)
      );
    logger.debug(
      'tagPreferences generaData ',
      eventTagPreferencesList,
      notificationPreferencesTags
    );

    const data =
      eventTagPreferencesList &&
      eventTagPreferencesList.map(({ name, key: tagKey, id }) => ({
        id: tagKey,
        tag: name,
        severity:
          (notificationPreferencesTags &&
            notificationPreferencesTags[tagKey] &&
            notificationPreferencesTags[tagKey].minSeverity) ||
          ALL_SEVERITY_LEVELS,
        plants: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SelectedPlantsList
              getPlantName={getPlantName}
              domainSelectedPlants={getEventTagPlantsList(
                notificationPreferencesTags,
                selectedDomain && selectedDomain.id,
                tagKey
              )}
            />
            <Link to={`${url}/tag/${tagKey}`}>
              <IconButton icon="pencil-alt" />
            </Link>
          </div>
        ),
        email: (
          <Switch
            labelOn=" "
            labelOff=" "
            id={`email|${tagKey}`}
            onChange={changeOptionHO('email', tagKey)}
            checked={
              notificationPreferencesTags
                ? notificationPreferencesTags[tagKey] && notificationPreferencesTags[tagKey].email
                : false
            }
          />
        ),
        toast: (
          <Switch
            labelOn=" "
            labelOff=" "
            id={`toast|${tagKey}`}
            onChange={changeOptionHO('toast', tagKey)}
            checked={
              notificationPreferencesTags
                ? notificationPreferencesTags[tagKey] && notificationPreferencesTags[tagKey].toast
                : false
            }
          />
        ),
        telegram: (
          <span onClick={checkPhone}>
            <Switch
              labelOn=" "
              labelOff=" "
              id={`telegram|${tagKey}`}
              onChange={changeOptionHO('telegram', tagKey)}
              disabled={
                !notificationPreferences ||
                notificationPreferences.phone == null ||
                notificationPreferences.phone === ''
              }
              checked={
                notificationPreferencesTags &&
                notificationPreferencesTags[tagKey] &&
                notificationPreferencesTags[tagKey].telegram
              }
            />
          </span>
        )
      }));

    return data;
  };

  const getHeader = () => {
    return [
      {
        property: 'id',
        hidden: true,
        index: 1,
        dataType: 'string'
      },
      {
        title: <Trans>Tag di classificazione degli eventi</Trans>,
        property: 'tag',
        index: 2,
        dataType: 'custom',
        cell: row => <Tag key={row.id}>{row.tag}</Tag>
      },
      {
        title: <Trans>Criticità minima</Trans>,
        property: 'severity',
        index: 3,
        dataType: 'custom',
        width: '15rem',
        cell: row => (
          <Select
            directionUp
            name="Select severity"
            value={row.severity}
            options={eventSeveritiesExtended}
            onChange={({ target }) => changeSeverity(row.id, target && target.value)}
          />
        )
      },
      {
        title: <Trans>Impianti abilitati</Trans>,
        property: 'plants',
        index: 4,
        dataType: 'string'
      },
      { title: <Trans>Toast</Trans>, property: 'toast', index: 5, dataType: 'string' },
      { title: <Trans>Email</Trans>, property: 'email', index: 6, dataType: 'string' },
      { title: <Trans>Telegram</Trans>, property: 'telegram', index: 7, dataType: 'string' },
      {
        title: '',
        property: 'button',
        index: 8,
        dataType: 'custom',
        cell: row => <TrashButton onClick={removeNotificationTag.bind(null, row.id)} />
      }
    ];
  };

  const checkPhone = () => {
    let phoneNumber = notificationPreferences ? notificationPreferences.phone : null;
    console.log('Check Phone', phoneNumber);
    if (!phoneNumber) {
      setShowPhoneEdit(true);
      setError({});
    }
  };

  const savePhone = async () => {
    if (!validatePhone(phoneNumber)) {
      setError({ phone: <Trans>Il numero di telefono inserito non è valido</Trans> });
      return;
    }
    const oldPhone = notificationPreferences.phone;
    if (phoneNumber == null || phoneNumber === '') {
      notificationPreferences.phone = null;
    } else {
      notificationPreferences.phone = `+39 ${phoneNumber}`;
    }
    const result = await onSubmit(notificationPreferences);
    const { err } = result || {};
    const { msg, codes } = err || {};
    logger.debug('phone msg ', msg, codes);
    if (codes && codes.preferences && codes.preferences[0] === 'custom.nonUniquePhone') {
      setError({
        phone: (
          <>
            <Trans>Il numero di telefono inserito non è valido.</Trans>&nbsp;
            <Trans>Ti preghiamo di contattare il Supporto EXACTO</Trans> (email:{' '}
            <a href="mailto:supportoexacto@elettrainvestimenti.it" target="_blank">
              supportoexacto@elettrainvestimenti.it
            </a>
            )
          </>
        )
      });
      notificationPreferences.phone = oldPhone;
      return;
    } else if (msg) {
      toast.error(msg);
    }

    setShowPhoneEdit(false);
  };

  return (
    <>
      {showPhoneEdit && (
        <Popup
          modalTitle={<Trans>Conferma ricezione notifiche Telegram</Trans>}
          modalContainer={
            <PhoneEdit error={error} onChange={onPhoneChange} phoneNumber={phoneNumber} />
          }
          onSave={savePhone}
          onSaveText={<Trans>Conferma</Trans>}
          undoColor="link"
          onUndo={setShowPhoneEdit.bind(null, false)}
        />
      )}
      <Spinner loading={loading} overlay />
      <div>
        <TagSelectionButton
          itemSelection={addNotificationTag}
          itemList={optionEventTagList}
          title="NUOVA NOTIFICA (PER TAG EVENTO)"
        />
        <br />
        <Row>
          <Col>
            <DataTable
              keyField="id"
              title=""
              width="100%"
              headers={getHeader()}
              data={generaData(eventTagList, notificationPreferencesTags)}
              noData="Nessuna notifica configurata in base a tag dell’evento"
            />
          </Col>
        </Row>
      </div>
      <StarExplanation>
        *&nbsp;
        <Trans>
          L'asterisco mostrato in corrispondenza di un impianto indica che l'abilitazione include
          anche i nodi di gerarchia inferiore appartenenti all'impianto stesso.
        </Trans>
      </StarExplanation>
    </>
  );
};

export default TagPreferences;
