import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getIntervalOptions, getAggregationOptions, widgetTypes } from '../common';

class PeriodSelector extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const { widgetType, periodCompetence, period } = props;

    // Controlla se il profilo temporale selezionato sia da settare come valore predefinito
    let isPeriodCompetenceToSet = false;
    if (periodCompetence && (widgetType === widgetTypes.SERIES || widgetType === widgetTypes.PIE || widgetType === widgetTypes.DISPLAY) ) {
      isPeriodCompetenceToSet = true;
    }

    const intervalValue = isPeriodCompetenceToSet ? periodCompetence : period.interval;

    // Calculate first aggregation date value
    let aggregationDateValue;
    if (intervalValue === 'from') {
      aggregationDateValue = moment(period.aggregation_date);
      if (!aggregationDateValue.isValid()) {
        aggregationDateValue = moment(); // must have  a value anyway
      }
    } else {
      aggregationDateValue = null; // today date for new widgets
    }

    this.state = {
      intervalOptions: [],
      aggregationOptions: [],
      interval: intervalValue,
      aggregation: period.aggregation,
      aggregationDate: aggregationDateValue, // additional date field
      showAggregationDate: (intervalValue === 'from'),
    };
  }

  componentDidMount() {
    const { widgetType, currentModule } = this.props;
    const { interval } = this.state;
    const { items, defaultValue } = getIntervalOptions(widgetType, currentModule);

    // Set period items options
    this.setState({ intervalOptions: items });

    const intervalForAggregations = (!interval) ? defaultValue : interval;
    this.setupAggregationOptions(intervalForAggregations);
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueTypes } = this.props;
    const { interval } = this.state;

    if (valueTypes !== prevProps.valueTypes || interval !== prevState.interval) {
      this.setupAggregationOptions(interval);
    }
  }

  setupAggregationOptions(interval) {
    const { widgetType, valueTypes, period } = this.props;
    const { items, defaultValue } = getAggregationOptions(widgetType, valueTypes, interval);

    this.setState({
      showAggregationDate: (interval === 'from'),
      aggregation: (period && period.aggregation) ? period.aggregation : defaultValue,
      aggregationOptions: items,
      interval: interval,
    });

    return defaultValue;
  }

  getFields(widgetType) {
    const { intervalOptions, aggregationOptions, showAggregationDate } = this.state;
    const disabledIntervalWidgets = [
      widgetTypes.CIRCLE,
      widgetTypes.GAUGE,
      widgetTypes.LED,
      widgetTypes.SWITCH,
      widgetTypes.SLIDER
    ];

    const intervalColumnSize = (showAggregationDate) ? 4 : 6;
    const aggregationColumnSize = (showAggregationDate) ? 4 : 6;

    const fields = [
      {
        name: 'interval',
        xs: intervalColumnSize,
        sm: intervalColumnSize,
        type: 'select',
        label: <Trans>Periodo</Trans>,
        props: { directionUp: true },
        validation: { required: true },
        options: intervalOptions,
        disabled: disabledIntervalWidgets.includes(widgetType),
        onChange: (value, values) => {
          const newAggregation = this.setupAggregationOptions(value);
          values.aggregation = newAggregation; // set default value option on the dynamic form

          this.setState({ showAggregationDate: (value === 'from') });
        },
      },
    ];

    if (showAggregationDate) {
      fields.push({
        name: 'aggregation_date',
        type: 'date',
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
        label: <Trans>Data</Trans>,
        props: {
          lang: 'it',
          showTime: false,
          showDateInput: true,
          disabled: false
        },
      });
    } else {
      fields.push({
        name: 'aggregation_date',
        type: 'custom',
        hidden: true,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        render: () => <></>
      });
    }

    fields.push({
      name: 'aggregation',
      xs: aggregationColumnSize,
      sm: aggregationColumnSize,
      md: aggregationColumnSize,
      lg: aggregationColumnSize,
      type: 'select',
      label: <Trans>Granularità</Trans>,
      props: { directionUp: true },
      validation: { required: true },
      options: aggregationOptions,
      // disabled: (value, values) => (widgetType === widgetTypes.PIE || widgetType === widgetTypes.DISPLAY) && values.interval !== 'lastvalue',
    });

    return fields;
  }

  getValues = async () => {
    const values = await this.formRef.current.validateAndGetValues();
    const { interval, aggregation, aggregation_date } = values;
    return {
      interval: interval,
      aggregation: aggregation,
      aggregation_date: (interval === 'from' && aggregation_date instanceof moment) ? aggregation_date.toISOString() : null
    };
  };

  render() {
    const { widgetType } = this.props;
    const { interval, aggregation, aggregationDate } = this.state;

    return (
      <DynamicForm
        ref={this.formRef}
        hideButtons
        hideRequiredLabel
        initialValues={{
          interval: interval,
          aggregation: aggregation,
          aggregation_date: aggregationDate
        }}
        fields={this.getFields(widgetType)}
      />
    );
  }
}

PeriodSelector.propTypes = {
  currentModule: PropTypes.string,
  widgetType: PropTypes.string,
  valueTypes: PropTypes.object,
  periodCompetence: PropTypes.string,
  period: PropTypes.object,
};

PeriodSelector.defaultProps = {
  period: {},
};

export default PeriodSelector;
