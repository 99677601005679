import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'app/common';
import api from 'api';
import { Trans } from '@lingui/macro';
import RealTimeProvider from 'app/common/RealTimeProvider';
import styled from 'styled-components';
import { formatNumber } from 'app/utils/formatNumber';

const Wrapper = styled.div`
  margin: .5rem 0;
`;

const Label = styled.span`
  font-size: .9rem;
  color: ${props => props.theme.common.text.headerColor};
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: .5rem;
`;

const Measure = (props) => {
  const { physicalQuantity, unit, observedPropertyId, subscribe, unsubscribe, lastValues } = props;

  const [initialValue, setInitialValue] = useState({});

  const loadInitialValue = async () => {
    const res = await api.get(`ObservedProperties/${observedPropertyId}/raw/lastSample`);
    setInitialValue(res.data);
  };

  useEffect(() => {
    const key = subscribe({ id: observedPropertyId, aggregation: 'raw' });
    loadInitialValue();

    return () => {
      unsubscribe(key);
    };
  }, []);

  let value = (lastValues[`${observedPropertyId}_raw`] || initialValue).v;
  if (value) {
    const { exactoConversionFactor } = unit;
    value = formatNumber(value, 0, exactoConversionFactor, 'instant').toLocaleString() + ' ' + unit.symbol;
  } else {
    value = 'In attesa del dato';
  }

  return (
    <>
      <div>
        <Label><Trans>{physicalQuantity._label} esterna del sito</Trans></Label>
        <Value>{value}</Value>
      </div>
      <Divider />
    </>
  );
};


const MeasuresPanel = (props) => {
  const { measures, physicalQuantities, units } = props;

  return (
    <Wrapper>
      <RealTimeProvider>
        {measures.map((m, index) => {
          const physicalQuantity = physicalQuantities.find(x => x.naturalKey === m.physicalQuantity);
          const defaultUnit = physicalQuantity.defaultVisualizationUnit || physicalQuantity.units[0];
          const unit = units.find(x => x.naturalKey === defaultUnit);
          return <Measure key={m.physicalQuantity} observedPropertyId={m.observedProperty} physicalQuantity={physicalQuantity} unit={unit} />;
        })}
      </RealTimeProvider>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const { physicalQuantities, units } = state.catalogs;
  return { physicalQuantities, units };
};

export default connect(mapStateToProps)(MeasuresPanel);
