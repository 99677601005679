export const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
};


export const getNestedObject = (object, dotSeparatedKeys) => {
  return dotSeparatedKeys.split('.').reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : undefined), object);
};


const isMomentObject = (obj) => {
  if (!obj || !Object.keys(obj)) {
    return false;
  }
  if (obj[Object.keys(obj)[0]] && obj[Object.keys(obj)[0]]._isAMomentObject !== undefined) {
    return true;
  }
  return false;
};


export const mergeDeep = (target, source) => {
  if (!isObject(target)) {
    target = {};
  }

  if (isObject(source) && !isMomentObject(source)) {
    /* eslint-disable-next-line no-restricted-syntax */
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  } else {
    return { ...target, ...source };
  }
  return target;
};

export const getObjectWithoutKeys = (object, keys, removeUnderscoreProps = false) => {
  const newObject = Object.entries(object).reduce((obj, [key, value]) => {
    if (!(keys.includes(key) || (removeUnderscoreProps && key[0] === '_'))) {
      obj[key] = value;
    }
    return obj;
  }, {});
  return newObject;
};

const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const getGUID = () => (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
