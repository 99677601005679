export const errorPage = (error, code) => {
    const div = document.createElement('div');
    div.innerHTML = `<div style='display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;'>
    <h1>
        Si è verificato un errore imprevisto
    </h1>
    <p>
        Per favore, inviare il contenuto di questa pagina all'indirizzo supporto.exacto@cogeninfra.it.
    </p>
    <br>
    <h1>
        An unexpected error occurred
    </h1>
    <p>
        Please send the content of this page to the email address support.exacto@cogeninfra.it.
    </p>
    <br>
    <h1>
        Une erreur inattendue s'est produite
    </h1>
    <p>
        Veuillez envoyer le contenu de cette page à l'adresse support.exacto@cogeninfra.it.
    </p>
    <br>
    <h1>
        Se ha producido un error inesperado
    </h1>
    <p>
        Por favor, envíe el contenido de esta página a la dirección support.exacto@cogeninfra.it.
    </p>
    <br>
    <p style='padding-left:30vh;padding-right:30vh;'>
    ` + error + `
    </p>
    <p>
        Fase: ` + code + `
    </p>
    </div>`;
    document.body.appendChild(div);
}