import React from 'react';
import api from 'api';

class Api extends React.Component {
  patchGraphData = (graphData, callback) => {
    let submitGraphData = JSON.parse(JSON.stringify(graphData));
    submitGraphData = this.checkFlowData(submitGraphData);
    const { id } = submitGraphData;
    api.patch(`/AssetGraphs/${id}`, submitGraphData).then((result) => {
      if (typeof callback === 'function') {
        result.success = true;
        callback(result);
      }
    }).catch((error) => {
      if (!error || !error.response) {
        return;
      }
      error.response.data.success = false;
      if (typeof callback === 'function') {
        callback(error.response.data);
      }
    });
  }


  checkFlowData = (graphData) => {
    console.log(graphData);
    for (let i = 0 ; i < graphData.assetFlows.length; i++) {
      
      let {sourceMeasures, destinationMeasures } = graphData.assetFlows[i];
      if (sourceMeasures === undefined) {
        sourceMeasures = [];
        graphData.assetFlows[i].sourceMeasures = [];
      }
      if (destinationMeasures === undefined) {
        destinationMeasures = [];
        graphData.assetFlows[i].destinationMeasures = [];
      }
      for (let j = 0; j < sourceMeasures.length; j++){
        let { observedPropertyId } = sourceMeasures[j];
        let type = 'op';
        if (typeof(observedPropertyId) === "string" && observedPropertyId.substring(0, 3) === "vm-") {
          type = 'vm';
          observedPropertyId = parseInt(observedPropertyId.replace("vm-",""),10);
        }
        graphData.assetFlows[i].sourceMeasures[j].observedPropertyId = observedPropertyId;
        graphData.assetFlows[i].sourceMeasures[j].type = type;
      }

      for (let j = 0; j < destinationMeasures.length; j++){
        let { observedPropertyId } = destinationMeasures[j];
        let type = 'op';
        if(typeof(observedPropertyId) === "string" && observedPropertyId.substring(0, 3) === "vm-"){
          type = 'vm';
          observedPropertyId = parseInt(observedPropertyId.replace("vm-",""),10);
        }
        graphData.assetFlows[i].destinationMeasures[j].observedPropertyId = observedPropertyId;
        graphData.assetFlows[i].destinationMeasures[j].type = type;
      }
    }

    for(let i = 0 ; i < graphData.assets.length; i++){
      let { measures } = graphData.assets[i];
      if (measures === undefined) {
        measures = [];
        graphData.assets[i].measures = [];
      }
      for (let j = 0; j < measures.length; j++){
        let { observedPropertyId } = measures[j];
        let type = 'op';
        if(typeof(observedPropertyId) === "string" && observedPropertyId.substring(0, 3) === "vm-"){
          type = 'vm';
          observedPropertyId = parseInt(observedPropertyId.replace("vm-",""),10);
        }
        graphData.assets[i].measures[j].observedPropertyId = observedPropertyId;
        graphData.assets[i].measures[j].type = type;
      }
    }
    return graphData;
  }
  
}

export default Api;
