import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & .ql-container.ql-snow {
    border: 0;
  }
`;

const modules = {
  toolbar: null,
};

const formats = [
  'header',
  'font',
  'size',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'color', 'background',
  'link', 'image',
];

const TextWidget = (props) => {
  const { widget } = props;

  return (
    <Wrapper>
      <ReactQuill
        readOnly
        modules={modules}
        formats={formats}
        value={widget.content}
      />
    </Wrapper>
  );
};

export default TextWidget;
