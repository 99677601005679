import React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const Footer = styled(ModalFooter)`
  border-top-color: transparent !important;
  justify-content: flex-start !important;
  padding: 0 1rem 0.5rem 0.5rem !important;
`;

const Header = styled(ModalHeader)`
  & .modal-title {
    font-weight: bold;
    }
`;

const Btn = styled(Button)`
  text-transform: uppercase;
  font-size: 0.8rem !important;
`;

class Popup extends React.Component {
  state = {
    modal: true,
  };

  toggle = () => {
    const { onUndo } = this.props;
    const { modal } = this.state;
    if (modal) {
      onUndo();
    }
    this.setState({
      modal: !modal,
    });
  }

  render() {
    let { exitText } = this.props;
    const { modalTitle, modalContainer, onUndo, size, onSave, onSaveText, saveColor, undoColor } = this.props;
    const { modal } = this.state;

    let save = null;
    if (onSave !== undefined) {
      let saveText = onSaveText;
      if (onSaveText === undefined) {
        saveText = i18n._(t`Salva`)
      }
      save = <Btn color={saveColor ? saveColor : 'primary'} onClick={onSave}>{saveText}</Btn>;
    }
    exitText = exitText !== undefined ? exitText : 'Annulla';
    return (
      <div>
        <Modal size={size !== undefined ? size : null} isOpen={modal} toggle={this.toggle}>
          <Header toggle={this.toggle}>
            {modalTitle}
          </Header>
          <ModalBody>
            {modalContainer}
          </ModalBody>
          <Footer>
            {save}
            <Btn color={undoColor ? undoColor : 'primary'} className='pull-left' onClick={onUndo}>{exitText}</Btn>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Popup;
