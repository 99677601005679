/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { Select as StyledSelect, Button } from 'app/common';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getDerivataType } from '../Functions';
import { TokenPropType } from '../../../utils/proptypesModels';

class VariableDerivata extends Component {
  detDerivata = getDerivataType().find(x => (x.id === (this.props.token.derivataType || 1)));

  state = {
    modalOpen: true,
    derivataType: this.props.token.derivataType || 1,
    serieRiferimento: this.props.token.serieRiferimento || {},
    serieComparata: this.props.token.serieComparata || {},
    param1: this.props.token.param1 || '',
  }

  handleChangeDerivataType = (derivataType) => {
    const value = parseInt(derivataType.target.value, 0);
    this.detDerivata = getDerivataType().filter(x => x.id === value).pop();
    this.setState({
      derivataType: value,
      serieRiferimento: this.detDerivata.maxSerie === null || this.detDerivata.maxSerie > 1 ? [] : {},
      serieComparata: this.detDerivata.maxSerieB === null || this.detDerivata.maxSerieB > 1 ? [] : {},
    });
  }

  handleSerieRiferimento = (serieRiferimento) => {
    this.setState({ serieRiferimento });
  }

  handleChangeParam1 = (param) => {
    this.setState({ param1: param.target.value });
  }

  submit = () => {
    const { derivataType, serieRiferimento, serieComparata, param1 } = this.state;
    const { maxLen } = this.props;
    const alertMsg = [];

    /* validazione selezione serie di riferimento */
    if (this.detDerivata.serieRiferimento) {
      if (this.detDerivata.maxSerie !== null && this.detDerivata.maxSerie > 1 && (serieRiferimento.length < (this.detDerivata.maxSerie || 2))) { alertMsg.push(i18n._(t`Definisci la variabile di riferimento`)); }
      if (this.detDerivata.maxSerie !== null && this.detDerivata.maxSerie === 1 && serieRiferimento.value === undefined) { alertMsg.push(i18n._(t`Definisci la variabile di riferimento`)); }
      if (this.detDerivata.maxSerie === null && serieRiferimento.length === 0) { alertMsg.push(i18n._(t`Definisci la variabile di riferimento`)); }
    }

    if (this.detDerivata.serieComparata) {
      if (this.detDerivata.maxSerieB !== null && this.detDerivata.maxSerieB > 1 && (serieRiferimento.length < (this.detDerivata.maxSerieB || 2))) { alertMsg.push(i18n._(t`Definisci la variabile da comparare`)); }
      if (this.detDerivata.maxSerieB !== null && this.detDerivata.maxSerieB === 1 && serieComparata.value === undefined) { alertMsg.push(i18n._(t`Definisci la variabile da comparare`)); }
      if (this.detDerivata.maxSerieB === null && serieComparata.length === 0) { alertMsg.push(i18n._(t`Definisci la variabile da comparare`)); }
    }

    if (this.detDerivata.id === 14 && param1.length === 0) { alertMsg.push(i18n._(t`Definisci un periodo per la media mobile`)); }

    /* controllo selezione massima variabili */
    if (!Array.isArray(serieRiferimento) && maxLen === 0) alertMsg.push(i18n._(t`Hai raggiunto il numero massimo di variabili selezionabili`));
    else if (Array.isArray(serieRiferimento) && !this.detDerivata.explodeSerie && maxLen === 0) alertMsg.push(i18n._(t`Hai raggiunto il numero massimo di variabili selezionabili`));
    else if (Array.isArray(serieRiferimento) && this.detDerivata.explodeSerie && maxLen < serieRiferimento.length) alertMsg.push(i18n._(t`Hai raggiunto il numero massimo di variabili selezionabili`));

    /* prendi l'informazioni sulla variabile di riferimento */
    if (alertMsg.length === 0) {
      if (!Array.isArray(serieRiferimento)) {
        const detRif = this.props.tab.activeVariable.find(x => x.id === serieRiferimento.value);
        const newVariable = { id: new Date().getTime().toString(),
          derivataType,
          unit: detRif.unit,
          scaleFactor: detRif.scaleFactor,
          valueType: detRif.valueType,
          aggregation: this.detDerivata.aggregation,
          serieRiferimento,
          serieComparata,
          path: detRif.path,
          label: `(${i18n._(this.detDerivata.label)}) ${i18n._(detRif.label)}`,
          seriesType: this.detDerivata.seriesType,
          param1 };
        this.props.saveAction(newVariable);
      } else if (Array.isArray(serieRiferimento) && !this.detDerivata.explodeSerie) {
        const detRif = this.props.tab.activeVariable.find(x => x.id === serieRiferimento[0].value);
        const newVariable = { id: new Date().getTime().toString(),
          derivataType,
          unit: detRif.unit,
          scaleFactor: detRif.scaleFactor,
          valueType: detRif.valueType,
          aggregation: this.detDerivata.aggregation,
          serieRiferimento,
          serieComparata,
          path: detRif.path,
          label: `${i18n._(this.detDerivata.label)}`,
          seriesType: this.detDerivata.seriesType,
          param1 };
        this.props.saveAction(newVariable);
      } else if (Array.isArray(serieRiferimento) && this.detDerivata.explodeSerie) {
        serieRiferimento.forEach((serieRiferimento) => {
          const detRif = this.props.tab.activeVariable.find(x => x.id === serieRiferimento.value);
          const newVariable = { id: new Date().getTime().toString(),
            derivataType,
            unit: detRif.unit,
            scaleFactor: detRif.scaleFactor,
            valueType: detRif.valueType,
            aggregation: this.detDerivata.aggregation,
            serieRiferimento,
            serieComparata,
            path: detRif.path,
            label: `${i18n._(this.detDerivata.label)}`,
            seriesType: this.detDerivata.seriesType,
            param1 };
          this.props.saveAction(newVariable);
        });
      }
    } else {
      alert(alertMsg.join('\n'));
    }
  }

  render() {
    const { modalOpen, derivataType, serieRiferimento, serieComparata, param1 } = this.state;
    const DERIVATE_TYPE = getDerivataType();

    /* mi creo un array di serie di riferimento selezionate per escluderle dalla selezione delle serie comparate */
    const serieRifSelected = [];
    if (!Array.isArray(serieRiferimento)) {
      serieRifSelected.push(serieRiferimento.value);
    } else if (Array.isArray(serieRiferimento)) {
      serieRiferimento.forEach((det) => {
        serieRifSelected.push(det.value);
      });
    }

    return (
      <div>
        <Modal isOpen={modalOpen}>
          <ModalHeader toggle={() => this.props.dismissAction(false)}><Trans>Imposta variabile derivata</Trans></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="derivataType"><Trans>Tipo di variabile derivata</Trans></Label>
                <StyledSelect
                  id="derivataType"
                  onChange={this.handleChangeDerivataType}
                  value={derivataType}
                  options={DERIVATE_TYPE.map(x => ({ value: x.id, label: i18n._(x.label) }))}
                />
              </FormGroup>

              {
                Object.keys(DERIVATE_TYPE.filter(x => x.id === derivataType && x.serieRiferimento)).length > 0 && (
                <FormGroup>
                  <Label for="serieRiferimento">
                    {(() => {
                      switch (derivataType) {
                        case 15:
                          return i18n._(t`Variabili da sommare`);
                        default:
                          return i18n._(t`Serie di riferimento (A)`);
                      }
                    })()}
                  </Label>
                  <Select
                    id="serieRiferimento"
                    value={serieRiferimento}
                    isMulti={this.detDerivata.maxSerie === null || this.detDerivata.maxSerie > 1}
                    onChange={serieRiferimento => this.setState({ serieRiferimento })}
                    options={this.props.tab.activeVariable && this.props.tab.activeVariable.filter(x => x.derivataType === undefined).map(x => ({ value: x.id, label: i18n._(x.label) }))}
                  />
                </FormGroup>
                )
              }

              {
                Object.keys(DERIVATE_TYPE.filter(x => x.id === derivataType && x.serieComparata)).length > 0 && (
                <FormGroup>
                  <Label for="serieComparata">
                    {(() => {
                      switch (derivataType) {
                        case 15:
                          return i18n._(t`Variabili da sottrarre`);
                        default:
                          return i18n._(t`Serie di comparazione (B)`);
                      }
                    })()}
                  </Label>
                  <Select
                    id="serieComparata"
                    value={serieComparata}
                    isMulti={this.detDerivata.maxSerieB === null || this.detDerivata.maxSerieB > 1}
                    onChange={serieComparata => this.setState({ serieComparata })}
                    options={this.props.tab.activeVariable.filter(x => x.derivataType === undefined).map(x => ({ value: x.id, label: i18n._(x.label) }))}
                  />
                </FormGroup>
                )
              }

              {
                derivataType === 14
                && (
                  <FormGroup>
                    <Label for="param1"><Trans>Periodi</Trans></Label>
                    <Input type="numeric" id="param1" value={param1} onChange={this.handleChangeParam1} />
                  </FormGroup>
                )
              }
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}><Trans>Ok</Trans></Button>
            <Button color="link" onClick={() => this.props.dismissAction(false)}><Trans>Annulla</Trans></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

VariableDerivata.defaultProps = {
  token: undefined,
};

VariableDerivata.propTypes = {
  saveAction: PropTypes.func.isRequired,
  dismissAction: PropTypes.func.isRequired,
  token: TokenPropType,
  tab: PropTypes.object.isRequired,
  maxLen: PropTypes.number.isRequired,
};

export default VariableDerivata;
