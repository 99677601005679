import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Vector from './Vector';

const StyledDynamicForm = styled(DynamicForm)`
  margin-bottom: 1rem;
`;

class VectorList extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      vectorsData: (props.vectors || []).map(item => ({ item, ref: React.createRef() })),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vectors.length !== prevState.vectorsData.length) {
      return ({ vectorsData: (nextProps.vectors || []).map(item => ({ item, ref: React.createRef() })) });
    }
    return null;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.initialValues !== undefined && prevState.initialValues === undefined) {
  //     const avgPeriodicCostFactor = nextProps.initialValues.avgPeriodicCostFactor || 1;
  //     let initialValues = { ...nextProps.initialValues };
  //     if (nextProps.initialValues.avgPeriodicCostFactor) {
  //       const scaledValues = getScaledValues(nextProps.initialValues, nextProps.vector._defaultUnit);
  //       initialValues = { ...initialValues, ...scaledValues };
  //     }
  //     return { initialValues, additionalData: { avgPeriodicCostFactor } };
  //   }
  //   return null;
  // }


  updateAdditionalData = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState(prevState => ({ additionalData: { ...prevState.additionalData, [name]: value } }));
  }

  getValues = async () => {
    try {
      const { vectorsData } = this.state;
      const data = await this.formRef.current.validateAndGetValues();
      const promises = vectorsData.map(item => item.ref.current.validateAndGetValues());
      const vectors = await Promise.all(promises);
      return { ...data, vectorsData: vectors };
    } catch (e) {
      throw e;
    }
  };


  render() {
    const { readOnly, costOptions, initialValues } = this.props;
    const { vectorsData } = this.state;

    return (
      <div>
        <StyledDynamicForm
          ref={this.formRef}
          hideButtons
          readOnly={readOnly}
          fields={[
            {
              name: 'whiteCerticatesPrice',
              type: 'text',
              label: <Trans>Prezzo certificati bianchi (TEE)</Trans>,
              validation: { number: true, minVal: 0 },
              md: 6,
              addon: '€/TEE',
            },
            {
              name: 'co2Penalty',
              type: 'text',
              label: <Trans>Penalità per emissioni CO2</Trans>,
              validation: { number: true, minVal: 0 },
              md: 6,
              addon: '€/kg',
            },
            {
              name: 'electMaxPowerWithdrawal',
              type: 'text',
              label: <Trans>Potenza elettrica massima in prelievo</Trans>,
              validation: { number: true, minVal: 0 },
              md: 6,
              addon: 'kW',
            },
            {
              name: 'electMaxPowerInjection',
              type: 'text',
              label: <Trans>Potenza elettrica massima in immissione</Trans>,
              validation: { number: true, minVal: 0 },
              md: 6,
              addon: 'kW',
            },
            {
              name: 'totAuxsHourlyNrgConsumpution',
              type: 'text',
              label: <Trans>Consumo orario tot. degli ausiliari (impianti di prod. energetica)</Trans>,
              validation: { number: true, minVal: 0 },
              md: 6,
              addon: 'kWh',
            },
          ]}
          initialValues={initialValues}
        />
        {vectorsData.map(item => (
          <Vector
            readOnly={readOnly}
            vector={item.item}
            initialValues={(initialValues.vectorsData || []).find(x => x.vNK === item.item.naturalKey) || {}}
            key={item.item.naturalKey}
            ref={item.ref}
            costOptions={costOptions}
          />
        ))}
      </div>
    );
  }
}

VectorList.propTypes = {
  vectors: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

VectorList.defaultProps = {
  initialValues: {},
};

export default VectorList;
