export default [
  { name: 'AND' },
  { name: 'OR' },
  { name: 'NOT' },
  { name: '>' },
  { name: '<' },
  { name: '≥' },
  { name: '≤' },
  { name: '≠' },
  { name: '=' },
];
