import React from 'react';

// set the defaults
const TemplatesContext = React.createContext({
  templates: [],
  supportedDevices: [],
  onEditTemplate: () => {},
  onAddTemplate: () => {},
});

export default TemplatesContext;
