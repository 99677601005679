import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PlantSelection from './PlantSelection';
import NotificationPreferences from './NotificationPreferences';

import { loadTree, savePreferences } from './preferences.api';
import { Spinner } from 'app/common';
import logger from 'app/common/log';
import {
  getCompleteEventTypeList,
  fixDefaultNotificationPreferences,
  getPlantName
} from './helpers';
import { translateSeverity, translateTags } from '../helpers/CatalogsTranslate.js';
import { changeNotifications, loadPreferences } from 'ducks/actions/preferences';

const RoutingPage = props => {
  const {
    match,
    selectedDomain,
    catalogs,
    user,
    companies,
    domains,
    sites,
    sitegroups,
    currentPreferences,
    changeNotifications,
    loadPreferences
  } = props;
  const { url } = match;
  const [domainPlantsTree, setDomainPlantsTree] = useState({});
  const { id: selectedDomainId } = selectedDomain || {};
  const [eventTypes, setEventTypes] = useState([]);
  const [loading, setLoading] = useState(0);
  const [preferences, setPreferences] = useState(currentPreferences);
  const addLoading = () => setLoading(c => ++c);
  const removeLoading = () => setLoading(c => (c > 0 ? --c : 0));

  const eventTagsCatalog =
    catalogs &&
    catalogs['event/tags'] &&
    catalogs['event/tags']
      .filter(tag => !tag.companyId || companies.find(company => company.id === tag.companyId))
      .map(item => ({
        ...item,
        localName: translateTags(item.naturalKey, catalogs)
      }));

  const eventSeverities =
    catalogs &&
    catalogs['event/severities'] &&
    catalogs['event/severities'].map(item => ({
      ...item,
      localName: translateSeverity(item.naturalKey, catalogs)
    }));

  useEffect(() => {
    logger.debug('loadTree useEffect');
    addLoading();
    loadTree(selectedDomainId).then(plantsData => {
      removeLoading();
      const newData = plantsData.err ? [] : plantsData.data;
      setDomainPlantsTree(newData);
    });
  }, [selectedDomainId]);

  useEffect(() => {
    logger.debug('useEffect setUserPreferences %o', [
      eventTypes,
      user,
      selectedDomainId,
      currentPreferences
    ]);
    if (selectedDomainId && user && selectedDomainId && currentPreferences) {
      const { notifications } = currentPreferences;
      const newNotificationPreferences = fixDefaultNotificationPreferences({
        domainId: selectedDomainId,
        user,
        eventTypes,
        notifications
      });
      logger.debug('useEffect newNotificationPreferences %o', newNotificationPreferences);
      setPreferences(prefs => ({ ...prefs, notifications: newNotificationPreferences }));
      // changeNotifications(newNotificationPreferences);
    }
  }, [eventTypes, user, selectedDomainId, currentPreferences]);

  // useEffect(() => {}, [notificationPreferences, domainPlantsTree]);
  //   useWhatChanged([eventTypes, user, selectedDomainId], 'eventTypes, user, selectedDomain');

  useEffect(() => {
    logger.debug('eventTypes useEffect', catalogs['event/types']);
    addLoading();
    getCompleteEventTypeList(catalogs).then(eventTypes => {
      removeLoading();
      setEventTypes(eventTypes);
    });
  }, [catalogs['event/types']]);

  async function submitNotificationPreferences(newNotificationPreferences) {
    const domainId = selectedDomain && selectedDomain.id;
    logger.debug('submitNotificationPreferences ', domainId, newNotificationPreferences);
    //Load from backend and update newNotificationPreferences
    loadPreferences().then(async (loadedData) => {
      const newPreferences = { ...loadedData.preferences, notifications: newNotificationPreferences };
      const result = await savePreferences(newPreferences);
      const { err, data } = result;
      if (!err) {
        logger.debug('submitNotificationPreferences setUserPreferences: ', data);
        changeNotifications(data.preferences.notifications);
        //setUserPreferences(data);
      }
      return result;
    });
  }

  return (
    <>
    <Spinner loading={loading} overlay/>
    <Switch>
      <Route
        exact
        path={[`${url}/event/:channelKey`, `${url}/tag/:tagKey`]}
        render={props => (
          <PlantSelection
            userPreferences={preferences}
            onSubmit={submitNotificationPreferences}
            domainPlantsTree={domainPlantsTree}
            {...props}
          />
        )}
      />
      <Route
        path={`${url}`}
        render={props => (
          <NotificationPreferences
            onSubmit={submitNotificationPreferences}
            eventTypes={eventTypes}
            userPreferences={preferences}
            selectedDomain={selectedDomain}
            getPlantName={(id, type) =>
              getPlantName({ companies, domains, sites, sitegroups, id, type })
            }
            eventSeverities={eventSeverities}
            eventTagsCatalog={eventTagsCatalog}
            {...props}
          />
        )}
      />
    </Switch>
    </>
  );
};

const mapStateToProps = state => {
  const { navigation, catalogs, auth, preferences } = state;
  const { user } = auth;
  const {
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup,
    companies,
    domains,
    sites,
    sitegroups
  } = navigation;
  return {
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup,
    catalogs,
    companies,
    domains,
    sites,
    sitegroups,
    user,
    currentPreferences: preferences
  };
};

const mapDispatchToProps = dispatch => ({
  changeNotifications: notifications => dispatch(changeNotifications(notifications)),
  loadPreferences: () => dispatch(loadPreferences()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoutingPage);
