/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Slider } from 'app/common';
import api from 'api';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import moment from 'moment';
import ConfirmActionModal from './ConfirmActionModal';
import { lastValueUpdated } from '../common';
import { numberToLocaleString } from 'app/utils/formatNumber';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  padding: 0.5rem;
`;

const Name = styled.div`
  font-size: 0.9vw;
  color: #666;
  margin-top: 0.5rem;
`;

const SliderContainer = styled.div`
  width: 100%;
  padding: 0;
  background-color: #fff;

  & input {
    width: 100%;
  }
`;

const Label = styled.div`
  width: 3rem;
  font-size: 0.8vw;
  text-align: ${props => props.align};
`;

const ValuesContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Value = styled.div`
  width: auto;
  height: 2vw;
  text-align: center;
  font-size: 1.2vw;
  margin-top: 0.2rem;
  cursor: pointer;
  color: ${props => props.color};
  font-weight: bold;
`;

const Unit = styled.span`
  margin-left: 0.3rem;
  font-size: 1vw;
`;

const ValueInput = styled.input`
  width: auto;
  height: 2vw;
  text-align: center;
  font-size: 1vw;
  margin-top: 0.2rem;
  padding: 0rem;
  border: ${props => `1px solid ${props.color}`};
  border-radius: 3px;
  outline: none;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 2px 2px 4px gray;
  color: #999;
  max-width: 20rem;
  padding: 0.4rem;
  font-size: 0.8rem;
  top: -2rem;
  right: 1rem;
  color: #666;
  font-weight: 600;
`;

const SliderWidget = props => {
  const { widget, data, width, height, canSendCommand, toggleFullscreen, isFullscreen } = props;
  const { period, key } = widget;
  const { interval, aggregation } = period;

  // OP properties
  const { id, label, unitData, _unit, color, value, properties,decimalDigits } = data;
  const { min, max, step } = properties;

  const waitResponseMaxTime = 12000;
  // Widget properties
  const { askConfirm } = widget.properties;

  const { v: currentValue } = value;
  // const currentTime = new Date(value.t);

  const [sliderValue, setSliderValue] = useState(currentValue);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [oldValue, setOldValue] = useState(false);
  const [fromFullscreen, setFromFullscreen] = useState(false);

  const inputRef = useRef();
  const timerId = useRef();
  const valueToSend = useRef();

  const sliderDisabled = waitingResponse || !canSendCommand || currentValue === undefined;

  console.log(isFullscreen);

  useEffect(() => {
    if (waitingResponse) {
      timerId.current = setTimeout(() => {
        setWaitingResponse(false);
        setSliderValue(currentValue);
        toast.warn(i18n._(t`Il valore di ${label} non è stato aggiornato`));
      }, waitResponseMaxTime);
      return () => clearTimeout(timerId.current);
    }
  }, [waitingResponse]);

  useEffect(() => {
    setSliderValue(currentValue);
    clearTimeout(timerId.current);
    setWaitingResponse(false);
    const oldVal = lastValueUpdated(interval, aggregation, value);
    setOldValue(oldVal);
  }, [currentValue]);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const toggleConfirm = () => {
    fromFullscreen && toggleFullscreen();
    setConfirmOpen(open => !open);
  }

  const sendValue = async newValue => {
    setConfirmOpen(false);
    setWaitingResponse(true);
    fromFullscreen && toggleFullscreen();
    try {
      // calcolo il valore in base all'unità di misura
      const { exactoConversionFactor } = unitData;
      const value = newValue * exactoConversionFactor;
      await api.post(`/ObservedProperties/${id}/value`, { value });
      setEditedValue(true);
      setOldValue(false);
    } catch (e) {
      console.log(e.message);
      toast.error(
        i18n._(t`Impossibile inviare il comando. Controllare che il gateway sia connesso`)
      );
      setWaitingResponse(false);
      setSliderValue(currentValue);
    }
  };

  const prepareToSendValue = () => {
    if (askConfirm) {
      if(isFullscreen){
        setFromFullscreen(true);
        toggleFullscreen();
      }else{
        setFromFullscreen(false);
      }
      setConfirmOpen(true);
    } else {
      sendValue(valueToSend.current);
    }
  };

  const changeSliderValue = e => {
    const { target } = e;
    const { value } = target;
    setSliderValue(value);
    valueToSend.current = value;
  };

  const startManualInput = () => {
    if (!sliderDisabled) {
      setEditValue(sliderValue);
      setIsEditing(true);
    }
  };

  const changeManualInput = e => {
    const { target } = e;
    const { value } = target;
    setEditValue(value);
  };

  const cancelManualInput = () => {
    setIsEditing(false);
  };

  const validValues = useMemo(() => {
    const values = [];
    let i = min;
    while (i <= max) {
      values.push(i);
      i = parseFloat((i + step).toFixed(10));
    }
    return values;
  }, [min, max, step]);

  const getValidValueIndex = value => {
    if (value < validValues[0]) {
      return 0;
    }
    if (value > validValues[validValues.length - 1]) {
      return validValues.length - 1;
    }
    let i = 0;
    while (i < validValues.length - 1) {
      if (value >= validValues[i] && value < validValues[i + 1]) {
        return i;
      }
      i++;
    }
    return i;
  };

  const confirmManualInput = () => {
    const editValueNumber = +editValue;
    if (!validValues.includes(editValueNumber)) {
      cancelManualInput();
      return;
    }
    setSliderValue(editValueNumber);
    valueToSend.current = editValueNumber;
    setIsEditing(false);

    prepareToSendValue();
  };

  const handleKey = e => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        const currentIndex = getValidValueIndex(+editValue);
        console.log(currentIndex);
        if (currentIndex !== null && currentIndex > 0) {
          setEditValue(validValues[currentIndex - 1]);
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const currentIndex = getValidValueIndex(+editValue);
        console.log(currentIndex);
        if (currentIndex !== null && currentIndex < validValues.length - 1) {
          setEditValue(validValues[currentIndex + 1]);
        }
        break;
      }
      case 'Enter':
        confirmManualInput();
        break;
      case 'Escape':
        cancelManualInput();
        break;
      default:
    }
  };

  const getLabel = sliderValue => {
    const valueLabel = numberToLocaleString(sliderValue, decimalDigits);
    return oldValue && !editedValue ? `*${valueLabel}` : valueLabel;
  };

  const showTooltip = () => {
    const { t } = value;
    const element = document.getElementById(`tooltip-${key}-${id}`);
    element.style.visibility = 'visible';
    element.innerHTML = `Valore del ${moment(t).format('DD/MM/YYYY, HH:mm:ss')}`;
  };

  const hideTooltip = () => {
    const element = document.getElementById(`tooltip-${key}-${id}`);
    element.style.visibility = 'hidden';
  };

  const confirmName = label;
  const confirmValue = `${valueToSend.current} ${_unit}`;

  return (
    <>
      <ConfirmActionModal
        isOpen={confirmOpen}
        toggle={toggleConfirm}
        onConfirm={() => sendValue(valueToSend.current)}
        onCancel={() => {
          setSliderValue(currentValue);
          fromFullscreen && toggleFullscreen();
        }}
      >
        <Trans>
          Impostare il valore di <strong>{confirmName}</strong> a <strong>{confirmValue}</strong>?
        </Trans>
      </ConfirmActionModal>
      <Wrapper width={width} height={height}>
        <div>
          <Name>{label}</Name>
        </div>
        <SliderContainer>
          <Slider
            type="range"
            disabled={sliderDisabled}
            color={color}
            value={sliderValue}
            min={min}
            max={max}
            step={step}
            onChange={changeSliderValue}
            onMouseDown={changeSliderValue}
            onMouseUp={prepareToSendValue}
            onKeyDown={e => {
              e.preventDefault();
            }}
          />
        </SliderContainer>
        <ValuesContainer onMouseOver={showTooltip} onMouseOut={hideTooltip}>
          <Label align="left">{min}</Label>
          {isEditing ? (
            <ValueInput
              type="number"
              ref={inputRef}
              color={color}
              value={editValue}
              onChange={changeManualInput}
              onBlur={confirmManualInput}
              onKeyDown={handleKey}
            />
          ) : (
            <Value color={color} onClick={startManualInput}>
              {getLabel(sliderValue)}
              <Unit>{_unit}</Unit>
            </Value>
          )}
          <Tooltip id={`tooltip-${key}-${id}`}></Tooltip>
          <Label align="right">{max}</Label>
        </ValuesContainer>
      </Wrapper>
    </>
  );
};

export default SliderWidget;
