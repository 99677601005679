/* eslint-disable no-loop-func */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteTab, addTab, saveTab, loadTabs, addTabData } from 'ducks/actions/databrowsing';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import ErrorBoundary from './ErrorBoundary';
import FilterBox from './FilterBox';
import TableData from './TableData';
import TabSlide from '../../common/TabSlide';
import Charts from './Charts';
import logger from 'app/common/log';
import {
  CHART_TYPE_CURVA,
  CHART_TYPE_SCATTER,
  CHART_TYPE_PIE_VAR,
  CHART_TYPE_PIE_TIME,
  CHART_TYPE_CUSUM,
  CHART_TYPE_DISTRBUZIONE,
  CHART_TYPE_CARPET,
  DATA_FOR_CSV_DATA,
  DATA_FOR_CSV_HEADER,
  DATA_FOR_XLS_DATA,
  getSequenceData,
  getPieData,
  getCusumData,
  getDistribuzioneValue,
  getHeatMapData,
  getScatterData,
  getCurvaCaricoData,
} from './Functions';

const EmptyChartWrap = styled.div`
  padding: 100px;
  text-align: center;
`;

const EmptyChartIcon = styled(FontAwesomeIcon)`
  &&&& {
    width: 200px;
    height: 200px;
    margin: 5px;
    color: #bdbdbd;
  }
`;

const TabPaneAutoH = styled.div`
  min-height: calc(100vh - 125px);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #c8ced3;
  margin-top: -2px;
`;

class DataBrowsing extends Component {
  constructor(props) {
    super(props);

    this.figure = undefined;
    this.graphDiv = undefined;
    this.chartDet = React.createRef();

    this.state = {
      exportData: {},
      selectedTabId: null,
      openDeleteDialog: false,
      tab2delete: null
    };
  }

  componentDidUpdate(prevProps) {
    const { databrowsing, loadTabs, selectedTabId, isDomainLoaded } = this.props;

    /* uso il type della navigazione per aveitare chiamate multiple sulla seleziona asyncrona del dominio */
    if (isDomainLoaded && (isDomainLoaded !== prevProps.isDomainLoaded)){
      loadTabs();
    }

    if (JSON.stringify(prevProps.databrowsing.tabs) !== JSON.stringify(databrowsing.tabs)) {
      this.setSelectedTab(selectedTabId); // controllo l'integrità della selezione del tab
    }

    /* quando si effettua un pin l'offlineid cambia quindi deve essere riattribuita l'essatta associazione al tab */
    if (selectedTabId !== this.state.selectedTabId) {
      this.setState({ selectedTabId });
    }
  }

  componentDidMount = async () => {
    const { databrowsing, isDomainLoaded } = this.props;
    console.log(this.props);
    const { selectedTabId } = this.state;
    const selectedTab = databrowsing.tabs.find(f => f.offlineId === selectedTabId);
    if (selectedTab && selectedTab.tableMode) {
      //this.setExportData(selectedTab, units);
    }
    if(isDomainLoaded){
      loadTabs();
    }
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  setSelectedTab = tabId => {
    const { databrowsing, addTabData } = this.props;
    const { selectedTabId } = this.state;
    const checkSelectedTabId =
      (tabId === undefined || databrowsing.tabs.filter(x => x.offlineId === tabId).length === 0) &&
      databrowsing.tabs !== undefined &&
      databrowsing.tabs.length > 0
        ? databrowsing.tabs[0].offlineId
        : tabId;
    const tabFinded = databrowsing.tabs.find(f => f.offlineId === checkSelectedTabId);

    /* 'seriesActive' in tabFinded permette un caricamento di dati in loro assenza, capita quando il tab è stato caricato dal session storage */
    if (tabFinded && (checkSelectedTabId !== selectedTabId || !('seriesActive' in tabFinded))) {
      this.setState({ selectedTabId: checkSelectedTabId });
      addTabData(checkSelectedTabId);
    }
  };

  saveAs = (uri, filename) => {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();

      // remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  updateDimensions = () => {
    this.forceUpdate();
  };

  toggle = selectedTabId => {
    this.setSelectedTab(selectedTabId);
  };

  deleteUserTab = async () => {
    const { tab2delete } = this.state;
    const { selectedTabId, databrowsing } = this.props;
    if (tab2delete) {
      await this.props.deleteTab(tab2delete);

      this.toggleDel();
      this.updateDimensions();

      /* reimposta la posizioen del tab se quello selezionato è quello eliminato */
      if (tab2delete.offlineId === selectedTabId && databrowsing.tabs.length > 0) {
        this.setSelectedTab(null);
      }

      window.addEventListener('resize', this.updateDimensions);
    }
  };

  toggleDel = () => {
    const { openDeleteDialog } = this.state;
    this.setState({ openDeleteDialog: !openDeleteDialog });
  };

  openForDelete = (e, tab) => {
    e.stopPropagation();
    const { openDeleteDialog } = this.state;
    this.setState({ openDeleteDialog: !openDeleteDialog, tab2delete: tab });
  };

  addTab = async () => {
    const { databrowsing } = this.props;
    try {
      /* cerca la disponibilità del nuovo nome */
      let findCount = 0;
      let finded;
      const baseTabName = i18n._(t`Nuovo tab`);
      let tabName = baseTabName;
      do {
        if (findCount > 0) tabName = `${baseTabName} (${findCount})`;
        finded = databrowsing.tabs.find(f => f.name === tabName);
        findCount += 1;
      } while (finded);

      const newTab = await this.props.addTab(tabName, [], []);
      this.setSelectedTab(newTab.offlineId);
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions);
    } catch (err) {
      console.error(err);
    }
  };

  changeLock = (e, tab, status) => {
    e.stopPropagation();
    tab.lock = status;
    this.props.saveTab(tab, false);
  };

  onChartInizialized = (figure, graphDiv) => {
    this.figure = figure;
    this.graphDiv = graphDiv;
  };

  onImageExport = (type, filename) => {
    if (type === 'svg') {
      if (this.graphDiv !== undefined) {
        import('plotly.js').then(Plotly => {
          Plotly.downloadImage(this.graphDiv, {
            format: type,
            height: 768,
            width: 1280,
            filename
          });
        });
      }
    } else {
      html2canvas(this.chartDet.current).then(canvas => {
        this.saveAs(canvas.toDataURL(type), filename);
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { databrowsing } = this.props;

    if (newIndex >= databrowsing.tabs.length) {
      let k = newIndex - databrowsing.tabs.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        databrowsing.tabs.push(undefined);
      }
    }
    databrowsing.tabs.splice(newIndex, 0, databrowsing.tabs.splice(oldIndex, 1)[0]);
    this.forceUpdate();

    /* parti dall'ordinamento minimo per salvare tutti i nuovi ordinamenti */
    databrowsing.tabs.forEach(async (tab, key) => {
      tab.order = key;
      this.props.saveTab(tab, false);
    });
  };

  saveNameTab = (tab, name) => {
    tab.name = name;
    this.props.saveTab(tab, false);
  };

  getExportData = (tab, units) => {
    console.log('getExportData');
    let csvHeaders = false;
    let csvData = false;
    let excelData = false;
    if (tab && 'seriesActive' in tab) {
      switch (tab.chartType) {
        case CHART_TYPE_PIE_VAR:
        case CHART_TYPE_PIE_TIME:
          csvHeaders = getPieData(tab, DATA_FOR_CSV_HEADER);
          csvData = getPieData(tab, DATA_FOR_CSV_DATA);
          excelData = getPieData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_CUSUM:
          csvHeaders = getCusumData(tab, DATA_FOR_CSV_HEADER);
          csvData = getCusumData(tab, DATA_FOR_CSV_DATA);
          excelData = getCusumData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_DISTRBUZIONE:
          csvHeaders = getDistribuzioneValue(tab, DATA_FOR_CSV_HEADER, null);
          csvData = getDistribuzioneValue(tab, DATA_FOR_CSV_DATA, units);
          excelData = getDistribuzioneValue(tab, DATA_FOR_XLS_DATA, units);
          break;
        case CHART_TYPE_CARPET:
          csvHeaders = getHeatMapData(tab, DATA_FOR_CSV_HEADER);
          csvData = getHeatMapData(tab, DATA_FOR_CSV_DATA);
          excelData = getHeatMapData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_SCATTER:
          csvHeaders = getScatterData(tab, DATA_FOR_CSV_HEADER);
          csvData = getScatterData(tab, DATA_FOR_CSV_DATA);
          excelData = getScatterData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_CURVA:
          csvHeaders = getCurvaCaricoData(tab, DATA_FOR_CSV_HEADER, null);
          csvData = getCurvaCaricoData(tab, DATA_FOR_CSV_DATA, units);
          excelData = getCurvaCaricoData(tab, DATA_FOR_XLS_DATA, units);
          break;
        default:
          csvHeaders = getSequenceData(tab, DATA_FOR_CSV_HEADER, null);
          csvData =
            getSequenceData(tab, DATA_FOR_CSV_DATA, units) &&
            getSequenceData(tab, DATA_FOR_CSV_DATA, units).reverse();
          excelData =
            getSequenceData(tab, DATA_FOR_XLS_DATA, units) &&
            getSequenceData(tab, DATA_FOR_XLS_DATA, units).reverse();
          break;
      }
    }

    return { csvData, excelData, csvHeaders };
  };

  setExportData = (tab, units) => {
    logger.debug('Databrowsing setExportData %o %o', tab, units);
    const exportData = this.getExportData(tab, units);
    logger.debug('exportData %o', exportData);
    this.setState({exportData}, () => {
      console.log('setState exportData');
    });
    // this.setState(exportData);
  };

  render() {
    const { databrowsing } = this.props;
    const { selectedTabId, openDeleteDialog, tab2delete } = this.state;
    const selectedTab = databrowsing.tabs.find(f => f.offlineId === selectedTabId);

    return (
      <div>
        {databrowsing.tabs && databrowsing.tabs.length > 0 && selectedTabId && (
          <div>
            <TabSlide
              addTab={this.addTab}
              items={databrowsing.tabs}
              toggle={this.toggle}
              activeTab={selectedTabId}
              onSortEnd={this.onSortEnd}
              changeLock={this.changeLock}
              openForDelete={this.openForDelete}
              saveNameTab={this.saveNameTab}
            />
            {selectedTab && (
              <TabPaneAutoH id="tabContent" activeTab={selectedTabId}>
                <FilterBox
                  tabId={selectedTab.offlineId}
                  onImageExport={this.onImageExport}
                  exportData={this.state.exportData}
                />
                <div ref={this.chartDet}>
                  {!selectedTab.tableMode && (
                    <ErrorBoundary>
                      <Charts tab={selectedTab} onChartInizialized={this.onChartInizialized} />
                    </ErrorBoundary>
                  )}
                  {selectedTab.tableMode && (
                    <ErrorBoundary>
                      <TableData tabId={selectedTab.offlineId} />
                    </ErrorBoundary>
                  )}
                </div>
              </TabPaneAutoH>
            )}
          </div>
        )}

        {(!databrowsing.tabs || databrowsing.tabs.length === 0 || !selectedTabId) && (
          <EmptyChartWrap>
            <EmptyChartIcon icon={faChartLine} />
            <br />
            <Button color="primary" onClick={this.addTab}>
              <Trans>Crea pannello di navigazione dati</Trans>
            </Button>
          </EmptyChartWrap>
        )}

        <Modal style={{ maxWidth: 600 }} isOpen={openDeleteDialog} toggle={this.toggleDel}>
          <ModalHeader toggle={this.toggleDel}>
            <Trans>Elimina pannello</Trans>
          </ModalHeader>
          <ModalBody>
            {tab2delete
              ? `Sicuro di voler eliminare il pannello ${tab2delete.name}?`
              : 'Sicuro di voler eliminare il pannello?'}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteUserTab}>
              <Trans>Elimina</Trans>
            </Button>
            <Button color="secondary" onClick={this.toggleDel}>
              <Trans>Annulla</Trans>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DataBrowsing.propTypes = {
  addTabData: PropTypes.func.isRequired, // from redux
  databrowsing: PropTypes.object.isRequired, // from redux
  navigation: PropTypes.object.isRequired, // Redux
  selectedDomain: PropTypes.object, // from redux
  selectedCompany: PropTypes.object, // from redux
  selectedSite: PropTypes.object, // from redux
  selectedAsset: PropTypes.object, // from redux
  selectedSitegroup: PropTypes.object, // from redux
  type: PropTypes.string.isRequired, // from redux
  user: PropTypes.object.isRequired, // from redux
  saveTab: PropTypes.func.isRequired, // from redux
  deleteTab: PropTypes.func.isRequired, // from redux
  addTab: PropTypes.func.isRequired, // from redux
  loadTabs: PropTypes.func.isRequired, // from redux
  selectedTabId: PropTypes.number
};

DataBrowsing.defaultProps = {
  selectedTabId: undefined,
  selectedDomain: null,
  selectedCompany: null,
  selectedSite: null,
  selectedAsset: null,
  selectedSitegroup: null
};

const mapStateToProps = state => ({
  databrowsing: state.databrowsing,
  tabs: state.databrowsing.tabs,
  units: state.catalogs.units,
  selectedTabId: state.databrowsing.selectedTabId,
  navigation: state.navigation,
  selectedDomain: state.navigation.selectedDomain,
  selectedCompany: state.navigation.selectedCompany,
  selectedSite: state.navigation.selectedSite,
  selectedAsset: state.navigation.selectedAsset,
  selectedSitegroup: state.navigation.selectedSitegroup,
  type: state.navigation.type,
  user: state.auth.user,
  isDomainLoaded: state.navigation.isDomainLoaded
});

const mapDispatchToProps = dispatch => ({
  loadTabs: () => dispatch(loadTabs()),
  addTabData: tabId => dispatch(addTabData(tabId)),
  saveTab: (tab, reload) => dispatch(saveTab(tab, reload)),
  deleteTab: tab => dispatch(deleteTab(tab)),
  addTab: (name, variables, dates) => dispatch(addTab(name, variables, dates))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataBrowsing);
