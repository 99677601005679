import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'app/common';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import PeriodSelector from '../WidgetForm/PeriodSelector';
const TimeProfilePeriodModal = props => {
  const { isOpen, toggle, onCancel, title, widget, editWidget } = props;
  const [periodRef] = useState(React.createRef());
  const valueTypes = widget.variables.map(x => x.valueType);

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
    toggle();
  };

  const onConfirm = async () => {
    try{
      const newWidget = { ...widget };
      const newPeriod =  await periodRef.current.getValues();
      newWidget.period = newPeriod;
      console.log('onConfirm ', newWidget);
      editWidget(newWidget);  
    }
    catch(err) {
      console.error('TimeProfilePeriodModal onConfirm ',err);
    };
    cancel();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Trans>Seleziona il periodo e la granularità dei dati da visualizzare nel widget.</Trans>
        <div style={{ marginTop: '1rem', marginBottom: '3rem', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Trans>
            Tali modifiche non verranno salvate in modo permanente. Per modificare permanentemente
            il periodo e la granularità, è necessario cambiare le impostazioni del widget aprendo la
            dashboard in modalità di configurazione.
          </Trans>
        </div>
        <PeriodSelector
          currentModule={'dashboard'}
          ref={periodRef}
          widgetType={widget.widgetType}
          valueTypes={valueTypes}
          periodCompetence={widget.period.interval}
          period={widget.period}
        />        
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}>
          <Trans>Annulla</Trans>
        </Button>
        <Button marginright="true" color="primary" onClick={onConfirm}>
          <Trans>OK</Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

TimeProfilePeriodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.element,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string
};

TimeProfilePeriodModal.defaultProps = {
  children: PropTypes.element,
  title: <Trans>Confermi</Trans>
};

export default TimeProfilePeriodModal;
