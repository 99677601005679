import React from 'react';
import * as go from 'gojs';
import ViewButton from '../Form/ViewButton';
import { GojsDiagram } from 'react-gojs';
import { Redirect } from 'react-router-dom';
import { AuthControl, Roles } from 'app/common';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import NodeInfo from '../Form/NodeInfo';
import cGraph from '../ConfigGraph';
import ReactResizeDetector from 'react-resize-detector';
import { getTranslatedString } from '../TranslateData';
import { ToolTipDiv, DiagramContainer } from '../Helpers/Styler';
import ObservedPropertiesData from '../Helpers/ObservedPropertiesData';
import { t } from '@lingui/macro';
import i18n from 'app/i18n';



class NodeGraphViewer extends React.Component {
  nodeId = 0;

  flowId = 0;
  
  state = {
    selectedNodeKeys: [],
    model:
    go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [],
      linkDataArray: [],
    }),
  };


  constructor(props) {
    super(props);
    this.interval = null;
    const { parentGraphId, siteId, graphData, observedProperties, energyAsset, vectors, meterAsset, catalogs, virtualMeter } = props;
    this.parentGraphId = parentGraphId;
    this.siteId = siteId;
    this.diagram = null;
    this.firstDataSettet = true;
    this.key = null;

    this.graphData = graphData;
    this.observedProperties = observedProperties;
    this.energyAsset = energyAsset;
    this.vectors = vectors;
    this.meterAsset = meterAsset;
    this.opd = new ObservedPropertiesData({observedProperties, graphData, catalogs, virtualMeter});
  }

  componentDidMount() {
    this.energyAsset = [];
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentDidUpdate() {
    this.firstDataSettet = true;
    const { redirectTo } = this.state;
    const { parentGraphId, siteId } = this.props;
    if(this.siteId !== siteId || parentGraphId !== this.parentGraphId)
    {
      this.parentGraphId = parentGraphId;
      this.siteId = siteId;
      this.onUpdate();
      //this.getGraphData();
    }else if (redirectTo !== undefined && redirectTo !== false) {
      this.onUpdate();
    } 
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    const { unsubscribe } = this.props;
    if(this.key != null)
    {
      unsubscribe(this.key);
    }
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onUpdate = () => {
    const state = {
      selectedNodeKeys: [],
      assetListSet: false,
      graphDataSet: false,
      meterAssetDataSet: false,
      redirectToEdit: false,
      redirectTo: false,
      model:
      go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [],
        linkDataArray: [],
      }),
    };
    this.setState(state);
  }

  setObservedPropertyValues = () => {
    const { lastValues, status } = this.props;
    console.log(lastValues);
    const { key } = this;
    const { model } = this.state;

    this.opd.setObservedPropertyValues(model, status, key, lastValues, (linkDataArray, nodeDataArray)=>{
      this.diagram.model.linkDataArray = linkDataArray;
      this.diagram.model.nodeDataArray = nodeDataArray;
      go.GraphObject.bind();
    });
  }

  getObservedPropertyValues = () => {
    const { graphData } = this;
    if (!graphData) {
      return;
    }

    const { model } = this.state;
    
    const ids = this.opd.getObservedPropertyValues(model, (linkDataArray, nodeDataArray)=>{
      this.diagram.model.linkDataArray = linkDataArray;
      this.diagram.model.nodeDataArray = nodeDataArray;
      go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...linkDataArray],
        linkDataArray: [...nodeDataArray],
      });
      go.GraphObject.bind();
    });
    
    const { subscribe } = this.props;
    console.log(subscribe);
    const { setObservedPropertyValues } = this;
    console.log(ids);
    this.key = subscribe(ids, setObservedPropertyValues);
  
    //this.interval = setInterval(this.refreshValori,10000);
  }

  getMeterAsset = (nodeKey) => {
    return this.opd.getMeterAsset(nodeKey);
  }

  dataSettet = () => {
    const { graphData } = this;
    const { assets, assetFlows } = graphData;
    const nodeDataArray = [];
    
    assets.forEach((asset) => {
      const item = this.energyAsset.find(ea => ea.id === asset.supportedAssetId);
      const newItem = JSON.parse(JSON.stringify(item));
      const location = new go.Point(asset.graphSetting.location.x, asset.graphSetting.location.y);
      const { measureAssetsList, fieldDevicesList } = this.getMeterAsset(asset.id);
      const newNode = {
        visible_ma_panel: false,
        location,
        graphSetting: asset.graphSetting,
        key: asset.id,
        item: newItem,
        id: item.id,
        name: asset.name,
        source: `${cGraph.symbol_path}${newItem.icon}`,
        isComplex: asset.childGraph !== undefined,
        graphChildId: null,
        measureAssetsList,
        fieldDevicesList,
        property: {
          make: asset.make ? asset.make : '',
          model: asset.model ? asset.model : '',
          inventory: asset.inventory ? asset.inventory : '',
          technicalData: asset.technicalData ? asset.technicalData : {},
        },
      };
       if (asset.qualifier !== undefined) {
        newNode.qualifier = asset.qualifier;
      }

      if (asset.addedTo !== undefined) {
        newNode.addedTo = asset.addedTo;
        newNode.group = `grp-${asset.addedTo}`;
      }
      if (asset.addon !== undefined) {
        newNode.addon = asset.addon;
        newNode.group = `grp-${asset.id}`;
        const groupData = {
          key: `grp-${asset.id}`,
          isGroup: true,
          name: JSON.parse(JSON.stringify(asset.name)),
        }
        nodeDataArray.push(groupData);
      }
      nodeDataArray.push(newNode);
    });
    this.addFlows(assetFlows, nodeDataArray, (flow, nodi) => {

      nodi.forEach((nodo) => {
        this.setNodeMetersIcon(nodo, flow);
      });
      const model = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodi],
        linkDataArray: [...flow],
      });
      this.setState({
        model,
      }, () => {
        this.getObservedPropertyValues();
        if (this.firstDataSettet) {
          this.firstDataSettet = false;
          if (this.diagram) {
             this.diagram.zoomToFit();
          }
         
        }
      });
    });
  }

  setNodeMetersIcon = (nodo, flow) => {
     if (nodo.isGroup) {
      return nodo;
    }
    const { measureAssetsList, key } = nodo;
    nodo.visible_ma1 = false;
    nodo.visible_ma2 = false;
    nodo.visible_ma3 = false;
    nodo.visible_ma4 = false;
    nodo.is_nodo_ma1 = true;
    nodo.is_nodo_ma2 = true;
    nodo.is_nodo_ma3 = true;
    nodo.is_nodo_ma4 = true;
    nodo.grandezza_ma1 = "";
    nodo.grandezza_ma2 = "";
    nodo.grandezza_ma3 = "";
    nodo.grandezza_ma4 = "";
    nodo.misura_ma1 = "";
    nodo.misura_ma2 = "";
    nodo.misura_ma3 = "";
    nodo.misura_ma4 = "";
    nodo.variabile_ma1 = "";
    nodo.variabile_ma2 = "";
    nodo.variabile_ma3 = "";
    nodo.variabile_ma4 = "";
    nodo.visible_more = false;
    let mcount = 0;
    const { catalogs } = this.props;
    const { physicalQuantities } = catalogs;
    for (let i = 0; i < measureAssetsList.length; i += 1) {
      if (measureAssetsList[i].display !== false) {
        const flowIndex = flow.findIndex(f => f.id === measureAssetsList[i].flowId);
        let observedPropertyId = measureAssetsList[i].observedPropertyId;
        if(measureAssetsList[i].type && measureAssetsList[i].type === 'vm'){
          observedPropertyId = this.opd.getRealObservedPropertyId(measureAssetsList[i].observedPropertyId);
          console.log(observedPropertyId);
        }
        if (flowIndex === -1) {
          const asset = this.energyAsset.find(ea => ea.id === nodo.item.id);
          let { dynamicParameters, actuationParameters } = asset;
          if (actuationParameters !== null) {
            dynamicParameters = dynamicParameters.concat(actuationParameters);
          }
          const { vName } = measureAssetsList[i];
          const variabile = dynamicParameters.find(v => v.name === vName);
          if(variabile == null){
            continue;
          }
          mcount += 1;
          nodo[`visible_ma${mcount}`] = true;
            
          nodo[`color_ma${mcount}`] = '#eee';
          nodo[`grandezza_ma${mcount}`] = physicalQuantities.find(pq => pq.naturalKey === measureAssetsList[i].vNk).name
          nodo[`misura_ma${mcount}`] = this.observedProperties.find(pq => pq.id === observedPropertyId).name
          nodo[`variabile_ma${mcount}`] = getTranslatedString("dynamicParameters",variabile.name);
          nodo[`value_ma${mcount}`] = '---';
          nodo[`source_ma${mcount}`] = `${cGraph.symbol_path}dashboard.svg`;
        }else{
          if(flow[flowIndex].from === key)
          {
            flow[flowIndex].visibleOut = true;
            flow[flowIndex].valueOut = '---';
            flow[flowIndex].errorOut = null;
          }else{
            flow[flowIndex].visibleIn = true;
            flow[flowIndex].valueIn = '---';
            flow[flowIndex].errorIn = null;
          }
          
          flow[flowIndex].is_nodo = false;
          flow[flowIndex].grandezza = physicalQuantities.find(pq => pq.naturalKey === measureAssetsList[i].vNk).name;
          console.log(measureAssetsList[i]);
          flow[flowIndex].misura = this.observedProperties.find(pq => pq.id === observedPropertyId).name;
          flow[flowIndex].flusso = flow[flowIndex].vectorItem.name;
        }
      }
    }
    nodo.visible_ma_panel = mcount > 0;
    return nodo;
  }

  updateWindowDimensions = () => {
    if (this.diagram != null) {
      const { div } = this.diagram;
      const h = window.innerHeight - 166;
      div.style.height = `${h}px`;
      this.diagram.requestUpdate();
    }
  }

  
  onEdit = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      redirectToEdit: true,
    });
  }

  showToolTip = (obj, diagram, tool) => {
    const toolTipDIV = document.getElementById('toolTipDIV');
    const pt = diagram.lastInput.viewPoint;
    toolTipDIV.style.left = (pt.x - 15) + "px";
    const tGrandezza = i18n._(t`Grandezza`);
    const tMisura = i18n._(t`Misura`);
    if (obj.is_nodo) {
      const tVariabile = i18n._(t`Variabile`);
      document.getElementById('toolTipParagraph').innerHTML = `<strong>${tVariabile}: </strong>${obj.variabile}<br />
      <strong>${tGrandezza}: </strong>${obj.grandezza}<br />
      <strong>${tMisura}: </strong>${obj.misura}`;
    } else {
      const tFlusso = i18n._(t`Flusso`);
      document.getElementById('toolTipParagraph').innerHTML = `<strong>${tFlusso}: </strong>${obj.flusso}<br />
      <strong>${tGrandezza}: </strong>${obj.grandezza}<br />
      <strong>${tMisura}: </strong>${obj.misura}`;
    }
    toolTipDIV.style.display = "block";
    toolTipDIV.style.top = (pt.y - document.getElementById('toolTipDIV').clientHeight + 20) + "px";
  }

  hideToolTip = (diagram, tool) => {
    const toolTipDIV = document.getElementById('toolTipDIV');
    toolTipDIV.style.display = "none";
  }

  createDiagram = (diagramId) => {
    const $ = go.GraphObject.make;
    const myToolTip = $(go.HTMLInfo, {
      show: this.showToolTip,
      hide: this.hideToolTip,
    });
    const NodeGraph = $(go.Diagram, diagramId, {
      initialContentAlignment: go.Spot.Center,
      initialAutoScale: go.Diagram.Uniform,
      isReadOnly: true,
      allowHorizontalScroll: true,
      allowVerticalScroll: true,
      allowZoom: true,
      allowSelect: true,
    });

    NodeGraph.toolManager.hoverDelay = 0;
    NodeGraph.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
    NodeGraph.nodeTemplate = $(go.Node, 'Auto', { fromSpot: go.Spot.RightSide, toSpot: go.Spot.LeftSide }, new go.Binding('location'), { selectionChanged: node => this.nodeSelectionHandler(node.key, node.isSelected) },
      $(go.Shape, 'RoundedRectangle', { fill: 'white', parameter1: 3, stroke: '#c8ced3' }),
      $(go.Panel, 'Table',
        $(go.RowColumnDefinition, { column: 0, alignment: go.Spot.Left }),
        $(go.RowColumnDefinition, { column: 1, alignment: go.Spot.Center }),
        $(go.RowColumnDefinition, { column: 2, alignment: go.Spot.Right }),
        $(go.Panel, 'Table', { column: 0, row: 0, rowSpan: 3, columnSpan: 2 },
          $(go.Panel, 'Table',
            { column: 0, row: 0, columnSpan: 3, defaultAlignment: go.Spot.Left },
            $(go.Panel, 'Table', { column: 0, row: 1 },
 
              $(go.TextBlock, { toolTip: myToolTip }, { column: 1, row: 0, margin: new go.Margin(0, 7, 0, 2), font: 'normal 12px sans-serif' },
                new go.Binding('visible', 'visible_ma1'),
                new go.Binding('grandezza', 'grandezza_ma1'),
                new go.Binding('variabile', 'variabile_ma1'),
                new go.Binding('is_nodo', 'is_nodo_ma1'),
                new go.Binding('misura', 'misura_ma1'),
                new go.Binding('text', 'value_ma1'))),

            $(go.Panel, 'Table', { column: 0, row: 2 },
       
              $(go.TextBlock, { toolTip: myToolTip },{ column: 1, row: 0, margin: new go.Margin(0, 7, 0, 2), font: 'normal 12px sans-serif' },
                new go.Binding('visible', 'visible_ma2'),
                new go.Binding('grandezza', 'grandezza_ma2'),
                new go.Binding('variabile', 'variabile_ma2'),
                new go.Binding('misura', 'misura_ma2'),
                new go.Binding('is_nodo', 'is_nodo_ma2'),
                new go.Binding('text', 'value_ma2'))),
            $(go.Panel, 'Table', { column: 0, row: 3 },
         
              $(go.TextBlock, { toolTip: myToolTip },{ column: 1, row: 0, margin: new go.Margin(0, 7, 0, 2), font: 'normal 12px sans-serif' },
                new go.Binding('visible', 'visible_ma3'),
                new go.Binding('grandezza', 'grandezza_ma3'),
                new go.Binding('variabile', 'variabile_ma3'),
                new go.Binding('misura', 'misura_ma3'),
                new go.Binding('is_nodo', 'is_nodo_ma3'),
                new go.Binding('text', 'value_ma3'))),
            $(go.Panel, 'Table', { column: 0, row: 4 },
    
              $(go.TextBlock, { toolTip: myToolTip },{ column: 1, row: 0, margin: new go.Margin(0, 7, 0, 2), font: 'normal 12px sans-serif' },
                new go.Binding('visible', 'visible_ma4'),
                new go.Binding('grandezza', 'grandezza_ma4'),
                new go.Binding('variabile', 'variabile_ma4'),
                new go.Binding('misura', 'misura_ma4'),
                new go.Binding('is_nodo', 'is_nodo_ma4'),
                new go.Binding('text', 'value_ma4'))),

            new go.Binding('visible', 'visible_ma_panel')),
          $(go.Picture, { column: 1, row: 1, columnSpan: 3, alignment: go.Spot.Center, margin: 10, width: 50, height: 50, background: 'white' },
            new go.Binding('source')),
          $(go.TextBlock,
            '', { column: 1, row: 2, columnSpan: 3, alignment: go.Spot.Center, margin: 5, stroke: 'black', font: 'normal 12px sans-serif', wrap: go.TextBlock.WrapFit, width: 110, textAlign: 'center' },
            new go.Binding('text', '', (v) => { return v.qualifier !== undefined && v.qualifier != null ? v.qualifier : v.name; } ),
            new go.Binding('font', 'over', (v) => {
              return v ? 'bold 12px sans-serif' : 'normal 12px sans-serif';
            }))),
        $(go.Panel, 'Horizontal', { column: 0, row: 3 },
          $(go.Picture,
            { column: 0, row: 2, alignment: go.Spot.Right, margin: 0, width: 20, height: 20, background: 'white', source: `${cGraph.symbol_path}complex2.svg` }),
          new go.Binding('visible', 'isComplex'))));

    NodeGraph.linkTemplate = $(go.Link, { routing: go.Link.AvoidsNodes, corner: 15, curve: go.Link.JumpOver, fromEndSegmentLength:80, toEndSegmentLength:80 },
      $(go.Shape,
        { strokeWidth: 3, stroke: '#000' },
        new go.Binding('stroke', 'color')),
      $(go.Shape, { toArrow: 'Triangle', fill: '#000', stroke: '#000' },
        new go.Binding('fill', 'color'),
        new go.Binding('stroke', 'color')),
      $(go.Panel, 'Auto', { segmentIndex: 0, segmentOffset: new go.Point(NaN, NaN), segmentOrientation: go.Link.OrientUpright },
      
        $(go.TextBlock,{ toolTip: myToolTip }, { margin: 0, text: 'Prova OUT' },
          new go.Binding('visible', 'visibleOut'),
          new go.Binding('text', 'valueOut'),
          new go.Binding('is_nodo', 'is_nodo'),
          new go.Binding('grandezza', 'grandezza'),
          new go.Binding('misura', 'misura'),
          new go.Binding('flusso', 'flusso')),




        ),
      $(go.Panel, 'Auto', { segmentIndex: -1, segmentOffset: new go.Point(NaN, NaN), segmentOrientation: go.Link.OrientUpright },
      
        $(go.TextBlock, { toolTip: myToolTip },{ margin: 0, text: 'Prova IN' },
          new go.Binding('visible', 'visibleIn'),
          new go.Binding('text', 'valueIn'),
          new go.Binding('is_nodo', 'is_nodo'),
          new go.Binding('grandezza', 'grandezza'),
          new go.Binding('misura', 'misura'),
          new go.Binding('flusso', 'flusso')),
        ),
      {
          toolTip:  // define a tooltip for each node that displays the color as text
            $("ToolTip",
              $(go.TextBlock, { margin: 4 },
                 new go.Binding('text', 'vectorItem', (v) => {
                return v.name;
              }),),

            )  // end of Adornment
        }

      );
    NodeGraph.groupTemplate =
      $(go.Group, 'Vertical',
      $(go.Panel, 'Auto',
        $(go.Shape, 'RoundedRectangle',
          { name: 'SHAPE',
            parameter1: 3,
            fill: 'transparent',
            stroke: '#c8ced3',
          }),
        $(go.Panel, 'Table',
           { defaultAlignment: go.Spot.Left },
        $(go.TextBlock,'',{ row: 0, column: 0, margin:5, alignment: go.Spot.Center, font:'normal 12px sans-serif'},
          new go.Binding('text', 'name')),
        $(go.Placeholder, { padding: 10, row: 1, column: 0 })),
      ));
    this.diagram = NodeGraph;
    this.updateWindowDimensions();
    this.dataSettet();
    NodeGraph.addDiagramListener('InitialLayoutCompleted', () => {
      NodeGraph.zoomToFit();
    });
    return NodeGraph;
  }

  unselectNode = () => {
    this.diagram.clearSelection();
    this.setState({
        selectedNodeKeys: [],
      });
  }

  nodeSelectionHandler = (nodeKey, isSelected) => {
    const { model, selectedNodeKeys } = this.state;
    if (isSelected) {
      const snk = selectedNodeKeys;
      snk.push(nodeKey);
      this.setState({
    //    model: model2,
        selectedNodeKeys: snk,
      });
    } else {
      const nodeIndexToRemove = selectedNodeKeys.findIndex(key => key === nodeKey);
      if (nodeIndexToRemove === -1) {
        return;
      }
      const snk = selectedNodeKeys;
      snk.splice(nodeIndexToRemove, 1);

      this.setState({
      //  model: model2,
        selectedNodeKeys: snk,
      });
    }
  }

  linkAddedNoSave = (fromKey, toKey, vector, flowId, nodes, callback) => {
    const fromNodeIndex = nodes.findIndex(node => node.key === fromKey);
    if (fromNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    const toNodeIndex = nodes.findIndex(node => node.key === toKey);
    if (toNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    const newflowId = flowId;
    const outVectorId = nodes[fromNodeIndex].item.outFlow.vectors.findIndex(v => v.id === vector.id);

    if (nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector === undefined) {
      nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector = [];
    }
    nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector.push(vector);


    const inVectorId = nodes[toNodeIndex].item.inFlow.vectors.findIndex(v => v.id === vector.id);
    if (nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector === undefined) {
      nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector = [];
    }
    nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector.push(vector);
    callback(nodes, newflowId);
  }

  addFlows = (listaFlows, nodi, callback, fCount, fFlows) => {
    let count = fCount;
    let flows = fFlows;
    if (count === undefined) {
      count = -1;
      flows = [];
    }
    count += 1;
    if (listaFlows[count] === undefined) {
      callback(flows, nodi);
      return;
    }
    const f = listaFlows[count];

    const newThis = this;
    const { vectors } = this;
    const vector = vectors.find(v => v.naturalKey === f.vector);
    f.fromKey = f.fromKey ? f.fromKey : f.sourceAssetId;
    f.toKey = f.toKey ? f.toKey : f.destinationAssetId;
    this.linkAddedNoSave(f.fromKey, f.toKey, f.vector, f.id, nodi, (nodi) => {
      const linksToAdd = {
        from: f.sourceAssetId,
        to: f.destinationAssetId,
        color: vector.type.color,
        vectorItem: vector,
        id: f.id,
        visibleOut: false,
        valueOut: '',
        errorOut: null,
        visibleIn: false,
        valueIn: '',
        errorIn: null,
      };
      flows = flows.concat(linksToAdd);
      newThis.addFlows(listaFlows, nodi, callback, count, flows);
    });
  }

  showPrincipale = () => {
    this.setState({
      redirectTo: `/sites/${this.siteId}/management`,
    });
  }

  divResized = () => {
    this.diagram.requestUpdate();
  }

  render() {
    if(this.diagram !== undefined && this.diagram !== null)
    {
      this.diagram.requestUpdate();
    }
    const { redirectToEdit, selectedNodeKeys, model, redirectTo } = this.state;
    const { parentGraphId } = this;
    if (redirectToEdit !== undefined && redirectToEdit) {
      let target = `/sites/${this.siteId}/management/editgraph`;
      if (this.parentGraphId) {
        target = `/sites/${this.siteId}/management/editgraph/${this.parentGraphId}`;
      }
      return <Redirect push to={target} />;
    }
    if (redirectTo !== undefined && redirectTo !== false) {
      const target = redirectTo;
      return <Redirect push to={target} />;
    }
    const nodeDataArray = JSON.parse(JSON.stringify(model.nodeDataArray));
    const linkDataArray = JSON.parse(JSON.stringify(model.linkDataArray));
    

    return (
      <DiagramContainer>
          <Row style={{ marginRight: "-20px",  overflow: "hidden" }}>
            <Col md="12">
              <AuthControl domainRole={Roles.OPE_Commissioning}>
                <ViewButton
                  key="diagramButtons"
                  onEdit={this.onEdit}
                  onShowPrincipale={this.showPrincipale}
                  isComplexGraph={parentGraphId !== undefined}
                  cardRef={this.props.cardRef}
   
                />
              </AuthControl>
              <GojsDiagram
                key="gojsDiagram"
                diagramId="NodeGraphDiv"
                model={model}
                createDiagram={this.createDiagram}
                className="NodeGraph"
                onModelChange={() => {}}
              />
              <ToolTipDiv id="toolTipDIV">
                <div className="arrow bottom right"></div>
                <p id="toolTipParagraph" style={{margin: 0}}>Tooltip</p>
              </ToolTipDiv>
              <ReactResizeDetector handleWidth handleHeight onResize={this.divResized} />
            </Col>

            
              <NodeInfo
                selectedNodeKeys={selectedNodeKeys}
                nodeDataArray={nodeDataArray}
                linkDataArray={linkDataArray}
                graphData={this.graphData}
                siteId={this.siteId}
                parentGraphId={this.parentGraphId}
                assetGroups={this.graphData ? this.graphData.assetGroups : []}
                assets={this.graphData ? this.graphData.assets : []}
                onClose={this.unselectNode}
              />
           
          </Row>
        </DiagramContainer> 

    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

export default connect(mapStateToProps)(NodeGraphViewer);
