/** Author: Stefano Chessa*/
import React, { useState } from 'react';
import { ToastContainer, toast } from '../../../../../node_modules/react-toastify/index';
import { DynamicForm } from 'app/common';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { Redirect } from 'react-router-dom';

const CreateUser = (props) => {

    const { history, url, userData } = props;

    const [redirect, setRedirect] = useState(false);
    const [id, setId] = useState('');

    const submit = async (values) => {

        const body = {
            "firstName": values['firstName'],
            "lastName": values['lastName'],
            "username": values['username'],
            "email": values['email']
        }

        const res = await api.post('/UserInfos', body, { validateStatus: () => true });
        if (res.status >= 200 && res.status < 300) {
            toast.success("Utente creato");
            userData.push(res.data);
            setTimeout(() => {
                setId(res.data.id);
                setRedirect(true);
            }, 2000);

        } else if (res.status >= 300 && res.status < 400) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 400 && res.status < 500) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 500) {
            toast.warn(res.data.error.message);
        }

    };

    const cancel = () => {
        history.goBack();
    };

    return (
        <div>
            <ToastContainer></ToastContainer>

            {!redirect ? (<DynamicForm
                card
                title={<Trans>Crea utente</Trans>}
                onSubmit={submit}
                onCancel={cancel}
                fields={[
                    {
                        name: 'firstName',
                        type: 'text',
                        //md: 6,
                        label: <Trans>Nome</Trans>,
                        validation: {
                            required: true, minLength: 3
                        },
                    },
                    {
                        name: 'lastName',
                        type: 'text',
                        label: <Trans>Cognome</Trans>,
                        //md: 6,
                        validation: {
                            required: true, minLength: 3
                        }
                    },
                    {
                        name: 'username',
                        type: 'text',
                        label: <Trans>Username</Trans>,
                        //md: 6,
                        validation: {
                            required: true, minLength: 3
                        }
                    },
                    {
                        name: 'email',
                        type: 'text',
                        label: <Trans>Email</Trans>,
                        //md: 6,
                        validation: {
                            required: true, email: true
                        }
                    },
                ]}
            >
            </DynamicForm>) : (<Redirect to={`${url}/${id}`} ></Redirect>)}

        </div>
    );
}



CreateUser.propTypes = {
    history: PropTypes.object.isRequired,
};

export default CreateUser;