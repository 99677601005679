import React, { Component } from 'react';
import mqtt from 'mqtt';
import api from 'api';
import logger from 'app/common/log';

class RealTimeNotification extends Component {
  componentDidMount() {
    this.connection = false;
    this.createMQTTConnection();
  }

  getConnectionData = async () => {
    const connectionData = await api.get(`/Events/realTimeAccess`);
    return connectionData.data;
  };

  closeConnection = () => {
    if (this.connection) {
      this.connection.end();
      this.connection = false;
    }
  };

  createMQTTConnection = async () => {
    if (!this.connection) {
      // If there is no connection to the required data, create it and subscribe to the topic
      try {
        const { brokerUrl, clientId, token, topics } = await this.getConnectionData();
        const mqttOptions = {
          clientId,
          username: clientId,
          password: token
        };
        const [topic] = topics;
        const client = mqtt.connect(brokerUrl, mqttOptions);
        client.on('error', error => {
          logger.error('RTNotification brokerUrl %s clientId: %s ON topic %s error %s', brokerUrl, clientId, topic, error);
          this.closeConnection();
          setTimeout(() => {
            logger.debug('RTNotification Reconnecting after error');
            this.createMQTTConnection();
          }, 2000);
        });
        client.on('connect', () =>
          logger.debug('RTNotification connected brokerUrl %s clientId: %s ON topic %s', brokerUrl, clientId, topic)
        );
        client.on('reconnect', () =>
          logger.debug('RTNotification reconnected clientId: %s ON topic %s', clientId, topic)
        );
        client.on('close', () =>
          logger.debug('RTNotification closed clientId: %s ON topic %s', clientId, topic)
        );
        client.on('offline', () =>
          logger.debug('RTNotification offline clientId: %s ON topic %s', clientId, topic)
        );
        //   client.on('offline', () => console.log( 'RTNotification offline'));
        this.connection = client;
        // newConnection.client = client;

        client.subscribe(topic, error => {
          if (error) {
            logger.error(
              'RTNotification suscribe error clientId: %s ON topic %s error %s',
              clientId,
              topic,
              error
            );
          }
          client.on('message', (topic, message) => {
            this.handleMessage(message);
            logger.debug(
              'RTNotification handleMessage: %s ON topic %s error %o',
              clientId,
              topic,
              message
            );
          });
        });
      } catch (error) {
        logger.error('RTNotification suscribe %s', error);
        //this.upgradeStatus(newConnection, op, 'error', error);
        return { error };
      }
    }
  };

  parse = message => {
    try {
      return JSON.parse(message);
    } catch (e) {
      return message.toString();
    }
  };

  handleMessage = message => {
    const { onMessage } = this.props;
    if (onMessage) {
      onMessage(this.parse(message));
    }
  };

  render() {
    return <div></div>;
  }
}

export default RealTimeNotification;
