import { OP_TYPE_INSTANT } from 'app/pages/databrowsing/Functions';
import { formatNumber } from 'app/utils/formatNumber'
import { getTranslatedString } from '../TranslateData';
import api from 'api';
const OP_TYPE_BINARY = "binary";
class ObservedPropertiesData  {

  constructor(data) {
    this.data = data;
    const { observedProperties, graphData, catalogs, virtualMeter } = data;
    this.observedProperties = observedProperties;
    this.graphData = graphData;
    this.catalogs = catalogs;
    this.virtualMeter = virtualMeter;
  }

  getRealObservedPropertyId = (observedPropertyId) => {
    return observedPropertyId;
  }

  getAggregationRateByVm = (virtualMeterId) => {
    const vm = this.virtualMeter.find(vm => vm.id === virtualMeterId)
    if(vm == null){
      return null;
    }
    return vm.rate;
  }
  setObservedPropertyValues = (model, status, key, lastValues, callback) =>{
    console.log(status);
    console.log(key);
    console.log(lastValues);
    //return;
    const observedPropertyStatus = status[key].observedProperties;
    const { graphData, observedProperties } = this;
    const { nodeDataArray, linkDataArray } = model;
    if (!graphData) {
      return;
    }
    console.log(lastValues);
    const { assets } = graphData;
    const observedPropertyList = [];
    assets.forEach((asset) => {
      const { measureAssetsList } = this.getMeterAsset(asset.id);
      console.log(measureAssetsList);
      for (let i = 0; i < measureAssetsList.length; i += 1)  {
        const ma = measureAssetsList[i];
        if (ma.display) {
            let type = ma.type ? ma.type : 'op';
            if(ma.observedPropertyId  != null){
              const observedProperty = JSON.parse(JSON.stringify(observedProperties.find(op => op.id === ma.observedPropertyId)));
              ma.forceAggregation  = false;
              if(observedProperty.virtualMeterId != null){
                const ar = this.getAggregationRateByVm(observedProperty.virtualMeterId);
                ma.aggregationRate = ar ? ar : (ma.aggregationRate ? ma.aggregationRate : null);
                ma.forceAggregation = true;
              }
              const data = {
                observedPropertyId: ma.observedPropertyId,
                observedProperty,
                type,
                unit: ma.unit === undefined ? null : ma.unit,
                options: ma.options === undefined ? null : ma.options,
                aggregationRate: ma.aggregationRate === undefined ? null : ma.aggregationRate,
                assetIds: [ma.assetId],
                scale: 1,
                forceAggregation: ma.forceAggregation,
              };

              if (data.unit != null) {
                data.scale =  this.getScale(data.unit);
                data.unitTXT = data.scale.unit
              }else if(ma.vNk){
                data.scale =  this.getScaleByPhysicalQuantity(ma.vNk);
                data.unitTXT = data.scale.unit
              } else {
                data.scale = null;
                data.unitTXT = "";
              }
              
              if(data.observedPropertyId != null){
                observedPropertyList.push(data);
              }
            }
        }
      }
    });

    observedPropertyList.forEach((observedProperty) => {
      console.log(observedProperty);
      let { valueType, scale, offset } = observedProperty.observedProperty;
      if(observedProperty.scale != null){
        scale = observedProperty.scale.scale;
        offset = observedProperty.scale.offset;
      }
      const { aggregationRate } = observedProperty;
      let aggregation = valueType === OP_TYPE_INSTANT ? 'raw' : aggregationRate;
    /*  if(valueType !== OP_TYPE_INSTANT) {
        return;
      }*/

      if(observedProperty.forceAggregation && observedProperty.aggregationRate){
        aggregation = observedProperty.aggregationRate
      }

     
      const { observedPropertyId } = observedProperty;
        let value = null;
        const OPStatus = (observedPropertyStatus && observedPropertyStatus.find(op => op.id === observedPropertyId)) || {};
        console.log(OPStatus);
        const { status } = OPStatus;
        let changeValue = false;
        if (status === 'error') {
         
          value = "...";
          changeValue = true;
        } else {
          value = "...";
          const key = `${observedPropertyId}_${aggregation}`;
          console.log(key)
          if(lastValues[key]) {
            const newValue = this.applicateOptions(lastValues[key].v,observedProperty.options?observedProperty.options:null);
            const formattedValue = formatNumber(newValue, offset ? offset : 0, scale, valueType, -1, true);
            value = `${formattedValue && formattedValue.toLocaleString(undefined, { maximumFractionDigits: 4 })} ${observedProperty.unitTXT}`;
            changeValue = true;
            const translateAggregation = getTranslatedString('aggregationRate', aggregation);
            if(translateAggregation !== "raw") {
              value = `${value} (${translateAggregation})`;
            }
          }
        }
        
        for (let i = 0; i < observedProperty.assetIds.length ; i += 1) {
          const assetId = observedProperty.assetIds[i];

          const node = nodeDataArray.find(nd => nd.key === assetId);
          if (node) {
            const { measureAssetsList, key } = node;
            let cnt = 0;
            for (let j = 0 ; j < measureAssetsList.length; j += 1) {
              if (measureAssetsList[j].display && changeValue) {
                if(measureAssetsList[j].flowId == null){
                  cnt += 1;
                  if (measureAssetsList[j].observedPropertyId === observedProperty.observedPropertyId) {
                    node[`value_ma${cnt}`] = value;
                    measureAssetsList[j].value = value;
                  }
                }else{
                  const flowIndex = linkDataArray.findIndex(lk => lk.id === measureAssetsList[j].flowId);
                  if (measureAssetsList[j].observedPropertyId === observedProperty.observedPropertyId) {
                    if (linkDataArray[flowIndex].from === key) {
                      linkDataArray[flowIndex].valueOut = value;
                    }else{
                      linkDataArray[flowIndex].valueIn = value;
                    }
                    measureAssetsList[j].value = value;
                  }
                }
                
              }
            }
            
          }
        }
    });
    callback(linkDataArray, nodeDataArray);
  }

  getObservedPropertyValues = (model, callback) =>{
    const observedProperties = this.observedProperties;
    const graphData = this.graphData;
    const { assets } = graphData;
    const observedPropertyList = [];
    assets.forEach((asset) => {
      const { measureAssetsList } = this.getMeterAsset(asset.id);
      for (let i = 0; i < measureAssetsList.length; i += 1)  {
        const ma = measureAssetsList[i];
        if (ma.display) {
            let type = ma.type ? ma.type : 'op';
            if(type === "vm"){
              //ma.observedPropertyId = this.getRealObservedPropertyId(ma.observedPropertyId);
              type = 'op';
              ma.type = 'op';
            }
            if(ma.observedPropertyId  != null){
              const observedProperty = observedProperties.find(op => op.id === ma.observedPropertyId);
              ma.forceAggregation = false;
              if(observedProperty.virtualMeterId != null){
                const ar = this.getAggregationRateByVm(observedProperty.virtualMeterId);
                ma.aggregationRate = ar ? ar : (ma.aggregationRate ? ma.aggregationRate : null);
                ma.forceAggregation = true;
              }
              const data = {
                observedPropertyId: ma.observedPropertyId,
                observedProperty,
                type,
                unit: ma.unit === undefined ? null : ma.unit,
                options: ma.options === undefined ? null : ma.options,
                aggregationRate: ma.aggregationRate === undefined ? null : ma.aggregationRate,
                assetIds: [ma.assetId],
                forceAggregation: ma.forceAggregation,
              };
              if(data.observedPropertyId != null){
                observedPropertyList.push(data);
              }
              observedPropertyList.push(data);
            }
        }
      }
    });
    let ids = [];
    observedPropertyList.forEach((observedProperty) => {
      //observedProperty.observedProperty  = JSON.parse(JSON.stringify(observedProperty.observedProperty));
      //let observedProperty = JSON.parse(JSON.stringify(cicledObservedProperty));
      let defaultAggregation = '1dy';
      if(observedProperty.aggregationRate !== undefined)
      {
        defaultAggregation = observedProperty.aggregationRate;
      }
    
      let aggregation = (observedProperty.observedProperty.valueType === OP_TYPE_INSTANT || observedProperty.observedProperty.valueType === OP_TYPE_BINARY) ? 'raw' : defaultAggregation;
      if(observedProperty.forceAggregation && observedProperty.aggregationRate){
        aggregation = observedProperty.aggregationRate
      }
      const { observedPropertyId } = observedProperty;

      if(ids.find(lista => lista.id === observedPropertyId && lista.aggregation === aggregation ) === undefined){

        ids.push({
          id: observedPropertyId,
          aggregation,
        });
      }
  
      let scale = null; 
      observedProperty.observedProperty.lastCheck = null;
      console.log(observedProperty);
      console.log(observedProperty.unit);
      if (observedProperty.unit == null){

        scale = this.getScale(this.getVisualizationUnit(observedProperty.observedProperty.physicalQuantity));
        observedProperty.observedProperty.unit = scale.unit; 
        observedProperty.observedProperty.scale = scale.scale;
        observedProperty.observedProperty.offset = scale.offset;

        observedProperty.unit = scale.unit; 
        observedProperty.scale = scale.scale;
        observedProperty.offset = scale.offset;
      }else{
        scale = this.getScale(observedProperty.unit);
        observedProperty.observedProperty.unit = scale.unit;
        observedProperty.observedProperty.scale = scale.scale;
        observedProperty.observedProperty.offset = scale.offset;

        observedProperty.scale = scale.scale;
        observedProperty.offset = scale.offset;
      }
      
 //     if (observedProperty.observedProperty.valueType !== OP_TYPE_INSTANT){
        api.get(`ObservedProperties/${observedProperty.observedPropertyId}/${aggregation}/lastSample`).then((result) => {
          const status = {
            statickey : {
              status: 'connected',
            },
          };
          const key = 'statickey';
          const lastValues = {};
          lastValues[`${observedProperty.observedPropertyId}_${aggregation}`] = result;
          this.setObservedPropertyValues(model, status, key, lastValues, callback)
        });
    });
    return ids;
	}

  applicateOptions = (value, options) => {
    if (options == null) {
      return value;
    }
    if (!options['tipo-di-misura']) {
      return value;
    }
    if (options['tipo-di-misura'] === "prelievo" || options['tipo-di-misura'] === "immissione") {
      return value;
    }

    if(options['tipo-di-misura'] === "immissione-e-prelievo") {
      if(value>0){
        return value;
      }
      return 0;
    }
    if(options['tipo-di-misura'] === "prelievo-e-immissione") {
      if(value<0){
        return -value;
      }
      return 0;
    }
    return value;
  }

  getMeasureData = (measure) => {
    let { observedPropertyId } = measure;
    const { observedProperties } = this;
    if(measure.type && measure.type === "vm") {
      observedPropertyId = this.getRealObservedPropertyId(observedPropertyId);
    }
    const observedProperty = observedProperties.find(obs => obs.id === observedPropertyId);
    if (observedProperty === null || !observedProperty) {
      return null;
    }
    measure.vNk = observedProperty.physicalQuantity;
    return {
      measure,
      fieldDeviceId: observedProperty.fieldDeviceId,
    };
  }

  getMeterAsset = (nodeKey) => {
    const fieldDevicesList = [];
    const { assets, assetFlows } = this.graphData;
    const asset = assets.find(ass => ass.id === nodeKey);
    if (asset == null) {
      return null;
    }
    const measureAssetsList = [];
    const { measures, inFlowIds, outFlowIds } = asset;
    measures.forEach((meas) => {
      const measureData = this.getMeasureData(meas);
      if (measureData != null) {
        const retData = this.getMeasureData(meas);
        if(retData != null){
          const { measure, fieldDeviceId } = this.getMeasureData(meas);
          measure.direction = 'ASSET';
          measure.flowId = null;
          measureAssetsList.push(measure);
          if (fieldDevicesList.indexOf(fieldDeviceId) < 0) {
            fieldDevicesList.push(fieldDeviceId);
          }
        }
      }
    });
    outFlowIds.forEach((flowId) => {
      const flow = assetFlows.find(af => af.id === flowId);
      if (flow) {
        const { sourceMeasures } = flow;
        sourceMeasures.forEach((meas) => {
          if (meas.assetId === nodeKey) {
            const retData = this.getMeasureData(meas);
            if(retData != null){
              const { measure, fieldDeviceId } = retData;
              measure.direction = 'OUT';
              measure.flowId = flowId;
              measureAssetsList.push(measure);
              if (fieldDevicesList.indexOf(fieldDeviceId) < 0) {
                fieldDevicesList.push(fieldDeviceId);
              }
            }
          }
        });
      }
    });

    inFlowIds.forEach((flowId) => {
      const flow = assetFlows.find(af => af.id === flowId);
      if (flow) {
        const { destinationMeasures } = flow;
        destinationMeasures.forEach((meas) => {
          if (meas.assetId === nodeKey) {
            const retData = this.getMeasureData(meas);
            if(retData != null){
              const { measure, fieldDeviceId } = retData;
              measure.direction = 'IN';
              measure.flowId = flowId;
              measureAssetsList.push(measure);
              if (fieldDevicesList.indexOf(fieldDeviceId) < 0) {
                fieldDevicesList.push(fieldDeviceId);
              }
            }
          }
        });
      }
    });
    return {
      fieldDevicesList,
      measureAssetsList,
    };
  }

  

  getScale = (unitNK) => {
    
    const { catalogs } = this;
    const { units } = catalogs;
    
    const unit = units.find(un => un.naturalKey === unitNK);
    if (unit === undefined || unit == null) {
      return {
        unit: unitNK,
        scale: 1,
      };
    }
    let offset = 0;
    if(unit.symbol === "K")
    {
      offset = 273.15;
    }
    if(unit.symbol === "°F")
    {
      offset = 32;
    }

    const ret = {
      unit: unit.symbol,
      scale: unit.exactoConversionFactor,
      offset,
    };
    return ret;
  }


  getVisualizationUnit = (physicalQuantityNK) => {
    const { catalogs } = this;
    const { physicalQuantities } = catalogs;
    const pq = physicalQuantities.find(pq => pq.naturalKey === physicalQuantityNK);
    if (pq.defaultVisualizationUnit !== null) {
      return pq.defaultVisualizationUnit;
    }
    return pq.units[0];
  }

  getScaleByPhysicalQuantity = (vNk) => {
    return this.getScale(this.getVisualizationUnit(vNk));
  }

}


export default ObservedPropertiesData;