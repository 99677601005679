import styled from 'styled-components';
const ToolTipDiv = styled.div`
  background-color: #fff;
  border: 1px solid white;
  border-radius: 16px;
  min-width: 110px;
  padding: 20px;
  position: absolute;
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  & .bottom {
    border-style: solid;
    position: absolute;
    border-color: white transparent transparent transparent;
    border-width: 8px 8px 0px 8px;
    bottom: -8px;
    &:after {
      border-color: #fff transparent transparent transparent;
      border-style: solid;
      border-width: 7px 7px 0px 7px;
      bottom: 1px;
      content: "";
      position: absolute;
      left: -7px;
   } 
  }
`;


const DiagramContainer = styled.div`
  #NodeGraphDiv
  {
    background-color: white;
    border: 1px solid #ddd;
    padding: 0px;
    border-top: none;
  }
  #NodeGraphDiv div
  {
    display: none!important;
  }

  canvas {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
    position: initial!important;
  }

  .diagram-sidebar
  {
    background-color: white;
    border-right:1px solid #ccc;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    flex:auto;
    margin-left: -15px!important;
  }

  .diagram-sidebar .scrollbar-container
  {
      width:100%;
  }

  #barra_assets
  {
      overflow-y: auto!important;
      border-bottom: 0;
      scrollbar-width: thin;

  }

  .sidebar .nav
  {
      min-height: 0;
  }

  .NodeGraph2
  {
    padding-right: 0;
  }

  .centered-container {
    width: 100%;
    text-align: center;
  }

  .inline-element {
      display: inline-block;
  }

  #diagramButtonBar
  {
    border: 1px solid #ddd;
    border-bottom: none;
    padding: 2px;
    background-color: white;
  }
`;

export { ToolTipDiv, DiagramContainer }