import React from 'react';
import styled from 'styled-components';
import { MultiSelectTag, Wizard, WizardStep } from 'app/common';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Varie from '../Helpers/Varie';
import { Trans } from '@lingui/macro';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { OP_TYPE_INSTANT, getRateOptions } from 'app/pages/databrowsing/Functions';
import { getTranslatedString } from '../TranslateData';
import { Select } from 'app/common/index';

const Bold = styled.span`
  font-weight: bold;
`;

class meterAssetsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.selectAsset = [];
    this.assetMeasures = [];
    this.INMeasures = [];
    this.INAggregation = [];

    this.OUTMeasures = [];
    this.OUTAggregation = [];

    this.ASTMeasures = [];
    this.ASTAggregation = [];

    this.ACTMeasures = [];
    this.ACTAggregation = [];
  }

  state = {
    activeTab: '1',
    selectedMeterAssets: [],
    measureAssetsList: [],
    variables: []
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    this.setState({
      activeTab: '1',
      selectedMeterAssets: [],
      measureAssetsList: [],
    });
    this.varie = new Varie();

    const { nodeDataArray, selectedNode, linkDataArray } = this.props;
    const selectedMeterAssets = [];
    const node = nodeDataArray.find(nd => nd.key === selectedNode);
    let { measureAssetsList } = node;
    const { fieldDevicesList } = node;
    if (!measureAssetsList) {
      measureAssetsList = [];
    }

    let hasOut = false;
    let hasIn = false;
    const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === selectedNode);
    const inFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.to === selectedNode);

    let nout = 0;
    outFlowIndex.forEach((ofIndex) => {
      const flow = linkDataArray[ofIndex];
      const vars = flow.vectorItem.variables;
      if (vars) {
        nout += vars.length;
      }
    });
    hasOut = (nout !== 0);

    let nin = 0;
    inFlowIndex.forEach((ifIndex) => {
      const flow = linkDataArray[ifIndex];
      const vars = flow.vectorItem.variables;
      if (vars) {
        nin += vars.length;
      }
    });

    hasIn = (nin !== 0);

    if (!hasIn && hasOut) {
      this.setState({
        activeTab: '2',
        selectedMeterAssets: this.checkCanDelete(selectedMeterAssets),
        measureAssetsList,
        fieldDevicesList,
      });
    } else if (!hasIn && !hasOut) {
      this.setState({
        activeTab: '3',
        selectedMeterAssets: this.checkCanDelete(selectedMeterAssets),
        measureAssetsList,
        fieldDevicesList,
      });
    } else {
      this.setState({
        selectedMeterAssets: this.checkCanDelete(selectedMeterAssets),
        measureAssetsList,
        fieldDevicesList,
      });
    }
  }

  getMeasureUnitListFromPhysicalQuantity = (phdNaturalKey) => {
    const { catalogs } = this.props;
    const { physicalQuantities, units } = catalogs;
    const physicalQuantity = physicalQuantities.find(pq => pq.naturalKey === phdNaturalKey);
    if (physicalQuantity == null) {
      return null;
    }
    const pQUnits = physicalQuantity.units;
    const retUnits = [];
    pQUnits.forEach((phunit) => {
      let u = {};
      const unit = units.find(un => un.naturalKey === phunit);
      u.key = phunit;
      if (unit == null) {
        u.name = unit;
      } else {
        u.name = unit.symbol
      }
      retUnits.push(u);
    })
    let { defaultVisualizationUnit } = physicalQuantity;
    defaultVisualizationUnit = defaultVisualizationUnit != null ? defaultVisualizationUnit : retUnits[0].key;
    return {
      units: retUnits,
      defaultVisualizationUnit,
    };
  }

  getMeasureUnitFromPhysicalQuantity = (phdNaturalKey) => {
    const { catalogs } = this.props;
    const { physicalQuantities, units } = catalogs;
    const physicalQuantity = physicalQuantities.find(pq => pq.naturalKey === phdNaturalKey);
    if (physicalQuantity == null) {
      return null;
    }
    const phunit = physicalQuantity.defaultVisualizationUnit ? physicalQuantity.defaultVisualizationUnit : physicalQuantity.units[0];
    const unit = units.find(un => un.naturalKey === phunit);
    if (unit == null) {
      return null;
    }
    unit.physicalQuantityName = physicalQuantity.name;
    return unit;
  }

  checkCanDelete = (mAsset) => {
    const { nodeDataArray, selectedNode } = this.props;
    const node = nodeDataArray.find(nd => nd.key === selectedNode);
    const { measures } = node;
    mAsset.forEach((ma) => {
      ma.canDelete = true;
      if (measures) {
        measures.forEach((meterA) => {
          if (meterA.meterAsset.id === ma.id) {
            ma.canDelete = false;
          }
        });
      }
    });
    return mAsset;
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changedMeterAssetList = (data) => {
    const fieldDevicesList = [];
    const { value } = data.target;
    value.forEach((val) => {
      fieldDevicesList.push(val.id);
    });
    this.setState({
      selectedMeterAssets: this.checkCanDelete(data.target.value),
      fieldDevicesList,
    });
  }

  changeCheckBox = (event) => {
    const display = event.target.checked;
    const vNk = event.target.getAttribute('obspropsref');
    const flowId = event.target.getAttribute('flowref');
    let { measureAssetsList } = this.state;
    let index = -1;
    if (flowId == null) {
      const variableIndex = event.target.getAttribute('varref');
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === null && ma.variableIndex === variableIndex);
    } else {
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === flowId);
    }
    if (index === -1) {
      return;
    }
    measureAssetsList[index].display = display;
    this.setState({
      measureAssetsList,
    });
  }

  changeSelectAggregation = (aggregation, direction, flowIndex, variableIndex, vr) => {
    const { nodeDataArray, linkDataArray, selectedNode } = this.props;
    /* const variableIndex = event.target.getAttribute('varref');
     const direction = event.target.getAttribute('dirref');
     const flowIndex = event.target.getAttribute('flowref');
     const aggregation = event.target.value;*/
    let flow = null;
    let flowId = null;
    let vNk = null;

    if (direction === 'IN') {
      if (!this.INAggregation) {
        this.INAggregation = [];
      }
      if (!this.INAggregation[flowIndex]) {
        this.INAggregation[flowIndex] = [];
      }
      this.INAggregation[flowIndex][variableIndex] = aggregation;
      const inFlowIndex = this.varie.findIndexes(linkDataArray, node => node.to === selectedNode);
      flow = linkDataArray[inFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
    }

    if (direction === 'OUT') {
      if (!this.OUTAggregation) {
        this.OUTAggregation = [];
      }
      if (!this.OUTAggregation[flowIndex]) {
        this.OUTAggregation[flowIndex] = [];
      }
      this.OUTAggregation[flowIndex][variableIndex] = aggregation;
      const outFlowIndex = this.varie.findIndexes(linkDataArray, node => node.from === selectedNode);
      flow = linkDataArray[outFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
    }

    if (direction === 'ASSET') {
      if (!this.ASTAggregation) {
        this.ASTAggregation = [];
      }

      const nodeSelected = nodeDataArray.find(node => node.key === selectedNode);
      this.ASTAggregation[variableIndex] = aggregation;
      vNk = vr ? vr.physicalQUantityNK : nodeSelected.item.dynamicParameters[variableIndex].physicalQUantityNK;

      const assetIndex = this.assetMeasures.findIndex(am => am.variableIndex === variableIndex)

      if (assetIndex !== -1) {
        this.assetMeasures[assetIndex].aggregation = aggregation;
      }
    }

    const { measureAssetsList } = this.state;
    let index = -1;
    if (measureAssetsList) {
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === flowId);
      if (direction === 'ASSET') {
        index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === null && ma.variableIndex === variableIndex);
      }
    }
    if (index !== -1) {
      measureAssetsList[index].aggregation = aggregation;
      measureAssetsList[index].aggregationRate = aggregation;
    }
    this.setState({
      measureAssetsList,
    });
  }

  changeOption = (optValue, direction, option, variableIndex) => {
    const { nodeDataArray, selectedNode } = this.props;
    /*const variableIndex = parseInt(event.target.getAttribute('varref'));
    const direction = event.target.getAttribute('dirref');

    const option = event.target.getAttribute('optionref');
    const optValue = event.target.value;*/
    let flowId = null;
    let vNk = null;
    if (direction === 'ASSET') {
      //this.ASTMeasures[variableIndex] = optValue;
      const nodeSelected = nodeDataArray.find(node => node.key === selectedNode);
      vNk = nodeSelected.item.dynamicParameters[variableIndex].physicalQUantityNK;

      const assetIndex = this.assetMeasures.findIndex(am => am.variableIndex === variableIndex)

      if (assetIndex !== -1) {
        if (this.assetMeasures[assetIndex].options === undefined) {
          this.assetMeasures[assetIndex].options = {};
        }
        this.assetMeasures[assetIndex].options[option] = optValue;
      }
    }

    const { measureAssetsList } = this.state;
    let index = -1;
    if (measureAssetsList) {
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === flowId);
      if (direction === 'ASSET') {
        index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === null && ma.variableIndex === variableIndex);
      }
    }
    if (index !== -1) {
      if (measureAssetsList[index].options === undefined) {
        measureAssetsList[index].options = {};
      }
      measureAssetsList[index].options[option] = optValue;
    }
    this.setState({
      measureAssetsList,
    });
  }

  changeUnitSelect = (unit, direction, flowIndex, variableIndex, vr) => {
    const { nodeDataArray, linkDataArray, selectedNode } = this.props;
    /*  const variableIndex = event.target.getAttribute('varref');
      const direction = event.target.getAttribute('dirref');
      const flowIndex = event.target.getAttribute('flowref');
      const unit = event.target.value;*/
    let flow = null;
    let flowId = null;
    let vNk = null;

    if (direction === 'IN') {
      if (!this.INMeasures) {
        this.INMeasures = [];
      }
      if (!this.INMeasures[flowIndex]) {
        this.INMeasures[flowIndex] = [];
      }
      this.INMeasures[flowIndex][variableIndex] = unit;
      const inFlowIndex = this.varie.findIndexes(linkDataArray, node => node.to === selectedNode);
      flow = linkDataArray[inFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
    }

    if (direction === 'OUT') {
      if (!this.OUTMeasures) {
        this.OUTMeasures = [];
      }
      if (!this.OUTMeasures[flowIndex]) {
        this.OUTMeasures[flowIndex] = [];
      }
      this.OUTMeasures[flowIndex][variableIndex] = unit;
      const outFlowIndex = this.varie.findIndexes(linkDataArray, node => node.from === selectedNode);
      flow = linkDataArray[outFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
    }

    if (direction === 'ASSET') {
      if (!this.ASTMeasures) {
        this.ASTMeasures = [];
      }

      this.ASTMeasures[variableIndex] = unit;
      const nodeSelected = nodeDataArray.find(node => node.key === selectedNode);
      vNk = vr ? vr.physicalQUantityNK : nodeSelected.item.dynamicParameters[variableIndex].physicalQUantityNK;

      const assetIndex = this.assetMeasures.findIndex(am => am.variableIndex === variableIndex)
      if (assetIndex !== -1) {
        this.assetMeasures[assetIndex].unit = unit;
      }
    }

    const { measureAssetsList } = this.state;
    let index = -1;
    if (measureAssetsList) {
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === flowId);
      if (direction === 'ASSET') {
        index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === null && ma.variableIndex === variableIndex);
      }
    }
    if (index !== -1) {
      measureAssetsList[index].unit = unit;
    }
    this.setState({
      measureAssetsList,
    });
  }

  changeSelect = (observedPropertyId, direction, flowIndex, variableIndex, vr) => {
    const { nodeDataArray, linkDataArray, selectedNode } = this.props;
    const nodeSelected = nodeDataArray.find(node => node.key === selectedNode);
    const assetId = nodeSelected.key;
    let flow = null;
    let flowId = null;
    let vNk = null;
    let vName = null;
    let unit = null;
    if (direction === 'IN') {
      if (!this.INMeasures) {
        this.INMeasures = [];
      }
      if (!this.INMeasures[flowIndex]) {
        this.INMeasures[flowIndex] = [];
      }
      if (!this.INAggregation) {
        this.INAggregation = [];
      }
      if (!this.INAggregation[flowIndex]) {
        this.INAggregation[flowIndex] = [];
      }
      const inFlowIndex = this.varie.findIndexes(linkDataArray, node => node.to === selectedNode);
      flow = linkDataArray[inFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
      unit = this.INMeasures[flowIndex][variableIndex];
    }
    if (direction === 'OUT') {

      const outFlowIndex = this.varie.findIndexes(linkDataArray, node => node.from === selectedNode);
      flow = linkDataArray[outFlowIndex[flowIndex]];
      flowId = flow.id;
      vNk = flow.vectorItem.variables[variableIndex];
    }
    if (direction === 'ASSET') {
      if (!this.ASTMeasures) {
        this.ASTMeasures = [];
      }
      if (!this.ASTAggregation) {
        this.ASTAggregation = [];
      }
      let vars = nodeSelected.item.dynamicParameters;
      vNk = vr ? vr.physicalQUantityNK : vars[variableIndex].physicalQUantityNK;
      vName = vr ? vr.name : vars[variableIndex].name;
      unit = this.ASTMeasures[variableIndex];
    }

    if (direction === 'ACTUATION') {
      if (!this.ACTMeasures) {
        this.ACTMeasures = [];
      }
      if (!this.ACTAggregation) {
        this.ACTAggregation = [];
      }
      let vars = nodeSelected.item.actuationParameters;
      if (vars == null) {
        vars = [];
      }
      vNk = vr ? vr.physicalQUantityNK : vars[variableIndex].physicalQUantityNK;
      vName = vr ? vr.name : vars[variableIndex].name;
      unit = this.ACTMeasures[variableIndex];
    }
    const { measureAssetsList } = this.state;

    let index = -1;
    if (measureAssetsList) {
      index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === flowId);
      if (direction === 'ASSET' || direction === 'ACTUATION') {
        index = measureAssetsList.findIndex(ma => ma.vNk === vNk && ma.flowId === null && ma.vName === vName && (!vr.howManyIndex || ma.howManyIndex === vr.howManyIndex));
      }
    }

    if (observedPropertyId !== 0) {
      const data = {
        assetId,
        flowId,
        vNk,
        observedPropertyId,
        direction,
        display: false,
        unit
      };
      if (direction === 'ASSET' || direction === 'ACTUATION') {
        data.variableIndex = variableIndex;
        data.vName = vName;
        if (vr.howManyIndex) {
          data.howManyIndex = vr.howManyIndex;
        }
      }
      if (index >= 0) {
        data.display = measureAssetsList[index].display;
        measureAssetsList[index] = data;
      } else {
        measureAssetsList.push(data);
      }
    } else {
      measureAssetsList.splice(index, 1);
    }
    this.setState({
      measureAssetsList,
    });
  }

  renderOutData = () => {
    const RATE = getRateOptions();
    const nessunMeter = i18n._(t`Nessun meter asset`);
    const varie = new Varie();
    const outFlowHTML = [];
    let i = 0;
    let j = 0;
    let outTab = false;
    let unitOption = [];
    let cumulatedOption = [];
    let isCumulated = false;
    let unitOptionView = null;
    let option = [];
    const { nodeDataArray, linkDataArray, selectedNode, meterAssetList, virtualMeterList } = this.props;

    const { measureAssetsList } = this.state;
    let { selectedMeterAssets } = this.state
    if (selectedMeterAssets.length === 0) {
      selectedMeterAssets = meterAssetList;
    }
    const outFlowIndex = varie.findIndexes(linkDataArray, node => node.from === selectedNode);
    const IDs = {};
    outFlowIndex.forEach((ofIndex, currentI) => {
      const flow = linkDataArray[ofIndex];
      let vars = flow.vectorItem.variables;
      if (!Array.isArray(vars)) {
        vars = [];
        vars.push(flow.vectorItem.variables);
      }
      const otherNode = nodeDataArray.find(node => node.key === flow.to);
      const vectorName = flow.vectorItem.name;
      const { color } = flow;
      const othernodename = otherNode.name;
      j = -1;
      let checked = false;
      vars.forEach((vr) => {
        const vrData = this.getMeasureUnitFromPhysicalQuantity(vr);
        const { units, defaultVisualizationUnit } = this.getMeasureUnitListFromPhysicalQuantity(vr);
        if (vrData !== null) {
          j += 1;
          const currentJ = j;
          let checkbox = null;
          checked = false;
          isCumulated = false;
          let measureUnit = defaultVisualizationUnit;
          let aggregation = '1dy';
          let selectedValue = 0;
          option = [];
          option.push({ name: nessunMeter, id: 0 });
          selectedMeterAssets.forEach((sMeterAsset) => {
            let { observedProperties } = JSON.parse(JSON.stringify(sMeterAsset));
            observedProperties.forEach((observedProperty) => {
              if (observedProperty.physicalQuantity === vr) {
                let selected = false;
                if (measureAssetsList) {
                  measureAssetsList.forEach((ma) => {
                    if (ma.flowId === flow.id && ma.observedPropertyId === observedProperty.id) {
                      if (ma.unit !== undefined && ma.unit != null) {
                        measureUnit = ma.unit;
                      }
                      if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                        aggregation = ma.aggregationRate;
                      }
                      isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                      selected = true;
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                      if (ma.display) {
                        checked = true;
                        checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                      } else if (measureAssetsList.find(ma => ma.display && ma.flowId === flow.id)) {
                        checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                      }
                    }
                  });
                }

                if (selected) {
                  selectedValue = observedProperty.id;
                }
                option.push(observedProperty)
              }
            });
          });
          virtualMeterList.forEach((observedProperty) => {
            if (observedProperty.physicalQuantity === vr) {
              let selected = false;
              if (measureAssetsList) {
                measureAssetsList.forEach((ma) => {
                  if (ma.flowId === flow.id && ma.observedPropertyId === observedProperty.id) {
                    if (ma.unit !== undefined && ma.unit != null) {
                      measureUnit = ma.unit;
                    }
                    if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                      aggregation = ma.aggregationRate;
                    }
                    isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                    selected = true;
                    checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                    if (ma.display) {
                      checked = true;
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                    } else if (measureAssetsList.find(ma => ma.display && ma.flowId === flow.id)) {
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                    }
                  }
                });
              }
              if (selected) {
                selectedValue = observedProperty.id;
              }
              option.push(observedProperty)
            }
          });
          unitOption = [];
          const selectedMesureOption = measureUnit
          units.forEach((unit) => {
            if (unit.key === measureUnit) {
              unitOption.push(<option selected="selected" value={unit.key}>{unit.name}</option>);
              if (this.INMeasures[i] === undefined) {
                this.INMeasures[i] = [];
              }
              this.INMeasures[i][j] = unit.key;
              unitOptionView = unit.name;

            } else {
              unitOption.push(<option value={unit.key}>{unit.name}</option>);
            }

          });
          cumulatedOption = [];
          const selectedCumulatedOption = aggregation;
          RATE.forEach((option) => {
            const { mins } = option;
            if (mins > 1440) {
              return;
            }
            if (option.mins === 0 || option.mins === undefined) {
              return;
            }
            cumulatedOption.push(option)
          });

          const tr = (
            <tr>
              <td>
                <div
                  style={{ width: '10px', height: '10px', display: 'inline-block', backgroundColor: color }}
                />
                {vectorName}
              </td>
              <td>
                {othernodename}
              </td>
              <td>
                {vrData.physicalQuantityName}
              </td>
              <td>
                {checked ?
                  <Select
                    dirref="OUT"
                    flowref={i}
                    varref={j}
                    onChange={(selectedItem) => {
                      this.changeUnitSelect(selectedItem.target.value, "OUT", currentI, currentJ);
                    }}
                    className="form-control"
                    options={units}
                    value={selectedMesureOption}
                    labelProperty="name"
                    valueProperty="key"
                  /> : <span style={{ paddingLeft: '16px' }}>{unitOptionView}</span>
                }
              </td>
              <td>
                <Select
                  dirref="OUT"
                  flowref={i}
                  varref={j}
                  onChange={(selectedItem) => {
                    this.changeSelect(selectedItem.target.value, "OUT", currentI, currentJ);
                  }}
                  value={selectedValue}
                  options={option}
                  labelProperty="name"
                  valueProperty="id"
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                {checkbox}
              </td>
              <td>
                {checked && isCumulated ?
                  <Select
                    dirref="OUT"
                    flowref={i}
                    varref={j}
                    //onChange={this.changeSelectAggregation}
                    onChange={(selectedItem) => {
                      this.changeSelectAggregation(selectedItem.target.value, "OUT", currentI, currentJ);
                    }}
                    value={selectedCumulatedOption}
                    className="form-control"
                    labelProperty="label"
                    valueProperty="id"
                    options={cumulatedOption}
                  /> : null
                }
              </td>
            </tr>
          );
          outFlowHTML.push(tr);
          IDs[vr.id] = vr.id;
        }
      });

      if (vars.length > 0) {
        outTab = true;
      }
    });
    const ret = {
      outTab,
      outFlowHTML,
    };
    return ret;
  }

  renderInData = () => {
    const RATE = getRateOptions();
    const nessunMeter = i18n._(t`Nessun meter asset`);
    const varie = new Varie();
    const inFlowHTML = [];
    let i = 0;
    let j = 0;
    let inTab = false;
    let option = [];
    let unitOption = [];
    let cumulatedOption = [];
    const IDs = {};
    let isCumulated = false;
    const { nodeDataArray, linkDataArray, selectedNode, meterAssetList, virtualMeterList } = this.props;
    let { selectedMeterAssets } = this.state
    if (selectedMeterAssets.length === 0) {
      selectedMeterAssets = meterAssetList;
    }
    const { measureAssetsList } = this.state;

    const inFlowIndex = varie.findIndexes(linkDataArray, node => node.to === selectedNode);
    i = -1;
    inFlowIndex.forEach((ifIndex) => {
      i += 1;
      const currentI = i;
      const flow = linkDataArray[ifIndex];
      let vars = flow.vectorItem.variables;
      if (!Array.isArray(vars)) {
        vars = [];
        vars.push(flow.vectorItem.variables);
      }

      const otherNode = nodeDataArray.find(nd => nd.key === flow.from);
      const vectorName = flow.vectorItem.name;
      const { color } = flow;
      const othernodename = otherNode.name;
      j = -1;
      let checked = false;
      let unitOptionView = null;
      vars.forEach((vr) => {
        const vrData = this.getMeasureUnitFromPhysicalQuantity(vr);
        const { units, defaultVisualizationUnit } = this.getMeasureUnitListFromPhysicalQuantity(vr);
        if (vrData !== null) {
          checked = false;
          isCumulated = false;
          j += 1;
          const currentJ = j;
          let checkbox = null;

          let measureUnit = defaultVisualizationUnit;
          let aggregation = '1dy';
          let selectedValue = 0;
          option = [];
          option.push({ name: nessunMeter, id: 0 });
          selectedMeterAssets.forEach((sMeterAsset) => {
            let { observedProperties } = JSON.parse(JSON.stringify(sMeterAsset));

            observedProperties.forEach((observedProperty) => {
              if (observedProperty.physicalQuantity === vr) {
                let selected = false;
                if (measureAssetsList) {
                  measureAssetsList.forEach((ma) => {
                    if (ma.flowId === flow.id && ma.observedPropertyId === observedProperty.id) {

                      if (ma.unit !== undefined && ma.unit != null) {
                        measureUnit = ma.unit;
                      }
                      if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                        aggregation = ma.aggregationRate;
                      }
                      isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                      selected = true;
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                      if (ma.display) {
                        checked = true;
                        checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                      } else if (measureAssetsList.find(ma => ma.display && ma.flowId === flow.id)) {
                        checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                      }
                    }
                  });
                }
                if (selected) {
                  selectedValue = observedProperty.id;
                }
                option.push(observedProperty)
              }
            });
          });

          virtualMeterList.forEach((observedProperty) => {
            if (observedProperty.physicalQuantity === vr) {
              let selected = false;
              if (measureAssetsList) {
                measureAssetsList.forEach((ma) => {
                  if (ma.flowId === flow.id && ma.observedPropertyId === observedProperty.id) {
                    if (ma.unit) {
                      measureUnit = ma.unit;
                    }
                    if (ma.aggregationRate != null) {
                      aggregation = ma.aggregationRate;
                    }
                    isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                    selected = true;
                    checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                    if (ma.display) {
                      checked = true;
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                    } else if (measureAssetsList.find(ma => ma.display && ma.flowId === flow.id)) {
                      checkbox = <input obspropsref={observedProperty.physicalQuantity} flowref={flow.id} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                    }
                  }
                });
              }
              if (selected) {
                selectedValue = observedProperty.id;
              }
              option.push(observedProperty)
            }
          });

          unitOption = [];
          const selectedMesureOption = measureUnit
          units.forEach((unit) => {
            if (unit.key === measureUnit) {
              unitOption.push(<option selected="selected" value={unit.key}>{unit.name}</option>);
              if (this.INMeasures[i] === undefined) {
                this.INMeasures[i] = [];
              }
              this.INMeasures[i][j] = unit.key;
              unitOptionView = unit.name;
            } else {
              unitOption.push(<option value={unit.key}>{unit.name}</option>);
            }

          });
          cumulatedOption = [];

          const selectedCumulatedOption = aggregation;
          RATE.forEach((option) => {
            const { mins } = option;
            if (mins > 1440) {
              return;
            }
            if (option.mins === 0 || option.mins === undefined) {
              return;
            }
            cumulatedOption.push(option)
          });

          const tr = (
            <tr>
              <td>
                <div style={{ width: '10px', height: '10px', display: 'inline-block', backgroundColor: color }} />
                {vectorName}
              </td>
              <td>
                {othernodename}
              </td>
              <td>
                {vrData.physicalQuantityName}
              </td>
              <td>
                {checked ?
                  <Select
                    dirref="IN"
                    flowref={i}
                    varref={j}
                    onChange={(selectedItem) => {
                      this.changeUnitSelect(selectedItem.target.value, "IN", currentI, currentJ);
                    }}
                    className="form-control"
                    options={units}
                    value={selectedMesureOption}
                    labelProperty="name"
                    valueProperty="key"
                  /> : <span style={{ paddingLeft: '16px' }}>{unitOptionView}</span>
                }
              </td>
              <td>
                <Select
                  dirref="IN"
                  flowref={i}
                  varref={j}
                  onChange={(selectedItem) => {
                    this.changeSelect(selectedItem.target.value, "IN", currentI, currentJ);
                  }}
                  value={selectedValue}
                  options={option}
                  labelProperty="name"
                  valueProperty="id"
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                {checkbox}
              </td>

              <td>
                {checked && isCumulated ?
                  <Select
                    dirref="IN"
                    flowref={i}
                    varref={j}
                    //onChange={this.changeSelectAggregation}
                    onChange={(selectedItem) => {
                      this.changeSelectAggregation(selectedItem.target.value, "IN", currentI, currentJ);
                    }}
                    value={selectedCumulatedOption}
                    className="form-control"
                    labelProperty="label"
                    valueProperty="id"
                    options={cumulatedOption}
                  /> : null
                }
              </td>
            </tr>
          );
          inFlowHTML.push(tr);
          IDs[vr.id] = vr.id;
        }
      });
      if (vars.length > 0) {
        inTab = true;
      }
    });

    const ret = {
      inTab,
      inFlowHTML,
    };
    return ret;
  }

  renderAssetData = () => {
    const RATE = getRateOptions();
    const nessunMeter = i18n._(t`Nessun meter asset`);
    const assetHTML = [];
    let j = 0;
    let assetTab = false;
    let option = [];
    let cumulatedOption = [];
    let unitOptionView = null;
    let isCumulated = false;
    let measureOptions = null;

    const { nodeDataArray, selectedNode, meterAssetList, virtualMeterList } = this.props;
    let { selectedMeterAssets } = this.state
    if (selectedMeterAssets.length === 0) {
      selectedMeterAssets = meterAssetList;
    }
    const node = nodeDataArray.find(node => node.key === selectedNode);
    const { measureAssetsList } = this.state;
    let numVis = 0;
    if (measureAssetsList) {
      measureAssetsList.forEach((ma) => {
        if (ma.display && ma.flowId === null) {
          numVis += 1;
        }
      });
    }
    let vars = node.item.dynamicParameters;
    if (vars === undefined) {
      vars = [];
    }

    const updatedVars = [...vars.map(v => { return v.howMany ? { ...v, howManyIndex: 1 } : v })];
    if (vars.length > 0) {
      const howManyVars = [...updatedVars.filter(variable => variable.howMany)];
      howManyVars.forEach(hMVar => {
        if (node.property.technicalData[hMVar.howMany] > 1) {
          const index = updatedVars.findIndex(v => v.name === hMVar.name);
          const howManyArray = [];
          for (let index = 1; index < node.property.technicalData[hMVar.howMany]; index++) {
            howManyArray.push({ ...hMVar, howManyIndex: index + 1 })
          }
          updatedVars.splice(index + 1, 0, ...howManyArray);
        }
      });
    }

    this.assetMeasures = [];
    this.selectAsset = [];
    j = -1;
    let checked = false;
    updatedVars.forEach((vr) => {
      measureOptions = null;
      const vrData = this.getMeasureUnitFromPhysicalQuantity(vr.physicalQUantityNK);
      const { units, defaultVisualizationUnit } = this.getMeasureUnitListFromPhysicalQuantity(vr.physicalQUantityNK);
      if (vrData) {
        j += 1;
        const currentJ = j;
        let checkbox = null;
        checked = false;
        let measureUnit = defaultVisualizationUnit;
        let aggregation = '1dy';
        let selectedValue = 0;
        option = [];
        option.push({ name: nessunMeter, id: 0 });
        selectedMeterAssets.forEach((sMeterAsset) => {
          let { observedProperties } = JSON.parse(JSON.stringify(sMeterAsset));
          observedProperties.forEach((observedProperty) => {
            if (observedProperty.physicalQuantity === vr.physicalQUantityNK) {
              let selected = false;
              isCumulated = false;
              if (measureAssetsList) {
                measureAssetsList.forEach((ma) => {
                  if (ma.flowId === null && vr.physicalQUantityNK === ma.vNk && ma.observedPropertyId === observedProperty.id && ma.vName === vr.name && (!ma.howManyIndex || ma.howManyIndex === vr.howManyIndex)) {
                    if (ma.unit) {
                      measureUnit = ma.unit;
                    }
                    if (ma.options) {
                      measureOptions = ma.options;
                    }
                    if (ma.aggregationRate) {
                      aggregation = ma.aggregationRate;
                    }
                    isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                    selected = true;
                    checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                    if (ma.display) {
                      checked = true;
                      checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                    } else if (numVis >= 4) {
                      checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                    }
                    const assetIndex = this.assetMeasures.findIndex(am => am.vName === vr.name);
                    const dt = {
                      observedPropertyId: ma.observedPropertyId,
                      display: ma.display,
                      variableIndex: j,
                      assetId: selectedNode,
                      vName: vr.name,
                    };

                    if (ma.display) {
                      dt.unit = measureUnit;
                    }
                    if (ma.display && isCumulated) {
                      dt.aggregationRate = aggregation;
                    }
                    if (assetIndex !== -1) {
                      this.assetMeasures[assetIndex] = dt;
                    } else {
                      this.assetMeasures.push(dt);
                    }
                  }
                });
              }
              if (selected) {
                selectedValue = observedProperty.id;
              }
              option.push(observedProperty);
            }
          });
        });

        virtualMeterList.forEach((observedProperty) => {
          if (observedProperty.physicalQuantity === vr.physicalQUantityNK) {
            let selected = false;
            isCumulated = false;
            if (measureAssetsList) {
              measureAssetsList.forEach((ma) => {
                if (ma.flowId === null && vr.physicalQUantityNK === ma.vNk && ma.observedPropertyId === observedProperty.id && ma.vName === vr.name) {
                  if (ma.unit !== undefined && ma.unit != null) {
                    measureUnit = ma.unit;
                  }
                  if (ma.options !== undefined && ma.options != null) {
                    measureOptions = ma.options;
                  }
                  if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                    aggregation = ma.aggregationRate;
                  }

                  isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                  selected = true;
                  checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                  if (ma.display) {
                    checked = true;
                    checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                  } else if (numVis >= 4) {
                    checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                  }
                  const assetIndex = this.assetMeasures.findIndex(am => am.vName === vr.name);
                  const dt = {
                    observedPropertyId: ma.observedPropertyId,
                    display: ma.display,
                    variableIndex: j,
                    assetId: selectedNode,
                    vName: vr.name,
                  };

                  if (ma.display) {
                    dt.unit = measureUnit;
                  }
                  if (ma.display && isCumulated) {
                    dt.aggregationRate = aggregation;
                  }
                  if (assetIndex !== -1) {
                    this.assetMeasures[assetIndex] = dt
                  } else {
                    this.assetMeasures.push(dt);
                  }
                }
              });
            }

            if (selected) {
              selectedValue = observedProperty.id;
            }
            option.push(observedProperty);
          }
        });

        const selectedMesureOption = measureUnit
        units.forEach((unit) => {
          if (unit.key === measureUnit) {
            this.ASTMeasures[j] = unit.key;
            unitOptionView = unit.name;
          }
        });
        cumulatedOption = [];

        const selectedCumulatedOption = aggregation;
        RATE.forEach((option) => {
          const { mins } = option;
          if (mins > 1440) {
            return;
          }
          if (option.mins === 0 || option.mins === undefined) {
            return;
          }
          cumulatedOption.push(option)
        });

        let selectOptions = [];
        if (vr.options !== undefined) {
          const { options } = vr;
          options.forEach((option) => {
            const { values, name, ifAddedTo } = option;
            let addValue = true

            if (ifAddedTo && node.addedTo === undefined) {
              addValue = false;
            } else if (!ifAddedTo) {
              addValue = true;
            }
            if (addValue) {
              let optionsOptions = [];
              let selecteAddOption = null;
              values.forEach((v) => {
                const label = getTranslatedString("option", v);
                if (measureOptions && measureOptions[name] && measureOptions[name] === v) {
                  selecteAddOption = v;
                  //optionsOptions.push(<option selected = "selected" value={v}>{label}</option>)
                  optionsOptions.push({ value: v, label });
                } else {
                  optionsOptions.push({ value: v, label });
                }

              });
              const currentName = name;
              const optName = getTranslatedString("option", name);
              selectOptions.push(
                <tr>
                  <td>
                    <label>{optName}</label>
                  </td>
                  <td colspan="2">
                    <Select
                      dirref="ASSET"
                      varref={j}
                      onChange={(selectedItem) => {
                        this.changeOption(selectedItem.target.value, "ASSET", currentName, currentJ);
                      }}
                      className="form-control"
                      options={optionsOptions}
                      value={selecteAddOption}
                      labelProperty="label"
                      valueProperty="value"
                    />

                  </td>
                  <td colspan="2">
                    &nbsp;
                  </td>
                </tr>
              );
            }
          })
        }

        let { name, howManyIndex } = vr;

        let showRow = true;
        if (vr.if) {
          const ifKeys = Object.keys(vr.if);
          const index = ifKeys.findIndex(k => !vr.if[k].includes(node.property.technicalData[k]));
          showRow = index < 0;
        }

        if (showRow) {
          name = getTranslatedString('dinamycParameters', name);
          const tr = (
            <tr>
              <td>
                {howManyIndex ? `${name} ${howManyIndex}` : `${name}`}
              </td>
              <td>
                {checked ?
                  <Select
                    dirref="ASSET"
                    varref={j}
                    onChange={(selectedItem) => {
                      this.changeUnitSelect(selectedItem.target.value, "ASSET", undefined, currentJ, vr);
                    }}
                    className="form-control"
                    options={units}
                    value={selectedMesureOption}
                    labelProperty="name"
                    valueProperty="key"
                  /> : <span style={{ paddingLeft: '16px' }}>{unitOptionView}</span>
                }
              </td>
              <td>
                <Select
                  dirref="ASSET"
                  varref={j}
                  onChange={(selectedItem) => {
                    this.changeSelect(selectedItem.target.value, "ASSET", undefined, currentJ, vr);
                  }}
                  value={selectedValue}
                  // options={option}
                  options={howManyIndex ? option.filter(o => typeof (o.id) !== 'string') : option} // avoid vm
                  labelProperty="name"
                  valueProperty="id"
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                {checkbox}
              </td>
              <td>
                {checked && isCumulated ?
                  <Select
                    dirref="ASSET"
                    varref={j}
                    //onChange={this.changeSelectAggregation}
                    onChange={(selectedItem) => {
                      this.changeSelectAggregation(selectedItem.target.value, "ASSET", undefined, currentJ, vr);
                    }}
                    value={selectedCumulatedOption}
                    className="form-control"
                    labelProperty="label"
                    valueProperty="id"
                    options={cumulatedOption}
                  /> : null
                }
              </td>
            </tr>
          );
          assetHTML.push(tr);
        }

        if (checked && selectOptions) {
          assetHTML.push(selectOptions);
        }
      }
    });

    if (vars.length > 0) {
      assetTab = true;
    }
    const ret = {
      assetTab,
      assetHTML,
    };
    return ret;
  }


  renderActuationHTML = () => {
    const RATE = getRateOptions();
    const nessunMeter = i18n._(t`Nessun meter asset`);
    let actuationHTML = [];
    let j = 0;
    let actuationTab = false;
    let option = [];
    let cumulatedOption = [];
    let unitOptionView = null;
    let isCumulated = false;
    let measureOptions = null;

    const { nodeDataArray, selectedNode, meterAssetList, virtualMeterList } = this.props;
    let { selectedMeterAssets } = this.state
    if (selectedMeterAssets.length === 0) {
      selectedMeterAssets = meterAssetList;
    }
    const node = nodeDataArray.find(node => node.key === selectedNode);
    const { measureAssetsList } = this.state;
    let numVis = 0;
    if (measureAssetsList) {
      measureAssetsList.forEach((ma) => {
        if (ma.display && ma.flowId === null) {
          numVis += 1;
        }
      });
    }
    let vars = node.item.actuationParameters;

    if (vars === undefined || vars === null) {
      vars = [];
    }

    const updatedVars = [...vars.map(v => { return v.howMany ? { ...v, howManyIndex: 1 } : v })];
    if (vars.length > 0) {
      const howManyVars = [...updatedVars.filter(variable => variable.howMany)];
      howManyVars.forEach(hMVar => {
        if (node.property.technicalData[hMVar.howMany] > 1) {
          const index = updatedVars.findIndex(v => v.name === hMVar.name);
          const howManyArray = [];
          for (let index = 1; index < node.property.technicalData[hMVar.howMany]; index++) {
            howManyArray.push({ ...hMVar, howManyIndex: index + 1 })
          }
          updatedVars.splice(index + 1, 0, ...howManyArray);
        }
      });
    }

    this.assetMeasures = [];
    this.selectAsset = [];
    j = -1;
    let checked = false;
    updatedVars.forEach((vr) => {
      measureOptions = null;
      const vrData = this.getMeasureUnitFromPhysicalQuantity(vr.physicalQUantityNK);
      const { units, defaultVisualizationUnit } = this.getMeasureUnitListFromPhysicalQuantity(vr.physicalQUantityNK);
      if (vrData) {
        j += 1;
        let checkbox = null;
        let selected = false;
        const currentJ = j;
        let measureUnit = defaultVisualizationUnit;
        let aggregation = '1dy';
        let selectedValue = 0;
        checked = false;
        option = [];
        option.push({ name: nessunMeter, id: 0 });
        selectedMeterAssets.forEach((sMeterAsset) => {
          let { observedProperties } = JSON.parse(JSON.stringify(sMeterAsset));
          //  observedProperties = observedProperties.concat(virtualMeterList);
          isCumulated = false;
          observedProperties.forEach((observedProperty) => {
            if (observedProperty.writeable) {
              if (vr.physicalQUantityNK === 'event') {
                vr.physicalQUantityNK = 'state';
              }
              if (observedProperty.physicalQuantity === vr.physicalQUantityNK) {
                if (measureAssetsList) {
                  measureAssetsList.forEach((ma) => {
                    if (ma.flowId === null && vr.physicalQUantityNK === ma.vNk && ma.observedPropertyId === observedProperty.id && ma.vName === vr.name && (!ma.howManyIndex || ma.howManyIndex === vr.howManyIndex)) {
                    // if (ma.flowId === null && vr.physicalQUantityNK === ma.vNk && ma.observedPropertyId == observedProperty.id && ma.vName === vr.name) {
                      if (ma.unit !== undefined && ma.unit != null) {
                        measureUnit = ma.unit;
                      }
                      if (ma.options !== undefined && ma.options != null) {
                        measureOptions = ma.options;
                      }
                      if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                        aggregation = ma.aggregationRate;
                      }
                      isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                      selected = true;
                      checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                      if (ma.display) {
                        checked = true;
                        checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                      } else if (numVis >= 4) {
                        checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                      }
                      const assetIndex = this.assetMeasures.findIndex(am => am.vName === vr.name);
                      const dt = {
                        observedPropertyId: ma.observedPropertyId,
                        display: ma.display,
                        variableIndex: j,
                        assetId: selectedNode,
                        vName: vr.name,
                      };
                      if (ma.display) {
                        dt.unit = measureUnit;
                      }
                      if (ma.display && isCumulated) {
                        dt.aggregationRate = aggregation;
                      }
                      if (assetIndex !== -1) {
                        this.assetMeasures[assetIndex] = dt
                      } else {
                        this.assetMeasures.push(dt);
                      }
                    }
                  });
                }

                if (selected) {
                  selectedValue = observedProperty.id;
                }
                option.push(observedProperty)
              }
            }
          });
        });
        virtualMeterList.forEach((observedProperty) => {
          if (observedProperty.writeable) {
            if (vr.physicalQUantityNK === 'event') {
              vr.physicalQUantityNK = 'state';
            }
            if (observedProperty.physicalQuantity === vr.physicalQUantityNK) {
              let selected = false;
              if (measureAssetsList) {
                measureAssetsList.forEach((ma) => {
                  if (ma.flowId === null && vr.physicalQUantityNK === ma.vNk && ma.observedPropertyId === observedProperty.id && ma.vName === vr.name) {
                    if (ma.unit !== undefined && ma.unit != null) {
                      measureUnit = ma.unit;
                    }
                    if (ma.options !== undefined && ma.options != null) {
                      measureOptions = ma.options;
                    }
                    if (ma.aggregationRate !== undefined && ma.aggregationRate != null) {
                      aggregation = ma.aggregationRate;
                    }
                    isCumulated = observedProperty.valueType !== OP_TYPE_INSTANT;
                    selected = true;
                    checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} type="checkbox" value="1" />;
                    if (ma.display) {
                      checked = true;
                      checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} checked="checked" type="checkbox" value="1" />;
                    } else if (numVis >= 4) {
                      checkbox = <input obspropsref={vr.physicalQUantityNK} varref={j} onChange={this.changeCheckBox} disabled="disabled" type="checkbox" value="1" />;
                    }
                    const assetIndex = this.assetMeasures.findIndex(am => am.vName === vr.name);
                    const dt = {
                      observedPropertyId: ma.observedPropertyId,
                      display: ma.display,
                      variableIndex: j,
                      assetId: selectedNode,
                      vName: vr.name,
                    };
                    if (ma.display) {
                      dt.unit = measureUnit;
                    }
                    if (ma.display && isCumulated) {
                      dt.aggregationRate = aggregation;
                    }
                    if (assetIndex !== -1) {
                      this.assetMeasures[assetIndex] = dt
                    } else {
                      this.assetMeasures.push(dt);
                    }
                  }
                });
              }

              if (selected) {
                selectedValue = observedProperty.id;
              }
              option.push(observedProperty)
            }
          }
        });
        const selectedMesureOption = measureUnit
        units.forEach((unit) => {
          if (unit.key === measureUnit) {

            this.ACTMeasures[j] = unit.key;
            unitOptionView = unit.name;
          }
        });
        cumulatedOption = [];

        RATE.forEach((option) => {
          const { id, mins } = option;
          if (mins > 1440) {
            return;
          }
          if (option.mins === 0 || option.mins === undefined) {
            return;
          }
          if (aggregation === option.id) {
            this.ACTAggregation[j] = id;
          }
          cumulatedOption.push(option)
        });

        if (vr.options !== undefined) {
          const { options } = vr;
          options.forEach((option) => {
            const { values, name } = option;
            let optionsOptions = [];
            values.forEach((v) => {

              const label = getTranslatedString("option", v);
              if (measureOptions && measureOptions[name] && measureOptions[name] === v) {
                optionsOptions.push(<option selected="selected" value={v}>{label}</option>)
              } else {
                optionsOptions.push(<option value={v}>{label}</option>)
              }

            });


          })
        }

        let { name, howManyIndex } = vr;
        name = getTranslatedString('dinamycParameters', name);
        const tr = (
          <tr>
            <td>
              {howManyIndex ? `${name} ${howManyIndex}` : `${name}`}
            </td>
            <td>
              {checked ?
                <Select
                  dirref="ACTUATION"
                  varref={j}
                  onChange={(selectedItem) => {
                    this.changeUnitSelect(selectedItem.target.value, "ACTUATION", undefined, currentJ);
                  }}
                  className="form-control"
                  options={units}
                  value={selectedMesureOption}
                  labelProperty="name"
                  valueProperty="key"
                /> : <span style={{ paddingLeft: '16px' }}>{unitOptionView}</span>
              }
            </td>
            <td>
              <Select
                dirref="ACTUATION"
                varref={j}
                onChange={(selectedItem) => {
                  this.changeSelect(selectedItem.target.value, "ACTUATION", undefined, currentJ, vr);
                }}
                value={selectedValue}
                // options={option}
                options={howManyIndex ? option.filter(o => typeof (o.id) !== 'string') : option} // avoid vm
                labelProperty="name"
                valueProperty="id"
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              {checkbox}
            </td>
          </tr>
        );
        actuationHTML.push(tr);
      }
    });

    if (vars.length > 0) {
      actuationTab = true;
    }

    const ret = {
      actuationTab,
      actuationHTML,
    };
    return ret;
  }

  save = () => {
    const { onSave, onHideAssetList } = this.props;
    const { measureAssetsList, fieldDevicesList } = this.state;
    let assetMeasures = [];
    for (let i in measureAssetsList) {
      if (measureAssetsList[i].flowId == null) {
        assetMeasures.push(measureAssetsList[i]);
      }
    }
    onHideAssetList(() => {
      onSave(measureAssetsList, fieldDevicesList, assetMeasures);
    })
  }

  renderWizardSteps = () => {
    const { inTab, inFlowHTML } = this.renderInData();
    const { outTab, outFlowHTML } = this.renderOutData();
    const { assetTab, assetHTML } = this.renderAssetData();
    const { actuationTab, actuationHTML } = this.renderActuationHTML();
    const steps = [];
    if (inTab) {
      steps.push(<WizardStep
        title={<Trans>Flussi in ingresso</Trans>}
        label={<Trans>Configurazione delle misure relative ai flussi in ingresso</Trans>}
      >
        <table width="100%">
          <thead>
            <tr>
              <th><Trans>Flusso</Trans></th>
              <th><Trans>Asset di origine</Trans></th>
              <th><Trans>Grandezza</Trans></th>
              <th><Trans>U.M.</Trans></th>
              <th style={{ width: '300px' }}><Trans>Misura</Trans></th>
              <th><Trans>Mostra</Trans></th>
              <th><Trans>Granularità</Trans></th>
            </tr>
          </thead>
          <tbody>
            {inFlowHTML}
          </tbody>
        </table>
      </WizardStep>);
    }
    if (outTab) {
      steps.push(<WizardStep
        title={<Trans>Flussi in uscita</Trans>}
        label={<Trans>Configurazione delle misure relative ai flussi in uscita</Trans>}
      >
        <table width="100%">
          <thead>
            <tr>
              <th><Trans>Flusso</Trans></th>
              <th><Trans>Asset di destinazione</Trans></th>
              <th><Trans>Grandezza</Trans></th>
              <th><Trans>U.M.</Trans></th>
              <th style={{ width: '300px' }}><Trans>Misura</Trans></th>
              <th><Trans>Mostra</Trans></th>
              <th><Trans>Granularità</Trans></th>
            </tr>
          </thead>
          <tbody>
            {outFlowHTML}
          </tbody>
        </table>
      </WizardStep>);
    }

    if (assetTab) {
      steps.push(<WizardStep
        title={<Trans>Variabili di funzionamento</Trans>}
        label={<Trans>Configurazione delle misure relative alle variabili di funzionamento</Trans>}
      >
        <table width="100%">
          <thead>
            <tr>
              <th><Trans>Variabile di funzionamento</Trans></th>
              <th><Trans>U.M.</Trans></th>
              <th style={{ width: '300px' }}><Trans>Misura</Trans></th>
              <th><Trans>Mostra</Trans></th>
              <th><Trans>Granularità</Trans></th>
            </tr>
          </thead>
          <tbody>
            {assetHTML}
          </tbody>
        </table>
      </WizardStep>);
    }

    if (actuationTab) {
      steps.push(<WizardStep
        title={<Trans>Variabili di attuazione</Trans>}
        label={<Trans>Configurazione degli attuatori relativi alle variabili di attuazione</Trans>}
      >
        <table width="100%">
          <thead>
            <tr>
              <th><Trans>Variabile di attuazione</Trans></th>
              <th><Trans>U.M.</Trans></th>
              <th style={{ width: '300px' }}><Trans>Attuatore</Trans></th>
              <th><Trans>Mostra</Trans></th>
            </tr>
          </thead>
          <tbody>
            {actuationHTML}
          </tbody>
        </table>
      </WizardStep>);
    }
    return steps;
  }
  render() {
    const { meterAssetList, onHideAssetList } = this.props;
    const { selectedMeterAssets } = this.state;
    return (
      <div>
        <Row>
          <Col md="2">
            <Bold><Trans>Dispositivi di misura</Trans>:</Bold>
            <br />
          </Col>
          <Col md="10">
            <MultiSelectTag name="MeterAsset" value={selectedMeterAssets} options={meterAssetList} keyProperty="id" labelProperty="name" canDeleteProperty="canDelete" onChange={this.changedMeterAssetList} />
          </Col>
        </Row>
        <hr />
        <Wizard
          edit={true}
          readOnly={false}
          onSave={this.save}
          onCancel={onHideAssetList}
          onStepSubmit={() => { }}
        >
          {this.renderWizardSteps()}
        </Wizard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

export default connect(mapStateToProps)(meterAssetsManagement);
