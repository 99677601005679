import { ModalBody } from 'reactstrap';
import styled from 'styled-components';

const MaxHeightModalBody = styled(ModalBody)`
  ${props => props.scroll && 'overflow-y: scroll; max-height: 75vh;'}
  ${props => props.maxheight && `overflow-y: scroll; max-height: ${props.maxheight};`}

  &::-webkit-scrollbar {
    width: .4rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent; 
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.navbar.borderColor}; 
  }
`;

export default MaxHeightModalBody;
