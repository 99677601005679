import React, { Component } from 'react';
import api from 'api';
import Api from './graph/Helpers/Api';
const sleep = (milliseconds, callback) => {
  setTimeout(callback, milliseconds)
}

class UpdateMeter extends Component {

  constructor(props) {
    super(props);
    this.Api = new Api();
    this.getListasiti();
    this.state = {
      operazione: "LOADING",
      numeroSiti: 0,
      sitoInAggiornamento: null,
      graphType: "Definitivo",
    }
  }

  getListasiti = () => {
    api.get('SupportedAssets').then((result) => {
      this.SupportedAssets = result.data;
      api.get(`Sites`).then((result) => {
          const siti = result.data;

          this.setState({numeroSiti: result.data.length});
          this.updateSiti(siti);
      });
    });
  }

  updateSiti = (listaSiti, count) => {
    if (count === undefined) {
      count = -1;
    }
    count += 1;
    if (listaSiti[count] === undefined) {
      this.setState({operazione: "AGGIORNATI TUTTI I SITI"});
      return;
    }
    const sito = listaSiti[count];
    this.setState({operazione: `AGGIORNAMENTO SITO ID ${sito.id}`});
    this.setState({sitoInAggiornamento: count + 1});
    sleep(1000, ()=>{
    api.get(`Sites/${sito.id}/observedProperties`).then((result) => {
      const allObservedProperties = result.data;
      this.updatePrincipale(sito.id,allObservedProperties, (parentId) =>{
        if (parentId !== false) {
          sleep(1000, ()=>{
            this.updateDraft(parentId,allObservedProperties, () =>{
              this.updateSiti(listaSiti, count);
            });
          });
          
        } else {
          this.updateSiti(listaSiti, count);
        }
      });
    });
      
    });
    // this.updateSiti(listaSiti, count);

  }

  updateDraft = (parentId, allObservedProperties, callback) => {
  
    this.setState({graphType: "Bozza"});
    api.get(`AssetGraphs/${parentId}/draft`).then((result) => {
      this.updateGraph(result.data,allObservedProperties,()=>{
        callback(true);
      });
    }).catch(() => {
      callback(false)
    });;
  }

  updatePrincipale = (idSito, allObservedProperties, callback) => {
  /*	if(idSito !=11){
  		return callback(false);
  	}*/
    this.setState({graphType: "Definitivo"});
    api.get(`Sites/${idSito}/assetGraph`).then((result) => {
      if (result.data.length !== 0) {
        const parentId = result.data.id;
        this.updateGraph(result.data,allObservedProperties,()=>{
          callback(parentId);
        })
        
      }else{
        callback(false)
      }
    }).catch((e) => {
      callback(false)
    });
  }

  getId = (oldId, observerProperties) => {
    console.log(oldId);
    console.log(observerProperties);

    const observedProperty = observerProperties.find(op => op.virtualMeterId === oldId)
    if(observedProperty){
      return observedProperty.id;
    }
    return oldId;
  }
  updateGraph = (graphData, observerProperties, callback) => {
    const { assets, assetFlows } = graphData;
    if(graphData.siteId===10){
      console.log(graphData);
    }

    for(let i in assetFlows) {
      for(let count in assetFlows[i].destinationMeasures){
        if(assetFlows[i].destinationMeasures[count].type === "vm"){
          assetFlows[i].destinationMeasures[count].observedPropertyId = this.getId(assetFlows[i].destinationMeasures[count].observedPropertyId,observerProperties)
        }
      }

      for(let count in assetFlows[i].sourceMeasures){
        if(assetFlows[i].sourceMeasures[count].type === "vm"){
          assetFlows[i].sourceMeasures[count].observedPropertyId = this.getId(assetFlows[i].sourceMeasures[count].observedPropertyId,observerProperties)
        }
      }

    }
    for(let i in assets) {
      for(let count in assets[i].measures){
        if(assets[i].measures[count].type === "vm"){
          assets[i].measures[count].observedPropertyId = this.getId(assets[i].measures[count].observedPropertyId,observerProperties)
        }
      }
    }
    //callback();

    
    this.Api.patchGraphData(graphData,() =>{
      callback();
    });
  }

  updateDestinationMeasuer = (destinationMeasures,observerProperties, callback, count) => {
    if (count === undefined) {
      count = -1;
    }
    count += 1;
    if (destinationMeasures[count] === undefined) {

      return callback(destinationMeasures);
    }
    if(destinationMeasures[count].type === "op"){
      return this.updateDestinationMeasuer(destinationMeasures,observerProperties, callback, count)
    }
    const observedProperty = observerProperties.find(op => op.virtualMeterId === destinationMeasures[count].id)
    if(observedProperty){
      destinationMeasures[count].id = observedProperty.id;
    }
    return this.updateDestinationMeasuer(destinationMeasures,observerProperties, callback, count)
  }

  updaeSourcesMeasuer = (sourceMeasuers,observerProperties, callback, count) => {
    if (count === undefined) {
      count = -1;
    }
    count += 1;
    if (sourceMeasuers[count] === undefined) {

      return callback(sourceMeasuers);
    }
    if(sourceMeasuers[count].type === "op"){
      return this.updateDestinationMeasuer(sourceMeasuers,observerProperties, callback, count)
    }
    const observedProperty = observerProperties.find(op => op.virtualMeterId === sourceMeasuers[count].id)
    if(observedProperty){
      sourceMeasuers[count].id = observedProperty.id;
    }
    return this.updateDestinationMeasuer(sourceMeasuers,observerProperties, callback, count)
  }


  updateAssetMeasuer = (assetsMeasures,observerProperties, callback, count) => {
    if (count === undefined) {
      count = -1;
    }
    count += 1;
    if (assetsMeasures[count] === undefined) {

      return callback(assetsMeasures);
    }
    if(assetsMeasures[count].type === "op"){
      return this.updateDestinationMeasuer(assetsMeasures,observerProperties, callback, count)
    }
    const observedProperty = observerProperties.find(op => op.virtualMeterId === assetsMeasures[count].id)
    if(observedProperty){
      assetsMeasures[count].id = observedProperty.id;
    }
    return this.updateDestinationMeasuer(assetsMeasures,observerProperties, callback, count)
  }


  render() {
   
    return (
      <div>{this.state.operazione}<br />
      Sito {this.state.sitoInAggiornamento} ({this.state.graphType}) di {this.state.numeroSiti}</div>
    );
  }

}

export default UpdateMeter;
