import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'app/common';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';


const ConfirmActionModal = (props) => {
  const { children, isOpen, toggle, onConfirm, onCancel, title } = props;

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={cancel}><Trans>Annulla</Trans></Button>
        <Button marginright="true" color="primary" onClick={onConfirm}><Trans>Conferma</Trans></Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.element,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ConfirmActionModal.defaultProps = {
  children: PropTypes.element,
  title: <Trans>Confermi</Trans>
};

export default ConfirmActionModal;
