import React, { Component, Fragment } from 'react';
import { Button, InputWithIcon } from 'app/common';
import styled from 'styled-components';
import memoize from 'memoize-one';

const ShowAllButton = styled(Button)`
  && {
    padding: 0;
  }
`;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-width: 22rem;
`;

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ListItemContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const ListItem = styled.div`
  margin-bottom: 0;
  margin-right: .5rem;
  color: #20a8d8;
    text-decoration: none;
    cursor:pointer;
    display:inline-block
`;

const ListDescription = styled.div`
  color: ${props => props.theme.lightColor};
  display: inline-block;
  font-size: .8rem;
  font-style: italic;
`;


const MySection = styled(Section)`
  border: 1px solid #e4e7ea;
  border-top: 0;
  padding: 3px;
  max-height: 200px;
  overflow: auto;
  margin-top: -4px;
`;


const SelectorItem = (props) => {
  const { item, onMouseIn ,onMouseOut, onSelect } = props;
  const productor = item.property !== undefined && item.property.productor !== undefined ? item.property.productor : '';
  const model = item.property !== undefined && item.property.model !== undefined ? item.property.model : '';
  const data = item.property !== undefined && item.property.data !== undefined ? item.property.data : '';
  const prependName = item.prepend_name !== undefined ? item.prepend_name : '';
  return (
    <ListItemContainer
      key={item.key}
      onMouseEnter={() => onMouseIn(item.key)}
      onClick={() => onSelect(item)}
      onMouseLeave={onMouseOut}
    >
      <ListItem>
        {prependName}
        &nbsp;
        {item.name}
      </ListItem>
      <ListDescription>
        {productor}
        &nbsp;
        {model}
        &nbsp;
        {data}
      </ListDescription>
    </ListItemContainer>
  );
};


class Selector extends Component {
  state = {
    filterText: '',
    showAllOthers: false,
  };

  filteredItems = memoize((items, filterProperty, selectedItem, filterText) => {
    const filteredItems = filterText.length > 0 ? items.filter(x => x.name.toLowerCase().includes(filterText) || (x.property !== undefined && x.property.productor !== undefined && x.property.productor.toLowerCase().includes(filterText)) || (x.property !== undefined && x.property.model !== undefined && x.property.model.toLowerCase().includes(filterText))) : items;

    if (selectedItem) {
      const partitions = this.partition(filteredItems, x => x[filterProperty] === selectedItem.id);
      return { current: partitions[0], others: partitions[1] };
    }
    return { current: [], others: filteredItems };
  });

  filterTextChange = (e) => {
    this.setState({ filterText: e.target.value.toLowerCase(), showAllOthers: true });
  }


  render() {
    const { filterText, showAllOthers } = this.state;
    const { onSelect, items,selectedItem,searchText,onMouseIn,onMouseOut} = this.props;
    const filterProperty = 'key';
    const { others } = this.filteredItems(items, filterProperty, selectedItem, filterText);
    const shownOthers = showAllOthers ? others : others.slice(0, 3);

    return (
      <Container>
        <InputWithIcon autoFocus placeholder={searchText} onChange={this.filterTextChange} righticon="search" />
        <MySection>
          <Fragment>
            {shownOthers.map(item => <SelectorItem key={item.id} item={item} onSelect={onSelect} onBlur={() => {}} onMouseIn={onMouseIn} onMouseOut={onMouseOut} />)}
            {shownOthers.length < others.length && <ShowAllButton color="link" size="sm" onClick={() => this.setState({ showAllOthers: true })}>Altro...</ShowAllButton>}
          </Fragment>
        </MySection>
      </Container>
    );
  }
}

export default Selector;
