import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GatewaysInventory from './GatewaysInventory';
import InsertGateway from './InsertGateway';

const Provisioning = (props) => {
  const { match } = props;
  const { url } = match;
  return (
    <Switch>
      <Route path={`${url}/new`} component={InsertGateway} />
      <Route path={url} component={GatewaysInventory} />
    </Switch>
  );
};

export default Provisioning;
