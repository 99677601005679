import React from 'react';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ViewerCheckbox = (props) => {
    const CheckBox = styled(FontAwesomeIcon)`
      margin: 0 .7rem 0 .3rem;
      min-width: 1.2rem;
      color: ${props => (props.checked ? `${props.theme.common.brand.primaryColor}` : '#aaa')};
    
      ${props => props.disabled && 'color: #999'};
    `;

    const Item = styled.div`
        display: inline-block;
        padding: .2rem .6rem;
        color: #212529;
        cursor: pointer;
        &:hover {
          span, svg {
            color: ${props => props.theme.common.brand.secondaryColor};
          }
        }
    `;

    const GroupLabel = styled.div`
        font-weight: 700;
        padding: 0.4rem;
        display: inline-block;
        cursor: default;
    `;

    const ItemLabel = styled(Label)`
        color: ${props => props.theme.common.brand.primaryColor};
    `

    const { item, checked, disabled } = props;
    return (
        <Item>
            {
                item.group && <CheckBox disabled={disabled} icon={checked ? 'check-square' : ['far', 'square']} checked={checked} />
            }
            {
                item.group
                    ? (checked ?
                        <ItemLabel disabled={disabled}>{item.label}</ItemLabel>
                        : <Label disabled={disabled}>{item.label}</Label>)
                    : <GroupLabel disabled={disabled}><div className="label">{item.label}</div></GroupLabel>
            }
        </Item>
    );
};

export default ViewerCheckbox;
