import React from 'react';
import Popup from '../Helpers/Popup';
import Select from 'app/common/Select';
import SelectionDetails from '../Form/SelectionDetails';

import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';


const title = {
  marginLeft: '15px',
};
const pt0 = {
  paddingTop: '0px',
};

 
class PopupEditor extends React.Component {
  render(){
    const { selectedNodeKeys, showError, assetTypesForComplex, showAddNode, dropdownOpen, dropdownIndex, firstSelectedItem, secondSelectedItem, vectors, vectorItem, model, firstDraggedSelectedItem, secondDraggedSelectedItem, vectorIntersection } = this.props.data;
    const { productDetailSave, selectedVectorAfterDrag, undoshowError, onSelectAssetTypeForComplex, undoAssetComplesso, onSelectFirst, onSelectSecond, assetMouseEnter, assetMouseOut, onSelectVector, deleteVectorItem, undoAddNode, saveNode, deleteSecondItem, deleteFirstItem, undoAddVettore } = this.props.functions;
    let popup = null;
    if (firstDraggedSelectedItem !== undefined && firstDraggedSelectedItem !== null
      && secondDraggedSelectedItem !== undefined && secondDraggedSelectedItem !== null
      && vectorIntersection !== undefined && vectorIntersection !== null
    ) {
      const contents = (
        <div>
          <label><Trans>Seleziona il vettore energetico per il link</Trans></label>
          <Select
            options={vectorIntersection}
            canSearch="true"
            onMouseIn={() => {}}
            onMouseOut={() => {}}
            onBlur={() => {}}
            onChange={selectedVectorAfterDrag}
            name="vectorID"
            labelProperty="name"
            valueProperty="id"
          />
        </div>
      );
      popup = <Popup saveColor="success" undoColor="link" onUndo={undoAddVettore} modalTitle={i18n._(t`Seleziona il vettore`)} modalContainer={contents} />;
       
    } else if (showError !== undefined && showError !== '') {
      const contents = (
        <div>
          <strong>
            Attenzione:
          </strong>
          {showError}
          <br />
        </div>
      );
      popup = <Popup onUndo={undoshowError} exitText={i18n._(t`Ok`)} modalTitle={i18n._(t`Errore nel savataggio`)} modalContainer={contents} />;
    } else if (assetTypesForComplex !== undefined && assetTypesForComplex !== null) {
      const contents = (
        <div>
          <strong style={title}>
            Seleziona il meter assets
          </strong>
          <Select
            style={pt0}
            options={assetTypesForComplex}
            onMouseIn={() => {}}
            onMouseOut={() => {}}
            onBlur={() => {}}
            onChange={onSelectAssetTypeForComplex}
            canSearch="true"
            name="assetID"
            labelProperty="name"
            valueProperty="id"
          />
        </div>
      );
      popup = <Popup  saveColor="success" undoColor="link" onUndo={undoAssetComplesso} modalTitle={i18n._(t`Scegli la tipologia di asset per il nodo complesso`)} modalContainer={contents} />;
    } else if (showAddNode) {
      const contents = (
        <SelectionDetails
          key="selectionDetails"
          selectedNodes={selectedNodeKeys}
          nodeDataArray={model.nodeDataArray}
          showAddNode={showAddNode}
          dropdownOpen={dropdownOpen}
          dropdownIndex={dropdownIndex}
          onSelectFirst={onSelectFirst}
          onSelectSecond={onSelectSecond}
          assetMouseEnter={assetMouseEnter}
          assetMouseOut={assetMouseOut}
          productDetailSave={productDetailSave}
          firstSelectedItem={firstSelectedItem}
          secondSelectedItem={secondSelectedItem}
          deleteFirstItem={deleteFirstItem}
          deleteSecondItem={deleteSecondItem}
          vectorsList={vectors}
          vectorItem={vectorItem}
          onSelectVector={onSelectVector}
          deleteVectorItem={deleteVectorItem}
          undoAddNode={undoAddNode}
          saveNode={saveNode}
        />
      );

      popup = <Popup  saveColor="success" undoColor="link" onUndo={undoAddNode} modalTitle={i18n._(t`Imposta i dati per il link`)} modalContainer={contents} />;
    }
    return popup;
  }
}

export default PopupEditor;