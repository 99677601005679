import React, { Component } from 'react';
import { connect } from 'react-redux';
import c3 from 'c3';
import styled from 'styled-components';
import 'c3/c3.css';
import { isEqual, debounce } from 'lodash';
import { isNil } from 'lodash';
import moment from 'moment';
import { lastValueUpdated, rateGreaterThanAggregation } from '../common';

const debounceTime = 200;

const Container = styled.div`
  & .c3-chart-arcs-title {
    font-size: ${props => props.size / 12}px;
  }
`;

const Total = styled.div`
    position: absolute;
    bottom: 0;
    left: calc(50% - 2rem);
    font-size: 1.2rem;
    max-width: max-content;
`;

class BarChart extends Component {
    componentDidUpdate(prevProps) {
        const { widget, data, width, height, shouldUpdate } = this.props;

        if (shouldUpdate) {
            if (!isEqual(widget, prevProps.widget) || !isEqual(data.map(x => ({ ...x, value: undefined })), prevProps.data.map(x => ({ ...x, value: undefined })))) {
                this.createChart();
                return;
            }

            if (width !== prevProps.width || height !== prevProps.height) {
                this.createChart();
            }

            if (!isEqual(data, prevProps.data) || shouldUpdate !== prevProps.shouldUpdate) {
                this.debouncedUpdateData();
            }
        }
    }

    getColumnsSingleValue = (data) => {
        const { physicalQuantities, widget } = this.props;
        let otherCol = [];
        if (physicalQuantities && physicalQuantities.find(pq => data[0].physicalQuantity === pq.naturalKey)) {
            otherCol = widget.format === 'value' ?
                [physicalQuantities.find(pq => data[0].physicalQuantity === pq.naturalKey)._label].concat(data.map(s => s.value.v || NaN))
                : [physicalQuantities.find(pq => data[0].physicalQuantity === pq.naturalKey)._label].concat(data.map(s => s.value.r || NaN))
        }
        const names = ['x'].concat(data.map(s => s.label));
        const colors = ['color'].concat(data.map(s => s.color));
        const columns = [names, otherCol];
        return { columns, colors };
    };


    createChart = () => {
        this.oldValue = false;
        this.created = false;
        const { data, widget, height, width } = this.props;
        if (data.length > 0) {
            const { columns, colors } = this.getColumnsSingleValue(data);
            window.d3 = undefined;
            this.chart = c3.generate({
                bindto: `#chart-${widget.key}`,
                size: {
                    height,
                    width,
                },
                data: {
                    x: 'x',
                    type: 'bar',
                    columns,
                    color: (inColor, category) => colors[category.index + 1],
                    order: null,
                    labels: {
                        format: (value, category, id) => this.getLabel(value, id)
                    },
                },
                bar: {
                    width: {
                        ratio: 0.7 // this makes bar width 70% of length between ticks
                    }
                },
                padding: {
                    bottom: widget.showTotal && 50,
                },
                axis: {
                    x: {
                        show: widget.type === 'horizontal-bar' ? width > 180 : width > 260,
                        type: 'category', // this needed to load string x value
                        tick: {
                            multilineMax: 2
                        }
                    },
                    y: {
                        show: height > 260,
                        label: { text: data[0]._unit, position: 'outer-top' }
                    },
                    rotated: widget.type && widget.type === 'horizontal-bar'
                },
                transition: {
                    ducategoryn: 200,
                },
                legend: {
                    show: false,
                },
                tooltip: {
                    format: {
                        title: () => '',
                        name: (value, category, id, index) => data[index].name,
                        value: (value, category, id, index) => this.getTooltip(value, index)
                    },
                    // use content to make custom tooltip(and remove format object above)
                    // contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
                    //     return '123'
                    // }
                },
            });
            this.created = true;
        }
    }

    debouncedUpdateData = debounce(this.createChart, debounceTime);

    getLabel = (value, id) => {
        const { period, data, widget } = this.props;
        const { interval, aggregation } = period;
        const item = data.find(d => d.id === id);
        let isBool = false;
        if (item) {
            const { valueType, physicalQuantity } = item;
            isBool = valueType === 'binary' || physicalQuantity === 'state' || physicalQuantity === 'event';
        }
        const greaterBtwRateAndAgg = item && item.rate ? rateGreaterThanAggregation(item.rate, aggregation) : aggregation;
        const oldValue = !isBool && !isNil(value) && !isNil(id) && lastValueUpdated(interval, greaterBtwRateAndAgg, item && item.value);
        if (oldValue && this.oldValue !== oldValue) {
            this.oldValue = oldValue;
        }
        const label = value && `${value.toLocaleString(undefined, { maximumFractionDigits: widget.format === 'value' ? 4 : 1 })}${widget.format !== 'value' ? '%' : ''}`;

        return oldValue ? `*${label}` : label;
    }

    getTooltip = (value, index) => {
        const { data, widget } = this.props;
        const item = data[index];
        const label = widget.format === 'value' ?
            `${value.toLocaleString(undefined, { maximumFractionDigits: 4 })} ${item._unit} (${(value / data.filter(dt => dt.value.v && !Number.isNaN(dt.value.v)).map(d => d.value.v).reduce((a, b) => a + b, 0) * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%)`
            : `${item.value.v.toLocaleString(undefined, { maximumFractionDigits: 4 })} ${item._unit} (${value.toLocaleString(undefined, { maximumFractionDigits: 1 })}%)`;
        const time = item.value && item.value.t && moment(item.value.t).format('DD/MM/YYYY, HH:mm:ss');

        return `${label} ${time}`;
    }

    render() {
        const { data, widget, height, width } = this.props;
        const size = Math.min(height, width);
        let total = data && data.length > 0 &&
            data.filter(dt => dt.value.v && !Number.isNaN(dt.value.v)).map(d => d.value.v).reduce((a, b) => a + b, 0).toLocaleString(undefined, { maximumFractionDigits: 4 });
        const unit = data && data.length > 0 && data[0]._unit;
        return (
            <>
                <Container id={`chart-${widget.key}`} size={size} />
                {this.created && widget.showTotal && <Total>Tot. {`${total} ${unit}`}</Total>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { physicalQuantities } = state.catalogs;
    return { physicalQuantities };
};

export default connect(mapStateToProps)(BarChart);
