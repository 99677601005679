import React, { useState, useEffect, useMemo } from 'react';
import { DataTable, Spinner, Button, IconButton } from 'app/common';
import { Card, CardHeader, CardFooter, ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import api from 'api';
import ConfirmActionModal from 'app/common/ConfirmActionModal';
import UpdateUser from '../UpdateUser/UpdateUser';
import ResetPassword from '../ResetPassword/ResetPassword';
import DisableUser from '../DisableUser/DisableUser';
import RolesUser from '../RolesUser/RolesUser';
import { toast } from 'react-toastify';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButton = styled(Button)`
  && {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const TabGroup = styled(ButtonGroup)`
    float: right; 
    margin-top: 3px;
    margin-right: 5px;
`;
const TabButton = styled(Button)`
  && {
    text-transform: none;
    font-weight: 600;
    font-size: 0.8rem;
  }`;

  const StyledIcon = styled(IconButton)`
    top: 1rem !important;
  `;


const UserLists = (props) => {
  const { userData, setUserData, history, selectedDomain, selectedCompany, selectedSite, selectedSitegroup, domains, companies, sites, sitegroups, isDomainLoaded, language} = props;

  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("filter");
  const isEnabled = queryParameters.get("enabled");
  const [ enabledUsers, setEnabledUsers ] = useState(isEnabled);
  const [ authorizations, setAuthorizations ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ modalRow, setModalRow ] = useState(null);
  const [ userModal, setUserModal ] = useState(null);
  const [ idUserModal, setIdUserModal ] = useState(null);
  const [ modalTitle, setModalTitle ] = useState(null);

  const modalOptions = {
    'edit': 'EDIT_USER',
    'reset': 'RESET_PASSWORD',
    'disable': 'DISABLE_USER',
    'manage': 'MANAGE_ROLES'
  }


  let { url } = props
  //rimuovo l'eventuale '/' a fine url 
  url = url.charAt(url.length -1) === '/' ? url.slice(0,url.length-1) : url;

  const getSelectedNode = () => {
    if(selectedSitegroup){
      return selectedSitegroup.name;
    }else if(selectedSite){
      return selectedSite.name;
    }else if(selectedCompany){
      return selectedCompany.name;
    }else if(selectedDomain){
      return selectedDomain.name;
    }
    return null;
  }

  useEffect(() => {
    api.get(`/UserAuthorizations`).then(res => {
      setAuthorizations(res.data);
    })
  }, []);

  const changeEnable = row => {
    setModalRow(row);
    setIsModalOpen(true);
  }

  const  confirmChangeEnable = async (row) => {
    try{
      await api.patch('/UserAuthorizations/'+row.id, { disabled: !row.disabled });
      await api.get(`/UserAuthorizations`).then(res => {
        window.location.hash = row.userInfoId + "#" + row.id;  //open accordion when back to datatable
        setAuthorizations(res.data);
      })
      console.log("Authorization " + row.id + " " + (row.disabled ? 'enabled' : 'disabled'));
      setModalRow(null);
      setIsModalOpen(false);
    }catch(e){
      modalRow.disabled ? 
        toast.warn(i18n._(t`Impossibile abilitare l'autorizzazione, segnalare il problema al supporto utenti`)) : 
        toast.warn(i18n._(t`Impossibile disabilitare l'autorizzazione, segnalare il problema al supporto utenti`));
      
    }
  }

  const cancelChangeEnable = () => {
    setModalRow(null);
    setIsModalOpen(false);
  }

  const allUsersTable = () => {
    return <DataTable
      keyField="id"
      edit
      search
      width="100%"
      sortby="name"
      filter={filter}
      buttons={
        <StyledButton size="sm" color="primary" tag={Link} to={`${url}/createuser`}>
          <Trans>Nuovo utente</Trans>
        </StyledButton>}
      rowButtons={user => [
        {
          label: <Trans>Modifica utente</Trans>,
          onClick: () => {
            setUserModal(modalOptions.edit);
            setIdUserModal(user.id);
          }
        },
        {
          label: <Trans>Reset password</Trans>,
          onClick: () => {
            setUserModal(modalOptions.reset);
            setIdUserModal(user.id);
          }
        },
        {
          label: <Trans>Disabilita/Riabilita utente</Trans>,
          onClick: () => {
            setUserModal(modalOptions.disable);
            setIdUserModal(user.id);
          }
        },
        {
          label: <Trans>Gestisci ruoli di sistema</Trans>,
          onClick: () => {
            setUserModal(modalOptions.manage);
            setIdUserModal(user.id);
          }
        },
      ]
      }
      headers={[
        {
          title: <Trans>Nome</Trans>,
          property: 'name',
          canSearch: true,
          index: 1,
          dataType: 'custom',
          cell: (row, parent, filter) => <Link to={`${url}/${row.id}` + (filter ? ("?fromFilter=" + filter) : "")}>{row.name}</Link>,
          addFilter: true
        },
        {
          title: <Trans>Username</Trans>,
          property: 'username',
          index: 2,
          dataType: 'string',
          canSearch: true,
        },
        {
          title: <Trans>Email</Trans>,
          property: 'email',
          index: 3,
          dataType: 'email',
          canSearch: true,
        },
        {
          title: <Trans>Email Verificata</Trans>,
          property: 'verifiedEmail',
          index: 4,
          dataType: 'custom',
          cell: row => <input type="checkbox" readOnly checked={row.verifiedEmail} />,
        },
        {
          title: <Trans>Ultimo accesso</Trans>,
          property: 'lastAccess',
          index: 5,
          dataType: 'date',
        },
        {
          title: <Trans>Numero accessi</Trans>,
          property: 'totalAccesses',
          index: 6,
          dataType: 'string',
          disableSort: true
        },
        {
          title: <Trans>Abilitato</Trans>,
          property: 'disabled',
          index: 7,
          dataType: 'custom',
          cell: row => <input type="checkbox" readOnly checked={!row.disabled} />,
        },
      ]}
      data={userData}
      noData={<Trans>Nessun utente presente</Trans>}
    />
  }

  //Create table for enabled only users, using memo to avoid re render table when opening modal to change enable
  const enabledUsersTable = useMemo(() => {
    if(authorizations && userData && isDomainLoaded){
      //Create list of authorizations for the current node
      const authFiltered = authorizations.filter(auth => {
        if(auth.siteGroupId){
          return (selectedSitegroup && auth.siteGroupId === selectedSitegroup.id) ? true : false
        }
        if(auth.siteId){
          return (selectedSite && auth.siteId === selectedSite.id) ? true : false
        }
        if(auth.companyId){
          return (selectedCompany && auth.companyId === selectedCompany.id) ? true : false
        }
        if(auth.domainId){
          return (selectedDomain && auth.domainId ===  selectedDomain.id) ? true : false
        }
      });
      
      //Create list of users enabled
      const filteredUser = userData.filter(user => authFiltered.some(auth => auth.userInfoId === user.id));
      
      //Add info node name to enabled user list for every authorization
      const filteredUserList = filteredUser.map(user => {
        const authList = authFiltered.filter(auth => auth.userInfoId === user.id).map(auth => {
          if(auth.siteGroupId){
            return {...auth, node: sitegroups.find(sitegroup => sitegroup.id === auth.siteGroupId).name, userName: user.name}
          }
          if(auth.siteId){
            return {...auth, node: sites.find(site => site.id === auth.siteId).name, userName: user.name}
          }
          if(auth.companyId){
            return {...auth, node: companies.find(company => company.id === auth.companyId).name, userName: user.name}
          }
          if(auth.domainId){
            return {...auth, node: domains.find(domain => domain.id === auth.domainId).name, userName: user.name}
          }
        });
        return {...user, authorizations: authList}
      })

      return <DataTable
        collapsed
        keyField="id"
        edit
        search
        width="100%"
        filter={filter}
        sortby="name"
        buttons={
          <StyledButton size="sm" color="primary" tag={Link} to={`${url}/createuser`}>
            <Trans>Nuovo utente</Trans>
          </StyledButton>}
        rowButtons={user => [
          {
            label: <Trans>Modifica utente</Trans>,
            onClick: () => {
              setUserModal(modalOptions.edit);
              setIdUserModal(user.id);
            }
          },
          {
            label: <Trans>Reset password</Trans>,
            onClick: () => {
              setUserModal(modalOptions.reset);
              setIdUserModal(user.id);
            }
          },
          {
            label: <Trans>Disabilita/Riabilita utente</Trans>,
            onClick: () => {
              setUserModal(modalOptions.disable);
              setIdUserModal(user.id);
            }
          },
          {
            label: <Trans>Gestisci ruoli di sistema</Trans>,
            onClick: () => {
              setUserModal(modalOptions.manage);
              setIdUserModal(user.id);
            }
          },
        ]
        }
        headers={[
          {
            title: <Trans>Nome</Trans>,
            property: 'name',
            canSearch: true,
            index: 1,
            dataType: 'custom',
            cell: (row, parent, filter) => <Link to={`${url}/${row.id}?enabled=true` + (filter ? ("&fromFilter=" + filter) : "")}>{row.name}</Link>,
            addFilter: true
          },
          {
            title: <Trans>Username</Trans>,
            property: 'username',
            index: 2,
            dataType: 'string',
            canSearch: true,
          },
          {
            title: <Trans>Email</Trans>,
            property: 'email',
            index: 3,
            dataType: 'email',
            canSearch: true,
          },
          {
            title: <Trans>Autorizzazione</Trans>,
            property: 'authorization',
            index: 4,
            dataType: 'string'
          },
          {
            title: <Trans>Sul nodo</Trans>,
            property: 'node',
            index: 5,
            dataType: 'string',
          },
          {
            title: <Trans>Stato</Trans>,
            property: 'disabled',
            index: 6,
            dataType: 'string',
          },
          {
            title: <Trans>Abilitato</Trans>,
            property: 'disabled',
            index: 7,
            dataType: 'custom',
            cell: row => <input type="checkbox" readOnly checked={!row.disabled} />,
          },
        ]}
        data={filteredUserList}
        noData={<Trans>Nessun utente presente</Trans>}
        nestedData={{
          field: 'authorizations',
          headers: [
            {
              title: <Trans>Nome</Trans>,
              property: 'name',
              index: 1,
              dataType: 'string',
              canSearch: true,
            },
            {
              title: <Trans>Username</Trans>,
              property: 'username',
              index: 2,
              dataType: 'string',
              canSearch: true,
            },
            {
              title: <Trans>Email</Trans>,
              property: 'email',
              index: 3,
              dataType: 'string',
              canSearch: true
            },
            {
              title: <Trans>Autorizzazione</Trans>,
              property: 'role',
              index: 4,
              dataType: 'string',
              canSearch: true,
            },
            {
              title: <Trans>Sul nodo</Trans>,
              property: 'node',
              index: 5,
              dataType: 'string',
              canSearch: true,
            },
            {
              title: <Trans>Stato</Trans>,
              property: 'disabled',
              index: 6,
              dataType: 'custom',
              cell: row => (
                <>
                  {row.disabled ? (<><Trans>Disabilitata</Trans><StyledIcon icon="pencil-alt" onClick={() => changeEnable(row)}/></>) : <><Trans>Abilitata</Trans><StyledIcon icon="pencil-alt" onClick={() => changeEnable(row)}/></>}
                  
                </>
              )
            },
            {
              title: <Trans>Abilitato</Trans>,
              property: 'enabled',
              index: 7,
              dataType: 'custom',
            },
          ],
          noData: <Trans>Nessuna autorizzazione presente</Trans>
        }}
      />
    }
    return <></>
  }, [userData, selectedDomain, selectedCompany, selectedSite, selectedSitegroup, isDomainLoaded, authorizations])

  const getAuthModalText = auth => {
    const { node, created, updated, role, disabled, userName } = auth;
    let nodeType = "";
    if(auth.siteGroupId){
      nodeType = <Trans>Gruppo di siti</Trans>
    }else if(auth.siteId){
      nodeType = <Trans>Sito</Trans>
    }else if(auth.companyId){
      nodeType = <Trans>Azienda</Trans>
    }else if(auth.domainId){
      nodeType = <Trans>Dominio</Trans>
    }
    const createdDate = new Date(created);
    const updatedDate = new Date(updated);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }

    const enable = disabled ? <Trans>abilitare</Trans> : <Trans>disabilitare</Trans>;

    return <> 
    <Trans>Utente</Trans>: {userName}
    <br />
    <Trans>Nodo</Trans>: {nodeType} {node}
    <br />
    <Trans>Autorizzazione</Trans>: {role}
    <br />
    <Trans>Creata</Trans>: {createdDate.toLocaleString(language, options)}
    <br />
    <Trans>Aggiornata</Trans>: {updatedDate.toLocaleString(language, options)}
    <br />
    <br />
    <Trans>Sei sicuro di voler {enable} l'autorizzazione?</Trans>
    </>;
  }

  const onSuccess = async (message) => {
    toast.success(message);
    closeModal();
  }

  const onError = (error) => {
    toast.error(error);
    closeModal();
  }

  const closeModal = () => {
    setUserModal(null);
    setIdUserModal(null);
  }

  const createModal = () => {
    switch(userModal) {
      case modalOptions.edit:
        return <UpdateUser {...props} 
          url={url} 
          userData={userData}
          setUserData={userData => setUserData(userData)}
          userid={idUserModal} 
          closeModal={() => closeModal()} 
          onSuccess={userid => onSuccess(userid)}
          onError={(message) => onError(message)}
          />
      case modalOptions.reset:
        return <ResetPassword {...props} 
          url={url} 
          userData={userData}
          userid={idUserModal} 
          closeModal={() => closeModal()} 
          onSuccess={userid => onSuccess(userid)}
          onError={(message) => onError(message)}
          />
      case modalOptions.disable:
        return <DisableUser {...props} 
        url={url} 
        userData={userData}
        userid={idUserModal} 
        setUserData={userData => setUserData(userData)}
        closeModal={() => closeModal()} 
        onSuccess={userid => onSuccess(userid)}
        onError={(message) => onError(message)}
        />
      case modalOptions.manage:
        return <RolesUser {...props} 
        url={url} 
        userData={userData} 
        userid={idUserModal} 
        closeModal={() => closeModal()} 
        onSuccess={userid => onSuccess(userid)}
        onError={(message) => onError(message)}
        />
      default:
        return <></>
    }
  }

  return (
    <>
    {createModal()}
    <Card>
      <CardHeader>
        <ButtonContainer>
          <Trans>Gestione utenti</Trans>
          <TabGroup>
          <TabButton
            onClick={() => setEnabledUsers(false)}
            active={!enabledUsers} >
            <FontAwesomeIcon icon="list" /> <Trans>Tutti</Trans>
          </TabButton>
          <TabButton
            onClick={() => setEnabledUsers(true)}
            active={enabledUsers} >
            <FontAwesomeIcon icon="users" /> { getSelectedNode() }
          </TabButton>
        </TabGroup>
        </ButtonContainer>
      </CardHeader>
      <CardFooter>
        {
          !userData ? <Spinner overlay={false} />
            :
            enabledUsers ?
              enabledUsersTable
            :
              allUsersTable()
        }
      </CardFooter>
    </Card >
    {isModalOpen && modalRow && <ConfirmActionModal
    isOpen={isModalOpen}
    toggle={() => cancelChangeEnable()}
    title={<><Trans>Conferma</Trans> {modalRow.disabled ? <Trans>abilita</Trans> : <Trans>disabilita</Trans>}</>}
    children={getAuthModalText(modalRow)}
    onConfirm={() => confirmChangeEnable(modalRow)}
    onCancel={() => cancelChangeEnable()}
/>}
    </>
  );
};

const mapStateToProps = state => {
  const { selectedDomain, selectedCompany, selectedSite, selectedSitegroup, domains, companies, sites, sitegroups, isDomainLoaded } = state.navigation;
  const { language } = state.preferences;
  return { selectedDomain, selectedCompany, selectedSite, selectedSitegroup, domains, companies, sites, sitegroups, isDomainLoaded, language };
};

export default connect(mapStateToProps)(UserLists);
