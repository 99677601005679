import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';


class Popup extends React.Component {
  state = {
    modal: true,
  };

  toggle = () => {
    const { onUndo } = this.props;
    const { modal } = this.state;
    if (modal) {
      onUndo();
    }
    this.setState({
      modal: !modal,
    });
  }

  render() {
    let { exitText } = this.props;
    const { modalTitle, modalContainer, onUndo, size, onSave, onSaveText, saveColor, undoColor } = this.props;
    const { modal } = this.state;

    let save = null;
    if (onSave !== undefined) {
      let saveText = onSaveText;
      if(onSaveText === undefined){
        saveText = i18n._(t`Salva`)
      }
      save = <Button color={saveColor ? saveColor : 'primary'} onClick={onSave}>{saveText}</Button>;
    }
    exitText = exitText !== undefined ? exitText : 'Annulla';
    return (
      <div>
        <Modal size={size !== undefined ? size : null} isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {modalTitle}
          </ModalHeader>
          <ModalBody>
            {modalContainer}
          </ModalBody>
          <ModalFooter>
            <Button color={undoColor ? undoColor : 'primary'} onClick={onUndo}>{exitText}</Button>
            {save}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Popup;
