import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from 'app/common';
import { Trans } from '@lingui/macro';
import { Roles } from 'app/utils/auth';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ForecastLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Header = styled.div`
  font-size: .9rem;
  color: ${props => props.theme.common.text.headerColor};
  font-weight: bold;
  margin-right: 1rem;
`;

const Forecast = styled.span`
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledLink = styled(Link)`
  line-height: 1;
  margin-left: .2rem;
`;

const ForecastLinks = ({ forecasts, userCurrentRoles }) => {
  if (!forecasts || forecasts.length === 0) {
    return null;
  }
  return (
    <ForecastLinksWrapper>
      <Header><Trans>Previsioni di consumo configurate</Trans></Header>
      {forecasts.map((forecast) => {
        const { id } = forecast;
        return (
          <Forecast>
            <Link to={`management/uploadForecast/${id}/view`}>{forecast.name}</Link>
            { (userCurrentRoles.includes(Roles.SUP) || userCurrentRoles.includes(Roles.ADM) || userCurrentRoles.includes(Roles.OPE_Commissioning)) &&
            <StyledLink to={`management/uploadForecast/${id}/edit`}><IconButton icon="pencil-alt" /></StyledLink>
            }
          </Forecast>
        );
      })}
    </ForecastLinksWrapper>
  );
};

ForecastLinks.propTypes = {
  forecasts: PropTypes.array.isRequired,
  userCurrentRoles: PropTypes.object.isRequired,
};

export default ForecastLinks;
