import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { isEmpty } from 'lodash';
import api from 'api';
import { DataTable } from 'app/common';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import { getUrlFromPath} from 'app/utils/navigation';
import { getPeriodReference, getReportsHistory } from './ReportHelper';

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import './ReportHistory.css';

const ReportHistory = (props) => {
  const { match, history, id, type } = props;

  const [error, setError] = useState(null);
  const [report, setReport] = useState([]);
  const [reportHistory, setReportHistory] = useState([]);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState();
  const [currentReportId, setCurrentReportId] = useState();

  const selectedRowStyleFirst = {
    backgroundColor: '#dae3f4',
    borderLeft: '3px solid #4472c4',
    borderTop: '2px solid #cbcdd0'
  };
  const selectedRowStyle = {
    backgroundColor: '#dae3f4',
    borderTop: '2px solid #cbcdd0'
  };
  const regularRowStyle = {
    borderTop: '2px solid #cbcdd0'
  };

  const loadData = () => {
    const { reportid } = match.params;

    const reportPromise = api.get(`/Reports/${reportid}`);
    const reportHistoryPromises = reportPromise.then(res => {
      return getReportsHistory(res.data);
    });

    Promise.all([reportPromise, reportHistoryPromises]).then(responses => {
      const resHistoryData = responses[1].data;

      if ( Array.isArray(resHistoryData) && resHistoryData.length > 0 ) {

        const reportHistoryList = resHistoryData.map(rep => {
          let periodString = '-';
          if ( rep.otherMetadata && !isEmpty(rep.otherMetadata.reportTargetPeriod) ) {
            periodString = getPeriodReference(
              rep.otherMetadata.pastDate || rep.created,
              rep.otherMetadata.reportTargetPeriod.reference,
              rep.otherMetadata.reportTargetPeriod.dateFrom
            );
          }
		  
		  let rdt = rep.created;
		  if (rep.otherMetadata)
			rdt = rep.otherMetadata.pastDate || rep.created;

          return {
            id: rep.id,
            report_date: moment(rdt).format('YYYY/MM/DD HH:mm'),
            period: periodString
          };
        });
		
		// ordino la lista
		reportHistoryList.sort(
			(l, r) => {
				return Date.parse(r.report_date) - Date.parse(l.report_date);
			}
		);

        // Set report template data
        setReport(responses[0].data);

        // Set first PDF document in the Viewer
        setPdfUrlToView(reportHistoryList[0].id);

        setReportHistory(reportHistoryList);
      }
    });

  };

  useEffect( () => {
    loadData();
  }, []);

  const setPdfUrlToView = async (reportId) => {
    try {
      const resDownloadPdf = await api.get(`/Documents/${reportId}/download/`, { responseType: 'blob' });
      const file = new Blob([resDownloadPdf.data], {type: 'application/pdf'});
      const url = URL.createObjectURL(file);

      setCurrentReportId(reportId);
      setPdfDocumentUrl(url);

      // Back to top of the page
      window.scrollTo(0, 0);
    } catch (ex) {
      setError('Errore selezione dati report da visualizzare');
    }
  }

  const onSetPdfDocument =  (event, reportId) => {
    event.preventDefault();

    setPdfUrlToView(reportId);

    return false; // avoid the click to change page
  }

  return (
    <Card>
      <CardHeader>
        <Trans>Documenti elaborati per</Trans> { report.name }
      </CardHeader>
      <CardBody>

        { error && 
          <div className="alert alert-danger">
            <h3>Errore verificato:</h3>
            <div>{error}</div>
          </div>
        }

        <Row>
          <Col md="5">
            <DataTable
              keyField="id"
              search
              sortby="make"
              width="100%"
              // TODO datatable pagination not working 
              // pagination={{
              //   enabled: true,
              //   pageLength: 10
              // }}
              buttons={<a className="btn btn-secondary btn-sm" onClick={() => {
                const { reportid } = match.params;
                const queryParameters = new URLSearchParams(window.location.search);
                const filter = queryParameters.get("filter");
                const reportUrl = getUrlFromPath('/reports' + (filter ? ('?filter=' + filter) : "") + '#' + reportid);
                history.push(reportUrl);
                return false;
              }}>&#8592; <Trans>Torna all'elenco dei report</Trans></a>}
              headers={[
                {
                  index: 1,
                  title: <Trans>Report del</Trans>,
                  property: 'report_date_custom',
                  draggable: false,
                  dataType: 'custom',
                  canSearch: true,
                  style: (row) => {
                    return (row.id === currentReportId) ? selectedRowStyleFirst : regularRowStyle;
                  },
                  cell: (row) => (
                    <div>
                      <a href="#" onClick={(e) => onSetPdfDocument(e, row.id)}>
                        { row.report_date }
                      </a>
                    </div>
                  )
                },
                {
                  index: 2,
                  title: <Trans>Periodo di competenza</Trans>,
                  property: 'period',
                  dataType: 'string',
                  canSearch: true,
                  style: (row) => {
                    return (row.id === currentReportId) ? selectedRowStyle : regularRowStyle;
                  },
                },
              ]}
              data={reportHistory}
              noData={<Trans>Nessuno storico report</Trans>}
            />

          </Col>
          <Col md="7">

            { pdfDocumentUrl &&
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: '750px' }}>
                  <Viewer fileUrl={pdfDocumentUrl} />
                </div>
              </Worker>
            }

          </Col>
        </Row>

      </CardBody>
    </Card>
  );
  
};

export default ReportHistory;
