/* eslint-disable import/prefer-default-export */
import api from 'api';
import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
} from 'reactstrap';

async function deleteDataFlowAlert(opId, alertId) {
  const httpMethod = 'delete';
  const url = `/observedProperties/${opId}/dataFlowAlerts/${alertId}`;
  let result = { _err: null };
  try {
    const res = await api[httpMethod](url);
    result = res && res.data;
    console.log(`deleteDataFlowAlert ${httpMethod} Success data: `, result);
  } catch (ex) {
    result._err = `deleteDataFlowAlert ${httpMethod} Error deleting alert id: ${alertId}, ${ex.message}`;
    console.log(result._err);
  }

  return result;
}

async function deleteDataFlowFilter(opId, filterId) {
  const httpMethod = 'delete';
  const url = `/observedProperties/${opId}/dataFlowFilters/${filterId}`;
  let result = { _err: null };
  try {
    const res = await api[httpMethod](url);
    result = res && res.data;
    console.log('deleteDataFlowFilter Success data: ', result);
  } catch (ex) {
    result._err = `deleteDataFlowFilter ${httpMethod} Error deleting filter id: ${filterId} ${ex.message}`;
    console.log(result._err);
  }
  return result;
}

async function getBinaryOPs() {
  let result = { _err: null };
  const params = {
    filter: { where: { valueType: 'binary' }, include: ['dataFlowFilters', 'dataFlowAlerts'] }
  };
  const url = `/observedProperties`;
  try {
    const res = await api.get(url, { params });
    result = res && res.data;
    console.log('getBinaryOPs Success data: ', result);
  } catch (ex) {
    const errMsg = `getBinaryOPs GET Error: ${JSON.stringify(ex, null, 2)}`;
    console.log(errMsg);
    result._err = errMsg;
  }
  return result;
}

const UpdateOldBinaryAlarms = () => {
  const [text, setText] = useState('');
  const [opWithAlarmList, setOpListWithAlerts] = useState([]);

  const analyze = () => {
    getBinaryOPs().then(opList => {
      console.log('opList ', opList);
      const opConfiguredAlarms = opList.filter(
        op => op.dataFlowFilters && op.dataFlowFilters.length > 0
      );
      console.log('opConfiguredAlarms ', opConfiguredAlarms);
      setText(
        `Found ${opList.length} Binary Observed Properties of which ${opConfiguredAlarms.length} with configured alarm`
      );
      setOpListWithAlerts(opConfiguredAlarms);
    });
  };

  const opList2delete = [100000000000]; // id list of op alarms to be cancelled
  // const opList2delete = [8978, 8979, 8981, 8982, 8983, 8985, 8986];

  const deleteSelectedOpAlarms = async () => {
    console.log('deleteDisabledStuff ');
    const opWithAlarmList2delete = opWithAlarmList.filter(op => opList2delete.includes(op.id));
    for (let i = 0; i < opWithAlarmList2delete.length; i++) {
      const op = opWithAlarmList2delete[i];
      setText(`${text}\n${op.name}`);
      const alerts = op.dataFlowAlerts;
      const filters = alerts.map(alert =>
        op.dataFlowFilters.find(filter => filter.id === alert.dataFlowFilterId)
      );

      console.log('op ', op);
      console.log('alerts 2 delete ', alerts);
      console.log('filters 2 delete ', filters);
      const deletedAlerts = await Promise.all(
        alerts.map(alert => deleteDataFlowAlert(op.id, alert.id))
      );
      const deletedFilters = await Promise.all(
        filters.map(filter => deleteDataFlowFilter(op.id, filter.id))
      );
      deletedAlerts.forEach((deletion, index) => {
        if (deletion._err) {
          console.error('OP ', op.name, deletion._err);
        } else {
          console.log('OP alert deleted', op.name, deletedAlerts[index]);
        }
      });
      deletedFilters.forEach((deletion, index) => {
        if (deletion._err) {
          console.error('OP ', op.name, deletion._err);
        } else {
          console.log('OP filter deleted', op.name, deletedFilters[index]);
        }
      });
    }
  };

  const checkConsistency = async () => {
    console.log('checkConsistency ');
    let globalError = false;
    for (let i = 0; i < opWithAlarmList.length; i++) {
      const op = opWithAlarmList[i];

      const binaryOnFilters = op.dataFlowFilters.filter(
        filter => filter.feFilter.type === 'binaryOn'
      );
      const binaryOffFilters = op.dataFlowFilters.filter(
        filter => filter.feFilter.type === 'binaryOff'
      );
      const binaryOnOffFilters = op.dataFlowFilters.filter(
        filter => filter.feFilter.type === 'binaryOnOff'
      );
      let filters = {};
      filters.binaryOn = binaryOnFilters && binaryOnFilters.length;
      filters.binaryOff = binaryOffFilters && binaryOffFilters.length;
      filters.binaryOnOff = binaryOnOffFilters && binaryOnOffFilters.length;

      let error = false;
      if (filters.binaryOnOff) {
        if (filters.binaryOn || filters.binaryOff) {
          error = true;
        }
        error = error || filters.binaryOn.length > 1;
        error = error || filters.binaryOnOff.length > 1;
      }
      if (filters.binaryOnOff) {
        error = error || filters.binaryOnOff.length > 1;
      }
      if (error) {
        console.log('Error, ', op.name, filters);
      }
      globalError = globalError || error;
    }
    if (!globalError) {
      console.log('Consistency OK');
    }
  };

  return (
    <Card>
      <CardHeader>Aggiornamento degli allarmi binari</CardHeader>
      <CardBody>{text}</CardBody>
      <CardFooter>
        <Button style={{ marginRight: '1em' }} color="primary" onClick={analyze}>
          Load Binary Alarms and Filters
        </Button>
        <Button style={{ marginRight: '1em' }} color="primary" onClick={deleteSelectedOpAlarms}>
          delete all disabled alarms and filters
        </Button>
        <Button style={{ marginRight: '1em' }} color="primary" onClick={checkConsistency}>
          Check Consistency
        </Button>
      </CardFooter>
    </Card>
  );
};

export default UpdateOldBinaryAlarms;
