import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'api';
import moment from 'moment';
import { DynamicForm, Wizard, WizardStep } from 'app/common';
import i18n from 'app/i18n';
import { getGenerationPeriodicity } from 'app/utils/data';
import { getUrl, getUrlFromPath } from 'app/utils/navigation';
import ReportWidgets from './ReportWidgets';
import {
  getPeriodOptions,
  getPostSubmitEndPoint,
  getBeaUsersEndpoint,
  getEndpointType,
  getBeaUsersTags,
  getFirstGenerationDay,
  getGenerationDay,
  getEmptyCell,
  getDocumentTotalPages,
  defaultHeaderWidget,
  defaultFooterWidget,
  ReportLoading,
  ReportError
} from '../ReportHelper';

const EditReport = (props) => {
  const {
    edit,
    isNew,
    readOnly,
    match,
    history,
    type,
    user,
    id,
    subid,
    subtype,
    onGeneratePdf,
    selectedDomain
  } = props;

  const formReportNameRef = useRef(null);
  const reportWizardRef = useRef(null);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [report, setReport] = useState(undefined);
  const [widgets, setWidgets] = useState(undefined);
  const [distributionListOptions, setDistributionListOptions] = useState(null);
  const [currentUserTag, setCurrentUserTag] = useState(null);
  const [generationTimeOptions, setGenerationTimeOptions] = useState(null);
  const [generationDayField, setGenerationDayField] = useState(getEmptyCell('generationDay'));
  const [periodDateField, setPeriodDateField] = useState(getEmptyCell('periodDate'));
  const [period, setPeriod] = useState(null);
  const [isHeaderWidgetVisible, setIsHeaderWidgetVisible] = useState(true);
  const [isFooterWidgetVisible, setIsFooterWidgetVisible] = useState(true);
  const [headerWidget, setHeaderWidget] = useState(defaultHeaderWidget);
  const [footerWidget, setFooterWidget] = useState(defaultFooterWidget);
  const [orientation, setOrientation] = useState(null);
  const layout = null;
  const [tempValues, setTempValues] = useState({});

  useEffect( () => {
    loadReport();

    // Recover user preferences and userInfoId
    api.get('/UserPreferences/own').then((result) => {
      const { data } = result;
      setCurrentUserTag([{
        userInfoId: data.userInfoId,
        name: user.name
      }]);
    }).catch((error) => {
      throw new Error('Errore recupero preferenze utente');
    });

    // Set internal users list
    if (!distributionListOptions) {
      api.get(getBeaUsersEndpoint(type, id)).then((result) => {
        setDistributionListOptions( getBeaUsersTags(result.data) );
      }).catch(err => {
        // Notify error
        toast.error('Errore caricamento elenco utenti interni');
      });;
    }

    setGenerationTimeOptions( getGenerationTimeOptions() );

    setIsLoaded(true);

    return;

  }, []);

  const onChangeGenerationDay = (value, formValues) => {
    // Assign a new generationStart date
    formValues.generationStart = getFirstGenerationDay(value);

    // Set tempValues again because the function is passed as a callback
    setTempValues({
      generationDay: value,
      generationPeriodicity: formValues.generationPeriodicity,
      personalInstance: formValues.personalInstance,
      generationTime: formValues.generationTime,
    });
  }

  const getShowHeaderValue = (report) => {
    const hasReportConfigurationFormat = report && report.configuration && report.configuration.format;
    if (hasReportConfigurationFormat && typeof report.configuration.format.showHeader === 'boolean') {
      return report.configuration.format.showHeader;
    }
    return isHeaderWidgetVisible;
  }

  const getShowFooterValue = (report) => {
    const hasReportConfigurationFormat = report && report.configuration && report.configuration.format;
    if (hasReportConfigurationFormat && typeof report.configuration.format.showFooter === 'boolean') {
      return report.configuration.format.showFooter;
    }
    return isFooterWidgetVisible;
  }

  const detectIfTimeZoneIsDisabled = () => {
    const typeDisabledTimeZones = [
      'domain', 'domains',
      'company', 'companies',
      'sitegroup', 'sitegroups'
    ];
    return ( type && (typeDisabledTimeZones.includes(type)) );
  }

  const getPeriodDateField = () => {
    const fld = {
      name: 'periodDate',
      type: 'date',
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
      label: '',
      props: {
        lang: 'it',
        showTime: false,
        showDateInput: true,
        disabled: false
      }
    };
    return fld;
  }

  const onChangePeriod = (value) => {
    const currentPeriodDate = getPeriodDateField();
    const periodDate = (value === 'from') ? currentPeriodDate : getEmptyCell('periodDate');

    setPeriodDateField(periodDate);
  }

  const getGenerationTimeOptions = (isTodayDate) => {
    const start = moment().startOf('day');
    const times = 24 * 4; // 24 hours * 15 mins in an hour

    const hourMinutes = [];
    for (let i = 0; i < times; i++) {
      const dateToAdd = moment(start).add(15 * i, 'minutes');
      const toPrint = dateToAdd.format('HH:mm');

      if (isTodayDate) {
        const currentTime = moment(moment().format('HH:mm'), 'HH:mm');
        const dateToAddAsMomentTime = moment(toPrint, 'HH:mm');
        if ( dateToAddAsMomentTime.isAfter(currentTime) ) {
          hourMinutes.push({ value: toPrint, label: toPrint });
        }
      } else {
        hourMinutes.push({ value: toPrint, label: toPrint });
      }

    }

    return hourMinutes;
  }

  const loadReport = () => {

    if (isNew || report) { // The report condition disallow get report twice
      setTempValues({ ...tempValues, personalInstance: true }); // temporary values for new report
      return;
    }

    const { reportid } = match.params;

    // reportid must be a number
    const isReportIdNumber = /^-?[\d.]+(?:e-?\d+)?$/.test(reportid);
    if (!isReportIdNumber) {
      setRedirect(true);
      return;
    }

    // NOTE if report not found, the API returns an error
    api.get(`/Reports/${reportid}`).then((res) => {
      const result = res.data;

      // Set header and footer widget configurations
      const hasResultFormat = (result && result.configuration && result.configuration.format);
      if (hasResultFormat) {

        // Load widget header properties
        if (result.configuration.format.header) {
          setHeaderWidget(result.configuration.format.header);
        }

        // Load widget footer properties
        if (result.configuration.format.footer) {
          setFooterWidget(result.configuration.format.footer);
        }

        // Attach fields for the form initial values
        result.layout = result.configuration.format.layout;
        result.orientation = result.configuration.format.orientation;
        result.showHeader = result.configuration.format.showHeader;
        result.showFooter = result.configuration.format.showFooter;
        result.lang = result.configuration.format.lang;
      }

      // Convert fields values into moment object to avoid error
      result.periodDate = moment(result.periodDate);
      result.generationStart = moment(result.generationStart); // AirBnb date picker

      // Set main widgets
      if (result && result.configuration && result.configuration.widgets) {
        setWidgets(result.configuration.widgets);
      }

      // Header widget visibility flag
      setIsHeaderWidgetVisible( getShowHeaderValue(result) );

      // Footer widget visibility flag
      setIsFooterWidgetVisible( getShowFooterValue(result) );

      // Setta periodo riferimento. S periodo = from, mostra data a fianco
      if (result.configuration.period && result.configuration.period.reference) {
        setPeriod(result.configuration.period.reference);
        if (result.configuration.period && result.configuration.period.reference === 'from') {
          onChangePeriod( getPeriodDateField() );
        }
      }

      // Set personalInstance defualt temporary value for the sharing report fields
      setTempValues({ ...tempValues, personalInstance: result.personalInstance });

      // Set report period field due to generationPeriodicity value
      if (result.generationPeriodicity) {
        setGenerationDayField( getGenerationDay(result.generationPeriodicity, onChangeGenerationDay) );
      }

      setReport(result);

    }).catch(err => {
      setLoadingError('Errore caricamento dati report');
    });
  };

  const updateHeaderWidget = (widget) => {
    setHeaderWidget(widget);
  }

  const updateFooterWidget = (widget) => {
    setFooterWidget(widget);
  }

  const validateGenerationTime = (generationTime, generationStart) => {
    if (!generationStart instanceof moment) {
      // Invalid date has been submitted
      return;
    }
    const generationTimeSplit = generationTime.split(":");
    if (generationTimeSplit.length !== 1) {
      // Invalid time has been submitted
      return;
    }
  };

  const submitReport = async (data) => {
    setError(null);
    setIsLoaded(false);

    try {
      if (!data.generationStart) {
        throw new Error(i18n._(t`Selezionare data inizio a partire dal...`));
      }
      if (!data.generationTime) {
        throw new Error(i18n._(t`Selezionare orario`));
      }
      // Further check on report name form
      if (!report.name) {
        throw new Error(i18n._(t`Errore aggiornamento report: nessun nome`));
      }

      // Nullify generationDay if the report is daily
      if (report.generationPeriodicity === 'daily') {
        report.generationDay = 'everyday'; // Symbolic value, cannot be blank for the backend
        data.generationDay = 'everyday';
      }

      if (report.generationPeriodicity !== 'daily' && data.generationDay === 'everyday') {
        throw new Error(i18n._(t`Selezionare Report elaborato ogni`));
      }

      validateGenerationTime(data.generationTime, data.generationStart);

      // Check form report name and submit
      const lastUpdateDate = moment().toISOString();
      report.updated = lastUpdateDate;
      report.configuration = (report.configuration) ? report.configuration : {};
      // Periodo riferimento per storico report
      report.configuration.period = {
        reference: report.period,
        date: lastUpdateDate,
        dateFrom: (report.period === 'from') ? report.periodDate : null,
      };
      report.configuration.format = {
        layout: report.layout, // orientation
        margin: [2.5, 2, 2, 2], // fixed margins
        orientation: report.orientation,
        showHeader: data.showHeader,
        showFooter: data.showFooter,
        lang: report.lang,
        header: {
          ...headerWidget,
          show: isHeaderWidgetVisible // flag show \ hide header
        },
        footer: {
          ...footerWidget,
          show: isFooterWidgetVisible, // flag show \ hide footer
          documentPages: (widgets) ? getDocumentTotalPages(widgets) : 1,
        }
      };

      // Save widgets by creating node
      if (widgets) {
        report.configuration.widgets = widgets;
      }

      setReport({ ...report, ...data });

      let res;
      if (report.id) {
        // Update report
        res = await api.patch(`/Reports/${report.id}`, report);
      } else {
        // Insert new report
        const endpoint = getPostSubmitEndPoint( getEndpointType(type), id );
        res = await api.post(endpoint, report);
      }

      if (!res.data) {
        // Error update report
        throw new Error( i18n._(t`Errore aggiornamento report`) );
      }

      // Redirect to grid
      const queryParameters = new URLSearchParams(window.location.search);
      const filter = queryParameters.get("fromFilter");
      const reportUrlRedir = getUrlFromPath('/reports'+ (filter ? ('?filter=' + filter) : "") + '#'+ res.data.id);
      history.push( reportUrlRedir );

      setIsLoaded(true);

    } catch (error) {
      setError(error);
      setIsLoaded(true);
    }
  };

  const cancel = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const filter = queryParameters.get("fromFilter");
    if(report){
      history.push(getUrlFromPath('/reports' + (filter ? ('?filter=' + filter) : "") + '#'+ report.id));
    }else{
      history.push(getUrlFromPath('/reports' + (filter ? ('?filter=' + filter) : "")));
    }
  };

  const onStepSubmit = async (data) => {
    const resFormReportName = formReportNameRef.current ? await formReportNameRef.current.validateAndGetValues() : null;
    if (resFormReportName) {
      setReport({ ...report, ...data, name: resFormReportName.name });
    } else {
      const errorMessage = i18n._(t`Errore recupero nome report dal form`);
      setError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const saveReportWidgets = (widgets) => {
    // Save widgets in a variable
    setWidgets(widgets);
  };

  const getFormContenstsInitialValues = () => {
    if (report) {
      const reportPeriod = (report.configuration && report.configuration.period) ? report.configuration.period : null;
      const reportPeriodReference = (reportPeriod && reportPeriod.reference) ? reportPeriod.reference : null;
      const reportPeriodDate = (reportPeriod && reportPeriod.date) ? moment(reportPeriod.date) : moment();
      // TODO set orientation and layout
      return {
        ...report,
        period: reportPeriodReference,
        periodDate: reportPeriodDate,
      };
    }

    // New report form contents values (step 1)
    return {
      periodDate: moment(),
      layout: 'A4',
      orientation: 'verticale',
      showHeader: true,
      showFooter: true,
      lang: 'it',
    };
  }

  const getFormPlanningInitialValues = () => {
    // Form step 2 initial values
    const defaultGenerationTime = '08:00';
    if (isNew) {
      return {
        generationTime: defaultGenerationTime,
        generationPeriodicity: 'daily',
        generationStart: moment(),
        timeZone: moment.tz.guess(),
        personalInstance: true,
        distributionList: (isNew && currentUserTag) ? currentUserTag : []
      };
    }
    return report;
  }

  const getUsersTagsList = () => {
    if (tempValues && tempValues.personalInstance === true) {
      return (currentUserTag) ? currentUserTag : [];
    }

    return (distributionListOptions) ? distributionListOptions : [];
  }

  const onGenerateReportPreview = async () => {
    // Recover form report name
    const resFormReportName = (formReportNameRef.current) ? await formReportNameRef.current.validateAndGetValues() : null;

    // Current step + current values. TODO get next step values?
    const firstStepValules = await reportWizardRef.current.getStepValues();
    await reportWizardRef.current.nextStep(); // go to second form wizard step
    const secondStepValues = await reportWizardRef.current.getStepValues();
    await reportWizardRef.current.goToStep(0, false);
    const currentReport = { ...firstStepValules, ...secondStepValues, ...resFormReportName };

    // Check form report name and submit
    const lastUpdateDate = moment().toISOString();
    currentReport.updated = lastUpdateDate;
    currentReport.configuration = (currentReport.configuration) ? currentReport.configuration : {};
    // Periodo riferimento per storico currentReport
    currentReport.configuration.period = {
      reference: currentReport.period,
      date: lastUpdateDate,
      dateFrom: (currentReport.period === 'from') ? currentReport.periodDate : null,
    };
    currentReport.configuration.format = {
      layout: currentReport.layout, // orientation
      margin: [2.5, 2, 2, 2], // fixed margins
      orientation: currentReport.orientation,
      showHeader: currentReport.showHeader,
      showFooter: currentReport.showFooter,
      lang: currentReport.lang,
      header: {
        ...headerWidget,
        show: isHeaderWidgetVisible // flag show \ hide header
      },
      footer: {
        ...footerWidget,
        show: isFooterWidgetVisible, // flag show \ hide footer
        documentPages: (widgets) ? getDocumentTotalPages(widgets) : 1,
      }
    };
    if (isNew) {
      currentReport.domainId = selectedDomain.id;
    }
    currentReport.distributionList = null;

    // Save widgets by creating node
    if (widgets) {
      currentReport.configuration.widgets = widgets;
    }

    return currentReport;
  }

  if (redirect) {
    const url = getUrl('/reports', type, id);
    return <Redirect to={url} />;
  } else if (loadingError) {
    return <ReportError error={loadingError} />
  } else if (!isLoaded || (!isNew && !report) ) {
    return <ReportLoading />
  } else {
    return (
      <Wizard
        card
        ref={reportWizardRef}
        title={
          (
            <DynamicForm
              ref={formReportNameRef}
              hideButtons
              hideErrors
              fields={[
                {
                  name: 'name',
                  type: 'text',
                  label: '',
                  placeholder: i18n._(t`Nome report`),
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 6,
                  validation: { required: true },
                  onBlur: (value, values) => {
                    setTempValues({...tempValues, name: value});
                  },
                  hideLabel: true,
                  nullable: false,
                  canSearch: false,
                  disabled: false
                },
              ]}
              initialValues={{
                name: (report && report.name) ? report.name : i18n._(t`Nuovo Report`)
              }}
              error={error}
              readOnly={readOnly}
              hideRequiredLabel={true}
            />
          )
        }
        value={report ? report : {}}
        edit={edit}
        readOnly={readOnly}
        onStepSubmit={onStepSubmit}
        onSave={submitReport}
        onCancel={cancel}
        error={error}
      >
        <WizardStep
          title={<Trans>Aspetto e contenuti</Trans>}
          label={<Trans>Definisci aspetto, contenuti e dati del report</Trans>}
          validatedProperties={['layout', 'orientation', 'lang']}
        >
            <DynamicForm
              hideButtons
              fields={[
                {
                  name: 'header1',
                  type: 'custom',
                  render: () => <h4><Trans>Aspetto</Trans></h4>
                },
                {
                  name: 'layout',
                  type: 'select',
                  label: <Trans>Formato pagine</Trans>,
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  validation: { required: true },
                  nullable: false,
                  canSearch: false,
                  disabled: report ? false : true,
                  options: [
                    { value: 'A4', label: 'A4' },
                  ],
                },
                {
                  name: 'orientation',
                  type: 'select',
                  label: <Trans>Orientamento</Trans>,
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  options: [
                    { value: 'orizzontale', label: 'Orizzontale' },
                    { value: 'verticale', label: 'Verticale'}
                  ],
                  validation: { required: true },
                  nullable: false,
                  canSearch: false,
                  onChange: (value) => {
                    setOrientation(value);
                  },
                },
                {
                  name: 'showHeader',
                  type: 'checkbox',
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: <Trans>Mostra intestazione</Trans>,
                  onChange: (value) => {
                   setIsHeaderWidgetVisible(value);
                  },
                },
                {
                  name: 'showFooter',
                  type: 'checkbox',
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: <Trans>Mostra piè di pagina</Trans>,
                  onChange: (value) => {
                   setIsFooterWidgetVisible(value);
                  },
                },
                {
                  name: 'header2',
                  type: 'custom',
                  render: () => <h4><Trans>Contenuti</Trans></h4>
                },
                {
                  name: 'lang',
                  type: 'select',
                  label: <Trans>Lingua</Trans>,
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  options: [
                    { value: 'en', label: 'English'},
                    { value: 'es', label: 'Español'},
                    { value: 'fr', label: 'Français'},
                    { value: 'it', label: 'Italiano'},
                  ],
                  validation: { required: true },
                  nullable: false,
                  canSearch: false
                },
                getEmptyCell('empty_half_row'),
                {
                  name: 'period',
                  type: 'select',
                  label: <Trans>Periodo di competenza del report</Trans>,
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  options: getPeriodOptions(),
                  nullable: false,
                  canSearch: false,
                  onChange: (value) => {
                    // Set period to pass to widgets
                    setPeriod(value);
  
                    // Show \ hide period date
                    onChangePeriod(value);

                    setTempValues({ ...tempValues, period: value });
                  },
                },
                periodDateField,
                {
                  name: 'add_widget_preview',
                  type: 'custom',
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  render: () => {
                    return (
                      <ReportWidgets
                        {...props}
                        report={report}
                        tempValues={tempValues}
                        orientation={(orientation) ? orientation : 'verticale'}
                        layout={(layout) ? layout : 'A4'}
                        isHeaderWidgetVisible={isHeaderWidgetVisible}
                        isFooterWidgetVisible={isFooterWidgetVisible}
                        headerWidget={headerWidget}
                        onUpdateHeaderWidget={updateHeaderWidget}
                        footerWidget={footerWidget}
                        onUpdateFooterWidget={updateFooterWidget}
                        period={period}
                        widgets={widgets}
                        onSaveReportWidgets={saveReportWidgets}
                        onGenerateReportPreview={onGenerateReportPreview}
                        onGeneratePdf={onGeneratePdf}
                      />
                    );
                  }
                }
              ]}
              initialValues={ getFormContenstsInitialValues() }
              error={error}
              readOnly={readOnly}
              hideRequiredLabel={false}
            />
        </WizardStep>
        <WizardStep
          title={<Trans>Pianificazione e condivisione</Trans>}
          label={<Trans>Pianifica periodicità e modalità di condivisione del report</Trans>}
          validatedProperties={['generationPeriodicity', 'generationDay', 'generationStart', 'timeZone']}
        >
          <DynamicForm
            hideButtons
            fields={[
              {
                name: 'header1',
                type: 'custom',
                render: () => <h5><Trans>Pianificazione per l'elaborazione del report</Trans></h5>
              },
              {
                name: 'generationPeriodicity',
                type: 'select',
                label: <Trans>Periodicità del report</Trans>,
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
                options: getGenerationPeriodicity(),
                validation: { required: true },
                hidden: false,
                nullable: false,
                canSearch: false,
                disabled: false,
                onChange: (value, formValues) => {
  
                  setTempValues({
                    ...tempValues,
                    generationPeriodicity: value,
                  });

                  setGenerationDayField( getGenerationDay(value, onChangeGenerationDay) );

                  // Update generationDay and generationStart form values
                  formValues.generationDay = (value === 'yearly') ? '01-01' : null;
                },
              },
              generationDayField,
              {
                name: 'generationTime',
                type: 'select',
                label: <Trans>Orario</Trans>,
                xs: 12,
                sm: 12,
                md: 2,
                lg: 2,
                options: generationTimeOptions,
                nullable: false,
                canSearch: false,
                validation: { required: true },
                disabled: false,
                onChange: (value) => {
                  setTempValues({ ...tempValues, generationTime: value });
                  setReport({ ...report, generationTime: value });
                }
              },
              getEmptyCell('empty_half_row2', 4),
              {
                name: 'timeZone',
                type: 'select',
                label: <Trans>Fuso orario</Trans>,
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
                options: moment.tz.names().map(x => ({ value: x, label: x })),
                validation: { required: true },
                nullable: false,
                canSearch: false,
                disabled: detectIfTimeZoneIsDisabled()
              },
              {
                name: 'generationStart',
                type: 'date-airbnb',
                label: <Trans>Inizio a partire dal</Trans>,
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6,
                nullable: false,
                canSearch: false,
                disabled: (report && report.generationStart <= moment()) ? true : false,
                showDefaultInputIcon: true,
                required: true,
                readOnly: true,
                numberOfMonths: 1,
                inputIconPosition: 'after',
                onChange: (value, formValues) => {
                  const isTodayDay = (value && value instanceof moment && value.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));
                  const timeOptions = getGenerationTimeOptions(isTodayDay);
                  setGenerationTimeOptions(timeOptions);

                  /**
                   * Se si seleziona la data di oggi l'orario selezionato non deve essere inferiore a quallo attuale. 
                   * Setta il primo orario utile fra le opzioni con l'elenco degli orari aggiornati
                   */
                  const currentDay = moment().format('DD-MM-YYYY');
                  const currentTime = moment( moment().format('HH:mm'), 'HH:mm');
                  const generationTimeAsMoment = moment(formValues.generationTime, 'HH:mm');
                  const isCurrentDay = (value instanceof moment && currentDay === value.format('DD-MM-YYYY'));
                  if (isCurrentDay && generationTimeAsMoment.isBefore(currentTime) ) {
                    formValues.generationTime = timeOptions[0].value;
                  }
                },
                isDayBlocked: (day) => {
                  // Callback to allow select dates, return TRUE to block a day
                  let finalCondition = false;

                  if (tempValues && ['daily', 'yearly'].includes(tempValues.generationPeriodicity)) {
                    return false; // if value is changed to daily or yearly, allow all dates
                  }

                  const generationStartValue = tempValues.generationDay ? tempValues.generationDay : report.generationDay;
  
                  if (generationStartValue) {
                    const weekDaysRange = {
                      monday: 0,
                      thursday: 1,
                      wednesday: 2,
                      tuesday: 3,
                      friday: 4,
                      saturday: 5,
                      sunday: 6
                    };

                    const date = day.toDate(), y = date.getFullYear(), m = date.getMonth();
                    const firstDay = new Date(y, m, 1);
                    const lastDay = new Date(y, m + 1, 0);
  
                    const weekDay = day.weekday();
                    const startOfMonth = moment(firstDay);
                    const endOfMonth = moment(lastDay);
  
                    // Day of week
                    if (generationStartValue in weekDaysRange) {
                      finalCondition = weekDay !== weekDaysRange[generationStartValue];
                    }
  
                    // Day of month N
                    if (typeof generationStartValue === 'string' && generationStartValue.includes('day_of_month_') ) {
                      const dayMonthNumber = generationStartValue.replace( /^\D+/g, '');
                      if (dayMonthNumber > 1) {
                        finalCondition = day.format('YYYY-MM-DD') !== startOfMonth.add(dayMonthNumber - 1, 'days').format('YYYY-MM-DD');
                      } else {
                        finalCondition = day.format('YYYY-MM-DD') !== startOfMonth.format('YYYY-MM-DD');
                      }
                    }

                    // Last days of month
                    switch(generationStartValue) {
                      case('last_but_two_day_month'): // Terzultimo giorno del mese
                        const lastButTwoDayMonth = endOfMonth.subtract(2, 'day');
                        finalCondition = day.format('YYYY-MM-DD') !== lastButTwoDayMonth.format('YYYY-MM-DD');
                        break;
  
                      case('penultimate_day_month'): // Penultimo giorno del mese
                        const penultimateDayMonth = endOfMonth.subtract(1, 'day');
                        finalCondition = day.format('YYYY-MM-DD') !== penultimateDayMonth.format('YYYY-MM-DD');
                        break;
  
                      case('last_day_month'): // Ultimo giorno del mese
                        finalCondition = day.format('YYYY-MM-DD') !== endOfMonth.format('YYYY-MM-DD');
                        break;
                      default:
                        break;
                    }

                    // Determine if today date will be unselectable
                    let reportGenerationTime = tempValues.generationTime; // changed time for NEW reports
                    if (!reportGenerationTime && report && report.generationTime) {
                      reportGenerationTime = report.generationTime;
                    }
                    if (['23:30', '23:45', '00:00'].includes(reportGenerationTime)) {
                      const iscurrentDate = day.isSame(new Date(), 'day');
                      return finalCondition || iscurrentDate;
                    }

                    return finalCondition;
                  }
  
                  return false; // enable all days
                }
              },
              getEmptyCell('empty_half_row3'),
              {
                name: 'header2',
                type: 'custom',
                render: () => <h5><Trans>Visibilità e condivisione</Trans></h5>
              },
              {
                name: 'personalInstance',
                type: 'select',
                label: <Trans>Visibilità</Trans>,
                options: [
                  { value: false, label: i18n._(t`Report condiviso`) },
                  { value: true, label: i18n._(t`Report privato`) }
                ],
                validation: { required: true },
                nullable: false,
                canSearch: false,
                disabled: (report && report.personalInstance === false),
                onChange: (value) => {
                  setTempValues({ ...tempValues, personalInstance: value });
                }
              },
              {
                name: 'distributionList',
                type: 'tags-report',
                label: i18n._(t`Destinatari per invio report via email`),
                options: getUsersTagsList(),
                keyProperty: 'userInfoId',
                labelProperty: 'name',
                emailTextTags: true,
                canChangeText: (tempValues.personalInstance === false),
                mustSetDefaultValue: true,
                defaultValue: (report && report.distributionList) ? report.distributionList : currentUserTag,
              },
            ]}
            initialValues={ getFormPlanningInitialValues() }
            error={error}
            readOnly={readOnly}
            hideRequiredLabel={false}
          />
        </WizardStep>
      </Wizard>
    );
  }
};

EditReport.propTypes = {
  edit: PropTypes.bool,
  isNew: PropTypes.bool,
  readOnly: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.object,
  id: PropTypes.number,
  onGeneratePdf: PropTypes.func
};

const mapStateToProps = (state) => {
  const {
    type,
    id,
    subtype,
    subid,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAssetgroup,
    selectedAsset,
    companies,
    sitegroups,
    sites
  } = state.navigation;
  const { userSystemRoles, user } = state.auth;
  return {
    type,
    id,
    subtype,
    subid,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAssetgroup,
    selectedAsset,
    userSystemRoles,
    companies,
    sitegroups,
    sites,
    user
  };
};

export default connect(mapStateToProps)(EditReport);
