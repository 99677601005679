/* eslint-disable quote-props */

import { t } from '@lingui/macro';
import i18n from 'app/i18n';
import moment from 'moment';

const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const getNewKey = () =>
  (
    S4() +
    S4() +
    '-' +
    S4() +
    '-4' +
    S4().substr(0, 3) +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toLowerCase();

export const widgetTypes = {
  SERIES: 'series',
  PIE: 'pie',
  DISPLAY: 'display',
  TABLE: 'table',
  GAUGE: 'gauge',
  CIRCLE: 'circle',
  IMAGE: 'image',
  TEXT: 'text',
  LED: 'led',
  SWITCH: 'switch',
  SLIDER: 'slider',
  REPORT_HEADER: 'reportHeader',
  REPORT_FOOTER: 'reportFooter'
};

// Periodi chiusi con data di fine al passato per cui NON va effettuato l'aggiornamento dei dati real time
export const closedPeriodIntervals = [
  'yesterday',
  'lastweek',
  'lastmonth',
  'lastbimester',
  'lastthreemester',
  'lastsemester',
  'lastyear'
];

// Granularità inferiori a 1 giorno
export const granularityLessThanOneDay = ['raw', '1m', '5m', '10m', '15m', '30m', '1h'];
export const isGranularityLessThanOneDay = granularity =>
  granularityLessThanOneDay.includes(granularity);

export const initialSizes = {
  series: { w: 6, h: 4 },
  pie: { w: 2, h: 3 },
  display: { w: 2, h: 1 },
  gauge: { w: 2, h: 2 },
  circle: { w: 2, h: 2 },
  image: { w: 2, h: 2 },
  text: { w: 4, h: 2 },
  table: { w: 12, h: 4 },
  led: { w: 2, h: 1 },
  switch: { w: 2, h: 1 },
  slider: { w: 2, h: 1 },
  reportHeader: { w: 12, h: 1 },
  reportFooter: { w: 12, h: 1 }
};

export const adjustDate = (start, end, interval, aggregation) => {
  let endDate = moment(end).format();
  let startDate = moment(start).format();
  if (interval !== 'lastvalue') {
    // se aggregazione 1 minuto, poiché i valori vengono calcolati in ritardo, leggo qualche minuto in più
    // e prendo solo il giusto numero di valori (es: 1h => 60 minuti)
    startDate =
      aggregation === '1m'
        ? moment(start)
            .subtract(5, 'minutes')
            .format()
        : moment(start).format();
    endDate = moment(end).format();
    const dateFormat = null;
    if (granularityLessThanOneDay.includes(aggregation)) {
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      // add 100 ms to exclude first value of start time
      startDate = moment(startDate)
        .add(100, 'milliseconds')
        .format(dateFormat);
      if (closedPeriodIntervals.includes(interval)) {
        // add 100 ms to include last value of end time
        endDate = moment(endDate)
          .add(100, 'milliseconds')
          .format(dateFormat);
      }
    } else if (closedPeriodIntervals.includes(interval)) {
      // subtract 1 day to exclude last value
      endDate = moment(endDate)
        .subtract(1, 'days')
        .format(dateFormat);
    }
  }

  return [startDate, endDate];
};

export const takeLastValues = (items, interval) => {
  if (!Array.isArray(items)) {
    return [];
  }
  let numberOfMinutes;
  switch (interval) {
    case '5m':
      numberOfMinutes = 5;
      break;
    case '15m':
      numberOfMinutes = 15;
      break;
    case '1h':
      numberOfMinutes = 60;
      break;
    case '3h':
      numberOfMinutes = 180;
      break;
    default:
      numberOfMinutes = items.length;
  }
  items.splice(0, items.length - numberOfMinutes);
  return items;
};

export const getStartDate = (end, interval, aggregationDate) => {
  const momentDate = end ? moment(end) : moment();
  const currentDateMonth = parseInt(momentDate.format('M'));

  let start;
  switch (interval) {
    case '5m':
      start = momentDate.subtract(5, 'minutes');
      break;
    case '15m':
      start = momentDate.subtract(15, 'minutes');
      break;
    case '1h':
      start = momentDate.subtract(1, 'hours');
      break;
    case '3h':
      start = momentDate.subtract(3, 'hours');
      break;
    case '12h':
      start = momentDate.subtract(12, 'hours');
      break;
    case '24h':
      start = momentDate.subtract(24, 'hours');
      break;
    case 'today':
      start = momentDate.startOf('day');
      break;
    case 'yesterday':
      start = momentDate.startOf('day').subtract(1, 'days');
      break;
    case 'yesterdayandtoday':
      start = momentDate.startOf('day').subtract(1, 'days');
      break;
    case 'lastweek':
      start = momentDate.startOf('week').subtract(1, 'week');
      break;
    case 'thisweek':
      start = momentDate.startOf('week');
      break;
    case '7d':
      start = momentDate.startOf('day').subtract(6, 'days');
      break;
    case 'thismonth':
      start = momentDate.startOf('month');
      break;
    case 'lastmonth':
      start = momentDate.startOf('month').subtract(1, 'month');
      break;
    case '30d':
      start = momentDate.startOf('day').subtract(29, 'days');
      break;
    case 'thisbimester':
      // Subtract 1 month if the month is even
      if (currentDateMonth % 2 === 0) {
        start = momentDate.startOf('month').subtract(1, 'months');
      } else {
        start = momentDate.startOf('month');
      }
      break;
    case 'lastbimester':
      if (currentDateMonth % 2 === 0) {
        start = momentDate.startOf('month').subtract(3, 'months');
      } else {
        start = momentDate.startOf('month').subtract(2, 'months');
      }
      break;
    case 'thisthreemester':
      if ([1, 4, 7, 10].includes(currentDateMonth)) {
        start = momentDate.startOf('month');
        break;
      } else if ([2, 5, 8, 11].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(1, 'months');
        break;
      } else if ([3, 6, 9, 12].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(2, 'months');
        break;
      }
      break;
    case 'lastthreemester':
      if ([1, 4, 7, 10].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(3, 'months');
        break;
      } else if ([2, 5, 8, 11].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(4, 'months');
        break;
      } else if ([3, 6, 9, 12].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(5, 'months');
        break;
      }
      break;
    case 'thissemester':
      if ([1, 7].includes(currentDateMonth)) {
        start = momentDate.startOf('month');
        break;
      } else if ([2, 8].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(1, 'months');
        break;
      } else if ([3, 9].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(2, 'months');
        break;
      } else if ([4, 10].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(3, 'months');
        break;
      } else if ([5, 11].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(4, 'months');
        break;
      } else if ([6, 12].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(5, 'months');
        break;
      }
      break;
    case 'lastsemester':
      if ([1, 7].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(6, 'months');
        break;
      }
      if ([2, 8].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(7, 'months');
        break;
      }
      if ([3, 9].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(8, 'months');
        break;
      }
      if ([4, 10].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(9, 'months');
        break;
      }
      if ([5, 11].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(10, 'months');
        break;
      }
      if ([6, 12].includes(currentDateMonth)) {
        start = momentDate.startOf('month').subtract(11, 'months');
        break;
      }
      break;
    case 'thisyear':
      start = momentDate.startOf('year');
      break;
    case '365d':
      start = momentDate.startOf('day').subtract(365, 'days');
      break;
    case 'lastyear':
      start = momentDate.startOf('year').subtract(1, 'year');
      break;
    case 'lastyearlast':
      start = momentDate.startOf('year').subtract(1, 'year');
      break;
    case '1yr':
      start = momentDate.startOf('year').subtract(364, 'days');
      break;
    case 'from':
      if (aggregationDate && aggregationDate instanceof moment) {
        start = aggregationDate.startOf('day');
        break;  //DO NOT MOVE
      } 
    default:
      return undefined;
  }
  return start.toDate();
};

const getXmesterEndDate = (mmNowDate, isGranularityLessThan1D, numberOfMonths) => {
  const nowDate = mmNowDate.clone();
  const currentDateMonth = parseInt(nowDate.format('M'));
  const remainingMonths = numberOfMonths - (currentDateMonth % numberOfMonths);
  return isGranularityLessThan1D
    ? nowDate
        .add(remainingMonths, 'months')
        .add(1, 'month')
        .startOf('month')
    : nowDate.add(remainingMonths, 'months').endOf('month');
};
export const getEndDate = (endDate, interval, granularity) => {
  // endDate is optional, set at least a Date value:
  const momentDate = endDate ? moment(endDate) : moment();
  const currentDateMonth = parseInt(momentDate.format('M'));
  const isGranularityLessThan1D = isGranularityLessThanOneDay(granularity);
  let end;
  switch (interval) {
    case 'yesterday':
      end = momentDate.startOf('day');
      break;
    case 'lastweek':
      end = momentDate
        .endOf('week')
        .subtract(1, 'week')
        .add(1, 'days')
        .startOf('day');
      break;
    case 'lastmonth':
      end = momentDate
        .subtract(1, 'month')
        .endOf('month')
        .add(1, 'days')
        .startOf('day');
      break;
    case 'lastbimester':
      if (currentDateMonth % 2 === 0) {
        end = momentDate
          .endOf('month')
          .subtract(2, 'months')
          .add(1, 'days')
          .startOf('day');
      } else {
        end = momentDate
          .endOf('month')
          .subtract(1, 'months')
          .add(1, 'days')
          .startOf('day');
      }
      break;
    case 'lastthreemester':
      if ([1, 4, 7, 10].includes(currentDateMonth)) {
        end = momentDate
          .subtract(1, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([2, 5, 8, 11].includes(currentDateMonth)) {
        end = momentDate
          .subtract(2, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([3, 6, 9, 12].includes(currentDateMonth)) {
        end = momentDate
          .subtract(3, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      }
      break;
    case 'lastsemester':
      if ([1, 7].includes(currentDateMonth)) {
        end = momentDate
          .subtract(1, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([2, 8].includes(currentDateMonth)) {
        end = momentDate
          .subtract(2, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([3, 9].includes(currentDateMonth)) {
        end = momentDate
          .subtract(3, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([4, 10].includes(currentDateMonth)) {
        end = momentDate
          .subtract(4, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([5, 11].includes(currentDateMonth)) {
        end = momentDate
          .subtract(5, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      } else if ([6, 12].includes(currentDateMonth)) {
        end = momentDate
          .subtract(6, 'months')
          .endOf('month')
          .add(1, 'days')
          .startOf('day');
      }
      break;
    case 'lastyear':
      end = momentDate
        .endOf('year')
        .subtract(1, 'year')
        .add(1, 'days')
        .startOf('day');
      break;
    case 'today':
    case 'yesterdayandtoday':
      end = isGranularityLessThan1D
        ? momentDate.add(1, 'days').startOf('day')
        : momentDate.endOf('day');
      break;
    case 'thisweek':
      end = isGranularityLessThan1D
        ? momentDate.add(1, 'isoWeek').startOf('isoWeek')
        : momentDate.endOf('isoWeek');
      break;
    case 'thismonth':
      end = isGranularityLessThan1D
        ? momentDate.add(1, 'month').startOf('month')
        : momentDate.endOf('month');
      break;
    case 'thisyear':
    case 'lastyearlast':
      end = isGranularityLessThan1D
        ? momentDate.add(1, 'year').startOf('year')
        : momentDate.endOf('year');
      break;
    case 'thisbimester':
      end = getXmesterEndDate(momentDate, isGranularityLessThan1D, 2);
      break;
    case 'thisthreemester':
      end = getXmesterEndDate(momentDate, isGranularityLessThan1D, 3);
      break;
    case 'thissemester':
      end = getXmesterEndDate(momentDate, isGranularityLessThan1D, 6);
      break;
    case 'from':
      switch (granularity) {
        case '1dy':
          end = momentDate.endOf('day');
          break;
        case '1wk':
          end = momentDate.endOf('isoWeek');
          break;
        case '1mo':
          end = momentDate.endOf('month');
          break;
        default:
          end = momentDate.endOf('day');
      }
      break;
    default:
      return momentDate.toDate();
  }
  return end.toDate();
};

export const roundDateByAggregation = (date, aggregation) => {
  if (!aggregation || aggregation instanceof moment) {
    return date;
  }
  if (aggregation.endsWith('h')) {
    return moment(date)
      .startOf('hour')
      .toDate();
  }
  if (aggregation.endsWith('dy') || aggregation.endsWith('d')) {
    return moment(date)
      .startOf('day')
      .toDate();
  }
  return date;
};

export const getAggregationInMilliseconds = aggregation => {
  switch (aggregation) {
    case 'raw':
      return 1000 * 15;
    case '1m':
      return 1000 * 60;
    case '5m':
      return 1000 * 60 * 5;
    case '10m':
      return 1000 * 60 * 10;
    case '15m':
      return 1000 * 60 * 15;
    case '30m':
      return 1000 * 60 * 30;
    case '1h':
      return 1000 * 60 * 60;
    case '1dy':
      return 1000 * 60 * 60 * 24;
    case '1wk':
      return 1000 * 60 * 60 * 24 * 7;
    case '1mo':
      return 1000 * 60 * 60 * 24 * 31;
    case '1yr':
      return 1000 * 60 * 60 * 24 * 365;
    default:
      return undefined;
  }
};

export const getIntervalOptions = (widgetType, currentModule) => {
  const items = [];
  let defaultValue = '1h';

  const isOnReportException =
    currentModule !== 'report' ||
    widgetType === widgetTypes.LED ||
    widgetType === widgetTypes.CIRCLE;
  if (
    widgetType !== widgetTypes.SERIES &&
    widgetType !== widgetTypes.TABLE &&
    isOnReportException
  ) {
    items.push({ value: 'lastvalue', label: i18n._(t`Ultimo valore`) });
    defaultValue = 'lastvalue';
  }

  if (currentModule !== 'report') {
    items.push(
      { value: '5m', label: i18n._(t`Ultimi 5 minuti`) },
      { value: '15m', label: i18n._(t`Ultimi 15 minuti`) },
      { value: '1h', label: i18n._(t`Ultima ora`) },
      { value: '3h', label: i18n._(t`Ultime ${3} ore`) },
      { value: '12h', label: i18n._(t`Ultime ${12} ore`) }
    );
  } else if (defaultValue !== 'lastvalue') {
    defaultValue = '24h';
  }

  items.push(
    { value: '24h', label: i18n._(t`Ultime ${24} ore`) },
    { value: 'today', label: i18n._(t`Oggi`) },
    { value: 'yesterday', label: i18n._(t`Ieri`) },
    { value: 'yesterdayandtoday', label: i18n._(t`Ieri e oggi`) },
    { value: 'thisweek', label: i18n._(t`Questa settimana`) },
    { value: '7d', label: i18n._(t`Ultimi ${7} giorni`) },
    { value: 'lastweek', label: i18n._(t`Settimana scorsa`) },
    { value: 'thismonth', label: i18n._(t`Questo mese`) },
    { value: '30d', label: i18n._(t`Ultimi ${30} giorni`) },
    { value: 'lastmonth', label: i18n._(t`Mese scorso`) },
    { value: 'thisbimester', label: i18n._(t`Questo bimestre`) },
    { value: 'lastbimester', label: i18n._(t`Bimestre scorso`) },
    { value: 'thisthreemester', label: i18n._(t`Questo trimestre`) },
    { value: 'lastthreemester', label: i18n._(t`Trimestre scorso`) },
    { value: 'thissemester', label: i18n._(t`Questo semestre`) },
    { value: 'lastsemester', label: i18n._(t`Semestre scorso`) },
    { value: 'thisyear', label: i18n._(t`Quest'anno`) },
    { value: '365d', label: i18n._(t`Ultimi ${365} giorni`) },
    { value: 'lastyear', label: i18n._(t`Anno scorso`) },
    { value: 'lastyearlast', label: i18n._(t`Quest'anno e anno scorso`) },
    { value: 'from', label: i18n._(t`Dal...`) }
  );

  return { items, defaultValue };
};

const aggregationsList = key => {
  const aggregations = {
    raw: { value: 'raw', label: i18n._(t`Raw data`) },
    '1m': { value: '1m', label: i18n._(t`1 minuto`) },
    '5m': { value: '5m', label: i18n._(t`${5} minuti`) },
    '10m': { value: '10m', label: i18n._(t`${10} minuti`) },
    '15m': { value: '15m', label: i18n._(t`${15} minuti`) },
    '30m': { value: '30m', label: i18n._(t`${30} minuti`) },
    '1h': { value: '1h', label: i18n._(t`1 ora`) },
    '1dy': { value: '1dy', label: i18n._(t`1 giorno`) },
    '7d': { value: '7d', label: i18n._(t`Ultimi ${7} giorni`) },
    '30d': { value: '30d', label: i18n._(t`Ultimi ${30} giorni`) },
    '1wk': { value: '1wk', label: i18n._(t`1 settimana`) },
    '1mo': { value: '1mo', label: i18n._(t`1 mese`) },
    '1yr': { value: '1yr', label: i18n._(t`1 anno`) }
  };
  if (!aggregations[key]) {
    throw new Error('Lista intervallo non trovata. Key: ' + key);
  }
  return aggregations[key];
};

const getAggregationsPerInterval = (widgetType, valueTypes, interval) => {
  if (interval === 'lastvalue') {
    if (
      [
        widgetTypes.PIE,
        widgetTypes.CIRCLE,
        widgetTypes.GAUGE,
        widgetTypes.LED,
        widgetTypes.TABLE
      ].includes(widgetType) &&
      (valueTypes.includes('cumulated') || valueTypes.includes('delta'))
    ) {
      return { items: ['1m', '5m', '10m', '15m', '30m', '1h'], defaultValue: '1m' };
    }
    if ([widgetTypes.SLIDER, widgetTypes.SWITCH].includes(widgetType)) {
      return { items: ['raw'], defaultValue: 'raw' };
    }
    return { items: ['raw', '1m', '5m', '10m', '15m', '30m', '1h'], defaultValue: 'raw' };
  }

  if (widgetType === widgetTypes.TABLE) {
    switch (interval) {
      case '5m':
      case '15m':
        return { items: ['1m'], defaultValue: '1m' };

      case '1h':
        return { items: ['5m', '10m', '15m'], defaultValue: '15m' };

      case '3h':
        return { items: ['10m', '15m'], defaultValue: '15m' };

      case '12h':
        return { items: ['30m', '1h'], defaultValue: '1h' };

      case 'yesterday':
        return { items: ['1h', '1dy'], defaultValue: '1h' };

      case '24h':
      case 'today':
      case 'yesterdayandtoday':
        return { items: ['1h'], defaultValue: '1h' };

      case '7d':
      case 'thisweek':
      case 'last7days':
        return { items: ['1dy'], defaultValue: '1dy' };

      case 'lastweek':
      case 'thismonth':
      case '30d':
        return { items: ['1dy', '1wk'], defaultValue: '1dy' };

      case 'lastmonth':
        return { items: ['1dy', '1wk', '1mo'], defaultValue: '1mo' };

      case 'thisbimester':
        return { items: ['1wk'], defaultValue: '1wk' };

      case 'lastbimester':
      case 'thisthreemester':
      case 'thissemester':
      case 'lastsemester':
        return { items: ['1wk', '1mo'], defaultValue: '1mo' };

      case 'thisyear':
      case '365d':
      case 'lastyearlast':
        return { items: ['1mo'], defaultValue: '1mo' };

      case 'lastyear':
        return { items: ['1mo', '1yr'], defaultValue: '1mo' };

      case 'from':
        return { items: ['1dy', '1wk', '1mo'], defaultValue: '1dy' };
      default:
        break;
    }
  }

  if (widgetType === widgetTypes.SERIES) {
    switch (interval) {
      case '30d':
      case 'lastmonth':
      case 'thismonth':
      case 'thisbimester':
      case 'lastbimester':
        return { items: ['1h', '1dy'], defaultValue: '1dy' };
      case 'thisthreemester':
      case 'lastthreemester':
        return { items: ['1h', '1dy', '1wk'], defaultValue: '1dy' };
      case 'thissemester':
      case 'lastsemester':
      case 'thisyear':
      case '365d':
      case 'lastyear':
      case 'lastyearlast':
      case 'from':
        return { items: ['1dy', '1wk', '1mo'], defaultValue: '1dy' };
      default: 
        break;
    }
  }

  if ([widgetTypes.CIRCLE].includes(widgetType)) {
    switch (interval) {
      case '30d':
      case 'lastmonth':
      case 'thismonth':
      case 'thisbimester':
      case 'lastbimester':
      case 'thisthreemester':
      case 'lastthreemester':
        return { items: ['1h'], defaultValue: '1h' };
      case 'thissemester':
      case 'lastsemester':
      case 'lastyearlast':
      case '365d':
      case 'thisyear':
      case 'lastyear':
      case 'from':
        return { items: ['1dy'], defaultValue: '1dy' };
      default: 
        break;
    }
  }

  if ([widgetTypes.PIE, widgetTypes.DISPLAY].includes(widgetType)) {
    switch (interval) {
      case '5m':
        return { items: ['raw', '1m'], defaultValue: 'raw' };
      case '15m':
        return { items: ['raw', '1m'], defaultValue: 'raw' };
      case '1h':
        return { items: ['raw', '1m', '5m', '10m', '15m'], defaultValue: 'raw' };
      case '3h':
        return { items: ['raw', '1m', '5m', '10m', '15m'], defaultValue: '5m' };
      case '12h':
      case '24h':
      case 'today':
      case 'yesterday':
      case 'yesterdayandtoday':
        return { items: ['5m', '10m', '15m', '30m', '1h'], defaultValue: '15m' };
      case '7d':
      case 'thisweek':
      case 'lastweek':
        return { items: ['15m', '30m', '1h', '1dy'], defaultValue: '1h' };
      case '30d':
      case 'thismonth':
      case 'lastmonth':
      case 'thisbimester':
      case 'lastbimester':
        return { items: ['1h', '1dy'], defaultValue: '1dy' };
      case 'thisthreemester':
      case 'lastthreemester':
        return { items: ['1h', '1dy', '1wk'], defaultValue: '1dy' };
      case 'thissemester':
      case 'lastsemester':
      case 'thisyear':
      case '365d':
      case 'lastyear':
      case 'lastyearlast':
      case 'from':
        return { items: ['1dy', '1wk', '1mo'], defaultValue: '1dy' };
      default:
        return { items: [] };
    }
  }

  // Generic aggregations for all the other widgets
  switch (interval) {
    case '5m':
      return { items: ['raw', '1m'], defaultValue: 'raw' };
    case '15m':
      return { items: ['raw', '1m'], defaultValue: 'raw' };
    case '1h':
      return { items: ['raw', '1m', '5m', '10m', '15m'], defaultValue: 'raw' };
    case '3h':
      return { items: ['raw', '1m', '5m', '10m', '15m'], defaultValue: '5m' };
    case '12h':
      return { items: ['5m', '10m', '15m', '30m', '1h'], defaultValue: '15m' };
    case '24h':
    case 'today':
    case 'yesterday':
    case 'yesterdayandtoday':
      return { items: ['5m', '10m', '15m', '30m', '1h'], defaultValue: '15m' };
    case '7d':
    case 'thisweek':
    case 'lastweek':
      return { items: ['15m', '30m', '1h', '1dy'], defaultValue: '1h' };
    case '30d':
    case 'thismonth':
      return { items: ['1h', '1dy'], defaultValue: '1dy' };
    case 'lastmonth':
      return { items: ['1dy', '1wk', '1mo'], defaultValue: '1mo' };
    case 'thisbimester':
      return { items: ['1wk'], defaultValue: '1wk' };
    case 'lastbimester':
    case 'thisthreemester':
    case 'lastthreemester':
    case 'thissemester':
    case 'lastsemester':
      return { items: ['1wk', '1mo'], defaultValue: '1mo' };
    case '365d':
    case 'thisyear':
      return { items: ['1mo'], defaultValue: '1mo' };
    case 'lastyear':
      return { items: ['1mo', '1yr'], defaultValue: '1mo' };
    case 'lastyearlast':
      return { items: ['1mo'], defaultValue: '1mo' };
    case 'from':
      return { items: ['1dy', '1wk', '1mo'], defaultValue: '1dy' };
    default:
      return { items: [] };
  }
};

export const getAggregationOptions = (widgetType, valueTypes, interval) => {
  let { items, defaultValue } = getAggregationsPerInterval(widgetType, valueTypes, interval);
  if (
    [widgetTypes.SERIES, widgetTypes.PIE].includes(widgetType) &&
    valueTypes.includes('cumulated')
  ) {
    items = items.filter(x => x !== 'raw');
    if (!items.includes(defaultValue)) {
      [defaultValue] = items;
    }
  }

  return { items: items.map(x => aggregationsList(x)), defaultValue };
};

export const getPeriodString = (widgetType, period, currentModule) => {
  const { interval, aggregation, aggregation_date } = period;
  const { items } = getIntervalOptions(widgetType, currentModule);
  const intervalString = (items.find(x => x.value === interval) || {}).label || '';
  if (
    typeof aggregation ===
    'string' /* && (interval === 'lastvalue' || widgetType === widgetTypes.SERIES || widgetType === widgetTypes.PIE)*/
  ) {
    if (interval === 'from' && aggregation_date) {
      return `${intervalString.replace('...', '')} ${moment(aggregation_date).format(
        'DD/MM/YYYY'
      )} (${aggregation.replace(/m$/, "'")})`;
    }
    return `${intervalString.replace('...', '')} (${aggregation.replace(/m$/, "'")})`;
  }
  return intervalString;
};

export const lastValueUpdated = (interval, aggregation, value) => {
  if (interval === 'lastvalue' && value && value.t) {
    const { t } = value;
    // const deltaT = moment().diff(moment(t, 'YYYY-MM-DDTHH:mm:ssZ'));
    const deltaT = moment().diff(moment(t).format('YYYY-MM-DDTHH:mm:ssZ'));
    const aggregationMs = 5 * getAggregationMs(aggregation);
    return deltaT > aggregationMs;
  }
};

export const getAggregationMs = aggregation => {
  let milliseconds = 0;
  switch (aggregation) {
    case 'raw':
    case '1m':
      milliseconds = 60 * 1000;
      break;
    case '5m':
      milliseconds = 5 * 60 * 1000;
      break;
    case '10m':
      milliseconds = 10 * 60 * 1000;
      break;
    case '15m':
      milliseconds = 15 * 60 * 1000;
      break;
    case '30m':
      milliseconds = 30 * 60 * 1000;
      break;
    case '1h':
      milliseconds = 60 * 60 * 1000;
      break;
    case '1dy':
      milliseconds = 24 * 60 * 60 * 1000;
      break;
    default:
      break;
  }
  return milliseconds;
};

export const rateGreaterThanAggregation = (rate, aggregation) => {
  const rateMs = getAggregationMs(rate);
  const aggMs = getAggregationMs(aggregation);
  return rateMs > aggMs ? rate : aggregation;
};

const ledStates = [t`Off`, t`On`, t`Spento`, t`Acceso`, t`Chiuso`, t`Aperto`];

export const getLedStates = () => ledStates.map(x => ({ label: x.id, _label: i18n._(x) }));

export const getType = _type => {
  let type = _type;
  switch (_type) {
    case 'domain':
      type = 'domains';
      break;
    case 'company':
      type = 'companies';
      break;
    case 'sitegroup':
      type = 'sitegroups';
      break;
    case 'site':
      type = 'sites';
      break;
    default:
      break;
  }
  return type;
};

export const getUrlFromDashboard = _dashboard => {
  const { domainId, siteId, companyId, siteGroupId, id } = _dashboard;
  let result;
  if (siteId) {
    result = `sites/${siteId}/dashboards/${id}`;
  } else if (siteGroupId) {
    result = `siteGroups/${siteGroupId}/dashboards/${id}`;
  } else if (companyId) {
    result = `companies/${companyId}/dashboards/${id}`;
  } else if (domainId) {
    result = `domains/${domainId}/dashboards/${id}`;
  }
  return result;
};

export const getPeriodValues = _granularity => {
  const items = [
    {
      value: 'values-period',
      label: i18n._(t`Valori ${_granularity} e statistiche su intero periodo`)
    },
    { value: 'values', label: i18n._(t`Solo valori ${_granularity}`) },
    { value: 'period', label: i18n._(t`Solo statistiche su intero periodo`) }
  ];
  const defaultValue = 'values-period';
  return { items, defaultValue };
};
