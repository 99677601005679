import { t } from '@lingui/macro';

const columnsList = [
    { id: '1', name: '1ª colonna (A)' },
    { id: '2', name: '2ª colonna (B)' },
    { id: '3', name: '3ª colonna (C)' },
    { id: '4', name: '4ª colonna (D)' },
    { id: '5', name: '5ª colonna (E)' },
    { id: '6', name: '6ª colonna (F)' },
    { id: '7', name: '7ª colonna (G)' },
    { id: '8', name: '8ª colonna (H)' },
    { id: '9', name: '9ª colonna (I)' },
    { id: '10', name: '10ª colonna (J)' },
    { id: '11', name: '11ª colonna (K)' },
    { id: '12', name: '12ª colonna (L)' },
    { id: '13', name: '13ª colonna (M)' },
    { id: '14', name: '14ª colonna (N)' },
    { id: '15', name: '15ª colonna (O)' },
    { id: '16', name: '16ª colonna (P)' },
    { id: '17', name: '17ª colonna (Q)' },
    { id: '18', name: '18ª colonna (R)' },
    { id: '19', name: '19ª colonna (S)' },
    { id: '20', name: '20ª colonna (T)' },
    { id: '21', name: '21ª colonna (U)' },
    { id: '22', name: '22ª colonna (V)' },
    { id: '23', name: '23ª colonna (W)' },
    { id: '24', name: '24ª colonna (X)' },
    { id: '25', name: '25ª colonna (Y)' },
    { id: '26', name: '26ª colonna (Z)' }
];

const granularityList = [
    { value: '15m', label: t`15 minuti`, hidden: true },
    { value: '1dy', label: t`1 giorno` },
    { value: 'raw', label: t`< 1 giorno` }
];

const valuesList = [
    { label: 'Istantanei', value: 'instant' },
    { label: 'Cumulativi', value: 'cumulated' },
    { label: 'Differenziali', value: 'delta' }
]

const scaleSuggestions = ['0.01', '0.1', '1', '10', '100'];

const columnsTypeList = [
    { id: 'separate', name: t`In colonne separate` },
    { id: 'same', name: t`Nella stessa colonna` },
    { id: 'noTime', name: t`Orario non presente` }
]

const delimitersList = [
    { value: ',', label: t`Virgola (,)` },
    { value: ';', label: t`Punto e virgola (;)` },
    { value: 'tab', label: t`Tabulazione` }
]

const dateFormatsList = [
    { id: '%d/%m/%Y', name: t`Giorno mese anno` },
    { id: '%m/%d/%Y', name: t`Mese giorno anno` },
    { id: '%Y/%m/%d', name: t`Anno mese giorno` }
]

const dataFormatsList = [
    { value: 'default', label: `Standard (1 riga per ciascun timestamp e tutti i valori della variabile nella stessa colonna)` },
    { value: 'enel', label: `Enel (1 riga per ciascuna data, con i rispettivi valori quart'orari disposti in 96 colonne)` }
]

export {
    columnsList, granularityList, scaleSuggestions, dateFormatsList,
    valuesList, columnsTypeList, delimitersList, dataFormatsList
};