import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getErrorMessages } from 'app/utils/validation';

const StepColors = {
  CURRENT: '#20a8d8',
  COMPLETE: '#0a0',
  TODO: '#aaa',
  ERROR: '#d00',
};

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 10rem;
  margin: 2px;
  cursor: ${props => (props.canGoToStep === true ? 'pointer' : 'default')};
`;

const StepTitle = styled.div`
  color: ${props => props.color};
`;

const StepLabel = styled.div`
  font-size: 0.8rem;
  color: ${props => props.color};
`;

const StepUnderline = styled.div`
  width: 10rem;
  height: 3px;
  margin-top: .5rem;
  background-color: ${props => `${props.color}`};
`;

const Steps = (props) => {
  const { steps, currentIndex, canGoToStep, goToStep, error } = props;
  const { fieldErrors } = getErrorMessages(error);
  return (
    <StepsContainer>
      {steps.filter(x => x.show !== false).map((step) => {
        const { index } = step;
        let color;
        if (currentIndex === index) {
          color = StepColors.CURRENT;
        } else if (step.validatedProperties && step.validatedProperties.some(field => fieldErrors[field])) {
          color = StepColors.ERROR;
        } else if (canGoToStep || index < currentIndex) {
          color = StepColors.COMPLETE;
        } else if (index > currentIndex) {
          color = StepColors.TODO;
        }

        return (
          <Step key={step.index} canGoToStep={canGoToStep} color={color} onClick={() => (canGoToStep ? goToStep(index) : null)}>
            <div>
              <StepTitle color={color}>
                <strong>
                  {step.title || `Step ${step.index + 1}`}
                </strong>
              </StepTitle>
              <StepLabel color={color}>
                {step.label}
              </StepLabel>
            </div>
            <StepUnderline color={color} />
          </Step>
        );
      })}
    </StepsContainer>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default Steps;
