/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const hexToRgbA = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity})`;
  }
};

const ActtionToken = styled(FontAwesomeIcon)`
  &&&& {
    cursor: pointer;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }
`;

const TokenWrapper = styled.div`
  padding: 0px 3px;
  margin:2px;
  display: inline-flex;
  min-height: 35px;
  color: ${props => props.textcolor};
  background: ${props => (props.disable === true ? '#A9A9A9' : (props.derivate === true ? `linear-gradient(90deg, ${hexToRgbA(props.background, 0.4)} 0, ${hexToRgbA(props.background, 1)} 100%)` : props.background))}; ;
  flex-direction: row;
  border-radius: 8px;
  border: ${props => (props.border ? `2px solid ${props.border}` : 'none')};
  user-select: none;
`;

const LabelWrapper = styled.div`
  line-height:12px;
  padding: 5px 3px;
  cursor: move;
  white-space: nowrap;
`;

const NewVarWrapper = styled.div`
  flex: 1;
  line-height:28px;
  padding: 0 3px;
`;

const Path = styled.div`
  font-size:10px;
`;

const Label = styled.div`
  font-size:13px;
`;

const LabelDate = styled.div`
  font-size:13px;
`;


const ButtonWrapper = styled.div`
  padding: 3px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.2;

  &.active {
    opacity: 1;
  }
`;

const DragHandle = SortableHandle(({ body }) => <span>{body}</span>);

class Token extends Component {
  state = {
    show: false,
  }

  mouseEnter = () => {
    this.setState({ show: true });
  }

  mouseLeave = () => {
    this.setState({ show: false });
  }

  onDeleteAction = (id) => {
    const { deleteItem } = this.props;
    deleteItem(id);
  }

  render() {
    const { icon, label, path, id, optionItem, newVarPlaceholder, startDate, endDate, disabled } = this.props;
    const { show } = this.state;

    return (
      <TokenWrapper {...this.props} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>

        { label && (
        <DragHandle body={(
          <LabelWrapper>
            <Path>{path}</Path>
            <Label>{label}</Label>
          </LabelWrapper>
          )}
        />
        )
        }

        { startDate && endDate && (
        <DragHandle body={(
          <LabelWrapper>
            <LabelDate>{startDate}</LabelDate>
            <LabelDate>{endDate}</LabelDate>
          </LabelWrapper>
          )}
        />
        )
        }

        { newVarPlaceholder && (
          <NewVarWrapper>
            <Label>{newVarPlaceholder}</Label>
          </NewVarWrapper>
        )}

        { this.onDeleteAction && !disabled && (
          <ButtonWrapper className={show ? 'active' : ''}>
            <ActtionToken icon="times" active="1" onClick={() => this.onDeleteAction(id)} />
            {optionItem && <ActtionToken icon={icon} active="1" onClick={() => optionItem(id)} />}
          </ButtonWrapper>
        )}

      </TokenWrapper>
    );
  }
}

Token.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
  optionItem: PropTypes.func,
  deleteItem: PropTypes.func,
  newVarPlaceholder: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  disabled: PropTypes.bool,
};

Token.defaultProps = {
  icon: null,
  label: null,
  path: null,
  id: null,
  optionItem: null,
  deleteItem: null,
  newVarPlaceholder: null,
  startDate: null,
  endDate: null,
  disabled: false,
};


export default Token;
