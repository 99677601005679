import React from 'react';

import FirstSelector from '../Form/FirstSelector';
import SecondSelector from '../Form/SecondSelector';
import VectorSelector from '../Form/VectorSelector';
import Finalize from '../Form/Finalize';

const SelectionDetails = ({ nodeDataArray, showAddNode, assetMouseEnter, assetMouseOut, onSelectFirst, firstSelectedItem, deleteFirstItem, onSelectSecond, secondSelectedItem, deleteSecondItem, vectorsList, onSelectVector, deleteVectorItem, vectorItem, undoAddNode, saveNode }) => {
  if (showAddNode) {
    if (firstSelectedItem == null && secondSelectedItem == null && vectorItem == null) {
      return (
        <FirstSelector
          nodeDataArray={nodeDataArray}
          assetMouseEnter={assetMouseEnter}
          assetMouseOut={assetMouseOut}
          onSelect={onSelectFirst}
          undoAddNode={undoAddNode}
        />
      );
    }
    if (secondSelectedItem === null && vectorItem === null) {
      return (
        <SecondSelector
          nodeDataArray={nodeDataArray}
          assetMouseEnter={assetMouseEnter}
          assetMouseOut={assetMouseOut}
          onSelect={onSelectSecond}
          firstItem={firstSelectedItem}
          deleteFirstItem={deleteFirstItem}
          undoAddNode={undoAddNode}
        />
      );
    }
    if (vectorItem === null) {
      return (
        <VectorSelector
          nodeDataArray={nodeDataArray}
          assetMouseEnter={assetMouseEnter}
          assetMouseOut={assetMouseOut}
          onSelect={onSelectVector}
          firstItem={firstSelectedItem}
          deleteFirstItem={deleteFirstItem}
          deleteSecondItem={deleteSecondItem}
          secondItem={secondSelectedItem}
          vectorsList={vectorsList}
          undoAddNode={undoAddNode}
        />
      );
    }

    return (
      <Finalize
        firstItem={firstSelectedItem}
        deleteFirstItem={deleteFirstItem}
        deleteSecondItem={deleteSecondItem}
        deleteVectorItem={deleteVectorItem}
        secondItem={secondSelectedItem}
        vectorItem={vectorItem}
        undoAddNode={undoAddNode}
        saveNode={saveNode}
      />
    );
  }
  return null;
};

export default SelectionDetails;
