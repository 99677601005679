import React, { useEffect, useState } from 'react';
import { DynamicForm, Spinner } from 'app/common';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import i18n from 'app/i18n';
import api from 'api';
import { distinctAndSort } from 'app/utils/array';
import { getUrlFromPath } from 'app/utils/navigation';
import PropTypes from 'prop-types';


const InsertGateway = (props) => {
  const { history } = props;

  const [supportedGatewayList, setSupportedGatewayList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [productCodeList, setProductCodeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateMakeList = () => {
    setMakeList(distinctAndSort(supportedGatewayList.map(x => ({ value: x.make, label: x.make }))));
  };

  const updateModelList = (maker) => {
    const newModelList = distinctAndSort(supportedGatewayList.filter(x => x.make === maker).map(x => ({ value: x.model, label: x.model })));
    setModelList(newModelList);
  };

  const updateProductCodeList = (maker, model) => {
    const newProductCodeList = distinctAndSort(supportedGatewayList.filter(x => x.make === maker && x.model === model).map(x => ({ value: x.productCode || '', label: x.productCode || '' })));
    setProductCodeList(newProductCodeList);
  };

  const loadSupportedGateways = async () => {
    const res = await api.get('/SupportedGateways');
    setSupportedGatewayList(res.data);
  };

  useEffect(() => {
    loadSupportedGateways();
  }, []);

  useEffect(() => {
    updateMakeList();
  }, [supportedGatewayList]);


  const getGatewayBySerial = async (serial) => {
    let gateway = [];
    try {
      gateway = await api.get(`/gateways?filter={"where":{"serialNumber":"${serial}"}}`);
    } catch (ex) {
      toast.error(ex.message);
    }
    return gateway;
  };

  const submitGateway = async (data) => {
    setLoading(true);
    const selectedItem = supportedGatewayList.find(
      gw => gw.make === data.make
        && gw.model === data.model
        && (!gw.productCode || gw.productCode === data.productCode),
    );
    const { id, created, updated, deleted, ...gateway2send } = selectedItem;
    gateway2send.serialNumber = data.serialNumber;
    try {
      await api.post('/Gateways', gateway2send);
      history.push(getUrlFromPath('/config/provisioning'));
      setLoading(false);
    } catch (ex) {
      toast.error(ex.message);
      setLoading(false);
    }
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <>
    { loading && <Spinner />}
    <DynamicForm
      card
      title={<Trans>Dati del gateway</Trans>}
      onSubmit={submitGateway}
      onCancel={cancel}
      fields={[
        {
          name: 'serialNumber',
          type: 'text',
          label: <Trans>Numero seriale</Trans>,
          validation: {
            required: true,
            func: async (serial) => {
              const { data: gatewaysWithSerial } = await getGatewayBySerial(serial);
              const gatewayAlreadyPresent = gatewaysWithSerial.length > 0;
              if (gatewayAlreadyPresent) {
                throw new Error(i18n._(t`Numero seriale già utilizzato`));
              }
              return true;
            },
          },
          validationOptions: { validateOnBlur: true },
        },
        {
          name: 'make',
          type: 'select',
          label: <Trans>Marca</Trans>,
          validation: { required: true },
          options: makeList,
          onChange: (value) => {
            updateModelList(value);
          },
        },
        {
          name: 'model',
          type: 'select',
          label: <Trans>Modello</Trans>,
          validation: { required: true },
          options: modelList,
          onChange: (value, values) => {
            updateProductCodeList(values.make, value);
          },
        },
        {
          name: 'productCode',
          type: 'select',
          label: <Trans>Codice prodotto</Trans>,
          validation: { required: false },
          options: productCodeList,
        },
      ]}
    />
    </>
  );
};

InsertGateway.propTypes = {
  history: PropTypes.object.isRequired,
};

export default InsertGateway;
