import {flowRight, head, isEqual, noop, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import React from 'react';

import highlightOnlyResultContainer from './highlightOnlyResultContainer';
import { caseSensitiveType, checkPropType, defaultInputValueType, highlightOnlyResultType, ignoreDiacriticsType, inputPropsType, labelKeyType, optionType } from '../propTypes/';
import { addCustomOption, defaultFilterBy, getDisplayName, getOptionLabel, getStringLabelKey, getTruncatedOptions, isShown, pluralize } from '../utils/';

import { DEFAULT_LABELKEY } from '../constants/defaultLabelKey';
import { DOWN, ESC, RETURN, RIGHT, LEFT, TAB, UP, CANC, BACKSPACE, CONTROL, COPY, PASTE, CUT } from '../constants/keyCode';

function genId(prefix = '') {
  return prefix + Math.random().toString(36).substr(2, 12);
}

function getInitialState(props) {
  const { defaultInputValue, defaultSelected, maxResults, multiple } = props;

  let selected = props.selected ?
    props.selected.slice() : defaultSelected.slice();

  let text = defaultInputValue;

  if (!multiple && selected.length) {
    // Set the text if an initial selection is passed in.
    text = getOptionLabel(head(selected), props.labelKey);

    if (selected.length > 1) {
      // Limit to 1 selection in single-select mode.
      selected = selected.slice(0, 1);
    }
  }

  return {
    activeIndex: -1,
    activeItem: null,
    initialItem: null,
    selected,
    showMenu: false,
    shownResults: maxResults,
    text,
    copyText: '',
    cursorIndex: -1,
    activeTokens: [],
    cntrlispressed: false,
  };
}

function typeaheadContainer(Typeahead) {
  // Nested HOCs to encapsulate behaviors. In order} from outer to inner.
  Typeahead = flowRight(
    highlightOnlyResultContainer,
  )(Typeahead);

  class WrappedTypeahead extends React.Component {
    constructor(props) {
      super(props);
      this.state = getInitialState(props);
    }

    getChildContext() {
      return {
        activeIndex: this.state.activeIndex,
        onActiveItemChange: this._handleActiveItemChange,
        onInitialItemChange: this._handleInitialItemChange,
        onMenuItemClick: this._handleMenuItemSelect,
      };
    }

    _handleKeyPress = (e) => {
      // Cattura il click sul tasto CTRL sull'intera maschera e non esclusivamente all'interno del campo Formula
      const { cntrlispressed } = this.state;
      if (e.keyCode === CONTROL && !cntrlispressed) {
        this.setState({ cntrlispressed: true });
      }
    }

    _handleKeyUp = (e) => {
      switch (e.keyCode) {
        case CONTROL:
          this.setState({ cntrlispressed: false });
          break;
        default:
          break;
      }
    }

    componentWillMount() {
      // Generate random id here since doing it in defaultProps will generate
      // the same id for every instance.
      this._menuId = genId('rbt-menu-');
    }

    componentDidMount() {
      this.props.autoFocus && this.focus();

      document.addEventListener('keydown', (e) => this._handleKeyPress(e));
      document.addEventListener('keyup', (e) => this._handleKeyUp(e));
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', (e) => this._handleKeyPress(e));
      document.removeEventListener('keyup', (e) => this._handleKeyUp(e));
    }

    componentWillReceiveProps(nextProps) {
      const { labelKey, multiple, selected, initialFunction, text, operands, action, getOperand, onUpdateText, onUpdateInitialFunction, onUpdateNumberToken } = nextProps;
      
      // È possibile inserire un numero massimo di 200 token
      if (this.state.selected && this.state.selected.length >= 200) {
        return;
      }
      if (action) {
        switch (action) {
          case 'copy':
            this._handleCopy();
            break;

            case 'paste':
            this._handlePaste();
            break;

          case 'mooveCursorLeft':
            this._mooveCursorLeft();
            break;

          case 'mooveCursorRight':
            this._mooveCursorRight();
            break;

          case 'clearLastToken':
            this._handleClearLastToken();
            break;

          case 'clearFunction':
            this._handleClear();
            break;

          case 'focus':
            this._setFocus();
            break;

          default:
            break;
        }

        return;
      }

      // Blocco che gestisce la props initialFunction,
      // utilizzata per inizializzare il componente con una formula preimpostata
      // (meccanismo utilizzato in fase di edit)
      if (initialFunction) {
        let initialFunctionString = initialFunction.expression;

        const tokens = [];
        let cursorIndex = 0;

        // Creazione dei token sulla base della regex
        let lastTokenNew = this.props.getLastToken(initialFunctionString);

        // Se il token è una variabile, recupero l'id e la label dalla lista degli Operandi
        const regexp = new RegExp(/\$\[op_\d*\]|\$\[vm_\d*\]/);
        let lastTokenObject = {};
        if (regexp.test(lastTokenNew)) {
          lastTokenObject = getOperand(lastTokenNew);
        } else {
          lastTokenObject.label = lastTokenNew;
        }
        while (lastTokenObject && lastTokenObject.label) {
          if (lastTokenObject.id != null) {
            initialFunctionString = initialFunctionString.substring(
              0,
              initialFunctionString.length -
                `$[${lastTokenObject.type}_${lastTokenObject.id}]`.length
            );
          } else {
            initialFunctionString = initialFunctionString.substring(
              0,
              initialFunctionString.length - lastTokenObject.label.length
            );
          }

          tokens.unshift({
            customOption: true,
            id: uniqueId('new-id-'),
            [getStringLabelKey(labelKey)]: lastTokenObject.label,
            text: lastTokenObject.id
              ? `$[${lastTokenObject.type}_${lastTokenObject.id}]`
              : lastTokenObject.label,
            type: lastTokenObject.type,
            variableId: lastTokenObject.id
          });

          cursorIndex = cursorIndex + 1;

          lastTokenNew = this.props.getLastToken(initialFunctionString);

          lastTokenObject = {};
          if (regexp.test(lastTokenNew)) {
            lastTokenObject = getOperand(lastTokenNew);
          } else {
            lastTokenObject.label = lastTokenNew;
          }
        }
        // remove expression if previous was not valid mostly because of dead OPs
        initialFunction.expression = lastTokenObject ? initialFunction.expression : '';
        this.setState({ selected: tokens, cursorIndex }, () => {
          onUpdateText(initialFunction.expression, initialFunction.operands);
          onUpdateInitialFunction();
          onUpdateNumberToken(tokens.length);
        });

        return;
      }


      // Blocco che gestisce la digitazione di ogni carattere all'interno del componente
      let lastText = this.state.selected.length > 0 ? this.state.selected.map(token => token.text).join('') : '';
      if (text && !isEqual(text, lastText)) {

        if (multiple) {
          let newCursorIndex = this.state.cursorIndex !== -1 ? this.state.cursorIndex : this.state.selected.length;
          let tokensPreFocus = this.state.selected.slice(0, newCursorIndex);
          let tokensPostFocus = this.state.selected.slice(newCursorIndex, this.state.selected.length);

          let lastChar = text.charAt(text.length-1);

          let textFuncPre = tokensPreFocus.length > 0 ? tokensPreFocus.map(token => token.text).join('') : '';
          let textFuncPost = tokensPostFocus.length > 0 ? tokensPostFocus.map(token => token.text).join('') : '';

          let textFunc = textFuncPre + (text.substring((textFuncPre+textFuncPost).length, text.length)) + textFuncPost;

          let lastToken = tokensPreFocus.length > 0 && tokensPreFocus[tokensPreFocus.length-1].text;

          // regex validazione numeri
          let regOnlyDigit =  /^\d$/;
          let regNumber = /^\d*\.?\d*$/; // Permette es. 55 - .55 - 55. - 55.55

          if((regOnlyDigit.test(lastChar) && regNumber.test(lastToken)) ||
              (isEqual(lastChar, '.') && (regNumber.test(lastToken) && !lastToken.includes('.')))
             ) {

            // last char is a number
            tokensPreFocus.length = tokensPreFocus.length-1;
            newCursorIndex = newCursorIndex - 1;
          }

          // Creazione dei token sulla base della regex
          let lastTokenNew = this.props.getLastToken(textFuncPre + (text.substring((textFuncPre+textFuncPost).length, text.length)));

          // Se il token è una variabile, recupero l'id e la label dalla lista degli Operandi
          const regexp = new RegExp(/\$\[op_\d*\]|\$\[vm_\d*\]/);
          let lastTokenObject = {};
          if (regexp.test(lastTokenNew)) {
            lastTokenObject = getOperand(lastTokenNew);
          } else {
            lastTokenObject.label = lastTokenNew;
          }
          console.log("lastTokenObject malato",lastTokenObject);

          if (lastTokenNew) {
            tokensPreFocus.push({
              customOption: true,
              id: uniqueId('new-id-'),
              [getStringLabelKey(labelKey)]: lastTokenObject.label,
              text: lastTokenObject.id ? `$[${lastTokenObject.type}_${lastTokenObject.id}]` : lastTokenObject.label,
              type: lastTokenObject.type,
              variableId: lastTokenObject.id,
            });

            newCursorIndex = newCursorIndex + 1;
          }

          let tokens = [...tokensPreFocus, ...tokensPostFocus];

          this._setFocus();

          this.setState({ selected: tokens, cursorIndex: newCursorIndex }, () => {
            onUpdateText(textFunc, operands);
            onUpdateNumberToken(tokens.length);
          });

          return;
        }
      }

      // If new selections are passed via props, treat as a controlled input.
      if (selected && !isEqual(selected, this.state.selected)) {
        this.setState({ selected });

        if (multiple) {
          return;
        }

        this.setState({
          text: selected.length ? getOptionLabel(head(selected), labelKey) : '',
        });
      }

      // Truncate selections when in single-select mode.
      let newSelected = selected || this.state.selected;
      if (!multiple && newSelected.length > 1) {
        newSelected = newSelected.slice(0, 1);
        this.setState({
          selected: newSelected,
          text: getOptionLabel(head(newSelected), labelKey),
        });
        return;
      }

      if (multiple !== this.props.multiple) {
        this.setState({text: ''});
      }
    }

    render() {
      const mergedPropsAndState = {...this.props, ...this.state};

      const {
        filterBy,
        labelKey,
        minLength,
        options,
        paginate,
        paginationText,
        shownResults,
        text,
        cursorIndex,
        activeTokens,
        cntrlispressed,
        inputRef,
      } = mergedPropsAndState;

      let results = [];
      if (text.length >= minLength) {
        const cb = Array.isArray(filterBy) ? defaultFilterBy : filterBy;
        results = options.filter((option) => (
          cb(option, mergedPropsAndState)
        ));
      }

      // This must come before results are truncated.
      const shouldPaginate = paginate && results.length > shownResults;

      // Truncate results if necessary.
      results = getTruncatedOptions(results, shownResults);

      let regNumber = /^\d*\.?\d*$/; // Permette es. 55 - .55 - 55. - 55.55
      // Add the custom option if necessary.
      if (addCustomOption(results, mergedPropsAndState)) {
        if (regNumber.test(text)) {
          results.unshift({
            customOption: true,
            id: uniqueId('new-id-'),
            [getStringLabelKey(labelKey)]: text,
          });
        }
      }

      // Add the pagination item if necessary.
      if (shouldPaginate) {
        results.push({
          [getStringLabelKey(labelKey)]: paginationText,
          paginationOption: true,
        });
      }

      // This must come after checks for the custom option and pagination.
      const isMenuShown = isShown(results, mergedPropsAndState);

      return (
        <Typeahead
          {...mergedPropsAndState}
          inputRef={(input) => {
            this._input = input;
            inputRef(input);
          }}
          isMenuShown={isMenuShown}
          menuId={this.props.menuId || this._menuId}
          onAdd={this._handleSelectionAdd}
          onChange={this._handleInputChange}
          onClear={this._handleClear}
          //onFocus={this._handleFocus}
          onInitialItemChange={this._handleInitialItemChange}
          onKeyDown={(e) => this._handleKeyDown(e, results, isMenuShown)}
          onRemove={this._handleSelectionRemove}
          onCopy={(e) => this._handleCopy(e)}
          onPaste={(e) => this._handlePaste(e)}
          onCursorIndexChange={(newIndex) => this._handleCursorIndexChange(newIndex)}
          onActiveTokensChange={(activeTokens) => this.setState({ activeTokens })}
          activeTokens={activeTokens}
          results={results}
          cntrlispressed={cntrlispressed}
          cursorIndex={cursorIndex}
        />
      );
    }

    blur = () => {
      this.getInput().blur();
      this._hideMenu();
    }

    clear = () => {
      this.setState({
        ...getInitialState(this.props),
        selected: [],
        text: '',
        cursorIndex: -1
      });
    }

    focus = () => {
      this.getInput().focus();
    }

    getInput = () => {
      return this._input;
    }

    _handleCursorIndexChange = (cursorIndex) => {
      // console.log('_handleCursorIndexChange', cursorIndex);
      this.setState({ cursorIndex });
    }

    _handleActiveIndexChange = (activeIndex) => {
      const newState = { activeIndex };

      if (activeIndex === -1) {
        // Reset the active item if there is no active index.
        newState.activeItem = null;
      }

      this.setState(newState);
    }

    _handleActiveItemChange = (activeItem) => {
      this.setState({activeItem});
    }

    _handleClear = () => {
      this.clear();
      this._setFocus();
      this._updateSelected([]);
    }

    _handleClearLastToken = () => {
      const { selected, cursorIndex } = this.state;

      let newCursorIndex = cursorIndex;
      const children = this._input.parentElement.parentElement.parentElement.children;
      const firstActive = Array.from(children).findIndex(ele => ele.className.includes('rbt-token-active'));
      if (firstActive !== -1) {
        // Ci sono tokens selezionati - cancello i tokens selezionati
        let selectedTokens = Array.from(children).filter(element => element.className.includes('rbt-token')).map((element, index) => {
          if (element.className.includes('rbt-token-active')) {
            // Calcolo nuovo cursorIndex
            if (index < cursorIndex) {
              newCursorIndex = newCursorIndex-1;
            }
            return index;
          }
          return null;
        });
        if (selected && selected.length > 0) {
          // Tokens da cancellare
          const tokens = selected.filter((e, idx) => {
              return !selectedTokens.includes(idx);
          });

          this._setFocus();

          this.setState({ text: '', cursorIndex: newCursorIndex, activeTokens: [] }, this._updateSelected(tokens));
        }
      } else {
        // Non ci sono tokens selezionati - Cancello il token alla sinistra del cursore
        let tokens = this.state.selected;
        let cursorIndex = this.state.cursorIndex;

        if(tokens && tokens.length > 0 && cursorIndex > 0) {

          tokens = tokens.filter((e, idx) => idx !== cursorIndex-1);

          this._setFocus();

          this.setState({text: '', cursorIndex: cursorIndex-1}, this._updateSelected(tokens));
        }
      }
    }

    _handleFocus = (e) => {
      this.setState({ showMenu: true }, () => this.props.onFocus(e));
    }

    _handleInitialItemChange = (initialItem) => {
      const {labelKey} = this.props;
      const currentItem = this.state.initialItem;

      // Don't update the initial item if it hasn't changed. For custom items,
      // compare the 'labelKey' values since a unique id is generated each time,
      // causing the comparison to always return false otherwise.
      if (
        isEqual(initialItem, currentItem) ||
        (
          currentItem &&
          initialItem &&
          initialItem.customOption &&
          initialItem[labelKey] === currentItem[labelKey]
        )
      ) {
        return;
      }

      this.setState({initialItem});
    }

    _handleInputChange = (e) => {
      e.persist();

      const text = e.target.value;
      const {
        activeIndex,
        activeItem,
        shownResults,
      } = getInitialState(this.props);
      const {multiple, onInputChange} = this.props;

      this.setState({
        activeIndex,
        activeItem,
        showMenu: true,
        shownResults,
        text,
      }, () => onInputChange(text, e));

      // Clear any selections if text is entered in single-select mode.
      if (this.state.selected.length && !multiple) {
        this._updateSelected([]);
      }
    }

    _handleKeyDown = (e, results, isMenuShown) => {
      const { activeItem, text } = this.state;
      switch (e.keyCode) {
        case UP:
        case DOWN:
          if (!isMenuShown) {
            this._showMenu();
            break;
          }

          let {activeIndex} = this.state;

          // Prevents input cursor} from going to the beginning when pressing up.
          e.preventDefault();

          // Increment or decrement index based on user keystroke.
          activeIndex += e.keyCode === UP ? -1 : 1;

          // Skip over any disabled options.
          while (results[activeIndex] && results[activeIndex].disabled) {
            activeIndex += e.keyCode === UP ? -1 : 1;
          }

          // If we've reached the end, go back to the beginning or vice-versa.
          if (activeIndex === results.length) {
            activeIndex = -1;
          } else if (activeIndex === -2) {
            activeIndex = results.length - 1;
          }

          this._handleActiveIndexChange(activeIndex);
          break;
        case ESC:
          isMenuShown && this._hideMenu();
          break;
        case RETURN:
          if (!isMenuShown) {
            break;
          }

          // Prevent form submission while menu is open.
          e.preventDefault();
          activeItem && this._handleMenuItemSelect(activeItem, e);
          break;
        case RIGHT:
          this._mooveCursorRight();
          break;
        case LEFT:
          this._mooveCursorLeft();
          break;
        case TAB:
          e.preventDefault();
          this._mooveCursorRight();
          break;
        case CANC:
          this._handleClear();
          break;
        case BACKSPACE:
          if (!text) {
            this._handleClearLastToken();
          }
          break;
        case CONTROL:
          // Il keydown del tasto ctrl è intercettato sul document
          // if (!cntrlispressed) {
          //   this.setState({ cntrlispressed: true });
          // }
          break;
        case COPY: // Tasto c
          if (this.state.cntrlispressed) {
            e.preventDefault(true);
            this._handleCopy();
          }
          break;
        case PASTE: // Tasto v
          if (this.state.cntrlispressed) {
            e.preventDefault(true);
            this._handlePaste();
          }
          break;
        case CUT: // Tasto x
          if (this.state.cntrlispressed) {
            e.preventDefault(true);
            this._handleCut();
          }
          break;
        default:
          break;
      }

      this.props.onKeyDown(e);
    }

    // _handleKeyUp = (e) => {
    //   switch (e.keyCode) {
    //     case CONTROL:
    //       this.setState({ cntrlispressed: false });
    //       break;
    //   }
    // }

    _handleMenuItemSelect = (option, e) => {
      if (option.paginationOption) {
        this._handlePaginate(e);
      } else {
        this._handleSelectionAdd(option);
      }
    }

    _handlePaginate = (e) => {
      e.persist();
      const {maxResults, onPaginate} = this.props;
      const shownResults = this.state.shownResults + maxResults;

      this.setState({ shownResults }, () => onPaginate(e, shownResults));
    }

    _handleSelectionAdd = (selection) => {
      const { multiple, labelKey } = this.props;

      let selected;
      let text;

      if (multiple) {

        let cursorIndex = this.state.cursorIndex !== -1 ? this.state.cursorIndex : this.state.selected.length;

        let tokensPreFocus = this.state.selected.slice(0, cursorIndex);
        let tokensPostFocus = this.state.selected.slice(cursorIndex, this.state.selected.length);

        // If multiple selections are allowed, add the new selection to the
        // existing selections.
        //selected = this.state.selected.concat(selection);

        let lastChar = typeof(selection) === 'object' ? selection.name.charAt(selection.name.length-1) : selection.charAt(selection.length-1);
        let lastToken = tokensPreFocus.length > 0 && tokensPreFocus[tokensPreFocus.length-1].name;

        /**********/
        // regex validazione numeri
        let regOnlyDigit =  /^\d$/;
        let regNumber = /^\d*\.?\d*$/; // Permette es. 55 - .55 - 55. - 55.55

        if((regOnlyDigit.test(lastChar) && regNumber.test(lastToken)) ||
            (isEqual(lastChar, '.') && (regNumber.test(lastToken) && !lastToken.includes('.')))
            ) {

          // last char is a number
          tokensPreFocus.length = tokensPreFocus.length-1;
          cursorIndex = cursorIndex - 1;

          if(typeof(selection) === 'object') {
            selection.name = lastToken + selection.name;
          } else {
            selection = lastToken + selection;
          }
        }
        /***********/

        let tokenToAdd = null;
        if (typeof(selection) === 'object') {
          if (selection.variableId) {
            tokenToAdd = { ...selection, text: `$[${selection.type}_${selection.variableId}]` };
          } else {
            tokenToAdd = { ...selection, text: selection[getStringLabelKey(this.props.labelKey)] };
          }
        } else {
          tokenToAdd = {
            customOption: true,
            id: uniqueId('new-id-'),
            [getStringLabelKey(this.props.labelKey)]: selection,
            text: selection,
          };
        }

        selected = tokensPreFocus.concat(tokenToAdd).concat(tokensPostFocus);
        text = '';

        // Update cursor Index
        this.setState({cursorIndex: cursorIndex+1});
      } else {
        // If only a single selection is allowed, replace the existing selection
        // with the new one.
        selected = [selection];
        text = getOptionLabel(selection, labelKey);
      }

      this._hideMenu();
      this.setState({
        initialItem: selection,
        text,
      });

      // Text must be updated before the selection to fix #211.
      // TODO: Find a more robust way of solving the issue.
      this._updateSelected(selected);
    }

    _handleSelectionRemove = (selection) => {
      const selected = this.state.selected.filter((option) => (
        !isEqual(option, selection)
      ));

      // Update cursor Index
      let cursorIndex = this.state.cursorIndex;
      let selectedIndex = this.state.selected.findIndex((option) => isEqual(option, selection) );
      if( cursorIndex !== 0 && cursorIndex !== selectedIndex ) {
    	  cursorIndex = cursorIndex -1;
    	  this.setState({cursorIndex});
      }

      // Make sure the input stays focused after the item is removed.
      this.focus();
      this._hideMenu();
      this._updateSelected(selected);
    }

    /**
     *} from 'onClickOutside' HOC.
     */
    handleClickOutside = (e) => {
      this.state.showMenu && this._hideMenu();
    }

    _hideMenu = () => {
      const {
        activeIndex,
        activeItem,
        showMenu,
        shownResults,
      } = getInitialState(this.props);

      this.setState({
        activeIndex,
        activeItem,
        showMenu,
        shownResults,
      });
    }

    _showMenu = () => {
      this.setState({showMenu: true});
    }

    _updateSelected = (selected) => {
      const { onUpdateText, onUpdateNumberToken } = this.props;
      const operands = [];
      let text = selected.length > 0 ? selected.map(token => {
        if (token.variableId) {
          const variable = token;
          const operand = {};
          operand[`$[${variable.type}_${variable.variableId}]`] = { id: variable.variableId, type: variable.type, IoTUID: variable.IoTUID, unit: variable.unit } ;
          operands.push(operand);
          return variable.text;
        }
        return token.name;
      }).join("") : '';

      let numberToken = selected.length;
      // Update number of token and text
      this.setState({ selected }, () => {
        onUpdateText(text, operands);
        onUpdateNumberToken(numberToken);
        this.props.onChange(selected);
      });
    }

    _handleCut = (e) => {
      const { selected, cursorIndex } = this.state;
      let newCursorIndex = cursorIndex;
      const children = this._input.parentElement.parentElement.parentElement.children;
      let selectedTokens = Array.from(children).filter(element => element.className.includes('rbt-token')).map((element, index) => {
        if (element.className.includes('rbt-token-active')) {
          // Calcolo nuovo cursorIndex
          if (index < cursorIndex) {
            newCursorIndex = newCursorIndex-1;
          }
          return index;
        }
        return null;
      });

      if (selected && selected.length > 0) {
        // Tokens da copiare
        const tokensToCopy = selected.filter((e, idx) => selectedTokens.includes(idx));

        // Tokens da cancellare
        const tokens = selected.filter((e, idx) => !selectedTokens.includes(idx));

        const copyText = tokensToCopy.length > 0 ? tokensToCopy.map(token => token.text).join('') : '';

        this._setFocus();

        this.setState({ copyText, text: '', cursorIndex: newCursorIndex, activeTokens: [] }, this._updateSelected(tokens));
      }
    }

    _handleCopy = (e) => {
      const { selected } = this.state;

      const children = this._input.parentElement.parentElement.parentElement.children;

      let tokens = Array.from(children).filter(element => {
        return element.className.includes('rbt-token');
      });

      let selectedTokens = [];
      tokens.forEach((element, index) => {
        if (element.className.includes('rbt-token-active')) {
          selectedTokens.push(index);
        }
      });

      let copyText = selectedTokens.length > 0 ? selected.filter((token, index) => selectedTokens.includes(index)).map(token => token.text).join("") : '';

      this._setFocus();

      this.setState({ copyText, activeTokens: [] });
    }

    _handlePaste = (e) => {
      const { labelKey, onUpdateText, getOperand } = this.props;

      let { copyText, selected, cursorIndex } = this.state;

      let tokensToPaste = [];

      // Creazione dei token sulla base della regex
      let lastTokenNew = this.props.getLastToken(copyText);

      const regexp = new RegExp(/\$\[op_\d*\]|\$\[vm_\d*\]/);
      let lastTokenObject = {};
      if (regexp.test(lastTokenNew)) {
        lastTokenObject = getOperand(lastTokenNew);
      } else {
        lastTokenObject.label = lastTokenNew;
      }

      while (lastTokenObject.label) {
        tokensToPaste.push({
          customOption: true,
          id: uniqueId('new-id-'),
          [getStringLabelKey(labelKey)]: lastTokenObject.label,
          text: lastTokenObject.id ? `$[${lastTokenObject.type}_${lastTokenObject.id}]` : lastTokenObject.label,
          type: lastTokenObject.type,
          variableId: lastTokenObject.id,
        });

        copyText = copyText.substring(0, copyText.length-lastTokenNew.length);
        lastTokenNew = this.props.getLastToken(copyText);

        lastTokenObject = {};
        if (regexp.test(lastTokenNew)) {
          lastTokenObject = getOperand(lastTokenNew);
        } else {
          lastTokenObject.label = lastTokenNew;
        }
      }

      let tokensPreFocus = this.state.selected.slice(0, cursorIndex);
      let tokensPostFocus = this.state.selected.slice(cursorIndex, this.state.selected.length);

      // Update cursorIndex
      cursorIndex = cursorIndex+tokensToPaste.length;

      selected = tokensPreFocus.concat(tokensToPaste.reverse()).concat(tokensPostFocus);

      // Deselezione dei token selezionati
      let wrapper = this._input.parentElement.parentElement.parentElement;
      let children = wrapper.children;

      Array.from(children).forEach(element => {
        element.classList.remove('rbt-token-active');
      });

      // let text = selected.length > 0 ? selected.map(token => token.name).join("") : '';

      let newOperands = [];
      let text = selected.length > 0 ? selected.map(token => {
        if (token.variableId) {
          const variable = token;
          const operand = {};
          operand[`$[${variable.type}_${variable.variableId}]`] = { id: variable.variableId, type: variable.type, IoTUID: variable.IoTUID, unit: variable.unit } ;
          newOperands.push(operand);
          return variable.text;
        }
        return token.name;
      }).join("") : '';

      this._setFocus();

      this.setState({ selected, cursorIndex }, () => {
        onUpdateText(text, newOperands);
        this.props.onChange(selected);
      });
    }

    _mooveCursorLeft = () => {
      const cursorIndex = this.state.cursorIndex-1 !== -1 ? this.state.cursorIndex-1 : this.state.selected.length;
      this._setFocus();
      this.setState({ cursorIndex });
    }

    _mooveCursorRight = () => {
      const cursorIndex = this.state.cursorIndex+1 <= this.state.selected.length ? this.state.cursorIndex+1 : 0;
      this._setFocus();
      this.setState({ cursorIndex });
    }

    _setFocus = () => {
      this._input.focus();
    }
  }

  WrappedTypeahead.displayName =
    `TypeaheadContainer(${getDisplayName(Typeahead)})`;

  WrappedTypeahead.propTypes = {
    /**
     * For localized accessibility: Should return a string indicating the number
     * of results for screen readers. Receives the current results.
     */
    a11yNumResults: PropTypes.func,
    /**
     * For localized accessibility: Should return a string indicating the number
     * of selections for screen readers. Receives the current selections.
     */
    a11yNumSelected: PropTypes.func,
    /**
     * Specify menu alignment. The default value is 'justify', which makes the
     * menu as wide as the input and truncates long values. Specifying 'left'
     * or 'right' will align the menu to that side and the width will be
     * determined by the length of menu item values.
     */
    align: PropTypes.oneOf(['justify', 'left', 'right']),
    /**
     * Allows the creation of new selections on the fly. Note that any new items
     * will be added to the list of selections, but not the list of original
     * options unless handled as such by 'Typeahead''s parent.
     *
     * If a function is specified, it will be used to determine whether a custom
     * option should be included. The return value should be true or false.
     */
    allowNew: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    /**
     * Autofocus the input when the component initially mounts.
     */
    autoFocus: PropTypes.bool,
    /**
     * Whether to render the menu inline or attach to 'document.body'.
     */
    bodyContainer: PropTypes.bool,
    /**
     * Whether or not filtering should be case-sensitive.
     */
    caseSensitive: checkPropType(PropTypes.bool, caseSensitiveType),
    /**
     * Displays a button to clear the input when there are selections.
     */
    clearButton: PropTypes.bool,
    /**
     * The initial value displayed in the text input.
     */
    defaultInputValue: checkPropType(PropTypes.string, defaultInputValueType),
    /**
     * Specify any pre-selected options. Use only if you want the component to
     * be uncontrolled.
     */
    defaultSelected: optionType,
    /**
     * Whether to disable the component.
     */
    disabled: PropTypes.bool,
    /**
     * Specify whether the menu should appear above the input.
     */
    dropup: PropTypes.bool,
    /**
     * Message to display in the menu if there are no valid results.
     */
    emptyLabel: PropTypes.node,
    /**
     * Either an array of fields in 'option' to search, or a custom filtering
     * callback.
     */
    filterBy: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string.isRequired),
      PropTypes.func,
    ]),
    /**
     * Whether or not to automatically adjust the position of the menu when it
     * reaches the viewport boundaries.
     */
    flip: PropTypes.bool,
    /**
     * Highlights the menu item if there is only one result and allows selecting
     * that item by hitting enter. Does not work with 'allowNew'.
     */
    highlightOnlyResult: checkPropType(PropTypes.bool, highlightOnlyResultType),
    /**
     * Whether the filter should ignore accents and other diacritical marks.
     */
    ignoreDiacritics: checkPropType(PropTypes.bool, ignoreDiacriticsType),
    /**
     * Props to be applied directly to the input. 'onBlur', 'onChange',
     * 'onFocus', and 'onKeyDown' are ignored.
     */
    inputProps: checkPropType(PropTypes.object, inputPropsType),
    /**
     * Bootstrap 4 only. Adds the 'is-invalid' classname to the 'form-control'.
     */
    isInvalid: PropTypes.bool,
    /**
     * Indicate whether an asynchronous data fetch is happening.
     */
    isLoading: PropTypes.bool,
    /**
     * Bootstrap 4 only. Adds the 'is-valid' classname to the 'form-control'.
     */
    isValid: PropTypes.bool,
    /**
     * Specify the option key to use for display or a function returning the
     * display string. By default, the selector will use the 'label' key.
     */
    labelKey: checkPropType(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      labelKeyType,
    ),
    /**
     * Maximum number of results to display by default. Mostly done for
     * performance reasons so as not to render too many DOM nodes in the case of
     * large data sets.
     */
    maxResults: PropTypes.number,
    /**
     * Id applied to the top-level menu element. Required for accessibility.
     */
    menuId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    /**
     * Number of input characters that must be entered before showing results.
     */
    minLength: PropTypes.number,
    /**
     * Whether or not multiple selections are allowed.
     */
    multiple: PropTypes.bool,
    /**
     * Invoked when the input is blurred. Receives an event.
     */
    onBlur: PropTypes.func,
    /**
     * Invoked whenever items are added or removed. Receives an array of the
     * selected options.
     */
    onChange: PropTypes.func,
    /**
     * Invoked when the input is focused. Receives an event.
     */
    onFocus: PropTypes.func,
    /**
     * Invoked when the input value changes. Receives the string value of the
     * input.
     */
    onInputChange: PropTypes.func,
    /**
     * Invoked when a key is pressed. Receives an event.
     */
    onKeyDown: PropTypes.func,
    /**
     * Invoked when the menu is hidden.
     */
    onMenuHide: PropTypes.func,
    /**
     * Invoked when the menu is shown.
     */
    onMenuShow: PropTypes.func,
    /**
     * Invoked when the pagination menu item is clicked. Receives an event.
     */
    onPaginate: PropTypes.func,
    /**
     * Full set of options, including pre-selected options. Must either be an
     * array of objects (recommended) or strings.
     */
    options: optionType.isRequired,
    /**
     * Give user the ability to display additional results if the number of
     * results exceeds 'maxResults'.
     */
    paginate: PropTypes.bool,
    /**
     * Prompt displayed when large data sets are paginated.
     */
    paginationText: PropTypes.string,
    /**
     * Placeholder text for the input.
     */
    placeholder: PropTypes.string,
    /**
     * Callback for custom menu rendering.
     */
    renderMenu: PropTypes.func,
    /**
     * The selected option(s) displayed in the input. Use this prop if you want
     * to control the component via its parent.
     */
    selected: optionType,
    /**
     * Allows selecting the hinted result by pressing enter.
     */
    selectHintOnEnter: PropTypes.bool,
  };

  WrappedTypeahead.defaultProps = {
    a11yNumResults: (results) => {
      const resultString = pluralize('result', results.length);
      return `${resultString}. Use up and down arrow keys to navigate.`;
    },
    a11yNumSelected: (selected) => {
      return pluralize('selection', selected.length);
    },
    align: 'justify',
    allowNew: false,
    autoFocus: false,
    bodyContainer: false,
    caseSensitive: false,
    clearButton: false,
    defaultInputValue: '',
    defaultSelected: [],
    disabled: false,
    dropup: false,
    emptyLabel: 'No matches found.',
    filterBy: [],
    flip: false,
    highlightOnlyResult: false,
    ignoreDiacritics: true,
    inputProps: {},
    isInvalid: false,
    isLoading: false,
    isValid: false,
    labelKey: DEFAULT_LABELKEY,
    maxResults: 100,
    minLength: 0,
    multiple: false,
    onBlur: noop,
    onChange: noop,
    onFocus: noop,
    onInputChange: noop,
    onKeyDown: noop,
    onMenuHide: noop,
    onMenuShow: noop,
    onPaginate: noop,
    paginate: true,
    paginationText: 'Display additional results...',
    placeholder: '',
    selectHintOnEnter: false,
  };

  WrappedTypeahead.childContextTypes = {
    activeIndex: PropTypes.number.isRequired,
    onActiveItemChange: PropTypes.func.isRequired,
    onInitialItemChange: PropTypes.func.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
  };

  return onClickOutside(WrappedTypeahead);
}

export default typeaheadContainer;
