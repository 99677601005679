/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unreachable */
/* eslint-disable brace-style */
/* eslint-disable max-len */
import moment from "moment";
import "moment/locale/it";
import CarpetSvg from "images/chart_icons/Carpet.svg";
import CategoricalMultiperiodoSvg from "images/chart_icons/CategoricalMultiperiodo.svg";
import CategoricalMultivariabileSvg from "images/chart_icons/CategoricalMultivariabile.svg";
import CurvaDiCaricoSvg from "images/chart_icons/CurvaDiCarico.svg";
import CusumSvg from "images/chart_icons/Cusum.svg";
import DistribuzioneSvg from "images/chart_icons/Distribuzione.svg";
import ScatterSvg from "images/chart_icons/Scatter.svg";
import TimeComparisonSvg from "images/chart_icons/TimeComparison.svg";
import TimeProfileSvg from "images/chart_icons/TimeProfile.svg";

import i18n from "app/i18n";
import { t } from "@lingui/macro";
import { getUnitByPhysicalQuantity } from "../tools/virtualmeters/commonUtils";

export const OP_MAX_SELECTION = 14;
export const OP_TYPE_INSTANT = "instant";
export const OP_TYPE_CUMULATED = "cumulated";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_REST_SERVER = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";
export const DATE_CHART_TIMESERIES = "YYYY-MM-DD[T]HH:mm:ss";
export const DATE_CHART_BASE = "YYYY-MM-DD[T]00:00:00";


export const rebuildDates = (
  startDate,
  endDate,
  rolling,
  rollingStartDate,
  rollingEndDate,
  rollingDiff,
  rollingShift,
  tab
) => {

  /* nel coso di un periodo di riferimento in roling bisogna definire la data di start ed end */
  let refStartDate = startDate;
  let refEndDate = endDate;
  if (rolling) {
    if (rollingStartDate)
      refStartDate = moment().subtract(rollingDiff + rollingShift, "days").format(DATE_FORMAT);
    refEndDate = moment().subtract(rollingShift, "days").format(DATE_FORMAT);
  }

  const a = moment(refEndDate, DATE_FORMAT);
  const b = moment(refStartDate, DATE_FORMAT);
  const days = a.diff(b, "days");

  tab.dateSelection.forEach((x, key) => {
    /* modifica solo le date secondarie */
    if (key > 0) {
      const momentEndDay = moment(x.startDate, DATE_FORMAT).add(days, "d");
      const addRollingShift = a.diff(momentEndDay, "days");
      x.endDate = momentEndDay.format(DATE_FORMAT);
      x.rolling = rolling;
      x.rollingStartDate = rollingStartDate;
      x.rollingEndDate = rollingEndDate;
      x.rollingDiff = rollingDiff;
      x.rollingShift = rollingShift + addRollingShift; // ricalcola lo shift dalla data corrente dell'utente
    }
  });
};

export const getSeriesType = () => [
  {
    id: "1",
    label: i18n._(t`Linea`),
    type: "scatter",
    mode: "lines",
    shape: "linear",
    fill: "none",
    stacked: false,
    priority: 2
  },
  {
    id: "2",
    label: i18n._(t`Linea smussata`),
    type: "scatter",
    mode: "lines",
    shape: "spline",
    fill: "none",
    stacked: false,
    priority: 2
  },
  {
    id: "3",
    label: i18n._(t`Area`),
    type: "scatter",
    mode: "lines",
    shape: "linear",
    fill: "tonexty",
    stacked: false,
    priority: 1
  },
  {
    id: "4",
    label: i18n._(t`Area Impilata`),
    type: "scatter",
    mode: "lines",
    shape: "linear",
    fill: "tonexty",
    stacked: true,
    priority: 1
  },
  {
    id: "5",
    label: i18n._(t`Barra`),
    type: "bar",
    mode: "markers",
    shape: "linear",
    fill: "none",
    stacked: false,
    priority: 0
  },
  {
    id: "6",
    label: i18n._(t`Barra Impilata`),
    type: "bar",
    mode: "markers",
    shape: "linear",
    fill: "none",
    stacked: true,
    priority: 0
  }
];

export const CHART_TYPE_TIME_SERIES_VAR = "1";
export const CHART_TYPE_TIME_SERIES_TIME = "2";
export const CHART_TYPE_PIE_VAR = "3";
export const CHART_TYPE_PIE_TIME = "4";
export const CHART_TYPE_CUSUM = "5";
export const CHART_TYPE_DISTRBUZIONE = "6";
export const CHART_TYPE_CARPET = "7";
export const CHART_TYPE_SCATTER = "8";
export const CHART_TYPE_CURVA = "9";

export const getRateOptions = () => [
  {
    id: "none",
    dayRange: [null, null],
    dayPieRange: [null, null],
    label: "Intero periodo",
    VMSelect: false,
    chartAvaible: [CHART_TYPE_PIE_TIME]
  },
  {
    id: "raw",
    mins: 0,
    label: i18n._(t`Raw data`),
    seriesStep: 1,
    seriesType: "seconds",
    xFormat: "DD/MM/YYYY HH:mm:ss",
    dayRange: [0, 2],
    VMSelect: false,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1m",
    mins: 1,
    label: i18n._(t`${1} minuto`),
    seriesStep: 1,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 7],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "5m",
    mins: 5,
    label: i18n._(t`${5} minuti`),
    seriesStep: 5,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "10m",
    mins: 10,
    label: i18n._(t`${10} minuti`),
    seriesStep: 10,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "15m",
    mins: 15,
    label: i18n._(t`${15} minuti`),
    seriesStep: 15,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "30m",
    mins: 30,
    label: i18n._(t`${30} minuti`),
    seriesStep: 30,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1h",
    mins: 60,
    label: i18n._(t`${1} ora`),
    seriesStep: 1,
    seriesType: "hours",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 365],
    dayPieRange: [0, 1],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1dy",
    mins: 1440,
    label: i18n._(t`${1} giorno`),
    seriesStep: 1,
    seriesType: "days",
    xFormat: "DD/MM/YYYY",
    dayRange: [2, null],
    dayPieRange: [2, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1wk",
    mins: 10080,
    label: i18n._(t`${1} settimana`),
    seriesStep: 1,
    seriesType: "weeks",
    xFormat: "DD/MM/YYYY",
    dayRange: [8, null],
    dayPieRange: [8, 365],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1mo",
    mins: 312480,
    label: i18n._(t`${1} mese`),
    seriesStep: 1,
    seriesType: "months",
    xFormat: "MM/YYYY",
    dayRange: [32, null],
    dayPieRange: [32, null],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1yr",
    mins: 114055200,
    label: i18n._(t`${1} anno`),
    seriesStep: 1,
    seriesType: "years",
    xFormat: "YYYY",
    dayRange: [367, null],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  }
];

export const getRateOptionsVM = () => [
  {
    id: "none",
    dayRange: [null, null],
    dayPieRange: [null, null],
    label: "Intero periodo",
    VMSelect: false,
    chartAvaible: [CHART_TYPE_PIE_TIME]
  },
  {
    id: "raw",
    mins: 0,
    label: i18n._(t`Raw data`),
    seriesStep: 1,
    seriesType: "seconds",
    xFormat: "DD/MM/YYYY HH:mm:ss",
    dayRange: [0, 0],
    VMSelect: false,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1m",
    mins: 1,
    label: i18n._(t`${1} minuto`),
    seriesStep: 1,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 7],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "5m",
    mins: 5,
    label: i18n._(t`${5} minuti`),
    seriesStep: 5,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "10m",
    mins: 10,
    label: i18n._(t`${10} minuti`),
    seriesStep: 10,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "15m",
    mins: 15,
    label: i18n._(t`${15} minuti`),
    seriesStep: 15,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "30m",
    mins: 30,
    label: i18n._(t`${30} minuti`),
    seriesStep: 30,
    seriesType: "minutes",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1h",
    mins: 60,
    label: i18n._(t`${1} ora`),
    seriesStep: 1,
    seriesType: "hours",
    xFormat: "DD/MM/YYYY HH:mm",
    dayRange: [0, 365],
    dayPieRange: [0, 1],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  },
  {
    id: "1dy",
    mins: 1440,
    label: i18n._(t`${1} giorno`),
    seriesStep: 1,
    seriesType: "days",
    xFormat: "DD/MM/YYYY",
    dayRange: [2, null],
    dayPieRange: [2, 31],
    VMSelect: true,
    chartAvaible: [
      CHART_TYPE_PIE_VAR,
      CHART_TYPE_PIE_TIME,
      CHART_TYPE_TIME_SERIES_VAR,
      CHART_TYPE_TIME_SERIES_TIME,
      CHART_TYPE_CUSUM,
      CHART_TYPE_DISTRBUZIONE,
      CHART_TYPE_CARPET,
      CHART_TYPE_SCATTER,
      CHART_TYPE_CURVA
    ]
  }
  // { id: '1wk', mins: 10080, label: i18n._(t`${1} settimana`), seriesStep: 1, seriesType: 'weeks', xFormat: 'DD/MM/YYYY', dayRange: [8, null], dayPieRange: [8, 365], VMSelect: true, chartAvaible: [CHART_TYPE_PIE_VAR, CHART_TYPE_PIE_TIME, CHART_TYPE_TIME_SERIES_VAR, CHART_TYPE_TIME_SERIES_TIME, CHART_TYPE_CUSUM, CHART_TYPE_DISTRBUZIONE, CHART_TYPE_CARPET, CHART_TYPE_SCATTER, CHART_TYPE_CURVA] },
  // { id: '1mo', mins: 312480, label: i18n._(t`${1} mese`), seriesStep: 1, seriesType: 'months', xFormat: 'MM/YYYY', dayRange: [32, null], dayPieRange: [32, null], VMSelect: true, chartAvaible: [CHART_TYPE_PIE_VAR, CHART_TYPE_PIE_TIME, CHART_TYPE_TIME_SERIES_VAR, CHART_TYPE_TIME_SERIES_TIME, CHART_TYPE_CUSUM, CHART_TYPE_DISTRBUZIONE, CHART_TYPE_CARPET, CHART_TYPE_SCATTER, CHART_TYPE_CURVA] },
  // { id: '1yr', mins: 114055200, label: i18n._(t`${1} anno`), seriesStep: 1, seriesType: 'years', xFormat: 'YYYY', dayRange: [367, null], VMSelect: true, chartAvaible: [CHART_TYPE_PIE_VAR, CHART_TYPE_TIME_SERIES_VAR, CHART_TYPE_TIME_SERIES_TIME, CHART_TYPE_CUSUM, CHART_TYPE_DISTRBUZIONE, CHART_TYPE_CARPET, CHART_TYPE_SCATTER, CHART_TYPE_CURVA] },
];

export const getAggregazione = () => [
  { id: "sum", label: i18n._(t`Somma dei valori`) }, // default se viene scelta una grandezza cumulativa
  // { id: 'delta', label: i18n._(t`Differenza`) },
  { id: "average", label: i18n._(t`Media aritmetica dei valori`) },
  // { id: 'latest', label: i18n._(t`Ultimo valore`) },
  { id: 'max', label: i18n._(t`Valore massimo`) },
  { id: 'min', label: i18n._(t`Valore minimo`) },
  // { id: 'none', label: i18n._(t`Nessuna aggregazione`) },
];

export const getChartTypes = () => [
  {
    id: CHART_TYPE_TIME_SERIES_VAR,
    label: i18n._(t`Profilo temporale (più variabili)`),
    default: true,
    selectVariableMode: null,
    selectDateMode: 1,
    timeframeSelectable: true,
    icon: TimeProfileSvg
  },
  {
    id: CHART_TYPE_TIME_SERIES_TIME,
    label: i18n._(t`Profilo temporale (più periodi)`),
    selectVariableMode: 1,
    selectDateMode: null,
    timeframeSelectable: true,
    icon: TimeComparisonSvg
  },
  {
    id: CHART_TYPE_PIE_VAR,
    label: i18n._(t`Grafico a torta (più variabili)`),
    selectVariableMode: null,
    selectDateMode: 1,
    timeframeSelectable: false,
    icon: CategoricalMultivariabileSvg
  },
  {
    id: CHART_TYPE_PIE_TIME,
    label: i18n._(t`Grafico a torta (più periodi)`),
    selectVariableMode: 1,
    selectDateMode: null,
    timeframeSelectable: true,
    icon: CategoricalMultiperiodoSvg
  },
  {
    id: CHART_TYPE_CUSUM,
    label: i18n._(t`Diagramma Cusum`),
    selectVariableMode: 2,
    selectDateMode: 1,
    timeframeSelectable: true,
    icon: CusumSvg
  },
  {
    id: CHART_TYPE_DISTRBUZIONE,
    label: i18n._(t`Distribuzione valori`),
    selectVariableMode: null,
    selectDateMode: 1,
    timeframeSelectable: true,
    icon: DistribuzioneSvg
  },
  {
    id: CHART_TYPE_CARPET,
    label: i18n._(t`Grafico Carpet`),
    selectVariableMode: 1,
    selectDateMode: 1,
    timeframeSelectable: false,
    icon: CarpetSvg
  },
  {
    id: CHART_TYPE_SCATTER,
    label: i18n._(t`Grafico di dispersione`),
    selectVariableMode: 2,
    selectDateMode: 1,
    timeframeSelectable: true,
    icon: ScatterSvg
  },
  {
    id: CHART_TYPE_CURVA,
    label: i18n._(t`Curva di carico`),
    selectVariableMode: 1,
    selectDateMode: 1,
    timeframeSelectable: true,
    icon: CurvaDiCaricoSvg
  }
];

export const DECIMAL_SEPARATOR = (0.1).toLocaleString().replace(/\d/g, "");

export const excelFormatNumber = num =>
  num && num.toString().replace(".", DECIMAL_SEPARATOR);

export const roundMoment = (m, unit, offset, midpoint = "up") => {
  let unitNorm = moment.normalizeUnits(unit);
  if (unitNorm.toLowerCase() === "days") {
    unitNorm = "date";
  }

  const offsetNorm = offset || 1;
  let value = m.get(unitNorm);

  switch (midpoint) {
    case "up":
      value = Math.ceil(value / offsetNorm);
      break;
    case "down":
      value = Math.floor(value / offsetNorm);
      break;
    case "nearest":
    default:
      value = Math.round(value / offsetNorm);
      break;
  }

  m.set(unit, value * offsetNorm);

  switch (unitNorm) {
    case "years":
      m.month(0);
    /* falls through */
    case "months":
      m.date(1);
    /* falls through */
    case "date":
      m.hours(0);
    /* falls through */
    case "hours":
      m.minutes(0);
    /* falls through */
    case "minutes":
      m.seconds(0);
    /* falls through */
    default:
      m.milliseconds(0);
    /* falls through */
  }
};

export const roundFloat = (num) => {
  let stringNumber = parseInt(num).toString();
  if (stringNumber === '0') {
    return parseFloat(num.toPrecision(4));
  }
  const numLength = stringNumber.length;
  if (numLength < 4) {
    return parseFloat(num.toPrecision(5));
  }

  const size = 2;
  const exp = Math.pow(10, size);
  return Math.round(num * exp) / exp;
};

export const average = elements => {
  if (elements.length > 0) {
    const sum = elements.reduce((a, b) => a + b);
    return roundFloat(sum / elements.length, 3);
  }
  return null;
};

export const sum = elements => {
  if (elements.length > 0) {
    return roundFloat(
      elements.reduce((a, b) => a + b),
      3
    );
  }
  return null;
};

export const multiplicate = elements => elements.length > 0 ? roundFloat(elements.reduce((a, b) => a * b), 3) : null;

export const last = elements => {
  if (elements.length > 0) {
    return roundFloat(elements[elements.length - 1], 3);
  }
  return null;
};

export const max = (elements, valueType) => {
  let result = null;
  if (elements.length > 0 && (valueType === undefined || valueType === OP_TYPE_INSTANT)) {
    result = roundFloat(Math.max(...elements), 3);
  } else if (elements.length > 0 && valueType === OP_TYPE_CUMULATED) {
    result = roundFloat(Math.max(...elements) * elements.length, 3);
  }
  return result;
};

export const min = (elements, valueType) => {
  let result = null;
  if (elements.length > 0 && (valueType === undefined || valueType === OP_TYPE_INSTANT)) {
    result = roundFloat(Math.min(...elements), 3);
  } else if (elements.length > 0 && valueType === OP_TYPE_CUMULATED) {
    result = roundFloat(Math.min(...elements) * elements.length, 3);
  }
  return result;
};

export const smooth = (arr, periods) => {
  const result = [];
  const windowSize = parseInt(periods, 0);

  for (let i = 0; i < arr.length; i += 1) {
    const leftOffeset = i - windowSize;
    const from = leftOffeset >= 0 ? leftOffeset : 0;
    const to = i + windowSize + 1;

    let count = 0;
    let sum = 0;
    for (let j = from; j < to && j < arr.length; j += 1) {
      sum += arr[j].y;
      count += 1;
    }
    const y = roundFloat(sum / count, 3);
    result[i] = { x: arr[i].x, y, yScaled: y };
  }

  return result;
};

export const getDerivataType = () => [
  {
    id: 1,
    label: i18n._(t`Valori minimi`),
    formatNumber: true,
    needLowTF: true,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: min,
    postCb: elements => elements
  },
  {
    id: 2,
    label: i18n._(t`Valori massimi`),
    formatNumber: true,
    needLowTF: true,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: max,
    postCb: elements => elements
  },
  {
    id: 3,
    label: i18n._(t`Valori delta`),
    customYlabel: "Delta",
    formatNumber: true,
    needLowTF: false,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: last,
    postCb: elements => {
      if (elements.length >= 2) {
        return elements.map((el, i) => {
          const y =
            elements[i].y && elements[i - 1] && elements[i - 1].y
              ? elements[i].y - elements[i - 1].y
              : null;
          return { x: elements[i].x, y, yScaled: y };
        });
      }
      return elements;
    }
  },
  {
    id: 4,
    label: i18n._(t`Valori delta %`),
    customYlabel: "%",
    formatNumber: false,
    needLowTF: false,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: last,
    postCb: elements => {
      if (elements.length >= 2) {

        return elements.map((el, i) => {
          const y =
            elements[i].y && elements[i - 1] && elements[i - 1].y
              ? roundFloat(
                ((elements[i].y - elements[i - 1].y) / elements[i - 1].y) *
                100,
                3
              )
              : null;
          return { x: elements[i].x, y, yScaled: y };
        });
      }
      return elements;
    }
  },
  {
    id: 5,
    label: i18n._(t`Valori base 100`),
    customYlabel: "Base 100",
    formatNumber: false,
    needLowTF: false,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: last,
    postCb: elements => {
      if (elements.length >= 2) {
        /* determino il primo valore della serie per calcolare il delta % dei successivi */
        const firstElement = elements.filter(x => x.y !== null).map(x => x.y);
        if (firstElement.length === 0) return elements;
        return elements
          .filter(x => x.y !== null)
          .map(el => {
            const y =
              el.y !== null
                ? roundFloat(
                  ((el.y - firstElement[0]) / firstElement[0]) * 100,
                  3
                )
                : -100;
            return { x: el.x, y, yScaled: y };
          });
      }
      return elements;
    }
  },
  {
    id: 6,
    label: i18n._(t`Valori Cumulati`),
    customYlabel: "Cumulati",
    formatNumber: true,
    needLowTF: false,
    maxSerie: 1,
    maxSerieB: 1,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: last,
    postCb: elements => {
      if (elements.length >= 2) {
        for (let i = 0; i < elements.length; i += 1) {
          elements[i].y =
            (elements[i].y !== null ? elements[i].y : 0) +
            (elements[i - 1] !== undefined && elements[i - 1].y !== null
              ? elements[i - 1].y
              : 0);
        }
        return elements.map(el => ({ x: el.x, y: el.y, yScaled: el.y }));
      }
      return elements;
    }
  },
  {
    id: 7,
    label: i18n._(t`Media dei valori`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: 2,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: average,
    postCb: elements => elements
  },
  {
    id: 8,
    label: i18n._(t`Somma dei valori`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: null,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: sum,
    postCb: elements => elements
  },
  {
    id: 15,
    label: i18n._(t`Somma algebrica dei valori`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: null,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: sum,
    postCb: elements => elements
  },
  {
    id: 9,
    label: i18n._(t`Differenza [A-B]`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: 1,
    explodeSerie: true,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: last,
    postCb: (elementsRiferimento, elementsComparati) =>
      elementsComparati.map(vComp => {
        const vRif = elementsRiferimento.find(det => det.x === vComp.x) || 0;
        const y = vComp.yScaled - vRif.yScaled;
        return { x: vComp.x, y, yScaled: y };
      })
  },
  {
    id: 10,
    label: i18n._(t`Differenza % [(A-B)/B*100]`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: 1,
    explodeSerie: true,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: last,
    customYlabel: "%",
    customUnit: "%",
    postCb: (elementsRiferimento, elementsComparati) =>
      elementsComparati.map(vComp => {
        const vRif = elementsRiferimento.find(det => det.x === vComp.x) || 0;
        const y = roundFloat(
          ((vComp.yScaled - vRif.yScaled) / vRif.yScaled) * 100,
          3
        );
        return { x: vComp.x, y, yScaled: y };
      })
  },
  {
    id: 16,
    label: i18n._(t`Moltiplicazione`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: null,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: multiplicate,
    postCb: elements => {
      return elements;
    }
  },
  {
    id: 11,
    label: i18n._(t`Rapporto [A/B]`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: 1,
    explodeSerie: true,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: last,
    postCb: (elementsRiferimento, elementsComparati) =>
      elementsComparati.map(vComp => {
        const vRif = elementsRiferimento.find(det => det.x === vComp.x) || 0;
        const y = roundFloat(vComp.yScaled / vRif.yScaled, 3);
        return { x: vComp.x, y, yScaled: y };
      })
  },
  {
    id: 12,
    label: i18n._(t`Rapporto % [A/B*100]`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: 1,
    explodeSerie: true,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: last,
    customYlabel: "%",
    customUnit: "%",
    postCb: (elementsRiferimento, elementsComparati) =>
      elementsComparati.map(vComp => {
        const vRif = elementsRiferimento.find(det => det.x === vComp.x) || 0;
        const y = roundFloat((vComp.yScaled / vRif.yScaled) * 100, 3);
        return { x: vComp.x, y, yScaled: y };
      })
  },
  {
    id: 13,
    label: i18n._(t`Rapporto % pesato [A/(A+B)*100]`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: null,
    maxSerieB: 1,
    explodeSerie: true,
    serieRiferimento: true,
    serieComparata: true,
    aggrCb: last,
    customYlabel: "%",
    customUnit: "%",
    postCb: (elementsRiferimento, elementsComparati) =>
      elementsComparati.map(vComp => {
        const vRif = elementsRiferimento.find(det => det.x === vComp.x) || 0;
        const y = roundFloat(
          (vComp.yScaled / (vComp.yScaled + vRif.yScaled)) * 100,
          3
        );
        return { x: vComp.x, y, yScaled: y };
      })
  },
  {
    id: 14,
    label: i18n._(t`Media mobile`),
    formatNumber: true,
    needLowTF: false,
    maxSerie: 1,
    maxSerieB: 1,
    explodeSerie: false,
    serieRiferimento: true,
    serieComparata: false,
    aggrCb: min,
    seriesType: "2",
    postCb: (elements, periods = 100) => {
      if (elements && elements.length > 0) {
        return smooth(elements, periods);
      }
      return elements;
    }
  }
];

/* 0: default presentation, 1: default DB */
export const CATALOGO = [
  { name: "Calore specifico", units: ["kJ/kg"], defP: "kJ/kg", defDb: "kJ/kg" },
  { name: "Capacità elettrica", units: ["F"], defP: "F", defDb: "F" },
  { name: "Carica elettrica", units: ["C"], defP: "C", defDb: "C" },
  {
    name: "Concentrazione (Frazione)",
    units: ["ppm", "%"],
    defP: "ppm",
    defDb: "ppm"
  },
  { name: "Conduttanza elettrica", units: ["S"], defP: "S", defDb: "S" },
  { name: "Conteggio", units: ["unit"], defP: "unit", defDb: "unit" },
  { name: "Corrente elettrica CA", units: ["A", "mA"], defP: "A", defDb: "A" },
  { name: "Corrente elettrica CC", units: ["A", "mA"], defP: "A", defDb: "A" },
  { name: "Densità", units: ["kg/cbm"], defP: "kg/cbm", defDb: "kg/cbm" },
  { name: "Densità di flusso magnetico", units: ["T"], defP: "T", defDb: "T" },
  {
    name: "Direzione (Angolo piano)",
    units: ["°", "rad"],
    defP: "°",
    defDb: "°"
  },
  { name: "Dose assorbita", units: ["Gy"], defP: "Gy", defDb: "Gy" },
  { name: "Dose equivalente", units: ["Sv"], defP: "Sv", defDb: "Sv" },
  {
    name: "Energia",
    units: [
      "GJ",
      "GWh",
      "MJ",
      "MWh",
      "TJ",
      "TWh",
      "Wh",
      "btu",
      "kWh",
      "J",
      "Mcal",
      "kcal"
    ],
    defP: "kWh",
    defDb: "Wh"
  },
  {
    name: "Energia elettrica apparente",
    units: ["GVAh", "VAh", "TVAh", "VAh", "kVAh"],
    defP: "kVAh",
    defDb: "VAh"
  },
  {
    name: "Energia elettrica attiva",
    units: ["GJ", "GWh", "MJ", "MWh", "TJ", "TWh", "Wh", "btu", "kWh"],
    defP: "kWh",
    defDb: "Wh"
  },
  {
    name: "Energia elettrica reattiva",
    units: ["GVArh", "MVArh", "TVArh", "VArh", "kVArh"],
    defP: "kVArh",
    defDb: "VArh"
  },
  {
    name: "Energia termica",
    units: [
      "GJ",
      "GWh",
      "MJ",
      "MWh",
      "TJ",
      "TWh",
      "Wh",
      "btu",
      "kWh",
      "Mcal",
      "kcal"
    ],
    defP: "kWh",
    defDb: "Wh"
  },
  {
    name: "Entalpia",
    units: ["kJ/(kg °C)"],
    defP: "kJ/(kg °C)",
    defDb: "kJ/(kg °C)"
  },
  { name: "Evento", units: ["unit"], defP: "unit", defDb: "unit" },
  { name: "Fattore di potenza", units: ["adim"], defP: "adim", defDb: "adim" },
  { name: "Flusso luminoso", units: ["lm"], defP: "lm", defDb: "lm" },
  { name: "Flusso magnetico", units: ["Wb"], defP: "Wb", defDb: "Wb" },
  { name: "Forza", units: ["N"], defP: "N", defDb: "N" },
  { name: "Frequenza", units: ["Hz"], defP: "Hz", defDb: "Hz" },
  {
    name: "Grandezza adimensionale",
    units: ["adim"],
    defP: "adim",
    defDb: "adim"
  },
  { name: "Illuminamento", units: ["lx"], defP: "lx", defDb: "lx" },
  { name: "Importo", units: ["CHF", "€", "£", "$"], defP: "€", defDb: "€" },
  { name: "Induttanza", units: ["H"], defP: "H", defDb: "H" },
  { name: "Intensità luminosa", units: ["cd"], defP: "cd", defDb: "cd" },
  {
    name: "Irraggiamento solare",
    units: ["W/sqm"],
    defP: "W/sqm",
    defDb: "W/sqm"
  },
  { name: "Livello", units: ["%"], defP: "%", defDb: "%" },
  {
    name: "Livello lineare",
    units: ["mm", "cm", "dm", "m"],
    defP: "mm",
    defDb: "m"
  },
  { name: "Lunghezza", units: ["km", "m"], defP: "m", defDb: "m" },
  { name: "Massa", units: ["g", "kg", "t"], defP: "kg", defDb: "kg" },
  {
    name: "Portata massica",
    units: ["kg/h", "t/h"],
    defP: "kg/h",
    defDb: "kg/h"
  },
  {
    name: "Portata volumetrica",
    units: ["cbm/h", "l/min"],
    defP: "cbm/h",
    defDb: "cbm/h"
  },
  {
    name: "Portata volumetrica (acqua)",
    units: ["cbm/h", "l/min"],
    defP: "cbm/h",
    defDb: "cbm/h"
  },
  {
    name: "Portata volumetrica (aria compressa)",
    units: ["FADcbm/h"],
    defP: "FADcbm/h",
    defDb: "FADcbm/h"
  },
  {
    name: "Portata volumetrica (gas)",
    units: ["Scbm/h"],
    defP: "Scbm/h",
    defDb: "Scbm/h"
  },
  {
    name: "Potenza",
    units: ["GW", "MW", "W", "kW", "Mcal/h", "kcal/h"],
    defP: "kW",
    defDb: "W"
  },
  {
    name: "Potenza elettrica apparente",
    units: ["GVA", "MVA", "VA", "kVA"],
    defP: "kVA",
    defDb: "VA"
  },
  {
    name: "Potenza elettrica attiva",
    units: ["GW", "MW", "W", "kW"],
    defP: "kW",
    defDb: "W"
  },
  {
    name: "Potenza elettrica reattiva",
    units: ["GVAr", "MVAr", "VAr", "kVAr"],
    defP: "kVAr",
    defDb: "VArW"
  },
  {
    name: "Potenza termica",
    units: ["GW", "MW", "W", "kW", "Mcal/h", "kcal/h"],
    defP: "kW",
    defDb: "W"
  },
  {
    name: "Pressione",
    units: ["Pa", "bar", "mbar"],
    defP: "bar",
    defDb: "bar"
  },
  {
    name: "Pressione atmosferica",
    units: ["Pa", "bar", "mbar"],
    defP: "mbar",
    defDb: "bar"
  },
  { name: "Radioattività", units: ["Bq"], defP: "Bq", defDb: "Bq" },
  { name: "Resistenza elettrica", units: ["Ω"], defP: "Ω", defDb: "Ω" },
  { name: "Stato", units: ["adim"], defP: "adim", defDb: "adim" },
  { name: "Superficie", units: ["sqm"], defP: "sqm", defDb: "sqm" },
  { name: "Temperatura", units: ["K", "°C", "°F"], defP: "°C", defDb: "°C" },
  { name: "Tempo", units: ["s", "min", "h"], defP: "s", defDb: "s" },
  { name: "Tensione CA", units: ["V", "mV"], defP: "V", defDb: "V" },
  { name: "Tensione CC", units: ["V", "mV"], defP: "V", defDb: "V" },
  { name: "Umidità relativa", units: ["%"], defP: "%", defDb: "%" },
  { name: "Velocità", units: ["m/s"], defP: "m/s", defDb: "m/s" },
  { name: "Velocità di rotazione", units: ["rpm"], defP: "rpm", defDb: "rpm" },
  { name: "Volume", units: ["cbm", "l"], defP: "cbm", defDb: "cbm" },
  { name: "Volume (acqua)", units: ["cbm", "l"], defP: "cbm", defDb: "cbm" },
  {
    name: "Volume (aria compressa)",
    units: ["FADcbm"],
    defP: "FADcbm",
    defDb: "FADcbm"
  },
  { name: "Volume (gas)", units: ["Scbm"], defP: "Scbm", defDb: "Scbm" }
];

export const DATA_FOR_CHART = 1;
export const DATA_FOR_TABLE = 2;
export const DATA_FOR_OPTIONS = 3;
export const DATA_FOR_CSV_HEADER = 4;
export const DATA_FOR_CSV_DATA = 5;
export const DATA_FOR_XLS_DATA = 6;

// export const palette = [{ bk: '#d0021b', text: '#ffffff' },
//   { bk: '#f5a623', text: '#000000' },
//   { bk: '#f8e71c', text: '#000000' },
//   { bk: '#8b572a', text: '#ffffff' },
//   { bk: '#7ed321', text: '#000000' },
//   { bk: '#417505', text: '#ffffff' },
//   { bk: '#bd10e0', text: '#ffffff' },
//   { bk: '#9013fe', text: '#ffffff' },
//   { bk: '#4a90e2', text: '#ffffff' },
//   { bk: '#b8e986', text: '#000000' },
//   { bk: '#000000', text: '#ffffff' },
//   { bk: '#4a4a4a', text: '#ffffff' },
//   { bk: '#9b9b9b', text: '#000000' },
//   { bk: '#0021ff', text: '#000000' }];

export const palette = [
  { bk: "#4996EF", text: "#ffffff" },
  { bk: "#D63BFD", text: "#ffffff" },
  { bk: "#82E706", text: "#ffffff" },
  { bk: "#f5a623", text: "#ffffff" },
  { bk: "#FF0066", text: "#ffffff" },
  { bk: "#5EE9CE", text: "#ffffff" },
  { bk: "#9013FE", text: "#ffffff" },
  { bk: "#05CF13", text: "#ffffff" },
  { bk: "#F75C03", text: "#ffffff" },
  { bk: "#2CBBE8", text: "#ffffff" },
  { bk: "#EAD900", text: "#ffffff" },
  { bk: "#C455A8", text: "#ffffff" },
  { bk: "#284B63", text: "#ffffff" },
  { bk: "#D0021B", text: "#ffffff" }
];

const cutHex = h => (h.charAt(0) === "#" ? h.substring(1, 7) : h);
const hexToR = h => parseInt(cutHex(h).substring(0, 2), 16);
const hexToG = h => parseInt(cutHex(h).substring(2, 4), 16);
const hexToB = h => parseInt(cutHex(h).substring(4, 6), 16);

export const getCorrectTextColor = hex => {
  const threshold = 130;
  return (hexToR(hex) * 299 + hexToG(hex) * 587 + hexToB(hex) * 114) / 1000 >
    threshold
    ? "#000000"
    : "#ffffff";
};

export const generateColor = (paletteSelected = []) => {
  const returnColor = `#${Math.random()
    .toString(16)
    .substr(-6)}`;
  for (const c of palette) {
    if (paletteSelected.indexOf(c.bk) < 0) {
      return c.bk;
    }
  }
  return returnColor;
};

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function getSequenceData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length === 0 ||
    tabs.seriesActive.length === 0 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`Per visualizzare il grafico, seleziona almeno una variabile e un periodo di riferimento`
          )
        };
    }
  }
  console.log('getSequenceData dataType ', dataType);
  //performance.mark("getSequenceData-start");
  //console.profile("getSequenceData");
  console.time("getSequenceData1");


  // Log them out
  const DERIVATE_TYPE = getDerivataType();
  let data = [];
  const shapes = [];
  const addShapes = (yValue, x0, x1, bk) => {
    shapes.push({
      type: "line",
      x0,
      y0: yValue,
      x1,
      y1: yValue,
      line: { color: bk, width: 1 }
    });
  };

  /* identifico la serie attive ed imposta i parametri utili alle rappresentazioni */
  const seriesActive = tabs.seriesActive
    ? tabs.seriesActive.filter(x => {
      const variable = tabs.activeVariable.findIndex(
        detVar => detVar.id === x.id
      );
      const date = tabs.activeData.findIndex(
        d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
      );
      return variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date];
    })
    : [];

  /* aggiungo dei valori utili all'elaborazione delle logiche future */
  seriesActive.forEach(x => {
    x.variable = tabs.activeVariable.find(detVar => detVar.id === x.id);
    x.date = tabs.activeData.find(
      d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
    );
    x.seriesType = getSeriesType().find(
      s => s.id === (x.variable.seriesType || "1")
    );
    x.priority = x.seriesType.priority;
    x.bk =
      tabs.activeVariable.length === 1 && tabs.activeData.length > 1
        ? x.date.background
        : x.variable.background;
  });

  /* eseguo un'ordinamento basato sulla tipologia della */
  seriesActive.sort((a, b) => a.priority - b.priority);

  /* identifica assi Y in maniera univoca */
  const yaxisUnique = seriesActive
    .map(x => ({ customYlabel: x.customYlabel, axisYlog: false }))
    .filter(
      (value, index, self) =>
        self.findIndex(det => det.customYlabel === value.customYlabel) === index
    );

  /* analizza la tipologia dell'assey e applicalo all'intero asse */
  seriesActive.forEach(s => {
    if (s.variable && s.variable.axisYlog) {
      const finded = yaxisUnique.find(f => f.customYlabel === s.customYlabel);
      if (finded) finded.axisYlog = true;
    }
  });

  const yaxis = { automargin: true };
  const vFormat = dataList => dataList.yFormatted === undefined ? 
    (dataList.y !== null ? dataList.yScaled.toLocaleString(undefined, { maximumFractionDigits: 4 }) : "") : 
    (dataList.y !== null ? dataList.yFormatted.toLocaleString(undefined, { maximumFractionDigits: 4 }) : "");

  const getTooltips = (dataAggr, timezone, label, um) => {

    switch (tabs.configuration.timeframeSelected) {
      case "1h":
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("ddd DD/MM/YYYY HH:mm")} ${timezone ||
              ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
      case "1dy":
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("ddd DD/MM/YYYY")} ${timezone || ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
      case "1wk":
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("ddd DD/MM/YYYY")} - ${moment(
                dataList.x,
                DATE_REST_SERVER
              )
                .endOf("week")
                .locale("it")
                .format("DD/MM/YYYY")} ${timezone || ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
      case "1mo":
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("MMM YYYY")} ${timezone || ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
      case "1yr":
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("YYYY")} ${timezone || ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
      default:
        return dataAggr.map(
          dataList =>
            `${moment(dataList.x, DATE_REST_SERVER)
              .locale("it")
              .format("ddd DD/MM/YYYY HH:mm:ss")} ${timezone ||
              ""}<br>${label}<br><b>${vFormat(dataList)} [${um}]</b>`
        );
    }
  };

  /* crea serie numerica per time profile */
  const findStackedVar = tabs.activeVariable.findIndex(
    det => det.seriesType === "4" || det.seriesType === "6"
  );
  if (tabs.chartType === CHART_TYPE_TIME_SERIES_VAR) {
    data = seriesActive.map(x => {
      const detDerivata = DERIVATE_TYPE.find(
        f => f.id === x.variable.derivataType
      );
      const yaxisIndex = yaxisUnique.findIndex(
        f => f.customYlabel === x.customYlabel
      );

      /* rintraccia MAX e MIN dell'asse per autoscale manuale (plotly non supporta l'autoscale sulle BAR) */
      const yData = x.dataAggr
        .filter(
          det => det.y !== null && det.y !== undefined && !Number.isNaN(det.y)
        )
        .map(dataList =>
          parseFloat(dataList.yScaled)
        );
      const minData = min(yData);
      const maxData = max(yData);

      if (
        !("min" in yaxisUnique[yaxisIndex]) ||
        minData < yaxisUnique[yaxisIndex].min
      )
        yaxisUnique[yaxisIndex].min = minData;
      if (
        !("max" in yaxisUnique[yaxisIndex]) ||
        maxData > yaxisUnique[yaxisIndex].max
      )
        yaxisUnique[yaxisIndex].max = maxData;

      const xData = x.dataAggr.map(det => det.x);

      /* calcola le shapes per le quickstats */
      if (xData && xData.length > 0) {
        if (x.variable.QsAvgPlot)
          addShapes(x.QsAvg, xData[0], xData[xData.length - 1], x.bk);
        if (x.variable.QsMaxPlot)
          addShapes(x.QsMax, xData[0], xData[xData.length - 1], x.bk);
        if (x.variable.QsMinPlot)
          addShapes(x.QsMin, xData[0], xData[xData.length - 1], x.bk);
      }

      return {
        startDate: x.startDate, // custom
        endDate: x.endDate, // custom
        variabileId: x.id, // custom
        variableUnit: x.unit, // custom
        type: x.seriesType.type,
        mode: x.seriesType.mode,
        fill: x.seriesType.fill,
        line: { shape: x.seriesType.shape, color: x.bk },
        marker: { color: x.bk },
        name: x.variable.label,
        yaxis: `y${yaxisIndex >= 1 ? yaxisIndex + 1 : ""}`,
        stackgroup:
          findStackedVar >= 0 ? (yaxisIndex >= 1 ? yaxisIndex + 1 : 1) : null,
        text: getTooltips(
          x.dataAggr,
          x.variable.timezone,
          x.variable.label,
          detDerivata && detDerivata.customUnit
            ? detDerivata.customUnit
            : x.unit
        ),
        hoverinfo: "text",
        x: xData,
        y: x.dataAggr.map(dataList => dataList.yFormatted === undefined ? dataList.yScaled : dataList.yFormatted)
      };
    });
  } else if (tabs.chartType === CHART_TYPE_TIME_SERIES_TIME) {
    /* crea serie numerica per time comparison */
    let startDateRiferimento = null;
    let days = 0;
    data = seriesActive.map((x, idx) => {
      const detDerivata = DERIVATE_TYPE.find(
        f => f.id === x.variable.derivataType
      );
      const yaxisIndex = yaxisUnique.findIndex(
        f => f.customYlabel === x.customYlabel
      );

      /* rintraccia MAX e MIN dell'asse per autoscale manuale (plotly non supporta l'autoscale sulle BAR) */
      const yData = x.dataAggr.map(dataList =>
        parseFloat(dataList.yScaled)
      );
      const minData = min(yData);
      const maxData = max(yData);

      const gap = (maxData - minData) / 10;
      if (
        !("min" in yaxisUnique[yaxisIndex]) ||
        minData < yaxisUnique[yaxisIndex].min
      )
        yaxisUnique[yaxisIndex].min = minData - gap;
      if (
        !("max" in yaxisUnique[yaxisIndex]) ||
        maxData > yaxisUnique[yaxisIndex].max
      )
        yaxisUnique[yaxisIndex].max = maxData + gap;

      /* calcola lo slittamento */
      if (idx === 0) startDateRiferimento = moment(x.startDate, DATE_FORMAT);
      else
        days =
          startDateRiferimento.diff(moment(x.startDate, DATE_FORMAT), "days") ||
          0;

      const xData = x.dataAggr.map(dataList =>
        idx === 0
          ? dataList.x
          : moment(dataList.x, DATE_REST_SERVER)
            .add(days || 0, "days")
            .format(DATE_REST_SERVER)
      );

      /* calcola le shapes per le quickstats */
      if (xData && xData.length > 0) {
        if (x.variable.QsAvgPlot)
          addShapes(x.QsAvg, xData[0], xData[xData.length - 1], x.bk);
        if (x.variable.QsMaxPlot)
          addShapes(x.QsMax, xData[0], xData[xData.length - 1], x.bk);
        if (x.variable.QsMinPlot)
          addShapes(x.QsMin, xData[0], xData[xData.length - 1], x.bk);
      }

      return {
        startDate: x.startDate, // custom
        endDate: x.endDate, // custom
        variabileId: x.id, // custom
        variableUnit: x.unit, // custom
        type: x.seriesType.type,
        mode: x.seriesType.mode,
        fill: x.seriesType.fill,
        line: { shape: x.seriesType.shape, color: x.bk },
        marker: { color: x.bk },
        name: x.startDate,
        text: getTooltips(
          x.dataAggr,
          x.variable.timezone,
          `${x.startDate} - ${x.variable.label}`,
          detDerivata && detDerivata.customUnit
            ? detDerivata.customUnit
            : x.unit
        ),
        hoverinfo: "text",
        yaxis: `y${yaxisUnique.indexOf(x.unit) >= 1
          ? yaxisUnique.indexOf(x.unit) + 1
          : ""
          }`,
        stackgroup:
          findStackedVar >= 0
            ? yaxisUnique.indexOf(x.unit) >= 1
              ? yaxisUnique.indexOf(x.unit) + 1
              : 1
            : null,
        connectgaps: false,
        x: xData,
        y: x.dataAggr.map(dataList => dataList.yFormatted === undefined ? dataList.yScaled : dataList.yFormatted)
      };
    });
  }

  if (dataType === DATA_FOR_CSV_HEADER) {
    if (tabs.chartType === "1") {
      return [
        { label: "Timestamp", key: "timestamp" },
        ...data.map(v => ({
          label: `${v.name} [${v.variableUnit}]`,
          key: v.variabileId
        }))
      ];
    }
    if (tabs.chartType === "2") {
      return [
        { label: "Timestamp", key: "timestamp" },
        ...data.map(v => ({ label: `${v.startDate}`, key: v.startDate }))
      ];
    }
  }

  if (dataType === DATA_FOR_TABLE) {
    if (tabs.chartType === "1") {
      const listTimestamp = [];
      data
        .filter(d => !!d.x)
        .forEach((dataDet, keySeries) => {
          dataDet.x.forEach((xSerie, key) => {
            const xTimestamp = moment(xSerie, DATE_REST_SERVER).format("X");
            let finded = listTimestamp.find(
              det => det.timestamp === xTimestamp
            );
            if (!finded) {
              const values = [
                moment(xSerie, DATE_REST_SERVER).format("DD/MM/YYYY HH:mm:ss")
              ];
              for (let i = 0; i < data.length; i++) values.push(""); // serve a creare delle celle vuote per serie con granularità mancante
              finded = { timestamp: xTimestamp, values };
              listTimestamp.push(finded);
            }            
            finded.values[keySeries + 1] = dataDet.y[key] !== null ? (dataDet.y[key].toLocaleString(undefined, { maximumFractionDigits: 4 })) : dataDet.y[key];
          });
        });
      listTimestamp
        .sort((a, b) => b.timestamp - a.timestamp)
        .forEach(x => {
          x.timestamp = moment(x.timestamp, "X").format("DD/MM/YYYY HH:mm:ss");
        });
      return listTimestamp;
    }
    if (tabs.chartType === "2") {
      const listTimestamp = [];
      data.forEach((dataDet, keySeries) => {
        if (dataDet.x) {
          dataDet.x.forEach((xSerie, key) => {
            const xTimestamp = moment(xSerie, DATE_REST_SERVER).format("X");
            let finded = listTimestamp.find(
              det => det.timestamp === xTimestamp
            );
            if (!finded) {
              const values = [
                moment(xSerie, DATE_REST_SERVER).format("DD/MM/YYYY HH:mm:ss")
              ];
              for (let i = 0; i < data.length; i++) values.push(""); // serve a creare delle celle vuote per serie con granularità mancante
              finded = { timestamp: xTimestamp, values };
              listTimestamp.push(finded);
            }
            finded.values[keySeries + 1] = dataDet.y[key] !== null ? (dataDet.y[key].toLocaleString(undefined, { maximumFractionDigits: 4 })) : dataDet.y[key];
          });
        }
      });
      listTimestamp
        .sort((a, b) => b.timestamp - a.timestamp)
        .forEach(x => {
          x.timestamp = moment(x.timestamp, "X").format("DD/MM/YYYY HH:mm:ss");
        });
      return listTimestamp;
    }
  }

  if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_XLS_DATA) {
    if (tabs.chartType === "1") {
      const listTimestamp = [];
      data.forEach(dataDet => {
        if (dataDet.x) {
          dataDet.x.forEach((xSerie, key) => {
            const xTimestamp = moment(xSerie, DATE_REST_SERVER).format("X");
            let finded = listTimestamp.find(
              det => det.timestamp === xTimestamp
            );
            if (!finded) {
              finded = { timestamp: xTimestamp };
              listTimestamp.push(finded);
            }
            finded[dataDet.variabileId] = dataType === DATA_FOR_CSV_DATA && dataDet.y[key] !== null ? dataDet.y[key].toLocaleString(undefined, { maximumFractionDigits: 4 }) : dataDet.y[key];
          });
        }
      });
      listTimestamp
        .sort((a, b) => b.timestamp - a.timestamp)
        .forEach(x => {
          x.timestamp = moment(x.timestamp, "X").format("DD/MM/YYYY HH:mm:ss");
        });
      return listTimestamp;
    }
    if (tabs.chartType === "2") {
      const listTimestamp = [];
      data.forEach(dataDet => {
        if (dataDet.x) {
          dataDet.x.forEach((xSerie, key) => {
            const xTimestamp = moment(xSerie, DATE_REST_SERVER).format("X");
            let finded = listTimestamp.find(
              det => det.timestamp === xTimestamp
            );
            if (!finded) {
              finded = { timestamp: xTimestamp };
              listTimestamp.push(finded);
            }
            finded[dataDet.startDate] = dataType === DATA_FOR_CSV_DATA && dataDet.y[key] !== null ? dataDet.y[key].toLocaleString(undefined, { maximumFractionDigits: 4 }) : dataDet.y[key];
          });
        }
      });
      listTimestamp
        .sort((a, b) => b.timestamp - a.timestamp)
        .forEach(x => {
          x.timestamp = moment(x.timestamp, "X").format("DD/MM/YYYY HH:mm:ss");
        });
      return listTimestamp;
    }
  }

  if (dataType === DATA_FOR_CHART) {
    let domain = [0, 1];

    /* struttura l'asse delle Y */
    yaxisUnique.forEach((y, key) => {
      let side = null;
      let anchor = null;
      let overlaying = null;
      let position = 0;
      let title = y.customYlabel;

      switch (key) {
        case 0:
          side = null;
          anchor = null;
          overlaying = null;
          domain = [0, 1];
          break;
        case 1:
          side = "right";
          anchor = "x";
          overlaying = "y";
          position = 0;
          domain = [0.1, 0.9];
          break;
        case 2:
          side = "left";
          anchor = "free";
          overlaying = "y";
          position = 0.08;
          domain = [0.1, 0.9];
          break;
        default:
          side = "right";
          anchor = "free";
          overlaying = "y";
          position = 0.98;
          title = "All";
          domain = [0.1, 0.9];
          break;
      }

      /* nel caso in cui sia presente una Area / Barra impilata / un asse logaritmico  l'autorange deve esssere abilitato per partire dal valore 0 */
      let autorange = true;
      let range = null;
      if (findStackedVar < 0 && !y.axisYlog && y.max && y.min) {
        const gap = (y.max - y.min) / 10;
        autorange = false;
        range = [y.min - gap, y.max + gap];
      }

      yaxis[`yaxis${key + 1}`] = {
        type: y.axisYlog ? "log" : "linear",
        range,
        autorange,
        fixedrange: true,
        title,
        side,
        anchor,
        overlaying,
        position
      };
    });

    //  performance.mark("getSequenceData-end");
    //  const getSequenceDataMeasure = performance.measure('getSequenceData-start', 'getSequenceData-end');
    // console.log('getSequenceDataMeasure ',getSequenceDataMeasure);
    console.timeEnd("getSequenceData1");
    //   console.profileEnd();
    return {
      data,
      plotlyLayout: {
        margin: { t: 0 },
        shapes,
        ...yaxis,
        xaxis: {
          nticks: 10,
          automargin: true,
          domain,
          type: "date",
          autorange: true,
          hoverformat: "%a %d/%m/%Y %H:%M:%S"
        },
        barmode: findStackedVar >= 0 ? "stack" : null,
        autosize: true,
        showlegend: false,
        dragmode: "pan"
      }
    };
  }

  return false;
}

export function getPieData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length === 0 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`Per visualizzare il grafico, seleziona almeno una variabile e un periodo di riferimento`
          )
        };
    }
  }

  let data = [];
  let labels = [];
  let activeSeries = [];
  let values = [];
  let totals = 0;
  const pieType = tabs.pieType || 0;

  /* identifica la tipologia di variabili in gioco */
  const { valueType } = tabs.activeVariable[0];

  /* calcola i valori di rifferimento sulla base de tipo di grafico */
  switch (tabs.chartType) {
    case CHART_TYPE_PIE_VAR:
      activeSeries = tabs.seriesActive.filter(x => {
        const variable = tabs.activeVariable.findIndex(
          detVar => detVar.id === x.id
        );
        const date = tabs.activeData.findIndex(
          d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
        );
        return variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date];
      });

      labels = activeSeries.map(x => {
        const variable = tabs.activeVariable.findIndex(
          detVar => detVar.id === x.id
        );
        return {
          id: x.id,
          label: tabs.activeVariable[variable].label,
          background: tabs.activeVariable[variable].background
        };
      });

      /* per le grandezze ISTANTANEEE usare la media, il TOTALE per le grandezze cumulated */
      if (valueType === OP_TYPE_INSTANT) {
        values = activeSeries.map(y => average(y.dataAggr.map(z => z.yScaled)));
      } else {
        values = activeSeries.map(y => sum(y.dataAggr.map(z => z.yScaled)));
      }

      totals = sum(values);
      break;

    case CHART_TYPE_PIE_TIME:
      if (tabs.configuration.timeframeSelected === "none") {
        activeSeries = tabs.seriesActive.filter(x => {
          const variable = tabs.activeVariable.findIndex(
            detVar => detVar.id === x.id
          );
          const date = tabs.activeData.findIndex(
            d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
          );
          return (
            variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date]
          );
        });

        labels = activeSeries.map(x => {
          const date = tabs.activeData.findIndex(
            d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
          );
          return {
            id: `${x.startDate}${x.endDate}`,
            label: `${x.startDate} - ${x.endDate}`,
            background: tabs.activeData[date].background
          };
        });

        /* per le grandezze ISTANTANEEE usare la media, il TOTALE per le grandezze cumulated */
        if (valueType === OP_TYPE_INSTANT) {
          values = activeSeries.map(y =>
            average(y.dataAggr.map(z => z.yScaled))
          );
        } else {
          values = activeSeries.map(y => sum(y.dataAggr.map(z => z.yScaled)));
        }

        totals = sum(values);
      } else {
        const colorAdd = [];
        tabs.seriesActive
          .filter(x => {
            const variable = tabs.activeVariable.findIndex(
              detVar => detVar.id === x.id
            );
            const date = tabs.activeData.findIndex(
              d => d.refStartDate === x.startDate && d.refEndDate === x.endDate
            );
            return (
              variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date]
            );
          })
          .forEach((l, keySerie) => {
            l.dataAggr.forEach((x, keyData) => {
              const bkTmp = generateColor(colorAdd);
              colorAdd.push(bkTmp);
              labels.push({
                id: `${keySerie}-${keyData}`,
                label: moment(x.x, DATE_REST_SERVER).format(tabs.xFormat),
                background: bkTmp
              });
              values.push(x.y);
            });
          });
        totals = sum(values);
      }

      break;
    default:
      break;
  }

  /* calcola struttura dato per CHART */
  if (dataType === DATA_FOR_CHART) {
    let tickangle = 0;
    let xSuffix = "";
    let ySuffix = "";
    let yVisible = true;

    switch (tabs.chartType) {
      case CHART_TYPE_PIE_VAR:
        switch (pieType) {
          case 1:
            ySuffix = "%";
            data = [
              {
                type: "bar",
                orientation: "v",
                hovertext: labels.map(det => det.label),
                marker: { color: labels.map(det => det.background) },
                x: labels.map(det => det.label),
                y: values.map(y => roundFloat((y / totals) * 100, 3))
              }
            ];
            break;

          case 2:
            tickangle = -90;
            xSuffix = "%";
            yVisible = false;
            data = [
              {
                type: "bar",
                orientation: "h",
                hovertext: labels.map(det => det.label),
                marker: { color: labels.map(det => det.background) },
                x: values.map(y => roundFloat((y / totals) * 100, 3)),
                y: labels.map(det => det.label)
              }
            ];
            break;

          default:
            data = [
              {
                sort: false,
                direction: "clockwise",
                textfont: { size: 10 },
                textposition: "outside",
                type: "pie",
                mode: "markers",
                marker: { colors: labels.map(det => det.background) },
                textinfo: "text+value+percent",
                hoverinfo: "text+value+percent",
                text: labels.map(det => det.label),
                values,
                labels: labels.map(det => det.id)
              }
            ];
            break;
        }

        break;

      case CHART_TYPE_PIE_TIME:
        switch (pieType) {
          case 1:
            ySuffix = "%";
            data = [
              {
                type: "bar",
                orientation: "v",
                hovertext: labels.map(det => det.label),
                marker: { color: labels.map(det => det.background) },
                x: labels.map(det => det.label),
                y: values.map(y => roundFloat((y / totals) * 100, 3))
              }
            ];
            break;

          case 2:
            tickangle = -90;
            xSuffix = "%";
            yVisible = false;
            data = [
              {
                type: "bar",
                orientation: "h",
                hovertext: labels.map(det => det.label),
                marker: { color: labels.map(det => det.background) },
                x: values.map(y => roundFloat((y / totals) * 100, 3)),
                y: labels.map(det => det.label)
              }
            ];
            break;

          default:
            data = [
              {
                sort: false,
                direction: "clockwise",
                textfont: { size: 10 },
                textposition: "outside",
                type: "pie",
                mode: "markers",
                marker: { colors: labels.map(det => det.background) },
                textinfo: "text+value+percent",
                hoverinfo: "text+value+percent",
                text: labels.map(det => det.label),
                values,
                labels: labels.map(det => det.id)
              }
            ];
            break;
        }
        break;

      default:
        break;
    }

    return {
      data,
      plotlyLayout: {
        autosize: true,
        showlegend: false,
        xaxis: { automargin: true, fixedrange: true, ticksuffix: xSuffix },
        yaxis: {
          visible: yVisible,
          automargin: true,
          fixedrange: true,
          tickangle,
          ticksuffix: ySuffix
        },
        dragmode: "pan"
      }
    };
  }

  /* calcola struttura data per TABLE */
  if (dataType === DATA_FOR_TABLE) {
    return { labels, values, totals };
  }

  /* elabora struttura per CSV */
  if (dataType === DATA_FOR_CSV_HEADER) {
    return [
      { label: "Label", key: "label" },
      { label: "Value", key: "value" }
    ];
  }

  if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_XLS_DATA) {
    return values.map((val, key) => ({
      label: labels[key].label,
      value: dataType === DATA_FOR_CSV_DATA ? roundFloat(((val / totals) * 100 * 100) / 100, 3).toLocaleString(undefined, { maximumFractionDigits: 4 }) : roundFloat(((val / totals) * 100 * 100) / 100, 3)
    }));
  }

  return {};
}

export function getCusumData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length < 2 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;

      default:
        return {
          error: i18n._(
            t`Per visualizzare il grafico, seleziona due variabili e un periodo di riferimento`
          )
        };

    }
  }

  const cusumData = { cusumXkeys: {}, x: {}, y: {} };

  tabs.seriesActive.forEach(x => {
    x.dataAggr.forEach(value => {
      cusumData.cusumXkeys[value.x] = [];
    });
  });

  /* aggiungo il valore delle coppie sulla chiave X */
  tabs.seriesActive.forEach(x => {
    x.dataAggr.forEach(dataList =>
      cusumData.cusumXkeys[dataList.x].push(
        dataList.yScaled === null ? 0 : dataList.yScaled
      )
    );
  });

  /* ricostruisco un array con i DELTA */
  const cusumDelta = {};
  Object.keys(cusumData.cusumXkeys).forEach(x => {
    cusumDelta[x] = cusumData.cusumXkeys[x][0] - cusumData.cusumXkeys[x][1];
  });

  /* creo somma cumulativa */
  const cusumDeltaCumulated = {};
  Object.keys(cusumData.cusumXkeys).forEach(x => {
    let prevTotal = 0;
    Object.keys(cusumDelta).forEach(prevX => {
      prevTotal += prevX < x ? cusumDelta[prevX] : 0;
    });
    cusumDeltaCumulated[x] = prevTotal + cusumDelta[x];
  });

  cusumData.x = Object.keys(cusumDeltaCumulated).map(key => key);
  cusumData.y = Object.keys(cusumDeltaCumulated).map(
    key => cusumDeltaCumulated[key]
  );

  if (dataType === DATA_FOR_CHART) {
    const data = [
      {
        type: "scatter",
        mode: "lines+points",
        fill: "none",
        line: { shape: "linear" },
        marker: {},
        visible: true,
        name: "Cusum",
        yaxis: "y1",
        x: cusumData.x,
        y: cusumData.y
      }
    ];

    /* Creo SIGMA LINE */
    const keys = Object.keys(cusumData.cusumXkeys);
    const firstX = keys[0];
    const endX = keys[keys.length - 1];

    const shapes = [
      {
        type: "line",
        y0: tabs.sigma || 5,
        y1: tabs.sigma || 5,
        x0: firstX,
        x1: endX,
        line: {
          color: "rgb(50, 171, 96)",
          width: 2
        }
      },
      {
        type: "line",
        y0: -tabs.sigma || -5,
        y1: -tabs.sigma || -5,
        x0: firstX,
        x1: endX,
        line: {
          color: "rgb(50, 171, 96)",
          width: 2
        }
      }
    ];

    const yLog =
      tabs &&
      tabs.variableSelection &&
      tabs.variableSelection.findIndex(f => f.axisYlog) >= 0;
    return {
      data,
      plotlyLayout: {
        margin: { t: 0 },
        yaxis1: {
          type: yLog ? "log" : "linear",
          automargin: true,
          autorange: true,
          fixedrange: true
        },
        shapes,
        autosize: true,
        showlegend: false,
        xaxis: { automargin: true },
        dragmode: "pan"
      }
    };
  }

  if (dataType === DATA_FOR_TABLE) {
    return cusumData.x.map((detX, detY) => ({
      timestamp: moment(detX, DATE_REST_SERVER).format("DD/MM/YYYY HH:mm:ss"),
      value: roundFloat(cusumData.y[detY]).toLocaleString(undefined, { maximumFractionDigits: 4 })
    }));
  }

  if (dataType === DATA_FOR_CSV_HEADER) {
    return [
      { label: "Timestamp", key: "timestamp" },
      { label: "Value", key: "value" }
    ];
  }

  if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_XLS_DATA) {
    return cusumData.x.map((detX, detY) => ({
      timestamp: moment(detX, DATE_REST_SERVER).format("DD/MM/YYYY HH:mm:ss"),
      value: dataType === DATA_FOR_CSV_DATA ? roundFloat(cusumData.y[detY]).toLocaleString(undefined, { maximumFractionDigits: 4 }) : roundFloat(cusumData.y[detY])
    }));
  }

  return {};
}

export function getDistribuzioneValue(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length === 0 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`Per visualizzare il grafico, seleziona almeno una variabile e un periodo di riferimento`
          )
        };
    }
  }

  let data = [];
  const SERIES_TYPE = getSeriesType();

  /* Determino l'insieme dei valori per calcolare il minimo, il massimo e la suddivisione in intervalli  */
  const fullValue = [];
  tabs.seriesActive
    .filter(serie => {
      const variable = tabs.activeVariable.findIndex(
        detVar => detVar.id === serie.id
      );
      const date = tabs.activeData.findIndex(
        d =>
          d.refStartDate === serie.startDate && d.refEndDate === serie.endDate
      );
      return variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date];
    })
    .forEach(serie => {
      serie.dataAggr.forEach(value => {
        fullValue.push(value.yScaled);
      });
    });

  const minValue = Math.min(...fullValue);
  const maxValue = Math.max(...fullValue);
  const suddivisione = (maxValue - minValue) / (tabs.intervalli || 10);

  const xAxis = [];
  if (suddivisione > 0) {
    for (let i = minValue; i < maxValue; i += suddivisione) {
      xAxis.push({
        min: i,
        max: i + suddivisione,
        mid: (i + (i + suddivisione)) / 2
      });
    }
  } else {
    xAxis.push({ min: minValue, max: maxValue, mid: minValue });
  }

  /* genero le serie da plottare */
  data = tabs.seriesActive
    .filter(serie => {
      const variable = tabs.activeVariable.findIndex(
        detVar => detVar.id === serie.id
      );
      const date = tabs.activeData.findIndex(
        d =>
          d.refStartDate === serie.startDate && d.refEndDate === serie.endDate
      );
      return variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date];
    })
    .map(serie => {
      const variable = tabs.activeVariable.findIndex(
        detVar => detVar.id === serie.id
      );
      const bk = tabs.activeVariable[variable].background;
      const seriesType = SERIES_TYPE.find(
        s => s.id === tabs.activeVariable[variable].seriesType || "1"
      );

      /* determino la distribuzione della variabile negli intervalli */
      const yAxis = [];
      xAxis.forEach(detX => {
        yAxis.push(
          serie.dataAggr.filter(
            dataList =>
              dataList.yScaled >= detX.min && dataList.yScaled <= detX.max
          ).length
        );
      });

      return {
        variabileId: serie.id,
        type: seriesType.type,
        mode: seriesType.mode,
        fill: seriesType.fill,
        line: { shape: seriesType.shape, color: bk },
        marker: { color: bk },
        visible: true,
        name: `${tabs.activeVariable[variable].label} [${tabs.activeVariable[variable].unit}]`,
        x: xAxis.map(x => Math.round(x.mid * 100) / 100),
        y: yAxis
      };
    });

  if (dataType === DATA_FOR_CHART) {
    return {
      data,
      plotlyLayout: {
        margin: { t: 0 },
        xaxis: { automargin: true, type: "category", nticks: tabs.intervalli },
        yaxis: { automargin: true, autorange: true, fixedrange: true },
        autosize: true,
        showlegend: false,
        dragmode: "pan"
      }
    };
  }

  if (dataType === DATA_FOR_CSV_HEADER) {
    return [
      { label: "Intervallo", key: "intervallo" },
      ...data.map(v => {
        const variable = tabs.activeVariable.find(
          detVar => detVar.id === v.variabileId
        );
        return {
          label: `${variable.label} [${variable.unit}]`,
          key: v.variabileId
        };
      })
    ];
  }

  if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_XLS_DATA) {
    const intervalli = [];
    data.forEach(dataDet => {
      dataDet.x.forEach((xSerie, key) => {
        let finded = intervalli.find(det => det.intervallo === xSerie);
        if (!finded) {
          finded = { intervallo: dataType === DATA_FOR_CSV_DATA ? xSerie.toLocaleString(undefined, { maximumFractionDigits: 4 }) : xSerie };
          intervalli.push(finded);
        }
        finded[dataDet.variabileId] = dataType === DATA_FOR_CSV_DATA ? dataDet.y[key].toLocaleString(undefined, { maximumFractionDigits: 4 }) : dataDet.y[key];
      });
    });
    return intervalli;
  }

  return {};
}

export function getHeatMapData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length === 0 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`Per visualizzare il grafico, seleziona almeno una variabile e un periodo di riferimento`
          )
        };
    }
  }

  const convertMinsToHrsMins = minutes => {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    h = h < 10 ? `0${h}` : h;
    m = m < 10 ? `0${m}` : m;
    return `${h}:${m}`;
  };

  /* identifica l'asse X (non definibile da parte dell'utente) */
  const primaryDate = tabs.dateSelection[0];
  const endDate = moment(primaryDate.endDate, DATE_FORMAT);
  const startDate = moment(primaryDate.startDate, DATE_FORMAT);
  const days = endDate.diff(startDate, "days");

  if (days < 6) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
        return false;
      default:
        return {
          error:
            "La finestra temporale risulta essere troppo piccola, selezionare una finestra superiore o uguale ai 7 giorni"
        };
    }
  }

  /* normalizza le date in base alla tipologia di heatmap */
  if (tabs.heatX === "WP") {
    startDate.startOf("week");
    endDate.startOf("week");
  } else if (tabs.heatX === "MP") {
    startDate.startOf("month");
    endDate.startOf("week");
  }

  /* componi l'asse X Y con i valori */
  const xAxis = [];
  do {
    const day = startDate.format("DD/MM/YYYY");
    xAxis.push(day);

    if (tabs.heatX === "DP") startDate.add(1, "days");
    else if (tabs.heatX === "WP") startDate.add(1, "weeks");
    else if (tabs.heatX === "MP") startDate.add(1, "months");
    else break;
  } while (startDate <= endDate);

  const yAxis = [];
  if (tabs.heatY === "15M") {
    for (let i = 15; i <= 1440; i += 15) {
      const formattedMin = convertMinsToHrsMins(i);
      yAxis.push(formattedMin);
    }
  } else if (tabs.heatY === "1H") {
    for (let i = 60; i <= 1440; i += 60) {
      const formattedMin = convertMinsToHrsMins(i);
      yAxis.push(formattedMin);
    }
  } else if (tabs.heatY === "D7") {
    yAxis.push("lun", "mar", "mer", "gio", "ven", "sab", "dom");
  } else if (tabs.heatY === "D31") {
    for (let i = 1; i <= 31; i += 1) {
      yAxis.push(i);
    }
  }

  /* crea heatmap */
  const zAxis = [];
  const variabile = tabs.activeVariable[0];
  const serie = tabs.seriesActive.find(x => x.id === variabile.id);

  if (serie !== undefined) {
    Object.keys(serie.timeSeries).forEach(zKey => {
      const timestampx = moment(serie.timeSeries[zKey].t, DATE_REST_SERVER);
      const timestampy = moment(serie.timeSeries[zKey].t, DATE_REST_SERVER);
      let xTmp = null;
      let yTmp = null;

      /* per i valori 15M 1H il primo campione del giorno successivo devo essere visualizzato nel giorno precendente */
      if (
        (tabs.heatY === "15M" || tabs.heatY === "1H") &&
        timestampy.format("kk:mm") === "24:00"
      ) {
        timestampx.subtract(1, "days");
      }

      if (tabs.heatX === "DP") xTmp = timestampx.format("DD/MM/YYYY");
      else if (tabs.heatX === "WP")
        xTmp = timestampx.startOf("week").format("DD/MM/YYYY");
      else if (tabs.heatX === "MP")
        xTmp = timestampx.startOf("month").format("DD/MM/YYYY");

      /* la mezzanotte deve essere valutata come 24:00 per finire nel giorno precedente */
      if (tabs.heatY === "15M") {
        yTmp =
          timestampy.format("kk:mm") === "24:00"
            ? timestampy.format("kk:mm")
            : timestampy.format("HH:mm");
      } else if (tabs.heatY === "1H") {
        yTmp = timestampy.format("kk:mm");
      } else if (tabs.heatY === "D7") {
        yTmp = timestampy.format("ddd");
      } else if (tabs.heatY === "D31") {
        yTmp = timestampy.format("D");
      }

      zAxis.push({ x: xTmp, y: yTmp, value: serie.dataAggr[zKey].yScaled });
    });
  }

  /* Identifica i valori dell'asse sulla base del periodo */
  if (dataType === DATA_FOR_CHART) {
    const zValues = yAxis.map(yDet =>
      xAxis.map(xDet => {
        const finded = zAxis.find(zDet => zDet.x === xDet && zDet.y === yDet);
        if (finded) return finded.value;
        return null;
      })
    );

    const zText = yAxis.map(yDet =>
      xAxis.map(xDet => {
        const finded = zAxis.find(zDet => zDet.x === xDet && zDet.y === yDet);
        let prefix = "";

        if (tabs.heatY === "D7") prefix = "Giorno della settimana: ";
        else if (tabs.heatY === "D31") prefix = "Giorno del mese: ";

        if (finded)
          return `${xDet}<br>${prefix}${yDet}<br><b>${finded.value.toLocaleString()} [${variabile.unit}]</b>`;
        return null;
      })
    );

    const data = [
      {
        type: "heatmap",
        mode: "markers",
        x: xAxis,
        y: yAxis,
        z: zValues,
        text: zText,
        hoverinfo: "text",
        colorbar: { title: ` ${variabile.unit}` }
      }
    ];

    return {
      data,
      plotlyLayout: {
        margin: { t: 0 },
        autosize: true,
        showlegend: false,
        xaxis: {
          type: "category",
          automargin: true,
          autorange: true,
          fixedrange: true
        },
        yaxis: {
          type: "category",
          automargin: true,
          autorange: true,
          fixedrange: true
        },
        dragmode: "pan"
      }
    };
  }

  if (dataType === DATA_FOR_CSV_HEADER) {
    return [
      { label: "X", key: "x" },
      { label: "Y", key: "y" },
      { label: "Z", key: "z" }
    ];
  }

  if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_TABLE || dataType === DATA_FOR_XLS_DATA) {
    const zValues = yAxis.map(yDet =>
      xAxis.map(xDet => {
        const finded = zAxis.find(zDet => zDet.x === xDet && zDet.y === yDet);
        if (finded) return dataType === DATA_FOR_CSV_DATA ? finded.value.toLocaleString(undefined, { maximumFractionDigits: 4 }) : finded.yValue;
        return null;
      })
    );

    const returnData = [];
    yAxis.forEach((o, idy) => {
      xAxis.forEach((v, idx) => {
        returnData.push({ x: v, y: o, z: zValues[idy][idx] });
      });
    });
    return returnData;
  }

  return {};
}

function arithmeticMean(data) {
  let total = 0;

  // note that incrementing total is done within the for loop
  for (let i = 0, l = data.length; i < l; total += data[i], i++);

  return total / data.length;
}

function variance(data) {
  const squares = [];

  for (let i = 0, l = data.length; i < l; i++) {
    squares[i] = Math.pow(data[i], 2);
  }

  const mean_of_squares = arithmeticMean(squares);
  const mean = arithmeticMean(data);
  const square_of_mean = Math.pow(mean, 2);
  const variance = mean_of_squares - square_of_mean;

  return variance;
}

function meanOfProducts(data1, data2) {
  let total = 0;

  // note that incrementing total is done within the for loop
  for (let i = 0, l = data1.length; i < l; total += data1[i] * data2[i], i++);

  return total / data1.length;
}

function standardDeviation(data) {
  const squares = [];

  for (let i = 0, l = data.length; i < l; i++) {
    squares[i] = Math.pow(data[i], 2);
  }

  const mean_of_squares = arithmeticMean(squares);
  const mean = arithmeticMean(data);
  const square_of_mean = Math.pow(mean, 2);
  const variance = mean_of_squares - square_of_mean;
  const std_dev = Math.sqrt(variance);

  return std_dev;
}

function pearsonCorrelation(independent, dependent) {
  // covariance
  const independent_mean = arithmeticMean(independent);
  const dependent_mean = arithmeticMean(dependent);
  const products_mean = meanOfProducts(independent, dependent);
  const covariance = products_mean - independent_mean * dependent_mean;

  // standard deviations of independent values
  const independent_standard_deviation = standardDeviation(independent);

  // standard deviations of dependent values
  const dependent_standard_deviation = standardDeviation(dependent);

  // Pearson Correlation Coefficient
  const rho =
    covariance /
    (independent_standard_deviation * dependent_standard_deviation);

  return rho;
}

function linearRegression(independent, dependent) {
  const lr = {};

  const independent_mean = arithmeticMean(independent);
  const dependent_mean = arithmeticMean(dependent);
  const products_mean = meanOfProducts(independent, dependent);
  const independent_variance = variance(independent);

  lr.a =
    (products_mean - independent_mean * dependent_mean) / independent_variance;

  lr.b = dependent_mean - lr.a * independent_mean;

  return lr;
}

export function getScatterData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length < 2 ||
    tabs.activeData.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`E' necessario selezionare almeno due variabili ed un periodo di riferimento per visualizzare il grafico`
          )
        };
    }
  }

  /* controlla che vi siano almeno due variabili a sistema */
  if (tabs.seriesActive && Object.keys(tabs.seriesActive).length === 2) {
    /* stampa tutti valori dalla variabile di riferimento alle variabili secondarie */

    const xData = [];
    const yData = [];
    const textData = [];

    tabs.seriesActive[0].dataAggr.forEach(detX => {
      tabs.seriesActive[1].dataAggr
        .filter(detY => detY.x === detX.x)
        .forEach(detY => {
          if (
            detX.y !== undefined &&
            detY.y !== undefined &&
            detX.y !== null &&
            detY.y !== null
          ) {
            xData.push(detX.yScaled);
            yData.push(detY.yScaled);
            textData.push(detX.x);
          }
        });
    });

    if (xData.length === 0) {
      switch (dataType) {
        case DATA_FOR_CSV_HEADER:
        case DATA_FOR_CSV_DATA:
        case DATA_FOR_XLS_DATA:
        case DATA_FOR_TABLE:
          return false;
        default:
          return {
            error:
              "Non sono presenti valori per il calcolo del grafico di dispersione"
          };
      }
    }

    /* calcolo della regressione lineare */
    // console.log(xData,yData);
    const lr = linearRegression(xData, yData);
    tabs.R2 = pearsonCorrelation(xData, yData) || 0;
    tabs.regressionFormula = `y = ${lr.a}x + ${lr.b}`;

    if (dataType === DATA_FOR_CHART) {
      let data = [];
      let shapes = [];

      const minX = Math.min(...xData);
      const maxX = Math.max(...xData);

      shapes = [
        {
          type: "line",
          x0: minX,
          y0: lr.a * minX + lr.b,
          x1: maxX,
          y1: lr.a * maxX + lr.b,
          line: {
            color: "rgb(50, 171, 96)",
            width: 2
          }
        }
      ];

      data = [
        {
          type: "scatter",
          mode: "markers",
          x: xData,
          y: yData,
          text: textData,
          hoverinfo: "text"
        }
      ];

      return {
        data,
        plotlyLayout: {
          margin: { t: 0 },
          shapes,
          yaxis: {
            automargin: true,
            autorange: true,
            fixedrange: true,
            title: `${tabs.activeVariable[1].label} [${tabs.seriesActive[1].unit}]`
          },
          xaxis: {
            automargin: true,
            title: `${tabs.activeVariable[0].label} [${tabs.seriesActive[0].unit}]`
          },
          autosize: true,
          showlegend: false,
          dragmode: "pan"
        }
      };
    }

    if (dataType === DATA_FOR_TABLE) {
      return { x: xData, y: yData };
    }

    if (dataType === DATA_FOR_CSV_HEADER) {
      return [
        { label: "X", key: "x" },
        { label: "Y", key: "y" }
      ];
    }

    if (dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_XLS_DATA) {
      const returnData = [];
      xData.forEach((idx, key) => {
        returnData.push({
          x: dataType === DATA_FOR_CSV_DATA ? idx.toLocaleString(undefined, { maximumFractionDigits: 4 }) : idx,
          y: dataType === DATA_FOR_CSV_DATA ? yData[key].toLocaleString(undefined, { maximumFractionDigits: 4 }) : yData[key]
        });
      });
      return returnData;
    }
  }

  return {};
}

export function getCurvaCaricoData(tabs, dataType) {
  if (
    !tabs.activeVariable ||
    !tabs.activeData ||
    !tabs.seriesActive ||
    tabs.activeVariable.length === 0 ||
    tabs.activeData.length === 0 ||
    tabs.seriesActive.length === 0
  ) {
    switch (dataType) {
      case DATA_FOR_CSV_HEADER:
      case DATA_FOR_CSV_DATA:
      case DATA_FOR_XLS_DATA:
      case DATA_FOR_TABLE:
        return false;
      default:
        return {
          error: i18n._(
            t`E' necessario selezionare una o più variabili ed un periodo di riferimento per visualizzare il grafico`
          )
        };
    }
  }

  const SERIES_TYPE = getSeriesType();
  const serie = tabs.seriesActive[0];
  const variable = tabs.activeVariable.findIndex(
    detVar => detVar.id === serie.id
  );
  const date = tabs.activeData.findIndex(
    d => d.refStartDate === serie.startDate && d.refEndDate === serie.endDate
  );
  const dataAggrSorted =
    variable >= 0 && date >= 0 && tabs.seriesVisible[variable][date]
      ? serie.dataAggr.sort((a, b) => b.yScaled - a.yScaled)
      : [];
  const bk =
    tabs.activeVariable.length === 1 && tabs.activeData.length > 1
      ? tabs.activeData[date].background
      : tabs.activeVariable[variable].background;
  const seriesType = SERIES_TYPE.find(
    s => s.id === tabs.activeVariable[variable].seriesType
  );

  const data = [
    {
      type: seriesType.type,
      mode: seriesType.mode,
      fill: seriesType.fill,
      line: { shape: seriesType.shape, color: bk },
      marker: { color: bk },
      visible: true,
      hoverinfo: 'text',
      text: dataAggrSorted.map(det => det.yScaled.toLocaleString(undefined, { maximumFractionDigits: 4 })),
      name: tabs.activeVariable[variable].label,
      x: dataAggrSorted.map((val, i) => i + 1),
      y: dataAggrSorted.map(det => det.yScaled)
    }
  ];

  /* crea serie numerica */
  if (dataType === DATA_FOR_CHART) {
    let autorange = true;
    let range = null;
    if (data.length > 0) {
      const minData = min(data[0].y);
      const maxData = max(data[0].y);
      if (!tabs.activeVariable[variable].axisYlog && maxData && minData) {
        const gap = (maxData - minData) / 10;
        autorange = false;
        range = [minData - gap, maxData + gap];
      }
    }

    return {
      data,
      plotlyLayout: {
        margin: { t: 0 },
        yaxis: {
          type: tabs.activeVariable[variable].axisYlog ? "log" : "linear",
          title: tabs.activeVariable[variable].unit,
          range,
          autorange,
          automargin: true,
          fixedrange: true
        },
        autosize: true,
        showlegend: false,
        xaxis: { automargin: true, type: "category" },
        dragmode: "pan"
      }
    };
  }

  if (dataType === DATA_FOR_CSV_HEADER) {
    return [
      { label: "Progressione", key: "progress" },
      { label: "Value", key: "value" }
    ];
  }

  if (dataType === DATA_FOR_XLS_DATA || dataType === DATA_FOR_CSV_DATA || dataType === DATA_FOR_TABLE) {
    return data[0].x.map((x, key) => [
      { value: dataType === DATA_FOR_XLS_DATA ? excelFormatNumber(x) : x.toLocaleString(undefined, { maximumFractionDigits: 4 }) },
      { value: dataType === DATA_FOR_XLS_DATA ? excelFormatNumber(data[0].y[key]) : data[0].y[key].toLocaleString(undefined, { maximumFractionDigits: 4 }) }
    ]);
  }

  return false;
}

export const isDynamicUnit = (catalogs, physicalQuantity) => {
  if (!catalogs || !catalogs.physicalQuantities) {
    // console.error('Non è stato trovato il catalog');
    return false;
  }

  const phisicalFinded = catalogs.physicalQuantities.find(
    f => f.naturalKey === physicalQuantity
  );
  if (!phisicalFinded) return false;

  const unitDaValutare = phisicalFinded.defaultVisualizationUnit
    ? phisicalFinded.defaultVisualizationUnit
    : phisicalFinded.units[0];
  return unitDaValutare === "s" || unitDaValutare === "kg";
};

export const getPhysicalQuantityFromUnit = (catalogs, unitKey) => {
  if (!catalogs || !catalogs.units) {
    // console.error('Non è stato trovato il catalog');
    return null;
  }
  const finded = catalogs.units.find(f => f.symbol === unitKey);
  return finded ? finded.physicalQuantities : null;
};

export const getPhysicalQuantityFromNaturalKey = (catalogs, naturalKey) => {
  if (!catalogs || !catalogs.units) {
    // console.error('Non è stato trovato il catalog');
    return null;
  }
  const finded = catalogs.units.find(f => f.naturalKey === naturalKey);
  return finded ? finded.physicalQuantities : null;
};

export const getUnitDetail = (catalogs, naturalKey) =>
  catalogs.units.find(x => x.naturalKey === naturalKey);

export const getDefaultPresentationUnit = (
  catalogs,
  physicaldimensions,
  physicalQuantity,
  customQuantityId
) => {
  if (!catalogs || !catalogs.physicalQuantities) {
    // console.error('Non è stato trovato il catalog');
    return null;
  }

  if (customQuantityId) {
    const findFisicalDim = physicaldimensions.find(
      f => f.id === customQuantityId
    );
    if (findFisicalDim)
      return { symbol: findFisicalDim.unitSymbol, exactoConversionFactor: 1 };
  }

  /* identifica la phisical quantity nel catalogo */
  const phisicalFinded = catalogs.physicalQuantities.find(
    f => f.naturalKey === physicalQuantity
  );
  if (!phisicalFinded) return null;

  /* identifica la default phiscal unit */
  // const defaultPhiscalUnit = phisicalFinded.defaultVisualizationUnit ? phisicalFinded.defaultVisualizationUnit : phisicalFinded.units[0];
  // const defaultPresentatioUnit = getUnitDetail(catalogs, defaultPhiscalUnit);
  // if (!defaultPresentatioUnit) return null;

  const defaultPhiscalUnit = getUnitByPhysicalQuantity(phisicalFinded);

  const defaultPresentatioUnit = getUnitDetail(catalogs, defaultPhiscalUnit);
  if (!defaultPresentatioUnit) return null;

  return defaultPresentatioUnit;
};

export const calcRollingDate = p => {
  if (p) {

    const {
      rollingDiff,
      rollingShift,
      rolling,
    } = p;
    let { startDate, endDate } = p;


    if (rolling) {
      endDate = moment().subtract(rollingShift, "days").format(DATE_FORMAT);
      startDate = moment().subtract(rollingDiff + rollingShift, "days").format(DATE_FORMAT);

      /*
      endDate = moment().subtract(rollingShift, "days");
      if (!rollingEndDate || rollingEndDate === "dynamic") {
        endDate = endDate.format(DATE_FORMAT);
      } else if (rollingEndDate === "2isoWeek") {
        endDate = endDate.endOf("isoWeek").subtract(1, "week").format(DATE_FORMAT);
      } else if (rollingEndDate === "2month") {
        endDate = endDate.endOf("month").subtract(1, "month").format(DATE_FORMAT);
      } else if (rollingEndDate === "2year") {
        endDate = endDate.endOf("year").subtract(1, "year").format(DATE_FORMAT);
      } else {
        endDate = endDate.endOf(rollingEndDate).format(DATE_FORMAT);
      }

      startDate = !rollingStartDate || rollingStartDate === "dynamic"
          ? moment().subtract(rollingDiff + rollingShift, "days").format(DATE_FORMAT)
          : moment(endDate, DATE_FORMAT).startOf(rollingStartDate).format(DATE_FORMAT);

      */



    }


    return { startDate, endDate };
  }

  const startDate = moment().format(DATE_FORMAT);
  const endDate = moment().format(DATE_FORMAT);
  return { startDate, endDate };
};
