import React from 'react';
import { translateDetail } from '../../../pages/notificationengine/helpers/CatalogsTranslate';

const AlarmToast = (props) => {

    const { event, catalogs } = props;
    const { name, occurred, nodeName, entityName, condition } = event;

    return <div>
        <p>{translateDetail(name, event, catalogs)}</p>
        <p>{new Date(occurred).toLocaleString()}</p><br />
        <ul>
            {nodeName && <li>{nodeName}</li>}
            {entityName && <li>{entityName}</li>}
            {condition && <li>{condition}</li>}
        </ul>
    </div>

}

export default AlarmToast;