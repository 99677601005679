/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-redeclare */
/* eslint-disable no-case-declarations */
import React, { Component } from "react";
import { ListGroupItem, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from "react-color";
import { Trans } from "@lingui/macro";
import styled from "styled-components";
import PropTypes from "prop-types";
import { calcRollingDate } from "./Functions";

/* stili per le labes */

const Color = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${props => (props.background ? props.background : "#000")};
`;

const Swatch = styled.div`
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
  padding-right: 5px;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 10;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const EyeIcon = styled(FontAwesomeIcon)`
  &&&& {
    padding-right: 5px;
    cursor: pointer;
  }
`;

const EyeStats = styled(FontAwesomeIcon)`
  &&&& {
    margin-right: 5px;
    cursor: pointer;
  }
`;

const BadgePointer = styled(Badge)`
  &&&& {
    cursor: pointer;
  }
`;
class ChartLabel extends Component {
  constructor(props) {
    super(props);

    const variable = props.tabs.activeVariable.findIndex(
      detVar => detVar.id === props.serie.id
    );
    const date = props.tabs.activeData.findIndex(
      d =>
        d.refStartDate === props.serie.startDate &&
        d.refEndDate === props.serie.endDate
    );
    const color =
      Object.keys(props.tabs.activeVariable).length === 1 &&
      Object.keys(props.tabs.activeData).length > 1
        ? props.tabs.activeData[date].background
        : props.tabs.activeVariable[variable].background;

    this.state = {
      displayColorPicker: false,
      variable,
      date,
      color
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.serie) !== JSON.stringify(prevProps.serie)) {
      const variable = this.props.tabs.activeVariable.findIndex(
        detVar => detVar.id === this.props.serie.id
      );
      const date = this.props.tabs.activeData.findIndex(
        d =>
          d.refStartDate === this.props.serie.startDate &&
          d.refEndDate === this.props.serie.endDate
      );
      const color =
        Object.keys(this.props.tabs.activeVariable).length === 1 &&
        Object.keys(this.props.tabs.activeData).length > 1
          ? this.props.tabs.activeData[date].background
          : this.props.tabs.activeVariable[variable].background;

      this.setState({ variable, date, color });
    }
  }

  handleColorPick = () => {
    this.setState({ displayColorPicker: true });
  };

  handleChange = color => {
    this.setState({ color: color.hex });
  };

  handleClose = () => {
    const { tabs, saveTab } = this.props;
    const { color, date, variable } = this.state;
    this.setState({ displayColorPicker: false });
    try {
      if (
        Object.keys(tabs.activeVariable).length === 1 &&
        Object.keys(tabs.activeData).length > 1
      ) {
        tabs.activeData[date].background = color;
      } else {
        tabs.activeVariable[variable].background = color;
      }
      saveTab(tabs, false);
    } catch (err) {
      console.warn(err);
    }
  };

  render() {
    const { serie, tabs, saveTab } = this.props;
    const { displayColorPicker, variable, date, color } = this.state;
    const { startDate, endDate } = calcRollingDate(tabs.activeData[date]);
    return (
      <ListGroupItem color={serie.timeSeriesErrors ? "warning" : ""}>
        {tabs.activeData[date] && tabs.activeVariable[variable] && (
          <div>
            <EyeIcon
              data-html2canvas-ignore
              icon={
                tabs.seriesVisible[variable][date] === undefined ||
                tabs.seriesVisible[variable][date]
                  ? faEye
                  : faEyeSlash
              }
              onClick={() => {
                tabs.seriesVisible[variable][date] = !(
                  tabs.seriesVisible[variable][date] === undefined ||
                  tabs.seriesVisible[variable][date]
                );
                saveTab(tabs);
              }}
            />
            <strong>
              {tabs.activeVariable[variable].label} [{serie.unit}]
            </strong>
            <div>
              <Swatch onClick={this.handleColorPick}>
                <Color background={color} />
              </Swatch>
              {displayColorPicker ? (
                <PopOver>
                  <Cover onClick={this.handleClose} />
                  <SketchPicker color={color} onChange={this.handleChange} />
                </PopOver>
              ) : null}

              <em>
                {startDate} {" - "} {endDate}
              </em>

              {serie.QsTotale !== false && (
                <div>
                  Tot <Badge pill>{serie.QsTotale && serie.QsTotale.toLocaleString(undefined, { maximumFractionDigits: 4 })}</Badge>
                </div>
              )}
              {serie.QsAvg !== false && (
                <div>
                  Med{" "}
                  <BadgePointer
                    pill
                    color={
                      tabs.activeVariable[variable].QsAvgPlot
                        ? "success"
                        : "secondary"
                    }
                    onClick={() => {
                      tabs.activeVariable[variable].QsAvgPlot = !tabs
                        .activeVariable[variable].QsAvgPlot;
                      saveTab(tabs, false);
                    }}
                  >
                    <EyeStats
                      icon={
                        tabs.activeVariable[variable].QsAvgPlot
                          ? faEye
                          : faEyeSlash
                      }
                    />
                    {serie.QsAvg && serie.QsAvg.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                  </BadgePointer>
                </div>
              )}
              {serie.QsMax !== false && (
                <div>
                  Max{" "}
                  <BadgePointer
                    pill
                    color={
                      tabs.activeVariable[variable].QsMaxPlot
                        ? "success"
                        : "secondary"
                    }
                    onClick={() => {
                      tabs.activeVariable[variable].QsMaxPlot = !tabs
                        .activeVariable[variable].QsMaxPlot;
                      saveTab(tabs, false);
                    }}
                  >
                    <EyeStats
                      icon={
                        tabs.activeVariable[variable].QsMaxPlot
                          ? faEye
                          : faEyeSlash
                      }
                    />
                    {serie.QsMax && serie.QsMax.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                  </BadgePointer>
                </div>
              )}
              {serie.QsMin !== false && (
                <div>
                  Min{" "}
                  <BadgePointer
                    pill
                    color={
                      tabs.activeVariable[variable].QsMinPlot
                        ? "success"
                        : "secondary"
                    }
                    onClick={() => {
                      tabs.activeVariable[variable].QsMinPlot = !tabs
                        .activeVariable[variable].QsMinPlot;
                      saveTab(tabs, false);
                    }}
                  >
                    <EyeStats
                      icon={
                        tabs.activeVariable[variable].QsMinPlot
                          ? faEye
                          : faEyeSlash
                      }
                    />
                    {serie.QsMin && serie.QsMin.toLocaleString(undefined, { maximumFractionDigits: 4 })}
                  </BadgePointer>
                </div>
              )}

              {serie.timeSeriesErrors && (
                <span>
                  <br />
                  <br />
                  <strong>
                    <Trans>Errore nel caricamento della serie</Trans>
                  </strong>
                </span>
              )}
            </div>
          </div>
        )}
      </ListGroupItem>
    );
  }
}

ChartLabel.propTypes = {
  serie: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  saveTab: PropTypes.func.isRequired
};

export default ChartLabel;
