import React, { Component } from 'react';
import 'react-dates/initialize';
import {
  SingleDatePicker,
  isInclusivelyAfterDay,
  SingleDatePickerPhrases
} from 'react-dates';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import omit from 'lodash/omit';

const HORIZONTAL_ORIENTATION = 'horizontal';
const ANCHOR_LEFT = 'left';

const propTypes = {
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,
};

const defaultProps = {
  autoFocus: false,
  initialDate: null,

  // input related props
  id: 'date',
  placeholder: 'Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDate: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  block: false,
  small: false,
  regular: false,
  verticalSpacing: undefined,
  keepFocusOnInput: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => {},

  // internationalization props
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',
  phrases: SingleDatePickerPhrases,
};

class AirBnbSingleDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: props.autoFocus,
      date: props.initialDate,
      required: props.required,
    };

    if (this.props.onGetDate) {
      this.onGetDate = this.props.onGetDate;
    }
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDateChange(date) {
    this.setState({ date });

    // Get the date value to a parent component
    if (this.onGetDate) {
      this.onGetDate(date);
    }
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialDate !== prevProps.initialDate) {
      this.onDateChange(this.props.initialDate);
    }
  }

  render() {
    const { openDirection, numberOfMonths } = this.props;
    const { focused, date, required } = this.state;

    // SingleDatePicker props to exclude (omit).
    const props = omit(this.props, [
      'autoFocus',
      'initialDate',
      'onGetDate',
      'onChange',
    ]);

    return (
      <SingleDatePicker
        {...props}
        date={date}
        focused={focused}
        onDateChange={this.onDateChange}
        onFocusChange={this.onFocusChange}
        openDirection={openDirection}
        numberOfMonths={numberOfMonths}
        required={required}
      />
    );
  }
}

AirBnbSingleDatePicker.propTypes = propTypes;
AirBnbSingleDatePicker.defaultProps = defaultProps;

export default AirBnbSingleDatePicker;
