import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { Card, Alert } from 'reactstrap';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import api from 'api';
import Spinner from 'app/common/Spinner/Spinner';
import {
  getWidgetPeriod,
  getAssociations,
  isLightColor,
  sum,
  average,
  getPeriods,
  getVariablesLabels,
  getPeriodsLabels,
  getStatisticsLabel,
  getDataRows,
  getTimeSerieSample,
  hasColumnStats,
  hasRowStats,
  getPeriodLabel
} from './TableWidgetHelper';
import { takeLastValues } from '../common';
import { numberToLocaleString } from 'app/utils/formatNumber';

class TableWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsLabels: [],
      rowsData: [],
      hasColStats: false,
      hasRowStats: false,
      hasBothStats: false,
      isLoading: null,
      error: null
    };
  }

  componentDidMount() {
    const { isEdit } = this.props;

    this.setLoadingAndLoadData();

    // Reload table data every 5 min only when NOT editing
    if (!isEdit) {
      const self = this;
      this.refreshingTable = setInterval(() => {
        self.setLoadingAndLoadData();
      }, 5 * 60 * 1000);
    }
  }

  componentDidUpdate(prevProps) {
    const { widget } = this.props;

    if (!isEqual(widget, prevProps.widget)) {
      this.setLoadingAndLoadData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshingTable);
  }

  setLoadingAndLoadData() {
    this.setState({ isLoading: true });
    this.loadTable();
  }

  loadTable() {
    const { widget, currentModule, units, setTableData } = this.props;
    const { properties, widgetType } = widget;

    properties.varPeriod =
      widget.variables.find(v => v.statistic) &&
      widget.period.rows.length === 1 &&
      (widget.period.rows[0].periodValue === 'period' ||
        widget.period.rows[0].periodValue === 'values-period')
        ? 'show'
        : 'notshow';

    const variables = this.getVariablesWithUnit(widget.variables);
    const periods = getPeriods(widget.period.rows);

    // Widget title
    this.props.widget.title = widget.title;

    // Implicit or esplicit period
    this.props.widget.titlePeriod = getWidgetPeriod(periods, widgetType, currentModule);

    const associations = getAssociations(variables, periods);
    const requests = associations.map(x => api.get(x.request));
    Promise.all(requests)
      .then(responses => {
        responses.map((res, index) => {
          if (associations[index].aggregation === '1m') {
            res.data.timeSeries = takeLastValues(res.data.timeSeries, associations[index].interval);
          }

          associations[index].response = res.data;

          // Empty timeSeries
          if (!res.data.timeSeries || res.data.timeSeries.length === 0) {
            associations[index].cellData = 0;
          } else {
            const currentVariable = associations[index].variable;
            const catalogUnit = units.find(u => u.naturalKey === currentVariable._unit);
            const conversionFactor =
              catalogUnit && catalogUnit.exactoConversionFactor
                ? catalogUnit && catalogUnit.exactoConversionFactor
                : 1;
            associations[index].response.timeSeries = res.data.timeSeries.map(ts => {
              return { ...ts, v: ts.v / conversionFactor};
            });
            const timeSeriesList = res.data.timeSeries.map(x => x.v);

            // Check properties first and then variable valueType
            let rowValue = 0;
            if (currentVariable.statistic === 'sum') {
              rowValue = sum(timeSeriesList);
            } else if (currentVariable.statistic === 'average') {
              rowValue = average(timeSeriesList);
            }
            // if (currentVariable.valueType === 'instant') {
            //   rowValue = average(timeSeriesList);
            // } else if (currentVariable.valueType === 'cumulated') {
            //   rowValue = sum(timeSeriesList);
            // } else {
            //   rowValue = average(timeSeriesList);
            // }
            // Table cell data number including statistics
            // associations[index].cellData = formatNumber(rowValue,0,1,currentVariable.valueType, currentVariable.decimalDigits);
            associations[index].cellData = rowValue;
          }
          return null;
        });

        const allTimeSeries = associations.map(assoc => assoc.response.timeSeries);
        const timeSerieSample = getTimeSerieSample(allTimeSeries);

        const variablesLabels = getVariablesLabels(variables);
        const periodsLabels = getPeriodsLabels(periods, currentModule, timeSerieSample);

        const unitMeasure = variables[0]._unit !== '' ? variables[0]._unit : variables[0].unit;
        const allLabels = this.getLabelsWithStats(
          variablesLabels,
          periodsLabels,
          properties,
          unitMeasure,
          periods
        );
        const rowFirstLabels = [...allLabels.rowsLabels];
        const dataRows = getDataRows(associations, variables, periods, properties);
        const isLabelsLengthEqualToDataRows =
          allLabels.rowsLabels &&
          dataRows.length &&
          allLabels.rowsLabels.length === dataRows.length;

        // Join data rows with rows labels
        const dataRowsWithLabel = [];
        for (let i = 0; i < dataRows.length; i++) {
          if (Array.isArray(rowFirstLabels) && rowFirstLabels[i]) {
            dataRowsWithLabel.push([rowFirstLabels[i], ...dataRows[i]]);
          }
        }

        let tableExportNames;
        let tableExportData;
        tableExportNames = [' ', ...allLabels.columnsLabels];
        tableExportData = dataRowsWithLabel.map(data =>
          data.reduce((acc, currVal, i) => {
            return i === 1
              ? {
                  [tableExportNames[0]]: acc,
                  [tableExportNames[i]]: currVal !== '-' ? currVal : ''
                }
              : {
                  ...acc,
                  [tableExportNames[i]]: currVal !== '-' ? currVal : ''
                };
          })
        );
        setTableData(tableExportData, tableExportNames);

        this.setState({
          rowsLabels: allLabels.rowsLabels,
          columnsLabels: allLabels.columnsLabels,
          rowsData: dataRowsWithLabel,
          hasColStats: hasColumnStats(properties) && isLabelsLengthEqualToDataRows,
          hasRowStats: hasRowStats(properties) && isLabelsLengthEqualToDataRows,
          hasBothStats:
            properties.varStats !== 'notshow' &&
            properties.varPeriod !== 'notshow' &&
            isLabelsLengthEqualToDataRows,
          isLoading: false,
          error: null
        });
      })
      .catch(errors => {
        const errorMessage = 'Errore caricamento dati tabella';
        console.log(errors);
        this.setState({
          isLoading: false,
          error: errorMessage
        });
      });
  }

  getVariablesWithUnit(variables) {
    const { units, physicaldimensions } = this.props;

    const updatedVar = variables.map(variable => {
      if (!units || !physicaldimensions) {
        return { ...variable };
      }

      const unit = units.find(x => x.naturalKey === variable.unit);
      const unitSymbol =
        variable.physicalQuantity === 'custom'
          ? (physicaldimensions.find(x => x.id === variable.customQuantityId) || {}).unitSymbol
          : unit.symbol;

      return { ...variable, _unit: unitSymbol };
    });

    return updatedVar;
  }

  getLabelsWithStats(variablesLabels, periodsLabels, properties, unitMeasure, periods) {
    let rowsLabels = null;
    let columnsLabels = null;
    let rowStatisticsProp = null;
    let columnStatisticsProp = null;
    if (properties.disposition === 'rowcol') {
      // Varibili su righe, periodi su colonne
      rowsLabels = [...variablesLabels];
      columnsLabels = [...periodsLabels];
      rowStatisticsProp = properties.varStats;
      columnStatisticsProp = properties.varPeriod;
    } else if (properties.disposition === 'colrow') {
      // Periodi su righe, Varibili su colonne
      rowsLabels = [...periodsLabels];
      columnsLabels = [...variablesLabels];
      rowStatisticsProp = properties.varPeriod;
      columnStatisticsProp = properties.varStats;
    } else {
      throw new Error('Errore selezione disposizione righe  colonne');
    }

    if (Array.isArray(rowsLabels)) {
      const statisticLabel =
        rowStatisticsProp === 'show' && periods.length === 1 && periods[0].periodValue === 'period'
          ? getPeriodLabel(periods[0], 'table')
          : getStatisticsLabel(rowStatisticsProp, unitMeasure);
      if (statisticLabel) {
        rowsLabels.push(statisticLabel);
      }
    }

    if (Array.isArray(columnsLabels)) {
      const statisticLabel =
        columnStatisticsProp === 'show' &&
        periods.length === 1 &&
        periods[0].periodValue === 'period'
          ? getPeriodLabel(periods[0], 'table')
          : getStatisticsLabel(columnStatisticsProp, unitMeasure);
      if (statisticLabel) {
        columnsLabels.push(statisticLabel);
      }
    }

    return {
      columnsLabels: columnsLabels ? columnsLabels : [],
      rowsLabels: rowsLabels ? rowsLabels : []
    };
  }

  getTableColorClass(centralcolumns) {
    const map = {
      colorsForRows: 'table-striped',
      colorsForColumns: 'table-bordered',
      colorsUniform: ''
    };
    return map[centralcolumns] ? map[centralcolumns] : '';
  }

  render() {
    const { widget } = this.props;
    const { properties, variables } = widget;
    const {
      columnsLabels,
      rowsData,
      hasColStats,
      hasRowStats,
      hasBothStats,
      isLoading,
      error
    } = this.state;
    const maxDecimalDigits = variables.reduce(
      (result, aVar) => (aVar.decimalDigits > result ? aVar.decimalDigits : result),
      -1
    );
    const firstRowBgColor =
      properties && properties.firstRowColor ? properties.firstRowColor : '#4A90E2';
    const columnCharColor = isLightColor(firstRowBgColor) ? '#000000' : '#ffffff';
    const headerCellStyle = {
      color: columnCharColor ? columnCharColor : '#ffffff'
    };

    const StyledTd = styled.td`
      padding: 0.2rem 0.5rem !important;
      vertical-align: middle !important;
    `;
    const StyledTh = styled.th`
      height: 1rem;
    `;
    const StyledTr = styled.tr`
      &:hover {
        background-color: rgba(31, 113, 183, 0.1) !important;
      }
    `;

    if (isLoading) {
      return (
        <Card>
          <Spinner />
        </Card>
      );
    } else if (error) {
      return (
        <Card className="p-4">
          <Alert color="danger">
            <h4>
              <Trans>{error}</Trans>
            </h4>
          </Alert>
        </Card>
      );
    }

    return (
      <>
        {rowsData && rowsData.length === 0 && (
          <div className="alert alert-info" style={{ marginLeft: '0.5%', marginRight: '0.5%' }}>
            <h3>
              <Trans>Nessun dato presente nella tabella</Trans>
            </h3>
          </div>
        )}

        {rowsData && rowsData.length > 0 && (
          <div className="table-responsive" style={{ height: 'calc(100% - 1.5rem)' }}>
            <table
              style={{ height: '100%', marginBottom: 0 }}
              className={
                'table table-responsive-sm ' +
                this.getTableColorClass(widget.properties.centralcolumns)
              }
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: firstRowBgColor,
                    fontWeight: 'bold',
                    textAlign: 'right'
                  }}
                >
                  <StyledTh scope="col">&nbsp;</StyledTh>
                  {columnsLabels &&
                    columnsLabels.map((label, i) => {
                      return (
                        <StyledTh key={`col_${i}`} style={headerCellStyle}>
                          {label}
                        </StyledTh>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {rowsData &&
                  rowsData.map((row, i, rows) => {
                    return (
                      <StyledTr>
                        {row.map((rowValue, j, cols) => {
                          const isBoldRow =
                            j === 0 ||
                            (j === cols.length - 1 && (hasBothStats || hasColStats)) ||
                            (i === rows.length - 1 && (hasBothStats || hasRowStats));
                          /*
                          const textValue =
                            j === 0 || isNaN(rowValue)
                              ? rowValue
                              : parseFloat(roundFloat(rowValue)).toLocaleString(undefined, {
                                  maximumFractionDigits: 4
                                });
                          */
                          const decimalDigits =
                            variables && variables[j - 1] && variables[j - 1].decimalDigits;
                          let textValue=' ';      
                          if (j===0) {
                            textValue = rowValue
                          }              
                          else if (!isNaN(rowValue)) {
                            textValue = numberToLocaleString(rowValue, decimalDigits == null ? maxDecimalDigits : decimalDigits);
                          }   
                          const align = j === 0 ? 'left' : 'right';
                          return isBoldRow ? (
                            <StyledTd style={{ textAlign: align }} key={'row_' + j} scope="row">
                              <strong>{textValue}</strong>
                            </StyledTd>
                          ) : (
                            <StyledTd style={{ textAlign: align }} key={'row_' + j} scope="row">
                              {textValue}
                            </StyledTd>
                          );
                        })}
                      </StyledTr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}

export default TableWidget;
