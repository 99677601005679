import api from 'api';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: ${props => props.fit || 'scale-down'};
`;

const ImageWidget = (props) => {
  const { widget } = props;
  let { content, filename, documentId } = widget;

  const contentToShow = useMemo(() => {
    if (documentId) {
      api.get(`documents/${documentId}/download`, { responseType: 'arraybuffer' }).then(res => {
        const { data, headers } = res;
        const arr = new Uint8Array(data);
        // Convert the int array to a binary string
        // We have to use apply() as we are converting an *array*
        // and String.fromCharCode() takes one or more single values, not
        // an array.
        // const raw = String.fromCharCode.apply(null, arr);
        const CHUNK_SZ = 0x8000;
        let raw = [];
        for (let i = 0; i < arr.length; i += CHUNK_SZ) {
          raw.push(String.fromCharCode.apply(null, arr.subarray(i, i + CHUNK_SZ)));
        }
        const b64 = btoa(raw.join(""));
        setCont(`data:${headers['content-type']};base64,${b64}`);
      }).catch(err => console.error(err));
    } else {
      return content;
    }
  }, [documentId])

  const [cont, setCont] = useState(contentToShow);

  return (
    <Wrapper>
      <Image fit={widget.fit} src={cont} alt={filename} />
    </Wrapper>
  );
};

export default ImageWidget;
