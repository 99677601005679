/* eslint-disable no-useless-concat */
/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
// import * as math from 'mathjs';
import { roundFloat } from '../pages/databrowsing/Functions';

/*
 * Use this calling first /Catalogs/Units
 * get (exactoConversionFactor","symbol") and use
 * for (scale,unit) in method formatNumber
 */

export const MICRO_TO_UNIT = 1e6;
export const MILLI_TO_UNIT = 1e3;
export const UNIT = 1;
export const UNIT_TO_K = 1e-3;
export const UNIT_TO_M = 1e-6;
export const UNIT_TO_T = 1e-9;

export const OP_TYPE_BINARY = "binary";


export const RGX = /(\d+)(\d{3})/;

export const getDecimalDigitFromPrefix = (prefix, digits) => {
  let suffix = '';
  if (prefix && prefix.length <= 4) {
    if (prefix.length === 0) {
      suffix = `.${digits}`;
    } else if (prefix.length === 1) {
      suffix = `.${digits}`;
    } else if (prefix.length === 2) {
      suffix = `.${digits.substring(0, 2)}`;
    } else { // ===3
      suffix = `.${digits.substring(0, 1)}`;
    }
  }
  return suffix;
};

export const formatNumberInstant = (num, offset, sca, decimalDigits) => {
  try {
    if (num === null) {
      throw 'null';
    } else if (sca === null) {
      sca = 0;
    }
    const value = (num / sca) + offset;
    if(decimalDigits != null && decimalDigits >= 0 ) {
      return parseFloat(value.toFixed(decimalDigits));
    }
    else
      return roundFloat(value, 4);

  } catch (e) {
    return 'NaN';
  }
};

export const formatNumberCumulative = (num, offset, sca, formatted) => {
  try {
    if (num === null) {
      throw 'null';
    } else if (sca === null) {
      sca = 0;
    }
    return roundFloat((num / sca) + offset, 4);

    /*
    math.config({ number: 'BigNumber', precision: 64 });
    const number = math.divide(math.bignumber(num), math.bignumber(sca));
    const roundNumber = number.round();
    const numberFloorFormatted = math.format(roundNumber, { notation: 'fixed', precision: 3 });
    return numberFloorFormatted;
    */
    /*
    if (formatted) {
      const x = numberFloorFormatted.split('.');
      let prefix = x[0];

      while (RGX.test(prefix)) {
        prefix = prefix.replace(RGX, '$1' + ',' + '$2');
      }
      return prefix + getDecimalDigitFromPrefix(x[0], x[1]);
    }

    return number;
    */
  } catch (e) {
    // console.error(e);
    return 'NaN';
  }
};

export const formatNumber = (number, offset, scale, type, decimalDigits = -1, checkBinary = false) => {
  
  let resp = null;
  if (checkBinary && type === OP_TYPE_BINARY) {
    return number ? "ON": "OFF";
  }

  if (number && scale && type) {
    resp = formatNumberInstant(number, offset, scale, decimalDigits);
  } else {
    resp = number;
  }

  return resp;
};

export const numberToLocaleString = (number, decimalDigits = -1) => {
  const options =
    decimalDigits == null || decimalDigits < 0
      ? { maximumFractionDigits: 4 }
      : { minimumFractionDigits : decimalDigits, maximumFractionDigits: decimalDigits };
  const value = typeof number === 'string' ? parseFloat(number) : number;
  return value && value.toLocaleString(undefined, options);
};