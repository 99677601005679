import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DataTable, Select, Wizard, WizardStep } from 'app/common';
import { toast } from 'react-toastify';
import api from 'api';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { getUrl } from 'app/utils/navigation';
import { editItem } from 'ducks/actions/navigation';
import { distinct } from 'app/utils/array';
import VectorList from './VectorList';
import Forecasts from './Forecasts';

const costOptions = [
  { value: 1, label: '€/mese' },
  { value: 2, label: '€/bimestre' },
  { value: 12, label: '€/anno' },
];


const SiteData = (props) => {
  const { readOnly, selectedSite, vectors, physicalQuantities, units, observableproperties, editItem, history } = props;

  const [siteData, setSiteData] = useState({});
  const [siteForecasts, setSiteForecasts] = useState([]);
  const [error, setError] = useState(null);
  const [vectorsIn, setVectorsIn] = useState([]);
  const [vectorsForecast, setVectorsForecast] = useState([]);
  const [measures, setMeasures] = useState([]);

  const { params } = selectedSite || {};
  const edit = params && params.id !== undefined;

  const getVectorsIn = () => {
    const { assetGraph } = selectedSite;
    if (assetGraph) {
      const productionAssets = assetGraph.assets.filter(x => x.inFlowIds.length === 0);
      const flowIds = [].concat(...productionAssets.map(x => x.outFlowIds));
      return assetGraph.assetFlows.filter(x => flowIds.includes(x.id) && x.vector !== 'energia-elettrica').map(x => x.vector);
    }
    return [];
  };

  const getVectorsOut = () => {
    const { assetGraph } = selectedSite;
    if (assetGraph) {
      const productionAssets = assetGraph.assets.filter(x => x.outFlowIds.length === 0);
      const flowIds = [].concat(...productionAssets.map(x => x.inFlowIds));
      return assetGraph.assetFlows.filter(x => flowIds.includes(x.id)).map(x => x.vector);
    }
    return [];
  };

  const addVectorData = (vectors) => {
    return vectors.map((vector) => {
      const physicalQuantityNaturalKey = vector.variables[0];
      const physicalQuantity = physicalQuantities.find(x => x.naturalKey === physicalQuantityNaturalKey);
      const defaultUnit = physicalQuantity.defaultVisualizationUnit || physicalQuantity.units[0];
      const _units = units.filter(x => physicalQuantity.units.includes(x.naturalKey));
      const _defaultUnit = _units.find(x => x.naturalKey === defaultUnit);
      return { ...vector, _physicalQuantities: [physicalQuantity], _defaultUnit, _units };
    });
  };

  const initVectors = () => {
    const electricEnergyVector = vectors.find(x => x.naturalKey === 'energia-elettrica');

    // Vectors flows in
    const newVectorsIn = distinct(getVectorsIn());
    setVectorsIn(addVectorData([electricEnergyVector, ...vectors.filter(x => newVectorsIn.includes(x.naturalKey))]));

    // Forecasts vectors (thermal energy out flows + electric energy)
    const newVectorsOut = distinct(getVectorsOut());
    const thermalVectorsOut = vectors.filter(x => newVectorsOut.includes(x.naturalKey)).filter(x => x.variables.includes('thermal-energy'));
    setVectorsForecast(addVectorData([electricEnergyVector, ...thermalVectorsOut]));
  };

  const initForecasts = () => {
    setSiteForecasts((selectedSite.siteForecasts || []).map(x => ({ ...x, _enabled: true })));
  };


  useEffect(() => {
    if (!selectedSite) {
      return;
    }
    setSiteData(selectedSite.params || {});
  }, [selectedSite]);


  useEffect(() => {
    if (!selectedSite) {
      return;
    }
    initVectors();
    initForecasts();
  }, [params, vectors]);


  const initMeasures = () => {
    const siteOps = observableproperties.filter(x => x.siteId === selectedSite.id);
    const pqs = ['temperature', 'relative-humidity'];
    const newMeasures = pqs.map((physicalQuantityNaturalKey) => {
      const name = physicalQuantityNaturalKey;
      const physicalQuantity = physicalQuantities.find(x => x.naturalKey === physicalQuantityNaturalKey);
      const label = i18n._(t`${physicalQuantity._label} esterna del sito`);
      const unitNaturalKey = physicalQuantity.defaultVisualizationUnit || physicalQuantity.units[0];
      const unit = units.find(x => x.naturalKey === unitNaturalKey).symbol;
      const ops = siteOps.filter(x => x.physicalQuantity === physicalQuantityNaturalKey);
      const value = ((siteData.measures || []).find(x => x.physicalQuantity === physicalQuantityNaturalKey) || {}).observedProperty;
      return { name, label, unit, ops, value };
    });
    setMeasures(newMeasures);
  };

  const changeMeasure = (e) => {
    const { name, value } = e.target;
    setMeasures(measures.map(x => (x.name === name ? { ...x, value } : x)));
  };

  const updateForecasts = async (forecasts) => {
    const currentForecastsIds = (selectedSite.siteForecasts || []).map(x => x.id);
    const newForecastsIds = forecasts.map(x => x.id);
    const toDeleteIds = currentForecastsIds.filter(x => !newForecastsIds.includes(x));
    const toAdd = forecasts.filter(x => x.id === null);
    const toEdit = forecasts.filter(x => x.id && !toDeleteIds.includes(x.id));


    toDeleteIds.forEach(id => api.delete(`/SiteForecasts/${id}`));
    const calls = [];
    toAdd.forEach(f => calls.push(api.post(`Sites/${selectedSite.id}/siteForecasts`, f)));
    toEdit.forEach(f => calls.push(api.patch(`/SiteForecasts/${f.id}`, f)));
    const results = await Promise.all(calls).catch((e) => { setError(e); toast.error(e.message); });
    const newData = results.map(x => x.data);
    return newData;
  };

  const submitSiteData = async (params) => {
    try {
      let newParams;
      if (selectedSite.params && selectedSite.params.id) {
        const res = await api.patch(`/SiteParams/${selectedSite.params.id}`, params);
        newParams = res.data;
      } else {
        const res = await api.post(`/Sites/${selectedSite.id}/params`, params);
        newParams = res.data;
      }
      const updatedSiteForecasts = await updateForecasts(siteForecasts);
      editItem('sites', { ...selectedSite, params: newParams, siteForecasts: updatedSiteForecasts });
      history.push(getUrl('/management', 'sites', selectedSite.id));
    } catch (e) {
      setError(e);
      toast.error(e.message);
    }
  };

  const cancel = () => {
    history.goBack();
  };


  const updateSiteData = (data) => {
    setSiteData({ ...siteData, ...data });
  };

  const updateSiteForecasts = (data) => {
    setSiteForecasts(data);
  };

  return (
    <Wizard
      card
      title={<Trans>Dati tecnici, operativi ed economici del sito</Trans>}
      value={siteData}
      edit={edit}
      readOnly={readOnly}
      onSave={submitSiteData}
      onCancel={cancel}
      error={error}
    >
      <WizardStep
        title={<Trans>Dati del sito</Trans>}
        label={<Trans>Configurazione dei parametri relativi al sito</Trans>}
        validatedProperties={['whiteCerticatesPrice', 'co2Penalty', 'electMaxPowerWithdrawal', 'electMaxPowerInjection', 'totAuxsHourlyNrgConsumpution']}
        onSubmit={updateSiteData}
      >
        <VectorList vectors={vectorsIn} initialValues={siteData} costOptions={costOptions} units={units} />
      </WizardStep>
      <WizardStep
        title={<Trans>Misure</Trans>}
        label={<Trans>Configurazione delle misure relative al sito</Trans>}
        onMount={initMeasures}
        getValues={() => ({ measures: measures.map(m => ({ physicalQuantity: m.name, observedProperty: m.value })) })}
        onSubmit={updateSiteData}
      >
        <DataTable
          keyField="name"
          edit
          width="100%"
          sortby="name"
          headers={[
            { title: <Trans>Nome</Trans>, property: 'label', index: 1, dataType: 'string' },
            { title: <Trans>Unità di misura</Trans>, width: '15%', property: 'unit', index: 2, dataType: 'string' },
            { title: <Trans>Misura</Trans>, width: '50%', property: 'op', index: 3, dataType: 'select', cell: row => <Select nullable name={row.name} value={row.value} placeholder={row.label} options={row.ops} valueProperty="id" labelProperty="name" disabled={readOnly} onChange={changeMeasure} /> },
          ]}
          data={measures}
          noData=""
        />
      </WizardStep>
      <WizardStep
        title={<Trans>Previsioni</Trans>}
        label={<Trans>Configurazione dei forecast (relativi ai fabbisogni energetici del sito)</Trans>}
        // onMount={initForecasts}
        onSubmit={updateSiteForecasts}
      >
        <Forecasts vectors={vectorsForecast} initialValues={siteForecasts} />
      </WizardStep>
    </Wizard>
  );
};

const mapStateToProps = (state) => {
  const { selectedSite } = state.navigation;
  const { vectors, physicalQuantities, units } = state.catalogs;
  const { observableproperties } = state.domain;
  return { selectedSite, vectors, physicalQuantities, units, observableproperties };
};

const mapDispatchToProps = dispatch => ({
  editItem: (type, item) => dispatch(editItem(type, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteData);
