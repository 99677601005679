/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable newline-per-chained-call */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from "app/common";
import PropTypes from "prop-types";
import {
  DayPickerRangeController,
  DayPickerSingleDateController
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { Trans } from "@lingui/macro";
import { TokenPropType } from "../../../utils/proptypesModels";
import { DATE_FORMAT, calcRollingDate } from "../Functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HORIZONTAL_ORIENTATION = "horizontal";
export const VERTICAL_ORIENTATION = "vertical";
export const VERTICAL_SCROLLABLE = "verticalScrollable";
export const START_DATE = "startDate";
export const END_DATE = "endDate";

const StyledWrapper = styled.div`
  .DayPicker__withBorder {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    margin-left: -18px;
  }

  .DayPicker__horizontal {
    background: none !important;
  }

  .DayPicker {
    background: none !important;
  }

  button.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .CalendarDay__selected_span {
    background: #007bff;
    border: 1px double #007bff;
  }
  .CalendarDay__selected {
    background: #1f71b7;
    border: 1px double #1f71b7;
  }
`;

const InputDate = styled(Input)`
  &&.selected {
    border: 1px solid #007bff;
  }
`;

const ModalBodyStyled = styled(ModalBody)`
  padding: 10px;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
`;

const YearSemesterButton = styled(Button)`
  width: auto;
  background-color: white !important;
  border: 1px solid #e4e7e7 !important;
  color: #757575 !important;
  margin-right:0.4rem !important;
  margin-left:0.4rem !important;

  &:hover {
    background-color: white !important;
    border: 1px solid #c4c4c4 !important;
    background-image: none !important;
  }
`;

const ArrowIconLeft = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const ArrowIconRight = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

const OptionsContainer = styled(Col)`
  height:410px; //Keep DayPickerRangeController and DayPickerSingleDateController height, to prevent the modal from shrinking during the component's loading on update
`;

class CalendarOptions extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate } = calcRollingDate(this.props.token);
    //Month displayed and modified by the day picker
    this.currentMonth = this.props.rangeCalendar ? (endDate ? moment(endDate, DATE_FORMAT).subtract(1, "month") : moment()) : startDate ? moment(startDate, DATE_FORMAT) : moment();
    this.state = {
      modalOptionVariable: true,
      focusedInput: START_DATE,
      startDate: moment(startDate, DATE_FORMAT),
      endDate: moment(endDate, DATE_FORMAT),
      rolling: this.props.token !== null ? this.props.token.rolling : false,
      rollingStartDate:
        this.props.token !== null ? this.props.token.rollingStartDate : false,
      rollingEndDate:
        this.props.token !== null ? this.props.token.rollingEndDate : false,
      rollingDiff: this.props.token !== null ? this.props.token.rollingDiff : 0,
      rollingShift:
        this.props.token !== null ? this.props.token.rollingShift : 0,
      focused: true,
      forceUpdate: 0
    };
  }

  saveCalendar = () => {
    const {
      startDate,
      endDate,
      rolling,
      rollingStartDate,
      rollingEndDate,
      rollingDiff,
      rollingShift
    } = this.state;
    if (!startDate || !endDate) return;

    this.props.saveAction(
      startDate.format(DATE_FORMAT),
      endDate.format(DATE_FORMAT),
      rolling,
      rollingStartDate,
      rollingEndDate,
      rollingDiff,
      rollingShift
    );
  };

  changeValue = options => {
    let startDate;
    let endDate;
    const rolling = true;
    let rollingStartDate = "dynamic";
    let rollingEndDate = "dynamic";
    switch (options) {
      case 1:
        startDate = moment();
        endDate = moment();
        break;

      case 2:
        startDate = moment().subtract(1, "d");
        endDate = moment().subtract(1, "d");
        break;

      case 3:
        startDate = moment().startOf("isoWeek");
        endDate = moment().endOf("isoWeek");
        rollingStartDate = "isoWeek";
        rollingEndDate = "isoWeek";
        break;

      case 4:
        startDate = moment().subtract(6, "d");
        endDate = moment();
        break;

      case 5:
        startDate = moment()
          .subtract(6, "d")
          .startOf("week");
        endDate = moment()
          .subtract(6, "d")
          .endOf("week");
        rollingStartDate = "isoWeek";
        rollingEndDate = "2isoWeek";
        break;

      case 6:
        startDate = moment().startOf("month");
        endDate = moment();
        rollingStartDate = "month";
        break;

      case 7:
        startDate = moment().subtract(29, "d");
        endDate = moment();
        break;

      case 8:
        startDate = moment()
          .startOf("month")
          .subtract(1, "months");
        endDate = moment()
          .startOf("month")
          .subtract(1, "days");
        rollingStartDate = "month";
        rollingEndDate = "2month";
        break;

      case 9:
        startDate = moment().startOf("year");
        endDate = moment();
        rollingStartDate = "year";
        break;

      case 10:
        startDate = moment().subtract(365, "d");
        endDate = moment();
        break;

      case 11:
        startDate = moment()
          .startOf("year")
          .subtract(1, "years");
        endDate = moment()
          .startOf("year")
          .subtract(1, "days");
        rollingStartDate = "year";
        rollingEndDate = "2year";
        break;

      default:
        break;
    }

    const newState = {
      startDate,
      endDate,
      rolling,
      rollingStartDate,
      rollingEndDate,
      rollingDiff: endDate.diff(startDate, "days"),
      rollingShift: moment().endOf("day").diff(endDate, "days")
    };
    
    this.setState(newState);
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate,
      endDate,
      rolling: false,
      rollingStartDate: "dynamic",
      rollingEndDate: "dynamic",
      rollingDiff: 0,
      rollingShift: 0
    });
  };

  onFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? START_DATE : focusedInput
    });
  };

  onSingleDateChange = startDate => {
    const endDate = startDate;
    this.setState({
      startDate,
      endDate,
      rolling: true,
      rollingStartDate: "dynamic",
      rollingEndDate: "dynamic"
      // rollingDiff: endDate.diff(startDate, "days"),
      // rollingShift: moment().endOf("day").diff(endDate, "days")
    });
  };

  onSingleFocusChange = () => {
    this.setState({ focused: true });
  };

  nextYear = () => {
    this.currentMonth = moment(this.currentMonth).add(1, "year");
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }

  previousYear = () => {
    this.currentMonth = moment(this.currentMonth).subtract(1, "year");
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }

  nextSemester = () => {
    this.currentMonth = moment(this.currentMonth).add(6, "month");
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }

  previousSemester = () => {
    this.currentMonth = moment(this.currentMonth).subtract(6, "month");
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }

  nextMonth = () => {
    this.currentMonth = moment(this.currentMonth).add(1, "month")
  }

  previousMonth = () => {
    this.currentMonth = moment(this.currentMonth).subtract(1, "month");
  }

  goToStartDate = () => {
    this.currentMonth = moment(this.state.startDate);
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }

  goToEndDate = () => {
    this.currentMonth = moment(this.state.endDate).subtract(1, "month");
    this.setState(prevState => ({
      forceUpdate: prevState.forceUpdate + 1
    }));
  }


  render() {
    const {
      modalOptionVariable,
      startDate,
      endDate,
      focusedInput,
      focused, 
      forceUpdate
    } = this.state;
    const { rangeCalendar } = this.props;

    return (
      <div>
        <Modal size="lg" centered isOpen={modalOptionVariable}>
          <ModalHeader toggle={() => this.props.dismissAction(false)}>
            {rangeCalendar ? <Trans>Seleziona periodo</Trans> :
            <Trans>Seleziona la data di inizio del periodo</Trans>}
          </ModalHeader>
          <ModalBodyStyled>
            {rangeCalendar && (
              <Row>
                <Col md="9">
                  <Row>
                    <Col md="6">
                      <InputDate
                        className={
                          focusedInput === START_DATE ? "selected" : null
                        }
                        value={startDate && startDate.format(DATE_FORMAT)}
                        readOnly
                        onClick={this.goToStartDate}
                      />
                    </Col>
                    <Col md="6">
                      <InputDate
                        className={
                          focusedInput === END_DATE ? "selected" : null
                        }
                        value={endDate && endDate.format(DATE_FORMAT)}
                        readOnly
                        onClick={this.goToEndDate}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <StyledWrapper>
                        <ButtonsContainer>
                          <div>
                          <YearSemesterButton onClick={() => this.previousYear()}>
                            <ArrowIconLeft icon="angle-left" />
                            <Trans>1 anno</Trans>
                          </YearSemesterButton>
                          <YearSemesterButton onClick={() => this.previousSemester()}>
                            <ArrowIconLeft icon="angle-left" />
                            <Trans>6 mesi</Trans>
                          </YearSemesterButton>
                          </div>
                          <div>
                          <YearSemesterButton onClick={() => this.nextSemester()}>
                            <Trans>6 mesi</Trans>
                            <ArrowIconRight icon="angle-right" />
                          </YearSemesterButton>
                          <YearSemesterButton onClick={() => this.nextYear()}>
                            <Trans>1 anno</Trans>
                            <ArrowIconRight icon="angle-right" />
                          </YearSemesterButton>
                          </div>
                        </ButtonsContainer>
                        <DayPickerRangeController
                          key={forceUpdate} //update on forceUpdate change
                          minimumNights={0}
                          numberOfMonths={2}
                          onPrevMonthClick={this.previousMonth}
                          onNextMonthClick={this.nextMonth}
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={this.onFocusChange}
                          initialVisibleMonth={() => this.currentMonth}
                        />
                      </StyledWrapper>
                    </Col>
                  </Row>
                </Col>
                <Col md="3">
                  <Button
                    onClick={() => this.changeValue(1)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Oggi</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(2)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Ieri</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(3)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Settimana corrente</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(4)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>
Ultimi{7}
{' '}
giorni
</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(5)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Settimana scorsa</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(6)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Questo mese</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(7)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>
Ultimi{30}
{' '}
giorni
</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(8)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Mese scorso</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(9)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Quest'anno</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(10)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>
Ultimi{365}
{' '}
giorni
</Trans>
                  </Button>
                  <Button
                    onClick={() => this.changeValue(11)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Anno scorso</Trans>
                  </Button>
                </Col>
              </Row>
            )}

            {!rangeCalendar && (
              <Row>
                <OptionsContainer md="9">
                  <Row>
                    <Col md="12">
                      <InputDate
                        value={startDate && startDate.format(DATE_FORMAT)}
                        readOnly
                        onClick={this.goToStartDate}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <StyledWrapper>
                        <ButtonsContainer>
                          <div>
                          <YearSemesterButton onClick={() => this.previousYear()}>
                            <ArrowIconLeft icon="angle-left" />
                            <Trans>1 anno</Trans>
                          </YearSemesterButton>
                          <YearSemesterButton onClick={() => this.previousSemester()}>
                            <ArrowIconLeft icon="angle-left" />
                            <Trans>6 mesi</Trans>
                          </YearSemesterButton>
                          </div>
                          <div>
                          <YearSemesterButton onClick={() => this.nextSemester()}>
                            <Trans>6 mesi</Trans>
                            <ArrowIconRight icon="angle-right" />
                          </YearSemesterButton>
                          <YearSemesterButton onClick={() => this.nextYear()}>
                            <Trans>1 anno</Trans>
                            <ArrowIconRight icon="angle-right" />
                          </YearSemesterButton>
                          </div>
                        </ButtonsContainer>
                        <DayPickerSingleDateController
                          key={forceUpdate} //update on forceUpdate change
                          date={startDate} // momentPropTypes.momentObj or null,
                          onPrevMonthClick={this.previousMonth}
                          onNextMonthClick={this.nextMonth}
                          onDateChange={this.onSingleDateChange}
                          onFocusChange={this.onSingleFocusChange}
                          focused={focused}
                          initialVisibleMonth={() => this.currentMonth}
                        />
                      </StyledWrapper>
                    </Col>
                  </Row>
                </OptionsContainer>
                <Col md="3">
                  <Button
                    onClick={() => this.changeValue(1)}
                    color="primary"
                    size="sm"
                    block
                  >
                    <Trans>Oggi</Trans>
                  </Button>
                </Col>
              </Row>
            )}
          </ModalBodyStyled>
          <ModalFooter>
            <Button color="primary" onClick={this.saveCalendar}>
              Ok
            </Button>
            <Button
              color="link"
              onClick={() => this.props.dismissAction(false)}
            >
              <Trans>Annulla</Trans>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CalendarOptions.propTypes = {
  dismissAction: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  token: TokenPropType,
  rangeCalendar: PropTypes.bool.isRequired
};

CalendarOptions.defaultProps = {
  token: null
};

export default CalendarOptions;
