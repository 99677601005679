import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, Modal, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Select } from 'app/common';

const StyledModal = styled(Modal)`
  && {
    max-width: 60rem;
  }
`;

const Toolbar = styled.div`

`;

const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: .75rem;
    padding: .5rem 1rem;
    font-weight: 500;
    margin-right: .5rem;
  }
`;

const DashboardNameInput = styled(Input)`
  && {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1;
    height: auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const EditLink = styled(Link)`
  margin-left: .5rem;
  line-height: 1;
`;

const GridContainer = styled.div`
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .2s;
  ${props => props.isFullscreen && 'padding: .8rem'};

  &::-webkit-scrollbar {
    width: .4rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.navbar.borderColor};
  }
`;


const ErrorMessage = styled.span`
  color: #f00;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .3rem 0;

  & img {
    max-width: 1rem;
    max-height: 1rem;
    margin-right: .8rem;
  }

  & span {
    color: ${props => props.theme.brandPrimaryColor};
  }
`;

const DashboardDropdownToggle = styled(DropdownToggle)`
  &&& {
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: .3rem !important;

    & span {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1;
    }
  }
`;

const CaretIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
  transition: all .1s;
  ${props => props.isOpen && 'transform: rotate(180deg)'};
`;

const BackLink = styled(Link)`
  text-transform: uppercase;
  font-size: .75rem;

  & svg {
    margin-right: .5rem;
  }
`;

const StretchLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: stretch;
`;

const StyledSelect = styled(Select)`
  && {
    min-width: 12rem;
    margin-right: 1rem;
  }
`;

export {
    StyledModal,
    Toolbar,
    StyledDropdownToggle,
    DashboardNameInput,
    Title,
    Header,
    EditLink,
    GridContainer,
    ErrorMessage,
    Item,
    DashboardDropdownToggle,
    CaretIcon,
    BackLink,
    StretchLink,
    StyledSelect
}