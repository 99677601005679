import React, { useState, useRef } from 'react';
import GridLayout from 'react-grid-layout';
import useInterval from 'app/hooks/useInterval';
import Widget from './widgets/Widget';
import 'react-grid-layout/css/styles.css';


const Grid = (props) => {
  const { saveWidget, widgets, additionalData, currentModule, shouldUpdateWidgets, edit, onEdit, onDelete, onLayoutChange, userCanSendCommand, toggleFullscreen, isFullscreen } = props;

  const layout = (Array.isArray(widgets) ? widgets : []).map(x => ({ i: x.key, ...x.layout, static: !edit }));

  const ref = useRef(null);

  const [width, setWidth] = useState(null);

  const measureWidth = () => {
    if (!ref.current || !ref.current.getBoundingClientRect().width) return;
    setWidth(ref.current.getBoundingClientRect().width - 1);
  };

  useInterval(() => {
    measureWidth();
  }, 500);


  const rowHeight = width / 12;
  // If the browser is firefox do not use useCSSTransforms (which is 6x faster) because tooltip position in C3 charts doesn't work correctly with "transform: translate(x, y)"
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const useCSSTransforms = !isFirefox;

  return (
    <div ref={ref}>
      <GridLayout className="layout" compactType="vertical" useCSSTransforms={useCSSTransforms} containerPadding={[0, 10]} layout={layout} cols={12} rowHeight={rowHeight} width={width} onLayoutChange={onLayoutChange} >
        {(Array.isArray(widgets) ? widgets : []).map(widget => <Widget saveWidget={saveWidget} additionalData={additionalData} currentModule={currentModule} key={widget.key} shouldUpdateWidgets={shouldUpdateWidgets} edit={edit} widget={widget} onEdit={onEdit} onDelete={onDelete} userCanSendCommand={userCanSendCommand} toggleFullscreen={() => toggleFullscreen()} isFullscreen={isFullscreen}/>)}
      </GridLayout>
    </div>
  );
};

export default Grid;
