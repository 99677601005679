import React from 'react';
import { connect } from 'react-redux';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { Tag } from 'app/common';
import Popup from './Popup';
import { translateDetail, translateDetailDescription, translateSeverity, translateTags } from './CatalogsTranslate';

const Header = styled.div`
  font-size: .9rem;
  color: ${props => props.theme.common.text.headerColor};
  font-weight: bold;
`;
const UppercaseHeader = styled(Header)`
  text-transform: uppercase;
`;
const Content = styled.div`
  font-size: .9rem;
`;
const CustomRow = styled(Row)`
  margin-bottom: 15px;
  line-height: initial;
`;
const Col3 = styled(Col)`
  flex: 0 0 25%;
  max-width: 25% !important;
`;
const InfoContainer = styled.div`
  padding: .5rem 2rem;
  background-color: #F8F8F8;
  margin: 0 -1rem 1rem;
`;
const AddInfoContainer = styled(InfoContainer)`
  background-color: transparent !important;
  margin-bottom: -0.5rem !important;
`;
const TransitionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1rem;
  margin-left: -0.5rem;
`;
const TransitionTitle = styled.h5`
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: bold;
`;

const EventDetails = (props) => {

    const { closeDetail, alarm, catalogs } = props;
    const idEvento = alarm.id;
    const title = i18n._(t`Dettagli evento ${idEvento}`);

    const renderSingleEvent = (_alarm) => {
        return (
            <div>
                <CustomRow>
                    <Col>
                        <Header>
                            <Trans>Allarme</Trans>
                        </Header>
                        <Content>
                            {translateDetail(_alarm.name, _alarm.alarm, catalogs)}
                        </Content>
                    </Col>
                    <Col>
                        <UppercaseHeader>
                            <Trans>ID</Trans>
                        </UppercaseHeader>
                        <Content>
                            {_alarm.id}
                        </Content>
                    </Col>
                    <Col>
                        <Header>
                            <Trans>Descrizione</Trans>
                        </Header>
                        <Content>
                            {translateDetailDescription(_alarm.description, catalogs)}
                        </Content>
                    </Col>
                    <Col>
                        <Header>
                            <Trans>Condizione</Trans>
                        </Header>
                        <Content>
                            {_alarm.condition}
                        </Content>
                    </Col>
                </CustomRow>
                <CustomRow>
                    <Col>
                        <Header>
                            <Trans>Impianto</Trans>
                        </Header>
                        <Content>
                            {_alarm.rootEntity}
                        </Content>
                    </Col>
                    <Col>
                        <Header>
                            <Trans>Entità</Trans>
                        </Header>
                        <Content>
                            {_alarm.entity}
                        </Content>
                    </Col>
                    <Col>
                        <Header>
                            <Trans>Criticità</Trans>
                        </Header>
                        <Content>
                            {translateSeverity(_alarm.severity, catalogs)}
                        </Content>
                    </Col>
                    <Col>
                        <Header>
                            <Trans>Tag</Trans>
                        </Header>
                        <Content>
                            {_alarm.tags.map(t => <Tag>{translateTags(t, catalogs)}</Tag>)}
                        </Content>
                    </Col>
                </CustomRow>
                <CustomRow>
                    <Col3>
                        <Header>
                            <Trans>Data ed orario</Trans>
                        </Header>
                        <Content>
                            {new Date(_alarm.occurred ? _alarm.occurred : _alarm.begin).toLocaleString()}
                        </Content>
                    </Col3>
                    {_alarm.detailsEvent && _alarm.detailsEvent.length < 2 && !_alarm.final &&
                        <Col3>
                            <Header>
                                <Trans>Stato corrente</Trans>
                            </Header>
                            <Content>
                                {_alarm.severity === 'info' ? '-' : <Trans>Attivo</Trans>}
                            </Content>
                        </Col3>
                    }
                    {_alarm.partial &&
                        <Col3>
                            <Header>
                                <Trans>Durata parziale</Trans>
                            </Header>
                            <Content>
                                {_alarm.partial}
                            </Content>
                        </Col3>
                    }
                </CustomRow>
            </div>
        )
    };

    const renderChain = (chain) => {
        let eventList = [];
        let transition;
        let statusAndTime =
            <CustomRow>
                <Col3>
                    <Header>
                        <Trans>Stato corrente</Trans>
                    </Header>
                    <Content>
                        {getCurrentState(chain.detailsEvent[1])}
                    </Content>
                </Col3>
                <Col3>
                    <Header>
                        <Trans>Durata totale</Trans>
                    </Header>
                    <Content>
                        {dateDiff(new Date(chain.detailsEvent[1].occurred), new Date(chain.begin))}
                    </Content>
                </Col3>
            </CustomRow>
        if (chain.detailsEvent.length > 0 && chain.detailsEvent.filter(te => te.severity === '----' && te.type === '--statechange--').length > 0) {
            const transitions = chain.detailsEvent.filter(te => te.severity === '----' && te.type === '--statechange--');
            transition =
                <>
                    <TransitionHeader>
                        <TransitionTitle><Trans>Gestione manuale dell'allarme</Trans></TransitionTitle>
                    </TransitionHeader>
                    <CustomRow>
                        <Col3>
                            <Header>
                                <Trans>Operazione</Trans>
                            </Header>
                            {transitions.reverse().map(transition => <Content>{getTransitionState(transition)}</Content>)}
                        </Col3>
                        <Col3>
                            <Header>
                                <Trans>Esecutore transizione</Trans>
                            </Header>
                            {transitions.reverse().map(transition => <Content>{transition.user ? transition.user.name : transition.agentName}</Content>)}
                        </Col3>
                        <Col3>
                            <Header>
                                <Trans>Data e orario</Trans>
                            </Header>
                            {transitions.reverse().map(transition => <Content>{new Date(transition.occurred).toLocaleString()}</Content>)}
                        </Col3>
                    </CustomRow>
                </>
        }
        chain.detailsEvent.filter(al => al.name !== '' && al.type !== '--statechange--').forEach((alarm, i) => {
            alarm.partial = i > 0 && dateDiff(new Date(alarm.occurred), new Date(chain.begin));
            alarm.rootEntity = chain.rootEntity;
            alarm.entity = chain.entity;
            eventList.push(
                <InfoContainer>
                    {renderSingleEvent(alarm, true)}
                </InfoContainer>
            );
        });
        const addInfo =
            <AddInfoContainer>
                {statusAndTime}
                {transition && transition}
            </AddInfoContainer>
        eventList.push(addInfo)
        return eventList;
    }

    const renderContent = () => {
        return alarm.detailsEvent && alarm.detailsEvent.length > 1 ? renderChain(alarm) : renderSingleEvent(alarm);
    };

    const getTransitionState = _transition => {
        let state;
        switch (_transition.state) {
            case 'WK-1':
                state = _transition.auto ? <Trans>Attivo</Trans> : <Trans>Riattivato manualmente</Trans>;
                break;
            case 'WK-2':
                if (!_transition.auto) {
                    state = <Trans>Chiuso manualmente</Trans>;
                } else {
                    state = _transition.type === '--statechange--' ? <Trans>Disabilitazione o cancellazione della sorgente di allarme</Trans> : <Trans>Chiuso</Trans>;
                }
                break;
            default: state = _transition.state;
                break;
        }
        return state;
    }

    const getCurrentState = event => {
        if (event.final && !event.auto) {
            return <Trans>Chiuso manualmente</Trans>
        }
        return event.final ? <Trans>Chiuso</Trans> : <Trans>Attivo</Trans>
    }

    const dateDiff = (d1, d2) => {
        let second = d1.getTime() - d2.getTime();
        second /= 1000;
        let minute = parseInt(second / 60);
        second = parseInt(second - minute * 60)
        let hour = parseInt(minute / 60);
        minute = minute - hour * 60;
        let days = parseInt(hour / 24);
        hour = hour - days * 24;
        days = days === 0 ? "" : `${days}d `;
        second = second < 10 ? `0${second}` : second;
        minute = minute < 10 ? `0${minute}` : minute;
        hour = hour < 10 ? `0${hour}` : hour;
        return `${days}${hour}:${minute}:${second}`
    }

    return <Popup
        size="xl"
        modalContainer={renderContent()}
        onUndo={() => closeDetail(alarm)}
        modalTitle={title}
        exitText={<Trans>Chiudi</Trans>}
        undoColor="link" />
}

const mapStateToProps = (state) => {
    const { catalogs } = state;
    return { catalogs };
};

export default connect(mapStateToProps)(EventDetails);