/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicForm, Wizard, WizardStep } from 'app/common';
import api from 'api';
import { getUrlFromPath } from 'app/utils/navigation';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import FieldInterfaces from './FieldInterfaces';
import GatewaysContext from '../GatewaysContext';
import { Roles } from 'app/utils/auth';

const EditGateway = props => {
  const { edit, readOnly, match, companies, sites, selectedCompany, selectedSite, history, userCurrentRoles } = props;
  const { url } = match;
  const { gatewayid } = match.params;

  const [fieldGateway, setFieldGateway] = useState({});
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [companySites, setCompanySites] = useState([]);
  const [error, setError] = useState(null);

  const { fieldGateways, onAddGateway, onEditGateway } = useContext(GatewaysContext);

  const initialize = async () => {
    // If in edit, select gateway, else fill company and/or site according to selected ones
    if (gatewayid) {
      const selectedGateway = fieldGateways && fieldGateways.find(x => x.id === +gatewayid);
      setFieldGateway(selectedGateway);
    } else if (selectedSite) {
      setFieldGateway({ siteId: selectedSite.id, site: selectedSite });
    } else if (selectedCompany) {
      setFieldGateway({ site: { companyId: selectedCompany.id } });
    }
  };

  useEffect(() => {
    initialize();
  }, [gatewayid, fieldGateways, selectedSite, selectedCompany]);

  // Set the sites for the select
  const loadCompanySites = companyId => {
    setCompanySites(sites.filter(x => x.companyId === companyId));
  };

  useEffect(() => {
    if (fieldGateway && fieldGateway.site) {
      loadCompanySites(fieldGateway.site.companyId);
    }
  }, [fieldGateway, sites]);

  const getGatewayBySerial = async serial => {
    try {
      const gateway = await api.get(
        `/gateways?filter={"where":{"serialNumber":"${serial}"},"include":{"fieldGateway":"site"}}`
      );
      if (gateway.data.length === 0) {
        return null;
      }
      const g = gateway.data[0];
      return g;
    } catch (e) {
      return null;
    }
  };

  const submitGateway = async gateway => {
    const queryParameters = new URLSearchParams(window.location.search);
    const filter = queryParameters.get("fromFilter");
    try {
      if (gateway.id) {
        const res = await api.patch(`/FieldGateways/${gateway.id}`, gateway);
        onEditGateway(res.data.id);
        history.push(getUrlFromPath('/devices/instruments' + (filter ? ('?filter=' + filter + "&id=" + res.data.id) : "") + "#" + res.data.id));
      } else {
        const res = await api.post(`/Sites/${gateway.siteId}/FieldGateways`, gateway);
        onAddGateway(res.data.id);
        history.push(getUrlFromPath('/devices/instruments' + (filter ? ('?filter=' + filter + "&id=" + res.data.id) : "") + "#" + res.data.id));
      }
    } catch (error) {
      setError(error);
    }
  };

  const cancel = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const filter = queryParameters.get("fromFilter");
    history.push(getUrlFromPath('/devices/instruments') + (filter ? ('?filter=' + filter + "&id=" + gatewayid) : "") + "#" + gatewayid);
  };

  const gatewayName = fieldGateway ? fieldGateway.name : '';



  return (
    <Wizard
      card
      title={edit ? <Trans>Modifica {gatewayName}</Trans> : <Trans>Nuovo gateway</Trans>}
      value={fieldGateway}
      edit={edit}
      buttonsDisabled={buttonsDisabled}
      onStepSubmit={data => setFieldGateway({ ...fieldGateway, ...data })}
      onSave={submitGateway}
      onCancel={cancel}
      error={error}
      readOnly={readOnly}
      url={url}
      isAuthorized={userCurrentRoles.includes(Roles.OPE_Commissioning)}
    >
      <WizardStep
        title={<Trans>Impostazioni generali</Trans>}
        label={<Trans>Dati identificativi del gateway</Trans>}
        validatedProperties={['gatewayId']}
      >
        <DynamicForm
          hideButtons
          initialValues={fieldGateway}
          error={error}
          readOnly={readOnly}
          fields={[
            {
              name: 'name',
              type: 'text',
              label: <Trans>Nome gateway</Trans>,
              validation: { required: true, minLength: 3 },
            },
            {
              name: 'gateway.serialNumber',
              type: 'text',
              label: <Trans>Numero seriale</Trans>,
              md: 6,
              validation: {
                required: true,
                func: (serial, values) => {
                  if (values._serialNotValid) {
                    return new Error(i18n._(t`Numero seriale non valido`));
                  }
                  if (values._alreadyCommissioned) {
                    return Error(i18n._(t`Gateway già assegnato`));
                  }
                  return true;
                }
              },
              validationOptions: { validateOnBlur: true },
              // onChange: () => this.setState({ buttonsDisabled: true }),
              onBlur: async (serial, values) => {
                try {
                  setButtonsDisabled(true);
                  const g = await getGatewayBySerial(serial);
                  values._serialNotValid = false;
                  values._alreadyCommissioned = false;
                  if (!g) {
                    // gateway not found
                    values._serialNotValid = true;
                    values.gateway = {};
                  } else if (
                    g.fieldGateway &&
                    (g.fieldGateway.length === 0 || !g.fieldGateway[0].site)
                  ) {
                    // gateway found and not commissioned
                    values.gateway = g;
                    values.gatewayId = g.id;
                  } else {
                    // gateway found and commissioned
                    const siteId = g.fieldGateway[0].siteId;
                    if (sites.some(x => x.id === siteId)) {
                      // gateway commissioned in a site visible to the user
                      values._alreadyCommissioned = true;
                      values.gateway = g;
                    } else {
                      // gateway commissioned in a site not visible to the user
                      values._serialNotValid = true;
                      values.gateway = {};
                    }
                  }
                  setButtonsDisabled(false);
                } catch (e) {
                  throw e;
                }
              }
            },
            { name: 'gateway.id', type: 'number', hidden: true, md: 6 },
            {
              name: 'gateway.make',
              type: 'text',
              label: <Trans>Marca</Trans>,
              disabled: true,
              md: 6
            },
            {
              name: 'gateway.model',
              type: 'text',
              label: <Trans>Modello</Trans>,
              disabled: true,
              md: 6
            },
            {
              name: 'gateway.productCode',
              type: 'text',
              md: 6,
              label: <Trans>Codice prodotto</Trans>,
              disabled: true
            },
            { name: 'site', type: 'object', hidden: true },
            {
              name: 'site.companyId',
              type: 'select',
              label: <Trans>Azienda di installazione</Trans>,
              md: 6,
              options: companies,
              valueProperty: 'id',
              labelProperty: 'name',
              onChange: (company, values) => {
                loadCompanySites(company);
                values.siteId = undefined;
              },
              validation: { required: true }
            },
            {
              name: 'siteId',
              type: 'select',
              label: <Trans>Sito di installazione</Trans>,
              md: 6,
              options: companySites,
              valueProperty: 'id',
              labelProperty: 'name',
              validation: { required: true }
            },
            {
              name: 'notes',
              type: 'textarea',
              label: <Trans>Note</Trans>,
              props: {
                rows: 4,
                placeholder: i18n._(
                  t`Qui è possibile indicare presso quale ambiente, armadio o quadro è stato installato il Gateway e inserire altre annotazioni utili per la gestione e manutenzione dell'apparato.`
                )
              }
            },
            { name: 'gatewayId', type: 'number', hidden: true },
            { name: '_serialNotValid', type: 'boolean', hidden: true },
            { name: '_alreadyCommissioned', type: 'boolean', hidden: true },
            { name: 'gateway.configurationOptions', type: 'custom', hidden: true }
          ]}
        />
      </WizardStep>
      <WizardStep
        title={<Trans>Interfacce di campo</Trans>}
        label={<Trans>Configurazione di canali e interfacce di connessione ai dispositivi</Trans>}
      >
        <FieldInterfaces
          readOnly={readOnly}
          isNew={gatewayid === undefined}
          fieldGateway={fieldGateway}
        /> 
      </WizardStep>
    </Wizard>
  );
};

const mapStateToProps = state => {
  const { navigation } = state;
  const { companies, sites, selectedCompany, selectedSite } = navigation;
  const { userCurrentRoles } = state.auth;

  return { companies, sites, selectedCompany, selectedSite, userCurrentRoles };
};

export default connect(mapStateToProps)(EditGateway);
