import moment from 'moment';
import { groupBy, mapValues } from 'lodash';
import { getStartDate, getEndDate, roundDateByAggregation, getAggregationInMilliseconds } from '../common';

const getDifferenceInUnit = (difference, unit) => {
  switch (unit) {
    case 'seconds':
      return difference;
    case 'minutes':
      return difference / 60;
    case 'hours':
      return difference / (60 * 60);
    case 'days':
      return difference / (60 * 60 * 24);
    case 'months':
      return difference / (60 * 60 * 24 * 30);
    default:
      return difference;
  }
};

const getBestIncrement = (difference, increments, maxTicks) => {
  let currentIndex = increments.length - 1;
  while (currentIndex >= 0 && difference / increments[currentIndex] < maxTicks) {
    currentIndex--;
  }
  if (currentIndex === increments.length - 1) {
    currentIndex--;
  }
  return increments[currentIndex + 1];
};

export const getIncrement = (startDate, endDate, maxTicks) => {
  let unit;
  let increments;
  let format;
  const difference = (endDate - startDate) / 1000; // difference in seconds
  if (difference < 60) { // 1 minute
    unit = 'seconds';
    increments = [1, 4, 15, 30, 60];
    format = '%H:%M:%S';
  } else if (difference < 60 * 60) { // 1 hour
    unit = 'minutes';
    increments = [1, 5, 10, 15, 30, 60];
    format = '%H:%M';
  } else if (difference < 24 * 60 * 60) { // 1 day
    unit = 'hours';
    increments = [1, 2, 3, 4, 6, 12, 24];
    format = '%H:%M';
  } else if (difference < 31 * 24 * 60 * 60) { // 1 month
    unit = 'days';
    increments = [1, 2, 3, 5, 10, 30];
    format = '%a %d';
  } else {
    unit = 'months';
    increments = [1, 2, 3, 4, 6, 12];
    format = '%b %Y';
  }

  const differenceInUnit = getDifferenceInUnit(difference, unit);
  const increment = getBestIncrement(differenceInUnit, increments, maxTicks);

  return { increment, unit, format };
};

export const getTicks = (dates, maxTicks) => {
  if (dates.length <= 1) {
    return [];
  }

  const startDate = dates[1];
  const endDate = dates[dates.length - 1];
  const { increment, unit, format } = getIncrement(startDate, endDate, maxTicks);

  const ticks = [];
  let currentDate = moment(startDate).startOf(unit.slice(0, unit.length - 1)).toDate();

  while (currentDate <= endDate) {
    if (currentDate >= startDate) {
      ticks.push(currentDate);
    }
    currentDate = moment(currentDate).add(increment, unit).toDate();
  }

  return { values: ticks, format };
};

const timeHole = (dates, date, difference) => {
  const lastDate = dates[dates.length - 1];

  if (!lastDate || dates.length <= 2) {
    return false;
  }

  if (date - lastDate > 2 * difference) {
    return true;
  }
  return false;
};


const getMinInterval = (data) => {
  return data.reduce((min, value, i, array) => {
    if (i === 0) {
      return undefined;
    }
    const diff = value.t - array[i - 1].t;
    if (!min || (diff > 0 && diff < min)) {
      return diff;
    }
    return min;
  }, undefined);
};

export const getColumns = (data, period) => {
  const dates = ['x'];
  const values = data.map(x => [x.id]);
  const indexes = {};
  const names = {};
  const colors = {};
  const types = {};
  const axes = {};
  let axis = {};

  const pushDateWithEmptyValues = (date) => {
    dates.push(date);
    values.forEach((col) => {
      col.push(null);
    });
  };

  const units = data.map(x => x.unit).reduce((unitsArray, unit) => {
    if (!unitsArray.includes(unit)) {
      unitsArray.push(unit);
    }
    return unitsArray;
  }, []);

  data.forEach((serie, i) => {
    const { seriesType } = serie.properties;
    const type = seriesType.split('_')[0];

    indexes[serie.id] = i;
    names[serie.id] = serie.label;
    colors[serie.id] = serie.color;
    types[serie.id] = type;

    if (serie.unit === units[0]) {
      axes[serie.id] = 'y';
      axis = { ...axis, y: { show: true, label: { text: serie._unit, position: 'outer-top' } } };
    } else {
      axes[serie.id] = 'y2';
      axis = { ...axis, y2: { show: true, label: units.length === 2 ? { text: serie._unit, position: 'outer-top' } : undefined } };
    }
  });

  // const stackedBars = data.filter(x => x.properties.seriesType.endsWith('_stacked') && x.properties.seriesType.startsWith('area')).map(x => x.id);
  const stackedBars = data.filter(x => x.properties.seriesType === 'bar_stacked');
  const groupedBars = Object.values(mapValues(groupBy(stackedBars, 'unit'), array => array.map(serie => serie.id)));

  const stackedAreas = data.filter(x => x.properties.seriesType === 'area_stacked');
  const groupedAreas = Object.values(mapValues(groupBy(stackedAreas, 'unit'), array => array.map(serie => serie.id)));

  const stackedAreaSplines = data.filter(x => x.properties.seriesType === 'area-spline_stacked');
  const groupedAreaSplines = Object.values(mapValues(groupBy(stackedAreaSplines, 'unit'), array => array.map(serie => serie.id)));

  const groups = [...groupedBars, ...groupedAreas, ...groupedAreaSplines];

  let allData = data.map(x => x.items.map(v => ({ ...v, id: x.id }))).flat().sort((a, b) => a.t - b.t);

  const minInterval = period.aggregation === 'raw' ? getMinInterval(allData) : getAggregationInMilliseconds(period.aggregation);
  const endDate = getEndDate(new Date(), period.interval, period.aggregation);
  const startDate = roundDateByAggregation(getStartDate(new Date(), period.interval, moment(period.aggregation_date)), period.aggregation);
  
  if (allData.length > 0 && period.aggregation === '1m') {
    const lastDate = allData[allData.length - 1].t;
    let numberOfMinutes;
    switch (period.interval) {
      case '5m':
        numberOfMinutes = 5;
        break;
      case '15m':
        numberOfMinutes = 15;
        break;
      case '1h':
        numberOfMinutes = 60;
        break;
      case '3h':
        numberOfMinutes = 180;
        break;
      default:
    }
    if (numberOfMinutes) {
      const startDateNew = moment(lastDate).add(-numberOfMinutes, 'minutes').toDate();
      allData = allData.filter(x => x.t > startDateNew);
    }
  }
// add a null measure at the start date if no measure has startDate itimestamp
// to include startDate in the graph
  const exceptedPeriods = ['12h','24h','today','yesterday'];

  if (allData.length === 0 || (allData[0].t > startDate && !exceptedPeriods.includes(period.interval))) {
    pushDateWithEmptyValues(startDate);
  }


  allData.forEach((value) => {
    const { id, t, v } = value;
    // if the date is new, add it to the dates array and add a null value to every series that does not have a value for the previous date
    // disabled: bug
    if (timeHole(dates, t, minInterval)) {
       pushDateWithEmptyValues(moment(t).add(-1, 's').toDate());
    }
    
    if (dates.length === 1 || t > dates[dates.length - 1]) {
      dates.push(t);
      values.forEach((col) => {
        if (col.length < dates.length - 1) {
          col.push(null);
        }
      });
    }
    values[indexes[id]].push(typeof v === 'boolean' ? Number(v) : v);
  });

  /*
  if (endDate - dates[dates.length - 1] > tooMuchTimeWithoutData) {
    pushDateWithEmptyValues(endDate);
  }
  */
  const numberOfValues = (endDate - startDate) / minInterval;

  const columns = [dates].concat(values);
  return { names, columns, colors, types, axes, axis, groups, numberOfValues };
};
