import React, { useState, useEffect } from 'react';
import { CustomInput } from 'reactstrap';
import { DynamicForm } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import api from 'api';

const Image = styled.img`
  max-width: 100%;
  max-height: 20rem;
  object-fit: ${props => props.fit || 'scale-down'};
`;

const ImageWidgetForm = (props) => {
  const { onClose, onSave, selectedDomain, selectedCompany, selectedSite, folders, addFolder } = props;

  const [widget, setWidget] = useState(props.widget);
  const [content, setContent] = useState();
  const [file, setFile] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (props.widget.documentId) {
      api.get(`documents/${widget.documentId}/download`, { responseType: 'arraybuffer' }).then(res => {
        const { data, headers } = res;
        const arr = new Uint8Array(data);
        // Convert the int array to a binary string
        // We have to use apply() as we are converting an *array*
        // and String.fromCharCode() takes one or more single values, not
        // an array.
        const CHUNK_SZ = 0x8000;
        let raw = [];
        for (let i = 0; i < arr.length; i += CHUNK_SZ) {
          raw.push(String.fromCharCode.apply(null, arr.subarray(i, i + CHUNK_SZ)));
        }
        const b64 = btoa(raw.join(""));
        setContent(`data:${headers['content-type']};base64,${b64}`);
      }).catch(err => console.error(err));
    }
  }, []);


  const title = widget ? widget.title : '';

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const getPath = () => {
    if (selectedSite) {
      return `sites/${selectedSite.id}`;
    } else if (selectedCompany) {
      return `companies/${selectedCompany.id}`;
    }
    return `domains/${selectedDomain.id}`;
  }

  const onChangeHandler = async (event) => {
    try {
      const { target } = event;
      const targetFile = target.files[0];
      setFile(targetFile);
      const { type } = targetFile;

      if (type.split('/')[0] !== 'image') {
        setError({ message: i18n._(t`Selezionare un file di tipo immagine`) });
        return;
      }

      const cont = await readFileAsync(targetFile);
      if (cont.length > 5000000) {
        setError({ message: i18n._(t`File troppo grande. La dimensione dell'immagine non può eccedere i 5000kB complessivamente`) });
      } else {
        setContent(cont);
        setError(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const submitWidget = async (data) => {
    if (!content) {
      setError({ message: i18n._(t`Selezionare un file`) });
    } else {
      if (file) {
        try {
          let srcName;
          let srcId;
          const formData = new FormData();
          formData.append('file', file);
          // formData.append('tags', ['dashboard-image']);
          const path = getPath();
          if (folders && folders.length > 0) {
            formData.append('folderId', folders[0].id);
          } else {
            const folderRes = await api.post(`/${path}/folders`, { name: 'documents' });
            formData.append('folderId', folderRes.data.id);
            addFolder(folderRes.data);
          }
          const res = await api.post(`/${path}/documents`, formData);
          if (res.status === 200) {
            const { originalName, id } = res.data;
            srcName = originalName;
            srcId = id;
            setWidget({ ...widget, filename: originalName, documentId: id });
          }
          onSave({ ...widget, ...data, filename: srcName, documentId: srcId });
          onClose();
        } catch {
          setError({ message: i18n._(t`Errore nel caricamento del file`) });
        }
      } else {
        onSave({ ...widget });
        onClose();
      }
    }
  };

  const edit = widget.key !== undefined;

  const fitList = [
    { value: 'none', label: i18n._(t`Non adattare dimensioni dell'immagine`) },
    { value: 'scale-down', label: i18n._(t`Mantieni rapporto tra larghezza e altezza e dimensione massima`) },
    { value: 'contain', label: i18n._(t`Adatta mantenendo rapporto tra larghezza e altezza`) },
    { value: 'initial', label: i18n._(t`Adatta senza mantenere il rapporto tra larghezza e altezza`) },
    { value: 'cover', label: i18n._(t`Occupa tutta l'area disponibile, tagliando in altezza o larghezza`) },
  ];

  return (
    <DynamicForm
      card
      edit={edit}
      title={title}
      initialValues={widget}
      onSubmit={submitWidget}
      onCancel={onClose}
      error={error}
      hideRequiredLabel
      fields={[
        {
          name: 'filename',
          type: 'custom',
          label: <Trans>File</Trans>,
          // validation: { required: true },
          render: () => <CustomInput type="file" id="fileBrowser" name="imageFile" accept="image/*" label={widget.filename || i18n._(t`Seleziona file`)} onChange={onChangeHandler} />,
        },
        {
          name: 'fit',
          type: 'select',
          label: <Trans>Adattamento immagine</Trans>,
          validation: { required: true },
          options: fitList,
          onChange: value =>setWidget({...widget, fit: value})
        },
        {
          name: 'content',
          type: 'custom',
          render: () => <Image src={content} alt={widget.filename} />,
        }
      ]}
    />
  );
};


export default ImageWidgetForm;
