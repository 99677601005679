import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Badge, NavItem, Nav, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import moment from 'moment';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { toast } from 'react-toastify';
import './toast.css';
import api from 'api';

import RealTimeNotification from 'app/common/RealTimeNotification';

import { changeAlarmFilters, loadPreferences } from 'ducks/actions/preferences';
import { addReader, removeReader } from 'ducks/actions/catalogs';
import AlarmsTree from './AlarmsTree';
import AlarmToast from './AlarmToast';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BadgeRelative = styled(Badge)`
  position: relative;
  top: -6px;
  left: -9px;
  background-color: ${props => props.colors ? `${props.colors.bgcolor} !important` : '#BF5298DD !important'};
  color: ${props => props.colors && `${props.colors.color} !important`};
  animation: ${props => props.animation ? 'badge-fa-pulse 3s ease-in-out forwards' : 'badge-fa-pulse2 3s ease-in-out forwards'}
  @keyframes badge-fa-pulse {
    0% {
        transform: scale(1.2);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    50% {
        transform: scale(0.8);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    100% {
        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
  @keyframes badge-fa-pulse2 {
    0% {
        transform: scale(1.2);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    50% {
        transform: scale(0.8);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    100% {
        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
`;

const DropdownList = styled(DropdownMenu)`
  width: 850px;
   &.noEvent{
    width: 425px;
  }
`;

const ActiveAlarmsRecap = props => {
    const { type, id, catalogs, changeAlarmFilters, alarmFilters, loadPreferences, companies, sites, sitegroups, selectedDomain } = props;

    const periods = [
        {
            value: 'yesterdayandtoday',
            label: i18n._(t`Ieri e oggi`)
        },
        {
            value: '7d',
            label: i18n._(t`Ultimi 7 giorni`)
        },
        {
            value: '15d',
            label: i18n._(t`Ultimi 15 giorni`)
        },
        {
            value: '30d',
            label: i18n._(t`Ultimi 30 giorni`)
        }
    ];


    useEffect(() => {
        if (catalogs['event/tags']) {
            const catalogsTags = catalogs['event/tags'].map(t => {
                const tag = {
                    label: t.name,
                    value: t.naturalKey
                }
                return tag
            })
            api.get(`/EventChains/allTags`).then(res => {
                const { data } = res;
                const updatedTags = [
                    ...catalogsTags,
                    ...data.filter(t => !catalogsTags.map(ct => ct.value).includes(t) && !catalogsTags.map(ct => ct.label).includes(t))
                        .map(dt => {
                            return {
                                value: 'custom',
                                label: dt
                            }
                        })
                ];
                setTagsList(updatedTags);
            }).catch(err => {
                console.error(`Non è stato possibile caricare i tag custom`);
                setTagsList(catalogsTags);
            });
        }
    }, [catalogs['event/tags']]);

    const severities = useMemo(() => catalogs['event/severities'] && [
        {
            value: '',
            label: i18n._(t`Tutti i livelli di criticità`)
        },
        ...catalogs['event/severities'].sort((a, b) => b.weight - a.weight).map(s => {
            const severity = {
                label: s.naturalKey === 'info' ? i18n._(t`Nessuna criticità`) : i18n._(t`Criticità ${s._label.toLowerCase()}`),
                value: s.naturalKey
            }
            return severity;
        })
    ], [catalogs['event/severities']]);

    const getSeverityColor = (severity) => {
        switch (severity) {
            case 'critical':
                return { bgcolor: '#D0021B' };
            case 'medium':
                return { bgcolor: '#F5A623', color: '#666' };
            case 'low':
                return { bgcolor: '#F8E71C', color: '#666' };
            case 'info':
                return { bgcolor: '#9bddf2', color: '#666' };
            default:
                return { bgcolor: '#BF5298DD' }
        }
    }

    const [loading, setLoading] = useState(false);
    const [openTree, setOpenTree] = useState(false);
    const [count, setCount] = useState(0);
    const [animation, setAnimation] = useState(true);
    const [countByNode, setCountByNode] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState(alarmFilters && alarmFilters.periodRecap ? alarmFilters.periodRecap : '15d');
    const [selectedSeverity, setSelectedSeverity] = useState('');
    const [badgeColors, setBadgeColors] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [openNodes, setOpenNodes] = useState([]);
    const [intervalId, setIntervalId] = useState(-1);
    const [domainId, setDomainId] = useState();

    useEffect(() => {
        if (intervalId > -1) {
            clearInterval(intervalId);
        }
        loadCounts();
        const interval = setInterval(() => {
            loadCounts();
        }, 1000 * 60 * 2);
        setIntervalId(interval);
    }, [selectedSeverity, selectedTags, selectedPeriod]);

    useEffect(() => {
        setDomainId(selectedDomain ? selectedDomain.id : -1);
        setCount(0);
    }, [selectedDomain]);

    useEffect(() => {
        if ((sites && sites.filter(s => companies.map(c => c.id).includes(s.companyId)).length === sites.length
            && sitegroups && sitegroups.filter(sg => companies.map(c => c.id).includes(sg.companyId)).length === sitegroups.length
            && companies && companies.length > 0 && companies.filter(c => c.domainId === domainId).length === companies.length)) {
            if (intervalId > -1) {
                clearInterval(intervalId);
            }
            loadCounts();
            const interval = setInterval(() => {
                loadCounts();
            }, 1000 * 60 * 2);
            setIntervalId(interval);
        }
    }, [companies, sitegroups, sites]);

    useEffect(() => {
        if (!loading) {
            setAnimation(!animation);
        }
    }, [loading])

    const loadCounts = () => {
        if (selectedDomain && companies && sitegroups && sites) {
            setLoading(true);
            const { id } = selectedDomain;
            let whereFilter = `"domainId":${id},"wholeBranch":true,"ongoing":true,"final":{"neq":true}`;
            whereFilter += `,"severity":"${selectedSeverity || 'any'}"`
            if (selectedTags && selectedTags.length > 0) {
                whereFilter += `,"tags":${JSON.stringify([...selectedTags.map(t => t.label), ...selectedTags.filter(tg => tg.value !== 'custom').map(t => t.value)])}`;
            }
            whereFilter += `${getPeriodFilter(selectedPeriod)}`;
            api.get(`EventChains/count/byNode?where={${whereFilter}}`).then(result => {
                let { data } = result;
                const nodes = Object.keys(data);
                let sum = 0;
                if (selectedSeverity) {
                    nodes.forEach(node => {
                        sum += Object.values(data[node]).reduce((a, b) => a + b);
                    });
                } else {
                    if (data['Domain']) {
                        Object.values(data['Domain']).map(val => Object.values(val)).forEach(counts => {
                            sum += counts.reduce((a, b) => a + b);
                        });
                    }
                    if (data['Company']) {
                        Object.values(data['Company']).map(val => Object.values(val)).forEach(counts => {
                            sum += counts.reduce((a, b) => a + b);
                        });
                    }
                    if (data['Site']) {
                        Object.values(data['Site']).map(val => Object.values(val)).forEach(counts => {
                            sum += counts.reduce((a, b) => a + b);
                        });
                    }
                    if (data['SiteGroup']) {
                        Object.values(data['SiteGroup']).map(val => Object.values(val)).forEach(counts => {
                            sum += counts.reduce((a, b) => a + b);
                        });
                    }
                }
                setCount(sum);
                setCountByNode(data);
                setLoading(false);
            }).catch(error => {
                console.error('Errore nel conteggio allarmi attivi');
                setLoading(false);
            });
        }
    }

    const getPeriodFilter = period => {
        let periodFilter;
        switch (period) {
            case 'yesterdayandtoday':
                periodFilter = `,"begin":{"between":["${moment().subtract(1, 'days').startOf('day').toISOString()}","${moment().toISOString()}"]}`;
                break;
            case '7d':
                periodFilter = `,"begin":{"between":["${moment().subtract(6, 'days').startOf('day').toISOString()}","${moment().toISOString()}"]}`;
                break;
            case '15d':
                periodFilter = `,"begin":{"between":["${moment().subtract(14, 'days').startOf('day').toISOString()}","${moment().toISOString()}"]}`;
                break;
            case '30d':
                periodFilter = `,"begin":{"between":["${moment().subtract(29, 'days').startOf('day').toISOString()}","${moment().toISOString()}"]}`;
                break;
            default:
                break;
        }
        return periodFilter;
    }

    const onPeriodChange = period => {
        setSelectedPeriod(period);
        const updatedAlarmFilters = { ...alarmFilters, periodRecap: period };
        changeAlarmFilters(updatedAlarmFilters);
        updateUserPreferences(updatedAlarmFilters);
    }

    const onSeverityChange = severity => {
        setSelectedSeverity(severity);
        setBadgeColors(getSeverityColor(severity));
        const updatedAlarmFilters = { ...alarmFilters, severity };
        changeAlarmFilters(updatedAlarmFilters);
    }

    const onTagsChange = tags => {
        setSelectedTags(tags);
        const updatedAlarmFilters = { ...alarmFilters, tags };
        changeAlarmFilters(updatedAlarmFilters);
    }

    const updateUserPreferences = (_alarmFilters) => {
        //Load from backend and update alarmFilters
        loadPreferences().then((data) => {
            const userPreferences = { ...data.preferences, alarmFilters: _alarmFilters }
            const body = { ...data, preferences: userPreferences };
            api.patch('/UserPreferences/own', body)
                .then(res => console.log('userPreferences update')).catch(err => console.log('userPreferences not updated'));
        })
    }

    const openNode = (node, open) => {
        const { type, id, item } = node;
        setOpenNodes(prevState => prevState.filter(x => x.type !== type || x.id !== id).concat({ type, id, open }));

        const { onOpen } = props;
        if (onOpen) {
            onOpen({ type, id, item }, open);
        }
    };

    const createNode = (item, type) => {
        const { id, name } = item;
        const node = { id, name, type, item, showLeafChildren: true };

        let keyType;
        switch (type) {
            case 'domain':
                keyType = 'Domain';
                break;
            case 'company':
                keyType = 'Company';
                break;
            case 'site':
                keyType = 'Site';
                break;
            case 'sitegroup':
                keyType = 'SiteGroup';
                break;
            default:
                break;
        }
        if (countByNode && keyType && countByNode[keyType]) {
            const keys = Object.keys(countByNode[keyType]);
            if (keys.includes(id.toString())) {
                node.severitiesCount = [];
                if (selectedSeverity) {
                    node.severitiesCount.push({
                        [selectedSeverity]: {
                            count: countByNode[keyType][id] < 100 ? countByNode[keyType][id] : '99+',
                            ...getSeverityColor(selectedSeverity)
                        },
                    })
                    node.totCount = countByNode[keyType][id];
                } else {
                    node.totCount = 0;
                    Object.keys(countByNode[keyType][id]).forEach(severity => {
                        node.severitiesCount.push({
                            [severity]: {
                                count: countByNode[keyType][id][severity] < 100 ? countByNode[keyType][id][severity] : '99+',
                                ...getSeverityColor(severity)
                            }
                        })
                        node.totCount += countByNode[keyType][id][severity];
                    })
                }
            }
        }

        let children;
        let childrenType;
        let siteGroupChildren;
        let siteGroupChildrenType;
        switch (type) {
            case 'domain':
                children = companies.filter(x => x.domainId === id);
                childrenType = 'company';
                break;
            case 'company':
                children = sites.filter(x => x.companyId === id);
                childrenType = 'site';
                siteGroupChildren = sitegroups.filter(x => x.companyId === id);
                siteGroupChildrenType = 'sitegroup';
                break;
            case 'sitegroup':
            case 'site':
                break;
            default:
                break;
        }
        node.key = getNodeKey(type, item);
        if (children) {
            node.children = siteGroupChildrenType ?
                [...children.map(x => createNode(x, childrenType, node.key)),
                ...siteGroupChildren.map(x => createNode(x, siteGroupChildrenType, node.key))]
                : children.map(x => createNode(x, childrenType, node.key));
            openNode(node, true);
        }
        return node;
    };

    const getNodeKey = (type, item) => {
        switch (type) {
            case 'site': {
                const site = sites.find(x => x.id === item.id);
                return `domain_${selectedDomain.id}/company_${site.companyId}/site_${item.id}`;
            }
            case 'sitegroup': {
                return `domain_${selectedDomain.id}/company_${item.companyId}/sitegroup_${item.id}`;
            }
            case 'company':
                return `domain_${selectedDomain.id}/company_${item.id}`;
            case 'domain':
                return `domain_${selectedDomain.id}`;
            default:
        }
    };

    const getChildrenNodeTotCount = node => {
        let tot = 0;
        node.rootCount = node.totCount || 0;
        if (selectedSeverity) {
            node.severityColor = getSeverityColor(selectedSeverity);
        }
        if (node.children) {
            node.children.forEach(child => {
                tot = getChildrenNodeTotCount(child)
            });
            if (node.children.map(child => child.totCount).filter(c => c).length > 0) {
                node.rootCount += node.children.map(child => child.totCount).filter(c => c).reduce((a, b) => a + b);
                tot += node.children.map(child => child.totCount).filter(c => c).reduce((a, b) => a + b);
            }
            if (node.children.length > 0 && node.children.map(c => c.severities).every(cs => cs && cs.length === 1 && cs === cs[0])) {
                node.severityColor = node.children[0].severities[0];
            }
        }
        return tot;
    }

    const structure = useMemo(() => {
        if (selectedDomain) {
            let rootNode = createNode(selectedDomain, 'domain');
            openNode(rootNode, true);
            rootNode.rootCount = rootNode.totCount || 0;
            if (selectedSeverity) {
                rootNode.severityColor = getSeverityColor(selectedSeverity);
            }
            if (rootNode.children) {
                rootNode.children.forEach(child => {
                    if (child.totCount) {
                        rootNode.rootCount += child.totCount;
                    }
                    if (child.children) {
                        rootNode.rootCount += getChildrenNodeTotCount(child);
                    }
                });
                if (rootNode.children.length > 0 && rootNode.children.map(c => c.severities).every(cs => cs && cs.length === 1 && cs === cs[0])) {
                    rootNode.severityColor = rootNode.children[0].severities[0];
                }
            }
            return rootNode;
        }
    }, [selectedDomain, countByNode]);

    const cName = 'noEvent';

    const showToast = (event) => {
        let { severity, final } = event;
        severity = final ? 'end' : severity;
        const options = {
            autoClose: 15000,
            className: `NE-background`,
            bodyClassName: `NE-body-severity`,
            progressClassName: `NE-progress-severity-${severity}`,
        };
        toast(<AlarmToast event={event} catalogs={catalogs} />, options);
    };

    return (
        <Wrapper>
            <RealTimeNotification onMessage={showToast} />
            <Dropdown direction="down" isOpen={openTree} toggle={() => setOpenTree(!openTree)}>
                <Nav>
                    <NavItem>
                        <DropdownToggle nav>
                            <img
                                src='/assets/sidebar/bell.svg'
                                alt='bell'
                            />
                            <BadgeRelative animation={animation} pill colors={badgeColors}>{count > 0 && (count <= 99 ? count : '99+')}</BadgeRelative>
                        </DropdownToggle>
                    </NavItem>
                </Nav>
                <DropdownList className={cName} left="true">
                    <AlarmsTree
                        onPeriodChange={onPeriodChange}
                        onSeverityChange={onSeverityChange}
                        onTagsChange={onTagsChange}
                        selectedPeriod={selectedPeriod}
                        selectedSeverity={selectedSeverity}
                        selectedTags={selectedTags}
                        periods={periods}
                        severities={severities}
                        tags={tagsList}
                        selectedDomain={selectedDomain}
                        companies={companies}
                        sitegroups={sitegroups}
                        sites={sites}
                        count={count}
                        structure={structure}
                        openNode={openNode}
                        openNodes={openNodes}
                        type={type}
                        id={id}
                        loading={loading}
                        onLink={() => setOpenTree(!openTree)}
                    />
                </DropdownList>
            </Dropdown>
        </Wrapper>
    );
}

const mapStateToProps = (state) => {
    const { preferences, catalogs, navigation } = state;
    const { alarmFilters, userInfoId } = preferences;
    const { companies, sites, sitegroups, selectedDomain, type, id } = navigation;
    return { preferences, catalogs, alarmFilters, userInfoId, companies, sites, sitegroups, selectedDomain, type, id };
};

const mapDispatchToProps = dispatch => ({
    changeAlarmFilters: alarmFilters => dispatch(changeAlarmFilters(alarmFilters)),
    addReader: (name, readers, newReaders) => dispatch(addReader(name, readers, newReaders)),
    removeReader: (name, readers, removedReadersIds) => dispatch(removeReader(name, readers, removedReadersIds)),
    loadPreferences: () => dispatch(loadPreferences()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAlarmsRecap);