import store from 'ducks/store';
import axios from 'axios';
import qs from 'qs';
import { t } from '@lingui/macro';

export const Roles = {
  VWR: 'VWR',
  GUE: 'GUE',
  OPE: 'OPE',
  OPE_CMD: 'OPE_CMD',
  OPE_Commissioning: 'OPE_Commissioning',
  OPE_VM: 'OPE_VM',
  ADM: 'ADM',
  SUP: 'SUP',
  CSU: 'CSU',
  SYS: 'SYS',
  SSYS: 'SSYS'
};

export const opeOptions = [
  { key: 'opeCommissioning', role: Roles.OPE_Commissioning, label: t`Asset e strumentazione` },
  { key: 'opeCMD', role: Roles.OPE_CMD, label: t`Attuazioni` },
  { key: 'opeVM', role: Roles.OPE_VM, label: t`Misuratori virtuali` }
];

const domainRanks = {
  VWR: 0,
  GUE: 0,
  OPE: 1,
  OPE_CMD: 1.5,
  OPE_Commissioning: 1.5,
  OPE_VM: 1.5,
  ADM: 2,
  SUP: 3
};

export const setUserCurrentRoles = auth => {
  const roles = [];
  Object.entries(domainRanks).forEach(([role, rank]) => {
    if (domainRanks[auth.role] >= rank) {
      roles.push(role);
    }
  });
  if (auth.role === Roles.OPE) {
    opeOptions.forEach(option => {
      if (auth[option.key]) {
        roles.push(option.role);
      }
    });
  }
  return roles;
};

export const checkPassword = async password => {
  try {
    const { keycloak, user } = store.getState().auth;
    const { username } = user;
    const { authServerUrl, realm, clientId: client_id } = keycloak;
    const body = qs.stringify({ grant_type: 'password', client_id, username, password });
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const res = await axios.post(
      `${authServerUrl}/realms/${realm}/protocol/openid-connect/token`,
      body,
      config
    );
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return false;
    }
    throw error;
  }
};
