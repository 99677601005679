import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { Button } from 'app/common';

const ModalEventName = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1rem;
`;
const ModalProceed = styled.div`
  margin-top: 2rem;
`;
const ModalField = styled.span`
  opacity: 0.6;
  min-width: 6rem;
  display: inline-block;
`;
const StyledModalFooter = styled(ModalFooter)`
  justify-content: flex-start !important;
  border-top: 0 !important;
`;
const Title = styled.span`
  font-weight: 700;
  color: #212529
`;

const ModalConfirm = (props) => {

  const { alarms, closeModal, openModalConfirm, onModalSubmit, reopening } = props;

  const filterdeAlarms = reopening ?
    alarms.filter(al => al.state === 'WK-2' && al.auto === false)
    : alarms.filter(al => al.state === 'WK-1');

  return (
    <Modal size="lg" isOpen={openModalConfirm} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <Title>
          {reopening ? <Trans>Conferma riattivazione manuale dell'allarme</Trans>
            : <Trans>Conferma chiusura manuale dell'allarme</Trans>
          }
        </Title>
      </ModalHeader>
      <ModalBody>
        {filterdeAlarms.length === 1 && (!filterdeAlarms[0].childEvents || filterdeAlarms[0].childEvents.length < 1) ?
          <Trans>Stai per effettuare la {reopening ? 'riattivazione' : 'chiusura'} manuale dell'allarme attivo selezionato:</Trans> :
          <Trans>Stai per effettuare la {reopening ? 'riattivazione' : 'chiusura'} manuale dell'allarme dei <b>{filterdeAlarms.length > 1 ? filterdeAlarms.length : filterdeAlarms[0].childEvents.length}</b> allarmi attivi selezionati.</Trans>
        }
        {filterdeAlarms.length === 1 && (!filterdeAlarms[0].childEvents || filterdeAlarms[0].childEvents.length < 1) &&
          <div>
            <ModalEventName>{filterdeAlarms[0].name}</ModalEventName>
            <ModalField><Trans>Impianto</Trans></ModalField> {filterdeAlarms[0].rootEntity}<br />
            <ModalField><Trans>Entità</Trans></ModalField> {filterdeAlarms[0].entity}<br />
            <ModalField><Trans>Condizione</Trans></ModalField> {filterdeAlarms[0].condition}<br />
          </div>
        }
        <ModalProceed><Trans>Procedere con l'operazione?</Trans></ModalProceed>
      </ModalBody>
      <StyledModalFooter>
        <Button color="primary" onClick={() => onModalSubmit(filterdeAlarms.map(a => a.id || a.rootEventId))}>
          <Trans>Conferma</Trans>
        </Button>
        <Button color="secondary" onClick={closeModal}><Trans>Annulla</Trans></Button>
      </StyledModalFooter>
    </Modal>
  )
}

export default ModalConfirm;