import React from 'react';
import * as go from 'gojs';
import { GojsDiagram, ModelChangeEventType } from 'react-gojs';
import  GuidedDraggingTool  from '../Helpers/GuidedDraggingTool';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { updateAssetGraph } from 'ducks/actions/navigation';
import DiagramButtons from '../Form/DiagramButtons';
import AssetsList from '../Form/AssetsList';
import PopupEditor from '../Form/PopupEditor';
import ContextMenu from '../Form/ContextMenu';
import GoJsButton from '../Helpers/GoJsButton';
import Api from '../Helpers/Api';
import Varie from '../Helpers/Varie';
import cGraph from '../ConfigGraph';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { DiagramContainer } from '../Helpers/Styler';

class NodeGraph extends React.Component {
  nodeId = 0;

  flowId = 0;

  state = {
    showAddNode: false,
    firstSelectedItem: null,
    secondSelectedItem: null,
    vectorItem: null,
    vectors: [],
    selectedNodeKeys: [],
    editError: null,
    addIfOk: false,
    model: go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [],
      linkDataArray: [],
    }),
    tab: 'diagram',
  };

  constructor(props) {
    super(props);
    const { match, energyAsset, meterAsset, observedProperties, parentGraphData, vectors, graphData, Loader, virtualMeter } = props;
    const { params } = match;
    const { parentGraphId } = params;
    const siteId = params.id;
    this.Api = new Api();
    this.ContextMenu = new ContextMenu();
    this.varie = new Varie();
    this.siteId = siteId;
    this.parentGraphId = parentGraphId;
    this.diagram = null;
    this.energyAsset = energyAsset;
    this.meterAsset = meterAsset;
    this.observedProperties = observedProperties;
    this.parentGraphData = parentGraphData;
    this.vectors = vectors;
    this.graphData = graphData;
    this.virtualMeter = virtualMeter;

    this.Loader = Loader;
    this.Loader.setPatchModelCallback(this.patchModel);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.assetListSet = false;
    this.graphDataSet = false;
    this.meterAssetDataSet = false;
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    const { redirectTo } = this.state;

    if (redirectTo !== undefined && redirectTo !== false) {
      this.onUpdate();
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onUpdate = () => {
    const state = {
      showAddNode: false,
      firstSelectedItem: null,
      secondSelectedItem: null,
      vectorItem: null,
      redirectTo: false,
      addIfOk: false,
      vectors: [],
      selectedNodeKeys: [],
      model:
      go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [],
        linkDataArray: [],
      }),
    };
    this.setState(state);
  }

  setEnergyAssetList = (assetList, jsonVector) => {
    this.energyAsset = assetList;
    this.vectors = jsonVector;
    this.assetListSet = true;
    this.dataSettet();
  }

  setMeterAssets = (meterAsset, observedProperties) => {
    this.meterAsset = meterAsset;
    this.observedProperties = observedProperties;
    this.meterAssetDataSet = true;
    this.dataSettet();
  }

  dataSettet = () => {
    const { assets, assetFlows } = this.graphData;
    const nodeDataArray = [];
    assets.forEach((asset) => {
      const item = this.energyAsset.find(ea => ea.id === asset.supportedAssetId);
      const newItem = JSON.parse(JSON.stringify(item));
      const location = new go.Point(asset.graphSetting.location.x, asset.graphSetting.location.y);
      const { measureAssetsList, fieldDevicesList } = this.Loader.getMeterAsset(asset.id);

      const newNodeToAdd = {
        visible_ma_panel: false,
        location,
        graphSetting: asset.graphSetting,
        key: asset.id,
        item: newItem,
        id: item.id,
        name: asset.name,
        source: `${cGraph.symbol_path}${newItem.icon}`,
        isComplex: asset.childGraph !== undefined,
        graphChildId: null,
        measureAssetsList,
        fieldDevicesList,
        has_error: false,
        isHover: false,
        isExpanded: false,
        property: {
          make: asset.make ? asset.make : '',
          model: asset.model ? asset.model : '',
          inventory: asset.inventory ? asset.inventory : '',
          technicalData: asset.technicalData ? asset.technicalData : {},
        },
      };
      if (asset.qualifier !== undefined) {
        newNodeToAdd.qualifier = asset.qualifier;
      }
      if (asset.addedTo !== undefined) {
        newNodeToAdd.addedTo = asset.addedTo;
        newNodeToAdd.group = `grp-${asset.addedTo}`;
      }
      if (asset.addon !== undefined) {
        newNodeToAdd.addon = asset.addon;
        newNodeToAdd.group = `grp-${asset.id}`;
        const groupData = {
          key: `grp-${asset.id}`,
          isGroup: true,
          name: JSON.parse(JSON.stringify(asset.name)),
        }
        nodeDataArray.push(groupData);
      }
      nodeDataArray.push(newNodeToAdd);
    });
    const { newNode, addIfOk } = this.state;

    if (addIfOk) {
      nodeDataArray.push(newNode);
    }
    this.addFlows(assetFlows, nodeDataArray, (flow, nodi) => {
      nodi.forEach((nodo) => {
        this.Loader.setNodeMetersIcon(nodo, flow);
      });
      const model = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodi],
        linkDataArray: [...flow],
      });
      this.setState({
        model,
      });
    });
  }

  updateWindowDimensions = () => {
    if (this.diagram != null) {
      const { div } = this.diagram;
      const h = window.innerHeight - 166;
      div.style.height = `${h}px`;
      this.diagram.requestUpdate();
    }
  }

  validGraph = (fcurrentGrpah, parentGraphId) => {
    let currentGrpah = fcurrentGrpah;
    if (currentGrpah === undefined) {
      currentGrpah = true;
    }
    const { model } = this.state;
    const { result, nodeDataArray } = this.Loader.validGraph(currentGrpah, parentGraphId, model);
    if (!result && currentGrpah) {
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodeDataArray],
        linkDataArray: [...model.linkDataArray],
      });
      this.setState({
        model: model2,
        showError: i18n._(t` Ci sono degli errori nella validazione del grafo`),
      });
    }
    return result;
  }

  patchModel = (data) => {
    this.setState(data);
  }

  patchData = (graphData) => {
    this.graphData = graphData;
    this.Api.patchGraphData(this.parentGraphData, ()=>{this.dataSettet()});
  }

  saveGraph = () => {
    const { updateAssetGraph } = this.props;
    const { model } = this.state;
    const { nodeDataArray } = model;
    if (this.validGraph()) {
      this.graphData.id = this.graphData.parentId;
      this.graphData.draft = false;
      this.graphData.parentId = null;
      this.Api.patchGraphData(this.parentGraphData, (result) => {
        if (result.success) {
          updateAssetGraph(result.data);
          const model2 = go.GraphObject.make(go.GraphLinksModel, {
            linkFromPortIdProperty: 'fromPort',
            linkToPortIdProperty: 'toPort',
            nodeDataArray: [...nodeDataArray],
            linkDataArray: [...model.linkDataArray],
          });
          if (this.parentGraphId) {
            this.setState({
              model: model2,
              redirectTo: `/sites/${this.siteId}/management/editgraph`,
            });
          } else {
            this.setState({
              model: model2,
              redirectDefinitivo: true,
            });
          }
        } else {
          const model2 = go.GraphObject.make(go.GraphLinksModel, {
            linkFromPortIdProperty: 'fromPort',
            linkToPortIdProperty: 'toPort',
            nodeDataArray: [...nodeDataArray],
            linkDataArray: [...model.linkDataArray],
          });
          this.setState({
              model: model2,
              showError: result.error.message,
            });
        }
      });
    }
  }

  removeSelectedNode = () => {
    const { selectedNodeKeys, model } = this.state;
    const { linkDataArray } = model;
    if (selectedNodeKeys.length > 0) {
      const { assets } = this.graphData;
      const asset = assets.find(ass => ass.id === selectedNodeKeys[0]);
      const { inFlowIds, outFlowIds } = asset;
      inFlowIds.forEach((flowId)=>{
        const node = linkDataArray.find(l => l.id === flowId);
        if (node !== null)
        {
          this.removeLink(node, false);
        }
      });
      outFlowIds.forEach((flowId)=>{
        const node = linkDataArray.find(l => l.id === flowId);
        if (node !== null)
        {
          this.removeLink(node, false);
        }
      });
      this.removeNode(selectedNodeKeys[0]);
    }
  }

  removeNodeByKey = (removeNodeKey, removeOther) => {
    const { model } = this.state;
    const { linkDataArray } = model;
    const { assets } = this.graphData;
    const asset = assets.find(ass => ass.id === removeNodeKey);
    if (asset != null){
      const { inFlowIds, outFlowIds } = asset;
      inFlowIds.forEach((flowId)=>{
        const node = linkDataArray.find(l => l.id === flowId);
        if (node !== null)
        {
          this.removeLink(node, false);
        }
      });
      outFlowIds.forEach((flowId)=>{
        const node = linkDataArray.find(l => l.id === flowId);
        if (node !== null)
        {
          this.removeLink(node, false);
        }
      });
      this.removeNode(removeNodeKey, removeOther);
    }
  }

  generaAssetComplesso = () => {
    const { selectedNodeKeys, model } = this.state;
    this.Loader.generaAssetComplesso(selectedNodeKeys, model);
  }

  showAssetData = () => {
    const { selectedNodeKeys } = this.state;
    if (selectedNodeKeys.length > 0) {
      this.setState({
        doubledClick: selectedNodeKeys[0],
        editError: null,
        addIfOk: false,
      });
    }
  }

  showPrincipale = () => {
    this.setState({
      redirectTo: `/sites/${this.siteId}/management/editgraph`,
    });
  }

  showDefinitivo = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      redirectDefinitivo: true,
    });
  }

  onSelectAssetTypeForComplex = (selectedData) => {
    const { assetTypesForComplex } = this.state;
    const { target } = selectedData;
    const { value } = target;
    const item = assetTypesForComplex.find(at => at.id === value);
    const { selectedNodeKeys, model } = this.state;
    const { linkDataArray } = model;
    let x = 0;
    let y = 0;
    let n = 0;
    for (let i = 0; i < selectedNodeKeys.length; i += 1) {
      const nodeKey = selectedNodeKeys[i];
      const node = model.nodeDataArray.find(nd => nd.key === nodeKey);
      x += node.graphSetting.location.x;
      y += node.graphSetting.location.y;
      n += 1;
    }
    x /= n;
    y /= n;
    const location = {
      x,
      y,
    };
    const graphSetting = {
      location,
    };

    this.graphData.assetIdCounter += 1;

    const assetSubGraph = {
      id: 1,
      assetIdCounter: 0,
      flowIdCounter: 0,
      assets: [],
      draft: true,
      graphType: 'sub',
      assetFlows: [],
    };
    const { assets, assetFlows } = this.graphData;
    const inFlowsList = [];
    const outFlowsList = [];


    // ricollego i flussi che entravano/uscivano dai nodi del vecchio grafo sul nuovo nodo
    for (let i = 0; i < selectedNodeKeys.length; i += 1) {
      const nodeKey = selectedNodeKeys[i];
      const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === nodeKey);
      const inFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.to === nodeKey);
      for (let j = 0; j < outFlowIndex.length; j += 1) {
        const outKey = linkDataArray[outFlowIndex[j]];
        if (selectedNodeKeys.indexOf(outKey.to) === -1) {
          outFlowsList.push(outKey);
          const assetFlow = assetFlows.find(af => af.id === outKey.id);
          const oldSurceAssetId = assetFlow.sourceAssetId;
          const oldAsset = assets.find(ass => ass.id === oldSurceAssetId);
          const outFlowIndex = oldAsset.outFlowIds.findIndex(outId => outId === outKey.id);
          oldAsset.outFlowIds.splice(outFlowIndex, 1);
          assetFlow.sourceAssetId = this.graphData.assetIdCounter;
          if (assetFlow.destinationMeasures !== undefined) {
            for (let k = 0; k < assetFlow.sourceMeasures.length; k += 1) {
              assetFlow.sourceMeasures[k].assetId = oldSurceAssetId ? this.graphData.assetIdCounter : assetFlow.sourceMeasures[k].assetId;
            }
          }
        }
      }

      for (let j = 0; j < inFlowIndex.length; j += 1) {
        const inKey = linkDataArray[inFlowIndex[j]];
        if (selectedNodeKeys.indexOf(inKey.from) === -1) {
          inFlowsList.push(inKey.id);
          const assetFlow = assetFlows.find(af => af.id === inKey.id);
          const oldDestinationAssetId = assetFlow.destinationAssetId;
          const oldAsset = assets.find(ass => ass.id === oldDestinationAssetId);
          const inFlowIndex = oldAsset.inFlowIds.findIndex(inId => inId === inKey.id);
          oldAsset.inFlowIds.splice(inFlowIndex, 1);
          assetFlow.destinationAssetId = this.graphData.assetIdCounter;
          if (assetFlow.destinationMeasures !== undefined) {
            for (let k = 0; k < assetFlow.destinationMeasures.length; k += 1) {
              assetFlow.destinationMeasures[k].assetId = oldDestinationAssetId ? this.graphData.assetIdCounter : assetFlow.destinationMeasures[k].assetId;
            }
          }
        }
      }
    }

    const assetsOldNew = [];
    // copio gli asset dal grafo principale al sottografo
    for (let i = 0; i < selectedNodeKeys.length; i += 1) {
      const nodeKey = selectedNodeKeys[i];
      const oldAssetIndex = assets.findIndex(ass => ass.id === nodeKey);
      const asset = JSON.parse(JSON.stringify(assets[oldAssetIndex]));
      assetSubGraph.assetIdCounter += 1;
      asset.id = assetSubGraph.assetIdCounter;
      asset.inFlowIds = [];
      asset.outFlowIds = [];
      assetSubGraph.assets.push(asset);
      assetsOldNew[assets[oldAssetIndex].id] = asset.id;
    }

    // sposto i flussi dal grafo principale al sottografo
    for (let i = 0; i < selectedNodeKeys.length; i += 1) {
      const nodeKey = selectedNodeKeys[i];
      const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === nodeKey);
      for (let j = 0; j < outFlowIndex.length; j += 1) {
        const outKey = linkDataArray[outFlowIndex[j]];
        if (selectedNodeKeys.indexOf(outKey.to) >= 0) {
          const oldSourceAssetId = outKey.from;
          const newSourceAssetId = assetsOldNew[outKey.from];
          const oldDestinationAssetId = outKey.to;
          const newDestinationAssetId = assetsOldNew[outKey.to];
          const assetFlowIndex = assetFlows.findIndex(af => af.destinationAssetId === oldDestinationAssetId && af.sourceAssetId === oldSourceAssetId);
          const assetFlow = JSON.parse(JSON.stringify(assetFlows[assetFlowIndex]));
          assetFlow.destinationAssetId = newDestinationAssetId;
          assetFlow.sourceAssetId = newSourceAssetId;
          assetSubGraph.flowIdCounter += 1;
          assetFlow.id = assetSubGraph.flowIdCounter;
          assetFlows.splice(assetFlowIndex, 1);
          assetSubGraph.assets.find(ass => ass.id === parseInt(newSourceAssetId, 10)).outFlowIds.push(assetSubGraph.flowIdCounter);
          assetSubGraph.assets.find(ass => ass.id === parseInt(newDestinationAssetId, 10)).inFlowIds.push(assetSubGraph.flowIdCounter);
          for (let k = 0; k < assetFlow.sourceMeasures.length; k += 1) {
            assetFlow.sourceMeasures[k].assetId = oldSourceAssetId ? newSourceAssetId : assetFlow.sourceMeasures[k].assetId;
          }
          for (let k = 0; k < assetFlow.destinationMeasures.length; k += 1) {
            assetFlow.destinationMeasures[k].assetId = oldDestinationAssetId ? newDestinationAssetId : assetFlow.destinationMeasures[k].assetId;
          }
          assetSubGraph.assetFlows.push(assetFlow);
        }
      }
    }

    for (let i = 0; i < selectedNodeKeys.length; i += 1) {
      const nodeKey = selectedNodeKeys[i];
      const oldAssetIndex = assets.findIndex(ass => ass.id === nodeKey);
      assets.splice(oldAssetIndex, 1);
    }

    const id = this.graphData.assetIdCounter;
    const supportedAssetId = item.id;
    const name = `${item.name} ${id}`;
    const dt = {
      name,
      supportedAssetId,
      graphSetting,
      childGraph: assetSubGraph,
      id,
      groupIds: [],
      inFlowIds: inFlowsList,
      outFlowIds: outFlowsList,
      inventory: '',
      make: '',
      model: '',
    };
    assets.push(dt);
    this.Api.patchGraphData(this.parentGraphData, () => {
      this.setState({
        assetTypesForComplex: null,
        redirectTo: `/sites/${this.siteId}/management/editgraph/${this.graphData.assetIdCounter}`,
      });
    });
  }

  undoAssetComplesso = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      assetTypesForComplex: null,
    });
  }

  showMeterAsset = () => {
    const { model, selectedNodeKeys } = this.state;
    if (selectedNodeKeys.length > 0) {
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...model.nodeDataArray],
        linkDataArray: [...model.linkDataArray],
      });
      this.setState({
        model: model2,
        showMeterAsset: true,
      });
    }
  }

  undoShowMeterAsset = () => {
    return;
  }

  undoshowError = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      showError: '',
    });
  }

  saveMeterAsset = (measureAssetsList, fieldDevicesList ,assetMeasures, callback) => {
    const { model, selectedNodeKeys } = this.state;
    const nodeKey = selectedNodeKeys[0];
    const { nodeDataArray } = model;
    const nodeIndex = model.nodeDataArray.findIndex(nd => nd.key === nodeKey);
    const node = model.nodeDataArray[nodeIndex];
    //this.undoShowMeterAsset();
    const { assets, assetFlows } = this.graphData;
    const asset = assets.find(ass => ass.id === parseInt(nodeKey, 10));
    asset.measures = [];
    const { inFlowIds, outFlowIds } = asset;
    inFlowIds.forEach((flowId) => {
      const flowIndex = assetFlows.findIndex(f => f.id === parseInt(flowId, 10));
      assetFlows[flowIndex].destinationMeasures = [];
    });
    outFlowIds.forEach((flowId) => {
      const flowIndex = assetFlows.findIndex(f => f.id === parseInt(flowId, 10));
      assetFlows[flowIndex].sourceMeasures = [];
    });

    measureAssetsList.forEach((measureAsset) => {
     /*let type = 'op';
      if(typeof(measureAsset.observedPropertyId) == "string" && measureAsset.observedPropertyId.substring(0, 3) == "vm-"){
        type = 'vm';
        measureAsset.observedPropertyId = parseInt(measureAsset.observedPropertyId.replace("vm-",""),10);
      }*/
      
      const measure = {
        observedPropertyId: measureAsset.observedPropertyId,
        assetId: nodeKey,
        display: measureAsset.display,
      };

      if (measureAsset.unit && measureAsset.display) {
        measure.unit = measureAsset.unit
      }

      if (measureAsset.aggregation && measureAsset.display) {
        measure.aggregationRate = measureAsset.aggregation
        measureAsset.aggregationRate = measureAsset.aggregation
      }
      asset.measures = [];
      if (measureAsset.direction === 'OUT') {
        const { flowId } = measureAsset;
        const flowIndex = assetFlows.findIndex(f => f.id === parseInt(flowId, 10));
        assetFlows[flowIndex].sourceMeasures.push(measure);
      } else if (measureAsset.direction === 'IN') {
        const { flowId } = measureAsset;
        const flowIndex = assetFlows.findIndex(f => f.id === parseInt(flowId, 10));
        assetFlows[flowIndex].destinationMeasures.push(measure);
      }
    });

    asset.measures = assetMeasures;
    this.graphData.assetFlows = assetFlows;
    this.Api.patchGraphData(this.parentGraphData,() => {
     /* measureAssetsList.forEach((measureAsset) => {
        if(measureAsset.type == "vm"){
          measureAsset.observedPropertyId = `vm-${measureAsset.observedPropertyId}`;
        }
      });*/
    
    
      node.measureAssetsList = measureAssetsList;
      node.fieldDevicesList = fieldDevicesList;
      let { linkDataArray } = model;
      this.Loader.setNodeMetersIcon(node, linkDataArray);

      
      nodeDataArray[nodeIndex] = node;

     this.diagram.model.linkDataArray = linkDataArray;
      this.diagram.model.nodeDataArray = nodeDataArray;
      go.GraphObject.bind();

      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodeDataArray],
        linkDataArray: [...linkDataArray],
      });
      this.setState({
        model: model2,
        showMeterAsset: false,
      });
    });
  }

  selectedVectorAfterDrag = (selectedData) => {
    const { vectorIntersection } = this.state;
    const { target } = selectedData;
    const { value } = target;
    const selectedItem = vectorIntersection.find(v => v.id === value);
    const { firstDraggedSelectedItem, secondDraggedSelectedItem, model } = this.state;
    const newThis = this;
    this.linkAdded(firstDraggedSelectedItem, secondDraggedSelectedItem, selectedItem, (nodeDataArray, id) => {
      const linksToAdd = {
        id,
        from: firstDraggedSelectedItem,
        to: secondDraggedSelectedItem,
        color: selectedItem.type.color,
        vectorItem: selectedItem,
        visibleIconOut: false,
        visibleMoreOut: false,
        meterNumberOut: 0,
        moreTextOut: '',
        visibleIconIn: false,
        visibleMoreIn: false,
        meterNumberIn: 0,
        moreTextIn: '',
      };
      const linkDataArray = model.linkDataArray.concat(linksToAdd);
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodeDataArray],
        linkDataArray: [...linkDataArray],
      });
      newThis.setState({
        model: model2,
        firstDraggedSelectedItem: null,
        secondDraggedSelectedItem: null,
        vectorIntersection: null,
        showAddNode: false,
      });
    });
  }

  undoAddVettore = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      firstDraggedSelectedItem: null,
      secondDraggedSelectedItem: null,
      vectorIntersection: null,
      showAddNode: false,
    });
  }

  pushVectorElems = (vectors) => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      vectors,
    });
  }

  onSelectFirst = (selectedItem) => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      firstSelectedItem: selectedItem,
    });
  }

  onSelectVector = (selectedItem) => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      vectorItem: selectedItem,
    });
  }

  onSelectSecond = (selectedItem) => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      secondSelectedItem: selectedItem,
    });
  }

  undoAddNode = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      firstSelectedItem: null,
      secondSelectedItem: null,
      vectorItem: null,
      showAddNode: false,
    });
  }

  addLink = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      showAddNode: true,
    });
  }

  deleteFirstItem = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      firstSelectedItem: null,
      secondSelectedItem: null,
      vectorItem: null,
    });
  }

  deleteSecondItem = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      secondSelectedItem: null,
      vectorItem: null,
    });
  }


  deleteVectorItem = () => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      vectorItem: null,
    });
  }


  saveNode = () => {
    const newThis = this;
    const { model, firstSelectedItem, secondSelectedItem, vectorItem } = this.state;
    this.linkAdded(firstSelectedItem.key, secondSelectedItem.key, vectorItem, (nodeDataArray, id) => {
      const linksToAdd = {
        id,
        from: firstSelectedItem.key,
        to: secondSelectedItem.key,
        color: vectorItem.type.color,
        vectorItem,
      };
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...nodeDataArray],
        linkDataArray: [...model.linkDataArray].concat(linksToAdd),
      });
      newThis.setState({
        model: model2,
        firstSelectedItem: null,
        secondSelectedItem: null,
        vectorItem: null,
        showAddNode: false,
      });
    });
  }

  getIntersectionVectors = (fromKey, toKey) => {
    const { model } = this.state;
    const nodes = model.nodeDataArray;
    const fromNodeIndex = nodes.findIndex(nd => nd.key === fromKey);
    if (fromNodeIndex === -1) {
      return false;
    }
    const toNodeIndex = nodes.findIndex(nd => nd.key === toKey);
    if (toNodeIndex === -1) {
      return false;
    }
    const fromNodeFreeVectors = this.getFreeVectors(nodes[fromNodeIndex].item, 'outFlow');
    const toNodeFreeVectors = this.getFreeVectors(nodes[toNodeIndex].item, 'inFlow');

    const result = fromNodeFreeVectors.filter((n) => {
      if (n) {
        return toNodeFreeVectors.findIndex(v => v && v.id === n.id) > -1;
      }
      return false;
    });
    if (!nodes[fromNodeIndex].item.outFlow.same && !nodes[fromNodeIndex].item.outFlow.different && !nodes[toNodeIndex].item.outFlow.same && !nodes[toNodeIndex].item.outFlow.different) {
      return result;
    }
    const finalResult = [];
    result.forEach((res) => {
      const data = this.checkSameDifferent(res, nodes[fromNodeIndex].item, nodes[toNodeIndex].item);
      if (data) {
        finalResult.push(data);
      }
    });
    return finalResult;
  }

  checkSameDifferent = (vector, fromItem, toItem) => {
    if (fromItem.outFlow.same || fromItem.outFlow.different) {
      let fromNk = null;
      const { vectors } = fromItem.inFlow;
      vectors.forEach((v) => {
        if (v.vector && v.vector.length > 0) {
          ([fromNk] = v.vector);
        }
      });
      if (fromItem.outFlow.same && fromNk !== null && vector.naturalKey !== fromNk) {
        return false;
      }

      if (fromItem.outFlow.different && fromNk !== null && vector.naturalKey === fromNk) {
        return false;
      }
    }


    if (toItem.outFlow.same || toItem.outFlow.different) {
      let toNk = null;
      const { vectors } = toItem.outFlow;
      vectors.forEach((v) => {
        if (v.vector && v.vector.length > 0) {
          ([toNk] = v.vector);
        }
      });
      if (toItem.outFlow.same && toNk !== null && vector.naturalKey !== toNk) {
        return false;
      }

      if (toItem.outFlow.different && toNk !== null && vector.naturalKey === toNk) {
        return false;
      }
    }
    return vector;
  }

  getFreeVectors = (item, flow) => {
    const freeVectors = [];
    if (item[flow].vectors === undefined) {
      item[flow].vectors = [];
    }
    const max = item[flow].max ? item[flow].max : null;
    let total = 0;
    for (let i = 0; i < item[flow].vectors.length; i += 1) {
      const v = item[flow].vectors[i];
      total += v.vector === undefined ? 0 : v.vector.length;
    }

    if (total === 0 || max === null || max > total) {
      const { vectors } = item[flow];
      vectors.forEach((v) => {
        const localMax = v.max ? v.max : null;
        if (localMax === null || v.vector === undefined || localMax > v.vector) {
          if (v.detail) {
            freeVectors.push(v.detail);
          } else if (v.details) {
            const { details } = v;
            details.forEach((detail) => {
              freeVectors.push(detail);
            });
          }
        }
      });
    }
    return freeVectors;
  }

  linkAddedNoSave = (fromKey, toKey, vector, flowID, nodes, callback) => {
    const fromNodeIndex = nodes.findIndex(node => node.key === fromKey);
    if (fromNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    const toNodeIndex = nodes.findIndex(nd => nd.key === toKey);
    if (toNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    const newflowId = flowID;
    let outVectorId = nodes[fromNodeIndex].item.outFlow.vectors.findIndex(v => v.vNK === vector);
    if (outVectorId === -1) {
      const { vectors } = nodes[fromNodeIndex].item.outFlow;
      let cnt = 0;
      vectors.forEach((v) => {
        if (v.vNKs && v.vNKs.indexOf(vector) >= 0) {
          outVectorId = cnt;
        }
        cnt += 1;
      });
    }

    if(outVectorId >= 0) {
      if (nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector === undefined) {
        nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector = [];
      }
      nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector.push(vector);
    }

    let inVectorId = nodes[toNodeIndex].item.inFlow.vectors.findIndex(v => v.vNK === vector);
    if (inVectorId === -1) {
      const { vectors } = nodes[toNodeIndex].item.inFlow;
      let cnt = 0;
      vectors.forEach((v) => {
        if (v.vNKs && v.vNKs.indexOf(vector) >= 0) {
          inVectorId = cnt;
        }
        cnt += 1;
      });
    }

    if(inVectorId >= 0) {
      if (nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector === undefined) {
        nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector = [];
      }
      nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector.push(vector);
    }
    callback(nodes, newflowId);
  }

  LinkDragged = (from, to) => {
    const { model } = this.state;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
    });

    const intersection = this.getIntersectionVectors(from, to);
    if (intersection === false) {
      return;
    }
    if (intersection.length === 1) {
      const intData = JSON.parse(JSON.stringify(intersection[0]));
      const newThis = this;
      newThis.linkAdded(from, to, intData, (nodeDataArray, id) => {
        const linksToAdd = {
          from,
          to,
          id,
          color: intData.type.color,
          vectorItem: intData,
          visibleIconOut: false,
          visibleMoreOut: false,
          meterNumberOut: 0,
          moreTextOut: '',
          visibleIconIn: false,
          visibleMoreIn: false,
          meterNumberIn: 0,
          moreTextIn: '',
        };

        const linkDataArray2 = JSON.parse(JSON.stringify(newThis.state.model.linkDataArray));
        const linkDataArray = linkDataArray2.concat(linksToAdd);

        const model2 = go.GraphObject.make(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort',
          linkToPortIdProperty: 'toPort',
          nodeDataArray: [...nodeDataArray],
          linkDataArray: [...linkDataArray],
        });
        setTimeout(() => {
          newThis.setState({
            model: model2,
          });
        }, 250);
      });
      return;
    }

    const model3 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model3,
      firstDraggedSelectedItem: from,
      secondDraggedSelectedItem: to,
      vectorIntersection: intersection,
      showAddNode: false,
    });
  }

  clearError = () => {
    const { model } = this.state;
    const modelt = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: model.nodeDataArray,
      linkDataArray: model.linkDataArray,
    });

    this.setState({
      model: modelt,
      editError: null,
    });
  }

  productDetailSave = (formModel, inventory, make, name, qualifier, groupsList, technicalData, technicalData2, fchangeAddOn, fnodeKey) => {
    const { onCloseEdit } = this.props;
    onCloseEdit();
    let changeAddOn = fchangeAddOn;
    if (changeAddOn === undefined) {
      changeAddOn = true;
    }
    const { model, doubledClick, addIfOk } = this.state;
    let nodeKey = doubledClick;
    if (fnodeKey !== undefined) {
      nodeKey = fnodeKey
    }
    const nodes = JSON.parse(JSON.stringify(model.nodeDataArray));
    const node = nodes.find(nd => nd.key === nodeKey);
    node.name = name;
    if (!node.property) {
      node.property = {};
    }
    node.property.model = formModel;
    node.property.inventory = inventory;
    node.property.make = make;
    node.property.technicalData = technicalData;
    if(addIfOk)
    {
       const dt = {
        name:node.name,
        supportedAssetId: node.supportedAssetId,
        graphSetting: node.graphSetting,
        id: node.key,
        groupIds: [],
        inFlowIds: [],
        outFlowIds: [],
        inventory: '',
        make: '',
        model: '',
      };
      let dt2 = {};
      if(node.addonOf !== undefined)
      {
        dt.qualifier = node.qualifier;
        const graphSetting = JSON.parse(JSON.stringify(node.graphSetting));
        graphSetting.location.x += 135;
        const { energyAsset }  = this;
        const otherNode = energyAsset.find(ea => ea.id === node.addonOf);
        this.graphData.assetIdCounter += 1;
        const key = this.graphData.assetIdCounter;
        dt2 = {
          name:node.name,
          supportedAssetId: otherNode.id,
          graphSetting: graphSetting,
          id: key,
          groupIds: [],
          inFlowIds: [],
          outFlowIds: [],
          inventory: '',
          make: '',
          model: '',
          addedTo: node.key,
          qualifier: node.baseQualifier,
        };
        dt.addon = key;
      }
      this.graphData.assets.push(dt);
      if(node.addonOf !== undefined)
      {
        this.graphData.assets.push(dt2);
      }
    }
    const { assets, assetGroups } = this.graphData;
    const assetToChangeIndex = assets.findIndex(asset => asset.id === node.key);

    if (assetToChangeIndex === -1) {
      return;
    }
    assets[assetToChangeIndex].name = name;
    assets[assetToChangeIndex].model = formModel;
    assets[assetToChangeIndex].inventory = inventory;
    assets[assetToChangeIndex].make = make;
    assets[assetToChangeIndex].technicalData = technicalData;
    assets[assetToChangeIndex].groupIds = [];
    if(node.groupIds === undefined)
    {
      node.groupIds = [];
    }
    groupsList.forEach((group) => {
      assets[assetToChangeIndex].groupIds.push(group.id);
      node.groupIds.push(group.id);
    });
    if(qualifier !== undefined && assets[assetToChangeIndex].qualifier !== undefined)
    {
      assets[assetToChangeIndex].qualifier = qualifier;
      node.qualifier = qualifier;
    }
    if(assets[assetToChangeIndex].addedTo !== undefined)
    {
      const group = nodes.find(nd => nd.key === `grp-${assets[assetToChangeIndex].addedTo}`);
      group.name = name;
    }

    assetGroups.forEach((agr) => {
      const { assetIds } = agr;
      const newId = [];
      assetIds.forEach((id) => {
        if (id !== nodeKey) {
          newId.push(id);
        }
      });
      agr.assetIds = newId;

      if (assets[assetToChangeIndex].groupIds.indexOf(agr.id) >= 0) {
        agr.assetIds.push(nodeKey);
      }
    });

    const newThis = this;
    this.graphData.assets = assets;
    this.graphData.assetGroups = assetGroups;

    this.Api.patchGraphData(this.parentGraphData, (result) => {
      if (result.success) {
        const model2 = go.GraphObject.make(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort',
          linkToPortIdProperty: 'toPort',
          nodeDataArray: nodes,
          linkDataArray: [...model.linkDataArray],
        });
        newThis.setState({
          model: model2,
          doubledClick: null,
          editError: null,
          addIfOk: false,
        }, () => {
          if(changeAddOn)
          {
            let editOther = false;
            let otherNodeKey = null;
            if (node.addon !== undefined) {
              editOther = true;
              otherNodeKey = node.addon;
            } else if(node.addedTo !== undefined) {
              editOther = true;
              otherNodeKey = node.addedTo;
            }
            if (editOther) {
              newThis.productDetailSave(formModel, inventory, make, name, undefined, groupsList, technicalData2, {}, false, otherNodeKey);
            } else {
              this.dataSettet();
              this.Api.patchGraphData(this.parentGraphData, ()=>{});
            }
          }

        });
      } else {
        if(addIfOk)
        {
          this.graphData.assets.splice(-1,1);
        }
        const model2 = go.GraphObject.make(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort',
          linkToPortIdProperty: 'toPort',
          nodeDataArray: model.nodeDataArray,
          linkDataArray: model.linkDataArray,
        });

        this.setState({
          model: model2,
          editError: result.error.details.codes.assets[0],
        });
      }
    });
  }

  assetMouseEnter = (nodeKey) => {
    const { model } = this.state;
    const nodes = model.nodeDataArray;
    const nodeIndex = nodes.findIndex(nd => nd.key === nodeKey);
    if (nodeIndex === -1) {
      return;
    }
    nodes[nodeIndex].over = true;
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: nodes,
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
    });
  }

  assetMouseOut = () => {
    const { model } = this.state;
    const nodes = model.nodeDataArray;
    for (let i = 0; i < nodes.length; i += 1) {
      nodes[i].over = false;
    }
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: nodes,
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
    });
  }

  validLink = (fromNode, fromPort, toNode, toPort) => {
    const intersection = this.getIntersectionVectors(fromNode.data.key, toNode.data.key);
    return intersection.length > 0;
  }

  createDiagram = (diagramId) => {
    const $ = go.GraphObject.make;
    const cxElement = document.getElementById('contextMenu');
    cxElement.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    }, false);
    const newThis = this;
    const myContextMenu = $(go.HTMLInfo, {
      show: newThis.ContextMenu.showContextMenu,
      mainElement: cxElement,
    });

    const NodeGraph = $(go.Diagram, diagramId, {
      draggingTool: new GuidedDraggingTool(),  // defined in GuidedDraggingTool.js
      "draggingTool.horizontalGuidelineColor": "blue",
      "draggingTool.verticalGuidelineColor": "blue",
      "draggingTool.centerGuidelineColor": "green",
      "draggingTool.guidelineWidth": 1,
      initialContentAlignment: go.Spot.Center,
      isReadOnly: false,
      allowHorizontalScroll: true,
      allowVerticalScroll: true,
      allowZoom: true,
      allowSelect: true,
    });

    NodeGraph.toolManager.hoverDelay = 0;
    NodeGraph.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
    NodeGraph.toolManager.linkingTool.linkValidation = this.validLink;
    NodeGraph.validCycle = go.Diagram.CycleNotDirected;
    const gjb = new GoJsButton();
    gjb.buttons(go);
    NodeGraph.nodeTemplate = $(go.Node, 'Auto', 
    {
      fromSpot: go.Spot.RightSide,
      toSpot: go.Spot.LeftSide ,
    },
    { contextMenu: null }, new go.Binding('location'), 
    {
      selectionChanged: node => this.nodeSelectionHandler(node.key, node.isSelected),
      mouseEnter: (e, obj) => { newThis.showPorts(obj.part, true); },
      mouseLeave: (e, obj) => { newThis.showPorts(obj.part, false); },
    },

    $(go.Shape, 'RoundedRectangle', { fill: 'white', parameter1: 3, stroke: '#c8ced3' },
      new go.Binding('stroke', 'border')),

    $(go.Panel, 'Table',
      $(go.RowColumnDefinition,
        { column: 0, alignment: go.Spot.Left }),
      $(go.RowColumnDefinition,
        { column: 2, alignment: go.Spot.Center }),
      $(go.RowColumnDefinition,
        { column: 3, alignment: go.Spot.Right }),
      $(go.Panel, 'Table',
        { column: 1, row: 0, rowSpan: 3, columnSpan: 2 },
        $(go.Panel, 'Table',
          { column: 0, row: 0, columnSpan: 2 },
          $(go.Picture,

            { column: 1, row: 0, alignment: go.Spot.Center, margin: 10, width: 15, height: 15, background: 'white' },
            new go.Binding('source', 'source_ma1'),
            new go.Binding('visible', 'visible_ma1')),
          $(go.Shape,

            { column: 1, row: 0, alignment: go.Spot.Bottom, margin: 7, width: 15, height: 2 },


            new go.Binding('visible', 'visible_ma1'),
            new go.Binding('fill', 'color_ma1'),
            new go.Binding('stroke', 'color_ma1')),
          $(go.Picture,

            { column: 2, row: 0, alignment: go.Spot.Center, margin: 10, width: 15, height: 15, background: 'white' },

            new go.Binding('source', 'source_ma2'),
            new go.Binding('visible', 'visible_ma2')),
          $(go.Shape,

            { column: 2, row: 0, alignment: go.Spot.Bottom, margin: 7, width: 15, height: 2 },


            new go.Binding('visible', 'visible_ma2'),
            new go.Binding('fill', 'color_ma2'),
            new go.Binding('stroke', 'color_ma2')),
          $(go.Picture,

            { column: 3, row: 0, alignment: go.Spot.Center, margin: 10, width: 15, height: 15, background: 'white' },

            new go.Binding('source', 'source_ma3'),
            new go.Binding('visible', 'visible_ma3')),
          $(go.Shape,

            { column: 3, row: 0, alignment: go.Spot.Bottom, margin: 7, width: 15, height: 2 },


            new go.Binding('visible', 'visible_ma3'),
            new go.Binding('fill', 'color_ma3'),
            new go.Binding('stroke', 'color_ma3')),

          $(go.Picture,

            { column: 4, row: 0, alignment: go.Spot.Center, margin: 10, width: 15, height: 15, background: 'white' },

            new go.Binding('source', 'source_ma4'),
            new go.Binding('visible', 'visible_ma4')),
          $(go.Shape,

            { column: 4, row: 0, alignment: go.Spot.Bottom, margin: 8, width: 15, height: 2 },


            new go.Binding('visible', 'visible_ma4'),
            new go.Binding('fill', 'color_ma4'),
            new go.Binding('stroke', 'color_ma4')),
          $(go.TextBlock,
            '',
            { column: 4, row: 0, alignment: go.Spot.Center, margin: 5, stroke: 'black', font: 'bold 15px sans-serif' },

            new go.Binding('text', 'more'),
            new go.Binding('visible', 'visible_more'),
            new go.Binding('font', 'over', (v) => {
              return v ? 'bold 12px sans-serif' : 'normal 12px sans-serif';
            })),

          new go.Binding('visible', 'visible_ma_panel')),
        $(go.Picture,

          { column: 1, row: 1, columnSpan: 3, alignment: go.Spot.Center, margin: 10, width: 50, height: 50, background: 'white' },

          new go.Binding('source')),
        $(go.Panel, 'Table', { column: 1, row: 2, columnSpan: 3 },
          $(go.TextBlock,
            '',
            { column: 1, row: 1, alignment: go.Spot.Center, margin: 5, stroke: 'black', font: 'normal 12px sans-serif', wrap: go.TextBlock.WrapFit, width: 110, textAlign: 'center' },

            new go.Binding('text', '', (v)=>{
              return v.qualifier !== undefined && v.qualifier != null ? v.qualifier : v.name;
            }),
            new go.Binding('font', 'over', (v) => {
              return v ? 'bold 12px sans-serif' : 'normal 12px sans-serif';
            })),
          $(go.TextBlock,
            '',
            { column: 1, row: 2, alignment: go.Spot.Center, margin: 5, stroke: 'red', font: 'normal 10px sans-serif', wrap: go.TextBlock.WrapFit, width: 110, textAlign: 'center' },

            new go.Binding('text', 'error_message'),
            new go.Binding('visible', 'has_error')))),
      $(go.Panel, 'Horizontal',
        { column: 0, row: 3 },
        $(go.Picture,

          { column: 0, row: 2, alignment: go.Spot.Right, margin: 0, width: 20, height: 20, background: 'white', source: `${cGraph.symbol_path}complex2.svg` }),
        new go.Binding('visible', 'isComplex')),
      $(go.Panel, 'Horizontal',
        { column: 0, row: 2 },
        $(go.Shape, 'Circle',
          { width: 12, height: 12, portId: 'LM', fill: null, toLinkable: true, fromLinkable: true, cursor: 'pointer', stroke: null })),

      $(go.Panel, 'Horizontal',
        { column: 2, row: 0 },
        $(go.Shape, 'Circle', { width: 12, height: 12, portId: 'CT', fill: null, toLinkable: true, fromLinkable: true, cursor: 'pointer', stroke: null })),

      $(go.Panel, 'Horizontal',
        { column: 2, row: 3 },
        $(go.Shape, 'Circle', { width: 12, height: 12, portId: 'CB', fill: null, toLinkable: true, fromLinkable: true, cursor: 'pointer', stroke: null })),

      $(go.Panel, 'Horizontal',
        { column: 3, row: 2 },
        $(go.Shape, 'Circle', { width: 12, height: 12, portId: 'RM', fill: null, toLinkable: true, fromLinkable: true, cursor: 'pointer', stroke: null })),
    
        
        ),
      $("CircleButton",
        { alignment: go.Spot.TopRight,  margin: 5, click: newThis.expandButton, mouseEnter: newThis.expandButton },
        $(go.Panel, 'Horizontal',
          $(go.Picture,
            {   source:`${cGraph.symbol_path}ellipsis.svg` ,  margin: 3, width: 20, height: 20},
          ),
        ),
        new go.Binding('visible','isHover')
      ),

      $("EllipseButton",
        { alignment: go.Spot.TopRight,  margin: 5, mouseLeave: newThis.implodeButton },
        $(go.Panel, 'Horizontal',
        $("circleButtonNoBorder",
            { alignment: go.Spot.TopLeft,  margin: 2, click: this.removeSelectedNodeMenu, mouseEnter: newThis.setSubHoverTrash, mouseLeave: newThis.removeSubHover },
              $(go.Picture,
                {   source:`${cGraph.symbol_path}trash.svg` ,  margin: 2, width: 18, height: 18},
                new go.Binding('source','subhover',(v) =>{
                  return v === 'trash' ? `${cGraph.symbol_path}trashHovered2.svg` : `${cGraph.symbol_path}trash.svg`;
                })  
              ),
          ),
          $("circleButtonNoBorder",
            { alignment: go.Spot.TopLeft,  margin: 2, click: this.generaAssetComplessoMenu , mouseEnter: newThis.setSubHoverMindMap, mouseLeave: newThis.removeSubHover},
              $(go.Picture,
                {   source:`${cGraph.symbol_path}mindmap.svg`,  margin: 2, width: 20, height: 14},
                new go.Binding('source','subhover',(v) =>{
                  return v === 'mindmap' ? `${cGraph.symbol_path}mindmapHovered.svg` : `${cGraph.symbol_path}mindmap.svg`;
                })  
              ),
          ),
          $("circleButtonNoBorder",
            { alignment: go.Spot.TopLeft, click: this.showMeterAssetMenu,  margin: 2, mouseEnter: newThis.setSubHoveredTachometer, mouseLeave: newThis.removeSubHover },
              $(go.Picture,
                {   source:`${cGraph.symbol_path}tachometer.svg` ,  margin: 2, width: 20, height: 14},
                new go.Binding('source','subhover',(v) =>{
                  return v === 'tachometer' ? `${cGraph.symbol_path}tachometerHovered.svg` : `${cGraph.symbol_path}tachometer.svg`;
                })  
              ),
              
          ),
          $("circleButtonNoBorder",
            { alignment: go.Spot.TopLeft, click: this.showAssetDataMenu,  margin: 2, mouseEnter: newThis.setSubHoveredEdit, mouseLeave: newThis.removeSubHover  },
              $(go.Picture,
                { source: `${cGraph.symbol_path}edit.svg`,  margin: 2, width: 20, height: 20 },
                new go.Binding('source','subhover',(v) =>{
                  return v === 'edit' ? `${cGraph.symbol_path}editHovered.svg` : `${cGraph.symbol_path}edit.svg`;
                })  
              ),
          ),
          
          
          
        ),
        new go.Binding('visible','isExpanded')
      ),
      
    );
    NodeGraph.contextMenu = myContextMenu;

    NodeGraph.linkTemplate = $(go.Link,
      { routing: go.Link.AvoidsNodes /*go.Link.AvoidsNodes*/, corner: 15, curve: go.Link.JumpOver, fromEndSegmentLength:80, toEndSegmentLength:80 },
      $(go.Shape,
        { strokeWidth: 3, stroke: '#000' },
        new go.Binding('stroke', 'color')),
      $(go.Shape,
        { toArrow: 'Triangle', fill: '#000', stroke: '#000' },
        new go.Binding('fill', 'color'),
        new go.Binding('stroke', 'color')),
      $(go.Panel, 'Horizontal',
        { segmentIndex: 0, segmentOffset: new go.Point(NaN, NaN), segmentOrientation: go.Link.OrientUpright },
        $(go.Picture,
          { margin: 0, width: 15, height: 15, background: null, source:`${cGraph.symbol_path}dashboard.svg` },
          new go.Binding("visible","visibleIconOut")),
         $(go.TextBlock,
            '',
            {  margin: new go.Margin(0, 0, 0, 5), stroke: 'black', font: 'bold 15px sans-serif' },

            new go.Binding('text', 'moreTextOut'),
            new go.Binding('visible', 'visibleMoreOut')
            ),

        ),

      $(go.Panel, 'Horizontal',
        { segmentIndex: -1, segmentOffset: new go.Point(NaN, NaN), segmentOrientation: go.Link.OrientUpright },
        $(go.Picture,
          { margin: 0, width: 15, height: 15, background: null, source:`${cGraph.symbol_path}dashboard.svg` },
          new go.Binding("visible","visibleIconIn")),
         $(go.TextBlock,
            '',
            {  margin: new go.Margin(0, 0, 0, 5), stroke: 'black', font: 'bold 15px sans-serif' },

            new go.Binding('text', 'moreTextIn'),
            new go.Binding('visible', 'visibleMoreIn')
            ),

        ),
        {
          toolTip:  // define a tooltip for each node that displays the color as text
            $("ToolTip",
              $(go.TextBlock, { margin: 4},
                 new go.Binding('text', 'vectorItem', (v) => {
                return v.name;
              }),),

            )  // end of Adornment
        });

    NodeGraph.groupTemplate =
      $(go.Group, 'Vertical',
      $(go.Panel, 'Auto',
        $(go.Shape, 'RoundedRectangle',
          { name: 'SHAPE',
            parameter1: 3,
            fill: 'transparent',
            stroke: '#c8ced3',
          }),
        $(go.Panel, 'Table',
           { defaultAlignment: go.Spot.Left },
        $(go.TextBlock,'',{ row: 0, column: 0, margin:5, alignment: go.Spot.Center, font:'normal 12px sans-serif'},
          new go.Binding('text', 'name')),
        $(go.Placeholder, { padding: 10, row: 1, column: 0 })),
      ),
      { mouseEnter: function(e, obj, prev) {  // change group's background brush
            var shape = obj.part.findObject('SHAPE');
            if (shape) shape.fill = '#eaeaea';
          },
        mouseLeave: function(e, obj, next) {  // restore to original brush
            var shape = obj.part.findObject('SHAPE');
            if (shape) shape.fill = 'transparent';
      }});


    NodeGraph.addDiagramListener('LinkDrawn', (e) => {
      newThis.LinkDragged(e.subject.data.from, e.subject.data.to);
    });

    NodeGraph.addDiagramListener('ObjectDoubleClicked', (ev) => {
      newThis.doubledClick(ev.subject.part.key, true);
    });

    NodeGraph.addDiagramListener('SelectionMoved', (ev) => {
      const it = ev.subject.iterator;
      let changed = false;
      const { model } = this.state;
      const nodes = model.nodeDataArray;
      const { assets } = this.graphData;
      while (it.next()) {

        changed = true;
        const item = it.value;
        if(item.data.isGroup === undefined || !item.data.isGroup) {
          if (item.data.key) {
            const index = nodes.findIndex(nd => nd.key === item.data.key);
            nodes[index].loc = `${item.location.x} ${item.location.y}`;
            const point = new go.Point(item.location.x, item.location.y);
            nodes[index].location = point;
            const location = {
              x: point.x,
              y: point.y,
            };
            const graphSetting = JSON.parse(JSON.stringify(nodes[index].graphSetting));
            graphSetting.location = location;
            const assetIndex = assets.findIndex(ast => ast.id === nodes[index].key);
            assets[assetIndex].graphSetting = graphSetting;
          }
        }
      }

      if (changed) {
        this.graphData.assets = assets;
        this.Api.patchGraphData(this.parentGraphData);
        const model2 = go.GraphObject.make(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort',
          linkToPortIdProperty: 'toPort',
          nodeDataArray: nodes,
          linkDataArray: [...model.linkDataArray],
        });
        newThis.setState({
          model: model2,
        });
      }
    });

    NodeGraph.addDiagramListener('InitialLayoutCompleted', () => {
      NodeGraph.zoomToFit();
    });
    this.diagram = NodeGraph;
    this.dataSettet();
    return NodeGraph;
  }

  modelChangeHandler = (event) => {
    switch (event.eventType) {
      case ModelChangeEventType.Remove:
        if (event.nodeData) {
          this.removeNode(event.nodeData.key);
        }
        if (event.linkData) {
          this.removeLink(event.linkData);
        }
        break;
      default:
        break;
    }
  }

  addNode = (item, point) => {
    const { model } = this.state;
    const location = {
      x: 0,
      y: 0,
    };

    if (point !== undefined) {
      location.x = point.x;
      location.y = point.y;
    }
    const graphSetting = {
      location,
    };

    this.graphData.assetIdCounter += 1;
    const id = this.graphData.assetIdCounter;
    const supportedAssetId = item.id;
    let name = `${item.name} ${id}`;
    if (this.parentGraphId) {
      name = `${item.name} ${this.parentGraphId}-${id}`;
    }
    const newThis = this;

    item.over = false;
    const newItem = JSON.parse(JSON.stringify(item));

    const newNode = {
      visible_ma_panel: false,
      graphSetting,
      key: id,
      item: newItem,
      supportedAssetId,
      name,
      source: `${cGraph.symbol_path}${newItem.icon}`,
      isComplex: false,
      graphChildId: null,
      measureAssetsList:[],
    };
    if(item.assetTypology.addonOf !== undefined)
    {
      newNode.addonOf = item.assetTypology.addonOf
      newNode.qualifier = item.assetTypology.addonQualifier
      newNode.addonQualifier = item.assetTypology.addonQualifier
      newNode.baseQualifier = item.assetTypology.baseQualifier
    }
    newNode.meterAsset = [];
    if (point !== undefined) {
      newNode.location = point;
    }
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray, newNode],
      linkDataArray: [...model.linkDataArray],
    });
    newThis.setState({
      model: model2,
      doubledClick: id,
      addIfOk: true,
      newNode: newNode
    });
    this.nodeId += 1;
  }

  deleteGroup = (groupKey) => {
    ////DELETE FUNCTION
    //const oldName = this.graphData.assetGroups[groupKey].name;
    const { assets } = this.graphData;
    assets.forEach((asset) => {
      const groupIds = [];
      asset.groupIds.forEach((thisId) => {
         if (thisId !== parseInt(groupKey,10)) {
          groupIds.push(thisId);
        }
      });
      asset.groupIds = groupIds;
    });
    const groupIndex = this.graphData.assetGroups.findIndex(grp => grp.id === parseInt(groupKey,10));
    this.graphData.assetGroups.splice(groupIndex, 1);
    this.Api.patchGraphData(this.parentGraphData);
    return this.graphData.assetGroups;
  }

  addGroup = (name, description, assetIds, groupId) => {
   ////DELETE FUNCTION
   // return this.graphData.assetGroups;;
    const listaIds = [];
    const { assets } = this.graphData;
    if (groupId === -1) {
       this.graphData.assetGroupIdCounter++;
      groupId = this.graphData.assetGroupIdCounter;
      assetIds.forEach((assId) => {
        const asset = assets.find(ass => ass.id === assId.key);
        if (!asset.groupIds) {
          asset.groupIds = [];
        }
        listaIds.push(assId.key);
        asset.groupIds.push(parseInt(groupId,10));
      });
    } else {
     // const oldName = this.graphData.assetGroups[groupId].name;
      assets.forEach((asset) => {
        const groupIds = [];
        asset.groupIds.forEach((thisId) => {
          if (thisId !== parseInt(groupId,10)) {
            groupIds.push(thisId);
          }
        });
        asset.groupIds = groupIds;
      });

      assetIds.forEach((assId) => {
        const asset = assets.find(ass => ass.id === assId.key);
        if (!asset.groupIds) {
          asset.groupIds = [];
        }
        listaIds.push(assId.key);
        asset.groupIds.push(parseInt(groupId,10));
      });
    }
    const group = {
      name,
      description,
      assetIds: listaIds,
      id: parseInt(groupId,10),
    };

    const groupkey = this.graphData.assetGroups.findIndex(grp => grp.id === parseInt(groupId,10));
    if (groupkey === -1) {
      this.graphData.assetGroups.push(group);
    } else {
      this.graphData.assetGroups[groupkey] = group;
    }
    this.Api.patchGraphData(this.parentGraphData);

    return this.graphData.assetGroups;
  }

  removeNode = (nodeKey, fremoveOther) => {
    let removeOther = fremoveOther;
    if(removeOther === undefined)
    {
      removeOther = true
    }
    const { model } = this.state;
    const nodeToRemoveIndex = model.nodeDataArray.findIndex(node => node.key === nodeKey);
    if (nodeToRemoveIndex === -1) {
      return;
    }
    if(typeof nodeKey == 'string')
    {
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [
          ...model.nodeDataArray.slice(0, nodeToRemoveIndex),
          ...model.nodeDataArray.slice(nodeToRemoveIndex + 1),
        ],
        linkDataArray: [...model.linkDataArray],
      });
      this.setState({
        model: model2,
      });
      return;
    }

    const nodes = model.nodeDataArray;


    if (removeOther && nodes[nodeToRemoveIndex].addon) {
      this.removeNodeByKey(nodes[nodeToRemoveIndex].addon, false);
    }
    if (removeOther && nodes[nodeToRemoveIndex].addedTo) {
      this.removeNodeByKey(nodes[nodeToRemoveIndex].addedTo, false );
    }
    let { assets, assetGroups } = this.graphData;
    const assetToRemoveIndex = assets.findIndex(asset => asset.id === nodes[nodeToRemoveIndex].key);
    if (assetToRemoveIndex === -1) {
      return;
    }
    for (let i = 0; i < assetGroups.length ; i += 1) {
      const { assetIds } = assetGroups[i];
      const index = assetIds.indexOf(nodeKey);
      if (index > -1) {
        assetIds.splice(index, 1);
        assetGroups[i].assetIds = assetIds;
      }
    }
    assets = [...assets.slice(0, assetToRemoveIndex), ...assets.slice(assetToRemoveIndex + 1)];
    this.graphData.assets = assets;
    this.graphData.assetGroups = assetGroups;
    this.Api.patchGraphData(this.parentGraphData);

    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [
        ...model.nodeDataArray.slice(0, nodeToRemoveIndex),
        ...model.nodeDataArray.slice(nodeToRemoveIndex + 1),
      ],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
    }, ()=>{
      this.dataSettet();
    });
  }

  removeLink = (linKToRemove, fpatchGraph) => {
    let patchGraph = fpatchGraph;
    if (patchGraph === undefined) {
      patchGraph = true;
    }
    const { model } = this.state;
    const linkToRemoveIndex = model.linkDataArray.findIndex(link => link.from === linKToRemove.from && link.to === linKToRemove.to);
    if (linkToRemoveIndex === -1) {
      return;
    }

    let { assetFlows } = this.graphData;
    const { assets } = this.graphData;

    const assetFlowsToRemoveIndex = assetFlows.findIndex(af => af.id === linKToRemove.id);

    if (assetFlowsToRemoveIndex === -1) {
      return;
    }
    const destinationAssetIdToChange = assetFlows[assetFlowsToRemoveIndex].destinationAssetId;
    const sourceAssetIdToChange = assetFlows[assetFlowsToRemoveIndex].sourceAssetId;
    assetFlows = [...assetFlows.slice(0, assetFlowsToRemoveIndex), ...assetFlows.slice(assetFlowsToRemoveIndex + 1)];

    const destinationAssetToChangeIndex = assets.findIndex(asset => asset.id === destinationAssetIdToChange);
    const assetToChange = assets[destinationAssetToChangeIndex];
    const idFlowToRemove = assetToChange.inFlowIds.findIndex(inId => inId === linKToRemove.id);
    assetToChange.inFlowIds = [...assetToChange.inFlowIds.slice(0, idFlowToRemove), ...assetToChange.inFlowIds.slice(idFlowToRemove + 1)];
    assets[destinationAssetToChangeIndex] = assetToChange;

    const sourceAssetToChangeIndex = assets.findIndex(asset => asset.id === sourceAssetIdToChange);
    const assetToChange2 = assets[sourceAssetToChangeIndex];
    const idFlowToRemove2 = assetToChange2.outFlowIds.findIndex(outId => outId === linKToRemove.id);
    assetToChange2.outFlowIds = [...assetToChange2.outFlowIds.slice(0, idFlowToRemove2), ...assetToChange2.outFlowIds.slice(idFlowToRemove2 + 1)];
    assets[sourceAssetToChangeIndex] = assetToChange2;

    this.graphData.assets = assets;
    this.graphData.assetFlows = assetFlows;
    if (patchGraph) {
      this.Api.patchGraphData(this.parentGraphData);
    }
    const nodes = model.nodeDataArray;
    const fromNodeIndex = nodes.findIndex(node => node.key === linKToRemove.from);

    const outVectorsIndex = nodes[fromNodeIndex].item.outFlow.vectors.findIndex((v) => {
      if (v.vNK) {
        return v.vNK === linKToRemove.vectorItem.naturalKey;
      }
      if (v.vNKs) {
        for (let i = 0; i < v.vNKs.length; i += 1) {
          const vNK = v.vNKs[i];
          if (vNK === linKToRemove.vectorItem.naturalKey) {
            return true;
          }
        }
      }
      return false;
    });
    if (outVectorsIndex !== -1 && nodes[fromNodeIndex].item.outFlow.vectors[outVectorsIndex].vector !== undefined) {
      const outVectorToRemove = nodes[fromNodeIndex].item.outFlow.vectors[outVectorsIndex].vector.findIndex(v => v.naturalKey === linKToRemove.vectorItem.naturalKey);
      nodes[fromNodeIndex].item.outFlow.vectors[outVectorsIndex].vector.splice(outVectorToRemove, 1);
    };
    const toNodeIndex = nodes.findIndex(node => node.key === linKToRemove.to);
    const inVectorsIndex = nodes[toNodeIndex].item.inFlow.vectors.findIndex((v) => {
      if (v.vNK) {
        return v.vNK === linKToRemove.vectorItem.naturalKey;
      }
      if (v.vNKs) {
        for (let i = 0; i < v.vNKs.length; i += 1) {
          const vNK = v.vNKs[i];
          if (vNK === linKToRemove.vectorItem.naturalKey) {
            return true;
          }
        }
      }
      return false;
    });
    if (inVectorsIndex !== -1 &&  nodes[toNodeIndex].item.inFlow.vectors[inVectorsIndex].vector!== undefined) {
      const inVectorToRemove = nodes[toNodeIndex].item.inFlow.vectors[inVectorsIndex].vector.findIndex(v => v.naturalKey === linKToRemove.vectorItem.naturalKey);
      nodes[toNodeIndex].item.inFlow.vectors[inVectorsIndex].vector.splice(inVectorToRemove, 1);
    }
    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: nodes,
      linkDataArray: [
        ...model.linkDataArray.slice(0, linkToRemoveIndex),
        ...model.linkDataArray.slice(linkToRemoveIndex + 1),
      ],
    });
    this.setState({
      model: model2,
    });
  }

  undoDoubleClick = () => {
    const { onCloseEdit } = this.props;
    onCloseEdit()
    const { model } = this.state;

    const model2 = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...model.nodeDataArray],
      linkDataArray: [...model.linkDataArray],
    });
    this.setState({
      model: model2,
      doubledClick: null,
      addIfOk: false,
    }, () => {
      this.dataSettet();
    });
  }

  nodeSelectionHandler = (nodeKey, isSelected, callback) => {
    const { model, selectedNodeKeys } = this.state;
    
    if(!isSelected){
      const oldkey = selectedNodeKeys && selectedNodeKeys[0] ? selectedNodeKeys[0] : null;
      const index = model.nodeDataArray.findIndex(na => na.key === oldkey);
      if(index !== null && index >=0){
        model.nodeDataArray[index].isHover = false;
        model.nodeDataArray[index].isExpanded = false;
      }
    }
    if (isSelected) {
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...model.nodeDataArray],
        linkDataArray: [...model.linkDataArray],
      });
      const snk = selectedNodeKeys;
      if (!selectedNodeKeys.includes(nodeKey)) {
        snk.push(nodeKey);
      }
      
      this.setState({
        model: model2,
        selectedNodeKeys: snk,
      },()=>{
        if(typeof callback == "function"){
          callback();
        }
      });
    } else {
      const nodeIndexToRemove = selectedNodeKeys.findIndex(key => key === nodeKey);
      if (nodeIndexToRemove === -1) {
        return;
      }
      const snk = selectedNodeKeys;
      snk.splice(nodeIndexToRemove, 1);
      const model2 = go.GraphObject.make(go.GraphLinksModel, {
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [...model.nodeDataArray],
        linkDataArray: [...model.linkDataArray],
      });

      this.setState({
        model: model2,
        selectedNodeKeys: snk,
      },()=>{
        if(typeof callback == "function"){
          callback();
        }
      });
    }
  }

  addFlows = (listaFlows, nodi, callback, fCount, fFlows) => {
    let count = fCount;
    let flows = fFlows;
    if (count === undefined) {
      count = -1;
      flows = [];
    }
    count += 1;
    if (listaFlows[count] === undefined) {
      callback(flows, nodi);
      return;
    }
    const f = listaFlows[count];

    const newThis = this;
    const { vectors } = this;
    const vector = vectors.find(v => v.naturalKey === f.vector);
    f.fromKey = f.fromKey ? f.fromKey : f.sourceAssetId;
    f.toKey = f.toKey ? f.toKey : f.destinationAssetId;

    this.linkAddedNoSave(f.fromKey, f.toKey, f.vector, f.id, nodi, (nodi) => {
      const linksToAdd = {
        from: f.sourceAssetId,
        to: f.destinationAssetId,
        color: vector.type.color,
        vectorItem: vector,
        id: f.id,
      };
      flows = flows.concat(linksToAdd);
      newThis.addFlows(listaFlows, nodi, callback, count, flows);
    });
  }

  renderPopup = () => {
    const { showMeterAsset, selectedNodeKeys, showError, assetTypesForComplex, showAddNode, dropdownOpen, dropdownIndex, firstSelectedItem, secondSelectedItem, vectors, vectorItem, doubledClick, editError, model, firstDraggedSelectedItem, secondDraggedSelectedItem, vectorIntersection } = this.state;
    const { parentGraphId, virtualMeter } = this;
    const data = { showMeterAsset, selectedNodeKeys, showError, assetTypesForComplex, showAddNode, dropdownOpen, dropdownIndex, firstSelectedItem, secondSelectedItem, vectors, vectorItem, doubledClick, editError, model, firstDraggedSelectedItem, secondDraggedSelectedItem, vectorIntersection, parentGraphId, graphData: this.graphData, virtualMeter }
    const functions = { productDetailSave: this.productDetailSave, clearError: this.clearError, selectedVectorAfterDrag: this.selectedVectorAfterDrag, undoDoubleClick: this.undoDoubleClick, meterAsset: this.meterAsset, saveMeterAsset: this.saveMeterAsset, undoShowMeterAsset: this.undoShowMeterAsset, undoshowError: this.undoshowError, onSelectAssetTypeForComplex: this.onSelectAssetTypeForComplex, undoAssetComplesso: this.undoAssetComplesso, onSelectFirst: this.onSelectFirst, onSelectSecond: this.onSelectSecond, assetMouseEnter: this.assetMouseEnter, assetMouseOut: this.assetMouseOut, onSelectVector: this.onSelectVector, deleteVectorItem: this.deleteVectorItem, undoAddNode: this.undoAddNode, patchData: this.patchData, saveNode: this.saveNode, deleteFirstItem: this.deleteFirstItem, deleteSecondItem: this.deleteSecondItem, undoAddVettore: this.undoAddVettore };
    return <PopupEditor data={data} functions={functions} />;
  }
  checkShowComplexButton = () => {
    const { selectedNodeKeys, model } = this.state;
    const { parentGraphId } = this;
    let showComplexGraphButton = selectedNodeKeys.length === 1 && parentGraphId === undefined;
    if (selectedNodeKeys.length > 1 && parentGraphId === undefined) {
      const { linkDataArray, nodeDataArray } = model;
      showComplexGraphButton = true;
      for (let i = 0; i < selectedNodeKeys.length; i += 1) {
        const key = selectedNodeKeys[i];
        let thisNodeOk = false;
        const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === key);
        const inFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.to === key);
        for (let j = 0; j < outFlowIndex.length; j += 1) {
          const outKey = linkDataArray[outFlowIndex[j]];
          if (selectedNodeKeys.indexOf(outKey.to) > -1) {
            thisNodeOk = true;
          }
        }
        for (let j = 0; j < inFlowIndex.length; j += 1) {
          const inKey = linkDataArray[inFlowIndex[j]];
          if (selectedNodeKeys.indexOf(inKey.from) > -1) {
            thisNodeOk = true;
          }
        }
        if (!thisNodeOk) {
          showComplexGraphButton = false;
        }
        const node = nodeDataArray.find(nd => nd.key === key);
        if (node.isComplex) {
          showComplexGraphButton = false;
        }
      }
    }
    return showComplexGraphButton;
  }

  checkRedirect = () => {
    const { siteId } = this;
    const { redirectDefinitivo, redirectTo } = this.state;
    if (redirectDefinitivo !== undefined && redirectDefinitivo) {
      const target = `/sites/${siteId}/management`;
      return <Redirect push to={target} />;
    }
    if (redirectTo !== undefined && redirectTo !== false) {
      const target = redirectTo;
      return <Redirect push to={target} />;
    }
    return false;
  }

  linkAdded(fromKey, toKey, vector, callback) {
    const { model } = this.state;
    const nodes = model.nodeDataArray;
    const fromNodeIndex = nodes.findIndex(node => node.key === fromKey);
    if (fromNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    const toNodeIndex = nodes.findIndex(node => node.key === toKey);
    if (toNodeIndex === -1) {
      callback(nodes, -1);
      return;
    }
    this.graphData.flowIdCounter += 1;
    const newflowId = this.graphData.flowIdCounter;
    const dt = {
      id: newflowId,
      name: `Flows ${newflowId}`,
      sourceAssetId: fromKey,
      destinationAssetId: toKey,
      vector: vector.naturalKey,
    };
    this.flowId += 1;

    this.graphData.assetFlows.push(dt);
    const { assets } = this.graphData;
    const fromAssetIndex = assets.findIndex(ast => ast.id === fromKey);
    if (assets[fromAssetIndex].outFlowIds === undefined) {
      assets[fromAssetIndex].outFlowIds = [];
    }
    assets[fromAssetIndex].outFlowIds.push(newflowId);

    const toAssetIndex = assets.findIndex(ast => ast.id === toKey);
    if (assets[toAssetIndex].inFlowIds === undefined) {
      assets[toAssetIndex].inFlowIds = [];
    }
    assets[toAssetIndex].inFlowIds.push(newflowId);

    this.Api.patchGraphData(this.parentGraphData, () => {
      let outVectorId = nodes[fromNodeIndex].item.outFlow.vectors.findIndex(v => v.vNK === vector.naturalKey);
      if (outVectorId === -1) {
        const { vectors } = nodes[fromNodeIndex].item.outFlow;
        let cnt = 0;
        vectors.forEach((v) => {
          if (v.vNKs && v.vNKs.indexOf(vector.naturalKey) >= 0) {
            outVectorId = cnt;
          }
          cnt += 1;
        });
      }
      if (nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector === undefined) {
        nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector = [];
      }
      nodes[fromNodeIndex].item.outFlow.vectors[outVectorId].vector.push(vector.naturalKey);

      let inVectorId = nodes[toNodeIndex].item.inFlow.vectors.findIndex(v => v.vNK === vector.naturalKey);

      if (inVectorId === -1) {
        const { vectors } = nodes[toNodeIndex].item.inFlow;
        let cnt = 0;
        vectors.forEach((v) => {
          if (v.vNKs && v.vNKs.indexOf(vector.naturalKey) >= 0) {
            inVectorId = cnt;
          }
          cnt += 1;
        });
      }
      if (nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector === undefined) {
        nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector = [];
      }
      nodes[toNodeIndex].item.inFlow.vectors[inVectorId].vector.push(vector.naturalKey);
      callback(nodes, newflowId);
    });
  }

  
  doubledClick(nodeKey) {
    if(typeof nodeKey === 'string')
    {
      return;
    }

    this.setState({
      
      doubledClick: nodeKey,
      editError: null,
      addIfOk: false,
    });
  }

  expandButton = (e, obj) => {
    let node = obj.part;
    let { model } = this.state;
    const { linkDataArray, nodeDataArray } = model;
    const index = nodeDataArray.findIndex(na => na.key === node.key);
    nodeDataArray[index].isExpanded = true;
   

    model = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...nodeDataArray],
      linkDataArray: [...linkDataArray],
    });
    this.setState({model});
  }

  removeSubHover = (e, obj) => {
    let node = obj.part;
    let { model } = this.state;
    const { linkDataArray, nodeDataArray } = model;
    const index = nodeDataArray.findIndex(na => na.key === node.key);
    if(nodeDataArray[index]){
      nodeDataArray[index].subhover = false;
    }
    
    model = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...nodeDataArray],
      linkDataArray: [...linkDataArray],
    });
    this.setState({model});

    if (!obj.isEnabledObject()) return;
        var shape = obj.findObject('ButtonBorder');  // the border Shape
        if (shape instanceof go.Shape) {
          shape.fill = obj['_buttonFillNormal'];
          shape.stroke = obj['_buttonStrokeNormal'];
        }
  }

  setSubHoveredEdit = (e, obj) => {
    this.setSubHoberType(obj, 'edit');
  }

  setSubHoveredTachometer = (e, obj) => {
    this.setSubHoberType(obj, 'tachometer');
  } 

  setSubHoverMindMap = (e, obj) => {
    this.setSubHoberType(obj, 'mindmap');
  }

  setSubHoverTrash = (e, obj) => {
    this.setSubHoberType(obj, 'trash');
  }
  setSubHoberType = (obj, type) => {
    let node = obj.part;
    let { model } = this.state;
    const { linkDataArray, nodeDataArray } = model;
    const index = nodeDataArray.findIndex(na => na.key === node.key);
    nodeDataArray[index].isExpanded = true;
    nodeDataArray[index].subhover = type;
    model = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...nodeDataArray],
      linkDataArray: [...linkDataArray],
    });
    this.setState({model});

    this.setButtonHover(obj);
  }
  setButtonHover = (obj) => {
    if (!obj.isEnabledObject()) return;
    var shape = obj.findObject('ButtonBorder');  // the border Shape
    if (shape instanceof go.Shape) {
      var brush = obj['_buttonFillOver'];
      obj['_buttonFillNormal'] = shape.fill;
      shape.fill = brush;
      brush = obj['_buttonStrokeOver'];
      obj['_buttonStrokeNormal'] = shape.stroke;
      shape.stroke = brush;
    }
  }

  implodeButton = (e, obj) => {
    let node = obj.part;
    let { model } = this.state;
    const { linkDataArray, nodeDataArray } = model;
    const index = nodeDataArray.findIndex(na => na.key === node.key);
    if(nodeDataArray[index]){
      nodeDataArray[index].isExpanded = false;
      nodeDataArray[index].subhover = false;
    }
    model = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...nodeDataArray],
      linkDataArray: [...linkDataArray],
    });
    this.setState({model});
  }

  showAssetDataMenu = (e, obj) => {
    const node = obj.part;
    const selectedNodeKeys = [];
    this.setState({selectedNodeKeys}, () => {
      this.diagram.select(this.diagram.findNodeForKey(node.key));
      this.nodeSelectionHandler(node.key, true,()=>{
        this.showAssetData();
      });
    }); 
  }

  showMeterAssetMenu = (e, obj) => {
    const node = obj.part;
    const selectedNodeKeys = [];
    this.setState({selectedNodeKeys}, () => {
      this.diagram.select(this.diagram.findNodeForKey(node.key));
      this.nodeSelectionHandler(node.key, true,()=>{
        this.showMeterAsset();
      });
    }); 
  }

  generaAssetComplessoMenu = (e, obj) => {
    const node = obj.part;
    const selectedNodeKeys = [];
    this.setState({selectedNodeKeys}, () => {
      this.diagram.select(this.diagram.findNodeForKey(node.key));
      this.nodeSelectionHandler(node.key, true,()=>{
        this.generaAssetComplesso();
      });
    }); 
  }

  removeSelectedNodeMenu = (e, obj) => {
    const node = obj.part;
    this.removeNodeByKey(node.key);
  }
  
  showPorts(node, fShow) {
    
    let show = fShow;
    if (this.getFreeVectors(node.data.item, 'outFlow').length === 0) {
      show = false;
    }
    const { diagram } = node;
    let { model } = this.state;
    const { selectedNodeKeys } = this.state;
    const { linkDataArray, nodeDataArray } = model;
    const index = nodeDataArray.findIndex(na => na.key === node.key);
    if(index !== null && index >= 0 && !nodeDataArray[index].isExpanded){
      nodeDataArray[index].isHover = fShow;
      if (selectedNodeKeys && selectedNodeKeys.length > 0 && selectedNodeKeys[0] === node.key) {
        nodeDataArray[index].isHover = true;
      }
      if(nodeDataArray[index].isHover === false){
        nodeDataArray[index].isExpanded = false;
      }
    }else if (index !== null && index >= 0 && !(selectedNodeKeys && selectedNodeKeys.length > 0 && selectedNodeKeys[0] === node.key)) {
      if(!fShow){
        nodeDataArray[index].isHover = false;
        nodeDataArray[index].isExpanded = false;
      }
    }
    model = go.GraphObject.make(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [...nodeDataArray],
      linkDataArray: [...linkDataArray],
    });
    this.setState({model});
    if (!diagram || diagram.isReadOnly || !diagram.allowLink) return;
    node.ports.each((port) => {
      port.stroke = (show ? 'black' : null);
      port.fill = (show ? 'black' : null);
    });
  }

  changeButtonTab = (tab) => {
    this.setState({tab})
  }

  
  render() {
    
    const { selectedNodeKeys, model, tab, doubledClick, editError, clearError, showMeterAsset } = this.state;
    const { parentGraphId, parentGraphData, energyAsset } = this;
    const redirect = this.checkRedirect();
    if (redirect) {
      return redirect;
    }
    const showComplexGraphButton = this.checkShowComplexButton();
    const isEmpty = model.nodeDataArray.length === 0;
    const popup = this.renderPopup();
    let assetType = null;
    if (parentGraphId && parentGraphData) {
      const { assets } = parentGraphData;
      const asset = assets.find(ass => ass.id === parseInt(parentGraphId, 10));
      if (asset) {
        const { supportedAssetId } = asset;
        assetType = energyAsset.find(ea => ea.id === supportedAssetId);
      }
    }
    
    if (doubledClick !== undefined && doubledClick != null) {
      
      const { productDetailSave, graphData, patchData, undoDoubleClick } = this;
      const { onShowEdit } = this.props;
      onShowEdit(model, doubledClick, productDetailSave, clearError, editError, graphData, parentGraphId, patchData, undoDoubleClick);
      return null;
    }

    if (showMeterAsset !== undefined && showMeterAsset != null) {
      
      const { meterAsset, virtualMeter, saveMeterAsset } = this;
      const { onShowMeterAssetManagement } = this.props;
      
      onShowMeterAssetManagement(model, meterAsset, virtualMeter, saveMeterAsset, selectedNodeKeys);
      return null;
    }
    const assetsList = (
      <AssetsList
        onAddNode={this.addNode}
        pushVectorElems={this.pushVectorElems}
        callbackAssetList={this.setEnergyAssetList}
        isEmpty={isEmpty}
        matchAsset={assetType}
        siteId={this.siteId}
      />
    );
    return (
      <div>
        {popup}
        <DiagramContainer fluid>
          <Row>
            <Col md={12} className="NodeGraph2">
              <DiagramButtons
                key="diagramButtons"
                onInit={this.initModelHandler}
                onSave={this.saveGraph}
                onAddLink={this.addLink}
                selectedNodeKeys={selectedNodeKeys}
                onNewAssetMeter={this.addMeterAsset}
                onListAssetMeter={this.showMeterAsset}
                onShowDefinitivo={this.showDefinitivo}
                onShowAssetGroup={this.showAssetGroup}
                onGeneraComplesso={this.generaAssetComplesso}
                nodeDataArray={model.nodeDataArray}
                onShowPrincipale={this.showPrincipale}
                showComplexGraphButton={showComplexGraphButton}
                isComplexGraph={parentGraphId !== undefined}
                assetSelect={assetsList}
                onChangeTab={this.changeButtonTab}
                tab={tab}
              />
              <ContextMenu
                onshowAssetData={this.showAssetData}
                onDelete={this.removeSelectedNode}
                onshowMeterAsset={this.showMeterAsset}
                onaddMeterAsset={this.addMeterAsset}
                onShowAssetGroup={this.showAssetGroup}
                onGeneraComplesso={this.generaAssetComplesso}
                selectedNodeKeys={selectedNodeKeys}
                nodeDataArray={model.nodeDataArray}
                showComplexGraphButton={showComplexGraphButton}
              />
              <GojsDiagram
                key="gojsDiagram"
                diagramId="NodeGraphDiv"
                model={model}
                createDiagram={this.createDiagram}
                className="NodeGraph"
                onModelChange={this.modelChangeHandler}
              />
            </Col>
          </Row>
        </DiagramContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

const mapDispatchToProps = dispatch => ({
  updateAssetGraph: assetGraph => dispatch(updateAssetGraph(assetGraph)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NodeGraph);
