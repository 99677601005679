import React, { useState, useEffect } from 'react';
import ReactMultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Button } from 'app/common';
import { getOpByNodeInfo, getCurrentUserDashboardsByNodeInfo, getDashboardOPs } from 'api';
import { Trans } from '@lingui/macro';
import { getAssetGraphByNode } from './viewerApi';
import './multiselect-style.css';
import { ViewerCheckbox, ViewerSelectAll, ViewerSelectedItem, ViewerSearch, ViewerNoItems, ViewerSelectionStatus } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const MultiSelect = props => {
  const { items, selectedItems, setSelectedItems } = props;

  function handleChange(selectedItems) {
    setSelectedItems(selectedItems);
  }

  return (
    <ReactMultiSelect
      items={items}
      withGrouping
      selectedItems={selectedItems}
      onChange={handleChange}
      itemRenderer={ViewerCheckbox}
      selectedItemRenderer={ViewerSelectedItem}
      selectAllRenderer={ViewerSelectAll}
      searchRenderer={ViewerSearch}
      noItemsRenderer={ViewerNoItems}
      selectionStatusRenderer={ViewerSelectionStatus}
    />
  );
};

const ViewerAuth = props => {

  const CheckBox = styled(FontAwesomeIcon)`
    margin: 0 0.7rem;
    width: 0.9rem !important;
    cursor: pointer;
    color: ${props => (props.checked ? `${props.theme.common.brand.primaryColor}` : '#aaa')};
`;
  const FullscreenText = styled.span`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #666666;
`;
  const DashboardsTitle = styled.h4`
    padding: 1em;
    display: flex;
    align-items: end;
`;

  const {
    nodeInfo,
    goBackOk,
    goBackCancel,
    setVWRDetails,
    getVWRDetails,
    siteNames,
    siteGroupNames,
    companyNames,
    domainNames,
    dashboardVwrList
  } = props;
  const { userName } = nodeInfo;
  const [fullscreenChecked, setFullscreenChecked] = useState(dashboardVwrList && dashboardVwrList.filter(d => d.nodeId === nodeInfo._id).length > 0);
  const [opList, setOpList] = useState([]);
  const [opSelectedList, setOpSelectedList] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);
  const [dashboardSelectedList, setDashboardSelectedList] = useState([]);
  const [assetGraphList, setAssetGraphList] = useState([]);
  const [assetGraphSelectedList, setAssetGraphSelectedList] = useState([]);

  useEffect(() => {
    const getOpBelongingNodeName = op => {
      const { siteId, siteGroupId, companyId, domainId } = op;
      let nodeName = '';
      if (siteId) {
        nodeName = siteNames[siteId];
      } else if (siteGroupId) {
        nodeName = siteGroupNames[siteGroupId];
      } else if (companyId) {
        nodeName = companyNames[companyId];
      } else if (domainId) {
        nodeName = domainNames[domainId];
      }
      return nodeName;
    };

    const VWRDetails = getVWRDetails(nodeInfo);
    getOpByNodeInfo(nodeInfo, { domain: false }).then(observedPropertiesData => {
      if (!observedPropertiesData.err && observedPropertiesData.data) {
        const opList = observedPropertiesData.data.map(opData => ({
          id: opData.id,
          label: opData.name,
          group: getOpBelongingNodeName(opData)
        }));
        setOpList(opList);
        const initOpSelectedList =
          VWRDetails && VWRDetails.ObservedProperty
            ? opList.filter(op => VWRDetails.ObservedProperty.includes(op.id))
            : [];
        setOpSelectedList(initOpSelectedList);
      }
    });
    getCurrentUserDashboardsByNodeInfo(nodeInfo).then(dashboardsData => {
      if (!dashboardsData.err && dashboardsData.data) {
        const initDashboardList = dashboardsData.data
          .filter(dashboard => !dashboard.personalInstance)
          .map(dashBoard => ({
            id: dashBoard.id,
            label: dashBoard.name,
            group: getOpBelongingNodeName(dashBoard)
          }));
        console.log('dashboardsData ', dashboardsData);
        setDashboardList(initDashboardList);
        const initDashboardSelectedList =
          VWRDetails && VWRDetails.Dashboard
            ? initDashboardList.filter(dashboard => VWRDetails.Dashboard.includes(dashboard.id))
            : [];
        setDashboardSelectedList(initDashboardSelectedList);
      }
    });
    getAssetGraphByNode(nodeInfo).then(assetGraphData => {
      if (!assetGraphData.err && assetGraphData.data) {
        console.log('assetGraphData ', assetGraphData);
        const initAssetGraphList = assetGraphData.data;
        setAssetGraphList(initAssetGraphList);
        const initAssetGraphSelectedList =
          VWRDetails && VWRDetails.AssetGraph
            ? initAssetGraphList.filter(assetGraph => VWRDetails.AssetGraph.includes(assetGraph.id))
            : [];
        setAssetGraphSelectedList(initAssetGraphSelectedList);
      }
    });
  }, [nodeInfo]);

  const cancel = () => {
    goBackCancel(nodeInfo);
  };

  async function save() {
    const VWRDetails = {};
    const dashboardIds = dashboardSelectedList.map(dashboard => dashboard.id);

    const opIdList = opSelectedList.map(op => op.id);
    const dashboardOPs = await Promise.all(
      dashboardIds.map(dashboardId => getDashboardOPs(dashboardId))
    );

    const extraOPs = dashboardOPs.reduce((acc, dashboardOpList) => {
      return [...acc, ...dashboardOpList.data.map(op => op.id)];
    }, []);

    const allOPIds = new Set([...extraOPs, ...opIdList]);
    VWRDetails.ObservedProperty = [...allOPIds];
    VWRDetails.Dashboard = dashboardIds;
    VWRDetails.AssetGraph = assetGraphSelectedList.map(assetGraph => assetGraph.id);
    setVWRDetails(nodeInfo, VWRDetails);
    goBackOk({
      nodeId: nodeInfo._id,
      nodeType: nodeInfo._type,
      dashboardList: fullscreenChecked ? dashboardSelectedList.map(d => d.id) : []
    });
  }

  console.debug('ViewerAuth props: ', props);
  console.debug('observedProperties ', opList);
  return (
    <Card>
      <CardHeader>
        <Trans>Impostazione permessi di visibilità per l'utente</Trans>: {userName}
      </CardHeader>
      <CardBody>
        <h4 style={{ padding: '0.75em' }}><Trans>Variabili</Trans></h4>
        <MultiSelect
          items={opList}
          selectedItems={opSelectedList}
          setSelectedItems={setOpSelectedList}
        />
        <DashboardsTitle>Dashboards
        <CheckBox
            icon={fullscreenChecked ? 'check-square' : ['far', 'square']}
            checked={fullscreenChecked}
            onClick={() => setFullscreenChecked(!fullscreenChecked)} />
          <FullscreenText>Visualizzazione automatica fullscreen</FullscreenText>
        </DashboardsTitle>
        <MultiSelect
          items={dashboardList}
          selectedItems={dashboardSelectedList}
          setSelectedItems={setDashboardSelectedList}
        />
        <h4 style={{ padding: '1em' }}>Energy Flow Models</h4>
        <MultiSelect
          items={assetGraphList}
          selectedItems={assetGraphSelectedList}
          setSelectedItems={setAssetGraphSelectedList}
        />
      </CardBody>
      <CardFooter>
        <Button marginRight color="primary" onClick={save}>
          <Trans>OK</Trans>
        </Button>
        <Button color="link" onClick={cancel}>
          <Trans>Annulla</Trans>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ViewerAuth;
