import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <h1><Trans>Something went wrong.</Trans></h1>;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
};


export default ErrorBoundary;
