import React from "react";
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const ViewerNoItems = (props) => {
    return (
        <Item><Trans>Nessun elemento selezionato</Trans></Item>
    );
};


export default ViewerNoItems;