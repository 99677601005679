import React from 'react';
import api from 'api';
import Api from './Api';

class LoaderView extends React.Component {
  constructor(props) {
    super(props);
    this.Api = new Api();
  }

  componentDidMount() {
      this.assetListSet = false;
      this.graphDataSet = false;
      this.meterAssetDataSet = false;   
      this.virtualMeterDataSet = false;
  }

  loadGraph = (siteId,parentGraphId, callback) => {
    this.assetListSet = false;
    this.graphDataSet = false;
    this.meterAssetDataSet = false;
    this.virtualMeterDataSet = false;
    this.parentGraphId = parentGraphId;
    this.siteId = siteId;
    this.callback = callback;
    this.getGraphData();
  }

  getVirtualMetersData = (allObservedProperties, callback) => {
    console.log(allObservedProperties);
    const { siteId } = this;
    api.get(`VirtualMeters?filter={"where": {"siteId":${siteId}}}`).then((result) => {
      const virtualMeter = result.data;

      this.virtualMeter = virtualMeter;
      this.virtualMeterDataSet = true;
      this.dataSettet();
    });
  }

  getGraphData = () => {
    this.getEnergyAssetsList();
    this.getVirtualMetersData();
    const { siteId } = this;
    this.asset = null;
    const newThis = this;
    api.get(`Sites/${siteId}/assetGraph`).then((result) => {
      if (result.data.length !== 0) {
        newThis.setGraphData(result.data);
      }
    }).catch((error) => {
      if (error.response && error.response.status === 404) {
        newThis.createPrimaryGraph(siteId);
      }
    });
  }

  createPrimaryGraph = (siteId) => {
    const dt = {
      graphType: 'main',
      assetIdCounter: 0,
      flowIdCounter: 0,
      assets: [],
      assetGroups: [],
      assetFlows: [],
      parentId: null,
    };
    api.post(`Sites/${siteId}/assetGraph`, dt).then(() => {
      this.getGraphData();
    }).catch(() => { });
  }

  getEnergyAssetsList = () => {
    let jsonVector = [];
    api.get('SupportedAssets').then((result) => {
      const jsonEas = result.data;
      api.get('Catalogs/Vectors').then((result) => {
        jsonVector = result.data;
        let cnt = 0;
        jsonEas.forEach((jsonEa) => {
          jsonEa.index = cnt;
          cnt += 1;
          const inVectors = jsonEa.inFlow.vectors ? jsonEa.inFlow.vectors : [];
          inVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
          const outVectors = jsonEa.outFlow.vectors ? jsonEa.outFlow.vectors : [];
          outVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
        });
        this.setEnergyAssetList(jsonEas, jsonVector);
      });
    });
  }

  setEnergyAssetList = (assetList, jsonVector) => {
    this.energyAsset = assetList;
    this.vectors = jsonVector;
    this.assetListSet = true;
    this.dataSettet();
  }

  setGraphData = (data) => {
    if (this.parentGraphId !== undefined && this.parentGraphId !== undefined) {
      this.graphData = data.assets.find(ass => ass.id === parseInt(this.parentGraphId, 10)).childGraph;
    } else {
      this.graphData = data;
    }
    this.graphId = data.id;
    this.graphDataSet = true;
    this.getMeterAssetsData();
    this.dataSettet();
  }

  getMeterAssetsData = () => {
    const { siteId } = this;
    api.get(`Sites/${siteId}/observedProperties`).then((result) => {
        const observedProperties = result.data;
        
        this.setMeterAssets(null, observedProperties);
      });
  }


  setMeterAssets = (meterAsset, observedProperties) => {
    this.meterAsset = meterAsset;
    this.observedProperties = observedProperties;
    this.meterAssetDataSet = true;
    this.dataSettet();
  }

  dataSettet = () => {
    if (!this.assetListSet || !this.graphDataSet || !this.meterAssetDataSet || !this.virtualMeterDataSet) {
      return;
    }
    let ret =  {
      graphData: this.graphData,
      observedProperties: this.observedProperties,
      energyAsset: this.energyAsset,
      vectors: this.vectors,
      meterAsset: this.meterAsset,
      virtualMeter: this.virtualMeter,
    }
    this.callback(ret);
  }

}

export default LoaderView;
