import React from 'react';
import logo from '../../../images/exacto.svg';
import './Error.css';

const ErrorPage = () => {
  return (
    <div className="Page">
      <header className="Page-header">
        <img src={logo} className="Page-logo" alt="logo" />
        <h2 className="label-color">
          Siamo spiacenti,
          il servizio è momentaneamente indisponibile.
          <br />
          Stiamo lavorando per ripristinarlo nel più breve tempo possibile.
          <br />
          <br />
          Si prega di riprovare più tardi.
        </h2>
      </header>
    </div>
  );
}

export default ErrorPage;
