/* eslint-disable sort-keys */

/**
 * KeyCode
 *
 * Map of common (non-printable) keycodes for the 'keydown' and 'keyup' events.
 * Note that 'keypress' handles things differently and may not return the same
 * values.
 */
module.exports = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  CONTROL: 17,
  ESC: 27,
  SPACE: 32,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  CANC: 46,
  COPY: 67,
  PASTE: 86,
  CUT: 88,
};
