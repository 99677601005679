import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import logger from 'app/common/log';
import { DeleteModal, Button } from 'app/common';
import NewEventTagForm from './CompanyEventTags/NewEventTagForm';
import { createEventTag, editCompanyEventTag, deleteCompanyEventTag } from './apiEventTags';
import { toast } from 'react-toastify';
import { translateEventTag } from './alarmsCommon';

function getTransTitle(type, tagName) {
  let title = '';
  switch (type) {
    case 'new':
      title = i18n._(t`Nuovo Tag`);
      break;
    case 'edit':
      title = i18n._(t`Modifica Tag`) + ` ${tagName}`;
      break;
    case 'delete':
      title = i18n._(t`Elimina Tag`) + ` ${tagName}`;
      break;
    default:
      break;
  }

  return title;
}

const EventTagModals = props => {
  const { isOpen, toggle, data, companyList, reload, language, tags, afterSubmit } = props;
  const { tag, type, predefinedGrant } = data;
  const isNew = type === 'new';
  const isDelete = type === 'delete';
  const isEdit = type === 'edit';
  const bodyForm = React.createRef(null);
  const submitRef = React.createRef(null);

  logger.debug('TagsModal props ', props);

  const onDelete = async tag => {
    logger.debug('onDelete tag ', tag);
    const res = await deleteCompanyEventTag(tag.id);
    if (res.err) {
      toast.error(res.err);
      logger.error('onDelete %o %o', tag, res.err);
    } else {
      logger.debug('onDelete success %o %o', tag, res.data);
      toggle();
      reload();
    }
  };

  const tagValidation = (tagName, tag) => {
    logger.debug('tagValidation tagName ', tagName, tag);
    const nameExist = tags
      .filter(aTag => (tag.predefined ? aTag.companyId == null : aTag.companyId === tag.companyId))
      .find(tag => tag.name && tag.name.toLowerCase() === tagName.toLowerCase());
    let result = true;
    if (nameExist) {
      result = tag.predefined
        ? new Error(i18n._(t`Esiste già un tag predefinito con lo stesso nome`))
        : new Error(i18n._(t`Nell'azienda selezionata esiste già un tag con lo stesso nome`));
    }
    return result;
  };

  const onSubmit = async newTag => {
    logger.debug('onSubmit newTag ', newTag);
    newTag.companyId = newTag.predefined ? null : newTag.companyId;
    // editing predefined tag
    if (newTag.predefined && newTag.id != null) {
      if (language !== 'it') {
        newTag.translations = { ...tag.translations, [language]: newTag.name };
        newTag.name = tag.name;
      }
    }
    newTag.naturalKey = tag.naturalKey;
    /*
    if (newTag.name !== tag.name) {
      newTag.naturalKey = createTagKey(newTag.name);
    }
    */

    const res =
      newTag.id == null ? await createEventTag(newTag) : await editCompanyEventTag(newTag);
    if (res.err) {
      toast.error(res.err);
      logger.error('onSubmit %o %o', newTag, res.err);
    } else {
      logger.debug('onSubmit success %o %o', newTag, res.data);
      if (afterSubmit) afterSubmit(res.data);
      toggle();
      reload();
    }
  };

  const bodyFormSubmitCall = () => {
    if(submitRef.current && !submitRef.current.disabled){
      submitRef.current.disabled = true;
      bodyForm.current.submit().then(res => {
        !res && submitRef.current && (submitRef.current.disabled = false)
      }).catch(err => {
        console.log(err);
        submitRef.current && (submitRef.current.disabled = false);
      });
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <strong>{getTransTitle(type, translateEventTag(tag, language))}</strong>
      </ModalHeader>
      <ModalBody>
        {!isDelete && (
          <NewEventTagForm
            tagValidationFn={tagValidation}
            hidden={isNew || isEdit}
            ref={bodyForm}
            onCancel={toggle}
            onSubmit={onSubmit}
            tag={tag || {}}
            predefinedGrant={predefinedGrant}
            companyList={companyList}
            isNew={isNew}
          />
        )}
        <DeleteModal
          isOpen={isDelete}
          toggleModal={toggle}
          header={getTransTitle(type, translateEventTag(tag, language))}
          onDelete={() => onDelete(tag)}
        >
          <Trans>Sicuro di voler eliminare il tag</Trans>&nbsp;
          {translateEventTag(tag, language)}&nbsp;?
        </DeleteModal>
      </ModalBody>
      <ModalFooter>
        <>
          <Button marginright="true" color="primary" onClick={() => bodyFormSubmitCall()} ref={submitRef}>
            <Trans>Salva</Trans>
          </Button>
          <Button color="secondary" onClick={toggle}>
            <Trans>Annulla</Trans>
          </Button>
        </>
      </ModalFooter>
    </Modal>
  );
};

export default EventTagModals;
