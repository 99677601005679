const primaryColor = '#f0c';
const secondaryColor = '#73818f';

export default {
  common: {
    primaryColor,
    secondaryColor,
    text: {
      primaryColor: '#ddd',
      secondaryColor: '#73818f',
      headerColor: '#fff',
    },
    input: {
      color: '#ddd',
      backgroundColor: '#29363d',
      disabledColor: '#eee',
      disabledBackgroundColor: '#333',
    },
    icons: {
      color: '#73818f',
      hoverColor: '#2f353a',
      activeColor: '#2f353a',
    },
    background: {
      primaryColor: '#111',
      secondaryColor: '#333',
    },
    links: {
      color: '#20a8d8',
      activeColor: '#20a8d8',
      hoverColor: '#20a8d8',
    },
    buttons: {
      primary: {
        color: '#23282c',
        background: primaryColor,
        border: primaryColor,
      },
      secondary: {
        color: '#fff',
        background: secondaryColor,
        border: secondaryColor,
      },
    },
  },
  navbar: {
    borderColor: '#73818f',
    backgroundColor: '#111',
    caretColor: '#20a808',
    caretHoverColor: '#fff',
    color: '#20a808',
    activeBackgroundColor: '#20a808',
    activeColor: '#fff',
    hoverBackgroudColor: '#29363d',
    hoverColor: '#fff',
    iconColor: 'red',
    activeIconColor: 'blue',
  },
  sidebar: {
    width: '200px',
    borderColor: '#73818f',
    backgroundColor: '#111',
    color: '#c00',
    hoverColor: '#fff',
    hoverBackgroudColor: '#f90',
    activeBackgroundColor: '#f60',
    activeColor: '#fff',
    iconColor: '#c00',
    activeIconColor: '#fff',
    openDropdownBackgroundColor: '#222',
  },

};
