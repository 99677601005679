import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import ReactExport from 'react-data-export';
import { CSVLink } from 'react-csv';
import { formatNumber } from 'app/utils/formatNumber';
import { IconButton } from 'app/common';
import api from 'api';
import { getOpById } from 'api';
import styled from 'styled-components';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import { RealTimeContext } from 'app/common/RealTimeProvider';
import PropTypes from 'prop-types';
import WidgetBody from './WidgetBody';
import ImageWidget from './ImageWidget';
import TextWidget from './TextWidget';
import ReportHeader from './ReportHeader';
import ReportFooter from './ReportFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  getStartDate,
  getEndDate,
  adjustDate,
  takeLastValues,
  roundDateByAggregation,
  getPeriodString,
  widgetTypes,
  closedPeriodIntervals
} from '../common';
import TimeProfilePeriodModal from './TimeProfilePeriodModal';
import logger from 'app/common/log';

const Container = styled.div`
  ${props => props.edit && 'border: 1px solid #ccc'};
  background-color: #fff;
  overflow: hidden;
  //overflow: ${props => `${props.overflow}`};

  & .title {
    font-size: 0.9vw;
  }

  & .subtitle {
    font-size: 0.8vw;
  }

  & .ql-snow .ql-editor {
    h1 {
      font-size: 2.5vw;
    }
    h2 {
      font-size: 2vw;
    }
    h3 {
      font-size: 1.5vw;
    }
    p {
      font-size: 1vw;
    }
    img {
      object-fit: scale-down;
    }
  }

  & .c3-bars path {
    shape-rendering: geometricprecision !important;
  }

  & .c3-line {
    shape-rendering: geometricprecision;
    stroke-width: 2px;
  }

  & .c3-tooltip-container table tr th {
    background-color: #1f71b7;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  /* background-color: #fff; */
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  ${Container}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0.5rem;
`;

const Buttons = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const Period = styled.div`
  font-size: 0.9rem;
  color: #999;
  font-style: italic;
`;

const OptionsIcon = styled.img`
  float: right;
`;
const Option = styled.div`
  align-items: center;
  display: flex;
  color: #212529;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 0.5rem !important;
  border-radius: 7px !important;
  min-width: 9rem !important;
`;

const HideDropdown = styled(Dropdown)`
  visibility: hidden;
  ${Container}:hover & {
    visibility: visible;
  }
`;

const StyledCustomMenuItem = styled.div`
  && a {
    color: black;
  }
`;
const Widget = props => {
  const {
    edit,
    className,
    widget,
    style,
    children,
    shouldUpdateWidgets,
    onEdit,
    onDelete,
    userCanSendCommand,
    currentModule,
    additionalData,
    units,
    physicaldimensions,
    saveWidget,
    observableproperties,
    toggleFullscreen,
    isFullscreen,
  } = props;

  //console.log('widget ', widget);
  const headerRef = useRef();

  const { subscribe, unsubscribe, lastValues } = useContext(RealTimeContext);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet, ExcelColumn } = ExcelFile;

  const [data, setData] = useState({});
  const [seriesData, setSeriesData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tempSeriesData, setTempSeriesData] = useState([]);
  const [seriesNames, setSeriesNames] = useState([]);
  const [pieNames, setPieNames] = useState([]);
  const [tableNames, setTableNames] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const [variablesToSubscribe, setVariablesToSubscribe] = useState([]);
  const sum = list => list.reduce((prev, curr) => prev + curr, 0);
  const average = list => sum(list) / list.length;
  const { period, variables } = widget;

  logger.debug('Widget data %o',data);

  function clearKey(key) {
    return key && typeof key === 'string' ? key.replace(/ /g, '_').replace(/\W/g, '') : key;
  }

  function sanitizeExportData(data, names) {
    // replaces objects keys with cleaner ones
    const newData = data.map(obj => {
      const newObj = Object.keys(obj).reduce((tot, key) => {
        const newKey = clearKey(key);
        return { ...tot, [newKey]: obj[key] };
      }, {});

      return newObj;
    });

    const headers = names.map(header => ({
      label: header.label,
      key: clearKey(header.key)
    }));

    return { data: newData, headers };
  }

  const formatDataArray = (variable, data) => {
    if (!data || !units) {
      return { _unit: '', items: [] };
    }
    const unit = units.find(x => x.naturalKey === variable.unit);
    const unitSymbol =
      variable.physicalQuantity === 'custom'
        ? (physicaldimensions.find(x => x.id === variable.customQuantityId) || {}).unitSymbol
        : unit.symbol;
    const offset = unitSymbol === "K" ? 273.15 : unitSymbol === "°F" ? 32 : 0;
    return {
      _unit: unitSymbol,
      items: data.map(x => ({
        t: x.t,
        v: formatNumber(
          x.v,
          offset,
          unit.exactoConversionFactor,
          variable.valueType,
          variable.decimalDigits
        )
      })),
      rate: variable.rate
    };
  };

  const formatDataAggregate = (variable, data) => {
    if (!data || !units || !physicaldimensions) {
      return { _unit: '', value: {} };
    }
    const { valueType } = variable;
    let value;
    if (valueType === 'instant') {
      value = average(data.map(x => x.v));
    } else {
      value = sum(data.map(x => x.v));
    }
    const unit = units.find(x => x.naturalKey === variable.unit);
    const unitSymbol =
      variable.physicalQuantity === 'custom'
        ? (physicaldimensions.find(x => x.id === variable.customQuantityId) || {}).unitSymbol
        : unit.symbol;
    return {
      _unit: unitSymbol,
      value: {
        t: (data[data.length - 1] || {}).t,
        v: formatNumber(
          value,
          0,
          unit.exactoConversionFactor,
          variable.valueType,
          variable.decimalDigits
        )
      },
      rate: variable.rate
    };
  };

  const formatDataValue = (variable, value) => {
    if (!value || !units || !physicaldimensions) {
      return { _unit: '', value: {} };
    }
    const { v, t } = value;
    const unit = units.find(x => x.naturalKey === variable.unit);
    const unitSymbol =
      variable.physicalQuantity === 'custom'
        ? (physicaldimensions.find(x => x.id === variable.customQuantityId) || {}).unitSymbol
        : unit.symbol;
    const offset = unitSymbol === "K" ? 273.15 : unitSymbol === "°F" ? 32 : 0;
    return {
      unitData: unit,
      _unit: unitSymbol,
      value: {
        t,
        v: formatNumber(
          v,
          offset,
          unit.exactoConversionFactor,
          variable.valueType,
          variable.decimalDigits
        )
      },
      rate: variable.rate
    };
  };

  const getUnit = variable => {
    const unit = units.find(x => x.naturalKey === variable.unit);
    return variable.physicalQuantity === 'custom'
      ? (physicaldimensions.find(x => x.id === variable.customQuantityId) || {}).unitSymbol
      : unit.symbol;
  };

  const dataToPass = useMemo(() => {
    if (period && widget.widgetType !== 'reportHeader' && widget.widgetType !== 'reportFooter') {
      const { interval, aggregation } = period;
      if (widget.widgetType === widgetTypes.SERIES) {
        const tempNames = [
          { label: 'timestamp', key: 'timestamp' },
          ...widget.variables.map(v => {
            return { label: `${v.label} [${getUnit(v)}]`, key: v.name };
          })
        ];
        setSeriesNames(tempNames);
        const xlsData = tempSeriesData.map(data => {
          return {
            ...data,
            ...tempNames
              .map(n => n.key)
              .filter(key => !Object.keys(data).includes(key))
              .map(n => {
                return { [n]: 0 };
              })
          };
        });
        setSeriesData(
          xlsData.sort((a, b) => {
            return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
          })
        );
        return widget.variables.map((x, i) => ({
          ...x,
          ...formatDataArray(
            x,
            data[`${x.id}_${aggregation}`] || [],
            i === widget.variables.length - 1
          )
        }));
      }
      if (
        (widget.widgetType === widgetTypes.PIE || widget.widgetType === widgetTypes.DISPLAY) &&
        interval !== 'lastvalue'
      ) {
        setPieNames([
          { label: '', key: 'label' },
          { label: `[${getUnit(widget.variables[0])}]`, key: 'value' },
          { label: '%', key: 'ratio' }
        ]);
        const dt = widget.variables.map(x => ({
          ...x,
          ...formatDataAggregate(x, data[`${x.id}_${aggregation}`] || [])
        }));
        const tot = dt
          .map(d => parseFloat(d.value.v))
          .reduce((accumulator, currentValue) => accumulator + currentValue);
        dt &&
          setPieData(
            dt.map(d => {
              const value = parseFloat(d.value.v);
              return {
                decimalDigits: d.decimalDigits,
                label: d.label,
                value: value || 0,
                ratio: value ? Math.round((value / tot) * 100 * 10) / 10 : 0
              };
            })
          );
        return dt;
      }
      return widget.variables.map(x => ({
        ...x,
        ...formatDataValue(
          x,
          lastValues[`${x.id}_${aggregation}`] || initialData[`${x.id}_${aggregation}`]
        )
      }));
    }
  }, [data, lastValues, initialData, units, physicaldimensions, period]);

  const handleNewData = (subscription, message) => {
    const { id, aggregation } = subscription;
    const { t, v } = message;
    const key = `${id}_${aggregation}`;
    setData(prevData => {
      const dataNew = prevData[key] || [];
      if (dataNew.length > 0 && new Date(t) <= dataNew[dataNew.length - 1].t) {
        return prevData;
      }
      dataNew.push({ t: new Date(t), v });
      if (aggregation === '1m') {
        takeLastValues(dataNew, period.interval);
        return { ...prevData, [key]: dataNew };
      }

      const start = roundDateByAggregation(
        getStartDate(new Date(), period.interval),
        period.aggregation
      );
      return { ...prevData, [key]: dataNew.filter(x => x.t >= start) };
    });
  };

  const loadData =async (variables,period) => {
    const { interval, aggregation, aggregation_date, rows } = period;
    logger.debug('widget loadData period %o', period);
    const end = getEndDate(new Date(), interval, aggregation);
    const startDateToRound = getStartDate(new Date(), interval, moment(aggregation_date));
    const start = roundDateByAggregation(startDateToRound, aggregation);

    if (rows && widget.widgetType === widgetTypes.TABLE) {
      return;
    }
    let xlsData = [];
    
    for (const variable of variables) {
      const { id } = variable;
      const key = `${id}_${aggregation}`;
      if (interval === 'lastvalue') {
        try {
          const res = await api.get(`ObservedProperties/${id}/${aggregation}/lastSample`);
          const d = res.data;
          logger.debug('widget loadData got lastSample for %o %o', variable, period);
          setInitialData(prevData => ({ ...prevData, [key]: { t: new Date(d.t), v: d.v } }));
        } catch (e) {
          logger.error('Widget lastSample loadData %o %s %o', variable, aggregation, e);
        }
      } else {
        try {
          // se aggregazione 1 minuto, poiché i valori vengono calcolati in ritardo, leggo qualche minuto in più
          // e prendo solo il giusto numero di valori (es: 1h => 60 munuti)
          let startDate =
            aggregation === '1m'
              ? moment(start)
                  .subtract(5, 'minutes')
                  .format()
              : moment(start).format();
          let endDate = moment(end).format();

          // adeguo le date se necessario
          const dates = adjustDate(startDate, endDate, interval, aggregation);
          const res = await api.get(
            `ObservedProperties/${id}/${aggregation}/timeSeries/${dates[0]}/${dates[1]}`
          );
          const { timeSeries } = res.data;
          logger.debug('widget loadData got #%s data for %o %o', timeSeries&& timeSeries.length, variable, period);
          // totRes++;
          if (aggregation === '1m') {
            takeLastValues(timeSeries, interval);
          }
          for (const ts of timeSeries) {
            const unit = units.find(x => x.naturalKey === variable.unit);
            const index = xlsData.findIndex(
              data => data.timestamp === moment(new Date(ts.t)).format('DD/MM/YYYY HH:mm:ss')
            );
            const varValue = {
              [variable.name]: formatNumber(
                ts.v,
                0,
                unit.exactoConversionFactor,
                variable.valueType,
                variable.decimalDigits
              )
            };
            if (index > -1) {
              xlsData[index] = {
                ...xlsData[index],
                ...varValue
              };
            } else {
              xlsData.push({
                timestamp: moment(new Date(ts.t)).format('DD/MM/YYYY HH:mm:ss'),
                ...varValue
              });
            }
          }
          setTempSeriesData(xlsData);
          const newData  = {
            [key]: timeSeries.map(x => ({ t: new Date(x.t), v: x.v }))
          };
          logger.debug('widget loadData setNewData for %o %o', variable, newData);
          setData(prevData => ({
            ...prevData,
            [key]: timeSeries.map(x => ({ t: new Date(x.t), v: x.v }))
          }));
        }
        catch(e)  {
          logger.debug('error Widget timeSeries loadData %o %s %o', variable, aggregation, e);
        }
      }
    };
  };

  useEffect(() => {
    if (!period) return;
    const { aggregation } = period;
    console.log('useEffect variablesToSubscribe ', variables);
    if (aggregation) {
      const pOpList = variables.map(x => {
        const op = observableproperties && observableproperties.find(op => x.id === op.id);
        return op
          ? Promise.resolve(op)
          : getOpById(x.id)
              .then(res => res.data)
              .catch(err => Promise.resolve(x));
      });
      Promise.all(pOpList).then(opList => {
        console.log('opList ', opList);
        const newVariables2Subscribe = opList.map(op => {
          return {
            id: op.id,
            aggregation,
            otherDataSource: op.otherDataSource
          };
        });
        console.log('useEffect newVariable2Subscribe ', newVariables2Subscribe);
        setVariablesToSubscribe(newVariables2Subscribe);
      });
    }
  }, [variables, period]);

  useEffect(() => {
    if (!period || !variables) {
      return () => {};
    }
    loadData(variables,period);
    const { interval, rows } = period;

    let key = null;

    // Single period subscriptions
    if (!closedPeriodIntervals.includes(interval) && variablesToSubscribe.length > 0) {
      key = subscribe(variablesToSubscribe, handleNewData);
    }

    /**
     * Multi periods: for each row, subscribe period with open intervals
     * Check if a variable is already subriscibed in a single period
     */
    if (rows) {
      const rowsPeriods = [...rows];
      const rowsVariablesToSubscribe = [];
      variables.map(variable => {
        rowsPeriods.map(period => {
          if (
            period.interval &&
            period.interval.value &&
            !closedPeriodIntervals.includes(period.interval.value)
          ) {
            rowsVariablesToSubscribe.push({
              id: variable.id,
              aggregation: period.aggregation
            });
          }
          return null;
        });
        return null;
      });

      if (rowsVariablesToSubscribe.length > 0) {
        key = subscribe(variablesToSubscribe, handleNewData);
      }
    }

    return () => {
      if (key) {
        unsubscribe(key);
      }
    };
  }, [period, variables, variablesToSubscribe]);

  const headerHeight = (headerRef.current || {}).clientHeight;
  const height = +style.height.replace('px', '') - headerHeight || 0;
  const width = +style.width.replace('px', '') || 0;
  const editWidget = () => {
    onEdit(widget);
    logger.debug('editWidget %o', widget);
  };

  const deleteWidget = () => {
    onDelete(widget);
  };

  const setTable = (data, names) => {
    setTableData(data);
    setTableNames(
      names.map(name => {
        return { label: name, key: name };
      })
    );
  };

  const getData = (widgetType, type, isCsv = false) => {
    let data;
    let names;
    switch (widgetType) {
      case widgetTypes.SERIES:
        data = seriesData;
        names = seriesNames;
        break;
      case widgetTypes.PIE:
        data = pieData.map(d => {
          return {
            ...d,
            value: formatNumber(d.value, d.decimalDigits),
            ratio: formatNumber(d.ratio, 1)
          };
        });
        names = pieNames;
        break;
      case widgetTypes.TABLE:
        const { data: newData, headers } = sanitizeExportData(tableData, tableNames);
        data = newData;
        names = headers;
        break;
      default:
        data = tableData;
        names = tableNames;
        break;
    }
    const result = type === 'data' ? data : names;
    //    console.log('getData type isCsv result', type, isCsv, result);
    return result;
  };

  if (
    widget.widgetType === widgetTypes.IMAGE ||
    widget.widgetType === widgetTypes.TEXT ||
    widget.widgetType === widgetTypes.REPORT_HEADER ||
    widget.widgetType === widgetTypes.REPORT_FOOTER
  ) {
    return (
      <Container {...props} className={className} edit={edit}>
        <Buttons>
          {edit && (
            <ButtonContainer>
              <IconButton icon="pencil-alt" onClick={editWidget} />
              {widget.widgetType !== widgetTypes.REPORT_HEADER &&
                widget.widgetType !== widgetTypes.REPORT_FOOTER && (
                  <IconButton color="red" icon="times" onClick={deleteWidget} />
                )}
            </ButtonContainer>
          )}
        </Buttons>
        {widget.widgetType === widgetTypes.IMAGE && <ImageWidget widget={widget} />}
        {widget.widgetType === widgetTypes.TEXT && <TextWidget widget={widget} />}
        {widget.widgetType === widgetTypes.REPORT_HEADER && (
          <ReportHeader report={additionalData} widget={widget} />
        )}
        {widget.widgetType === widgetTypes.REPORT_FOOTER && (
          <ReportFooter report={additionalData} widget={widget} />
        )}
        {children}
      </Container>
    );
  }

  const periodString = getPeriodString(widget.widgetType, period);

  const canSendCommand = !edit && userCanSendCommand;

  return (
    <Container
      {...props}
      className={className}
      edit={edit}
      overflow={widget.widgetType === widgetTypes.DISPLAY ? 'visible' : 'hidden'}
    >
      <Header ref={headerRef}>
        {widget.title && widget.title.length > 0 && <Title className="title">{widget.title} </Title>}
        {
          <Period className="subtitle">
            {widget.titlePeriod ? widget.titlePeriod : periodString}
          </Period>
        }
        {/* error && <WarningIcon icon="wifi" color="#cc0000" title={error} /> */}
      </Header>
      <Buttons>
        {edit && (
          <ButtonContainer>
            <IconButton icon="pencil-alt" onClick={editWidget} />
            <IconButton color="red" icon="times" onClick={deleteWidget} />
          </ButtonContainer>
        )}
        {!edit &&
          (widget.widgetType === widgetTypes.SERIES ||
            widget.widgetType === widgetTypes.PIE ||
            widget.widgetType === widgetTypes.TABLE) && (
            <>
              <HideDropdown
                direction="down"
                isOpen={showOptions}
                toggle={() => setShowOptions(!showOptions)}
              >
                <DropdownToggle nav>
                  <OptionsIcon src="/assets/img/opzioni.svg" alt="options" />
                </DropdownToggle>
                <StyledDropdownMenu right>
                  <Trans>Esporta</Trans>
                  <DropdownItem>
                    <ExcelFile
                      filename={`${widget.title || ''}_${moment().format('YYYY-MM-DD_HH-mm-ss')}`}
                      element={
                        <Option>
                          <IconButton icon="file-excel" />
                          Excel
                        </Option>
                      }
                    >
                      <ExcelSheet data={getData(widget.widgetType, 'data')} name="data">
                        {getData(widget.widgetType, 'names').map(n => (
                          <ExcelColumn label={n.label} value={n.key} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  </DropdownItem>
                  <DropdownItem>
                    <CSVLink
                      headers={getData(widget.widgetType, 'names')}
                      data={getData(widget.widgetType, 'data', true)}
                      filename={`${widget.title || ''}_${moment().format(
                        'YYYY-MM-DD_HH-mm-ss'
                      )}.csv`}
                    >
                      <Option>
                        <IconButton icon="file-csv" />
                        CSV
                      </Option>
                    </CSVLink>
                  </DropdownItem>
                  {widget.widgetType !== widgetTypes.TABLE && (
                    <StyledCustomMenuItem>
                      <a href="#" onClick={e => setShowPeriodModal(true)}>
                        <Trans>Altro periodo</Trans>...
                      </a>
                    </StyledCustomMenuItem>
                  )}
                </StyledDropdownMenu>
              </HideDropdown>
            </>
          )}
      </Buttons>
      <WidgetBody
        setTableData={(data, names) => setTable(data, names)}
        currentModule={currentModule}
        units={units}
        physicaldimensions={physicaldimensions}
        widget={widget}
        dataToPass={dataToPass || []}
        edit={edit}
        initialData={initialData}
        lastValues={lastValues}
        width={width}
        height={height}
        shouldUpdateWidgets={shouldUpdateWidgets}
        canSendCommand={canSendCommand}
        toggleFullscreen={() => toggleFullscreen()}
        isFullscreen={isFullscreen}
      />
      <TimeProfilePeriodModal
        isOpen={showPeriodModal}
        title={'Seleziona un altro periodo di visualizzazione'}
        toggle={() => setShowPeriodModal(v => !v)}
        widget={widget}
        editWidget={saveWidget}
      />
      {children}
    </Container>
  );
};

Widget.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  edit: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  shouldUpdateWidgets: PropTypes.bool,
  units: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { catalogs, domain } = state;
  const { units } = catalogs;
  const { physicaldimensions, observableproperties } = domain;

  return { units, physicaldimensions, observableproperties };
};

export default connect(mapStateToProps)(Widget);
