import operatoriAlgebrici from './operatoriAlgebrici';
import operatoriBooleani from './operatoriBooleani';
import funzioniMatematiche from './funzioniMatematiche';
import punctuation from './punctuation';

export default [
  ...funzioniMatematiche,
  ...operatoriBooleani,
  ...operatoriAlgebrici,
  ...punctuation
];
