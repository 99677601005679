import React, { useState, useEffect } from 'react';
import { DataTable, DeleteModal, IconButton, Button } from 'app/common';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import api from 'api';
import { concatUrl } from 'app/utils/navigation';
import { getNestedObject } from 'app/utils/object';

const getAllGatewaysWithAssociatedFieldGw = async () => {
  let gateways = [];
  try {
    gateways = await api.get('/gateways?filter={"include":{"fieldGateway":{"site":"company"}}}');
  } catch (e) {
    console.log('Error, getAllGateways, ', e);
  }
  return gateways;
};

const selectBestFieldGateway = (fieldGatewayList) => {
  let result = {};

  if (fieldGatewayList && fieldGatewayList.length > 0) {
    [result] = fieldGatewayList;
  }

  return result;
};


const GatewaysInventory = (props) => {
  const { match } = props;
  const { url } = match;
  const [gatewayList, setGatewayList] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false });


  const loadAllGateways = async () => {
    const gwData = await getAllGatewaysWithAssociatedFieldGw();
    const gatewayAndFieldData = gwData.data.map((gw) => {
      const newGateway = { ...gw };
      newGateway.fieldGateway = selectBestFieldGateway(gw.fieldGateway);
      newGateway.fgName = newGateway.fieldGateway.name || '';
      newGateway.siteName = Object.keys(newGateway.fieldGateway).length > 0 ? newGateway['fieldGateway']['site']['name'] : '';      
      return newGateway;
    });
    setGatewayList(gatewayAndFieldData);
  };

  useEffect(() => {
    loadAllGateways();
  }, []);

  const deleteModalToggle = () => {
    setDeleteModal({ isOpen: !deleteModal.isOpen });
  };

  const deleteGateway = async (gatewayId) => {
    await api.delete(`/Gateways/${gatewayId}`);
    setGatewayList(gatewayList.filter(x => x.id !== gatewayId));
    deleteModalToggle();
  };

  const renderDeleteModal = () => (
    <DeleteModal
      askPassword
      isOpen={deleteModal.isOpen}
      toggleModal={deleteModalToggle}
      header={<Trans>Conferma cancellazione {deleteModal.serialNumber}</Trans>}
      onDelete={() => deleteGateway(deleteModal.id)}
    />
  );

  return (
    <>
      {renderDeleteModal()}
      <Card>
        <CardHeader>
          <Trans>Inventario gateway</Trans>
        </CardHeader>
        <CardBody>
          <DataTable
            keyField="name"
            edit
            search
            width="100%"
            buttons={(
              <StyledButton size="sm" color="primary" tag={Link} to={concatUrl(url, 'new')}>
                <Trans>Nuovo gateway</Trans>
              </StyledButton>
            )}
            headers={[
              {
                title: <Trans>Numero seriale</Trans>,
                property: 'serialNumber',
                index: 1,
                dataType: 'string',
              },
              {
                title: <Trans>Modello</Trans>,
                property: 'model',
                index: 2,
                dataType: 'custom',
                canSearch: true,
                cell: gateway => (
                  <span>
                    <SecondaryText>{gateway.make}</SecondaryText>
                    <div>{gateway.model}</div>
                  </span>
                ),
              },
              {
                title: <Trans>Codice prodotto</Trans>,
                property: 'productCode',
                index: 3,
                dataType: 'string',
                canSearch: true,
              },
              {
                title: <Trans>Sito di installazione</Trans>,
                property: 'siteName',
                index: 4,
                dataType: 'custom',
                canSearch: true,
                cell: gateway => (
                  <span>
                    <SecondaryText>{getNestedObject(gateway, 'fieldGateway.site.company.name')}</SecondaryText>
                    <div>{getNestedObject(gateway, 'fieldGateway.site.name')}</div>
                  </span>
                ),
              },
              {
                title: <Trans>Nome</Trans>,
                property: 'fgName',
                index: 5,
                dataType: 'string',
                canSearch: true,
              },
              {
                title: <Trans>IoTuid</Trans>,
                property: 'IoTUID',
                index: 6,
                dataType: 'string',
                canSearch: true,
              },
            ]}
            rowButtons={gateway => (
              gateway.fieldGateway.id 
              ? null 
              : [{
                icon: "trash",
                label: <Trans>Elimina</Trans>,
                onClick: () => setDeleteModal({ isOpen: true, id: gateway.id, serialNumber: gateway.serialNumber })
              }])}
            data={gatewayList}
            noData={<Trans>Nessun gateway trovato</Trans>}
          />
        </CardBody>
      </Card>
    </>
  );
};

const StyledButton = styled(Button)`
  && {
    margin-bottom: 0.5rem;
  }
`;

const SecondaryText = styled.div`
  font-size: 0.75rem;
  opacity: 0.6;
`;

export default GatewaysInventory;
