import React, { Component } from 'react';
import { Button, Checkbox } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { reorderArrayForDragAndDrop } from 'app/utils/array';
import { MAX_MODBUS_REGISTER_ADDRESS } from 'app/constants';
import { MAX_NUMBER_OF_OBSERVED_PROPERTIES } from 'app/constants';
import styled from 'styled-components';
import OPForm from './OPForm';

const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.div`
  color: #f00;
  margin-bottom: 1rem;
`;

class OPList extends Component {
  constructor(props) {
    super(props);

    let observedProperties = [{}];
    let showDecimalAddress = false;

    if (props.template.template) {
      observedProperties = props.template.template.observedProperties.sort(
        (a, b) => a.order - b.order
      );
      ({ showDecimalAddress } = props.template.template);
      observedProperties.forEach(op => {
        if (op.startAddress) {
          op.startAddressDecimal = op.startAddress.toString();
          op.startAddressHexadecimal = op.startAddress.toString(16);
          op.key = Math.random().toString();
        }
      });
    }

    this.state = {
      observedProperties: observedProperties.map(item => ({ item, ref: React.createRef() })),
      showDecimalAddress
    };
  }

  // Get updated values from all forms and store them in observedProperties (used in drag&drop and delete to update state)
  updateObservedProperties = () => {
    const { observedProperties } = this.state;
    const newObs = [...observedProperties];
    observedProperties.forEach((op, index) => {
      const values = op.ref.current.getValues();
      newObs[index].item = { ...newObs[index].item, ...values };
    });
    this.setState({ observedProperties: newObs });
  };

  addItem = () => {
    const { observedProperties } = this.state;
    if (observedProperties.length < MAX_NUMBER_OF_OBSERVED_PROPERTIES) {
      this.setState(prevState => ({
        observedProperties: [
          ...prevState.observedProperties,
          { item: { key: Math.random().toString() }, ref: React.createRef() }
        ]
      }));
    }
  };

  removeItem = opRef => {
    this.updateObservedProperties();
    this.setState(prevState => ({
      observedProperties: [...prevState.observedProperties.filter((x, index) => index !== opRef)]
    }));
  };

  getValues = async data => {
    const { observedProperties, showDecimalAddress } = this.state;
    const promises = observedProperties.map(item => item.ref.current.validateAndGetValues(data));
    try {
      const values = await Promise.all(promises);
      return {
        numberOfObservedProperties: observedProperties.length,
        template: {
          showDecimalAddress,
          observedProperties: values.map((op, order) => ({ ...op, order }))
        }
      };
    } catch (e) {
      throw e;
    }
  };

  onDragStart = e => {
    const sourceIndex = +e.target.dataset.index;
    this.updateObservedProperties();
    e.dataTransfer.setData('text/plain', sourceIndex);
  };

  onDragOver = e => {
    e.preventDefault();
  };

  onDrop = e => {
    e.preventDefault();
    const sourceIndex = +e.dataTransfer.getData('text/plain');
    const targetIndex = +e.currentTarget.dataset.index;

    const { observedProperties } = this.state;
    const newObs = reorderArrayForDragAndDrop(observedProperties, sourceIndex, targetIndex);
    this.setState({ observedProperties: newObs });
  };

  fillBits = (op, bits) => {
    let error, warning;
    const { name, startAddress, dataSize, bit } = op;
    const startBit = 16 * startAddress + (+bit || 0);
    const bitSize = dataSize === 'bit' ? 1 : +dataSize.match(/[0-9]+/g);
    const endBit = startBit + bitSize; // escluso

    if (endBit > 16 * MAX_MODBUS_REGISTER_ADDRESS + 1) {
      error = i18n._(
        t`Errore indirizzi di registro: la variabile ${name} va oltre il massimo indirizzo disponibile`
      );
    }
    for (let i = startBit; i < endBit && !error; i++) {
      if (bits.includes(i) && bitSize !== 8) {
        warning = i18n._(
          t`Errore indirizzi di registro: la variabile ${name} è sovrapposta ad un'altra variabile`
        );
      }
      bits.push(i);
    }
    return { error, warning };
  };

  validateAddresses = observedProperties => {
    let error, warning;
    try {
      const status = [];
      /* eslint-disable-next-line no-restricted-syntax */
      for (let i = 0; i < observedProperties.length && !error; i++) {
        const op = observedProperties[i];
        const { error: opError, warning: opWarning } = this.fillBits(op, status);
        error = opError;
        warning = opWarning;
      }
    } catch (e) {
      return { error: e.message };
    }

    return { error, warning };
  };

  validate = () => {
    const { observedProperties } = this.state;
    if (observedProperties.length === 0) {
      this.setState({ errorMessage: <Trans>Inserire almeno una variabile</Trans> });
      return false;
    }
    if (observedProperties.length > MAX_NUMBER_OF_OBSERVED_PROPERTIES) {
      this.setState({
        errorMessage: (
          <Trans>
            Ogni template può contenere al massimo {MAX_NUMBER_OF_OBSERVED_PROPERTIES} variabili
          </Trans>
        )
      });
      return false;
    }

    // Address overlap or overflow
    const currentValues = observedProperties.map(op => op.ref.current.getValues(true));
    const { error, warning } = this.validateAddresses(currentValues);
    if (error) {
      this.setState({ errorMessage: error });
      return false;
    }
    if (error) {
      this.setState({ warning });
      return false;
    }

    return true;
  };

  render() {
    const { catalogs, readOnly } = this.props;
    const { observedProperties, showDecimalAddress, errorMessage } = this.state;
    const numberOfObservedProperties = observedProperties.length;
    return (
      <div>
        <CheckboxContainer>
          <Checkbox
            name="showDecimalAddress"
            checked={showDecimalAddress}
            onChange={e => this.setState({ showDecimalAddress: e.target.checked })}
            label={<Trans>Mostra indirizzi in formato decimale</Trans>}
          />
        </CheckboxContainer>
        {observedProperties.map((item, index) => (
          <div data-index={index} onDragOver={this.onDragOver} onDrop={this.onDrop}>
            <OPForm
              index={index}
              readOnly={readOnly}
              showDecimalAddress={showDecimalAddress}
              op={item.item}
              onDelete={() => this.removeItem(index)}
              key={item.item.key}
              ref={item.ref}
              opNumber={index + 1}
              catalogs={catalogs}
              onDragStart={this.onDragStart}
            />
          </div>
        ))}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <p>
          <Trans>
            Numero di variabili inserite: {numberOfObservedProperties} (max{' '}
            {MAX_NUMBER_OF_OBSERVED_PROPERTIES} variabili per template)
          </Trans>
        </p>
        {!readOnly && (
          <Button
            color="secondary"
            disabled={numberOfObservedProperties >= MAX_NUMBER_OF_OBSERVED_PROPERTIES}
            onClick={this.addItem}
          >
            <Trans>Aggiungi variabile</Trans>
          </Button>
        )}
      </div>
    );
  }
}

OPList.propTypes = {
  template: PropTypes.object.isRequired
};

export default OPList;
