import React from 'react';

// set the defaults
const SchedulersContext = React.createContext({
  schedulers: [],
  onEditScheduler: () => {},
  onAddScheduler: () => {},
});

export default SchedulersContext;
