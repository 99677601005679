import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Avatar } from 'app/common';
import { transparentize } from 'polished';
import styled from 'styled-components';
import UserCard from '../UserCard';

const Toggle = styled(DropdownToggle)`
`;

const HoverAvatar = styled(Avatar)`
  ${Toggle}:hover & {
    background-image: ${props => `linear-gradient(to bottom, transparent, ${transparentize(0.7, props.theme.brandSecondaryColor)})`};
  }
`;

const UserIcon = (props) => {
  const { user } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown direction="down" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <Toggle nav>
        <HoverAvatar rounded background="#fff" size={28}>{user.abbreviation}</HoverAvatar>
      </Toggle>
      <DropdownMenu right>
        <UserCard />
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return {
    user,
  };
};

export default connect(mapStateToProps)(UserIcon);
