import store from 'ducks/store';
import {
  translateDetail,
} from '../notificationengine/helpers/CatalogsTranslate.js';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

export const translateAlarmEventName = feAlarm => {
  const result = feAlarm.eventName
    ? feAlarm.eventName
    : translateDetail(feAlarm.eventNameKey, true, store.getState().catalogs);
  // log.debug("feAlarm.eventName ", feAlarm.eventName, result)
  return result;
};

export const translateEventTag = (tag={}, language) => {
  const { translations } = tag;
  return translations && translations[language] ? translations[language] : tag && tag.name;
};

// eslint-disable-next-line import/prefer-default-export
export const getAlarmTags = feAlarm => {
  const labelMap = {
    noData: ['equipmentMonitoring'],
    zero: ['equipmentMonitoring'],
    notZero: ['equipmentMonitoring'],
    comparison1: ['facilityMonitoring'],
    comparison2: ['facilityMonitoring'],
    binaryOnOff: ['facilityMonitoring']
  };
  return feAlarm && labelMap[feAlarm.type] ? labelMap[feAlarm.type] : [];
};

export const createAlarmEventNameKey = alarm => {
  const labelMap = {
    noData: 'unavailableOpData',
    notZero: 'nonZeroConstantMeasure',
    zero: 'zeroConstantMeasure',
    comparison1: 'firstThresholdAlarm',
    comparison2: 'secondThresholdAlarm',
    binaryOnOff: alarm.value ? 'changeToOn' : 'changeToOff'
  };
  const result = alarm && labelMap[alarm.type] ? labelMap[alarm.type] : '';
  return result;
};

export const mapSeverityColor = severity =>
  severity === 'critical' ? '#D0021B' : severity === 'medium' ? '#F5A623' : '#F8E71C';

export const getPersistencyDataFromGranularity = granularity => {
  const granularity2persistency = {
    raw: {
      label: i18n._(t`${15} minuti`),
      sec: 15 * 60
    },
    '1m': {
      label: i18n._(t`${15} minuti`),
      sec: 15 * 60
    },
    '5m': {
      label: i18n._(t`${1} ora`),
      sec: 3600
    },
    '10m': {
      label: i18n._(t`${1} ora`),
      sec: 3600
    },
    '15m': {
      label: i18n._(t`${3} ore`),
      sec: 3 * 3600
    },
    '30m': {
      label: i18n._(t`${3} ore`),
      sec: 3 * 3600
    },
    '1h': {
      label: i18n._(t`${6} ore`),
      sec: 6 * 3600
    },
    '1dy': {
      label: i18n._(t`${6} giorni`),
      sec: 6 * 24 * 3600
    }
  };
  return granularity2persistency[granularity] || {};
};

export const alarmDurationLabel = alarm =>
  alarm.granularity !== 'raw' ? ` (${alarm.granularity})` : '';
