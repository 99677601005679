import React from 'react';
import { AuthControl, Roles } from 'app/common';
import CreateDomain from '../CreateDomain/CreateDomain';
import DomainList from '../DomainList/DomainList';
import UpdateDomain from '../UpdateDomain/index';
import { Route, Switch } from 'react-router-dom';

const ManageDomains = (props) => {
    const { match } = props;
  const { url } = match;


  return (
    <AuthControl systemRole={Roles.CSU}>
      <Switch>
      <Route path={`${url}/createdomain`}
      render={props => (
        <CreateDomain {...props} url={url} />
      )}
      />
      <Route path={`${url}/updatedomain/:domainid`}
      render={props => (
        <UpdateDomain {...props} url={url}/>
      )}  
      />
      <Route
        exact
        path={url}
        render={props => <DomainList {...props} url={url} />}
      />
    </Switch>
    </AuthControl>
  );
};

export default ManageDomains;