/** Author: Stefano Chessa*/
import React, { useEffect, useState } from 'react';
import ReactMultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';
import { Button } from 'app/common';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { ToastContainer, toast } from '../../../../../../../node_modules/react-toastify/index';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MultiSelect = props => {
    const { items, selectedItems, setSelectedItems } = props;

    function handleChange(selectedItems) {
        setSelectedItems(selectedItems);
    }

    return (
        <ReactMultiSelect
            items={items}
            withGrouping
            selectedItems={selectedItems}
            onChange={handleChange}
            showSelectAll={false}
        />
    );
};

const RolesUser = (props) => {

    const { userData, userid, closeModal, onSuccess, onError } = props;
    const thisUserData = userData && userData.filter(user => user.id === userid);
    const userName = thisUserData && thisUserData[0] && thisUserData[0].name;

    const [userRoles, setUserRoles] = useState({});
    const [userAvailableRoles, setUserAvailableRoles] = useState({});
    const [modal, setModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [userDisabled, setUserDisabled] = useState(false);

    const itemList = [
        { id: 0, label: "trading", group: "apps" }, { id: 1, label: "energy", group: "apps" }, { id: 2, label: "optimizer", group: "apps" },
        { id: 3, label: "CSU", group: "exacto-bea" }, { id: 4, label: "ICA_RECONFIG", group: "exacto-bea" }, { id: 5, label: "SYS", group: "exacto-bea" },
        { id: 6, label: "SYS", group: "lvp-control-api" }, { id: 7, label: "LVP", group: "lvp-control-api" },
        { id: 8, label: "TechOffice", group: "trading-web" }, { id: 9, label: "Trader", group: "trading-web" }
    ];

    const items = itemList;
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        getUserStatus();
        getUserRoles();
        getUserAvailableRoles();
    }, []);

    const getUserRoles = async () => {
        const res = await api.get('/UserInfos/' + userid + '/roles', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setUserRoles(res.data);
            let elements = [];
            Object.entries(res.data).forEach((entry) => {
                const [key, value] = entry;
                let values = value.toString().split(",");
                for (const el of values) {
                    let item = itemList.find(x => {
                        if (x.group === key && x.label === el)
                            return x
                        return null;
                    })
                    elements.push({
                        id: item.id,
                        group: key,
                        label: el
                    })
                }
            });
            setSelectedItems(elements);

        } else if (res.status >= 300) {
            toast.warn(res.data.error.message);
        }
    }

    const getUserAvailableRoles = async () => {
        const res = await api.get('/UserInfos/' + userid + '/availableRoles', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setUserAvailableRoles(res.data);
        } else if (res.status >= 300) {
            toast.warn(res.data.error.message);
        }
    }

    const getUserStatus = async () => {
        const res = await api.get('/UserInfos/' + userid + '/disabled', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            if (res.data.disabled) {
                setButtonDisabled(true);
                setUserDisabled(true);
            }
        } else if (res.status >= 300) {
            toast.warn(res.data.error.message);
        }
    }

    const updateUserRoles = async () => {

        const body = selectedItems.reduce(function (r, a) {
            r[a.group] = r[a.group] || [];
            r[a.group].push(a.label);
            return r;
        }, Object.create(null));

        if (body['exacto-bea'] === undefined) {
            body['exacto-bea'] = ([]);
        }

        if (body['apps'] === undefined) {
            body['apps'] = ([]);
        }

        if (body['lvp-control-api'] === undefined) {
            body['lvp-control-api'] = ([]);
        }

        if (body['trading-web'] === undefined) {
            body['trading-web'] = ([]);
        }

        const res = await api.patch('/UserInfos/' + userid + '/roles', body, { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            onSuccess(userName + " - " + i18n._(t`Permessi aggiornati`));
        } else if (res.status >= 300) {
            toast.warn(res.data.error.message);
        }

    }

    const toogle = () => { setModal(!modal) };
    const cancel = () => {
        closeModal();
    };

    return (
        <>
            <ToastContainer></ToastContainer>
            <Modal size="xl" centered isOpen={true}>
                <ModalHeader toggle={closeModal}>
                    <Trans>Ruoli di sistema dell'utente</Trans>&nbsp;{userName}
                </ModalHeader>
                <ModalBody>
                    {userDisabled ?

                        (<div>
                            <Trans>Attenzione! L'utente selezionato è</Trans> <b><Trans>disabilitato</Trans></b><Trans>. Non è possibile procedere alla modifica dei permessi.</Trans>
                        </div>) :
                        (null)
                    }
                    <Trans></Trans>&nbsp;
                    <h6><Trans>Ruoli assegnati</Trans></h6>
                    {userRoles != {} ? <div>{JSON.stringify(userRoles, null, 4)}</div> : <div><Trans>Nessun ruolo assegnato</Trans></div>}
                    <hr></hr>
                    <h6><Trans>Ruoli disponibili</Trans></h6>
                    {Object.keys(userAvailableRoles).length !== 0 ? <div>{JSON.stringify(userAvailableRoles, null, 4)}</div> : <div><Trans>Nessun ruolo disponibile</Trans></div>}

                    <Modal isOpen={modal} toggle={() => toogle()} size="lg">
                        <ModalHeader>
                            <Trans>Modifica i permessi</Trans>
                        </ModalHeader>
                        <ModalBody>
                            <MultiSelect
                                items={items}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}>
                            </MultiSelect>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => { updateUserRoles(); }}><Trans>Salva</Trans></Button>
                            <Button color="link" onClick={() => { toogle(); }}><Trans>Chiudi</Trans></Button>
                        </ModalFooter>
                    </Modal>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={buttonDisabled} onClick={() => { toogle(); }}><Trans>Modifica</Trans></Button>
                    <Button color="link" onClick={cancel}><Trans>Annulla</Trans></Button>
                </ModalFooter>
            </Modal>
        </>
    );

}


RolesUser.propTypes = {
    history: PropTypes.object.isRequired,
};


export default RolesUser;
