import React from 'react';
import styled from 'styled-components';

const SpinnerDiv = styled.div`
  .Loader,
  .Loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .Loader {
    font-size: 7px;
    left: 50%;
    top: 30vh;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(31, 113, 183, 0.2);
    border-right: 1.1em solid rgba(31, 113, 183, 0.2);
    border-bottom: 1.1em solid rgba(31, 113, 183, 0.2);
    border-left: 1.1em solid #1f71b7;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
    max-width: 4rem;
    max-height: 4rem;
    z-index: 9;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .overlay-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    display: ${props => props.display};
    margin: ${props => props.margin};
  }
  .overlay-visible {
    color: white;
    background: #fff;
    opacity: 0.8;
    width: 100%;
    z-index: 1000;
    display: ${props => props.display};
    margin: ${props => props.margin};
  }
`;

const Spinner = props => {
  const { loading, overlay, margin } = props;
  const classes = overlay ? 'overlay-box overlay-visible' : 'overlay-box';
  return (
    <SpinnerDiv display={loading ? 'block' : 'none'} margin={margin || 'inherit'}>
      <div className={classes}>
        <div className="Loader"> Loading...</div>
      </div>
    </SpinnerDiv>
  );
};

export default Spinner;
