import React, { Component } from 'react';
import { Button } from 'app/common';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListItemContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const ListItem = styled(Link)`
  margin-bottom: 0;
  margin-right: .5rem;
`;

const ListDescription = styled.div`
  color: ${props => props.theme.lightColor};
  display: inline-block;
  font-size: .9rem;
  font-style: italic;
`;

const NoItemsText = styled.div`
  color: ${props => props.theme.lightColor};
  margin-bottom: 1rem;
`;

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;

  ${props => props.isOtherSites && !props.isFilteredItemsEmpty && `
  &:hover {
    cursor: pointer;
    span {
      color: #666;
    }
    span.badge {
      color: #fff;
      background-color: #aaa
    }
    svg {
      color: #aaa
    }
  }
  `}

  & svg {
      transition: all .2s;
      margin-right:0.5rem;
      ${props => props.isOpen && 'transform: rotate(180deg)'};
    }
`;

const NameBadge = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex:1;
`;

const SectionTitle = styled.span`
  font-weight: 400;
  margin-right: .5rem;
  margin-bottom: 0;
`;

const Item = (props) => {
  const { item, nameProp, descriptionProp, getUrl, onSelect } = props;
  return (
    <ListItemContainer>
      <ListItem to={getUrl(item)} onClick={onSelect}>{item[nameProp]}</ListItem>
      {descriptionProp && <ListDescription>{item[descriptionProp]}</ListDescription>}
    </ListItemContainer>
  );
};


class AssetSelectorSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  changeOpen = () => {
    const newState = !this.state.isOpen;
    this.setState({ isOpen: newState });
  };

  filterItems = memoize((items, nameProp, descriptionProp, filterText) => {
    return filterText && filterText.length > 0 ? items.filter(x => x[nameProp].toLowerCase().includes(filterText.toLowerCase()) || (x[descriptionProp] && x[descriptionProp].toLowerCase().includes(filterText.toLowerCase()))) : [...items];
  });

  firstItems = memoize((items, number) => {
    return number ? items.slice(0, number) : items;
  });

  getKey = (item, idProp) => {
    if (Array.isArray(idProp)) {
      return idProp.map(p => item[p]).join('_');
    }
    return item[idProp];
  };

  render() {
    const { onSelect, isOtherSites, header, items, noItemsText, filterText, getUrl, idProp, nameProp, descriptionProp } = this.props;

    const { isOpen } = this.state;

    const filteredItems = this.filterItems(items, nameProp, descriptionProp, filterText).sort((a,b) => a.name.localeCompare(b.name));

    const isFilteredItemsEmpty = filteredItems.length === 0;

    return (
      <Section>

        <SectionHeader onClick={this.changeOpen} isOpen={isOpen} isOtherSites={isOtherSites} isFilteredItemsEmpty={isFilteredItemsEmpty}>
          <NameBadge>
            <SectionTitle>{header}</SectionTitle>
            <Badge color="secondary" pill>{items.length}</Badge>
          </NameBadge>
          
            {isOtherSites && !isFilteredItemsEmpty && (
                <FontAwesomeIcon icon="angle-down" />
            )}
          
        </SectionHeader>

        {isFilteredItemsEmpty && (
          <NoItemsText>{noItemsText}</NoItemsText>
        )}
        {isOtherSites && isOpen && filteredItems.map(item => (
          <Item key={this.getKey(item, idProp)} item={item} getUrl={getUrl} nameProp={nameProp} descriptionProp={descriptionProp} onSelect={onSelect} />
        ))}
        {!isOtherSites && filteredItems.map(item => (
          <Item key={this.getKey(item, idProp)} item={item} getUrl={getUrl} nameProp={nameProp} descriptionProp={descriptionProp} onSelect={onSelect} />
        ))}

      </Section>
    );
  }
}

AssetSelectorSection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  header: PropTypes.element.isRequired,
  isOtherSites: PropTypes.bool,
  items: PropTypes.array.isRequired,
  noItemsText: PropTypes.element.isRequired,
  filterText: PropTypes.string.isRequired,
  getUrl: PropTypes.func.isRequired,
  idProp: PropTypes.string,
  nameProp: PropTypes.string,
  descriptionProp: PropTypes.string,
};

AssetSelectorSection.defaultProps = {
  idProp: 'id',
  nameProp: 'name',
  descriptionProp: undefined,
};

export default AssetSelectorSection;
