/* Author: Stefano Chessa */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Button, DataTable, CalendarOptions, Select } from 'app/common';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import {
    Modal, ModalBody, ModalHeader, ModalFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import api from 'api/api';
import { ToastContainer, toast } from '../../../../../node_modules/react-toastify/index';
import { Roles } from 'app/utils/auth';
import 'react-toastify/dist/ReactToastify.css';
import OPSelector from 'app/common/OPSelector/OPSelector';
import { AuthControl } from 'app/common';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateInput = styled(Input)`
  max-width: 8.33%;
  min-width: 7.7rem;
  cursor: pointer;
`;
const DateInputGroup = styled(InputGroup)`
  justify-content: center;
  margin-right: 1rem;
`;
const DateInputGroupAddon = styled(InputGroupAddon)`
  margin-left: 1rem;
`;

const ModalText = styled.p`
  margin-left: 1rem;
`;

const aggregations = [ // supported aggregation intervals
    { value: 'raw', label: 'raw' },
    { value: '1m', label: '1m' },
    { value: '5m', label: '5m' },
    { value: '10m', label: '10m' },
    { value: '15m', label: '15m' },
    { value: '30m', label: '30m' },
    { value: '1h', label: '1h' }
];

const AggregationJob = (props) => {

    const { match } = props
    const { type, id, subtype, subid } = match.params;

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [granularity, setGranularity] = useState('raw');

    const [modalOp, setModalOp] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalVariable, setModalVariable] = useState(false);
    const [modalLaunch, setModalLaunch] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    const yesterday = moment().subtract(1, 'days');

    const [filteredDates, setFilteredDates] = useState({
        filtered: false,
        startDate: yesterday.format('YYYY-MM-DD'),
        endDate: yesterday.format('YYYY-MM-DD'),
        showStartDate: yesterday.format('DD/MM/YYYY'),
        showEndDate: yesterday.format('DD/MM/YYYY')
    });

    const [selectedOp, setOp] = useState({ loading: true });
    const [opStatus, setOpStatus] = useState(false);
    const [jobStatus, setJobStatus] = useState({});

    const [currentAggregationJob, setCurrentAggregationJob] = useState({ loading: true });
    const [currentAggregationJobStatus, setCurrentAggregationJobStatus] = useState({ loading: true });
    const [selectedOpCurrentAggregationJob, setSelectedOpCurrentAggregationJob] = useState({ loading: true });

    const loadFilteredList = (startDate, endDate) => {

        const ISOStartDate = moment.tz(startDate, 'DD/MM/YYYY', moment.tz.guess()).toISOString()
        const ISOEndDate = moment.tz(endDate, 'DD/MM/YYYY', moment.tz.guess()).toISOString()

        setFilteredDates({ filtered: true, startDate: ISOStartDate, endDate: ISOEndDate, showStartDate: startDate, showEndDate: endDate })
    }

    const getCurrentAggregationJob = async () => {
        const res = await api.get('/AggregationJobs', { validateStatus: () => true });
        const resStatus = await api.get('/AggregationJobs/statuses', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setCurrentAggregationJob({ data: res.data, loading: false });
            setCurrentAggregationJobStatus({ data: resStatus.data, loading: false })
        } else if (res.status >= 300 && res.status < 400) {
            setCurrentAggregationJob({ loading: false });
        } else if (res.status >= 400 && res.status < 500) {
            setCurrentAggregationJob({ loading: false });
        } else if (res.status >= 500) {
            setCurrentAggregationJob({ loading: false });
        }
    }

    const getSelectedOpAggrJob = async (id) => {
        const res = await api.get('/ObservedProperties/' + id + '/aggregationJobs', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            const data = [...res.data].sort((a, b) => {
                if (a.statusDetail.lastUpdated > b.statusDetail.lastUpdated) {
                    return -1;
                } else if (a.statusDetail.lastUpdated < b.statusDetail.lastUpdated) {
                    return 1;
                } else {
                    return 0;
                }
            })
            setSelectedOpCurrentAggregationJob({ data: data, loading: false });
        } else if (res.status >= 300 && res.status < 400) {
            setSelectedOpCurrentAggregationJob({ loading: false });
        } else if (res.status >= 400 && res.status < 500) {
            setSelectedOpCurrentAggregationJob({ loading: false });
        } else if (res.status >= 500) {
            setSelectedOpCurrentAggregationJob({ loading: false });
        }
    }

    /** viene lanciato all'avvio del componente */
    useEffect(() => {
        getCurrentAggregationJob();
    }, []);

    useEffect(() => {
        setOp({ loading: true });
        getCurrentAggregationJob();
        setButtonDisabled(false);
    }, [type, id, subtype, subid]);

    const toogleOp = () => { setModalOp(!modalOp) };
    const toogleStatus = () => { setModalStatus(!modalStatus) };
    const toogleVariable = () => { setModalVariable(!modalVariable) };
    const toogleLaunch = () => { setModalLaunch(!modalLaunch) };

    const aggregationJobColumns = [
        {
            title: <Trans>id</Trans>, property: 'id', index: 1, dataType: 'string', cell: row => {
                return (<Link onClick={() => {
                    setOpStatus(row);
                    toogleOp();
                }}>
                    {row.id}
                </Link>);
            }
        },
        { title: <Trans>opId</Trans>, property: 'opId', index: 2, dataType: 'string' },
        {
            title: <Trans>start</Trans>, property: 'applyOnInterval.start', index: 3, dataType: 'string', cell: row => {
                return (row['applyOnInterval'] && row['applyOnInterval']['start']);
            }
        },
        {
            title: <Trans>end</Trans>, property: 'applyOnInterval.end', index: 4, dataType: 'string', cell: row => {
                return (row['applyOnInterval'] && row['applyOnInterval']['end']);
            }
        },
        {
            title: <Trans>status</Trans>, property: 'status', index: 5, dataType: 'string', cell: row => {
                if (currentAggregationJobStatus.data) {
                    const status = currentAggregationJobStatus.data.find(x => x.id === row.id);
                    if (status)
                        return (<Link onClick={() => {
                            setJobStatus(status);
                            toogleStatus();
                        }}>
                            {status.status}
                        </Link>);
                    else
                        return 'N/A';
                } else
                    return 'N/A';
            }


        },
    ]

    const selectedOpAggregationJobColumns = [
        {
            title: <Trans>id</Trans>, property: 'id', index: 1, dataType: 'string', cell: row => {
                return (<Link onClick={() => {
                    setOpStatus(row);
                    toogleOp();
                }}>
                    {row.id}
                </Link>);
            }
        },
        { title: <Trans>SourceDatastream</Trans>, property: 'sourceDatastream', index: 2, dataType: 'string' },
        {
            title: <Trans>start</Trans>, property: 'intervalStart', index: 3, dataType: 'string', cell: row => {
                return (row['intervalStart']);
            }
        },
        {
            title: <Trans>end</Trans>, property: 'intervalEnd', index: 4, dataType: 'Date', cell: row => {
                return (row['intervalEnd']);
            }
        },
        {
            title: <Trans>status</Trans>, property: 'status', index: 5, dataType: 'string', cell: row => {
                if (row.status)
                    return (<Link onClick={() => {
                        setJobStatus(row.statusDetail);
                        toogleStatus();
                    }}>
                        {row.status}
                    </Link>);
                else
                    return 'N/A';
            }
        },
        {
            title: <Trans>Ultimo aggiornamento</Trans>, property: 'statusDetail.last_update', index: 6, dataType: 'string', cell: row => {
                return (row['statusDetail']['lastUpdated']);
            }
        },
    ]

    const opInfoColumns = [
        { title: <Trans>id</Trans>, property: 'id', index: 1, dataType: 'string' },
        { title: <Trans>IoTUID</Trans>, property: 'IoTUID', index: 2, dataType: 'string' },
        {
            title: <Trans>Device id</Trans>, property: 'IoTUID', index: 3, dataType: 'string', cell: row => {
                return row['fieldDevice'] && row['fieldDevice']['IoTUID']
            }
        }
    ]

    const submitAggregationJob = async () => {

        const submitUrl = '/ObservedProperties/' + selectedOp.data.id + '/aggregationJobs'
        const body = {
            "begin": filteredDates.startDate,
            "end": filteredDates.endDate,
            "fromAggregation": granularity
        }

        const res = await api.post(submitUrl, body, { validateStatus: () => true })

        if (res.status >= 200 && res.status < 300) {
            toast.success("Aggregation job lanciato");
            toogleLaunch();
            setTimeout(() => {
                setSelectedOpCurrentAggregationJob({ loading: true });
                getSelectedOpAggrJob(selectedOp.data.id);
            }, 2000)

        } else if (res.status >= 300 && res.status < 400) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 400 && res.status < 500) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 500) {
            toast.warn(res.data.error.message);
        }

    }

    return (
        <AuthControl systemRole={Roles.SSYS}>
            <Card>
                <CardHeader>
                    <ButtonContainer>
                        {selectedOp.loading ?
                            'Batch aggregation job in corso' : 'Rilancia aggregazioni per la variabile -> ' + selectedOp.data.name}

                        <ToastContainer />

                        {selectedOp.loading ? <Button onClick={() => {
                            setCurrentAggregationJob({ loading: true });
                            getCurrentAggregationJob();
                        }}>
                            Aggiorna tabella
                        </Button> : <Button onClick={() => {
                            setSelectedOpCurrentAggregationJob({ loading: true });
                            getSelectedOpAggrJob(selectedOp.data.id);
                        }}>
                            Aggiorna tabella
                        </Button>}

                        <Button onClick={() => {
                            toogleVariable();
                        }}>
                            {selectedOp.loading ? 'Seleziona variabile' : selectedOp.data.name}
                        </Button>

                        {selectedOp.loading ?
                            null
                            : <Button onClick={() => setOp({ loading: true })}>
                                Annulla selezione
                            </Button>}
                        <Modal isOpen={modalVariable} toggle={() => toogleVariable()} size="xl">
                            <ModalHeader>
                                Seleziona variabile
                            </ModalHeader>
                            <ModalBody>
                                {/* trovare il modo di visualizzare solamente le variabili dai dispositivi di campo */}
                                <OPSelector
                                    maxChecked={1}
                                    onCheck={async (node, checked) => {
                                        setOp({ data: Object(node.item), loading: false });
                                        await getSelectedOpAggrJob(node.item.id);
                                    }}
                                    opClass='field'
                                    onlyOpClasses={['field']}
                                >
                                </OPSelector>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => toogleVariable()}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </ButtonContainer>
                </CardHeader>
                <CardBody>
                    <Row>
                        {selectedOp.loading ?
                            null :
                            <DataTable
                                title={"Informazioni sulla variabile inserita"}
                                data={[selectedOp.data]}
                                headers={opInfoColumns}
                                noData={"Dati non disponibili"}
                            >
                            </DataTable>}
                    </Row>
                    <hr></hr>
                    <Row>
                        {currentAggregationJob.loading ?
                            (<ModalText>Caricamento dati in corso...</ModalText>) : (
                                selectedOp.loading ?
                                    <DataTable
                                        title="Elenco aggregation job in esecuzione"
                                        data={currentAggregationJob.data}
                                        headers={aggregationJobColumns}
                                        noData={"Nessun aggregation job in corso"} >
                                    </DataTable> :
                                    (
                                        selectedOpCurrentAggregationJob.loading ?
                                            <ModalText>Caricamento dati in corso...</ModalText> :
                                            <DataTable
                                                title={"Elenco aggregation job della variabile selezionata -> " + selectedOp.data.name}
                                                data={selectedOpCurrentAggregationJob.data}
                                                headers={selectedOpAggregationJobColumns}
                                                noData={"Nessun aggregation job in corso per la variabile selezionata"} >
                                            </DataTable>
                                    )

                            )
                        }
                        <Modal isOpen={modalOp} toggle={() => toogleOp()} size="xl">
                            <ModalHeader>
                                Info del job
                            </ModalHeader>
                            <ModalBody>
                                <p>
                                    <pre>
                                        {JSON.stringify(opStatus, null, 2) || 'N/A'}
                                    </pre>
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => toogleOp()}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={modalStatus} toggle={() => toogleStatus()} size="xl">
                            <ModalHeader>
                                Info sullo stato del job
                            </ModalHeader>
                            <ModalBody>
                                <p>
                                    <pre>
                                        {JSON.stringify(jobStatus, null, 2) || 'N/A'}
                                    </pre>
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => toogleStatus()}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                    <hr></hr>
                    <Row>
                        {selectedOp.loading ? null : (
                            <Button onClick={
                                () => {
                                    toogleLaunch();
                                }
                            }
                                disabled={buttonDisabled}>
                                Lancia riaggregazione
                            </Button>
                        )}

                        <Modal isOpen={modalLaunch} toggle={() => toogleLaunch()} size="lg">
                            <ModalHeader>
                                Lancia riaggregazione
                            </ModalHeader>
                            <ModalBody>
                                {showCalendar && (
                                    <CalendarOptions
                                        saveAction={(startDate, endDate) => {
                                            return (
                                                loadFilteredList(startDate, endDate), setShowCalendar(false)
                                            )
                                        }}
                                        dismissAction={() => setShowCalendar(false)}
                                        rangeCalendar={true}
                                        token={{startDate: filteredDates.showStartDate, endDate: filteredDates.showEndDate}}
                                        resetLabel={<Trans>Reset filtro date</Trans>}
                                    />
                                )}
                                <Row>
                                    <ModalText>Variabile selezionata: <b>{selectedOp.loading ? '' : selectedOp.data.name}</b></ModalText>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col sm="3">
                                        <Select options={aggregations} value={granularity} onChange={e => { setGranularity(e.target.value) }} />
                                    </Col>
                                    <Col sm="9">
                                        <DateInputGroup>
                                            <DateInputGroupAddon addonType="prepend" onClick={() => setShowCalendar(true)}>
                                                <InputGroupText>
                                                    <Trans>Da:</Trans>
                                                </InputGroupText>
                                            </DateInputGroupAddon>
                                            <DateInput type="text" value={filteredDates.showStartDate} readonly onClick={() => setShowCalendar(true)} />
                                            <DateInputGroupAddon addonType="prepend" onClick={() => setShowCalendar(true)}>
                                                <InputGroupText>
                                                    <Trans>A:</Trans>
                                                </InputGroupText>
                                            </DateInputGroupAddon>
                                            <DateInput type="text" value={filteredDates.showEndDate} onClick={() => setShowCalendar(true)} />
                                        </DateInputGroup>
                                    </Col>

                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={async () => await submitAggregationJob()}> Lancia</Button>
                                <Button color="secondary" onClick={() => toogleLaunch()}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </CardBody>
            </Card >
        </AuthControl >
    );
}


const mapStateToProps = (state) => {
    const { userSystemRoles } = state.auth;
    const { observableproperties } = state.domain;
    const { selectedDomain, selectedCompany, selectedSite, sites } = state.navigation;
    return { selectedDomain, selectedCompany, selectedSite, sites, observableproperties, userSystemRoles };
}

export default connect(mapStateToProps)(AggregationJob);