/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-redeclare */
/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, ListGroup, Badge } from 'reactstrap';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { saveTab } from 'ducks/actions/databrowsing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Plotly from 'plotly.js';
import * as itDictionary from 'plotly.js/lib/locales/it.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import ChartLabel from './ChartLabel';
import { CHART_TYPE_CURVA, CHART_TYPE_SCATTER, CHART_TYPE_PIE_VAR, CHART_TYPE_PIE_TIME, CHART_TYPE_CUSUM, CHART_TYPE_DISTRBUZIONE, CHART_TYPE_CARPET, DATA_FOR_CHART, getSequenceData, getPieData, getCusumData, getDistribuzioneValue, getHeatMapData, getScatterData, getCurvaCaricoData } from './Functions';
import logger from 'app/common/log';

const Plot = createPlotlyComponent(Plotly);
Plotly.register(itDictionary);

/* stili per le labes */
const LableGroup = styled(ListGroup)`
  margin-top: 15px;
`;

const BrowserContent = styled(Row)`
  padding: 15px 15px;
`;

const EmptyChartWrap = styled.div`
  padding: 100px;
  text-align:center;
`;

const EmptyChartIcon = styled(FontAwesomeIcon)`
  &&&& {
    width:200px;
    height:200px;
    margin: 5px;
    color:#BDBDBD;
  }
`;

const BadgeStyled = styled(Badge)`
&&&& {
  line-height:25px;
  padding:0px 10px;
  cursor: pointer;
  position: absolute;
  right:20px;
  top:5px;
}
`;

const ColSideBar = styled(Col)`
  &&&& {
    text-align:right;
  }
`;

const WrapperSideBar = styled.div`
  text-align:left;
  padding-top:40px;
`;


class Charts extends Component {
  timoutSaveTab = undefined;

  state = {
    showSideBar: true,
    plotlyConfig: {
      locale: 'it',
      displaylogo: false,
      displayModeBar: false,
      // modeBarButtonsToRemove: ['sendDataToCloud', 'lasso2d', 'zoomIn2d', 'zoom3d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
      scrollZoom: true,
    },
    // tab: this.props.tab,
  };


  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.tab && JSON.stringify(nextProps.tab) !== JSON.stringify(state.tab)) {
      return { ...state, tab: nextProps.tab };
    }
    return null;
  }

  variableProperty = (tab, varId, property) => {
    const el = tab.variableSelection.filter(selVar => selVar.id == varId).pop();
    return el[property];
  }

  /* temporizzo il salvataggio per evitare una richiesta massiva verso il server */
  delaySaveTab = (tab) => {
    if (this.timoutSaveTab !== undefined) clearTimeout(this.timoutSaveTab);
    this.timoutSaveTab = setTimeout(() => {
      this.props.saveTab(tab, false);
      this.timoutSaveTab = undefined;
    }, 5000);
  }

  updateSigma = (e) => {
    const { tab } = this.state;

    if (e.target.value.length > 0) {
      tab.sigma = parseInt(e.target.value, 0);
    } else if ('sigma' in tab) {
      delete tab.sigma;
    }
    this.forceUpdate();
    this.delaySaveTab(tab);
  }

  updateIntervalli = (e) => {
    const { tab } = this.state;

    if (e.target.value.length > 0) {
      tab.intervalli = parseInt(e.target.value, 0);
    } else if ('intervalli' in tab) {
      delete tab.intervalli;
    }
    this.forceUpdate();
    this.delaySaveTab(tab);
  }

  updateHeatTf = (e, axes) => {
    const { tab } = this.state;
    tab[axes] = e.target.value;
    this.props.saveTab(tab);
  }

  renderLabels = (tab) => {
    const check4duplicate = [];
    return tab.seriesActive.map((serie, key) => {
      const variable = tab.activeVariable.findIndex(detVar => detVar.id === serie.id);
      const date = tab.activeData.findIndex(d => d.refStartDate === serie.startDate && d.refEndDate === serie.endDate);
      const keyRender = `label${this.tabId}-${key}`;
      if (variable >= 0 && date >= 0 && check4duplicate.findIndex(f => f.variable == variable && f.date === date) < 0) {
        check4duplicate.push({ variable, date });
        return (<ChartLabel key={keyRender} serie={serie} tabs={tab} saveTab={this.props.saveTab} />);
      }
      return (<div key={keyRender} />);
    });
  }

  handleSwitchSidebar = () => {
    const { showSideBar } = this.state;
    this.setState({ showSideBar: !showSideBar });
  }

  render() {
    const { plotlyConfig, showSideBar, tab } = this.state;
    logger.debug('Rendering chart ',tab.chartType);
    let chartNeedSideBar = true;
    let mainChartData = {};
    if (tab) {
      switch (tab.chartType) {
        case CHART_TYPE_PIE_VAR: case CHART_TYPE_PIE_TIME:
          mainChartData = getPieData(tab, DATA_FOR_CHART);
          break;
        case CHART_TYPE_CUSUM:
          mainChartData = getCusumData(tab, DATA_FOR_CHART);
          break;
        case CHART_TYPE_DISTRBUZIONE:
          mainChartData = getDistribuzioneValue(tab, DATA_FOR_CHART);
          break;
        case CHART_TYPE_CARPET:
          mainChartData = getHeatMapData(tab, DATA_FOR_CHART);
          chartNeedSideBar = false;
          break;
        case CHART_TYPE_SCATTER:
          mainChartData = getScatterData(tab, DATA_FOR_CHART);
          break;
        case CHART_TYPE_CURVA:
          mainChartData = getCurvaCaricoData(tab, DATA_FOR_CHART);
          break;
        default:
          mainChartData = getSequenceData(tab, DATA_FOR_CHART);
          break;
      }
    }

    const WrapPlotly = styled(Col)`
        height: ${window.innerHeight - 335}px;
        text-align:center;
        position: relative;
      `;

    return (
      <div>
        { tab && !('error' in mainChartData) && (
          <BrowserContent>
            <WrapPlotly xs="12" md={showSideBar && chartNeedSideBar ? 9 : 12}>
              <Plot
                data={mainChartData.data}
                layout={mainChartData.plotlyLayout}
                config={plotlyConfig}
                style={{ position: 'absolute', width: '100%', height: '100%', margin: 0, top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
                onInitialized={this.props.onChartInizialized}
                useResizeHandler
              />
              { !showSideBar && (
                <BadgeStyled data-html2canvas-ignore onClick={this.handleSwitchSidebar} color="light">&lt;</BadgeStyled>
              )}
            </WrapPlotly>
            { tab && 'seriesActive' in tab && tab.seriesActive.length > 0 && showSideBar && chartNeedSideBar && (
            <ColSideBar xs="12" md="3">
              <BadgeStyled data-html2canvas-ignore onClick={this.handleSwitchSidebar} color="light"><Trans>Nascondi</Trans> &gt;</BadgeStyled>
              <WrapperSideBar>

                { tab.chartType === CHART_TYPE_CUSUM && <Input placeholder={i18n._(t`Sigma (se vuoto 5)`)} onChange={this.updateSigma} value={tab.sigma !== undefined ? tab.sigma : ''} /> }
                { tab.chartType === CHART_TYPE_DISTRBUZIONE && <Input placeholder={i18n._(t`Intervalli (se vuoto 10)`)} onChange={this.updateIntervalli} value={tab.intervalli !== undefined ? tab.intervalli : ''} /> }

                { tab.chartType === CHART_TYPE_SCATTER && (
                  <div>
                    <div>
                      <strong><Trans>Coefficenti:</Trans> </strong>
                      { tab.regressionFormula }
                    </div>
                    <div>
                      <strong><Trans>R:</Trans> </strong> { tab.R2 }<br />
                      <strong><Trans>R2:</Trans> </strong> { Math.pow(tab.R2, 2) }
                    </div>
                  </div>
                )}

                { tab.chartType !== CHART_TYPE_SCATTER && tab.chartType !== CHART_TYPE_CARPET && (<LableGroup>{this.renderLabels(tab)}</LableGroup>) }

              </WrapperSideBar>
            </ColSideBar>
            )}
          </BrowserContent>
        )}

        { tab && ('error' in mainChartData) && (
          <BrowserContent>
            <WrapPlotly xs="12">
              <EmptyChartWrap>{mainChartData.error}<br />
                <EmptyChartIcon icon={faChartArea} />
              </EmptyChartWrap>
            </WrapPlotly>
          </BrowserContent>
        )}
      </div>
    );
  }
}

Charts.propTypes = {
  saveTab: PropTypes.func.isRequired, // from Redux
  onChartInizialized: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  databrowsing: state.databrowsing,
  selectedDomain: state.navigation.selectedDomain,
});

const mapDispatchToProps = dispatch => ({
  saveTab: (tab, reload = true) => dispatch(saveTab(tab, reload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Charts);
