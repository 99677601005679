import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import styled from 'styled-components';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { widgetTypes } from '../common';
import logger from 'app/common/log';
import StateConfig from './StateConfig';

const StyledForm = styled(DynamicForm)`
  border: 1px solid #eee;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
`;

const InputColor = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  background-color: ${props => props.background};
  cursor: pointer;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

class VariableConfig extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.statusTable = React.createRef();
    this.onChangeUnitMeasure = props.onChangeUnitMeasure;

    this.state = {
      colorPickerOpen: false,
      color: props.variable.color,
      isTableWidget: props.widgetType === widgetTypes.TABLE
    };
  }

  getDecimalDigitsOptions = () => {
    return [
      { value: -1, label: i18n._(t`Auto`) },
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' }
    ];
  };
  getValues = async () => {
    try {
      const { widgetType, variable } = this.props;
      if (widgetType === widgetTypes.LED || widgetType === widgetTypes.SWITCH) {
        const { label, states } = await this.statusTable.current.getValues();
        return { ...variable, label: label || variable.name, properties: { states } };
      }

      const values = await this.form.current.validateAndGetValues();
      const { color } = this.state;
      const result = { ...variable, ...values, color };
      logger.debug('variable VariableConfig getValues %o', result);
      return result;
    } catch (e) {
      console.log('Variable config error: ', e.message);
      return null;
    }
  };

  getSeriesType = () => {
    return [
      { value: 'line', label: i18n._(t`Linea`) },
      { value: 'spline', label: i18n._(t`Linea smussata`) },
      { value: 'area', label: i18n._(t`Area`) },
      { value: 'area_stacked', label: i18n._(t`Area impilata`) },
      { value: 'area-spline', label: i18n._(t`Area smussata`) },
      { value: 'area-spline_stacked', label: i18n._(t`Area smussata impilata`) },
      { value: 'bar', label: i18n._(t`Barre`) },
      { value: 'bar_stacked', label: i18n._(t`Barre impilate`) },
      { value: 'step', label: i18n._(t`Scalino`) }
    ];
  };

  handleClickColor = () => {
    this.setState(prevState => ({ colorPickerOpen: !prevState.colorPickerOpen }));
  };

  handleCloseColor = () => {
    this.setState({ colorPickerOpen: false });
  };

  handleChangeColor = color => {
    this.setState({ color: color.hex });
  };

  getFields = () => {
    const { widgetType, units, showStatistic } = this.props;
    const { colorPickerOpen, color } = this.state;
    const seriesTypes = this.getSeriesType();

    const fields = [];

    if (widgetType === widgetTypes.TABLE) {
      fields.push({
        name: 'label',
        type: 'text',
        sm: 12,
        md: 3,
        label: <Trans>Nome</Trans>,
        validation: { required: true }
      });

      fields.push({
        name: 'unit',
        type: 'select',
        xs: 3,
        sm: 12,
        md: 3,
        label: <Trans>Unità di misura</Trans>,
        options: units,
        valueProperty: 'naturalKey',
        labelProperty: 'symbol',
        onChange: async (value, formValues) => {
          if (this.onChangeUnitMeasure) {
            const formValues = await this.getValues();
            this.onChangeUnitMeasure(value, formValues);
          }
        }
      });

      fields.push({
        name: 'decimalDigits',
        type: 'select',
        sm: 2,
        label: <Trans>N° decimali</Trans>,
        options: this.getDecimalDigitsOptions()
      });

      if (showStatistic) {
        fields.push({
          name: 'statistic',
          type: 'select',
          xs: 3,
          sm: 12,
          md: 3,
          label: <Trans>Statistica</Trans>,
          options: [
            { value: 'sum', label: i18n._(t`Totale`) },
            { value: 'average', label: i18n._(t`Media`) }
          ]
        });
      }
    } else {
      fields.push({
        name: 'color',
        type: 'custom',
        sm: 1,
        label: <Trans>Colore</Trans>,
        render: () => (
          <>
            <InputColor id="pickColor" background={color} onClick={this.handleClickColor} />
            {colorPickerOpen && (
              <PopOver>
                <Cover onClick={this.handleCloseColor} />
                <SketchPicker color={color} onChangeComplete={this.handleChangeColor} />
              </PopOver>
            )}
          </>
        )
      });

      fields.push({
        name: 'label',
        type: 'text',
        sm: 4,
        label: <Trans>Nome</Trans>,
        validation: { required: true },
        hidden: [widgetTypes.GAUGE, widgetTypes.CIRCLE].includes(widgetType)
      });

      fields.push({
        name: 'unit',
        type: 'select',
        sm: 2,
        label: <Trans>Unità di misura</Trans>,
        options: units,
        valueProperty: 'naturalKey',
        labelProperty: 'symbol'
      });
    }

    if (
      widgetType === 'series' ||
      widgetType === 'pie' ||
      widgetType === 'display' ||
      widgetType === 'gauge' ||
      widgetType === 'circle' ||
      widgetType === 'slider'
    ) {
      fields.push({
        name: 'decimalDigits',
        type: 'select',
        sm: 2,
        label: <Trans>N° decimali</Trans>,
        options: this.getDecimalDigitsOptions()
      });
    }

    if (widgetType === 'series') {
      fields.push({
        name: 'properties.seriesType',
        type: 'select',
        sm: 3,
        label: <Trans>Rappresentazione</Trans>,
        options: seriesTypes
      });
    }

    if (widgetType === 'gauge' || widgetType === 'circle') {
      fields.push({
        name: 'properties.min',
        type: 'number',
        sm: 2,
        disabled: widgetType === 'circle',
        label: <Trans>Min</Trans>
      });
      fields.push({
        name: 'properties.max',
        type: 'number',
        sm: 2,
        label: <Trans>Max</Trans>,
        validation: { required: true }
      });
    }

    if (widgetType === widgetTypes.SLIDER) {
      fields.push({
        name: 'properties.min',
        type: 'number',
        sm: 2,
        label: <Trans>Min</Trans>,
        validation: { required: true }
      });
      fields.push({
        name: 'properties.max',
        type: 'number',
        sm: 2,
        label: <Trans>Max</Trans>,
        validation: { required: true }
      });
      fields.push({
        name: 'properties.step',
        type: 'number',
        sm: 2,
        label: <Trans>Step</Trans>,
        validation: { required: true }
      });
    }
    return fields;
  };

  render() {
    const { readOnly, variable, widgetType } = this.props;
    console.log('VariableConfig variable render ', variable);

    if (widgetType === widgetTypes.LED || widgetType === widgetTypes.SWITCH) {
      return <StateConfig widgetType={widgetType} ref={this.statusTable} variable={variable} />;
    }

    if (widgetType === widgetTypes.TABLE && !variable.statistic) {
      variable.statistic = variable.valueType === 'instant' ? 'average' : 'sum';
    }

    return (
      <StyledForm
        description={<h5>{variable.name}</h5>}
        hideButtons
        hideRequiredLabel
        readOnly={readOnly}
        ref={this.form}
        fields={this.getFields()}
        initialValues={variable}
      />
    );
  }
}

VariableConfig.propTypes = {
  variable: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  widgetType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  showStatistic: PropTypes.bool
};

VariableConfig.defaultProps = {
  readOnly: false
};

export default VariableConfig;
