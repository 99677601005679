import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import {
  Item
} from 'app/common/Widgets/WidgetStyledComponents';

const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    border-radius: .3rem;
    font-size: 1rem;
    padding: .5rem 1rem;
    font-weight: 400;
    margin-right: .5rem;
  }
`;


const FunctionButton = props => {
  const { onClick, style, label } = props;
  const btnWith = style && style.width + 7;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const dateFunctions = ['year', 'month', 'day', 'hour', 'minute'];
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} style={{ ...style, width: btnWith }}>
      <StyledDropdownToggle caret color="primary" style={{ textTransform: 'none' }}>
        <Trans>{label}</Trans>...
      </StyledDropdownToggle>
      <DropdownMenu>
        {dateFunctions.map(dateFunction => (
          <DropdownItem key={dateFunction} onClick={() => onClick(dateFunction)}>
            <Item>
              <span>{dateFunction}</span>
            </Item>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default FunctionButton;
