import React, { Component } from 'react';
import c3 from 'c3';
import * as d3 from 'd3';
import styled from 'styled-components';
import 'c3/c3.css';
import { isEqual, debounce } from 'lodash';
import moment from 'moment';
import { lastValueUpdated, rateGreaterThanAggregation } from '../common';
import { numberToLocaleString } from 'app/utils/formatNumber';

const debounceTime = 200;

const getColumnsSingleValue = data => {
  const columns = data.map(s => [s.id, s.value.v || 0]);
  const names = data.reduce((obj, s) => ({ ...obj, [s.id]: s.label }), {});
  const colors = data.reduce((obj, s) => ({ ...obj, [s.id]: s.color }), {});
  return { names, columns, colors };
};

const Container = styled.div`
  & .c3-chart-arcs-title {
    font-size: ${props => props.size / 12}px;
  }
`;

const Total = styled.div`
  position: absolute;
  top: 20px;
  left: calc(50% - 5rem);
  font-size: 1.2rem;
  max-width: max-content;
`;

class DonutChart extends Component {
  componentDidMount() {
    this.createChart();
  }

  componentDidUpdate(prevProps) {
    const { widget, data, width, height, shouldUpdate } = this.props;

    if (shouldUpdate) {
      if (
        !isEqual(widget, prevProps.widget) ||
        !isEqual(
          data.map(x => ({ ...x, value: undefined })),
          prevProps.data.map(x => ({ ...x, value: undefined }))
        )
      ) {
        this.createChart();
        return;
      }

      if (width !== prevProps.width || height !== prevProps.height) {
        this.updateSize();
      }

      if (!isEqual(data, prevProps.data) || shouldUpdate !== prevProps.shouldUpdate) {
        this.debouncedUpdateData();
      }
    }
  }

  createChart = () => {
    this.created = false;
    const { data, widget, height, width } = this.props;

    if (data.length === 0) {
      return;
    }

    const { names, columns, colors } = getColumnsSingleValue(data);
    window.d3 = undefined;

    this.chart = c3.generate({
      bindto: `#chart-${widget.key}`,
      size: {
        height,
        width
      },
      data: {
        type: widget.type ? `${widget.type}` : 'donut',
        columns,
        colors,
        names,
        order: null
      },
      donut: {
        label: {
          format: (value, ratio, id, index) => this.getLabel(value, ratio, id, false)
        }
      },
      pie: {
        label: {
          format: (value, ratio, id, index) => this.getLabel(value, ratio, id, false)
        }
      },
      padding: {
        top: widget.type && widget.type === 'pie' && 20
      },
      transition: {
        duration: 200
      },
      legend: {
        show: widget.legend !== 'none',
        position: widget.legend,
        item: {
          onclick: id => {
            this.chart.internal.api.toggle(id);
            this.updateTotal();
          }
        }
      },
      tooltip: {
        format: {
          value: (value, ratio, id) => this.getLabel(value, ratio, id, true)
        }
      }
    });
    this.created = true;

    this.updateTotal();
  };

  updateSize = () => {
    const { width, height } = this.props;
    this.chart.resize({
      height,
      width
    });

    this.updateTotal();
  };

  updateTotal = () => {
    const { widget, data } = this.props;
    const { variables } = widget;
    const maxDecimalDigits = variables.reduce(
      (result, aVar) => (aVar.decimalDigits > result ? aVar.decimalDigits : result),
      -1
    );
        
    const totalText = d3.selectAll(`#chart-${widget.key} text.c3-chart-arcs-title`);
    if (!data || data.length === 0) {
      totalText.text('');
      return;
    }

    const unit = data[0]._unit;

    if (!widget.showTotal) {
      totalText.attr('fill', '#999').text(unit);
      return;
    }

    const { hiddenTargetIds } = this.chart.internal;
    const sum = data.reduce((res, s) => {
      if (!hiddenTargetIds.includes(s.id)) {
        return res + s.value.v || 0;
      }
      return res;
    }, 0);
    totalText
      .attr('fill', '#333')
      .text(`${numberToLocaleString(sum,maxDecimalDigits)} ${unit}`);
  };

  updateData = () => {
    if (!this.created) {
      return;
    }
    const { data } = this.props;

    const { columns } = getColumnsSingleValue(data);

    this.chart.load({
      columns
    });

    this.updateTotal();
  };

  getLabel = (value, ratio, id, isTooltip) => {
    const { period, data, widget } = this.props;
    const { interval, aggregation } = period;
    const item = data.find(d => d.id === id);
    const { valueType, physicalQuantity, decimalDigits } = item;
    const isBool =
      valueType === 'binary' || physicalQuantity === 'state' || physicalQuantity === 'event';
    const greaterBtwRateAndAgg = item.rate
      ? rateGreaterThanAggregation(item.rate, aggregation)
      : aggregation;
    const oldValue = !isBool && lastValueUpdated(interval, greaterBtwRateAndAgg, item.value);
    if (isTooltip) {
      const time =
        item.value && item.value.t && moment(item.value.t).format('DD/MM/YYYY, HH:mm:ss');
      const label = `${numberToLocaleString(value,decimalDigits)} ${
        data.find(x => x.id === id)._unit
      } (${numberToLocaleString(ratio*100,1)}%)`;
      return `${label} ${time}`;
    } else {
      const label =
        widget.format === 'value'
          ? numberToLocaleString(value,decimalDigits)
          : `${numberToLocaleString(ratio*100,1)}%`;
      return oldValue ? `*${label}` : label;
    }
  };

  debouncedUpdateData = debounce(this.updateData, debounceTime);

  render() {
    const { data, widget, height, width } = this.props;
    const size = Math.min(height, width);
    const total =
      data &&
      data.length > 0 &&
      data
        .filter(dt => dt.value.v && !Number.isNaN(dt.value.v))
        .map(d => d.value.v)
        .reduce((a, b) => a + b, 0)
        .toLocaleString(undefined, { maximumFractionDigits: 4 });
    const unit = data && data.length > 0 && data[0].unit;
    return (
      <>
        <Container id={`chart-${widget.key}`} size={size} />
        {widget.type && widget.type === 'pie' && widget.showTotal && (
          <Total>Tot. {`${numberToLocaleString(total)} ${unit}`}</Total>
        )}
      </>
    );
  }
}

export default DonutChart;
