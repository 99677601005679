import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { DynamicForm, Checkbox } from 'app/common';
import logger from 'app/common/log';

const CheckboxContainer = styled.div`
  margin-bottom: 0rem;
  margin-top: -1rem;
`;

const NewEventTagForm = forwardRef((props, ref) => {
  logger.debug('NewEventTagForm ', props);
  const { onSubmit, onCancel, isNew, companyList, tag, predefinedGrant, tagValidationFn } = props;
  const only1Company = companyList && companyList.length === 1;
  const [initialValues, setInitialValues] = useState({
    predefined: tag && tag.predefined,
    id: tag && tag.id,
    name: tag && tag.name,
    companyId: isNew ? (only1Company ? companyList[0].id : null) : tag && tag.companyId
  });
  const fields = [
    {
      name: 'id',
      type: 'number',
      hidden: true
    },
    {
      name: 'name',
      label: <Trans>Nome Tag</Trans>,
      md: 12,
      type: 'text',
      validation: {
        required: true,
        func: tagValidationFn
      },
      props: { disabled: false }
    },
    {
      name: 'predefined',
      type: 'custom',
      hidden: !isNew || (isNew && !predefinedGrant),
      md: 12,
      render: field => (
        <CheckboxContainer>
          <Checkbox
            disabled={!isNew}
            checked={field && field.value}
            onChange={e => {
              setInitialValues(values => ({ ...values, predefined: !field.value }));
              logger.debug('saveAsSystemTag  ', e.target);
            }}
            label={
              <>
                <strong>
                  <Trans>Imposta come tag predefinito</Trans>
                </strong>
              </>
            }
          />
        </CheckboxContainer>
      )
    },
    {
      name: 'companyId',
      type: 'select',
      label: <Trans>Azienda</Trans>,
      md: 12,
      validation: { required: true },
      nullable: false,
      canSearch: false,
      disabled: !isNew || (only1Company && isNew),
      hidden: initialValues && initialValues.predefined,
      options: companyList,
      valueProperty: 'id',
      labelProperty: 'name'
    }
  ];

  return (
    <>
      <DynamicForm
        ref={ref}
        title={''}
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideButtons
      />
    </>
  );
});

export default NewEventTagForm;
