import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import api from 'api';
import UserDetails from './UserDetails';
import UserList from './UserList';
import CreateUser from '../../CreateUser/CreateUser';

const UserAuth = props => {
  const { match } = props;
  const { url } = match;
  const [userData, setUserData] = useState([]);

  const getAllUserData = async () => {
    try {
      setUserData(null);
      const userInfos = await api.get('/UserInfos');
      const userInfosDisabled = await api.get('/UserInfos/disabled');
      setUserData(userInfos.data.map(user => ({...user, disabled: userInfosDisabled.data.find(userDis => userDis.id === user.id).disabled})));
    } catch (err) {
      setUserData([]);
      console.log('err ', err);
    }
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  return (
    <Switch>
      <Route path={`${url}/createuser`}
      render={props => (
        <CreateUser {...props} url={url} userData={userData}/>
      )}
      />
      <Route
        path={`${url}/:userid`}
        render={props => (
          <UserDetails {...props} url={url} userData={userData} />
        )}
      />
      <Route
        exact
        path={url}
        render={props => <UserList {...props} url={url} userData={userData} 
        setUserData={userData => setUserData(userData)}
      />
    }
      />
    </Switch>
  );
};

export default UserAuth;
