/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveTab, createVariable } from 'ducks/actions/databrowsing';
import {
  faCalendar,
  faCog,
  faPlus,
  faCalculator,
  faTable,
  faChartLine,
  faDownload,
  faChartPie,
  faAlignLeft,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  ButtonGroup,
} from 'reactstrap';
import { Select as StyledSelect, Button, Spinner } from 'app/common';
import logger from 'app/common/log';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Select from 'react-select';
import {
  calcRollingDate,
  getDefaultPresentationUnit,
  OP_MAX_SELECTION,
  getChartTypes,
  CHART_TYPE_TIME_SERIES_VAR,
  CHART_TYPE_TIME_SERIES_TIME,
  CHART_TYPE_CURVA,
  CHART_TYPE_SCATTER,
  CHART_TYPE_PIE_VAR,
  CHART_TYPE_PIE_TIME,
  CHART_TYPE_CUSUM,
  CHART_TYPE_DISTRBUZIONE,
  CHART_TYPE_CARPET,
  DATA_FOR_CSV_HEADER,
  DATA_FOR_CSV_DATA,
  DATA_FOR_XLS_DATA,
  getSequenceData,
  getPieData,
  getCusumData,
  getDistribuzioneValue,
  getHeatMapData,
  getScatterData,
  getCurvaCaricoData,
  getCorrectTextColor,
  generateColor,
  rebuildDates
} from './Functions';
import Token from '../../common/Token/Token';
import MultiSelection from './MultiSelection';
import { VariableOptions, CalendarOptions, VariableDerivata, DataExport } from './Modals';
import FiltersDialog from '../tools/virtualmeters/EditVirtualMeter/formula/FiltersDialog';
import { toast } from 'react-toastify';

const RowFilter = styled(Row)`
  &&&& {
    padding: 2px 0;
  }
`;

const ColButtons = styled(Col)`
  &&&& {
    padding: 0 15px 6px 15px;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    //    background-color:#FAFAFA;
  }
`;

const PlaceholderTag = styled.div`
  padding: 0px 5px;
  margin: 2px;
  display: inline-flex;
  min-height: 34px;
  line-height: 30px;
  flex-direction: row;
  border-radius: 8px;
  border: 2px solid #c1bbbb;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  &&&& {
    width: 21px;
    height: 21px;
    margin: 5px 2px;
    color: #bdc3c7;
    cursor: pointer;
  }
`;

const OptionIcon = styled.img`
  &&&& {
    width: 16px;
    height: 16px;
  }
`;

const DerivataIcon = styled(FontAwesomeIcon)`
  &&&& {
    right: 7px;
    top: 7px;
    height: 25px;
    width: 25px;
    position: absolute;
    cursor: pointer;
  }
`;

const TokenItem = styled(Token)`
  float: left;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  padding-right: 0.5rem;
`;

const updateExportData = (tab, units, done) => {
  logger.debug('updateExportData for chart: %s', tab.chartType);
  const exportData = {};
  if (tab && 'seriesActive' in tab) {
    switch (tab.chartType) {
      case CHART_TYPE_PIE_VAR:
      case CHART_TYPE_PIE_TIME:
        exportData.csvHeaders = getPieData(tab, DATA_FOR_CSV_HEADER);
        exportData.csvData = getPieData(tab, DATA_FOR_CSV_DATA);
        exportData.excelData = getPieData(tab, DATA_FOR_XLS_DATA);
        break;
      case CHART_TYPE_CUSUM:
        exportData.csvHeaders = getCusumData(tab, DATA_FOR_CSV_HEADER);
        exportData.csvData = getCusumData(tab, DATA_FOR_CSV_DATA);
        exportData.excelData = getCusumData(tab, DATA_FOR_XLS_DATA);
        break;
      case CHART_TYPE_DISTRBUZIONE:
        exportData.csvHeaders = getDistribuzioneValue(tab, DATA_FOR_CSV_HEADER, null);
        exportData.csvData = getDistribuzioneValue(tab, DATA_FOR_CSV_DATA, units);
        exportData.excelData = getDistribuzioneValue(tab, DATA_FOR_XLS_DATA, units);
        break;
      case CHART_TYPE_CARPET:
        exportData.csvHeaders = getHeatMapData(tab, DATA_FOR_CSV_HEADER);
        exportData.csvData = getHeatMapData(tab, DATA_FOR_CSV_DATA);
        exportData.excelData = getHeatMapData(tab, DATA_FOR_XLS_DATA);
        break;
      case CHART_TYPE_SCATTER:
        exportData.csvHeaders = getScatterData(tab, DATA_FOR_CSV_HEADER);
        exportData.csvData = getScatterData(tab, DATA_FOR_CSV_DATA);
        exportData.excelData = getScatterData(tab, DATA_FOR_XLS_DATA);
        break;
      case CHART_TYPE_CURVA:
        exportData.csvHeaders = getCurvaCaricoData(tab, DATA_FOR_CSV_HEADER, null);
        exportData.csvData = getCurvaCaricoData(tab, DATA_FOR_CSV_DATA, units);
        exportData.excelData = getCurvaCaricoData(tab, DATA_FOR_XLS_DATA, units);
        break;
      default:
        {
          exportData.csvHeaders = getSequenceData(tab, DATA_FOR_CSV_HEADER, null);
          const excelData = getSequenceData(tab, DATA_FOR_XLS_DATA, units);
          exportData.csvData = excelData && excelData.reverse();
          exportData.excelData = excelData;
        }
        break;
    }
  }
  done();
  return exportData;
};

const FilterBox = props => {
  const CHART_TYPES = getChartTypes();
  const [modalOptionVariable, setModalOptionVariable] = useState(false);
  const [modalOptionDerivate, setModalOptionDerivate] = useState(false);
  const [modalOptionDate, setModalOptionDate] = useState(false);
  const [selectedOptionVariable, setSelectedOptionVariable] = useState();
  const [selectedOptionDate, setSelectedOptionDate] = useState();
  const [openVariableDialog, setOpenVariableDialog] = useState(false);
  const [selectedChart, setSelectedChart] = useState(CHART_TYPES[0]);
  const [headersAndData, setHeadersAndData] = useState({});
  const [forceUpdate, setForceUpdate] = useState(0);
  const [exportData, setExportData] = useState();
  const [loadingExportData, setLoadingExportData] = useState(false);
  const [showExportData, setShowExportData] = useState(false);

  const [checkVariabileSelection, setCheckVariabileSelection] = useState();
  const [checkCountVariable, setCheckCountVariable] = useState();
  const [checkPeriodoSelection, setCheckPeriodoSelection] = useState();

  const { tab, units } = props;
  const { chartType } = tab;

  //Check for deleted variables and create toast if there are any
  useEffect(() => {
    const { observableproperties } = props;
    let deletedVariable = false;
    tab.variableSelection.map(opToFind => {
      const opFound = observableproperties.find(op => op.id.toString() === opToFind.id);
      if(!opFound){
        deletedVariable = true;
      }
    });
    if(deletedVariable){
      toast.warn(i18n._(t`Sono presenti variabili eliminate o non trovate, per le quali la piattaforma non riceve più dati.`));
    }
  },[tab.variableSelection])

  useEffect(() => {
    logger.debug('useEffect setShowExportData');
    setShowExportData(true);
  }, [exportData]);

  useEffect(() => {
    logger.debug('useEffect loadingExportData ', loadingExportData);
    if (loadingExportData) {
      setExportData(updateExportData(tab, units, () => setLoadingExportData(false)));
    }
  }, [loadingExportData]);

  useEffect(() => {
    setSelectedChart(
      chartType
        ? CHART_TYPES.find(item => item.id === tab.chartType)
        : CHART_TYPES.find(item => item.default)
    );
  }, [chartType, tab]);

  useEffect(() => {
    logger.debug('useEffect %s %o', selectedChart, tab);
    let csvHeaders = false;
    let csvData = false;
    let excelData = false;
    const checkVariabileSelection =
      (tab && tab.variableSelection ? tab.variableSelection.length : 0) <
        (selectedChart ? selectedChart.selectVariableMode : 0) ||
      (selectedChart && selectedChart.selectVariableMode === null);
    const checkCountVariable = !(
      tab.variableSelection && tab.variableSelection.length > OP_MAX_SELECTION
    );
    const checkPeriodoSelection =
      (tab && tab.dateSelection ? tab.dateSelection.length : 0) <
        (selectedChart ? selectedChart.selectDateMode : 0) ||
      (selectedChart && selectedChart.selectDateMode === null);

    setCheckVariabileSelection(checkVariabileSelection);
    setCheckCountVariable(checkCountVariable);
    setCheckPeriodoSelection(checkPeriodoSelection);
    if (tab && 'seriesActive' in tab) {
      switch (tab.chartType) {
        case CHART_TYPE_PIE_VAR:
        case CHART_TYPE_PIE_TIME:
          headersAndData.csvHeaders = getPieData(tab, DATA_FOR_CSV_HEADER);
          headersAndData.csvData = getPieData(tab, DATA_FOR_CSV_DATA);
          headersAndData.excelData = getPieData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_CUSUM:
          headersAndData.csvHeaders = getCusumData(tab, DATA_FOR_CSV_HEADER);
          headersAndData.csvData = getCusumData(tab, DATA_FOR_CSV_DATA);
          headersAndData.excelData = getCusumData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_DISTRBUZIONE:
          headersAndData.csvHeaders = getDistribuzioneValue(tab, DATA_FOR_CSV_HEADER, null);
          headersAndData.csvData = getDistribuzioneValue(tab, DATA_FOR_CSV_DATA, units);
          headersAndData.excelData = getDistribuzioneValue(tab, DATA_FOR_XLS_DATA, units);
          break;
        case CHART_TYPE_CARPET:
          headersAndData.csvHeaders = getHeatMapData(tab, DATA_FOR_CSV_HEADER);
          headersAndData.csvData = getHeatMapData(tab, DATA_FOR_CSV_DATA);
          headersAndData.excelData = getHeatMapData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_SCATTER:
          headersAndData.csvHeaders = getScatterData(tab, DATA_FOR_CSV_HEADER);
          headersAndData.csvData = getScatterData(tab, DATA_FOR_CSV_DATA);
          headersAndData.excelData = getScatterData(tab, DATA_FOR_XLS_DATA);
          break;
        case CHART_TYPE_CURVA:
          headersAndData.csvHeaders = getCurvaCaricoData(tab, DATA_FOR_CSV_HEADER, null);
          headersAndData.csvData = getCurvaCaricoData(tab, DATA_FOR_CSV_DATA, units);
          headersAndData.excelData = getCurvaCaricoData(tab, DATA_FOR_XLS_DATA, units);
          break;
        default:
          {
            headersAndData.csvHeaders = getSequenceData(tab, DATA_FOR_CSV_HEADER, null);
            const excelData = getSequenceData(tab, DATA_FOR_XLS_DATA, units);
            headersAndData.csvData = excelData && excelData.reverse();
            headersAndData.excelData = excelData;
          }
          break;
      }
    }
    setHeadersAndData({ csvHeaders, csvData, excelData });
  }, [selectedChart, tab, tab.dateSelection, tab.variableSelection]);

  useEffect(() => {}, [modalOptionVariable, modalOptionDate]);

  const deleteVariable = id => {
    const { tab } = props;

    tab.variableSelection = tab.variableSelection.filter(x => {
      const tetCurrentVar = x.id != id;
      let testSerieRef = true;
      let testComparataRef = true;

      if (
        x.serieRiferimento &&
        Array.isArray(x.serieRiferimento) &&
        x.serieRiferimento.findIndex(fRif => fRif.value == id) >= 0
      ) {
        testSerieRef = false;
      } else if (
        x.serieRiferimento &&
        !Array.isArray(x.serieRiferimento) &&
        x.serieRiferimento.value == id
      ) {
        testSerieRef = false;
      }

      if (
        x.serieComparata &&
        Array.isArray(x.serieComparata) &&
        x.serieComparata.findIndex(fRif => fRif.value == id) >= 0
      ) {
        testComparataRef = false;
      } else if (
        x.serieComparata &&
        !Array.isArray(x.serieComparata) &&
        x.serieComparata.value == id
      ) {
        testComparataRef = false;
      }

      return tetCurrentVar && testSerieRef && testComparataRef;
    });

    setForceUpdate(i => i++); // Anticipa aggiornamento render e posticipa elaborazione redux
    props.saveTab(tab);
  };

  const deleteData = id => {
    const { tab } = props;
    tab.dateSelection = tab.dateSelection.filter(x => x.id !== id);

    setForceUpdate(i => i++); // Anticipa aggiornamento render e posticipa elaborazione redux
    props.saveTab(tab);
  };

  const sortItems = (items, oldIndex, newIndex) => {
    const { tab } = props;

    if (newIndex >= items.length) {
      let k = newIndex - items.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        items.push(undefined);
      }
    }
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);

    setForceUpdate(i => i++); // Anticipa aggiornamento render e posticipa elaborazione redux
    props.saveTab(tab);
  };

  const changeChart = e => {
    logger.debug('changeChart %s', e);
    const { tab } = props;
    tab.chartType = e.value;
    if (tab.chartType === CHART_TYPE_PIE_TIME) {
      tab.configuration.timeframeSelected = 'none'; // se si passa da un chart differente al PIE TIME cambia la visualizzazione al PIE generico
    }
    props.saveTab(tab);
  };

  const changeTimeframe = e => {
    const { tab } = props;
    tab.configuration.timeframeSelected = e.target.value;
    props.saveTab(tab);
  };

  const dismissAction = (update = false) => {
    const { tab } = props;
    setModalOptionVariable(false);
    setModalOptionDate(false);
    setModalOptionDerivate(false);
    if (update) props.saveTab(tab);
  };

  const saveDataAction = (
    startDate,
    endDate,
    rolling,
    rollingStartDate,
    rollingEndDate,
    rollingDiff,
    rollingShift
  ) => {
    const { tab } = props;
    let saved = false;
    if (selectedOptionDate !== null) {
      const data = tab.dateSelection.find(item => item.id === selectedOptionDate);
      tab.configuration.timeframeSelected = undefined;
      data.startDate = startDate;
      data.endDate = endDate;
      data.rolling = rolling;
      data.rollingStartDate = rollingStartDate;
      data.rollingEndDate = rollingEndDate;
      data.rollingDiff = rollingDiff;
      data.rollingShift = rollingShift;
      saved = true;
    } else {
      const bk = generateColor(tab.dateSelection.map(b => b.background));
      const text = getCorrectTextColor(bk);
      tab.dateSelection.push({
        id: moment()
          .unix()
          .toString(),
        startDate,
        endDate,
        rolling,
        rollingStartDate,
        rollingEndDate,
        rollingDiff,
        rollingShift,
        background: bk,
        textcolor: text
      });
      saved = true;
    }

    rebuildDates(
      tab.dateSelection[0].startDate,
      tab.dateSelection[0].endDate,
      tab.dateSelection[0].rolling,
      tab.dateSelection[0].rollingStartDate,
      tab.dateSelection[0].rollingEndDate,
      tab.dateSelection[0].rollingDiff,
      tab.dateSelection[0].rollingShift,
      tab
    );

    if (saved) {
      props.saveTab(tab);
      setModalOptionVariable(false);
      setModalOptionDate(false);
    }
  };

  const saveVariabileDerivata = newVariable => {
    const { tab } = props;

    const newBk = generateColor(tab.variableSelection.map(b => b.background));
    const compileVariable = {
      ...newVariable,
      background: newBk,
      textcolor: getCorrectTextColor(newBk),
      totale: false,
      media: false,
      minimo: false,
      massimo: false
    };

    tab.variableSelection.push(compileVariable);

    props.saveTab(tab);
    dismissAction(true);
  };

  const changeTableMode = (active, pieType) => {
    const { tab } = props;
    tab.tableMode = active;
    if (pieType !== undefined) {
      tab.pieType = pieType;
    }
    props.saveTab(tab);
  };

  const getPathUrl = (siteId, assetId) => {
    const pathList = [];
    const sites = siteId != null ? props.sites.filter(x => x.id === siteId).pop() : undefined;
    if (sites !== undefined) pathList.push(sites.name);
    const assets = assetId != null ? props.assets.filter(x => x.id === assetId).pop() : undefined;
    if (assets !== undefined) pathList.push(assets.name);
    return pathList.join(' / ');
  };

  const onSelect = async selectedVariableList => {
    const { tab, sites, catalogs, physicaldimensions } = props;

    if (selectedVariableList && selectedVariableList.length > 0) {
      const idsFinded = [];

      for (const tryOP of selectedVariableList) {
        try {
          idsFinded.push(tryOP.id.toString());
          let compileVariable = {};
          const finded = tab.variableSelection.find(x => x.id === tryOP.id.toString());
          if (finded === undefined && tryOP) {
            const siteDetail = sites.find(s => s.id === tryOP.siteId);
            const timezone = siteDetail ? siteDetail.timeZone : null;
            const defaultPresentationUnit = getDefaultPresentationUnit(
              catalogs,
              physicaldimensions,
              tryOP.physicalQuantity,
              tryOP.customQuantityId
            );
            const background = generateColor(tab.variableSelection.map(b => b.background));
            compileVariable = createVariable(
              background,
              tryOP.id,
              tryOP.name,
              tryOP.valueType,
              tryOP.physicalQuantity,
              defaultPresentationUnit ? defaultPresentationUnit.symbol : null,
              defaultPresentationUnit ? defaultPresentationUnit.exactoConversionFactor : 1,
              timezone,
              getPathUrl(tryOP.siteId, tryOP.assetId),
              tryOP.aggregationRule,
              '1m',
              '1'
            );
            tab.variableSelection.push(compileVariable);
          }
        } catch (err) {
          console.warn(err);
        }
      }

      /* rimuovi elementi rimossi dalla dialog */
      tab.variableSelection = tab.variableSelection.filter(
        f => f.derivataType || idsFinded.indexOf(f.id.toString()) >= 0
      );
    } else {
      tab.variableSelection = [];
    }

    props.saveTab(tab);
    toggle();
  };

  const toggle = () => {
    setOpenVariableDialog(oldValue => !oldValue);
  };

  const updateHeatTf = (e, axes) => {
    const { tab } = props;
    tab[axes] = e.target.value;
    props.saveTab(tab);
  };

  return (
    <div>
      <RowFilter>
        <Col sm="12">
          <MultiSelection
            sortAction={({ oldIndex, newIndex }) =>
              sortItems(tab.variableSelection, oldIndex, newIndex)
            }
            items={
              tab && tab.variableSelection
                ? tab.variableSelection
                    .filter(x => !!x)
                    .map(x => (
                      <TokenItem
                        optionItem={id => {
                          setSelectedOptionVariable(id);
                          setModalOptionVariable(true);
                        }}
                        deleteItem={deleteVariable}
                        id={x.id}
                        path={x.path}
                        label={x.label}
                        derivate={x.derivataType !== undefined}
                        background={x.background}
                        textcolor={x.textcolor}
                        disable={x.disable}
                        icon={faCog}
                      />
                    ))
                : []
            }
            newVariable={
              checkVariabileSelection &&
              checkCountVariable && (
                <PlaceholderTag onClick={toggle}>
                  <StyledIcon icon={faPlus} /> <Trans>Aggiungi variabile</Trans>
                </PlaceholderTag>
              )
            }
            variabileDerivata={
              <DerivataIcon color="#545b62" icon={faCalculator} onClick={() => setModalOptionDerivate(true)} />
            }
          />
        </Col>
      </RowFilter>

      <RowFilter>
        <Col sm="12">
          <MultiSelection
            sortAction={({ oldIndex, newIndex }) =>
              sortItems(tab.dateSelection, oldIndex, newIndex)
            }
            items={
              tab && tab.dateSelection
                ? tab.dateSelection.map(x => {
                    const { startDate, endDate } = calcRollingDate(x);
                    return (
                      <TokenItem
                        optionItem={id => {
                          setModalOptionDate(true);
                          setSelectedOptionDate(id);
                        }}
                        deleteItem={deleteData}
                        id={x.id}
                        startDate={startDate}
                        endDate={endDate}
                        background={x.background}
                        textcolor={x.textcolor}
                        disable={x.disable}
                        icon={faCalendar}
                      />
                    );
                  })
                : []
            }
            newVariable={
              checkPeriodoSelection && (
                <PlaceholderTag
                  onClick={() => {
                    {
                      setModalOptionDate(true);
                      setSelectedOptionDate(null);
                    }
                  }}
                >
                  <StyledIcon icon={faPlus} /> <Trans>Aggiungi periodo</Trans>
                </PlaceholderTag>
              )
            }
          />
        </Col>
      </RowFilter>
      <RowFilter>
        <Col sm="4">
          <Select
            id="chart_types"
            maxMenuHeight={1000}
            value={selectedChart}
            onChange={changeChart}
            options={CHART_TYPES.map(x => ({
              value: x.id,
              label: x.label,
              icon: x.icon
            }))}
            getOptionLabel={option => (
              <div>
                <OptionIcon src={option.icon} /> {option.label}
              </div>
            )}
          />
        </Col>
        {(tab.chartType === CHART_TYPE_PIE_TIME ||
          tab.chartType === CHART_TYPE_TIME_SERIES_VAR ||
          tab.chartType === CHART_TYPE_TIME_SERIES_TIME ||
          tab.chartType === CHART_TYPE_CUSUM ||
          tab.chartType === CHART_TYPE_DISTRBUZIONE ||
          tab.chartType === CHART_TYPE_SCATTER ||
          tab.chartType === CHART_TYPE_CURVA) && (
          <Col sm="4">
            <StyledSelect
              id="changeTimeFrame"
              onChange={changeTimeframe}
              value={tab && tab.configuration.timeframeSelected}
              disabled={tab && !tab.timeframeEnable}
              options={
                tab &&
                tab.timeframeAvaible !== undefined &&
                tab.timeframeAvaible.map(x => ({
                  value: x.id,
                  label: x.label
                }))
              }
            />
          </Col>
        )}
        {tab.chartType === CHART_TYPE_CARPET && (
          <Col sm="2">
            <StyledSelect
              onChange={e => updateHeatTf(e, 'heatX')}
              value={tab.heatX || null}
              options={
                tab.HeatMapXAxisOptions
                  ? tab.HeatMapXAxisOptions.map(x => ({
                      value: x.id,
                      label: x.label
                    }))
                  : []
              }
            />
          </Col>
        )}
        {tab.chartType === CHART_TYPE_CARPET && (
          <Col sm="2">
            <StyledSelect
              onChange={e => updateHeatTf(e, 'heatY')}
              value={tab.heatY || null}
              options={
                tab.HeatMapYAxisOptions
                  ? tab.HeatMapYAxisOptions.filter(x => x.visible).map(x => ({
                      value: x.id,
                      label: x.label
                    }))
                  : []
              }
            />
          </Col>
        )}
        <ColButtons sm="4">
          <StyledButtonGroup>
            {tab.chartType !== CHART_TYPE_PIE_VAR && tab.chartType !== CHART_TYPE_PIE_TIME && (
              <Button size="sm" active={!tab.tableMode} onClick={() => changeTableMode(false)}>
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
            )}
            {(tab.chartType === CHART_TYPE_PIE_VAR || tab.chartType === CHART_TYPE_PIE_TIME) && (
              <Button
                size="sm"
                active={!tab.tableMode && !tab.pieType}
                onClick={() => changeTableMode(false, 0)}
              >
                <FontAwesomeIcon icon={faChartPie} />
              </Button>
            )}

            {(tab.chartType === CHART_TYPE_PIE_VAR || tab.chartType === CHART_TYPE_PIE_TIME) && (
              <Button
                size="sm"
                active={!tab.tableMode && tab.pieType === 1}
                onClick={() => {
                  changeTableMode(false, 1);
                }}
              >
                <FontAwesomeIcon icon={faChartBar} />
              </Button>
            )}

            {(tab.chartType === CHART_TYPE_PIE_VAR || tab.chartType === CHART_TYPE_PIE_TIME) && (
              <Button
                size="sm"
                active={!tab.tableMode && tab.pieType === 2}
                onClick={() => {
                  changeTableMode(false, 2);
                }}
              >
                <FontAwesomeIcon icon={faAlignLeft} />
              </Button>
            )}

            <Button size="sm" active={tab.tableMode} onClick={() => changeTableMode(true)}>
              <FontAwesomeIcon icon={faTable} />
            </Button>
            {loadingExportData && <Spinner />}
            {exportData &&
              exportData.excelData &&
              exportData.csvHeaders &&
              exportData.csvData &&
              Array.isArray(exportData.csvHeaders) && (
                <DataExport
                  title={i18n._(t`Esporta Dati`)}
                  dismiss={() => setShowExportData(false)}
                  isOpen={showExportData}
                  excelData={exportData.excelData}
                  csvData={exportData.csvData}
                  csvHeaders={exportData.csvHeaders}
                  fileName={tab.name}
                  exportJpeg={() => {
                    setShowExportData(false);
                    props.onImageExport('image/jpeg', `${tab.name}.jpeg`);
                  }}
                  exportPng={() => {
                    setShowExportData(false);
                    props.onImageExport('image/png', `${tab.name}.png`);
                  }}
                  exportSvg={() => {
                    setShowExportData(false);
                    props.onImageExport('svg', `${tab.name}`);
                  }}
                />
              )}
          </StyledButtonGroup>
          <Button
            style={{ overflow: 'visible' }}
            size="sm"
            color="primary"
            onClick={() => {
              setLoadingExportData(true);
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </ColButtons>
      </RowFilter>

      {modalOptionDerivate && (
        <VariableDerivata
          dismissAction={dismissAction}
          saveAction={saveVariabileDerivata}
          maxLen={OP_MAX_SELECTION - tab.variableSelection.length}
          token={{}}
          tab={tab}
        />
      )}

      {modalOptionVariable && (
        <VariableOptions
          dismissAction={dismissAction}
          token={tab.variableSelection.filter(item => item.id == selectedOptionVariable).pop()}
        />
      )}

      {modalOptionDate && (
        <CalendarOptions
          saveAction={saveDataAction}
          dismissAction={dismissAction}
          rangeCalendar={
            tab.dateSelection.length === 0 ||
            tab.dateSelection.map(item => item.id).indexOf(selectedOptionDate) === 0
          }
          token={tab.dateSelection.find(item => item.id === selectedOptionDate)}
        />
      )}

      <FiltersDialog
        isOpen={openVariableDialog}
        toggle={toggle}
        onSelect={onSelect}
        title={i18n._(t`Seleziona variabili`)}
        mutipleSelection
        excludeSelection={
          tab.variableSelection
            ? tab.variableSelection.filter(f => !!f && !('derivataType' in f)).map(d => ({id: d.id, name: d.label}))
            : []
        }
        maxSelect={OP_MAX_SELECTION}
      />
    </div>
  );
};

FilterBox.defaultProps = {};

FilterBox.propTypes = {
  tab: PropTypes.object.isRequired, // from Redux
  saveTab: PropTypes.func.isRequired, // from Redux
  observableproperties: PropTypes.array.isRequired, // from Redux
  units: PropTypes.array.isRequired, // from Redux
  onImageExport: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired, // from Redux
  sites: PropTypes.array.isRequired, // from Redux
  catalogs: PropTypes.object.isRequired, // from Redux
  physicaldimensions: PropTypes.object.isRequired // from Redux
};

const mapStateToProps = (state, ownProps) => ({
  tab: state.databrowsing.tabs.find(tab => tab.offlineId === ownProps.tabId),
  databrowsing: state.databrowsing,
  selectedDomain: state.navigation.selectedDomain,
  observableproperties: state.domain.observableproperties,
  assets: state.navigation.assets,
  sites: state.navigation.sites,
  domains: state.navigation.domains,
  companies: state.navigation.companies,
  units: state.catalogs.units,
  catalogs: state.catalogs,
  physicaldimensions: state.domain.physicaldimensions
});

const mapDispatchToProps = dispatch => ({
  saveTab: (data, reload = true) => dispatch(saveTab(data, reload))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBox);
