import cx from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import ClearButton from './ClearButton.react';

import tokenContainer from './containers/tokenContainer';
import {RETURN} from './constants/keyCode';

import operatoriAlgebrici from './constants/operatoriAlgebrici';
import operatoriAritmetici from './constants/operatoriAritmetici';
import operatoriBooleani from './constants/operatoriBooleani';
import funzioniMatematiche from './constants/funzioniMatematiche';
import punctuation from './constants/punctuation';

/**
 * Token
 *
 * Individual token component, generally displayed within the TokenizerInput
 * component, but can also be rendered on its own.
 */
class Token extends React.Component {
  state = {
    clearButtonVisible: false,
  };

  render() {
    return this.props.onRemove && !this.props.disabled ?
      this._renderRemoveableToken() :
      this._renderToken();
  }

  _renderRemoveableToken = () => {
    const { active, activeTokens, index, children, className, onRemove, ...props } = this.props;

    //Gestione del colore del token in base alla sua tipologia in fase di edit
    let tokenColor = '';

    // OPERATORI ALGEBRICI
    if (operatoriAlgebrici.findIndex(element => isEqual(element.name, children)) > -1
        || operatoriAritmetici.findIndex(element => isEqual(element.name, children)) > -1
    ) {
      tokenColor = 'rbt-token-color-1';
    }
    // OPERATORI BOOLEANI
    else if (operatoriBooleani.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-2';
    }
    // FUNZIONI MATEMATICHE
    else if (funzioniMatematiche.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-3';
    }
    // PARENTESI
    else if (punctuation.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-4';
    }
    // VARAIBILI
    else {
      tokenColor = 'rbt-token-color-5';
    }

    return (
      <div
        {...props}
        className={cx('rbt-token', 'rbt-token-removeable', {
          'rbt-token-click': active,
          'rbt-token-active': activeTokens.includes(index),
        }, tokenColor, className)}
        onMouseOver={() => this.setState({ clearButtonVisible: true })}
        onMouseLeave={() => this.setState({ clearButtonVisible: false })}
      >
        {children}
        <ClearButton
          className="rbt-token-remove-button"
          label="Remove"
          onClick={onRemove}
          onKeyDown={this._handleRemoveButtonKeydown}
          tabIndex={-1}
          style={{visibility: this.state.clearButtonVisible ? 'visible' : 'hidden'}}
        />
      </div>
    );
  }

  _renderToken = () => {
    const {children, className, disabled, href} = this.props;

    //Gestione del colore del token in base alla sua tipologia in fase di sola visualizzazione
    let tokenColor = '';

    // OPERATORI ALGEBRICI
    if (operatoriAlgebrici.findIndex(element => isEqual(element.name, children)) > -1
        || operatoriAritmetici.findIndex(element => isEqual(element.name, children)) > -1
    ) {
      tokenColor = 'rbt-token-color-1';
    }
    // OPERATORI BOOLEANI
    else if (operatoriBooleani.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-2';
    }
    // FUNZIONI MATEMATICHE
    else if (funzioniMatematiche.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-3';
    }
    // PARENTESI
    else if (punctuation.findIndex(element => isEqual(element.name, children)) > -1) {
      tokenColor = 'rbt-token-color-4';
    }
    // VARAIBILI
    else {
      tokenColor = 'rbt-token-color-5';
    }

    const classnames = cx('rbt-token', {
      'rbt-token-disabled': disabled,
    }, tokenColor, className);

    if (href) {
      return (
        <a className={classnames} href={href}>
          {children}
        </a>
      );
    }

    return (
      <div className={classnames}>
        {children}
      </div>
    );
  }

  _handleRemoveButtonKeydown = (e) => {
    switch (e.keyCode) {
      case RETURN:
        this.props.onRemove();
        break;
      default:
        break;
    }
  }
}

Token.propTypes = {
  active: PropTypes.bool,
  /**
   * Handler for removing/deleting the token. If not defined, the token will
   * be rendered in a read-only state.
   */
  onRemove: PropTypes.func,
  tabIndex: PropTypes.number,
};

Token.defaultProps = {
  //active: false,
  tabIndex: 0,
};


export default tokenContainer(Token);
