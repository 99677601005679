import styled from 'styled-components';


const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
  transition: all .5s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #fff;
    opacity: 0.4;
    cursor: default;
  }


  &::-webkit-slider-runnable-track {
    width: 100%;
    height: .3rem;
    border-radius: 2px;
    transition: all .5s;
    background: ${props => props.color};
  }

  &::-webkit-slider-thumb {
    border: 1px solid #fff;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background: ${props => props.color};
    -webkit-appearance: none;
    margin-top: -0.55rem;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${props => props.color};
  }

  &::-moz-range-track {
    width: 100%;
    height: .3rem;
    border-radius: 2px;
    background: ${props => props.color};
  }
  &::-moz-range-thumb {
    border: 1px solid #fff;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background: ${props => props.color};
    margin-top: -0.55rem;
  }
  &::-ms-track {
    width: 100%;
    height: .3rem;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: ${props => props.color};
    border-radius: .5rem;
  }
  &::-ms-fill-upper {
    background: ${props => props.color};
    border-radius: .5rem;
  }
  &::-ms-thumb {
    border: 1px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.color};
    margin-top: 0;
  }
  &:focus::-ms-fill-lower {
    background: ${props => props.color};
  }
  &:focus::-ms-fill-upper {
    background: ${props => props.color};
  }
`;

export default Slider;
