/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  padding: 1px 30px 1px 1px;  
  background-color: #fff;
  border: solid 0.1rem #e4e5e6;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
`;

const SortableItem = SortableElement(({ value }) => value);

const SortableList = SortableContainer(({ items, input }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem key={value.props.id} index={index} value={value} sortIndex={index} />
    ))}
    { input }
  </div>
));

class MultiSelection extends Component {
  state = {};

  render() {
    const { items, newVariable } = this.props;

    return (
      <Wrapper>
        <SortableList
          axis="x"
          useDragHandle
          items={items}
          input={newVariable}
          onSortEnd={this.props.sortAction}
        />
        { this.props.variabileDerivata !== undefined && (this.props.variabileDerivata) }
      </Wrapper>
    );
  }
}

MultiSelection.defaultProps = {
  variabileDerivata: undefined,
};

MultiSelection.propTypes = {
  items: PropTypes.array.isRequired,
  sortAction: PropTypes.func.isRequired,
  newVariable: PropTypes.any.isRequired,
  variabileDerivata: PropTypes.any,
};


export default MultiSelection;
