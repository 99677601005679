import InputAutosize from 'react-input-autosize';
import styled from 'styled-components';

const Input = styled(InputAutosize)`
  & input {
    border: none;
    outline: none;
  }

  ${props => !props.canSearch && 'display: none !important;'}

  div[style] {
    overflow: hidden !important;
  }
`;

export default Input;
