import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [oldWidth, setOldWidth] = useState(window.innerWidth);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  let throttled = false;
  let forLastExec;

  const updateSize = () => {
    setOldWidth(width);
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const handleResize = () => {
    if (!throttled) {
      updateSize();

      throttled = true;

      setTimeout(() => {
        throttled = false;
      }, 1000);
    }

    clearTimeout(forLastExec);
    forLastExec = setTimeout(updateSize, 1000);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return { width, oldWidth, height };
};

export default useWindowSize;
