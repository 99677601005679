/* eslint-disable no-underscore-dangle */

import React, { useState, useMemo, useEffect, useRef } from 'react';
import api from 'api';
import { Tooltip } from 'reactstrap';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ReactSVG from 'react-svg';
import Spinner from './Spinner';
import ConfirmActionModal from './ConfirmActionModal';
import { getLedStates, widgetTypes, lastValueUpdated, rateGreaterThanAggregation } from '../common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const Svg = styled(ReactSVG)`
  margin: .5rem;
  height: ${props => props.size};
  line-height: 0;

  & svg {
    width: ${props => props.size};
    height: ${props => props.size};
    fill: ${props => props.color};
  }
`;

const SvgClickable = styled(Svg)`
  &:hover {
    cursor: pointer;
    animation: fa-pulse 1s ease infinite;
  }

  @keyframes fa-pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Info = styled.div`

`;

const StyledTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #fff;
    box-shadow: 2px 2px 4px gray;
    color: #999;
    max-width: 20rem;
  }

  & .tooltip.show {
    opacity: 1;
  }  
`;

const TooltipWrapper = styled.div`
  text-align: left;
  size: 1rem;

  & h6 {
    color: #185A92;
  }
`;

const TooltipState = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const TooltipValue = styled.span`
  margin-left: .5rem;
  white-space: nowrap;
  color: #666;
`;

const Name = styled.div`
  font-size: 0.9vw;
  color: #666;
`;

const Star = styled.span`
  font-size: 1vw;
  color: #666;
`;

const Label = styled.div`
  font-size: 0.8vw;
  color: ${props => props.color};
`;

const TooltipStateLabel = styled.span`
  color: ${props => (props.active ? '#333' : '#666')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

const SvgButton = (props) => {
  const { onClick } = props;
  return onClick ? <SvgClickable {...props} /> : <Svg {...props} />;
};


const Led = (props) => {
  const { widget, data, width, height, canSendCommand, toggleFullscreen, isFullscreen } = props;

  // OP properties
  const { id, label, value, properties, rate } = data;
  const { states } = properties;
  const { period } = widget;
  const { aggregation, interval } = period;

  // if value is a number or a string convert to boolean (0 => false, != 0 => true)
  const { v } = value;
  const currentValue = typeof v === 'number' || typeof v === 'string' ? !!v : v;
  const currentTime = new Date(value.t);


  // Widget properties
  const size = (widget.properties || {}).iconSize || 1;
  const iconSize = `${size * 2}vw`;
  const { showLabel, askConfirm } = widget.properties;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [fromFullscreen, setFromFullscreen] = useState(false);

  const waitResponseMaxTime = 12000;

  const timerId = useRef();

  useEffect(() => {
    if (waitingResponse) {
      timerId.current = setTimeout(() => {
        setWaitingResponse(false);
        toast.warn(i18n._(t`Il valore di ${label} non è stato aggiornato`));
      }, waitResponseMaxTime);
      return () => clearTimeout(timerId.current);
    }
  }, [waitingResponse]);

  useEffect(() => {
    clearTimeout(timerId.current);
    setWaitingResponse(false);
    console.log(label, value.v, currentValue, currentTime)
  }, [currentValue]);


  const ledStates = useMemo(getLedStates, []);

  const currentState = states.find(x => x.value === currentValue);
  const otherState = states.find(x => x.value !== currentValue);
  const otherStateLabel = otherState ? otherState.label || otherState.state : undefined;

  const tooltipId = `Icon_${widget.key}_${id}`;

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleConfirm = () => {
    fromFullscreen && toggleFullscreen();
    setConfirmOpen(open => !open);
  }

  const tooltip = useMemo(() => (
    <TooltipWrapper>
      <h6>{label}</h6>
      {states.map((state) => {
        const label = (ledStates.find(s => s.label === state.label) || { _label: state.label })._label;
        const active = state.value === currentValue;
        return (
          <TooltipState>
            <Svg size="1rem" color={state.color} src={`/assets/icons/led/${state.image}`} />
            <TooltipStateLabel active={active}>{label || state.state}</TooltipStateLabel>
            {active && <TooltipValue>{currentTime ? currentTime.toLocaleString(undefined, { maximumFractionDigits: 4 }) : <Trans>n.d.</Trans>}</TooltipValue>}
          </TooltipState>
        );
      })}
    </TooltipWrapper>
  ), [states, currentValue, currentTime]);

  const sendValue = async () => {
    fromFullscreen && toggleFullscreen();
    setConfirmOpen(false);
    setWaitingResponse(true);
    try {
      const res = await api.post(`/ObservedProperties/${id}/value`, { value: otherState.value });
      console.log(res.data);
    } catch (e) {
      console.log(e.message);
      toast.error(i18n._(t`Impossibile inviare il comando. Controllare che il gateway sia connesso`));
      setWaitingResponse(false);
    }
  };

  const handleClick = () => {
    if (canSendCommand) {
      if (askConfirm) {
        if(isFullscreen){
          setFromFullscreen(true);
          toggleFullscreen();
        }else{
          setFromFullscreen(false);
        }
        setConfirmOpen(true);
      } else {
        sendValue();
      }
    }
  };

  const isValueOld = () => {
    const { data } = props;
    const { valueType, physicalQuantity } = data;
    const isBool = valueType === 'binary' || (physicalQuantity === 'state' || physicalQuantity === 'event');
    const greaterBtwRateAndAgg = rate ? rateGreaterThanAggregation(rate, aggregation) : aggregation;
    const oldValue = !isBool && lastValueUpdated(interval, greaterBtwRateAndAgg, value);
    return oldValue;
  }


  const clickable = widget.widgetType === widgetTypes.SWITCH;

  let icon;
  if (!currentState || waitingResponse) {
    icon = <Spinner size={iconSize} />;
  } else if (clickable && canSendCommand) {
    icon = <SvgButton size={iconSize} color={currentState.color} src={`/assets/icons/led/${currentState.image}`} onClick={handleClick} />;
  } else {
    icon = <Svg size={iconSize} color={currentState.color} src={`/assets/icons/led/${currentState.image}`} />;
  }

  let stateLabel = '';
  if (currentState) {
    stateLabel = (ledStates.find(s => s.label === currentState.label) || { _label: currentState.label })._label;
  }


  const confirmName = label;
  const confirmValue = otherStateLabel;

  return (
    <>
      <ConfirmActionModal isOpen={confirmOpen} toggle={toggleConfirm} onConfirm={sendValue}>
        <Trans>Impostare il valore di <strong>{confirmName}</strong> a <strong>{confirmValue}</strong>?</Trans>
      </ConfirmActionModal>
      <Wrapper width={width} height={height}>
        <div id={tooltipId}>
          {icon}
        </div>
        <Info>
          <Name>{label}</Name>
          {<Label color={currentState && currentState.color}><Star>{isValueOld() && '* '}</Star>{showLabel && stateLabel}</Label>}
        </Info>
        <StyledTooltip placement="right" isOpen={tooltipOpen} target={tooltipId} toggle={toggleTooltip}>
          {tooltip}
        </StyledTooltip>
      </Wrapper>
    </>
  );
};

export default Led;
