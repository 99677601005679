import React from 'react';
import { AuthControl, Roles } from 'app/common';
import UserAuth from './UserAuth';

const ManageUsers = (props) => {
  return (
    <AuthControl systemRole={Roles.CSU}>
      <UserAuth {...props} />
    </AuthControl>
  );
};

export default ManageUsers;
