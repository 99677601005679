import React, { Component } from 'react';
import { OPSelector } from 'app/common';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import CreateOp from './CreateOp'

const StyledLabel = styled.label`
    && {
        margin: 1rem;    
    }
`;

const Wrapper = styled.div`
  max-height: 70vh;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #f00;
`;

class Selector extends Component {
    constructor(props) {
        super(props);

        const { checkedNode, newVariable, tabOption } = props;

        this.state = {
            checkedNodes: checkedNode ? [checkedNode] : [],
            error: '',
            selectedOption: tabOption,
            newOp: {
                readable: true
            },
            newVariable: newVariable
        };
    }

    getFilters = memoize((checkedNodes) => {
        const filters = {};

        if (checkedNodes.length > 0) {
            const { physicalQuantity } = checkedNodes[0];
            filters.physicalQuantity = physicalQuantity;
        }

        return filters;
    });

    updateNewOp = (value) => {
        const { newOp } = this.state;
        this.setState({ newOp: { ...newOp, ...value } });
    }

    updateCheckedNodes = (node, checked) => {
        const { setCheckeNode } = this.props;
        const { type, id, item } = node;
        const { valueType, physicalQuantity, name, entryRate, fieldDeviceId, virtualMeterId, timeZone, customQuantityId } = item;
        if(checked){
            this.setState({ checkedNodes: [{ type, id, valueType, physicalQuantity, name, entryRate, fieldDeviceId, virtualMeterId, timeZone, customQuantityId }] });
            setCheckeNode({ type, id, valueType, physicalQuantity, name, entryRate, fieldDeviceId, virtualMeterId, timeZone, customQuantityId });
        }else{
            this.setState({ checkedNodes: [] });
            setCheckeNode(null);
        }
    };

    validate = async () => {
        this.setState({ error: '' });
        const { tabOption } = this.props;
        const { checkedNodes } = this.state;

        try {
            if (tabOption === 'old_variable' && checkedNodes.length === 0) {
                throw new Error(i18n._(t`Selezionare una variabile`));
            } else if (tabOption === 'old_variable' || tabOption === 'new_variable') {
                return true;
            }
        } catch (e) {
            this.setState({ error: e.message });
            return false;
        }
    }

    getValues = async () => {
        const { physicalQuantities, tabOption } = this.props;
        const { checkedNodes, newOp } = this.state;

        if (tabOption === 'old_variable') {
            const variables = checkedNodes.map((node) => {
                const { type, id, valueType, physicalQuantity, name, entryRate, fieldDeviceId, virtualMeterId, timeZone, customQuantityId } = node;
                let { unit, label } = node;
                if (!unit) {
                    const pq = physicalQuantities.find(x => x.naturalKey === physicalQuantity);
                    unit = pq.defaultVisualizationUnit || pq.units[0];
                }
                if (!label) {
                    label = name;
                }
                return { type, id, valueType, physicalQuantity, unit, name, label, entryRate, fieldDeviceId, virtualMeterId, timeZone, customQuantityId };
            });

            return { variables };
        } else { // if selectedOption === 'new_variable'
            return { newOp }
        }
    }

    render() {
        const { setTabOption, tabOption } = this.props;
        const { checkedNodes, error } = this.state;

        const filters = this.getFilters(checkedNodes);

        return (
            <div>
                <Trans>Selezionare per quale tipo di variabile desideri caricare i dati</Trans>
                <br />
                <StyledLabel>
                    <Input
                        type={'radio'}
                        name={'old_variable'}
                        value={'old_variable'}
                        onChange={e => setTabOption(e.target.value)}
                        checked={tabOption === 'old_variable'}
                    /><Trans>Variabile esistente</Trans>
                </StyledLabel>
                <StyledLabel>
                    <Input
                        type={'radio'}
                        name={'new_variable'}
                        value={'new_variable'}
                        onChange={e => setTabOption(e.target.value)}
                        checked={tabOption === 'new_variable'}
                    /><Trans>Nuova variabile (crea ora)</Trans>
                </StyledLabel>
                {tabOption === 'old_variable' &&
                    <Wrapper>
                        <OPSelector
                            scroll
                            maxChecked={1}
                            isLoadingData={true}
                            onCheck={this.updateCheckedNodes}
                            checkedNodes={checkedNodes}
                            filters={filters}
                            physicalQuantity={filters.physicalQuantity}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Wrapper>
                }
                {tabOption === 'new_variable' &&
                    <CreateOp onUpdate={this.updateNewOp} newVariable={this.state.newVariable} />
                }
            </div>
        );
    }
}

Selector.propTypes = {
    physicalQuantities: PropTypes.array.isRequired,
};

export default Selector;
