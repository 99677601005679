import moment from 'moment';
import _ from 'lodash';

const retriveStartAndEndDates = (period) => {
  if (period) {
    const { startDate: sStartDate, endDate: sEndDate, startTime: sStartTime, endTime: sEndTime } = period;
    return { sStartDate, sEndDate, sStartTime, sEndTime };
  }
  return { sStartDate: null, sEndDate: null, sStartTime: null, sEndTime: null };
};

export const checkIsValidDate = (timeDefinition, fieldsDataValidation, setFieldsDataValidation, indexTimeSlot, indexPeriod, hFormat) => {
  const newFieldsDataValidation = { ...fieldsDataValidation };
  const fieldDateName = `dateRange_${indexTimeSlot}_${indexPeriod}`;
  const fieldTimeName = `timeRange_${indexTimeSlot}_${indexPeriod}`;
  const periodoRiferimento = timeDefinition.timeSlots[indexTimeSlot].periods[indexPeriod];

  const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(timeDefinition.timeSlots[indexTimeSlot].periods[indexPeriod]);

  // Reset field validations
  if (timeDefinition && timeDefinition.rate !== '1dy') {
    newFieldsDataValidation[fieldTimeName].isValidDate = true;
    newFieldsDataValidation[fieldTimeName].isValidDateFormat = true;
    newFieldsDataValidation[fieldTimeName].isValidRange = true;
  }
  newFieldsDataValidation[fieldDateName].isValidDate = true;
  newFieldsDataValidation[fieldDateName].isValidDateFormat = true;
  newFieldsDataValidation[fieldDateName].isValidRange = true;
  newFieldsDataValidation[fieldDateName].isValidPeriod = true;

  // Campo obbligatorio
  if (_.isEmpty(sStartDate) && _.isEmpty(sEndDate)) {
    return;
  }

  // Controllo formato campo date-range
  const regexDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/;
  if ((!_.isEmpty(sStartDate) && !regexDate.test(sStartDate))
      || (!_.isEmpty(sEndDate) && !regexDate.test(sEndDate))) {
    newFieldsDataValidation[fieldDateName].isValidDateFormat = false;
    setFieldsDataValidation(newFieldsDataValidation);
    return;
  }

  // Controllo formato campo time-range
  let regexTime = /^([01]?[0-9]|2[0-3])(:([0-5][0-9]))$/;
  if (hFormat === 24) {
    regexTime = /^([01]?[0-9]|2[0-4])(:([0-5][0-9]))$/;
  }

  if ((!_.isEmpty(sStartTime) && !regexTime.test(sStartTime))
      || (!_.isEmpty(sEndTime) && !regexTime.test(sEndTime))) {
    newFieldsDataValidation[fieldTimeName].isValidDateFormat = false;
    setFieldsDataValidation(newFieldsDataValidation);
    return;
  }

  let tmpStartHours = [null, null];
  if (sStartTime) {
    tmpStartHours = sStartTime.split(':');
    if (hFormat === 24 && tmpStartHours[0] === '24' && tmpStartHours[1] === '00') {
      tmpStartHours[0] = '23';
      tmpStartHours[1] = '59';
    }
  }

  let tmpEndHours = [null, null];
  if (sEndTime) {
    tmpEndHours = sEndTime.split(':');
    if (hFormat === 24 && tmpEndHours[0] === '24' && tmpEndHours[1] === '00') {
      tmpEndHours[0] = '23';
      tmpEndHours[1] = '59';
    }
  }

  const [startHours, startMinutes] = tmpStartHours;
  const [endHours, endMinutes] = tmpEndHours;
  let startDate = moment(sStartDate, 'DD/MM');
  if (startHours && startMinutes) {
    startDate = startDate.set({ hour: startHours, minute: startMinutes });
  }
  let endDate = moment(sEndDate, 'DD/MM');
  if (endHours && endMinutes) {
    endDate = endDate.set({ hour: endHours, minute: endMinutes });
  }

  // Controllo validità date
  if (!startDate._isValid || !endDate._isValid) {
    newFieldsDataValidation[fieldDateName].isValidDateFormat = false;
    setFieldsDataValidation(newFieldsDataValidation);
    return;
  }

  // Controllo startDate < endDate
  if (startDate.isAfter(endDate)) {
    newFieldsDataValidation[fieldDateName].isValidRange = false;
    setFieldsDataValidation(newFieldsDataValidation);
    return;
  }

  // Controllo startTime < endTime
  if (moment().set({ hour: startHours, minute: startMinutes }).isAfter(moment().set({ hour: endHours, minute: endMinutes }))) {
    newFieldsDataValidation[fieldTimeName].isValidRange = false;
    setFieldsDataValidation(newFieldsDataValidation);
    return;
  }

  /* prendo tutti i periodi diversi da quello in analisi per  */
  const timeslotDaAnalizzare = [];
  timeDefinition.timeSlots.forEach((ts, tsKey) => {
    if (ts.periods) {
      ts.periods.forEach((pe, peKey) => {
        if (!(tsKey === indexTimeSlot && peKey === indexPeriod)) { timeslotDaAnalizzare.push(pe); }
      });
    }
  });


  /* ricava l'esatto valore in minuti del timeslot di riferimento */
  const startRif = moment(`${sStartDate} ${sStartTime}`, 'DD/MM HH:mm');
  const endRif = moment(`${sEndDate} ${sEndTime}`, 'DD/MM HH:mm');
  const startHRif = moment(`${sStartTime}`, 'HH:mm');
  const endHRif = moment(`${sEndTime}`, 'HH:mm');
  let nessunaSovrapposizione = true;

  /* filtra i timeslot che ricadono, in almeno una delle giornate selezionate, all'interno del timeslot di interesse */
  timeslotDaAnalizzare.filter((f) => {
    if (periodoRiferimento.all || f.all) {
      return true; // se il periodo di riferimento o un periodo già inserito comprende tutti i giorni della settimana è necessario toranere il record per valutarlo
    }
    return ((periodoRiferimento.monday && f.monday)
      || (periodoRiferimento.tuesday && f.tuesday)
      || (periodoRiferimento.wednesday && f.wednesday)
      || (periodoRiferimento.thursday && f.thursday)
      || (periodoRiferimento.friday && f.friday)
      || (periodoRiferimento.saturday && f.saturday)
      || (periodoRiferimento.sunday && f.sunday)
      || (periodoRiferimento.s1 && f.s1)
      || (periodoRiferimento.s2 && f.s2)
      || (periodoRiferimento.holiday && f.holiday));
  }).forEach((pe) => {
    const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(pe);
    const startPe = moment(`${sStartDate} ${sStartTime}`, 'DD/MM HH:mm');
    const endPe = moment(`${sEndDate} ${sEndTime}`, 'DD/MM HH:mm');
    const startHPe = moment(`${sStartTime}`, 'HH:mm');
    const endHPe = moment(`${sEndTime}`, 'HH:mm');

    const checkOverlap = startHPe.isBetween(startHRif, endHRif, 'minutes', '()') || endHPe.isBetween(startHRif, endHRif, 'minutes', '()');
    const checkSame = startHPe.isSame(startHRif) && endHPe.isSame(endHRif);
    if ((checkOverlap || checkSame) && (startPe.isBetween(startRif, endRif, 'days', '[]') || endPe.isBetween(startRif, endRif, 'days', '[]'))) {
      console.warn('Trovata sovrapposizione', pe, periodoRiferimento);
      nessunaSovrapposizione = false;
    }
  });

  newFieldsDataValidation[fieldDateName].isValidPeriod = nessunaSovrapposizione;

  /*
  const timeSlot = timeDefinition.timeSlots[indexTimeSlot];
  const timeSlotsLength = timeDefinition.timeSlots ? timeDefinition.timeSlots.length - 1 : 0;
  const periodsLength = timeSlot.periods ? timeSlot.periods.length : 0;

  let previusEndDate = null;
  let nextStartDate = null;

  // Retrive previus startDate and endDate
  if (indexPeriod > 0) {
    const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(timeSlot.periods[indexPeriod - 1]);
    if ((sStartDate && !regexDate.test(sStartDate)) || (sEndDate && !regexDate.test(sEndDate))) {
      setFieldsDataValidation(newFieldsDataValidation);
      return;
    }
    if ((sStartTime && !regexTime.test(sStartTime)) || (sEndTime && !regexTime.test(sEndTime))) {
      // Tengo in considerazione soltanto le date
    } else if (sEndTime) {
      const { endMinutes, endHours } = sEndTime.split(':');
      previusEndDate = moment(sEndDate, 'DD/MM').set({ hour: endMinutes, minute: endHours });
    }
  }
  if (indexPeriod === 0 && indexTimeSlot > 0) {
    const previusTimeSlot = timeDefinition.timeSlots[indexTimeSlot - 1];
    if (previusTimeSlot.periods) {
      const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(previusTimeSlot.periods[previusTimeSlot.periods.length - 1]);
      if ((sStartDate && !regexDate.test(sStartDate)) || (sEndDate && !regexDate.test(sEndDate))) {
        setFieldsDataValidation(newFieldsDataValidation);
        return;
      }
      if ((sStartTime && !regexTime.test(sStartTime)) || (sEndTime && !regexTime.test(sEndTime))) {
        // Tengo in considerazione soltanto le date
      } else if (sEndTime) {
        const { endMinutes, endHours } = sEndTime.split(':');
        previusEndDate = moment(sEndDate, 'DD/MM').set({ hour: endMinutes, minute: endHours });
      }
    }
  }

  // Retrive next startDate and endDate
  if ((indexPeriod + 1) !== periodsLength) {
    const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(timeSlot.periods[indexPeriod + 1]);
    if ((sStartDate && !regexDate.test(sStartDate)) || (sEndDate && !regexDate.test(sEndDate))) {
      setFieldsDataValidation(newFieldsDataValidation);
      return;
    }
    if ((sStartTime && !regexTime.test(sStartTime)) || (sEndTime && !regexTime.test(sEndTime))) {
      // Tengo in considerazione soltanto le date
    } else if (sStartTime) {
      const { startMinutes, startHours } = sStartTime.split(':');
      nextStartDate = moment(sStartDate, 'DD/MM').set({ hour: startMinutes, minute: startHours });
    }
  }

  if ((indexPeriod + 1) === periodsLength && (indexTimeSlot + 1) !== timeSlotsLength) {
    const nextTimeSlot = timeDefinition.timeSlots[indexTimeSlot + 1];
    if (nextTimeSlot.periods) {
      const { sStartDate, sEndDate, sStartTime, sEndTime } = retriveStartAndEndDates(nextTimeSlot.periods[0].startDate);
      if ((sStartDate && !regexDate.test(sStartDate)) || (sEndDate && !regexDate.test(sEndDate))) {
        setFieldsDataValidation(newFieldsDataValidation);
        return;
      }
      if ((sStartTime && !regexTime.test(sStartTime)) || (sEndTime && !regexTime.test(sEndTime))) {
        // Tengo in considerazione soltanto le date
      } else if (sStartTime) {
        const { startMinutes, startHours } = sStartTime.split(':');
        nextStartDate = moment(sStartDate, 'DD/MM').set({ hour: startMinutes, minute: startHours });
      }
    }
  }

  // Controllo che la data di start sia successiva alla data di end del periodo precedente
  // Controllo che la data di end sia precedente alla data di start del periodo successivo
  if ((!previusEndDate || (previusEndDate && startDate.isAfter(previusEndDate)))
      && (!nextStartDate || (nextStartDate && endDate.isBefore(nextStartDate)))) {
    newFieldsDataValidation[fieldDateName].isValidPeriod = true;
  } else {
    newFieldsDataValidation[fieldDateName].isValidPeriod = false;
  }
  */

  setFieldsDataValidation(newFieldsDataValidation);
};

export const checkIsValidDates = (timeDefinition, fieldsDataValidation, setFieldsDataValidation, hFormat = 23) => {
  const newTimeDefinition = { ...timeDefinition };
  newTimeDefinition.timeSlots.forEach((ts, indexTS) => {
    if (ts.periods) {
      ts.periods.forEach((p, indexP) => {
        checkIsValidDate(timeDefinition, fieldsDataValidation, setFieldsDataValidation, indexTS, indexP, hFormat);
      });
    }
  });
};
