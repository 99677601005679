import axios from 'axios';
import keycloak from '../keycloak';
import logger from 'app/common/log';
/*
// custom params serializer provided to fix axios bug #2563 (spaces within strings converted as '+')
axios.defaults.paramsSerializer = function(params) {
  return qs.stringify(params, { indices: false }); // param=value1&param=value2
}
*/
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api'
});
const CancelToken = axios.CancelToken;

api.interceptors.request.use(config => {
  logger.debug('interceptors url: %o',config.url);
  const parts = config &&  config.url && config.url.split('?')[0].split('/');
  if (config.url === '' || parts.includes('undefined') ) {
    logger.error('bad url %o %o',config.url,parts);
    return {
      ...config,
      cancelToken: new CancelToken((cancel) => cancel('Cancel bad request'))
    };
  }
  return keycloak
    .updateToken(5)
    .then(refreshed => {
      if (refreshed) {
        keycloak.saveTokensInLocalStorage();
      }
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(config);
    })
    .catch(e => {
      console.log(e);
      keycloak.login();
    });
});

// api.interceptors.response.use(null, (error) => {
//   console.log(error, error.response);
//   return Promise.reject(error);
// });

const modifyPayload = (payload) => {
  if(payload instanceof FormData || !payload){
    return payload;
  }
  const {updater, siteGroups, sites, fieldDevices, gateway, site, _site, _template, ...newPayload} = payload;
  return newPayload;
};

//New post with modified payload
const originalPost = api.post;
api.post = (url, data, config) => {
  const modifiedData = modifyPayload(data);
  return originalPost(url, modifiedData, config);
};

// New patch with modified payload
const originalPatch = api.patch;
api.patch = (url, data, config) => {
  const modifiedData = modifyPayload(data);
  return originalPatch(url, modifiedData, config);
};

export default api;
