import React, { Component } from 'react';
import moment from 'moment';
import c3 from 'c3';
import 'c3/c3.css';
import { isEqual, debounce } from 'lodash';
import { getColumns, getTicks } from './dates';
import logger from 'app/common/log';
import { numberToLocaleString } from 'app/utils/formatNumber';

const debounceTime = 200;

class TimeProfileChart extends Component {
  componentDidMount() {
    this.debouncedCreateChart();
  }

  componentDidUpdate(prevProps) {
    const { widget, data, width, height, shouldUpdate } = this.props;
    //console.log('TimeProfileChart componentDidUpdate ',widget)
    if (
      width !== prevProps.width ||
      height !== prevProps.height ||
      !isEqual(widget, prevProps.widget) ||
      this.shouldRedraw(data, prevProps.data)
    ) {
      this.debouncedCreateChart();
    } else if (
      this.shouldAddItem(data, prevProps.data) ||
      shouldUpdate !== prevProps.shouldUpdate
    ) {
      // todo: sostituire con debouncedAddDataToChart e rimuovere controllo su shouldUpdate (forse)
      this.debouncedCreateChart();
    }
  }

  shouldRedraw = (data, prevData) => {
    if (data.length !== prevData.length) {
      return true;
    }
    if (!isEqual(data.map(x => x.id).sort(), prevData.map(x => x.id).sort())) {
      return true;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const i in data) {
      if (
        !isEqual({ ...data[i], items: undefined }, { ...prevData[i], items: undefined }) ||
        Math.abs(data[i].items.length - prevData[i].items.length) > 1
      ) {
        return true;
      }
    }
    return false;
  };

  shouldAddItem = (data, prevData) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const i in data) {
      if (
        data[i].items.length > 0 &&
        (data[i].items.length !== prevData[i].items.length ||
          !isEqual(data[i].items, prevData[i].items))
      ) {
        return true;
      }
    }
    return false;
  };

  createChart = () => {
    this.created = false;
    const { data, widget, height, width } = this.props;
    if (data.length === 0) {
      return;
    }
    const { names, columns, colors, types, axes, groups, axis, numberOfValues } = getColumns(
      data,
      widget.period
    );
    this.lastDate = columns[0][columns[0].length - 1];
    // console.log('create')
    // console.log(data, columns)
    const maxTicks = width > 700 ? 15 : 8;
    const tick = getTicks(columns[0], maxTicks);
    window.d3 = undefined;
    let ratioValue =
      (tick && tick.values && (0.8 * tick.values.length) / numberOfValues) <= 0.9
        ? (0.8 * tick.values.length) / numberOfValues
        : undefined;

    if (tick) {
      logger.debug(
        '%s ratioValue %s, tick.values.length %s, numberOfValues %s, data %j columns %j, groups %j',
        widget.key,
        ratioValue,
        tick.values.length,
        numberOfValues,
        data,
        columns
      );
    } else {
      logger.debug('%s tick is null, ratioValue %s ', ratioValue, widget.key);
    }
    const chartGenerateData = {
      bindto: `#chart-${widget.key}`,
      size: {
        height,
        width: width - 10
      },
      data: {
        x: 'x',
        columns,
        colors,
        types,
        names,
        axes,
        groups,
        order: null
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: tick.values && tick.values.length > 0 ? tick : undefined
        },
        ...axis
      },
      point: {
        show: true,
        r: 1.1
      },
      spline: {
        interpolation: {
          type: 'monotone'
        }
      },
      bar: {
        width: {
          ratio: tick && tick.values && ratioValue
        }
      },
      grid: {
        x: {
          show: widget.grid === 'x' || widget.grid === 'both'
        },
        y: {
          show: widget.grid === 'y' || widget.grid === 'both'
        }
      },
      tooltip: {
        show: true,
        format: {
          title: x => moment(x).format('dddd DD/MM/YYYY HH:mm:ss'),
          value: (value, ratio, id, index) => {
            const elem = data.find(x => x.id === id) || {};
            return `${numberToLocaleString(value, elem.decimalDigits)} ${elem._unit}`;
          }
        }
      },
      transition: {
        duration: null
      },
      legend: {
        show: widget.legend !== 'none',
        position: widget.legend
      }
    };

    try {
      this.chart = c3.generate(chartGenerateData);
      this.created = true;
    } catch (err) {
      console.log('TimeProfileChart generate error', err);
      this.created = false;
    }
  };

  getNewData = columns => {
    const dates = columns[0];
    let lastDateIndex = dates.findIndex(x => x - this.lastDate === 0);
    if (lastDateIndex <= 0) {
      lastDateIndex = 0;
    }
    this.lastDate = dates[dates.length - 1];
    const newColumns = columns.map(x => [x[0]].concat(x.slice(lastDateIndex + 1)));
    // console.table(columns);
    // console.table(newColumns);

    return newColumns;
  };

  addDataToChart = () => {
    if (!this.created) {
      return;
    }
    const { data, widget, width } = this.props;
    const { columns } = getColumns(data, widget.period);
    // console.table(columns);

    const newColumns = this.getNewData(columns);
    if (newColumns[0].length <= 1) {
      return;
    }
    console.table('time profile addDataToChart', newColumns);

    const maxTicks = width > 700 ? 15 : 8;
    const tick = getTicks(columns[0], maxTicks);
    this.chart.internal.config.axis_x_tick_values = tick.values;

    this.chart.flow({
      columns: newColumns,
      to: columns[0][1]
    });
  };

  debouncedCreateChart = debounce(this.createChart, debounceTime);
  debouncedAddDataToChart = debounce(this.addDataToChart, debounceTime);

  render() {
    const { widget } = this.props;
    return <div id={`chart-${widget.key}`} />;
  }
}

export default TimeProfileChart;
