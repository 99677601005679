import React from 'react';
import styled from 'styled-components';
import Display from './Display';
import Led from './Led';
import SliderWidget from './SliderWidget';
import { widgetTypes } from '../common';

const Wrapper = styled.div`
  font-size: ${props => `${props.fontSize / 16}px`};
  display: flex;
  flex-direction: ${props => props.direction};
  height: ${props => `${props.height}px`};
`;

const MultiWidget = props => {
  const { widget, data, width, height, shouldUpdate, canSendCommand, toggleFullscreen, isFullscreen } = props;
  const direction = width > 2.5 * height ? 'row' : 'column';
  const fontsize = width > height ? height * 2 : width;
  const singleWidth = direction === 'row' ? width / data.length : width;
  const singleHeight = direction === 'column' ? height / data.length : height;

  return (
    <Wrapper direction={direction} fontSize={fontsize} height={height}>
      {data.map(variable => {
        switch (widget.widgetType) {
          case widgetTypes.DISPLAY:
            return (
              <Display
                direction={direction}
                dataLength={data.length}
                data={variable}
                height={height}
                width={width}
                shouldUpdate={shouldUpdate}
                period={widget.period}
              />
            );
          case widgetTypes.LED:
          case widgetTypes.SWITCH:
            return (
              <Led
                widget={widget}
                data={variable}
                height={singleHeight}
                width={singleWidth}
                shouldUpdate={shouldUpdate}
                canSendCommand={canSendCommand}
                toggleFullscreen={() => toggleFullscreen()}
                isFullscreen={isFullscreen}
              />
            );
          case widgetTypes.SLIDER:
            return (
              <SliderWidget
                widget={widget}
                data={variable}
                height={singleHeight}
                width={singleWidth}
                canSendCommand={canSendCommand}
                toggleFullscreen={() => toggleFullscreen()}
                isFullscreen={isFullscreen}
              />
            );
          default:
            return null;
        }
      })}
    </Wrapper>
  );
};

export default MultiWidget;
