import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, IconButton } from 'app/common';
import { Card, CardHeader, CardBody, ButtonDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import screenfull from 'screenfull';
import ifvisible from 'ifvisible';
import { Quill } from 'react-quill';
import api from 'api';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { Roles } from 'app/utils/auth';
import { getUrlFromPath } from 'app/utils/navigation';
import Grid from './Grid';
import { OPWidgetForm, ImageWidgetForm, TextWidgetForm } from './WidgetForm';
import { getNewKey, widgetTypes, initialSizes, getUrlFromDashboard } from './common';
import {
  StyledModal,
  Toolbar,
  StyledDropdownToggle,
  DashboardNameInput,
  Title,
  Header,
  EditLink,
  GridContainer,
  ErrorMessage,
  Item,
  DashboardDropdownToggle,
  CaretIcon,
  BackLink,
  StretchLink,
  StyledSelect
} from 'app/common/Widgets/WidgetStyledComponents';
import { getWidgetTypesOptions } from 'app/common/Widgets/WidgetUtils';
import { addFolder } from 'ducks/actions/folders';
import logger from 'app/common/log';
import { toast } from 'react-toastify';

const CardStyle = styled(Card)`
  border-radius: ${props => props.dashboardVwrList && '0 !important'};
`;

const StyledItem = styled(DropdownItem)`
  padding-left: 1rem !important;
`;

const StyledMenu = styled(DropdownMenu)`
  max-height: 50vh !important;
  overflow-y: auto !important;
`;

const UnUpdatedValue = styled.div`
  font-size: 0.8rem;
`;

const widgetTypesOptions = getWidgetTypesOptions();

const getDashboardVisibilityOptions = () => [
  { value: true, label: i18n._(t`Dashboard privata`) },
  { value: false, label: i18n._(t`Dashboard condivisa`) }
];

const DashboardSelector = props => {
  const { dashboards, selectedDashboard, url, dashboardVwrList } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  let dashboardSites = [];
  if (dashboardVwrList) {
    const sitesNames = dashboards.map(d => (d.site ? d.site.name : d.company && d.company.name));
    dashboardSites = sitesNames
      .filter((n, i) => sitesNames.indexOf(n) === i)
      .map(el => {
        return { name: el, type: 'site' };
      });
  }

  const getDashboardVwrList = () => {
    (dashboards || []).reverse().forEach(dashboard => {
      const index = dashboardSites.findIndex(ds =>
        dashboard.site ? dashboard.site.name === ds.name : dashboard.company.name === ds.name
      );
      dashboardSites.splice(index + 1, 0, {
        name: dashboard.name,
        type: 'dashboard',
        dashboard: dashboard
      });
    });
    const items = dashboardSites.map(d => {
      return d.type === 'site' ? (
        <StyledItem>{d.name}</StyledItem>
      ) : (
        <DropdownItem>
          <StretchLink to={`/${getUrlFromDashboard(d.dashboard)}`}>
            <span>{d.name}</span>
          </StretchLink>
        </DropdownItem>
      );
    });
    return items;
  };

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DashboardDropdownToggle>
        <span>{selectedDashboard.name}</span>
        <CaretIcon icon="angle-down" isOpen={dropdownOpen} />
      </DashboardDropdownToggle>
      <StyledMenu>
        {!dashboardVwrList && (
          <DropdownItem>
            <BackLink to={`${url}`}>
              <FontAwesomeIcon icon="angle-left" />
              <Trans>Elenco dashboard</Trans>
            </BackLink>
          </DropdownItem>
        )}
        {!dashboardVwrList
          ? (dashboards || []).map(d => (
              <DropdownItem>
                <StretchLink to={`${url}/${d.id}`}>
                  <span>{d.name}</span>
                </StretchLink>
              </DropdownItem>
            ))
          : getDashboardVwrList()}
      </StyledMenu>
    </ButtonDropdown>
  );
};

const QuillLink = Quill.import('formats/link');

class MyLink extends QuillLink {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', this.sanitize(value));
    node.removeAttribute('target');
    return node;
  }
}

const Dashboard = props => {
  const {
    isNew,
    edit,
    readOnly,
    match,
    history,
    type,
    id,
    subtype,
    subid,
    onSubmit,
    userCurrentRoles,
    dashboards,
    url,
    dashboardVwrList,
    logout,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedAsset,
    selectedSitegroup,
    folders,
    addFolder,
    observableproperties,
    isDomainLoaded,
    virtualmeters,
    physicalQuantities
  } = props;
  const { dashboardId } = match.params;

  const [CardCustom, setCardCustom] = useState(CardStyle);
  const [dashboard, setDashboard] = useState({ configuration: {} });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [widgetModal, setWidgetModal] = useState({ isOpen: false, widget: undefined });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [shouldUpdateWidgets, setShouldUpdateWidgets] = useState(true);
  const [error, setError] = useState();
  const [canChangeVisibility, setCanChangeVisibility] = useState(false);
  const [showUnUpdatedValue, setShowUnUpdatedValue] = useState(false);
  const [ opNotFound, setOpNotFound ] = useState([]);
  const [ deletedOp, setDeletedOp ] = useState([]);
  const [ loadedDashboard, setLoadedDashboard ] = useState(null);

  const cardRef = useRef(null);
  const saveRef = useRef(null);

  const [hasScrollbar, setHasScrollbar] = useState(false);

  const checkForScrollbars = () => {
    const hasVerticalScrollbar = document.body.scrollHeight > window.innerHeight;
    setHasScrollbar(hasVerticalScrollbar);
  };

  // Check if there's scrollbar
  useEffect(() => {
    // Check for window dimension change
    const resizeObserver = new ResizeObserver(() => {
      checkForScrollbars();
    });
    resizeObserver.observe(document.body);

    // Check for DOM changes
    const mutationObserver = new MutationObserver(() => {
      checkForScrollbars();
    });
    mutationObserver.observe(document.body, { childList: true, subtree: true, attributes: true });

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  const loadDashboard = async () => {
    const ret = await api.get(`/dashboards/${dashboardId}`);
    setLoadedDashboard(ret.data);
    logger.debug('Dashboards ',ret.data);
    setCanChangeVisibility(ret.data.personalInstance !== false);
  };

  useEffect(() => {
    if(isDomainLoaded && loadedDashboard){
      let deletedVariables = false;

    const updatedDashboard = {...loadedDashboard, configuration: {
      ...loadedDashboard.configuration, widgets: loadedDashboard.configuration.widgets.map(widget =>{ 
        if(!widget.variables){
          return widget;
        }
        return {
        ...widget, variables: widget.variables.map(variable => {
          const updatedOp = observableproperties.find(op => op.id === variable.id);
          if(updatedOp){
            const vm = virtualmeters.find(vm => vm.id === updatedOp.virtualMeterId);
            const rate = vm && vm.rate;
            const pq = physicalQuantities.find(x => x.naturalKey === updatedOp.physicalQuantity);
            const unit = pq.defaultVisualizationUnit || pq.units[0];

            const newVariable = {
              ...variable,
              valueType: updatedOp.valueType,
              physicalQuantity: updatedOp.physicalQuantity,
              customQuantityId: updatedOp.customQuantityId,
              name: updatedOp.name
            };

            return newVariable;
          }else{
            deletedVariables = true;
            return variable;
          }
        })
      }})
    }}

    if(deletedVariables && !edit){
      toast.warn(i18n._(t`Alcuni widget fanno riferimento a variabili eliminate o non trovate, per le quali la piattaforma non riceve più dati.`));
    }
    setDashboard(updatedDashboard);
    }
  }, [loadedDashboard, isDomainLoaded]);


  useEffect(() => {
    if(dashboard.configuration.widgets && isDomainLoaded && edit){
      handleRemovedOp();
    }
  }, [dashboard.configuration.widgets, isDomainLoaded])

  const handleRemovedOp = async () => {
    const opToCheck = [];
    //find all the op that are not loaded
    dashboard.configuration.widgets.map(widget => {
      if(!widget.variables){
        return;
      }
      widget.variables.map(variable => {
        if(observableproperties.find(op => op.id === variable.id)){
          return;
        }
        if(!opToCheck.includes(variable.id)){
          opToCheck.push(variable.id);
        }
      })
    });

    let apiCall = '';
    if (selectedAsset) {
      apiCall = `/Sites/${selectedSite.id}/orphanedObservedProperties`;
    } else if (selectedSite) {
      apiCall = `/Sites/${selectedSite.id}/orphanedObservedProperties`;
    } else if (selectedSitegroup) {
      apiCall = `/Companies/${selectedCompany.id}/orphanedObservedProperties`;
    } else if (selectedCompany) {
      apiCall = `/Companies/${selectedCompany.id}/orphanedObservedProperties`;
    } else if (selectedDomain) {
      apiCall = `/Domains/${selectedDomain.id}/orphanedObservedProperties`;
    }
    
    const res = await api.get(apiCall + `?filter={"where":{"id":{"inq":${JSON.stringify(opToCheck)}}}}`);

    const deletedOp = [];
    res.data.forEach(x => {
      if(opToCheck.includes(x.id)){
        deletedOp.push(x.id);
      }
    })

    const opNotFound = opToCheck.filter(op => !deletedOp.includes(op));

    setDeletedOp(deletedOp);
    setOpNotFound(opNotFound);
    if(deletedOp.length > 0){
      toast.warn(i18n._(t`Alcuni widget fanno riferimento a variabili eliminate, per le quali la piattaforma non riceve più dati.`));
    }
    if(opNotFound.length > 0){
      toast.error(i18n._(t`Alcuni widget fanno riferimento a variabili non più esistenti, rimuovere tali riferimenti per poter salvare altre modifiche.`));
    }
  }

  useEffect(() => {
    if (dashboardVwrList) {
      const card = styled(Card)`
        ${props =>
          dashboardVwrList &&
          `
        width: calc(100% + 3rem);
        left: -30px;
        border-radius: 0 !important;
        min-height: 100vh;`}
      `;
      setCardCustom(card);
      let list = [];
      dashboardVwrList.forEach(dv => {
        list.push(...dv.dashboardList);
      });
    }
  }, [dashboardVwrList]);

  useEffect(() => {
    console.log('Dashboard Quill registered ');
    Quill.register(MyLink);

    if (screenfull.enabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }

    ifvisible.on('blur', () => setShouldUpdateWidgets(false));
    ifvisible.on('focus', () => setShouldUpdateWidgets(true));
  }, []);

  useEffect(() => {
    if (isNew) {
      setDashboard({
        name: i18n._(t`Nuova dashboard`),
        configuration: { widgets: [] },
        personalInstance: true
      });
      setCanChangeVisibility(true);
    } else {
      loadDashboard();
    }
  }, [dashboardId, edit]);

  const getDefaultWidgetData = widgetType => {
    const { value, label } = widgetType;
    const widget = { title: i18n._(label), titlePeriod: null, widgetType: value };
    if (value === widgetTypes.SERIES) {
      widget.legend = 'bottom';
      widget.grid = 'none';
    }
    if (value === widgetTypes.PIE) {
      widget.type = 'donut';
      widget.legend = 'bottom';
      widget.format = 'value';
      widget.showTotal = false;
      widget.title = i18n._(t`Grafico di confronto`);
    }
    if (value === widgetTypes.IMAGE) {
      widget.fit = 'scale-down';
    }
    if (value === widgetTypes.TEXT) {
      widget.content = '';
    }
    if (value === widgetTypes.LED || value === widgetTypes.SWITCH) {
      widget.properties = { iconSize: 1, showLabel: true, askConfirm: true };
    }
    if (value === widgetTypes.SLIDER) {
      widget.properties = { askConfirm: true };
    }
    return widget;
  };

  const dashboardVisibilityOptions = getDashboardVisibilityOptions();

  const openNewWidgetWindow = widgetType => {
    setWidgetModal({
      isOpen: true,
      widgetType: widgetType.value,
      widget: getDefaultWidgetData(widgetType)
    });
  };

  const openEditWidgetWindow = widget => {
    setWidgetModal({ isOpen: true, widgetType: widget.widgetType, widget });
  };

  const widgetModalToggle = () => {
    setWidgetModal({ isOpen: !widgetModal.isOpen, widget: undefined });
  };

  const updateLayout = layout => {
    setDashboard(dashboard => {
      layout.forEach(l => {
        const w = dashboard.configuration.widgets.find(x => x.key === l.i);
        w.layout = l;
      });
      return dashboard;
    });
  };

  const changeName = e => {
    setDashboard({ ...dashboard, name: e.target.value });
  };

  const saveWidget = widget => {
    if (!widget.key) {
      const key = getNewKey();
      widget.key = key;
      const { w, h } = initialSizes[widget.widgetType];
      widget.layout = { x: 0, y: 0, w, h };
      setDashboard(dashboard => ({
        ...dashboard,
        configuration: {
          ...dashboard.configuration,
          widgets: [...dashboard.configuration.widgets, widget]
        }
      }));
    } else {
      const widgetsNew = [...dashboard.configuration.widgets];
      const index = widgetsNew.findIndex(x => x.key === widget.key);
      widgetsNew[index] = widget;
      console.log('setDashboard widget ',widget)
      setDashboard(dashboard => ({
        ...dashboard,
        configuration: { ...dashboard.configuration, widgets: widgetsNew }
      }));
    }
  };

  const deleteWidget = widget => {
    setDashboard(dashboard => ({
      ...dashboard,
      configuration: {
        ...dashboard.configuration,
        widgets: dashboard.configuration.widgets.filter(x => x.key !== widget.key)
      }
    }));
  };

  const saveDashboard = async () => {
    if(saveRef.current && !saveRef.current.disabled){
      saveRef.current.disabled = true;
      try {
        setError(null);
        let savedDashboard;
        if (isNew) {
          if (subtype === 'assets') {
            dashboard.assetId = subid;
          } else if (subtype === 'assetgroups') {
            dashboard.assetGroupId = subid;
          }
          const ret = await api.post(`${type}/${id}/dashboards`, dashboard);
          savedDashboard = ret.data;
        } else {
          const ret = await api.patch(`/dashboards/${dashboardId}`, dashboard);
          savedDashboard = ret.data;
        }

        setDashboard(savedDashboard);
        if (onSubmit) {
          onSubmit().catch(err => {
            console.log(err);
            saveRef.current && (saveRef.current.disabled = false);
          });
        }
        history.push(getUrlFromPath(`/dashboards/${savedDashboard.id}`));
      } catch (err) {
        saveRef.current && (saveRef.current.disabled = false);
        setError(err.message);
      }
    }
  };

  const cancel = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const filter = queryParameters.get("fromFilter");
    if(dashboardId){
      history.push(getUrlFromPath('/dashboards' + (filter ? ("?filter=" + filter) : "") + '#'+ dashboardId) );
    }else{
      history.push(getUrlFromPath('/dashboards') + (filter ? ("?filter=" + filter) : ""));
    }
  };

  const toggleFullscreen = () => {
    if (screenfull.enabled) {
      screenfull.toggle(cardRef.current);
    }
  };

  const changeDashboardVisibility = e => {
    setDashboard({ ...dashboard, personalInstance: e.target.value });
  };

  const renderWidgetModal = () => {
    let widgetForm;
    if (widgetModal.widget) {
      if (widgetModal.widgetType === widgetTypes.IMAGE) {
        widgetForm = (
          <ImageWidgetForm
            widget={widgetModal.widget}
            onClose={widgetModalToggle}
            onSave={saveWidget}
            selectedDomain={selectedDomain}
            selectedCompany={selectedCompany}
            selectedSite={selectedSite}
            folders={folders}
            addFolder={addFolder}
          />
        );
      } else if (widgetModal.widgetType === widgetTypes.TEXT) {
        widgetForm = (
          <TextWidgetForm
            widget={widgetModal.widget}
            onClose={widgetModalToggle}
            onSave={saveWidget}
          />
        );
      } else {
        console.log('variable Dashboard:renderWidgetModal:OPWidgetForm ',widgetModal.widget)
        widgetForm = (
          <OPWidgetForm
            currentModule={'dashboard'}
            widget={widgetModal.widget}
            onClose={widgetModalToggle}
            onSave={saveWidget}
          />
        );
      }
    }

    return (
      <StyledModal isOpen={widgetModal.isOpen} toggleModal={widgetModalToggle}>
        {widgetForm}
      </StyledModal>
    );
  };

  const userCanEditDashboard = userCurrentRoles.includes(Roles.OPE);
  const userCanSendCommand = userCurrentRoles.includes(Roles.OPE_CMD);

  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("fromFilter");

  return (
    <>
      {renderWidgetModal()}
      <CardCustom ref={cardRef}>
        <CardHeader>
          <Header>
            <Title>
              {readOnly ? (
                <DashboardSelector
                  dashboards={dashboards}
                  selectedDashboard={dashboard}
                  url={url}
                  dashboardVwrList={dashboardVwrList}
                />
              ) : (
                <DashboardNameInput value={dashboard.name} onChange={changeName} />
              )}
              {readOnly && userCanEditDashboard && (
                <>
                  <EditLink to={getUrlFromPath(`/dashboards/${dashboardId}/edit`) + (filter ? ("?fromFilter=" + filter) : "")}>
                    <IconButton icon="pencil-alt" />
                  </EditLink>
                  {hasScrollbar ? 
                    <Button color="link" onClick={cancel}>
                      {<Trans>Torna all'elenco</Trans>}
                    </Button>
                    : ""}
                </>
              )}
            </Title>
            {!dashboardVwrList ? (
              !edit && <IconButton icon={isFullscreen ? 'compress' : 'expand'} onClick={toggleFullscreen} />
            ) : (
              <Button
                color="link"
                className="btn btn-link"
                onClick={() => {
                  logout();
                }}
              >
                <Trans>Logout</Trans>
              </Button>
            )}
          </Header>
        </CardHeader>
        <CardBody>
          {!readOnly && (
            <Toolbar>
              <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                <StyledDropdownToggle caret>
                  <Trans>Aggiungi widget</Trans>
                </StyledDropdownToggle>
                <StyledSelect
                  value={dashboard.personalInstance}
                  options={dashboardVisibilityOptions}
                  onChange={changeDashboardVisibility}
                  disabled={!canChangeVisibility}
                />
                <DropdownMenu>
                  {widgetTypesOptions.map(widgetType => (
                    <DropdownItem
                      key={widgetType.value}
                      onClick={() => openNewWidgetWindow(widgetType)}
                    >
                      <Item>
                        <img src={widgetType.icon} alt={widgetType.label} />
                        <span>{i18n._(widgetType.label)}</span>
                      </Item>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button color="primary" onClick={saveDashboard} ref={saveRef}>
                <Trans>Salva</Trans>
              </Button>
              <Button color="link" onClick={cancel}>
                <Trans>Annulla</Trans>
              </Button>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Toolbar>
          )}
          <GridContainer ref={cardRef} isFullscreen={isFullscreen}>
            {isFullscreen && (
              <Header>
                <DashboardSelector
                  dashboards={dashboards}
                  selectedDashboard={dashboard}
                  url={url}
                  dashboardVwrList={dashboardVwrList}
                />
                <IconButton
                  icon={isFullscreen ? 'compress' : 'expand'}
                  onClick={toggleFullscreen}
                />
              </Header>
            )}
            <Grid
              widgets={dashboard.configuration.widgets}
              edit={!readOnly}
              onEdit={openEditWidgetWindow}
              onDelete={deleteWidget}
              onLayoutChange={updateLayout}
              shouldUpdateWidgets={shouldUpdateWidgets}
              userCanSendCommand={userCanSendCommand}
              setUnupdateValue={value => setShowUnUpdatedValue(value)}
              saveWidget={saveWidget}
              isFullscreen={isFullscreen}
              toggleFullscreen={() => toggleFullscreen()}
            />
            {showUnUpdatedValue && (
              <UnUpdatedValue>
                <Trans>* Valore non aggiornato</Trans>
              </UnUpdatedValue>
            )}
          </GridContainer>
          {!edit && <Button color="link" onClick={cancel}>
            {<Trans>Torna all'elenco</Trans>}
          </Button>}
        </CardBody>
      </CardCustom>
    </>
  );
};

const mapStateToProps = state => {
  const { navigation, preferences, domain, catalogs } = state;
  const {
    path,
    type,
    id,
    subtype,
    subid,
    sites,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedAsset,
    selectedSitegroup,
    isDomainLoaded
  } = navigation;
  const { folders, observableproperties, virtualmeters } = domain;
  const { userCurrentRoles, keycloak } = state.auth;
  const { dashboardVwrList } = preferences;
  const { logout } = keycloak;
  const { physicalQuantities } = catalogs;

  return {
    preferences,
    path,
    type,
    id,
    subtype,
    subid,
    userCurrentRoles,
    dashboardVwrList,
    logout,
    sites,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedAsset,
    selectedSitegroup,
    folders,
    observableproperties,
    isDomainLoaded,
    virtualmeters,
    physicalQuantities
  };
};

const mapDispatchToProps = dispatch => ({
  addFolder: folder => dispatch(addFolder(folder))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
