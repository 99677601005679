import api from 'api';

export async function getOpById(id, includeList = []) {
  console.log(`getOpById ${id}`);
  return api.get(`/observedProperties/${id}`, {
    params: {
      filter: { include: includeList }
    }
  });
}

export async function getOpByIdWithAlerts(id) {
  let data = {};
  let error;
  try {
    const queriedData = await getOpById(id, ['dataFlowAlerts', 'dataFlowFilters']);
    data = (queriedData && queriedData.data) || {};
  } catch (ex) {
    console.error(`getOpByIdWithAlerts ${id}`, ex);
  }
  return { data, error };
}

export async function getOpByNodeInfo(
  nodeInfo,
  { domain = true, company = true, site = true, sitegroup = true }
) {
  const { domainId, siteId, companyId, siteGroupId } = nodeInfo;
  let apiCall;
  let result = [];
  try {
    if (siteId && site) {
      apiCall = `/Sites/${siteId}/observedProperties`;
    } else if (siteGroupId && sitegroup) {
      apiCall = `/SiteGroups/${siteGroupId}/observedProperties`;
    } else if (companyId && company) {
      apiCall = `/Companies/${companyId}/observedProperties`;
    } else if (domainId && domain) {
      apiCall = `/Domains/${domainId}/observedProperties`;
    } else {
      result.err = `getOpByNodeInfo - nor domainId, nor siteId, nor companyId, nor siteGroupId provided`;
      console.error(result.err);
      return result;
    }
    result = apiCall ? await api.get(apiCall) : [];
  } catch (ex) {
    console.error(`getOpByNodeInfo ${apiCall}`, ex);
    result.err = ex;
  }
  return result;
}

export const getCurrentUserDashboardsByNodeInfo = async nodeInfo => {
  const { domainId, siteId, companyId, siteGroupId } = nodeInfo;
  let apiCall;
  let result = {};
  try {
    if (siteId) {
      apiCall = `/Sites/${siteId}`;
    } else if (siteGroupId) {
      apiCall = `/SiteGroups/${siteGroupId}`;
    } else if (companyId) {
      apiCall = `/Companies/${companyId}`;
    } else if (domainId) {
      apiCall = `/Domains/${domainId}`;
    } else {
      result.err = `getCurrentUserDashboardsByNodeInfo - nor domainId, nor siteId, nor companyId, nor siteGroupId provided`;
      console.error(result.err);
      return result;
    }
    apiCall += '/dashboards';
    result = await api.get(apiCall);
  } catch (ex) {
    console.error(`getCurrentUserDashboardsByNodeInfo ${apiCall}`, ex);
    result.err = ex;
  }
  return result;
};

export async function getDashboardOPs(dashboardId) {
  let result = {};
  const apiCall = `/Dashboards/${dashboardId}/observedProperties`;
  try {
    result = await api.get(apiCall);
  } catch (ex) {
    console.error(`getDashboardOPs ${apiCall}`, ex);
    result.err = ex;
  }
  return result;
}

export function getAxiosError(error) {
  let msg = error.message;
  let codes;
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    const { data } = error.response;
    if (data && data.error) {
      msg = `${data.error.statusCode} - ${data.error.message}`;

      if (data.error && data.error.details) {
        codes = data.error.details.codes;
      }
    }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    msg = 'No response received';
  }
  return { msg, codes };
}
