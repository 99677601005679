import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem } from '../Dropdown';

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  flex-grow: 1;
  height: 100%;
`;

const NoBorderInput = styled.input`
  &&& {
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    padding-right: .75rem;
    box-shadow: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 6rem;
    max-width: 6rem;
    /* top: 2.5rem; */
  }
`;

const MonthPicker = (props) => {
  const { value, months, onChange, dropdownOpen, setDropdownOpen, className, ...rest } = props;

  const [inputText, setInputText] = useState();
  const [highlightedOption, setHighlightedOption] = useState(null);

  const componentRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);


  const findOption = (text) => {
    if (text) {
      return months.find(x => x.label.toLowerCase().startsWith(text.toLowerCase()));
    }
    return null;
  };


  useEffect(() => {
    const option = months.find(x => x.value === value);
    if (option) {
      setInputText(option.label.slice(0, 3));
    }
  }, [value]);


  const highlightItem = (item) => {
    setHighlightedOption(item);
    const element = document.getElementById(item.value);
    element.scrollIntoView();
  };

  useEffect(() => {
    if (dropdownOpen) {
      inputRef.current.focus();
      if (inputText) {
        const bestOption = findOption(inputText);
        if (bestOption) {
          highlightItem(bestOption);
        }
      }
    } else {
      setHighlightedOption(null);
    }
  }, [dropdownOpen, inputText]);


  const closeDropdown = () => {
    setDropdownOpen(false);
    const inputTextMonth = findOption(inputText);
    if (!inputTextMonth) {
      const option = months.find(x => x.value === value);
      if (option) setInputText(option.label.slice(0, 3));
    } else if (value !== inputTextMonth.value) {
      onChange(inputTextMonth.value, inputTextMonth);
    }
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useOnClickOutside(componentRef, () => {
    if (dropdownOpen) {
      closeDropdown();
    }
  });


  const selectItem = (month) => {
    onChange(month.value, month);
    closeDropdown();
  };

  const handleKey = (e) => {
    const { length } = months;
    const index = months.indexOf(highlightedOption);

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        highlightItem(index >= 0 ? months[(index + length - 1) % length] : months[length - 1]);
        break;
      case 'ArrowDown':
        e.preventDefault();
        highlightItem(index >= 0 ? months[(index + 1) % length] : months[0]);
        break;
      case 'Tab':
      case 'Enter':
        if (highlightedOption) {
          selectItem(highlightedOption);
          closeDropdown();
        }
        break;
      case 'Escape':
        closeDropdown();
        break;
      default:
    }
  };

  const focus = () => {
    setDropdownOpen(true);
    inputRef.current.select();
  };

  const changeText = (e) => {
    const newInputText = e.target.value;
    setInputText(newInputText);
    setDropdownOpen(true);
  };


  return (
    <Wrapper ref={componentRef} className={className}>
      <NoBorderInput className="form-control" ref={inputRef} value={inputText} onChange={changeText} onFocus={focus} onKeyDown={handleKey} {...rest} />
      {dropdownOpen && (
        <StyledDropdown className="dropdown-menu show" ref={dropdownRef}>
          {months.map(month => (
            <DropdownItem
              id={month.value}
              type="button"
              className="dropdown-item"
              key={month.value}
              value={month.value}
              onClick={() => selectItem(month)}
              onMouseOver={() => setHighlightedOption(month)}
              onFocus={() => {}}
              active={highlightedOption === month}
            >
              {month.label.slice(0, 3)}
            </DropdownItem>
          ))}
        </StyledDropdown>
      )}
    </Wrapper>
  );
};


MonthPicker.propTypes = {
  value: PropTypes.number.isRequired,
  months: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MonthPicker.defaultProps = {
  className: undefined,
};

export default MonthPicker;
