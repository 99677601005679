import React from 'react';
import { IconButton } from 'app/common';
import styled from 'styled-components';

const ViewerSelectedItem = (props) => {
    const { item } = props;

    const Item = styled.div`
        height: ${props => props.height};
        display: flex;
        align-items: center;
        padding: 0.5rem;

        ${props => item.disabled && 'color: #b4b4b4; background-color: #f7f7f7; cursor: default;'}
    `;

    const IconDiv = styled.div`
        font-size: 1.5rem;
        margin: 0 0 0 auto;
    `;


    return (
        <Item>
            <div>{item.label}</div>
            {!item.disabled &&
                <IconDiv>
                    <IconButton icon="times" color="#c00" />
                </IconDiv>}
        </Item>
    )
};

export default ViewerSelectedItem;