import React from 'react';
import { Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

let defaultMessages = {
  date: 'Date',
  time: 'Time',
  event: 'Event',
  allDay: 'All Day',
  week: <Trans>Settimana</Trans>,
  work_week: 'Work Week',
  day: <Trans>Giorno</Trans>,
  month: <Trans>Mese</Trans>,
  previous: <FontAwesomeIcon icon={faAngleLeft} />,
  next: <FontAwesomeIcon icon={faAngleRight} />,
  yesterday: <Trans>Ieri</Trans>,
  tomorrow: <Trans>Domani</Trans>,
  today: <Trans>Oggi</Trans>,
  agenda: <Trans>Agenda</Trans>,
  year: <Trans>Anno</Trans>,

  noEventsInRange: 'There are no events in this range.',

  showMore: total => `+${total} more`,
}

export default function messages(msgs) {
  return {
    ...defaultMessages,
    ...msgs,
  }
}
