import api from 'api';

export const LOAD_CATALOG_START = 'catalogs/LOAD_CATALOG_START';
export const LOAD_CATALOG_SUCCESS = 'catalogs/LOAD_CATALOG_SUCCESS';
export const LOAD_PROVINCE_CATALOG_SUCCESS = 'catalogs/LOAD_PROVINCE_CATALOG_SUCCESS';
export const LOAD_CATALOG_FAILURE = 'catalogs/LOAD_CATALOG_FAILURE';
export const LOAD_READERS_START = 'catalogs/LOAD_READERS_START';
export const LOAD_READERS_SUCCESS = 'catalogs/LOAD_READERS_SUCCESS';
export const LOAD_READERS_FAILURE = 'catalogs/LOAD_READERS_FAILURE';
export const ADD_READER = 'catalogs/ADD_READER';
export const REMOVE_READER = 'catalogs/REMOVE_READER';

export const loadCatalogStart = name => ({
  type: LOAD_CATALOG_START,
  payload: name
});

export const loadCatalogSuccess = (name, items, language) => ({
  type: LOAD_CATALOG_SUCCESS,
  payload: { name, items, language }
});

export const loadCatalogFailure = error => ({
  type: LOAD_CATALOG_FAILURE,
  payload: error
});

export const loadReadersStart = name => ({
  type: LOAD_READERS_START,
  payload: name
});

export const loadReadersSuccess = (name, items) => ({
  type: LOAD_READERS_SUCCESS,
  payload: { name, items }
});

export const loadReadersFailure = error => ({
  type: LOAD_READERS_FAILURE,
  payload: error
});

export const addReader = (name, readers, newReaders) => ({
  type: ADD_READER,
  payload: { name, readers, newReaders }
});

export const removeReader = (name, readers, removedReadersIds) => ({
  type: REMOVE_READER,
  payload: { name, readers, removedReadersIds }
});

export const loadProvinceCatalogSuccess = (country, items) => ({
  type: LOAD_PROVINCE_CATALOG_SUCCESS,
  payload: { country, items }
});

export const loadCatalog = (name, filter) => async (dispatch, getState) => {
  const { language } = getState().preferences;
  try {
    dispatch(loadCatalogStart(name));
    const params = {
      filter
    };
    const apiUrl = name !== 'event/tags' ? `/Catalogs/${name}` : '/EventTags';
    const res = await api.get(apiUrl, { params });
    // const res = await api.get(`/Catalogs/${name}${filter ? `?filter=${filter}` : ''}`);
    let items = res.data;
    dispatch(loadCatalogSuccess(name, items, language));
  } catch (err) {
    console.log(name);
    console.log(err);
    dispatch(loadCatalogFailure(err.message));
  }
};

export const loadReaders = name => async dispatch => {
  try {
    dispatch(loadReadersStart(name));
    const res = await api.get(`/EventReaders`);
    let items = res.data;
    dispatch(loadReadersSuccess(name, items));
  } catch (err) {
    dispatch(loadReadersFailure(err.message));
  }
};

const currentFilter = { where: { current: true } };
const severitiesFilter = { where: { current: true }, order: 'weight DESC' };

export const loadAllCatalogs = () => async dispatch => {
  dispatch(loadCatalog('modbus/registerkinds', currentFilter));
  dispatch(loadCatalog('modbus/datakinds', currentFilter));
  dispatch(loadCatalog('physicalQuantities', currentFilter));
  dispatch(loadCatalog('units', currentFilter));
  dispatch(loadCatalog('vectors', currentFilter));
  dispatch(loadCatalog('valueTypes', currentFilter));
  dispatch(loadCatalog('aggregationRules', currentFilter));
  dispatch(loadCatalog('countries', currentFilter));

  dispatch(loadCatalog('event/severities', severitiesFilter));
  dispatch(loadCatalog('event/tags'));
  dispatch(loadCatalog('event/types', currentFilter));
  dispatch(loadCatalog('event/details', currentFilter));
  dispatch(loadReaders('event/readers'));
};

export const loadProvinceCatalog = country => async (dispatch, getState) => {
  try {
    let items = getState().catalogs.provinces[country];
    if (!items) {
      if (country !== 'ITA') {
        return [];
      }
      dispatch(loadCatalogStart(`Provinces ${country}`));
      const res = await api.get(`/Catalogs/${country}/Provinces`);
      items = res.data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      dispatch(loadProvinceCatalogSuccess(country, items));
    }
    return items;
  } catch (err) {
    dispatch(loadCatalogFailure(err.message));
  }
};
