/* eslint-disable import/prefer-default-export */
import api, { getAxiosError } from 'api';

import logger from 'app/common/log';

export async function getOpDevice(opId) {
  let data;
  if (opId != null) {
    const url = `/observedProperties/${opId}/fieldDevice`;
    try {
      const res = await api.get(url);
      data = res && res.data;
      console.log('getOpDevices Success data: ', data);
    } catch (ex) {
      const errMsg = `getOpDevices GET Error: ${JSON.stringify(ex, null, 2)}`;
      console.log(errMsg);
      throw ex.message;
    }
  }
  return data;
}

export async function addDataFlowFilterToOP(opId, dataFlowFilter) {
  let data = {};
  const httpMethod = dataFlowFilter.id != null ? 'put' : 'post';
  const baseUrl = `/observedProperties/${opId}/dataFlowFilters`;
  const url = httpMethod === 'post' ? baseUrl : `${baseUrl}/${dataFlowFilter.id}`;

  try {
    const res = await api[httpMethod](url, dataFlowFilter);
    data = res && res.data;
    logger.debug(`addDataFlowFilterToOP ${httpMethod} Success data: `, data);
  } catch (ex) {
    const errMsg = `addDataFlowFilterToOP ${httpMethod} Error creating: ${JSON.stringify(
      dataFlowFilter,
      null,
      2
    )}`;
    logger.error(errMsg);
    throw ex.message;
  }
  return data;
}

export async function addDataFlowAlertToOP(opId, dataFlowFilterId, dataFlowAlert) {
  let data = {};
  const dataFlowAlertWithFlowFilterId = { ...dataFlowAlert, dataFlowFilterId };
  const httpMethod = dataFlowAlertWithFlowFilterId.id != null ? 'put' : 'post';
  const baseUrl = `/observedProperties/${opId}/dataFlowAlerts`;
  const url = httpMethod === 'post' ? baseUrl : `${baseUrl}/${dataFlowAlertWithFlowFilterId.id}`;

  try {
    const res = await api[httpMethod](url, dataFlowAlertWithFlowFilterId);
    data = res && res.data;
    logger.debug(`addDataFlowAlertToOP ${httpMethod} Success data: `, data);
  } catch (ex) {
    const errMsg = `addDataFlowAlertToOP ${httpMethod}  Error creating: ${JSON.stringify(
      dataFlowAlertWithFlowFilterId,
      null,
      2
    )}`;
    logger.error(errMsg);
    throw ex.message;
  }
  return data;
}

export async function addOPToDataFlowFilter({ opId, dataFlowFilterId, opName }) {
  let data = {};
  const url = `/observedProperties/${opId}/dataFlowFilters/${dataFlowFilterId}/alias`;
  try {
    const res = await api.put(url, { name: opName });
    data = res && res.data;
    logger.debug(`addOPToDataFlowFilter Success data: `, data);
  } catch (ex) {
    const bodyResponse = JSON.stringify(ex, null, 2);
    console.log('bodyResponse ', bodyResponse);
    logger.error(
      'addOPToDataFlowFilter - Error creating: alarmOp: %s for (OP,dataFlowFilter) : (%d,%d), errmsg: ',
      opName,
      opId,
      dataFlowFilterId,
      ex && ex.response
    );
    logger.error('addOPToDataFlowFilter %o', getAxiosError(ex));
  }
  return data;
}

