/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { Roles } from 'app/utils/auth';

export const findSiteTimezone = (sites, siteId, assetId, assetGroupId) => {
  let timezone = moment.tz.guess();
  try {
    if ((siteId || assetId || assetGroupId) && sites.length) {
      const siteFinded = sites.find(f => f.id.toString() === siteId.toString());
      timezone = siteFinded.timeZone;
    }
  } catch (err) {
    console.warn(err);
  }
  return timezone;
};

export const getApiCall = (apiName, filters, includeRelation, getActive, selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies, sitegroups, sites, assetgroups, assets) => {
  try {
    const companyIds = companies.map(x => x.id).join(',');
    let include = '';
    if (includeRelation) {
      include = `, "include":{"relation": "${includeRelation}"}`;
    }

    let parsedFilter = filters && filters.length > 0 ? filters.join(',') : '';
    if (parsedFilter.length > 0) parsedFilter =  parsedFilter.concat(', ');
    if (getActive) {
      parsedFilter =  parsedFilter.concat(' "active": "true",');
    }

    let siteIds = '';
    let sitegroupsIds = '';
    if (selectedSitegroup) {
      siteIds = sites.filter(x => {
        return selectedSitegroup.companyId === x.companyId && (x.siteGroups && x.siteGroups.findIndex(s => s.id === selectedSitegroup.id) !== -1);
      }).map(x => x.id).join(',');
    } else if (selectedCompany) {
      siteIds = sites.filter(x => selectedCompany.id === x.companyId).map(x => x.id).join(',');
      sitegroupsIds = sitegroups.filter(x => selectedCompany.id === x.companyId).map(x => x.id).join(',');
    } else if (companyIds) {
      siteIds = sites.filter(x => companyIds.includes(x.companyId)).map(x => x.id).join(',');
      sitegroupsIds = sitegroups.filter(x => companyIds.includes(x.companyId)).map(x => x.id).join(',');
    }

    let assetIds = [];
    let assetGroupsIds = [];
    if (selectedAssetgroup) {
      const ids = selectedAssetgroup.assetIds ? selectedAssetgroup.assetIds : [];
      assetIds = assetIds.concat(ids);
    } else if (selectedSite) {
      assetIds = selectedSite.assetGraph ? selectedSite.assetGraph.assets.map(a => a.id) : [];
      assetGroupsIds = assetgroups.filter(x => selectedSite.id === x.siteId).map(x => x.id).join(',');
    } else if (siteIds) {
      sites.forEach(selectedSite => {
        if (siteIds.includes(selectedSite.id)) {
          const ids = selectedSite.assetGraph ? selectedSite.assetGraph.assets.filter(a => !assetIds.includes(a.id)).map(a => a.id) : [];
          assetIds = assetIds.concat(ids);
        }
      });
      assetGroupsIds = assetgroups.filter(x => siteIds.includes(x.siteId)).map(x => x.id).join(',');
    }

    assetIds = assetIds.join(',');

    // const domainFilter = `{"domainId":${selectedDomain.id}}`;
    // const companyFilter = selectedCompany ? `{"companyId":${selectedCompany.id}}` : `{"companyId":{"inq":[${companyIds}]}}`;
    const sitegroupsFilter = selectedSitegroup ? `{"siteGroupId":${selectedSitegroup.id}}` : `{"siteGroupId":{"inq":[${sitegroupsIds}]}}`;
    const sitesFilter = selectedSite ? `{"siteId":${selectedSite.id}}` : `{"siteId":{"inq":[${siteIds}]}}`;
    const assetgroupsFilter = selectedAssetgroup ? `{"assetGroupId":${selectedAssetgroup.id}}` : `{"assetGroupId":{"inq":[${assetGroupsIds}]}}`;
    const assetsFilter = selectedAsset ? `{"assetId":${selectedAsset.id}}` : `{"assetId":{"inq":[${assetIds}]}}`;

    let apiCall = '';
    if (selectedSite && selectedAsset) {
      apiCall = `/${apiName}?filter={"where":{${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}${include}}`;
    } else if (selectedSite && selectedAssetgroup) {
      apiCall = `/${apiName}?filter={"where":{"or":[{${parsedFilter} "and":[${sitesFilter},${assetgroupsFilter}]}, {${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}]}${include}}`;
    } else if (selectedSite) {
      apiCall = `/${apiName}?filter={"where":{${parsedFilter} "or":[${sitesFilter}, {${parsedFilter} "and":[${sitesFilter},${assetgroupsFilter}]}, {${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}]}${include}}`;
    } else if (selectedSitegroup) {
      apiCall = `/${apiName}?filter={"where":{${parsedFilter} "or":[${sitegroupsFilter},${sitesFilter}, {${parsedFilter} "and":[${sitesFilter},${assetgroupsFilter}]}, {${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}]}${include}}`;
    } else if (selectedCompany) {
      apiCall = `/${apiName}?filter={"where":{${parsedFilter} "or":[${sitegroupsFilter},${sitesFilter}, {${parsedFilter} "and":[${sitesFilter},${assetgroupsFilter}]}, {${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}]}${include}}`;
    } else if (selectedDomain) {
      apiCall = `/${apiName}?filter={"where":{${parsedFilter} "or":[${sitegroupsFilter},${sitesFilter}, {${parsedFilter} "and":[${sitesFilter},${assetgroupsFilter}]}, {${parsedFilter} "and":[${sitesFilter},${assetsFilter}]}]}${include}}`;
    }

    return apiCall;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getFilteredPlants = (selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies, sites, assets) => {
  let filteredPlants = [];

  // Le company sono comunque filtrate per dominio
  const companyIds = selectedCompany ? [selectedCompany.id] : companies.map(x => x.id);
  let filteredSites = [];
  if (selectedSitegroup) {
    filteredSites = sites.filter(site => companyIds.includes(site.companyId) && (site.siteGroups && site.siteGroups.findIndex(s => s.id === selectedSitegroup.id) !== -1));
  } else if (selectedSite) {
    filteredSites = [selectedSite];
  } else {
    filteredSites = sites.filter(site => companyIds.includes(site.companyId));
  }

  const getAssets = (site, assetgroup) => {
    let children = [];
    if (assetgroup.assetIds) {
      assetgroup.assetIds.forEach(assetId => {
        const asset = assets.find(a => a.siteId === site.id && a.id === assetId);
        children.push({ type: 'Asset', id: asset.id, name: asset.name, siteId: site.id, key: `Asset-${asset.id}` });
      });
    }
    return children;
  };

  const getAssetgroups = ((s) => {
    let children = [];
    if (s.assetGraph) {
      if (s.assetGraph.assetGroups) {
        s.assetGraph.assetGroups.forEach(ag => {
          const assetChildren = getAssets(s, ag);
          const open = false;
          children.push({ type: 'AssetGroup', id: ag.id, name: ag.name, siteId: s.id, key: `AssetGroup-${ag.id}`, open, children: assetChildren });
        });
      }
      if (s.assetGraph.assets) {
        children = children.concat(s.assetGraph.assets.map(a => ({ type: 'Asset', id: a.id, name: a.name, siteId: s.id, key: `Asset-${a.id}` })));
      }
    }
    return children;
  });

  if (selectedAsset) {
    const s = filteredSites.find(site => site.id === selectedAsset.siteId);
    filteredPlants.push({ type: 'Asset', id: selectedAsset.id, name: selectedAsset.name, siteId: s.id, key: `Asset-${selectedAsset.id}` });
    return filteredPlants;
  }
  if (selectedAssetgroup) {
    const s = filteredSites.find(site => site.id === selectedAssetgroup.siteId);
    const children = getAssets(s, selectedAssetgroup);
    const open = children.length > 0;
    filteredPlants.push({ type: 'AssetGroup', id: selectedAssetgroup.id, name: selectedAssetgroup.name, siteId: s.id, key: `AssetGroup-${selectedAssetgroup.id}`, open, children });
    return filteredPlants;
  }

  filteredPlants = filteredSites.map((s) => {
    const children = getAssetgroups(s);
    const open = children.length > 0;
    return { type: 'Site', id: s.id, name: s.name, siteId: s.id, key: `Site-${s.id}`, open, children };
  });
  return filteredPlants;
};

export const getHolidayCalendar = (holidayCalendars, siteId, assetGroupId, assetId, selectedDomain, sites) => {
  const findAssociation = (holiday, id, type) => {
    if (holiday.associations) {
      return holiday.associations.findIndex(a => a.type === type && a.id === id) !== -1;
    }
    return false;
  };

  try {
    // Parto sempre dai siti, perchè non c'è la possibilità di associare un calendario delle festività ad un asset o ad un assetgroup
    let holidayCalendar = null;
    if (siteId) { // Cerco a livello di sito
      holidayCalendar = holidayCalendars.find(holiday => findAssociation(holiday, siteId, 'Site'));
      if (!holidayCalendar) { // Cerco a livello di company
        const { companyId } = sites.find(s => s.id === siteId);
        holidayCalendar = holidayCalendars.find(holiday => findAssociation(holiday, companyId, 'Company'));
      }
      if (!holidayCalendar) { // Cerco a livello di domain
        holidayCalendar = holidayCalendars.find(holiday => findAssociation(holiday, selectedDomain.id, 'Domain'));
      }
      if (!holidayCalendar) { // Recupero il default (quello nazionale predefinito impostato del systemuser)
        const { country } = sites.find(s => s.id === siteId);
        holidayCalendar = holidayCalendars.find(holiday => holiday.type === null && holiday.country === country);
      }
    }
    return holidayCalendar;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gestione ruoli utente
export const canViewActionButtons = (userCurrentRoles) => {
  const userHasOpeCMDRole = userCurrentRoles.includes(Roles.OPE_CMD);
  // Utenti con ruolo superiore al GUEST
  if (userHasOpeCMDRole) {
    return true;
  }
  return false;
};

export const canInsert = (userCurrentRoles) => {
  const userHasOpeCMDRole = userCurrentRoles.includes(Roles.OPE_CMD);
  // Utenti con ruolo superiore al GUEST
  if (userHasOpeCMDRole) {
    return true;
  }
  return false;
};

export const canEditOrDelate = (userSystemRoles, userCurrentRoles, type) => {
  const userHasSystemRole = userSystemRoles.includes(Roles.SYS);
  const userHasOpeCMDRole = userCurrentRoles.includes(Roles.OPE_CMD);

  if (type === 'default' && userHasSystemRole) {
    // Esclusivamente gli utenti SYS possono modificare o conacellare un asset Predefinito
    return true;
  } else if (type === 'custom' && userHasOpeCMDRole) {
    // Gli altri utenti con privilegi superiori al GUEST, possono modificare o cancellare asset Personalizzati
    return true;
  }
  return false;
};

export const canClone = (userCurrentRoles, type) => {
  const userHasOpeCMDRole = userCurrentRoles.includes(Roles.OPE_CMD);
  // Utenti con ruolo superiore al GUEST
  if (userHasOpeCMDRole) {
    return true;
  }
  return false;
};
