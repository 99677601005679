import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

const ContextMenuDiv = styled.div`
  display: none;
  position: absolute;
  z-index: 9999;
  ul
  {
    list-style: none;
    padding: 0;
    background-color: #eee;
  }

  .svg-style2
  {
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-bottom: 10px;
  }

  .svg-style
  {
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-bottom: 4px;
  }
`;

const Container = styled.a`
  color:#555!important;;
  text-decoration:none;
  display:block;
  cursor:pointer;
  padding: 5px
  &:hover
  {
    text-decoration:none;
    color:white!important;
    background-color:#20a8d8;
  }
`;

const Separator = styled.li`
  border-bottom: 1px solid #ccc;
`;

class ContextMenu extends React.Component {
  showContextMenu = (obj, diagram) => {
    const hidecxElement = document.getElementById('hideContextMenu');
    const cxElement = document.getElementById('contextMenu');
    if (hidecxElement != null) {
      cxElement.style.display = 'none';
      return;
    }
    if (obj == null) {
      cxElement.style.display = 'none';
      return;
    }

    cxElement.style.display = 'block';
    const mousePt = diagram.lastInput.viewPoint;
    const x = mousePt.x + 18;
    const y = mousePt.y + 35;
    cxElement.style.left = `${x}px`;
    cxElement.style.top = `${y}px`;
  }

  cxcommand = (command) => {
    const cxElement = document.getElementById('contextMenu');
    cxElement.style.display = 'none';
    const { onshowAssetData, onDelete, onshowMeterAsset, onaddMeterAsset, onShowAssetGroup, onGeneraComplesso } = this.props;

    if (command === 'show_data') {
      onshowAssetData();
    } else if (command === 'delete') {
      onDelete();
    } else if (command === 'meter_asset') {
      onshowMeterAsset();
    } else if (command === 'new_meter_asset') {
      onaddMeterAsset();
    } else if (command === 'asset_group') {
      onShowAssetGroup();
    } else if (command === 'crea_complesso') {
      onGeneraComplesso();
    }
  }

  renderComplexButton = () => {
    let textAssetComplesso =  <Trans>Genera asset complesso</Trans>;
    let add = null;
    const { selectedNodeKeys, nodeDataArray, showComplexGraphButton } = this.props;
    if (selectedNodeKeys.length === 1) {
      const index = nodeDataArray.findIndex(node => node.key === selectedNodeKeys[0]);
      if (index >= 0 && nodeDataArray[index].isComplex) {
        textAssetComplesso = <Trans>Modifica asset complesso</Trans>;
      }
    } else {
      add = <li id="hideContextMenu" />;
    }
    let complexGraphButton = null;
    if (showComplexGraphButton) {
      complexGraphButton = (
        <li id="crea_complesso">
          <Container onClick={() => this.cxcommand('crea_complesso')}>
            <svg
              aria-hidden="true"
              data-prefix="fas"
              data-icon="plus-circle"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="svg-inline--fa fa-plus-circle fa-w-16 fa-7x svg-style2"
            >
              <path
                fill="currentColor"
                d="M576 192c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zM64 240c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm449.6-37.2l-19.2-25.6-48 36 19.2 25.6 48-36zM576 384c-14.4 0-27.6 5-38.3 13l-96-57.6c3.8-11.2 6.3-23 6.3-35.5 0-61.9-50.1-112-112-112-8.4 0-16.6 1.1-24.4 2.9l-40.8-87.4C281.4 96 288 80.8 288 64c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304c0 61.9 50.1 112 112 112 32.1 0 60.8-13.7 81.2-35.3l95.8 57.5c-.5 3.2-1 6.5-1 9.8 0 35.3 28.7 64 64 64s64-28.7 64-64-28.7-64-64-64zm-240-32c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-184-32h48v-32h-48v32z"
              />
            </svg>
            {textAssetComplesso}
          </Container>
        </li>
      );
    }
    const ret = {
      add,
      complexGraphButton,
    };
    return ret;
  }


  render() {
    const { add, complexGraphButton } = this.renderComplexButton();
    return (
      <ContextMenuDiv id="contextMenu">
        <ul>
          {add}
          <li id="show_data">
            <Container onClick={() => this.cxcommand('show_data')}>
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="file-edit"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="svg-inline--fa fa-file-edit fa-w-12 fa-3x svg-style"
              >
                <path
                  fill="currentColor"
                  d="M384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-59.6 109.6l-95 95-5.4 48.2c-.7 6.4 4.7 11.9 11.2 11.2l48.2-5.4 95-95c2-2 2-5.2 0-7.2l-46.8-46.8c-2-2-5.2-2-7.2 0zm109.7-30.3l-25.4-25.4c-7.9-7.9-20.7-7.9-28.6 0l-26 26c-2 2-2 5.2 0 7.2l46.8 46.8c2 2 5.2 2 7.2 0l26-26c7.9-7.9 7.9-20.7 0-28.6z"
                />
              </svg>
              <strong><Trans>Dati dell&apos;asset</Trans></strong>
            </Container>
          </li>
          <li id="meter_asset">
            <Container onClick={() => this.cxcommand('meter_asset')}>
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="tachometer"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                className="svg-inline--fa fa-tachometer fa-w-18 fa-3x svg-style"
              >
                <path
                  fill="currentColor"
                  d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm102.77 119.59l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36z"
                />
              </svg>
              <Trans>Associazione misure</Trans>
            </Container>
          </li>
          {complexGraphButton}
          <Separator />
          <li id="delete">
            <Container onClick={() => this.cxcommand('delete')}>
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="trash-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-trash-alt fa-w-14 fa-3x svg-style"
              >
                <path
                  fill="currentColor"
                  d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z"
                />
              </svg>
              <Trans>Elimina</Trans>
            </Container>
          </li>
        </ul>
      </ContextMenuDiv>
    );
  }
}
export default ContextMenu;
