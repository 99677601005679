import React, { useState, useEffect } from 'react';
import LoadedDataList from './LoadedDataList/LoadedDataList';
import Loading from './Loading/Loading';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import api from 'api';
import moment from 'moment';
import useInterval from 'app/hooks/useInterval';

const LoadData = (props) => {
    const {
        match,
        observableproperties,
        physicaldimensions,
        loadedRoles,
        physicalQuantities,
        selectedDomain,
        selectedCompany,
        selectedSitegroup,
        selectedSite,
        domains,
        companies,
        sites,
        sitegroups,
        updatedRoles
    } = props;
    const { url } = match;

    const [openIds, setOpenIds] = useState([]);
    const [list, setList] = useState([]);
    const [pageAllowed, setPageAllowed] = useState(true);
    const [loading, setLoading] = useState(true);
    const [filteredDates, setFilteredDates] = useState({
        filtered: false,
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD') + 'T00:00',
        endDate: moment().format('YYYY-MM-DDTHH:mm'),
        showStartDate: moment().subtract(30, 'days').format('DD/MM/YYYY'),
        showEndDate: moment().format('DD/MM/YYYY')
    });
    const [ openRows, setOpenRows ] = useState([])

    useEffect(() => {
        if (observableproperties && updatedRoles) {
            setPageAllowed(checkPermissions());
            loadList();
        }
    }, [observableproperties, updatedRoles]);

    useInterval(() => {
        if (!filteredDates.filtered || filteredDates.endDate === moment().format('DD/MM/YYYY')) {
            loadList();
        }
    }, 60000);

    const checkPermissions = () => {
        if (selectedSite) {
            return loadedRoles.sites[selectedSite.id].role === 'SUP' || loadedRoles.sites[selectedSite.id].role === 'ADM';
        }
        if (selectedSitegroup) {
            return loadedRoles.sitegroups[selectedSitegroup.id].role === 'SUP' || loadedRoles.sitegroups[selectedSitegroup.id].role === 'ADM';
        }
        if (selectedCompany) {
            return loadedRoles.companies[selectedCompany.id].role === 'SUP' || loadedRoles.companies[selectedCompany.id].role === 'ADM';
        }
        if (selectedDomain) {
            return loadedRoles.domains[selectedDomain.id].role === 'SUP' || loadedRoles.domains[selectedDomain.id].role === 'ADM';
        }
        return false;
    }

    const loadList = (startDate = filteredDates.startDate, endDate = filteredDates.endDate) => {
        let node;
        let id;
        let path = 'branchDataUploadFiles';
        const tempList = [];
        if (selectedSite) {
            node = 'Sites';
            id = selectedSite.id;
            path = 'dataUploadFiles';
        } else if (selectedSitegroup) {
            node = 'SiteGroups';
            id = selectedSitegroup.id;
        } else if (selectedCompany) {
            node = 'Companies';
            id = selectedCompany.id;
        } else if (selectedDomain) {
            node = 'Domains';
            id = selectedDomain.id;
        }
        if (node && id) {
            setLoading(true);
            const endPoints = [
                api.get(`/${node}/${id}/${path}?filter={"order": "fileUpdated DESC", "where": {"fileUpdated": {"between": ["${startDate}", "${endDate}"]}}}`),
                /* api.get(`/${node}/${id}/${path}/count`) */
            ];
            Promise.all(endPoints).then(([filesData]) => {
                const uploadedFiles = filesData.data;
                const nodeOp = observableproperties;
                uploadedFiles.forEach(uf => {
                    const fileProps = {
                        id: uf.id,
                        status: '',
                        fileName: uf.originalName,
                        updaterName: uf.updater.name
                    };
                    const observableProperty = nodeOp.find(op => op.id === uf.concernedId);
                    if (observableProperty) {
                        const dtProperty = {
                            variableName: observableProperty.name,
                            variableId: observableProperty.id,
                            quantity: getQuantityName(observableProperty),
                            site: /* assetName ? assetName : */ getSite(uf),
                            // siteSecondaryName: selectedAssetgroup && sites.find(s => s.id === selectedAssetgroup.siteId).name,
                            id: uf.id,
                            collapsedRow: true
                        };
                        if (!tempList.find(data => data.variableId === observableProperty.id)) {
                            dtProperty.uploadedFiles = [fileProps];
                            tempList.push(dtProperty);
                        } else {
                            const index = tempList.findIndex(data => data.variableId === observableProperty.id);
                            tempList[index].uploadedFiles.push(fileProps);
                        }
                    }
                });
                setLoading(false);
                if (openIds && openIds.length > 0) {
                    getUpdatedList(openIds, tempList);
                } else {
                    setList(tempList);
                }
            }).catch(err => {
                setList([]);
                setLoading(false);
            });
        }

        const getQuantityName = (observableProperty) => {
            return observableProperty.customQuantityId ?
                physicaldimensions.find(pd => pd.id === observableProperty.customQuantityId).name
                : physicalQuantities.find(pq => pq.naturalKey === observableProperty.physicalQuantity).name;
        }

        const getSite = (uf) => {
            if (uf.siteId) {
                return sites.find(s => s.id === uf.siteId).name
            }
            if (uf.siteGroupId) {
                return sitegroups.find(s => s.id === uf.siteGroupId).name
            }
            if (uf.companyId) {
                return companies.find(s => s.id === uf.companyId).name
            }
            if (uf.domainId) {
                return domains.find(s => s.id === uf.domainId).name
            }
        }
    };

    const arrowClicked = (_row, _isOpen) => {
        if (!_isOpen && _row.variableId) {
            setOpenIds([...openIds, _row.variableId]);
            setOpenRows([...openRows, _row.id]);
            getUpdatedList([_row.variableId]);
        } else if (_row.variableId) {
            setOpenIds(openIds.filter(id => id !== _row.variableId));
        }
    }

    const getUpdatedList = (ids, _list = undefined) => {
        let listClone = _list ? [..._list] : [...list];
        const uris = [];
        setLoading(true);
        const activeIds = ids.filter(id => listClone.filter(el => el.variableId === id).length > 0);
        activeIds.forEach(id => {
            uris.push(api.get(`/ObservedProperties/${id}/imports`));
        });
        Promise.all(uris).then(filesData => {
            filesData.forEach((fileData, i) => {
                const index = listClone.findIndex(el => el.variableId === activeIds[i]);
                listClone[index].collapsedRow = false;
                fileData.data.forEach(file => {
                    const updaterName = listClone[index].uploadedFiles[listClone[index].uploadedFiles.findIndex(f => f.id === file.documentId)].updaterName;
                    listClone[index].uploadedFiles[listClone[index].uploadedFiles.findIndex(f => f.id === file.documentId)] = {
                        ...listClone[index].uploadedFiles[listClone[index].uploadedFiles.findIndex(f => f.id === file.documentId)],
                        id: file.documentId,
                        status: file.status,
                        statusDetail: file.statusDetail,
                        fileName: file.documentMetadata.originalName,
                        period: file.statusDetail && file.statusDetail.rangeFrom &&
                            moment(file.statusDetail.rangeFrom).format('DD/MM/YYYY HH:mm') + ' - \n' + moment(file.statusDetail.rangeTo).format('DD/MM/YYYY HH:mm'),
                        operation: updaterName + '\n' + (file.lastUpdate ? moment(file.lastUpdate).format('DD/MM/YYYY HH:mm:ss') : moment(file.createdOn).format('DD/MM/YYYY HH:mm:ss'))
                    };
                });
            });
            setList(listClone);
            setLoading(false);
        }).catch(e => setLoading(false));
    }

    const downloadFile = (_id, _name) => {
        api.get(`Documents/${_id}/download`, { responseType: 'arraybuffer' }).then(res => {
            const data = new Blob([res.data]);
            const docURL = window.URL.createObjectURL(data);
            let tempLink = document.createElement('a');
            tempLink.href = docURL;
            tempLink.setAttribute('download', _name);
            tempLink.click();
        }).catch(e => {
            console.log('error!', e);
        })
    }

    const loadFilteredList = (showStartDate, showEndDate) => {
        if (showStartDate === '' && showEndDate === '') {
            showStartDate = moment().subtract(30, 'days').format('DD/MM/YYYY');
            showEndDate = moment().format('DD/MM/YYYY');
        }
        const adgStartDate = moment(showStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00';
        const adgEndDate = moment(showEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T23:59';
        setFilteredDates({ filtered: true, startDate: adgStartDate, endDate: adgEndDate, showStartDate, showEndDate })
        loadList(adgStartDate, adgEndDate);
    }

    const updateOpsAndLoadList = (newOp) => {
        if (newOp) {
            observableproperties.push(newOp);
        }
        loadList();
    }

    return (
        <Switch>
            <Route
                exact
                path={url}
                render={props => <LoadedDataList
                    {...props}
                    data={list}
                    loading={loading}
                    arrowClicked={(row, isOpen) => arrowClicked(row, isOpen)}
                    downloadFile={downloadFile}
                    // onScroll={onScroll}
                    // dataLength={dataLength}
                    loadFilteredList={(startDate, endDate) => loadFilteredList(startDate, endDate)}
                    filteredDates={filteredDates}
                    pageAllowed={pageAllowed}
                    openRows={openRows}
                />}
            />
            <Route
                exact
                path={`${url}/new`}
                render={props => <Loading {...props}
                    reload={(newOp) => updateOpsAndLoadList(newOp)} />}
            />
        </Switch>
    );
};

const mapStateToProps = (state) => {
    const { observableproperties, physicaldimensions } = state.domain;
    const { physicalQuantities } = state.catalogs;
    const { loadedRoles, updatedRoles } = state.auth;
    const {
        selectedDomain,
        selectedCompany,
        selectedSitegroup,
        selectedSite,
        domains,
        companies,
        sites,
        sitegroups
    } = state.navigation;
    return {
        observableproperties,
        physicaldimensions,
        loadedRoles,
        physicalQuantities,
        selectedDomain,
        selectedCompany,
        selectedSitegroup,
        selectedSite,
        domains,
        companies,
        sites,
        sitegroups,
        updatedRoles    
    };
};

export default connect(mapStateToProps)(LoadData);
