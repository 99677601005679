const brandPrimaryColor = '#1F71B7';
const brandSecondaryColor = '#03C3CC';
const secondaryColor = '#EBEEF0';

export default {
  brandPrimaryColor,
  brandSecondaryColor,
  common: {
    brand: {
      primaryColor: brandPrimaryColor,
      secondaryColor: brandSecondaryColor,
    },
    text: {
      primaryColor: '#7A8496',
      secondaryColor: '#a3a1bf',
      headerColor: '#3E444E',
    },
    input: {
      color: '#29363d',
      backgroundColor: '#fff',
      disabledColor: '#29363d',
      disabledBackgroundColor: '#eee',
    },
    icons: {
      color: '#73818f',
      hoverColor: brandPrimaryColor,
    },
    background: {
      primaryColor: '#fff',
      secondaryColor: '#ebeef0',
    },
    buttons: {
      primary: {
        color: '#fff',
        background: brandPrimaryColor,
        border: brandPrimaryColor,
        activeColor: brandSecondaryColor,
      },
      secondary: {
        color: '#23282c',
        background: secondaryColor,
        border: secondaryColor,
        activeColor: brandSecondaryColor,
      },
    },
    links: {
      color: brandPrimaryColor,
      activeColor: brandSecondaryColor,
      hoverColor: brandSecondaryColor,
    },
  },
  navbar: {
    borderColor: '#c8ced3',
    brandBackgroundColor: '#185A92',
    backgroundColor: brandPrimaryColor,
    caretColor: '#fff',
    caretHoverColor: brandSecondaryColor,
    color: '#fff',
    activeBackgroundColor: brandPrimaryColor,
    activeColor: '#fff',
    hoverBackgroudColor: '#185A92',
    hoverColor: brandSecondaryColor,
    iconColor: '#fff',
    activeIconColor: brandSecondaryColor,
    height: '55px',
  },
  sidebar: {
    width: '220px',
    compactWidth: '3rem',
    borderColor: '#c8ced3',
    backgroundColor: '#fff',
    activeBackgroundColor: 'rgba(31,113,183,0.1)',
    color: '#889',
    hoverColor: '#c8ced3',
    activeColor: brandPrimaryColor,
    iconColor: '#889',
    openDropdownBackgroundColor: '#fafafa',
  },
  card: {
    padding: '1.5rem',
  },
};
