import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Wizard, WizardStep } from 'app/common';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import DataSelector from './DataSelector';
import LayoutConfig from './LayoutConfig';
import { widgetTypes } from '../common';
import logger from 'app/common/log';


const StyledWizard = styled(Wizard)`
  && .card-body {
    padding-bottom: 0;
  }
`;

const WidgetForm = props => {
  const {
    onClose,
    onSave,
    physicalQuantities,
    units,
    physicaldimensions,
    period,
    currentModule,
    virtualmeters
  } = props;

  const [widget, setWidget] = useState(props.widget);
  const error = "";

  const title = widget ? widget.title : '';

  const submitWidget = data => {
    onSave(data);
    onClose();
  };
  useEffect(()=> {
    logger.debug('variable WidgetForm widget ',widget);
  },[widget])

  const edit = widget.key !== undefined;
  const isTableWidget = widget.widgetType === widgetTypes.TABLE;

  return (
    <StyledWizard
      card
      edit={edit}
      title={title}
      value={widget}
      onStepSubmit={data => setWidget({ ...widget, ...data })}
      onSave={submitWidget}
      onCancel={onClose}
      error={error}
      saveDisabled={[widgetTypes.CIRCLE, widgetTypes.GAUGE, widgetTypes.TABLE].includes(
        widget.widgetType
      )} // perché è necessario impostare min e max
    >
      <WizardStep
        title={<Trans>Variabili</Trans>}
        label={
          isTableWidget ? (
            <Trans>Seleziona le variabili di interesse</Trans>
          ) : (
            <Trans>Seleziona le variabili e il periodo</Trans>
          )
        }
        onSubmit={data => {
          let { title } = widget;
          if (isTableWidget && widget.variables) {
            data.variables = data.variables.map(v => {
              const variable = widget.variables.find(va => va.statistic && va.id === v.id);
              return variable ? { ...v, statistic: variable.statistic } : v;
            });
          }
          // se è un widget a singola variabile e cambio variabile selezionata, imposto il titolo col nome della variabile
          else if (
            !isTableWidget &&
            [widgetTypes.CIRCLE, widgetTypes.GAUGE].includes(widget.widgetType) &&
            (!widget.variables || data.variables[0].name !== widget.variables[0].name)
          ) {
            title = data.variables[0].name;
          }
          logger.debug('onStepSubmit ',data,widget)
          setWidget({ ...widget, ...data, title });
        }}
      >
        <DataSelector
          currentModule={currentModule}
          periodCompetence={period}
          currentStep={1}
          widget={widget}
          isTableWidget={isTableWidget}
          isAddPeriodsStep={false}
          physicalQuantities={physicalQuantities}
          virtualmeters={virtualmeters}
        />
      </WizardStep>
      {isTableWidget && (
        <WizardStep
          title={<Trans>Seleziona periodi</Trans>}
          label={<Trans>Seleziona i periodi da considerare</Trans>}
          onSubmit={data => {
            let { title } = widget;
            data.variables = data.variables.map(v => {
              const variable = widget.variables.find(va => va.statistic && va.id === v.id);
              return variable ? { ...v, statistic: variable.statistic } : v;
            });
            setWidget({ ...widget, ...data, title });
          }}
        >
          <DataSelector
            currentModule={currentModule}
            periodCompetence={period}
            currentStep={2}
            widget={widget}
            isTableWidget={isTableWidget}
            isAddPeriodsStep={true}
            physicalQuantities={physicalQuantities}
          />
        </WizardStep>
      )}
      <WizardStep
        title={<Trans>Aspetto</Trans>}
        label={<Trans>Definisci l'aspetto del widget</Trans>}
      >
        <LayoutConfig
          currentModule={currentModule}
          isTableWidget={isTableWidget}
          periodCompetence={period}
          widget={widget}
          physicalQuantities={physicalQuantities}
          physicaldimensions={physicaldimensions}
          units={units}
        />
      </WizardStep>
    </StyledWizard>
  );
};

const mapStateToProps = state => {
  const { physicalQuantities, units } = state.catalogs;
  const { physicaldimensions, virtualmeters } = state.domain;
  return { physicalQuantities, units, physicaldimensions, virtualmeters };
};

export default connect(mapStateToProps)(WidgetForm);
