export const RATES = [
  { id: '1m', label: '1 minuto', value: '1m', gap: 1, weight: 10 },
  { id: '15m', label: '15 minuti', value: '15m', gap: 15, weight: 20 },
  { id: '1h', label: '1 ora', value: '1h', gap: 60, weight: 30 },
  { id: '1dy', label: '1 giorno', value: '1dy', gap: 1440, weight: 40 },
];

export const DAYTYPES = [
  { label: 'Predefinito', value: 'default' },
  { label: 'Personalizzato', value: 'custom' },
];
