import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';

const Wrapper = styled.div`
  &&& {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${props => props.active && `
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    `};
  }
`;

const GenericDatePicker = (props) => {
  const { value, onChange, invalid, separator, className, ...rest } = props;
  const { day, month } = value;

  const [daysOfMonth, setDaysOfMonth] = useState([]);

  const [dropdownDayOpen, setDropdownDayOpen] = useState(false);
  const [dropdownMonthOpen, setDropdownMonthOpen] = useState(false);

  const dayPickerRef = useRef(null);

  const months = [
    { value: 1, label: i18n._(t`Gennaio`), maxDays: 31 },
    { value: 2, label: i18n._(t`Febbraio`), maxDays: 29 },
    { value: 3, label: i18n._(t`Marzo`), maxDays: 31 },
    { value: 4, label: i18n._(t`Aprile`), maxDays: 30 },
    { value: 5, label: i18n._(t`Maggio`), maxDays: 31 },
    { value: 6, label: i18n._(t`Giugno`), maxDays: 30 },
    { value: 7, label: i18n._(t`Luglio`), maxDays: 31 },
    { value: 8, label: i18n._(t`Agosto`), maxDays: 31 },
    { value: 9, label: i18n._(t`Settembre`), maxDays: 30 },
    { value: 10, label: i18n._(t`Ottobre`), maxDays: 31 },
    { value: 11, label: i18n._(t`Novembre`), maxDays: 30 },
    { value: 12, label: i18n._(t`Dicembre`), maxDays: 31 },
  ];

  const changeDay = (day) => {
    onChange({ day, month });
  };

  const changeMonth = (month) => {
    onChange({ day, month });
  };

  useEffect(() => {
    const selectedMonth = month ? months.find(x => x.value === month) : { maxDays: 31 };
    const newMaxDays = Array.from(Array(selectedMonth.maxDays), (e, i) => i + 1);
    setDaysOfMonth(newMaxDays);
    if (day > selectedMonth.maxDays) {
      changeDay(selectedMonth.maxDays);
    }
  }, [month]);

  useEffect(() => {
    if (dropdownDayOpen) {
      setDropdownMonthOpen(false);
    }
  }, [dropdownDayOpen]);

  useEffect(() => {
    if (dropdownMonthOpen) {
      setDropdownDayOpen(false);
    }
  }, [dropdownMonthOpen]);


  return (
    <Wrapper active={dropdownDayOpen || dropdownMonthOpen} className={`form-control ${invalid ? 'is-invalid' : ''} ${className}`}>
      <DayPicker ref={dayPickerRef} value={day || undefined} days={daysOfMonth} onChange={changeDay} dropdownOpen={dropdownDayOpen} setDropdownOpen={setDropdownDayOpen} {...rest} />
      <MonthPicker value={month} months={months || undefined} onChange={changeMonth} dropdownOpen={dropdownMonthOpen} setDropdownOpen={setDropdownMonthOpen} {...rest} />
    </Wrapper>
  );
};

GenericDatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  gap: PropTypes.number,
  invalid: PropTypes.bool,
  separator: PropTypes.string,
  className: PropTypes.string,
};

GenericDatePicker.defaultProps = {
  minTime: '00:00',
  maxTime: '24:00',
  gap: 1,
  invalid: false,
  separator: ':',
  className: undefined,
};

export default GenericDatePicker;
