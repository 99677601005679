import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

const ViewerSelectionStatus = (props) => {
    const { selected, clearAll } = props;

    const Item = styled.div`
        height: 3rem;
        padding: 0 12px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        justify-content: space-between;
    `;

    const clearStyle = {
        color: '#008BBF',
        cursor: 'pointer'
    };

    return (
        <Item>
            {selected.length > 0 ?
                <>
                    <Trans>Elementi selezionati</Trans>: {selected.length}
                    <div style={clearStyle} onClick={clearAll}><Trans>Svuota</Trans></div>
                </>
                : <Trans>Nessuna selezione</Trans>
            }
        </Item>
    )
};

export default ViewerSelectionStatus;