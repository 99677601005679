import React from 'react';

const TreeContext = React.createContext({
  filtered: false,
  updateVisible: () => {},
  visibleNodes: [],
  disabledItems: [],
});

export default TreeContext;
