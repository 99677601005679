import React from 'react';

const RealTimeContext = React.createContext({
  status: {},
  lastValues: {},
  error: null,
  subscribe: () => {},
  unsubscribe: () => {},
});

export default RealTimeContext;
