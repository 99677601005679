import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import store from 'ducks/store';
import logger from 'app/common/log';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { Spinner } from 'app/common';
import AlarmList from './AlarmsList';
import EditAlarm from './EditAlarm';
import CompanyEventTags from './CompanyEventTags';
import SwitchButtons from './SwitchButtons';
import { loadCatalog } from 'ducks/actions/catalogs';
import { createAlarmEventNameKey, getAlarmTags } from './alarmsCommon';
import { loadObservableProperties as loadObservablePropertiesAction } from 'ducks/actions/observableproperties';
import { concatUrl } from 'app/utils/navigation';
import { getEventTagsByEntity } from './apiEventTags';

function createInitialFEAlarmData(opAlarm, feFilter) {
  let result = {};
  result.id = opAlarm.id;
  result.name = opAlarm.name;
  result.unit = opAlarm._unit;
  result.isVirtualMeter = !!(opAlarm.virtualMeterId || opAlarm.formerVirtualMeterId);
  result.isBoolean = feFilter.type === 'binaryOnOff';
  result.unitScaleFactor2db = opAlarm._dbScaleFactor;
  result.nodeInfo = {
    domainId: opAlarm.domainId,
    companyId: opAlarm.companyId,
    siteId: opAlarm.siteId
  };
  result.valueType = opAlarm.valueType;
  result.updated =
    opAlarm.updater && opAlarm.updated
      ? `${opAlarm.updater.name} ${new Date(opAlarm.updated).toLocaleString()}`
      : '';
  result.entity = result.isVirtualMeter
    ? opAlarm.name
    : opAlarm.fieldDevice && opAlarm.fieldDevice.name;
  if (result.isBoolean) {
    result.granularity = 'raw';
  } else if (result.isVirtualMeter) {
    result.granularity = '1m';
  } else {
    result.granularity = opAlarm.valueType === 'cumulated' ? '1h' : 'raw';
  }
  result.type = feFilter.type;
  result.value = feFilter.value;
  result.operator = feFilter.operator;
  result.eventName = null;
  result.eventNameKey = createAlarmEventNameKey(feFilter);
  result.severity = 'medium';
  result.state = false;
  result.persistencySec =
    feFilter.type === 'zero' || feFilter.type === 'notZero' || feFilter.type === 'noData'
      ? 15 * 60
      : 0;
  result.type = feFilter.type;
  result.event_tags = getAlarmTags(feFilter);
  return result;
}

function convertOpAlarmDataToFEAlarmData(opAlarm, dataFlowAlert, dataFlowFilter) {
  let result = {};

  if (
    dataFlowFilter &&
    (dataFlowFilter.filterType === 'expression' || dataFlowFilter.filterType === 'nodata')
  ) {
    const { feFilter } = dataFlowFilter;
    if (
      feFilter &&
      (feFilter.type === 'zero' ||
        // feFilter.type === 'notZero' ||
        feFilter.type === 'noData' ||
        feFilter.type === 'comparison1' ||
        feFilter.type === 'comparison2' ||
        feFilter.type === 'binaryOnOff')
    ) {
      result.alarmId = dataFlowAlert.id;
      result.filterId = dataFlowFilter.id;
      result.id = opAlarm.id;
      result.name = opAlarm.name;
      result.unitScaleFactor2db = opAlarm._dbScaleFactor;
      result.unit = opAlarm._unit;
      result.isVirtualMeter = opAlarm.virtualMeterId || opAlarm.formerVirtualMeterId;
      result.isBoolean = feFilter.type === 'binaryOnOff';
      result.entity = result.isVirtualMeter
        ? opAlarm.name
        : opAlarm.fieldDevice && opAlarm.fieldDevice.name;
      result.nodeInfo = {
        domainId: opAlarm.domainId,
        companyId: opAlarm.companyId,
        siteId: opAlarm.siteId
      };
      result.valueType = opAlarm.valueType;
      result.updated =
        opAlarm.updater && opAlarm.updated
          ? `${opAlarm.updater.name} ${new Date(opAlarm.updated).toLocaleString()}`
          : '';
      result.granularity = dataFlowFilter.aggregation;
      result.type = feFilter.type;
      result.value = feFilter.value;
      result.operator = feFilter.operator;
      result.eventNameKey = createAlarmEventNameKey(feFilter);
      result.eventName = feFilter.eventName;
      result.severity = dataFlowAlert.onNotification.severity;
      result.state = dataFlowAlert.enabled;
      result.event_tags = dataFlowAlert.eventProperties.event_tags;
      result.persistencySec = dataFlowFilter.triggerWindow;
    }
  }
  return result;
}

function addFeAlarms2SingleOPAlarm(opAlarm) {
  const flowAlertAndFilterList = opAlarm.dataFlowAlerts.map(flowAlert => ({
    filter: opAlarm.dataFlowFilters.find(filter => filter.id === flowAlert.dataFlowFilterId),
    alert: flowAlert
  }));

  const feAlarmsArray = flowAlertAndFilterList
    .map(alertAndFilter =>
      convertOpAlarmDataToFEAlarmData(opAlarm, alertAndFilter.alert, alertAndFilter.filter)
    )
    .filter(item => Object.keys(item).length > 0);
  const newOpAlarm = { ...opAlarm };
  feAlarmsArray.forEach(feAlarm => {
    const index = newOpAlarm.feAlarms.findIndex(alarm => alarm.type === feAlarm.type);
    newOpAlarm.feAlarms[index] = feAlarm;
  });

  return newOpAlarm;
}

function addDefaultFeAlarms2SingleOPAlarm(opAlarm) {
  const feAlarms = [];

  if (
    opAlarm.valueType === 'binary' ||
    opAlarm.physicalQuantity === 'state' ||
    opAlarm.physicalQuantity === 'event'
  ) {
    feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'binaryOnOff', value: 1 }));
  } else {
    feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'noData' }));
    feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'zero' }));
    //   feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'notZero' }));
    feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'comparison1', operator: '>' }));
    feAlarms.push(createInitialFEAlarmData(opAlarm, { type: 'comparison2', operator: '<' }));
  }

  return { ...opAlarm, feAlarms };
}

const addFEAlarms2OPAlarms = opAlarms => {
  let result = {};
  result = opAlarms
    .map(opAlarm => addDefaultFeAlarms2SingleOPAlarm(opAlarm))
    .map(opAlarm => addFeAlarms2SingleOPAlarm(opAlarm));
  return result;
};

const getEventTagNameByKey = (language, tags, key) => {
  logger.trace('getEventTagNameByKey ', language, tags, key);
  const tag = tags && tags.find(tag => tag.naturalKey === key);
  return tag ? tag.translations[language] || tag.name : key;
};

const getDomainCompaniesById = (companies, domainId) =>
  companies && companies.filter(company => company.domainId === domainId);

const Alarms = props => {
  const [feAlarmsList, setFeAlarmsList] = useState([]);
  const [completeFeAlarmsList, setCompleteFeAlarmsList] = useState([]);
  const [assetOpList, setAssetOpList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsReload, setTagsReload] = useState(0);
  const forceTagsReload = () => setTagsReload(c => ++c);

  const {
    observableproperties,
    physicalQuantities,
    units,
    match,
    loadObservableProperties,
    domains,
    companies,
    sites,
    assets,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup,
    currentRole,
    hideObservedProperties,
    userSystemRoles,
    userCurrentRoles,    
    language,
    history,
    catalogs,
    isModal,
    closeModal
  } = props;
  const allTags = catalogs && catalogs['event/tags'];
  const { url } = match;
  const makeCompanyList = ({ companies, selectedCompany, selectedDomain }) => {
    let result = [];
    if (selectedCompany) result = [selectedCompany];
    else if (selectedDomain) {
      result = getDomainCompaniesById(companies, selectedDomain.id);
    }
    logger.debug('companyList ', result);
    return result;
  };

  const companyList = makeCompanyList({ companies, selectedCompany, selectedDomain });

  logger.debug('Current Role: ', currentRole);

  const getSiteNameById = siteId => {
    const site = sites.find(aSite => aSite.id === siteId);
    return site ? site.name : '';
  };

  const getAssetOPIds = asset => {
    if (asset === undefined) return [];
    const { assetGraph } = sites.find(x => x.id === asset.siteId);
    const { assetFlows } = assetGraph;
    const { measures, inFlowIds, outFlowIds } = asset;
    const measuresIds = (measures || []).map(x => x.observedPropertyId);
    const inFlows = (inFlowIds || []).map(id => assetFlows.find(y => y.id === id));
    const destinationMeasuresIds = inFlows
      .map(flow => flow.destinationMeasures.map(x => x.observedPropertyId))
      .flat();
    const outFlows = (outFlowIds || []).map(id => assetFlows.find(y => y.id === id));
    const sourceMeasuresIds = outFlows
      .map(flow => flow.sourceMeasures.map(x => x.observedPropertyId))
      .flat();
    return measuresIds.concat(destinationMeasuresIds).concat(sourceMeasuresIds);
  };

  const opAssetAssetGroupFilter = op => {
    const result = selectedAsset || selectedAssetgroup ? assetOpList.includes(op.id) : true;
    return result;
  };

  const addPhysicalData2OPs = async opList => {
    return (
      opList &&
      opList.filter(opAssetAssetGroupFilter).map(op => {
        const _physicalQuantity = op.physicalQuantity
          ? (
              (physicalQuantities &&
                physicalQuantities.find(y => y.naturalKey === op.physicalQuantity)) ||
              {}
            )._label
          : '';
        const _unitKey =
          op.physicalQuantityDetails &&
          (op.physicalQuantityDetails.defaultVisualizationUnit || op.unit);
        const _dbScaleFactor = op.unit
          ? (
              (units && units.find(y => y.naturalKey === _unitKey)) || {
                symbol: `unit '${_unitKey}' not found`
              }
            ).exactoConversionFactor
          : null;
        const _unitSymbol = ((units && units.find(unit => unit.naturalKey === _unitKey)) || {})
          .symbol;
        return {
          ...op,
          _name: op.name,
          _physicalQuantity,
          _unit: _unitSymbol,
          _dbScaleFactor
        };
      })
    );
  };

  useEffect(() => {
    logger.debug('Alarms tags useEffect ');
    setLoading(true);
    getEventTagsByEntity({
      domains,
      companies,
      sites,
      selectedDomain,
      selectedCompany,
      selectedSite
    })
      .then(result => {
        setLoading(false);
        if (result.err) {
          logger.error('Alarms ', result.err);
        } else {
          const tags = result && result.data;
          logger.debug('Alarms setTags', tags);
          setTags(tags);
        }
      })
      .catch(() => setLoading(false));
  }, [
    domains,
    companies,
    selectedDomain,
    selectedCompany,
    selectedSite,
    sites,
    tagsReload,
    language
  ]);

  useEffect(() => {
    logger.debug('Alarms catalog event/tags useEffect ');
    store.dispatch(loadCatalog('event/tags'));
  }, [tagsReload]);

  useEffect(() => {
    let assetOpList = [];
    if (selectedAsset) {
      assetOpList = getAssetOPIds(selectedAsset);
    } else if (selectedAssetgroup) {
      assetOpList = selectedAssetgroup.assetIds.reduce(
        (acc, currAsset) => [
          ...acc,
          ...getAssetOPIds(assets.find(asset => asset.id === currAsset))
        ],
        []
      );
    }
    logger.debug('New assetOpList: ', assetOpList);
    setAssetOpList(assetOpList);
  }, [selectedAsset, selectedAssetgroup]);

  useEffect(() => {
    logger.debug('observableproperties changed, ', observableproperties);
    if (!hideObservedProperties) {
      addPhysicalData2OPs(observableproperties.filter(op => !op.hidden))
        .then(observedPropertiesWithUnits =>
          observedPropertiesWithUnits.filter(op => !op.configuration || !op.configuration.oPId)
        )
        .then(observedProperties => addFEAlarms2OPAlarms(observedProperties))
        .then(newObservedPropertiesWithAlarms => {
          const newFeAlarmsList = newObservedPropertiesWithAlarms.map(opAlarm => opAlarm.feAlarms);
          setFeAlarmsList(newFeAlarmsList);
          //Set alarms list with node info
          setCompleteFeAlarmsList(newObservedPropertiesWithAlarms);
          logger.debug('feAlarmsList: set feAlarmsList: ', feAlarmsList);
        });
    } else {
      setFeAlarmsList([]);
      setCompleteFeAlarmsList([]);
    }
  }, [observableproperties, assetOpList, hideObservedProperties]);

  const getAlarmsData = opAlarmId => {
    logger.debug('Getting alarm data of alarm...', opAlarmId);
    const alarmsData = feAlarmsList.find(feAlarms =>
      feAlarms.find(alarm => alarm.id === opAlarmId)
    );
    return alarmsData
      ? alarmsData.map(alarm => ({
          ...alarm,
          siteName:
            alarm.nodeInfo && alarm.nodeInfo.siteId && getSiteNameById(alarm.nodeInfo.siteId),
          domainName: selectedDomain.name
        }))
      : [];
  };

  function goBack(history) {
    loadObservableProperties(
      hideObservedProperties,
      selectedDomain,
      selectedCompany,
      selectedSitegroup,
      selectedSite,
      selectedAsset
    );
    history.goBack();
  }

  const AlarmsTagSwitchButtons = (url, active) => (
    <SwitchButtons
      labels={[i18n._(t`Allarmi`), i18n._(t`Tag`)]}
      actives={[active, !active]}
      onClick2={() => history.push(concatUrl(url, `/tags`))}
      onClick1={() => history.goBack()}
    />
  );
  return (
    <>
      {loading && <Spinner overlay={false} />}
      <Switch>
        <Route
          path={`${url}/:alarmId/edit`}
          render={props => (
            <EditAlarm
              {...props}
              userSystemRoles={userSystemRoles}
              getEventTagNameByKey={getEventTagNameByKey.bind(null, language, allTags)}
              language={language}
              tags={tags}
              companyList={companyList}
              allTags={allTags}
              getAlarmData={getAlarmsData}
              forceTagsReload={forceTagsReload}
              goBack={goBack}
              isModal={isModal}
              closeModal={closeModal}
            />
          )}
        />
        <Route
          path={`${url}/:alarmId`}
          render={props => (
            <EditAlarm
              {...props}
              userSystemRoles={userSystemRoles}
              getEventTagNameByKey={getEventTagNameByKey.bind(null, language, allTags)}
              language={language}
              tags={tags}
              companyList={companyList}
              allTags={allTags}
              getAlarmData={getAlarmsData}
              forceTagsReload={forceTagsReload}
              goBack={goBack}
              readOnly={isModal}
              isModal={isModal}
              closeModal={closeModal}
            />
          )}
        />
        <Route
          path={`${url}/tags`}
          render={props => (
            <CompanyEventTags
              {...{
                domains,
                companies,
                sites,
                selectedDomain,
                selectedCompany,
                selectedSite,
                language,
                currentRole,
                userSystemRoles,
                userCurrentRoles,
                tags,
                companyList
              }}
              forceReload={forceTagsReload}
              getAlarmData={getAlarmsData}
              goBack={goBack}
              switchButtons={AlarmsTagSwitchButtons(url, false)}
            />
          )}
        />
        <Route
          exact
          path={url}
          render={props => (
            <AlarmList
              {...props}
              url={url}
              feAlarmsList={completeFeAlarmsList}
              getSiteNameById={getSiteNameById}
              switchButtons={AlarmsTagSwitchButtons(url, true)}
            />
          )}
        />
      </Switch>
    </>
  );
};

function getCurrentRole(loadedRoles, type, id) {
  const allUsersAuthsOnType = loadedRoles[type];
  const currentUserAuthOnType = allUsersAuthsOnType && allUsersAuthsOnType[id];
  const currentRole = allUsersAuthsOnType && currentUserAuthOnType && currentUserAuthOnType.role;
  return currentRole;
}

const mapStateToProps = state => {
  const {
    domains,
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup
  } = state.navigation;
  const { loadedRoles, userCurrentRoles, userSystemRoles } = state.auth;
  const { type, id } = state.navigation;
  const { preferences, catalogs } = state;
  const { language } = preferences;

  const { observableproperties, physicaldimensions } = state.domain;
  const { physicalQuantities, units } = catalogs;
  const customPhysicalQuantities = (physicaldimensions || []).map(x => ({
    ...x,
    naturalKey: x.id,
    _label: x.name
  }));

  const currentRole = getCurrentRole(loadedRoles, type, id);
  const hideObservedProperties =
    userSystemRoles !== 'SYS' &&
    userSystemRoles !== 'CSU' &&
    (currentRole === 'VWR' || currentRole === 'GUE' || !currentRole);
  return {
    domains,
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup,
    observableproperties,
    physicalQuantities,
    customPhysicalQuantities,
    units,
    catalogs,
    currentRole,
    userCurrentRoles,    
    userSystemRoles,
    hideObservedProperties,
    language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadObservableProperties: (hideObservedProperties, domain, company, siteGroup, site, asset) => {
      logger.debug(
        `Loading ObservableProperties `,
        hideObservedProperties,
        domain,
        company,
        siteGroup,
        site,
        asset
      );
      return hideObservedProperties
        ? null
        : dispatch(loadObservablePropertiesAction(domain, company, siteGroup, site, asset));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
