import React, { Component } from 'react';
import NodeGraph from './graph/Components/NodeGraph';
import GroupList from './graph/Components/Group/GroupList';
import { connect } from 'react-redux'
import { Card, CardHeader, ButtonGroup, CardBody } from 'reactstrap';
import { Button } from 'app/common';
import Loader from './graph/Helpers/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import NodeEdit2 from './graph/Form/NodeEdit2';
import MeterAssetsManagement from './graph/Form/MeterAssetsManagement';
import { Trans } from '@lingui/macro';
import { getTranslatedString } from './graph/TranslateData';

class editAssets extends Component {

  state = {
    view: 'graph',
    isLoaded: false,
    energyAsset: null,
    meterAsset: null,
    observedProperties: null,
    parentGraphData: null,
    vectors: null,
    graphData: null,
    editNode: null,
    manageAsset: null,
  }

  constructor(props) {
    super(props);
    this.Loader = new Loader();
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    const { parentGraphId } = params;
    if (this.state.isLoaded && parentGraphId !== this.parentGraphId) {
      this.setState({ isLoaded: false });
      this.loadData();
    }
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { match } = this.props;
    const { params, url } = match;
    const { parentGraphId, id } = params;
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    const view = lastSegment === "group" ? "group" : "graph";
    this.parentGraphId = parentGraphId;
    this.Loader.loadGraph(id, parentGraphId, (ret) => {
      const { energyAsset, meterAsset, observedProperties, parentGraphData, vectors, graphData, virtualMeter } = ret;
      this.setState({ view, energyAsset, meterAsset, observedProperties, parentGraphData, vectors, graphData, virtualMeter, isLoaded: true });
    });
  }

  onChangeGroup = (graphData) => {
    this.setState({ graphData });
    this.Loader.patch();
  }

  

  showManageMeterAsset = (model, meterAsset, virtualMeter, saveMeterAsset, selectedNodeKeys) => {
    const currentAsset = model.nodeDataArray.find(ass => ass.key === selectedNodeKeys[0]);
    const measureAndDeviceList = this.Loader.getMeterAsset(currentAsset.key);
    currentAsset.measureAssetsList = measureAndDeviceList.measureAssetsList;
    const manageAsset = 
    <Card>
      <CardHeader>
        <Trans>Associazione misure { currentAsset.name }</Trans>
      </CardHeader>
      <CardBody>
        <MeterAssetsManagement
          meterAssetList={meterAsset} 
          virtualMeterList={virtualMeter} 
          onSave={saveMeterAsset} 
          selectedNode={selectedNodeKeys[0]} 
          linkDataArray={model.linkDataArray} 
          nodeDataArray={model.nodeDataArray}
          onHideAssetList={(callback) => {this.setState({ manageAsset: null }, ()=>{
            if (typeof callback == "function") {
              callback();
            }
          })}}
        />
      </CardBody>
    </Card>
    this.setState({ manageAsset })
  }

  showEdit = (model, selectedNode, productDetailSave, clearError, editError, graphData, parentGraphId, patchData, undoDoubleClick) => {
    const { assets } = graphData;
    const currentAsset = assets.find(ass => ass.id === selectedNode);
    const node = model.nodeDataArray.find(ass => ass.key === selectedNode);
    let nodeName = getTranslatedString('asset', JSON.parse(JSON.stringify(node.item.name)));
    const editNode = (
      <Card>
        <CardHeader>
        { currentAsset ? (<Trans>Modifica { currentAsset.name }</Trans>) : (<Trans>Nuovo { nodeName }</Trans>) } 
        </CardHeader>
        <CardBody>
          <NodeEdit2
            nodeDataArray={model.nodeDataArray}
            linkDataArray={model.linkDataArray}
            selectedNodes={selectedNode}
            productDetailSave={productDetailSave}
            clearError={clearError}
            editError={editError}
            assetGroups={graphData.assetGroups}
            assets={graphData.assets}
            parentGraphId={parentGraphId}
            graphData={graphData}
            isComplexGraph={parentGraphId !== undefined}
            onPatch={patchData}
            onUndoDoubleClick={undoDoubleClick}
          />
        </CardBody>
      </Card>
    );
    this.setState({ editNode })
  }
  closeEdit = () =>{
    this.setState({ editNode: null });
  }
  render() {
    const { selectedSite, match } = this.props;
    const { params } = match;
    const { parentGraphId, id } = params;
    const { view, energyAsset, meterAsset, observedProperties, parentGraphData, vectors, graphData, isLoaded, virtualMeter, editNode, manageAsset } = this.state;
    if (editNode != null) {
      return editNode;
    }
    if (manageAsset != null){
      return manageAsset;
    }
    return (
      (!isLoaded ? null :
        <Card id="editDiagramBodyCard">
          <CardHeader>
            <ButtonGroup style={{ float: 'right', marginTop: '3px', marginRight: '5px' }}>
              <Button size="sm" onClick={() => this.setState({ view: 'graph' })} active={view === 'graph'} ><FontAwesomeIcon icon={faProjectDiagram} /></Button>
              <Button size="sm" onClick={() => this.setState({ view: 'group' })} active={view === 'group'} ><FontAwesomeIcon icon="sitemap" /></Button>
            </ButtonGroup>
            {selectedSite && selectedSite.name}
          </CardHeader>
          <CardBody>
            {view === 'graph'
              ? <NodeGraph
                {...this.props}
                siteId={id}
                energyAsset={energyAsset}
                meterAsset={meterAsset}
                observedProperties={observedProperties}
                parentGraphData={parentGraphData}
                vectors={vectors}
                graphData={graphData}
                Loader={this.Loader}
                parentGraphId={parentGraphId}
                virtualMeter={virtualMeter}
                onShowEdit={this.showEdit}
                onShowMeterAssetManagement={this.showManageMeterAsset}
                onCloseEdit={this.closeEdit}
              />
              : <GroupList
                graphData={graphData}
                energyAsset={energyAsset}
                onChangeGroup={this.onChangeGroup}
                showCommand={true}
                siteId={id}
              />
            }
          </CardBody>
        </Card>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedSite, assets } = state.navigation;

  return {
    selectedSite,
    assets,
  };
};



export default connect(mapStateToProps)(editAssets);