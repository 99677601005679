import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';



const LeftIcon = styled(FontAwesomeIcon)`

`;

const RowButton = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  display:flex;

    &:hover {
      color: #007bff;
    }
`;

const Label = styled.span`
  padding: 0.5rem;
`;

const RowMenuButton = styled.span`
  padding-top: 1 px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  border-radius: 10rem;
  z-index: 3;
  top: calc(50% - 10px);
  // right: 0;
  right: 1rem;
  line-height: 0;
  transition: all 0.2s;
    &:hover {
    cursor: pointer;
  }
`;

const RowButtons = styled.span`
  position: absolute;
  white-space: nowrap;
  border-radius: 0.3rem;
  z-index: 4;
  top: 1rem;
  right: 0.5rem;
  line-height: 0;
  transition: all 0.2s;
  border: 1px solid #ccc;
  background-color: ${props =>
    transparentize(0, props.theme.common.background.secondaryColor)};
`;



  class Dropdown extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        showButtons: false,
        rowButtons: props.rowButtons,
        id: props.id,
        filter: props.filter
      };

      /*
      rowButtons -- object or function which return object
      [
        {
          icon: "buttonIcon",
          label: "buttonName",
          onClick: (id) => onClickFunction()
        },
        [...]
      ]
      */

      this.dropdownRef = React.createRef();
      this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
      //Check for updates
      if (prevProps.rowButtons !== this.props.rowButtons || prevProps.id !== this.props.id || prevProps.filter !==this.props.filter) {
        this.setState({
          rowButtons: this.props.rowButtons,
          id: this.props.id,
          filter: this.props.filter
        });
      }
      if (this.state.showButtons && !prevState.showButtons) {
        this.fixDropdownPosition();
      }
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick);
    }
    
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    //Close dropdown with outside click
    handleOutsideClick(event) {
      if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
          this.setState({ showButtons: false });
      }
    }

    toggleButtons = () => {
      this.setState(prevState => ({showButtons: !prevState.showButtons}));
    }

    //Fix position for dropdown in the bottom of the page
    fixDropdownPosition = () => {
      if (this.dropdownRef.current) {
        const rect = this.dropdownRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
  
        //calculate offset between dropdown and page, move dropdown up for the offset amount plus 10px to give some space
        if (rect.bottom > windowHeight) {
          const offset = rect.bottom - windowHeight;
          this.dropdownRef.current.style.top = `-${offset + 10}px`;
        } else {
          this.dropdownRef.current.style.top = '1rem';
        }
      }
    };

    render(){
        return(
            <>
            <RowMenuButton onClick={() => this.toggleButtons()}>
              <img src="/assets/img/opzioni.svg" alt="options" />
            </RowMenuButton>
            { this.state.showButtons && 
                <RowButtons ref={this.dropdownRef} >
                    {
                        this.state.rowButtons.map(button => (
                        <RowButton key={this.state.id + "_" +button.label} onClick={() => button.onClick(this.state.id, this.state.filter)}>
                                <LeftIcon icon={button.icon}/>
                                <Label>
                                    {button.label}
                                </Label>
                            </RowButton>
                        ))
                    }
                </RowButtons>
            }
           
            </>
        );
    } 
    
    
}

export default Dropdown;