import cn from 'classnames';
import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

/* eslint-disable react/prop-types */
function TimeGridEvent(props) {
  //const [eventDetailOpen, setEventDetailOpen] = useState(false);

  const {
    style,
    className,
    event,
    accessors,
    isRtl,
    selected,
    label,
    continuesEarlier,
    continuesLater,
    getters,
    onClick,
    onDoubleClick,
    components: { event: Event, eventWrapper: EventWrapper },
  } = props;
  const title = accessors.title(event);
  const end = accessors.end(event);
  const start = accessors.start(event);

  const userProps = getters.eventProp(event, start, end, selected);

  const { height, top, width, xOffset } = style;
  const inner = [
    <div key="1" className="rbc-event-label">
      {label}
    </div>,
    <div key="2" className="rbc-event-content">
      {Event ? (
        <Event event={event} title={title} />
      ) : (
        <>
          <span className="event-title">{title}</span>
          <span className="event-subtitle">{event.plantName}</span>
        </>
      )}
    </div>,
  ];

  const handleOnClick = (e) => {
    if (onClick) {
      onClick(event, e);
    }
    //setEventDetailOpen(!eventDetailOpen);
  };

  const componentRef = useRef(null);

  useOnClickOutside(componentRef, () => {
    //setEventDetailOpen(false);
  });

  return (
    <EventWrapper type="time" {...props}>
      <div
        id={`eventid-${event.id}`}
        ref={componentRef}
        onClick={handleOnClick}
        onDoubleClick={onDoubleClick}
        style={{
          ...userProps.style,
          top: `${top}%`,
          height: `${height}%`,
          [isRtl ? 'right' : 'left']: `${Math.max(0, xOffset)}%`,
          width: `${width}%`,
        }}
        className={cn('rbc-event', className, userProps.className, {
          'rbc-selected': selected,
          'rbc-event-continues-earlier': continuesEarlier,
          'rbc-event-continues-later': continuesLater,
        })}
      >
        {inner}
      </div>
    </EventWrapper>
  );
}

export default TimeGridEvent;
