import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSVG from 'react-svg';
import useOnClickOutside from 'use-onclickoutside';

const Wrapper = styled.div`
  outline: none;
  width: auto;
  max-height: 10rem;
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CaretIcon = styled(FontAwesomeIcon)`
  margin-left: .3rem;
  transition: all .1s;

  ${props => props.dropdownOpen && 'transform: rotate(180deg);'}
`;

const Dropdown = styled.div`
  && {
    overflow-y: auto;
    max-height: ${props => props.maxHeight};
    position: absolute;
    top: ${props => (!props.directionUp ? '2.5rem' : 'auto')};
    bottom: ${props => (props.directionUp ? '2.5rem' : 'auto')};
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: ${props => props.theme.common.background.primaryColor};
    color: ${props => props.theme.common.text.primaryColor};
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.16);

    &::-webkit-scrollbar {
      width: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.navbar.borderColor};
    }
  }
`;

const SelectedIcon = styled(ReactSVG)`
  & svg {
    width: ${props => props.size};
    height: ${props => props.size};
    fill: ${props => props.color};
  }
`;

const Icon = styled(ReactSVG)`
  & svg {
    width: 100%;
    height: 100%;
    fill: ${props => props.color};
  }
`;

const Item = styled.button`
  width: ${props => props.size};
  height: ${props => props.size};
  background-color: transparent;
  border: 1px solid #ccc;

  &:hover {
    background-color: #ccc;
  }
`;


const IconSelect = (props) => {
  const { name, value, iconSize, selectedIconSize, path, icons, color, maxHeight, directionUp, className, onChange } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const componentRef = useRef(null);

  const openDropDown = () => {
    setDropdownOpen(true);
  };

  const closeDropDown = () => {
    setDropdownOpen(false);
  };

  
  useOnClickOutside(componentRef, () => {
    setDropdownOpen(false);
  });

  const selectItem = (icon) => {
    onChange({ target: { name, value: icon } });
    closeDropDown();
  };

  return (
    <Wrapper ref={componentRef} tabIndex="0" onFocus={openDropDown} className={className}>
      <Toggle>
        <SelectedIcon color={color} src={`${path}/${value}`} size={selectedIconSize || '1.5rem'} />
        <CaretIcon dropdownOpen={dropdownOpen} icon="angle-down" />
      </Toggle>
      {dropdownOpen && (
        <Dropdown maxHeight={maxHeight} directionUp={directionUp} className="dropdown-menu show">
          {icons.map(icon => <Item key={icon} size={iconSize || '2.5rem'} onClick={() => selectItem(icon)}><Icon color={color} src={`${path}/${icon}`} /></Item>)}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default IconSelect;
