import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import screenfull from 'screenfull';
import styled from 'styled-components';

const DiagramButtonBar = styled.div`
  height: 38px;
`;

const StyledButton = styled(Button)`
  border-radius: 0.5rem !important;
`;
const StyledSimpleButton = styled.button`
  border-radius: 0.5rem !important;
`;

const ViewButton = ({ onEdit, isComplexGraph, onShowPrincipale}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    
    if (screenfull.enabled) {
      screenfull.toggle(document.getElementById("diagramBodyCard"));
    }
  };

   useEffect(() => {
    if (screenfull.enabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }
  }, []);


  let showDefinitivo = null;
  if (isComplexGraph) {
    showDefinitivo = (
      <button
        title={i18n._(t`Torna al grafo principale`)}
        onClick={onShowPrincipale}
        className="btn btn-primary"
        type="button"
      >
        <svg
          aria-hidden="true"
          data-prefix="fas"
          data-icon="save"
          className="svg-inline--fa fa-save fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M272 157.1v197.8c0 10.7-13 16.1-20.5 8.5l-98.3-98.9c-4.7-4.7-4.7-12.2 0-16.9l98.3-98.9c7.5-7.7 20.5-2.3 20.5 8.4zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
          />
        </svg>
      </button>
    );
  }
  return (
    <DiagramButtonBar id="diagramButtonBar">
      {isFullscreen ? null : (
        <ButtonGroup>
          <StyledSimpleButton
            title={i18n._(t`Modifica il grafo`)}
            onClick={onEdit}
            className="btn btn-primary"
            type="button"
          >
            <svg
              aria-hidden="true"
              data-prefix="fas"
              data-icon="save"
              className="svg-inline--fa fa-save fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
              />
            </svg>
          </StyledSimpleButton>
          
          {showDefinitivo}
        </ButtonGroup>
      )}  
      <StyledButton color="secondary" style={{float: 'right'}} onClick={toggleFullscreen} >
        <FontAwesomeIcon icon={isFullscreen ? 'compress' : 'expand'}/>
      </StyledButton>
    </DiagramButtonBar>
  );
};

export default ViewButton;
