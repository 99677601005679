import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ToggleButton = styled.div`
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  /* border: 1px solid ${props => props.theme.navbar.iconColor}; */
  margin: auto .5rem;
  padding: 0 .5rem;
  cursor: pointer;
  outline: none;
`;

const ToggleIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  color: ${props => props.theme.navbar.iconColor};
  width: 2rem;
  height: 2rem;

  ${ToggleButton}:hover & {
    color: ${props => props.theme.navbar.activeIconColor};
  }
`;

const SidebarToggle = ({ onClick }) => (
  <ToggleButton onClick={onClick} className="d-lg-none" display="md">
    <ToggleIcon icon="bars" />
  </ToggleButton>
);

export default SidebarToggle;
