import React, { useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';
import { Button } from 'app/common';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { checkPassword } from 'app/utils/auth';

const PasswordField = styled.div`
  margin-bottom: 1rem;
`;

const Error = styled.div`
  font-size: .8rem;
  color: #f00;
`;

const DeleteModal = (props) => {
  const { isOpen, header, children, askPassword, toggleModal, onDelete } = props;

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const deleteRef = useRef(null);

  const check = async () => {
    if(deleteRef.current && !deleteRef.current.disabled){
      deleteRef.current.disabled = true;
      try {
        setError('');
        const isPasswordValid = !askPassword || await checkPassword(password);
        if (isPasswordValid) {
          onDelete();
        } else {
          deleteRef.current && (deleteRef.current.disabled = false);
          setError(<Trans>Password errata</Trans>);
        }
      } catch (err) {
        deleteRef.current && (deleteRef.current.disabled = false);
        setError(err.message);
      }
      setPassword('');
    }
  };

  const resetState = () => {
    setPassword('');
    setError('');
  };

  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={toggleModal} onOpened={resetState}>
      <ModalHeader toggle={toggleModal}>
        {header}
      </ModalHeader>
      <ModalBody>
        {children}
        {askPassword && (
          <>
            <p><Trans>Inserisci la password del tuo account per confermare l’operazione.</Trans></p>
            <PasswordField>
              <Label><Trans>Password</Trans></Label>
              {/* campo vuoto perché Firefox prima di un campo password si aspetta un campo con username, e quindi se
              l'autocomplete è attivo va a riempire automaticamente il contenuto del primo campo precedente che trova */}
              <input type="text" name="not-an-email" defaultValue="" style={{ display: 'none' }} />
              <Input autoFocus autoComplete="new-password" type="password" value={password} onChange={(e) => { setPassword(e.target.value); setError(''); }} />
              <Error>{error}</Error>
            </PasswordField>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggleModal} ref={deleteRef}><Trans>Annulla</Trans></Button>
        <Button marginright="true" color="danger" onClick={check}><Trans>Elimina</Trans></Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  children: PropTypes.element,
  toggleModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  askPassword: PropTypes.bool,
};

DeleteModal.defaultProps = {
  children: null,
  askPassword: false,
};

export default DeleteModal;
