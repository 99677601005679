/* eslint-disable import/prefer-default-export */
import { addDataFlowFilterToOP, addDataFlowAlertToOP, addOPToDataFlowFilter } from '../apiAlarms';
import { translateAlarmEventName, alarmDurationLabel } from '../alarmsCommon';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import log from 'app/common/log';

const convertOperatorSymbol = sym => {
  let result = '';
  switch (sym) {
    case '≥':
      result = '>=';
      break;
    case '≤':
      result = '<=';
      break;
    case '≠':
      result = '!=';
      break;
    case '=':
      result = '==';
      break;
    default:
      result = sym;
  }
  return result;
};

const createEventTypeLabel = alarm => {
  const labelMap = {
    noData: 'noOpData',
    zero: 'analogOpConstant',
    notZero: 'analogOpConstant',
    comparison1: 'analogOpThreshold',
    comparison2: 'analogOpThreshold',
    binaryOnOff: 'digitalOpStatusChange'
  };
  return alarm && labelMap[alarm.type] ? labelMap[alarm.type] : '';
};

const createAlarmOffName = alarm => {
  const message =  `${i18n._(t`Fine allarme per`)} ${translateAlarmEventName(alarm)}`;
  return alarm && alarm.eventNameKey && message;
};

function createDataFlowFilter(opAlarm) {
  const result = {};
  result.id = opAlarm.filterId;
  const thresholdValue = parseFloat(opAlarm.value, 10) * opAlarm.unitScaleFactor2db;
  const isValueNaN = Number.isNaN(thresholdValue);
  result.aggregation = opAlarm.granularity;
  result.feFilter = {
    type: opAlarm.type,
    value: opAlarm.value,
    operator: opAlarm.operator,
    eventName: opAlarm.eventName
  };
  result.triggerWindow = opAlarm.persistencySec;

  switch (opAlarm.type) {
    case 'noData':
      result.filterType = 'nodata';
      break;
    case 'zero':
      result.filterType = 'expression';
      result.triggerExpression = 'result == 0';
      break;
    case 'notZero':
      result.filterType = 'expression';
      result.triggerExpression = 'result != 0';
      break;
    case 'comparison1':
    case 'comparison2':
      const compValue = isValueNaN ? 0 : thresholdValue;
      result.filterType = 'expression';
      result.triggerExpression = `result ${convertOperatorSymbol(opAlarm.operator)} ${compValue}`;
      result.triggerWindow = 0;
      break;
    case 'binaryOnOff':
      result.filterType = 'expression';
      result.triggerExpression = opAlarm.isVirtualMeter
        ? `result == ${opAlarm.value}`
        : `result == ${opAlarm.value === true}`;
      result.triggerWindow = 0;
      break;
    default:
      result.filterType = 'expression';
      result.triggerExpression = '';
  }
  return result;
}

const createAlarmEventDescription = alarm => {
  const labelMap = {
    noData: 'unavailableOpData',
    zero: 'zeroConstantMeasure',
    notZero: 'nonZeroConstantMeasure',
    comparison1: `firstThresholdAlarm`,
    comparison2: `secondThresholdAlarm`,
    binaryOnOff: alarm.value ? 'changeToOn' : 'changeToOff'
  };
  return alarm && labelMap[alarm.type] ? labelMap[alarm.type] : '';
};

const createPersistencyLabel = alarm => {
  const persistencyLabel = {
    raw: `15 minuti`,
    '1m': `15 minuti`,
    '5m': `1 ora`,
    '10m': `1 ora`,
    '15m': `3 ore`,
    '30m': `3 ore`,
    '1h': `6 ore`,
    '1dy': `6 giorni`
  };
  return alarm && persistencyLabel[alarm.granularity] ? persistencyLabel[alarm.granularity] : '';
};

const createAlarmConditionDescription = alarm => {
  const notAvailable = i18n._(t`non disponibile`);
  const forAtLeast = i18n._(t`per almeno`);
  const labelMap = {
    noData: `${alarm.name} ${notAvailable}, ${forAtLeast} ${createPersistencyLabel(alarm)}`,
    zero: `${alarm.name} = 0; ${forAtLeast} ${createPersistencyLabel(alarm)}`,
    notZero: `${alarm.name} ≠ 0; ${forAtLeast} ${createPersistencyLabel(alarm)}`,
    comparison1: `${alarm.name}${alarmDurationLabel(alarm)} ${alarm.operator} ${alarm.value} ${alarm.unit}`,
    comparison2: `${alarm.name}${alarmDurationLabel(alarm)} ${alarm.operator} ${alarm.value} ${alarm.unit}`,
    binaryOnOff: `${alarm.name} = ${alarm.value ? 'On' : 'Off'}`
  };
  return alarm && labelMap[alarm.type] ? labelMap[alarm.type] : '';
};

function createDataFlowAlert(opAlarm) {
  const result = {};
  result.id = opAlarm.alarmId;
  result.dataFlowFilterId = opAlarm.filterId;
  result.enabled = opAlarm.state;
  result.onNotification = {
    severity: opAlarm.severity
  };

  result.eventProperties = {
    event_type: createEventTypeLabel(opAlarm),
    event_tags: opAlarm.event_tags,
    event_name: opAlarm.eventName ? opAlarm.eventName : opAlarm.eventNameKey, 
    event_description: createAlarmEventDescription(opAlarm),
    condition_description: createAlarmConditionDescription(opAlarm),
    severity: opAlarm.severity
  };

  result.eventProperties.schema_name = 'workflow2';

  result.offNotification = {
    severity: 'low',
    eventProperties: {
      event_name:  opAlarm.eventName ? createAlarmOffName(opAlarm): opAlarm.eventNameKey
    }
  };

  return result;
}

export const saveAlarm = async opAlarmData => {
  log.debug('Alert saving...',opAlarmData);
  // eslint-disable-next-line no-restricted-syntax
  let updatedAlarmsInfo = [];
  for (const feAlarm of opAlarmData) {
    const dataFlowFilter2Add = createDataFlowFilter(feAlarm);
    const dataFlowAlert2Add = createDataFlowAlert(feAlarm);
    // eslint-disable-next-line no-await-in-loop
    const createdDataFlowFilter = await addDataFlowFilterToOP(feAlarm.id, dataFlowFilter2Add);
    // eslint-disable-next-line no-await-in-loop
    const createdDataFlowAlert = await addDataFlowAlertToOP(
      feAlarm.id,
      createdDataFlowFilter.id,
      dataFlowAlert2Add
    );

    updatedAlarmsInfo.push({createdDataFlowFilter: createdDataFlowFilter, createdDataFlowAlert: createdDataFlowAlert});
    
    if (feAlarm && !feAlarm.isBoolean && feAlarm.state) {
      const alarmName = translateAlarmEventName(feAlarm);
      const opName = `${feAlarm.name} - ${alarmName}`;
      const createdOp = await addOPToDataFlowFilter({
        opId: feAlarm.id,
        dataFlowFilterId: createdDataFlowFilter.id,
        opName
      });
      log.debug('Created alarmOp: %o for Op %d-%s', createdOp, feAlarm.id, feAlarm.name);
    }
    
  }

  return updatedAlarmsInfo;
};
