import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AutosizeInput from './AutosizeInput.react';
import Token from './Token.react';

import {getOptionLabel} from './utils/';
import hintContainer from './containers/hintContainer';
import inputContainer from './containers/inputContainer';

const HintedAutosizeInput = hintContainer(AutosizeInput);

class TypeaheadInputMulti extends React.Component {
  render() {
    const {
      className,
      inputClassName,
      labelKey,
      onRemove,
      onCopy,
      onPaste,
      onCursorIndexChange,
      renderToken,
      selected,
      activeTokens,
      ...props
    } = this.props;

    let cursorIndex = this.props.cursorIndex !== -1 ? this.props.cursorIndex : selected.length;

    let tokensPreFocus = selected.slice(0, cursorIndex);
    let tokensPostFocus = selected.slice(cursorIndex, selected.length);

    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      <div
        className={cx('form-control', 'rbt-input-multi', className)}
        disabled={props.disabled}
        onClick={this._handleContainerClickOrFocus}
        onFocus={this._handleContainerClickOrFocus}
        onCopy={onCopy}
        onPaste={onPaste}
        tabIndex={-1}>
        <div className="rbt-input-wrapper" ref={(el) => this._wrapper = el}>
          {
            tokensPreFocus.map((option, idx) => this._renderToken(activeTokens, option, idx))
          }
            <HintedAutosizeInput
              {...props}
              inputClassName={cx('rbt-input-main', inputClassName)}
              inputRef={(input) => {
                this._input = input;
                this.props.inputRef(input);
              }}
              inputStyle={{
                backgroundColor: 'transparent',
                border: 0,
                boxShadow: 'none',
                cursor: 'inherit',
                outline: 'none',
                padding: 0,
              }}
              onKeyDown={this._handleKeyDown}
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            />
          {
            tokensPostFocus.map((option, idx) => this._renderToken(activeTokens, option, tokensPreFocus.length+idx))
          }
        </div>
      </div>
      /* eslint-enable jsx-a11y/no-static-element-interactions */
      /* eslint-enable jsx-a11y/click-events-have-key-events */
    );
  }

  _renderToken = (activeTokens, option, idx) => {
    const { onRemove, renderToken } = this.props;
    const props = {
      ...this.props,
      inputNode: this._input,
      onRemove: () => onRemove(option),
    };

    return renderToken(activeTokens, option, props, idx);
  }

  /**
   * Forward click or focus events on the container element to the input.
   */
  _handleContainerClickOrFocus = (e) => {
    // Don't focus the input if it's disabled.
    if (this.props.disabled) {
      e.target.blur();
      return;
    }

    // Move cursor to the end if the user clicks outside the actual input.
    const inputNode = this._input;
    if (e.target !== inputNode) {
      inputNode.selectionStart = inputNode.value.length;
    }

    inputNode.focus();
  }

  _handleKeyDown = (e) => {
    const { onKeyDown } = this.props;

    // switch (e.keyCode) {
      // case BACKSPACE:
      //   if (e.target === this._input && selected.length && !value) {
    //       // Prevent history back
    //       e.preventDefault();
    //       // If the input is selected and there is no text, focus the last
    //       // token when the user hits backspace.
    //       const children = this._wrapper.children;
    //
    //       const cursorIndex = Array.from(children).findIndex(item => item.className === 'rbt-input-hint-container');
    //
    //       // Permetteva di selezionare prima il token e poi cancellarlo - modifica 30/01/2019
    //       // const lastToken = children[cursorIndex-1];
    //       // // const lastToken = children[children.length - 2];
    //       // if(lastToken) {
    //       //   lastToken.classList.add('rbt-token-active');
    //       //   lastToken.focus();
    //       // }
    //
    //       const lastToken = selected[cursorIndex-1];
    //       if(lastToken) {
    //         onRemove(lastToken);
    //       }
    //     }
    //     break;
    // }

    // // L'autocomplete scatta esclusivamente nel caso vengano digitati caratteri non numerici
    // if(Number.isNaN) {
    //   onKeyDown(e);
    // }

    onKeyDown(e);
  }
}

TypeaheadInputMulti.propTypes = {
  /**
   * Provides a hook for customized rendering of tokens when multiple
   * selections are enabled.
   */
  renderToken: PropTypes.func,
};

TypeaheadInputMulti.defaultProps = {
  renderToken: (activeTokens, option, props, idx) => {
    const { disabled, cursorIndex, inputNode, onRemove, onCursorIndexChange, onActiveTokensChange, cntrlispressed, tabIndex, labelKey } = props;
    return (
      <Token
        disabled={disabled}
        key={idx}
        active={cursorIndex === idx}
        inputNode={inputNode}
        onRemove={onRemove}
        onCursorIndexChange={onCursorIndexChange}
        onActiveTokensChange={onActiveTokensChange}
        activeTokens={activeTokens}
        cntrlispressed={cntrlispressed}
        index={idx}
        tabIndex={tabIndex}
      >
        {getOptionLabel(option, labelKey)}
      </Token>
    );
  },
};

export default inputContainer(TypeaheadInputMulti);
