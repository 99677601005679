import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { getUrlFromId } from 'app/utils/navigation';
import AssetSelectorSection from './AssetSelectorSection';
import UserRole from '../UserRole';
import ActiveAlarmsRecap from '../Notification/ActiveAlarmsRecap';
import Selector from './Selector';
import { Roles } from 'app/utils/auth';

const DOMAINS = 'domains';
const COMPANIES = 'companies';
const SITES = 'sites';
const SITEGROUPS = 'sitegroups';
const ASSETS = 'assets';
const ASSETGROUPS = 'assetgroups';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const WrapperRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;


const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
`;

const Label = styled.span`
  font-weight: 500;
`;

const AssetsBar = (props) => {
  const { domains, companies, sites, sitegroups, assets, assetgroups, type, id, subtype, subid, selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAsset, selectedAssetgroup, path, userCurrentRoles, userSystemRoles } = props;

  const siteSections = [];
  if (selectedCompany) {
    if (sitegroups) {
      const items = sitegroups.filter(x => x.companyId === selectedCompany.id);
      if (items.length > 0) {
        siteSections.push(<AssetSelectorSection key="groups" items={items} getUrl={item => getUrlFromId(SITEGROUPS, item.id)} header={<Trans>Gruppi di siti di <Label>{selectedCompany.name}</Label></Trans>} noItemsText={<Trans>Nessun sito</Trans>} />);
      }
    }
    if (sites) {
      const sitesOfCompany = sites.filter(x => x.companyId === selectedCompany.id);
      const otherSites = sites.filter(x => x.companyId !== selectedCompany.id);
      siteSections.push(<AssetSelectorSection key="sitesofcompany" items={sitesOfCompany} getUrl={item => getUrlFromId(SITES, item.id)} header={<Trans>Siti di <Label>{selectedCompany.name}</Label></Trans>} noItemsText={<Trans>Nessun sito</Trans>} />);
      siteSections.push(<AssetSelectorSection key="othersites" isOtherSites={true} items={otherSites} getUrl={item => getUrlFromId(SITES, item.id)} header={<Trans>Siti delle altre aziende</Trans>} noItemsText={<Trans>Nessun sito</Trans>} />);
    }
  } else {
    siteSections.push(<AssetSelectorSection key="sites" items={sites} getUrl={item => getUrlFromId(SITES, item.id)} header={<Trans>Siti di tutte le aziende</Trans>} noItemsText={<Trans>Nessun sito</Trans>} />);
  }

  const assetSections = [];
  if (selectedSite) {
    if (assetgroups) {
      const assetgroupsOfSite = assetgroups.filter(x => x.siteId === selectedSite.id);
      assetSections.push(<AssetSelectorSection key="assetgroupsofsite" items={assetgroupsOfSite} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETGROUPS, item.id)} descriptionProp="qualifier" header={<Trans>Gruppi di asset di <Label>{selectedSite.name}</Label></Trans>} noItemsText={<Trans>Nessun gruppo di asset</Trans>} />);
    }
    if (assets) {
      const assetsOfSite = assets.filter(x => x.siteId === selectedSite.id);
      const otherAssets = assets.filter(x => x.siteId !== selectedSite.id);
      assetSections.push(<AssetSelectorSection key="assetsofsite" items={assetsOfSite} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset di <Label>{selectedSite.name}</Label></Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
      assetSections.push(<AssetSelectorSection key="otherassets" items={otherAssets} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset degli altri siti</Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
    }
  } else if (selectedSitegroup) {
    const siteIds = sites.filter(x => x.siteGroups && x.siteGroups.some(y => y.id === selectedSitegroup.id)).map(x => x.id);
    if (assetgroups) {
      const assetgroupsOfSitegroup = assetgroups.filter(x => siteIds.includes(x.siteId));
      assetSections.push(<AssetSelectorSection key="assetgroupsofsitegroup" items={assetgroupsOfSitegroup} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETGROUPS, item.id)} descriptionProp="qualifier" header={<Trans>Gruppi di asset di <Label>{selectedSitegroup.name}</Label></Trans>} noItemsText={<Trans>Nessun gruppo di asset</Trans>} />);
    }
    if (assets) {
      const assetsOfSitegroup = assets.filter(x => siteIds.includes(x.siteId));
      const otherAssets = assets.filter(x => !siteIds.includes(x.siteId));
      assetSections.push(<AssetSelectorSection key="assetsofsitegroup" items={assetsOfSitegroup} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset dei siti di <Label>{selectedSitegroup.name}</Label></Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
      assetSections.push(<AssetSelectorSection key="otherassets" items={otherAssets} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset degli altri siti</Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
    }
  } else if (selectedCompany) {
    const siteIds = sites.filter(x => x.companyId === selectedCompany.id).map(x => x.id);
    if (assetgroups) {
      const assetgroupsOfCompany = assetgroups.filter(x => siteIds.includes(x.siteId));
      assetSections.push(<AssetSelectorSection key="assetgroupsofcompany" items={assetgroupsOfCompany} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETGROUPS, item.id)} descriptionProp="qualifier" header={<Trans>Gruppi di asset di <Label>{selectedCompany.name}</Label></Trans>} noItemsText={<Trans>Nessun gruppo di asset</Trans>} />);
    }
    if (assets) {
      const assetsOfCompany = assets.filter(x => siteIds.includes(x.siteId));
      const otherAssets = assets.filter(x => !siteIds.includes(x.siteId));
      assetSections.push(<AssetSelectorSection key="assetsofcompany" items={assetsOfCompany} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset dei siti di <Label>{selectedCompany.name}</Label></Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
      assetSections.push(<AssetSelectorSection key="otherassets" items={otherAssets} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset degli altri siti</Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
    }
  } else {
    assetSections.push(<AssetSelectorSection key="assetgroups" items={assetgroups} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETGROUPS, item.id)} descriptionProp="qualifier" header={<Trans>Gruppi di asset di tutti i siti</Trans>} noItemsText={<Trans>Nessun gruppo di asset</Trans>} />);
    assetSections.push(<AssetSelectorSection key="assets" items={assets} idProp={['siteId', 'id']} getUrl={item => getUrlFromId(SITES, item.siteId, ASSETS, item.id)} descriptionProp="qualifier" header={<Trans>Asset di tutti i siti</Trans>} noItemsText={<Trans>Nessun asset</Trans>} />);
  }

  return (
    <Wrapper>
      <Bar>
        <Selector
          label={<Trans>Dominio</Trans>}
          selectedItem={selectedDomain}
          selectedItemLink={selectedDomain ? getUrlFromId(DOMAINS, selectedDomain.id) : null}
          placeholder={<Trans>Dominio</Trans>}
          showDropdown={domains.length > 1 || userSystemRoles.includes(Roles.CSU)}
          active={type === DOMAINS && selectedDomain && id === selectedDomain.id}
          type={"domains"}
        >
          <AssetSelectorSection header={<Trans>Domini</Trans>} items={domains} getUrl={item => getUrlFromId(DOMAINS, item.id)} noItemsText={<Trans>Nessun dominio</Trans>} />
        </Selector>
        <Selector
          label={<Trans>Azienda</Trans>}
          selectedItem={selectedCompany}
          selectedItemLink={selectedCompany ? getUrlFromId(COMPANIES, selectedCompany.id) : null}
          placeholder={<Trans>Non selezionata</Trans>}
          path={path}
          showDropdown={companies.length > 1 || userCurrentRoles.includes(Roles.SUP)}
          active={type === COMPANIES && selectedCompany && id === selectedCompany.id}
          type={"companies"}
        >
          <AssetSelectorSection header={<Trans>Aziende</Trans>} items={companies} getUrl={item => getUrlFromId(COMPANIES, item.id)} noItemsText={<Trans>Nessuna azienda</Trans>} />
        </Selector>
        <Selector
          label={<Trans>Sito</Trans>}
          selectedItem={selectedSitegroup || selectedSite}
          selectedItemLink={selectedSitegroup ? getUrlFromId(SITEGROUPS, selectedSitegroup.id) : (selectedSite ? getUrlFromId(SITES, selectedSite.id) : null)}
          placeholder={<Trans>Non selezionato</Trans>}
          showDropdown={sites.length > 1 || userCurrentRoles.includes(Roles.ADM)}
          active={(type === SITES && selectedSite && id === selectedSite.id && !selectedAsset && !selectedAssetgroup) || (type === SITEGROUPS && selectedSitegroup && id === selectedSitegroup.id)}
          type={"sites"}
        >
          {siteSections}
        </Selector>
        <Selector
          label={<Trans>Asset</Trans>}
          selectedItem={selectedAssetgroup || selectedAsset}
          selectedItemLink={selectedAssetgroup ? getUrlFromId(SITES, selectedAssetgroup.siteId, ASSETGROUPS, selectedAssetgroup.id) : (selectedAsset ? getUrlFromId(SITES, selectedAsset.siteId, ASSETS, selectedAsset.id) : null)}
          placeholder={<Trans>Non selezionato</Trans>}
          showDropdown
          active={type === SITES && selectedSite && id === selectedSite.id && ((subtype === ASSETS && selectedAsset && subid === selectedAsset.id) || (subtype === ASSETGROUPS && selectedAssetgroup && subid === selectedAssetgroup.id))}
        >
          {assetSections}
        </Selector>
      </Bar>
      <WrapperRight>
        <ActiveAlarmsRecap />
        <UserRole />
      </WrapperRight>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const {
    domains,
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    selectedDomain,
    selectedSitegroup,
    selectedAssetgroup,
    path,
    type,
    id,
    subtype,
    subid,
  } = state.navigation;
  const { 
    userCurrentRoles, 
    userSystemRoles 
  } = state.auth;
  let {
    selectedCompany,
    selectedSite,
    selectedAsset,
  } = state.navigation;
  if (companies.length === 1) {
    [selectedCompany] = companies;
    if (sites.length === 1) {
      [selectedSite] = sites;
      if (assets.length === 1) {
        [selectedAsset] = assets;
      }
    }
  }

  return {
    numberOfDomains: domains.length,
    numberOfCompanies: companies.length,
    numberOfSites: sites.length,
    numberOfAssets: assets.length,
    selectedDomain,
    selectedCompany,
    selectedSitegroup,
    selectedSite,
    selectedAssetgroup,
    selectedAsset,
    path,
    type,
    id,
    subtype,
    subid,
    domains,
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    userCurrentRoles,
    userSystemRoles
  };
};

export default connect(mapStateToProps)(AssetsBar);
