/** Author: Stefano Chessa*/
import React from 'react';
import { DynamicForm } from 'app/common';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { ModalBody, ModalHeader, Modal } from 'reactstrap';

const UpdateUser = (props) => {
    const { userid, userData, setUserData, closeModal, onSuccess, onError } = props;
    const thisUserData = userData && userData.filter(user => user.id === userid);
    const userName = thisUserData && thisUserData[0] && thisUserData[0].name;

    const submit = async (values) => {
        const body = {
            "name": values['name'],
            "email": values['email']
        }

        const res = await api.patch('/UserInfos/' + userid, body, { validateStatus: () => true })
        if (res.status >= 200 && res.status < 300) {
            const getRes = await api.get('/UserInfos/' + userid);
            if(getRes && getRes.data){
                setUserData(userData.map(user => user.id === userid ? getRes.data : user));
                onSuccess(getRes.data.name + " - " + i18n._(t`Utente modificato`));
            }
        } else if (res.status >= 300 && res.status < 500) {
            onError(userName + " - " + res.data.error.message)
        } else if (res.status >= 500) {
            let error = "";
            if (res.data.error.Keycloaksaid.errorMessage !== undefined) {
                error = res.data.error.Keycloaksaid.errorMessage;
            } else {
                error = res.data.error.message;
            }
            onError(userName + " - " + error)
        }
    };

    const cancel = () => {
        closeModal();
    };

    if (!thisUserData || !userName)
        return null;

    return (
        <>
            {thisUserData ?
                (
                    <Modal size="xl" centered isOpen={true}>
                        <ModalHeader toggle={closeModal}><Trans>Modifica dell'utente</Trans>&nbsp;{userName}</ModalHeader>
                        <ModalBody>
                            <DynamicForm
                                card
                                initialValues={thisUserData[0]}
                                onSubmit={submit}
                                onCancel={cancel}
                                fields={[
                                    {
                                        name: 'name',
                                        type: 'text',
                                        label: <Trans>Nome</Trans>,
                                        validation: {
                                            required: true, minLength: 3
                                        },
                                    },
                                    {
                                        name: 'email',
                                        type: 'text',
                                        label: <Trans>Email</Trans>,
                                        validation: {
                                            required: true, email: true
                                        }
                                    },
                                ]}
                            >
                            </DynamicForm>
                        </ModalBody>
                    </Modal>)
                     : (null)

            }

        </>
    );
}

UpdateUser.propTypes = {
    userid: PropTypes.number.isRequired,
    setUserData: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired, 
    onError: PropTypes.func.isRequired
};

export default UpdateUser;