/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import i18n from 'app/i18n';
import { Trans } from '@lingui/macro';
import { Select as StyledSelect, Button } from 'app/common';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { getSeriesType, getUnitDetail, isDynamicUnit, getCorrectTextColor } from '../Functions';
import { TokenPropType } from '../../../utils/proptypesModels';

const InputColor = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  cursor: pointer;
  margin:5px 0px;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

class VariableOptions extends Component {
  state = {
    modalOptionVariable: true,
    displayColorPicker: false,
    id: this.props.token.id,
    derivataType: this.props.token.derivataType || undefined,
    background: this.props.token.background,
    seriesType: this.props.token.seriesType || '1',
    totale: this.props.token.totale || false,
    media: this.props.token.media || false,
    massimo: this.props.token.massimo || false,
    minimo: this.props.token.minimo || false,
    physicalQuantity: this.props.token.physicalQuantity || null,
    symbol: this.props.token.symbol || null,
    exactoConversionFactor: this.props.token.exactoConversionFactor || 1,
    axisYlog: this.props.token.axisYlog || false,
  }

  componentDidMount = async () => {
    try {
      const { derivataType, physicalQuantity, symbol } = this.state;
      const { catalogs } = this.props;

      if (physicalQuantity && physicalQuantity === 'custom') {
        this.setState({ units: [{ exactoConversionFactor: 1, symbol }] });
      } else if (derivataType === undefined) {
        /* prendi tutte le units selezionabili a partire dalla physical quantiti della OP */
        const phisicalDetail = catalogs.physicalQuantities.find(f => f.naturalKey === physicalQuantity);
        if (!phisicalDetail) console.error('Non è stata trovata la phisical quantity nel catalogo physicalQuantities per la chiave: '.physicalQuantity);
        else {
          this.setState({ units: phisicalDetail.units.filter(phisicalUnit => getUnitDetail(catalogs, phisicalUnit)).map((phisicalUnit) => {
            const unitDet = getUnitDetail(catalogs, phisicalUnit);
            return ({ exactoConversionFactor: unitDet.exactoConversionFactor, symbol: unitDet.symbol });
          }) });
        }
      } else {
        this.setState({ units: [] });
      }
    } catch (e) {
      this.setState({ units: [] });
    }
  }

  handleClick = () => {
    const { displayColorPicker } = this.state;
    this.setState({ displayColorPicker: !displayColorPicker });
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  }

  handleChange = (color) => {
    this.setState({ background: color.hex });
  }

  handleChangeQuickStats = (attr) => {
    const newVal = {};
    newVal[attr] = !this.state[attr];
    this.setState(newVal);
  }

  handleChangeChartType = (chartType) => {
    this.setState({ seriesType: chartType.target.value });
  }

  handleChangeUnit = (e) => {
    const { units } = this.state;
    const selecetedUnit = units.find(f => f.symbol === e.target.value);
    this.setState({ exactoConversionFactor: selecetedUnit.exactoConversionFactor, symbol: selecetedUnit.symbol });
  }

  submit = () => {
    const { background, seriesType, totale, media, massimo, minimo, physicalQuantity, exactoConversionFactor, symbol, axisYlog } = this.state;
    this.props.token.background = background;
    this.props.token.textcolor = getCorrectTextColor(background);
    this.props.token.seriesType = seriesType;
    this.props.token.totale = totale;
    this.props.token.media = media;
    this.props.token.massimo = massimo;
    this.props.token.minimo = minimo;
    this.props.token.physicalQuantity = physicalQuantity;
    this.props.token.exactoConversionFactor = exactoConversionFactor;
    this.props.token.symbol = symbol;
    this.props.token.axisYlog = axisYlog;
    this.props.dismissAction(true);
  }

  render() {
    const { modalOptionVariable, displayColorPicker, background, seriesType, totale, media, massimo, minimo, axisYlog, units, physicalQuantity, symbol } = this.state;
    const { catalogs } = this.props;

    const InputInnerColor = styled.div`
      width: auto;
      height: 14px;
      border-radius: 2px;
      background: ${background};
    `;

    const SERIES_TYPE = getSeriesType();

    return (
      <div>
        <Modal isOpen={modalOptionVariable}>
          <ModalHeader toggle={() => this.props.dismissAction(false)}><Trans>Seleziona opzioni</Trans></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="pickColor"><Trans>Colore</Trans></Label>
                <InputColor id="pickColor" onClick={this.handleClick}>
                  <InputInnerColor />
                </InputColor>
                { displayColorPicker ? (
                  <PopOver>
                    <Cover onClick={this.handleClose} />
                    <SketchPicker color={background} onChangeComplete={this.handleChange} />
                  </PopOver>
                ) : null
                }
              </FormGroup>

              <FormGroup>
                <Label for="chartType"><Trans>Rappresentazione</Trans></Label>
                <StyledSelect
                  onChange={e => this.setState({ seriesType: e.target.value })}
                  value={seriesType}
                  options={SERIES_TYPE.map(x => ({ value: x.id, label: i18n._(x.label) }))}
                />
              </FormGroup>

              { units && units.length > 0
                && (
                <FormGroup>
                  <Label for="units"><Trans>Unità di misura</Trans></Label>
                  <StyledSelect
                    id="units"
                    value={isDynamicUnit(catalogs, physicalQuantity) ? '' : symbol}
                    onChange={this.handleChangeUnit}
                    options={units.map(x => ({ value: x.symbol, label: x.symbol || 'Auto' }))}
                  />
                </FormGroup>
                )
              }

              <FormGroup tag="fieldset">
                <legend><Trans>Statistiche</Trans></legend>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="totale" checked={totale} onChange={e => this.handleChangeQuickStats('totale', e)} />
                    <Trans>Totale</Trans>
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="media" checked={media} onChange={e => this.handleChangeQuickStats('media', e)} />
                    <Trans>Media</Trans>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="massimo" checked={massimo} onChange={e => this.handleChangeQuickStats('massimo', e)} />
                    <Trans>Massimo</Trans>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="minimo" checked={minimo} onChange={e => this.handleChangeQuickStats('minimo', e)} />
                    <Trans>Minimo</Trans>
                  </Label>
                </FormGroup>
              </FormGroup>

              <FormGroup tag="fieldset">
                <legend><Trans>Assi</Trans></legend>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="axisYlog" checked={axisYlog} onChange={e => this.handleChangeQuickStats('axisYlog', e)} />
                    <Trans>Asse Y logaritmico</Trans>
                  </Label>
                </FormGroup>
              </FormGroup>

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}><Trans>Ok</Trans></Button>
            <Button color="link" onClick={() => this.props.dismissAction(false)}><Trans>Annulla</Trans></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

VariableOptions.propTypes = {
  dismissAction: PropTypes.func.isRequired,
  token: TokenPropType.isRequired,
  catalogs: PropTypes.object.isRequired, // from redux
};

const mapStateToProps = state => ({
  catalogs: state.catalogs,
});

const mapDispatchToProps = dispatch => ({ });


export default connect(mapStateToProps, mapDispatchToProps)(VariableOptions);
