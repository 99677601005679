export const getTimeAsMinutes = (timeString, separator) => {
  const [hours, minutes] = timeString.split(separator);
  return +minutes + 60 * +hours;
};

export const getMinutesAsString = (timeMinutes, separator) => {
  let hours = Math.floor(timeMinutes / 60).toString();
  if (hours.length === 1) {
    hours = `0${hours}`;
  }
  let mins = (timeMinutes % 60).toString();
  if (mins.length === 1) {
    mins = `0${mins}`;
  }
  return `${hours}${separator}${mins}`;
};

export const addMinutesToTimeString = (timeString, minutes, separator) => {
  return getMinutesAsString(getTimeAsMinutes(timeString, separator) + minutes, separator);
};
