import React from 'react';

import Selector from '../Helpers/Selector';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import i18n from 'app/i18n';

const Divider = styled.div`
  clear: both;
  border-bottom: 1px solid #ccc;
`;

const DeleteNote = styled.svg`
  margin-right: 20px;
  margin-top: 15px;
  cursor: pointer;
`;

const VectorSelector = ({ onSelect, firstItem, deleteFirstItem, secondItem, deleteSecondItem, vectorsList }) => {

  const outVectorAvaibles = [];
  const outVectors = firstItem.item.outFlow.vectors;
  outVectors.forEach((vector) => {
    if (vector.vector === undefined || vector.instances === undefined || vector.instances === null || vector.instances > vector.vector.length) {
      outVectorAvaibles.push(vector.id);
    }
  });

  const inVectorAvaibles = [];
  const inVectors = secondItem.item.inFlow.vectors;
  inVectors.forEach((vector) => {
    if (vector.vector === undefined || vector.instances === undefined || vector.instances === null || vector.instances > vector.vector.length) {
      inVectorAvaibles.push(vector.id);
    }
  });

  const items = [];
  vectorsList.forEach((vectorItem) => {
    if (outVectorAvaibles.indexOf(vectorItem.id) >= 0 && inVectorAvaibles.indexOf(vectorItem.id) >= 0) {
      items.push(vectorItem);
    }
  });


  if (items.length === 1) {
    onSelect(items[0]);
  }

  let iFirst = null;
  const productor = firstItem.property === undefined && firstItem.property.productor === undefined ? firstItem.property.productor : null;
  const model = firstItem.property === undefined && firstItem.property.model === undefined ? firstItem.property.model : null;
  if (productor != null && model != null) {
    iFirst = (
      <i>
        {productor}
        &nbsp;-&nbsp;
        {model}
      </i>
    );
  } else if (productor != null) {
    iFirst = (
      <i>
        {productor}
      </i>
    );
  } else if (model != null) {
    iFirst = (
      <i>
        {model}
      </i>
    );
  }

  let iSecond = null;
  const productorSecond = secondItem.property === undefined && secondItem.property.productor === undefined ? secondItem.property.productor:null;
  const modelSecond = secondItem.property === undefined && secondItem.property.model === undefined ? secondItem.property.model:null;
  if (productorSecond != null && modelSecond != null) {
    iSecond = (
      <i>
        {productorSecond}
        &nbsp;-&nbsp;
        {modelSecond}
      </i>
    );
  } else if (productorSecond != null) {
    iSecond = (
      <i>
        {productorSecond}
      </i>
    );
  } else if (modelSecond != null) {
    iSecond = (
      <i>
        {modelSecond}
      </i>
    );
  }

  return (
    <div className="selection show node">
      <div>
        <div style={{ marginLeft: '15px' }}>
          <div style={{ float: 'left' }}>
            <strong><Trans>Primo nodo selezionato</Trans>:</strong>
            <br />
            <span>
              {firstItem.name}
            </span>
            {iFirst}
          </div>
          <div
            style={{ float: 'right' }}
            onClick={() => deleteFirstItem()}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <DeleteNote
              aria-hidden="true"
              data-prefix="far"
              data-icon="times-circle"
              className="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
              />
            </DeleteNote>
          </div>
        </div>
        <Divider />
        <div style={{ marginLeft: '15px' }}>
          <div style={{ float: 'left' }}>
            <strong>
              <Trans>Secondo nodo selezionato</Trans>:&nbsp;
            </strong>
            <br />
            <span>
              {secondItem.name}
            </span>
            {iSecond}
          </div>
          <div
            style={{ float: 'right'}}
            onClick={() => deleteSecondItem()}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <DeleteNote
              aria-hidden="true"
              data-prefix="far"
              data-icon="times-circle"
              className="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
              />
            </DeleteNote>
          </div>
        </div>
        <Divider />
        <div>
          <strong style={{ marginLeft: '15px' }}>
            <Trans>Seleziona il vettore energetico</Trans>
          </strong>
          <Selector
            style={{ paddingTop: '0px' }}
            items={items}
            onMouseIn={() => {}}
            onMouseOut={() => {}}
            onBlur={() => {}}
            onSelect={onSelect}
            searchText={i18n._(t`Cerca il vettore energetico per il link`)}
          />
        </div>
      </div>
    </div>
  );
};

export default VectorSelector;
