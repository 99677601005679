/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faGripVertical, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditInline from './EditInline';

const TabWrapper = styled.div`
  display: flex;
`;

const Tab = styled.div`
  display:inline-block;
  white-space:nowrap;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #2f353a;
  border-color: #c8ced3;  
  padding: 0.5rem 0.5rem;
  background-color: #f0f3f5;
  cursor: pointer;
  user-select: none;

  &.active {
    background-color: #ffffff !important;
    border-bottom: 2px solid ${props => props.theme.common.background.primaryColor};
  }
`;

const NavWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex: 9;
  scroll-behavior: smooth;
`;

const NavStyled = styled.div`
  flex-wrap: initial;
  flex: 15;
`;

const ArrowDiv = styled.div`
  padding:5px 10px;
  text-align: center;
  visibility: hidden;
  &.visible {
    visibility: show;
  }
`;

const Pin = styled(FontAwesomeIcon)`
  &&&& {
    height: 13px;
    width: 13px;
    cursor: pointer;
    margin:0 10px;
    opacity: 0.5;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const PinLocked = styled(FontAwesomeIcon)`
  &&&& {
    height: 13px;
    width: 13px;    
    cursor: pointer;
    margin:0 8px;
    color: #545b62;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  &&&& {
    flex: 1;
    color: #545b62;
    margin:0 8px;
  }
`;

const StyledIconMove = styled(FontAwesomeIcon)`
  &&&& {
    flex: 1;
    color: #000;
    margin:0 3px;
    cursor: move;
    color:#c8ced3;
  }
`;

const NewTab = styled(FontAwesomeIcon)`
  &&&& {
    float:right;
    height: 25px;
    width: 25px;
    margin: 5px 12px;
    cursor: pointer;
    color: #545b62;
  }
`;

class TabSlide extends Component {
  DragHandle = SortableHandle(({ body }) => (body));

  SortableItem = SortableElement(({ tab }) => (
    <Tab className={this.props.activeTab === tab.offlineId ? 'active' : 'inactive'} onClick={() => this.props.toggle(tab.offlineId)}>
      { tab.lock && <PinLocked icon={faThumbtack} onClick={e => this.props.changeLock(e, tab, false)} /> }
      { !tab.lock && <Pin icon={faThumbtack} onClick={e => this.props.changeLock(e, tab, true)} /> }
      <EditInline value={tab.name} onSave={value => this.props.saveNameTab(tab, value)} />
      <StyledIcon icon="times" active="1" onClick={e => this.props.openForDelete(e, tab)} />
      <this.DragHandle body={(<StyledIconMove icon={faGripVertical} active="1" />)} />
    </Tab>
  ));

  SortableList = SortableContainer(({ items }) => (
    <NavStyled>
      {items.map((tab, index) => (
        <this.SortableItem key={tab.offlineId} index={index} tab={tab} />
      ))}
    </NavStyled>
  ));


  constructor(props) {
    super(props);

    this.state = {
      showLeftArrow: false,
      showRigthArrow: false,
    };

    this.myRef = React.createRef(); // Create a ref
  }

  componentDidMount() {
    this.checkArrowVissibility();
  }

  componentDidUpdate() {
    this.checkArrowVissibility();
  }

  checkArrowVissibility = () => {
    const { showLeftArrow, showRigthArrow } = this.state;
    if (this.myRef.current) {
      const newShowLeftArrow = this.myRef.current.scrollLeft > 0;
      const newShowRigthArrow = this.myRef.current.scrollLeft + this.myRef.current.offsetWidth < this.myRef.current.scrollWidth;
      if (newShowLeftArrow !== showLeftArrow || newShowRigthArrow !== showRigthArrow) {
        this.setState({ showLeftArrow: newShowLeftArrow, showRigthArrow: newShowRigthArrow });
      }
    }
  }

  scrollTab = (direction) => {
    if (direction === 'add') {
      this.myRef.current.scrollLeft = this.myRef.current.scrollWidth;
    } else if (direction === '>') {
      this.myRef.current.scrollLeft = this.myRef.current.scrollLeft + ((this.myRef.current.offsetWidth * 30) / 100);
    } else {
      this.myRef.current.scrollLeft = this.myRef.current.scrollLeft - ((this.myRef.current.offsetWidth * 30) / 100);
    }
    setTimeout(() => this.checkArrowVissibility(), 500);
  }

  render() {
    const { showLeftArrow, showRigthArrow } = this.state;

    return (
      <TabWrapper>
        <ArrowDiv className={showLeftArrow ? 'visible' : null}><Button color="primary" size="sm" onClick={() => this.scrollTab('<')}>&lt;</Button></ArrowDiv>
        <NewTab icon={faPlus} active="1" onClick={() => { this.props.addTab(); setTimeout(() => this.scrollTab('add'), 500); }} />
        <NavWrapper ref={this.myRef}>
          <this.SortableList useDragHandle axis="x" lockAxis="x" items={this.props.items} onSortEnd={this.props.onSortEnd} />
        </NavWrapper>
        <ArrowDiv className={showRigthArrow ? 'visible' : null}><Button color="primary" size="sm" onClick={() => this.scrollTab('>')}>&gt;</Button></ArrowDiv>
      </TabWrapper>
    );
  }
}

TabSlide.propTypes = {
  items: PropTypes.array.isRequired,
  changeLock: PropTypes.func.isRequired,
  openForDelete: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  saveNameTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  addTab: PropTypes.func.isRequired,
};

TabSlide.defaultProps = {
};


export default TabSlide;
