import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { toast } from 'react-toastify'
import styled from 'styled-components';
import moment from 'moment';
import { DynamicForm, Button } from 'app/common';
import {
  getIntervalOptions as getIntervals,
  getAggregationOptions,
  getPeriodValues
} from '../common';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const Container = styled.div`
  background-color: #fff;
  min-height: 25rem;
  max-height: 250rem;
  position: relative;
  overflow-x: hidden;

  ${props => props.scroll && `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props.theme.navbar.borderColor};
    }
  `}
`;

class PeriodSelectorMulti extends Component {
  constructor(props) {
    super(props);

    this.maxPeriods = 18;

    this.state = {
      forms: [],
      error: null,
      intervalColumnSize: 5,
      periodColumnSize: 5,
      aggregationColumnSize: 5
    };

    this.currentModule = props.currentModule;

    this.setupAggregations = this.setupAggregations.bind(this);
    this.getForm = this.getForm.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.getValues = this.getValues.bind(this);
  }

  componentDidMount() {
    const { period, periodCompetence } = this.props;
    const rows = (period && period.rows) ? [...period.rows] : [];

    if (rows && rows.length > 0) {
      // Get existing rows and re-create forms
      const startingForms = [];
      rows.map((row, index) => {
        const frm = this.getForm(rows.length - 1, row.interval, row.period, row.aggregation, index, row.periodValue);
        startingForms.push(frm);
        return null;
      });

      const isFirstFormWithDate = (rows[0].interval === 'from' && rows.length === 1);

      this.setState({
        forms: startingForms,
        intervalColumnSize: (isFirstFormWithDate) ? 4 : 5,
        periodColumnSize: (isFirstFormWithDate) ? 3 : 5,
        aggregationColumnSize: (isFirstFormWithDate) ? 3 : 5,
      });
    } else {
      // Add 1 new form
      const firstForm = this.getForm(1, periodCompetence, null, null, 1);
      this.setState({ forms: [firstForm] });
    }
  }

  setupAggregations(periodInterval) {
    const { widgetType, valueTypes } = this.props;
    return getAggregationOptions(widgetType, valueTypes, periodInterval);
  }

  getForm(rowsLength, interval, period, aggregation, rowIndex, periodValue) {
    const { widgetType } = this.props;

    // Period Intervals
    const intervals = getIntervals(widgetType, this.currentModule);
    const currentInterval = (interval) ? interval : intervals.defaultValue;

    // Aggregations
    const aggregations = this.setupAggregations(currentInterval);

    // Check existing values, otherwise set default values
    let newForm = {
      ref: React.createRef(),
      isPeriodDateHidden: (currentInterval !== 'from'),
      // isAggregationHidden: (rowsLength > 1),
      // isEmptyColumnHidden: ((currentInterval !== 'from' && rowsLength <= 1) || (currentInterval === 'from' && rowsLength > 0)),
      interval: { items: intervals.items },
      aggregation: { items: aggregations.items },
      // periodValues: { items: periodValues.items },
      initialValues: {
        interval: currentInterval,
        period: (period) ? moment(period) : null,
        aggregation: (aggregation) ? aggregation : aggregations.defaultValue
      }
    };

      const aggreg = aggregations.items.find(a => {
        const aggVal = aggregation || aggregations.defaultValue;
        return a.value === aggVal;
      });
      const periodValues = getPeriodValues(aggreg.label);
      let periodValInitial;
      if (rowsLength > 1) {
        periodValInitial = 'period';
      } else {
        periodValInitial = periodValue || periodValues.defaultValue;
      }
      newForm = { ...newForm, periodValues: { items: periodValues.items }, initialValues: { ...newForm.initialValues, periodValue: periodValInitial } }

    return newForm;
  }

  addRow() {
    const { forms } = this.state;
    if (forms.length < 4) {
      const { periodCompetence } = this.props;

      const newForm = this.getForm(forms.length + 1, periodCompetence, null, null, forms.length + 1);

      // const firstFormValues = forms[0].ref.current.getValues();

      // forms[0].isAggregationHidden = true;
      // if (firstFormValues.interval === 'from') {
      // forms[0].isPeriodDateHidden = false;
      // forms[0].isEmptyColumnHidden = true;
      // } 
      // else {
      // forms[0].isEmptyColumnHidden = false;
      // }

      this.setState({
        forms: [...forms, newForm],
        intervalColumnSize: 5,
        periodColumnSize: 5,
        aggregationColumnSize: 5,
      });
    } else {
      toast.warn(i18n._(t`E' possibile aggiungere al massimo 4 periodi`));
    }
  }

  deleteRow(event, index) {
    const { forms } = this.state;

    forms.splice(index, 1);

    // const firstFormValues = forms[0].ref.current.getValues();
    // const mustResizeColumn = (firstFormValues && firstFormValues.interval === 'from' && forms.length === 1);

    // if (forms.length <= 1) {
    // forms[0].isAggregationHidden = false;
    // forms[0].isEmptyColumnHidden = true;
    // Show date if the first value is from
    //   if (firstFormValues.interval === 'from') {
    //     forms[0].isPeriodDateHidden = false;
    //   }
    // }

    this.setState({
      forms: forms,
      // intervalColumnSize: (mustResizeColumn) ? 4 : 5,
      // periodColumnSize: (mustResizeColumn) ? 3 : 5,
      // aggregationColumnSize: (mustResizeColumn) ? 3 : 5,
    });
  }

  getValues() {
    const { forms } = this.state;

    const formValues = forms.map((form, i) => {
      // TODO validate form anyway: form.ref.current.validateAndGetValues(values);
      let values = form.ref.current.getValues();
      // Delete useless fields
      delete values.buttons;
      delete values.empty_column;
      if (i !== forms.length - 1) {
        delete values.periodValue;
      }

      if (values.period instanceof moment) {
        values.period = values.period.toISOString();
      }

      return values;
    });

    return { rows: formValues };
  }

  render() {
    const { scroll } = this.props;
    const {
      forms, error
    } = this.state;

    return (
      <Container scroll={scroll}>
        { forms.map((form, index, allForms) => {
          const fields = [
            {
              name: 'interval',
              type: 'select',
              label: <Trans>Periodo</Trans>,
              xs: 4,
              validation: { required: true },
              nullable: false,
              canSearch: false,
              disabled: false,
              onChange: (value, currentFormValues) => {
                const aggregations = this.setupAggregations(value);
                // const isNotFrom = (value !== 'from');
                const defaultPeriodValue = (value === 'from') ? moment() : null;

                let allForms = [...forms];
                let currentForm = { ...allForms[index] };
                // currentForm.isPeriodDateHidden = isNotFrom;
                // currentForm.isEmptyColumnHidden = (value === 'from');
                currentForm.aggregation = {
                  items: aggregations.items
                };
                currentForm.initialValues.period = defaultPeriodValue;
                currentForm.initialValues.aggregation = aggregations.defaultValue;
                allForms[index] = currentForm;

                // Override current aggregation form value (otherwise the default value does not change)
                currentFormValues.aggregation = aggregations.defaultValue;
                currentFormValues.period = defaultPeriodValue;

                const aggreg = currentForm.aggregation.items.find(a => a.value === currentForm.initialValues.aggregation);
                currentForm.periodValues = getPeriodValues(aggreg.label);

                // Adjust column sizes and update state
                if (value === 'from') {
                  // allForms[0].isAggregationHidden = false;
                  // allForms[0].isPeriodDateHidden = false;
                  // allForms[0].isEmptyColumnHidden = true;
                  this.setState({
                    forms: allForms,
                    intervalColumnSize: 4,
                    periodColumnSize: 3,
                    aggregationColumnSize: 3,
                  });
                }
                // else if (value === 'from' && allForms.length > 1) {
                //   this.setState({
                //     forms: allForms,
                //     intervalColumnSize: 5,
                //     periodColumnSize: 5,
                //     aggregationColumnSize: 5,
                //   });
                // } 
                // else if (value !== 'from' && allForms.length === 1) {
                // allForms[0].isAggregationHidden = false;
                // allForms[0].isPeriodDateHidden = true;
                // allForms[0].isEmptyColumnHidden = true;
                // this.setState({
                //   forms: allForms,
                // intervalColumnSize: 5,
                // periodColumnSize: 5,
                // aggregationColumnSize: 5,
                // });
                // } else if (value !== 'from' && allForms.length > 1) {
                //   this.setState({
                //     forms: allForms,
                //     intervalColumnSize: 5,
                //     periodColumnSize: 5,
                //     aggregationColumnSize: 5,
                //   });
                // } 
                else {
                  this.setState({
                    forms: allForms,
                    intervalColumnSize: 5,
                    periodColumnSize: 5,
                    aggregationColumnSize: 5,
                  });
                }

              },
              options: form.interval.items,
            },
            {
              name: 'period',
              type: 'date',
              xs: 3,
              label: <Trans>Data</Trans>,
              hidden: (values) => values.interval !== 'from',
              props: {
                lang: 'it',
                showTime: false,
                showDateInput: true,
                disabled: false
              }
            },
            {
              name: 'aggregation',
              type: 'select',
              label: <Trans>Granularità</Trans>,
              // hidden: form.isAggregationHidden,
              xs: 3,
              validation: { required: true },
              nullable: false,
              canSearch: false,
              disabled: false,
              options: form.aggregation.items,
              onChange: (value) => {
                let allForms = [...forms];
                let currentForm = { ...forms[index] };
                const aggreg = currentForm.aggregation.items.find(a => a.value === value);
                currentForm.periodValues = getPeriodValues(aggreg.label);
                allForms[index] = currentForm;
                this.setState({ forms: allForms });
              }
            },
            {
              name: 'empty_column',
              type: 'custom',
              hidden: (values) => values.interval === 'from',
              xs: 3,
              render: () => (<div></div>)
            },
            {
              name: 'buttons',
              type: 'custom',
              xs: 2,
              render: () => (
                <div>
                  { /* Add button */}
                  <Button color="primary" onClick={this.addRow}>+</Button>
                  { /* Delete button */}
                  { forms.length > 1 &&
                    <>
                      &nbsp;
                      <Button color="danger" onClick={(e) => { this.deleteRow(e, index); }}>X</Button>
                    </>
                  }
                </div>
              )
            },
            {
              name: 'periodValue',
              type: 'select',
              label: <Trans>Valori da mostrare nel periodo selezionato</Trans>,
              hidden: index !== forms.length - 1,
              xs: 12,
              validation: { required: index === forms.length - 1 },
              disabled: (val) => allForms.length > 1,
              options: index !== forms.length - 1 || !form.periodValues ? [] : form.periodValues.items,
            }
          ];

          return (
            <DynamicForm
              key={`form-${index}`}
              ref={form.ref}
              hideButtons
              hideErrors
              fields={fields}
              initialValues={form.initialValues}
              error={error}
              readOnly={false}
              hideRequiredLabel={true}
            />
          )
        })}
      </Container>
    );
  }
}

PeriodSelectorMulti.propTypes = {
  rows: PropTypes.array,
  widgetType: PropTypes.string,
  currentModule: PropTypes.string,
};

export default PeriodSelectorMulti;
