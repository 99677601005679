import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import { Row, Col } from 'reactstrap';

import { Select, MultiSelectTag, Spinner } from 'app/common';
import AlarmsNodesTree from 'app/common/OPSelector/AlarmsNodesTree';

const Container = styled.div`
    padding: 1rem;
`;
const Title = styled.div`
    font-weight: 700;
`;
const StyledSelect = styled(Select)`
    margin: 0.2rem 0;
    max-height: 2rem;
`;
const ColLinks = styled(Col)`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    font-style: italic;
`;
const StyledSpinner = styled(Spinner)`
    z-index: 2;
`;

const AlarmsTree = props => {

    const { type, id, onPeriodChange, onSeverityChange, onTagsChange, selectedSeverity, selectedPeriod, companies, sites, sitegroups,
        selectedTags, severities, periods, tags, selectedDomain, count, structure, openNode, openNodes, loading, onLink } = props;

    return <>
        {loading && <StyledSpinner />}
        <Container>
            <Row>
                <Col md="6">
                    <Title>
                        <Trans>Riepilogo allarmi attivi</Trans>
                    </Title>
                </Col>
                <Col md="6">
                    <StyledSelect
                        name='period'
                        options={periods}
                        value={selectedPeriod}
                        onChange={event => event.target.value !== selectedPeriod && onPeriodChange(event.target.value)} />
                </Col>
                <Col md="12">
                    <StyledSelect
                        options={severities}
                        value={selectedSeverity}
                        onChange={event => event.target.value !== selectedSeverity && onSeverityChange(event.target.value)} />
                </Col>
                <Col md="12">
                    <MultiSelectTag
                        placeholder={i18n._(t`Eventi con qualsiasi tag`)}
                        options={tags}
                        value={selectedTags}
                        keyProperty="value"
                        labelProperty="label"
                        onChange={event => JSON.stringify(event.target.value) !== JSON.stringify(selectedTags) && onTagsChange(event.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    {selectedDomain && companies && sites && sitegroups &&
                        <AlarmsNodesTree
                            scroll
                            count={count}
                            structure={structure}
                            openNode={openNode}
                            openNodes={openNodes}
                            onLink={onLink}
                        />}
                </Col>
            </Row>
            <Row>
                <ColLinks>
                    <Link to={selectedDomain && `/domains/${selectedDomain.id}/alarms/log`} onClick={onLink}><Trans>Tutti gli eventi</Trans></Link>
                    <Link to={type && id && `/${type}/${id}/config/channels`} onClick={() => onLink()}><Trans>Configurazione notifiche</Trans></Link>
                </ColLinks>
            </Row>
        </Container>
    </>
}

export default AlarmsTree;