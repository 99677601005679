import { t } from '@lingui/macro';
import i18n from 'app/i18n';

export const getTranslatedString = (paramType, key, keyParent) => {
  if (paramType === 'staticParameters') {
    if (staticParametersNames[key] === undefined) {
      return key;
    }
    return i18n._(staticParametersNames[key]);
  }
  if (paramType === 'aggregationRate') {
    if (aggregationRates[key] === undefined) {
      return key;
    }
    return i18n._(aggregationRates[key]);
  }
  if (paramType === 'values') {
    if (valuesNames[keyParent] === undefined || valuesNames[keyParent][key] === undefined) {
      return key && !isNaN(key) ? parseFloat(key).toLocaleString() : key;
    }
    return i18n._(valuesNames[keyParent][key].id);
  }
  if (paramType === 'asset') {
    if (assetNames[key] === undefined) {
      return key;
    }
    return i18n._(assetNames[key]);
  }
  if (paramType === 'dinamycParameters' || paramType === 'dynamicParameters') {
    if (dinamycParametersNames[key] === undefined) {
      return key;
    }
    return i18n._(dinamycParametersNames[key]);
  }
  if (paramType === 'otherStaticParameters') {
    if (otherStaticParametersNames[key] === undefined) {
      return key;
    }
    return i18n._(otherStaticParametersNames[key]);
  }

  if (paramType === 'option') {
    if (optionsNames[key] === undefined) {
      return key;
    }
    return i18n._(optionsNames[key]);
  }

  return key;
};

export const staticParametersNames = {
  'codice-identificativo-pod': t`Codice identificativo POD`,
  'tipologia-di-contatore': t`Tipologia di contatore`,
  'tipo-di-fornitura': t`Tipo di fornitura`,
  'potenza-contrattuale-impegnata': t`Potenza contrattuale impegnata`,
  'codice-identificativo-pdr': t`Codice identificativo PDR`,
  'portata-permanente-classe-g': t`Portata permanente (Classe G)`,
  'portata-volumetrica-nominale': t`Portata volumetrica nominale`,
  'portata-massica-nominale': t`Portata massica nominale`,
  'portata-permanente-q3': t`Portata permanente (Q3)`,
  'campo-di-misura-q3q1': t`Campo di misura (Q3/Q1)`,
  // 'numero-moduli': t`Numero moduli`
  'potenza-massima-nominale': t`Potenza massima nominale`,
  'coefficiente-di-deriva-termica-della-potenza': t`Coefficiente di deriva termica della potenza (sopra i 25 °C)`,
  'superficie-netta': t`Superficie netta dei pannelli`,
  'tipologia-di-collettore': t`Tipologia di collettore`,
  'numero-collettori': t`Numero collettori`,
  'superficie-captante-netta-singolo-modulo': t`Superficie captante netta (singolo modulo)`,
  'uso-finale-del-calore-prodotto': t`Uso finale del calore prodotto`,
  'potenza-nominale': t`Potenza nominale`,
  'perdite-a-vuoto': t`Perdite a vuoto`,
  'perdite-a-carico': t`Perdite a carico`,
  'tensione-nominale-primaria': t`Tensione nominale primaria`,
  'tensione-nominale-secondaria-a-vuoto': t`Tensione nominale secondaria a vuoto`,
  'potenza-nominale-taglia': t`Potenza nominale (taglia)`,
  'potenza-attiva-nominale-in-uscita': t`Potenza attiva nominale in uscita`,
  'rendimento-nominale-': t`Rendimento nominale %`,
  'pressione-max-di-esercizio': t`Pressione max. di esercizio`,
  'temperatura-min-di-esercizio': t`Temperatura min. di esercizio`,
  'temperatura-max-di-esercizio': t`Temperatura max. di esercizio`,
  'capacita-di-stoccaggio': t`Capacità di stoccaggio`,
  'potenza-termica-nominale-in-assorbimento': t`Potenza termica nominale in assorbimento`,
  'potenza-termica-nominale-di-erogazione': t`Potenza termica nominale di erogazione`,
  'coefficiente-di-perdita-del-serbatoio': t`Coefficiente di perdita del serbatoio`,
  'coefficiente-di-perdita-in-ingresso-assorbimento': t`Coefficiente di perdita in ingresso (assorbimento)`,
  'coefficiente-di-perdita-in-uscita-erogazione': t`Coefficiente di perdita in uscita (erogazione)`,
  'portata-nominale-in-assorbimento': t`Portata nominale in assorbimento`,
  'portata-nominale-di-erogazione': t`Portata nominale di erogazione`,
  'capacita-di-accumulo-utilizzabile': t`Capacità di accumulo utilizzabile (energia max.)`,
  'livello-energetico-minimo-da-mantenere': t`Livello energetico minimo da mantenere (energia min.)`,
  'tensione-nominale': t`Tensione nominale in CC`,
  'potenza-nominale-in-carica': t`Potenza nominale in carica`,
  'potenza-nominale-in-scarica': t`Potenza nominale in scarica`,
  'efficienza-in-carica': t`Efficienza in carica`,
  'efficienza-in-scarica': t`Efficienza in scarica`,
  'perdite-in-stand-by': t`Perdite in stand-by`,
  'vita-utile-cicli-di-ricarica': t`Vita utile (cicli di ricarica)`,
  'tipologia-di-pompa-di-calore': t`Tipologia di pompa di calore`,
  reversibilita: t`Reversibilità`,
  'distribuzione-caldofreddo-tipologia-di-terminali': t`Distribuzione caldo/freddo (tipologia di terminali)`,
  'uso-finale-del-freddo-prodotto': t`Uso finale del freddo prodotto`,
  'potenza-termica-nominale-in-raffrescamento': t`Potenza termica nominale in raffrescamento`,
  'eer-nominale': t`EER nominale`,
  U_W: t`EER nominale`,
  'potenza-termica-nominale-in-riscaldamento': t`Potenza termica nominale in riscaldamento`,
  'cop-nominale': t`COP nominale`,
  'tipologia-di-uta': t`Tipologia di UTA`,
  'tipologia-di-terminali-aria-primaria': t`Tipologia di terminali (aria primaria)`,
  'potenza-el-nominale-totale-assorbita-dalla-uta': t`Potenza el. nominale totale (assorbita dalla UTA)`,
  'potenza-el-nominale-dei-ventilatori': t`Potenza el. nominale dei ventilatori`,
  'portata-daria-nominale-dei-ventilatori': t`Portata d’aria nominale dei ventilatori`,
  'tipologia-di-cogeneratore': t`Tipologia di cogeneratore`,
  'potenza-el-di-minimo-tecnico-pmin-alla-t-di-riferimento': t`Potenza el. di minimo tecnico (Pmin) alla T di riferimento`,
  W_min: t`Potenza el. di minimo tecnico (Pmin) alla T di riferimento`,
  'potenza-el-massima-erogabile-pmax-alla-t-di-riferimento': t`Potenza el. massima erogabile (Pmax) alla T di riferimento`,
  W_max: t`Potenza el. massima erogabile (Pmax) alla T di riferimento`,
  'temperatura-di-riferimento-per-pmin-e-pmax': t`Temperatura di riferimento per Pmin e Pmax`,
  T_max: t`Temperatura di riferimento per Pmin e Pmax`,
  'temperatura-di-riferimento-per-la-curva-di-consumo': t`Temperatura di riferimento per la curva di consumo`,
  T_ref: t`Temperatura di riferimento per la curva di consumo`,
  'sensitivita-di-pmax-e-pmin-alla-temperatura': t`Sensitività di Pmax e Pmin alla temperatura`,
  T_sens_pot: t`Sensitività di Pmax e Pmin alla temperatura`,
  'sensitivita-della-curva-di-consumo-alla-temperatura': t`Sensitività della curva di consumo alla temperatura`,
  T_sens_rend: t`Sensitività della curva di consumo alla temperatura`,
  'emissioni-co2': t`Emissioni CO2`,
  eta_CO2: t`Emissioni CO2`,
  'coefficienti-della-curva-di-consumo-combustibile': t`Coefficienti della curva di consumo combustibile`,
  'coefficienti-della-curva-di-recupero-calore': t`Coefficienti della curva di recupero calore`,
  'tipologia-di-generatore-elettrico': t`Tipologia di generatore elettrico`,
  'potenza-el-nominale-prodotta-lorda': t`Potenza el. nominale prodotta (lorda)`,
  'tipologia-di-caldaia': t`Tipologia di caldaia`,
  'distribuzione-del-calore-tipologia-di-terminali': t`Distribuzione del calore (tipologia di terminali)`,
  'potenza-el-nominale-assorbita': t`Potenza el. nominale assorbita`,
  'potenza-termica-minima-producibilita-min': t`Potenza termica minima (Producibilità min.)`,
  Q_min: t`Potenza termica minima (Producibilità min.)`,
  'potenza-termica-massima-producibilita-max': t`Potenza termica massima (Producibilità max.)`,
  Q_max_2: t`Potenza termica massima (Producibilità max.)`,
  'potenza-termica-nominale': t`Potenza termica nominale`,
  Q_max_boiler: t`Potenza termica nominale`,
  'tipologia-di-macchina-frigorifera': t`Tipologia di macchina frigorifera`,
  'tipologia-di-condensazione': t`Tipologia di condensazione`,
  'fluido-di-lavoro-distribuzione-freddo-alle-utenze': t`Fluido di lavoro (distribuzione freddo alle utenze)`,
  'distribuzione-del-freddo-tipologia-di-terminali': t`Distribuzione del freddo (tipologia di terminali)`,
  'numero-di-compressori': t`Numero di compressori`,
  'tipologia-di-compressori': t`Tipologia di compressori`,
  'tipo-di-regolazione-dei-compressori': t`Tipo di regolazione dei compressori`,
  'temperatura-di-condensazione-nominale': t`Temperatura di condensazione nominale`,
  'temperatura-di-mandata-nominale': t`Temperatura di mandata nominale`,
  'potenza-frigorifera-nominale': t`Potenza frigorifera nominale`,
  Q_max_chiller: t`Potenza frigorifera nominale`,
  // 'efficienza-assorbimento-termico-se-applicabile': t`Efficienza assorbimento termico`,
  U_H: t`Efficienza assorbimento termico`,
  'tipologia-di-compressore': t`Tipologia di compressore`,
  'classe-di-qualita-particelle-solide': t`Classe di qualità particelle solide`,
  'classe-di-qualita-umidita': t`Classe di qualità umidità`,
  'classe-di-qualita-contenuto-dolio': t`Classe di qualità contenuto d’olio`,
  'potenza-elettrica-nominale': t`Potenza elettrica nominale`,
  'pressione-nominale': t`Pressione nominale`,
  nominale: t`nominale`,
  'portata-nominale': t`nominale`,
  'tipo-di-lampade': t`Tipo di lampade`,
  'potenza-nominale-complessiva': t`Potenza nominale complessiva`,
  'tipologia-di-terminali': t`Tipologia di terminali`,
  'portata-nominale': t`Portata nominale`,
  'tipologia-di-ventilatore': t`Tipologia di ventilatore`,
  'classe-di-efficienza-del-motore-elettrico': t`Classe di efficienza del motore elettrico`,
  'meccanismo-di-regolazione': t`Meccanismo di regolazione`,
  'salto-di-pressione-nominale': t`Salto di pressione nominale`,
  'tipologia-di-pompa': t`Tipologia di pompa`,
  'classe-di-efficienza-del-motore': t`Classe di efficienza del motore`,
  'prevalenza-nominale': t`Prevalenza nominale`,
  'meccanismo-di-regolazione-del-motore': t`Meccanismo di regolazione del motore`,
  'velocita-nominale-numero-di-poli': t`Velocità nominale - numero di poli`,
  'numero-di-riavvolgimenti': t`Numero di riavvolgimenti`,
  'tipo-di-impianto': t`Tipo di impianto`,
  'a0-termine-costante': t`a0 - termine costante`,
  'a1-termine-noto': t`a1 - termine noto`,
  'a2-termine-quadratico': t`a2 - termine quadratico`,
  'b0-termine-costante': t`b0 - termine costante`,
  'b1-termine-noto': t`b1 - termine noto`,
  'b2-termine-quadratico': t`b2 - termine quadratico`,
  a0: t`a0 - termine costante`,
  a1: t`a1 - termine noto`,
  a2: t`a2 - termine quadratico`,
  b0: t`b0 - termine costante`,
  b1: t`b1 - termine noto`,
  b2: t`b2 - termine quadratico`,
  W_nominal_abs_aux: t`W_nominal_abs_aux`,
  'potenza-nominale-min-garantita-al-collaudo': t`Potenza nominale min. garantita al collaudo`,
  'coefficiente-di-decadimento-del-rendimento-1-anno': t`Coeff. di decadimento del rendimento (1° anno)`,
  'coefficiente-di-decadimento-del-rendimento-anni-successivi': t`Coeff. di decadimento del rendimento (anni successivi)`,
  'coefficiente-perdite-di-rete-per-conversione-da-CC-a-CA': t`Coeff. perdite di rete per conversione da CC a CA`,
  'temperatura-operativa-nominale-delle-celle': t`Temperatura operativa nominale delle celle (NOCT)`,
  'inclinazione-pannelli': t`Inclinazione pannelli (rispetto al piano orizzontale)`,
  'latitudine-sito': t`Latitudine del sito di installazione dell’impianto`,
  'irraggiamento-solare-minimo-producibilità-pannelli': t`Irraggiamento solare minimo per producibilità dei pannelli`,
  'tipologia-di-inverter': t`Tipologia di inverter`,
  'tipologia-di-linea-elettrica': t`Tipologia di linea elettrica`,
  'tensione-di-ingresso-min-in-cc-da-fotovoltaico': t`Tensione di ingresso min. in CC (da fotovoltaico)`,
  'tensione-di-ingresso-max-in-cc-da-fotovoltaico': t`Tensione di ingresso max. in CC (da fotovoltaico)`,
  'corrente-di-ingresso-max-in-cc-da-fotovoltaico': t`Corrente di ingresso max. in CC (da fotovoltaico)`,
  'tensione-di-ingresso-min-in-cc-da-sistema-di-accumulo': t`Tensione di ingresso in CC (da sistema di accumulo)`,
  'corrente-di-scarica-in-cc-da-sistema-di-accumulo': t`Corrente di scarica in CC (da sistema di accumulo)`,
  'potenza-nominale-in-ca-in': t`Potenza nominale in ingresso in CA`,
  'corrente-di-carica-in-cc-verso-sistema-di-accumulo': t`Corrente di carica in CC (verso sistema di accumulo)`,
  'potenza-nominale-in-ca-out': t`Potenza nominale in uscita in CA`,
  'efficienza-percent-massima': t`Efficienza % massima`,
  'efficienza-percent-pesata': t`Efficienza % pesata (EURO/CEC)`,
  'potenza-elettrica-nominale-assorbita-dagli-ausiliari': t`Potenza el. nominale assorbita dagli ausiliari`,
  'numero-linee-produttive': t`Numero di linee produttive`,
  'tipologia-di-linea-elettrica-stazione-ricarica': t`Tipologia di linea elettrica (alimentazione)`,
  'modalita-di-ricarica': t`Modalità di ricarica`,
  'potenza-massima-erogabile': t`Potenza massima erogabile`,
  'numero-prese-ricarica': t`Numero di prese`,
  'dati-ingresso-in-cc': t`Dati ingresso in CC`,
  'dati-ingresso-in-ca': t`Dati ingresso in CA`,
  'dati-uscita-in-cc': t`Dati uscita in CC`,
  'dati-uscita-in-ca': t`Dati uscita in CA`,
  'efficienza': t`Efficienza`,
};

export const valuesNames = {
  'tipologia-di-contatore': {
    monofase: t`Monofase`,
    trifase: t`Trifase`
  },
  'tipo-di-fornitura': {
    bt: t`BT`,
    mt: t`MT`,
    at: t`AT`
  },
  'tipologia-di-collettore': {
    'a-tubi-sottovuoto-assorbitore-piano': t`A tubi sottovuoto (assorbitore piano)`,
    'a-tubi-sottovuoto-assorbitore-circolare': t`A tubi sottovuoto (assorbitore circolare)`,
    'a-piani-vetrati': t`A piani vetrati`,
    'non-vetrati': t`Non vetrati`
  },
  'tipologia-di-pompa-di-calore': {
    'aria--acqua': t`Aria / Acqua`,
    'acqua--acqua-geotermica': t`Acqua / Acqua Geotermica`,
    'aria--aria': t`Aria / Aria`
  },
  reversibilita: {
    reversibile: t`Reversibile`,
    'non-reversibile': t`Non reversibile`
  },
  'tipologia-di-uta': {
    'aria-primaria-misto-aria--acqua': t`Aria primaria (misto aria / acqua)`,
    'aria-primaria-misto-aria-acqua': t`Aria primaria (misto aria / acqua)`,
    tuttaria: t`Tutt'aria`
  },
  'tipologia-di-terminali-aria-primaria': {
    'ventilconvettori-fan-coil': t`Ventilconvettori (fan coil)`,
    'travi-calde--fredde': t`Travi calde / fredde`,
    'travi-calde-fredde': t`Travi calde / fredde`
  },
  'tipologia-di-cogeneratore': {
    'turbina-a-gas-a-ciclo-combinato': t`Turbina a gas a ciclo combinato`,
    'turbina-a-vapore-a-contropressione': t`Turbina a vapore a contropressione`,
    'turbina-di-condensazione-a-estrazione-di-vapore': t`Turbina di condensazione a estrazione di vapore`,
    'turbina-a-gas': t`Turbina a gas`,
    microturbina: t`Microturbina`,
    'motore-a-combustione-interna': t`Motore a combustione interna`,
    'motore-stirling': t`Motore Stirling`,
    'pile-a-combustibile': t`Pile a combustibile`,
    orc: t`ORC`
  },
  'coefficienti-della-curva-di-consumo-combustibile': {
    'a0-termine-costante': t`a0 - termine costante`,
    'a1-termine-noto': t`a1 - termine noto`,
    'a2-termine-quadratico': t`a2 - termine quadratico`,
    a0: t`a0 - termine costante`,
    a1: t`a1 - termine noto`,
    a2: t`a2 - termine quadratico`
  },
  'coefficienti-della-curva-di-recupero-calore': {
    'b0-termine-costante': t`b0 - termine costante`,
    'b1-termine-noto': t`b1 - termine noto`,
    'b2-termine-quadratico': t`b2 - termine quadratico`,
    b0: t`b0 - termine costante`,
    b1: t`b1 - termine noto`,
    b2: t`b2 - termine quadratico`
  },
  'tipologia-di-generatore-elettrico': {
    'turbina-a-gas-a-ciclo-combinato': t`Turbina a gas a ciclo combinato`,
    'turbina-a-vapore-a-contropressione': t`Turbina a vapore a contropressione`,
    'turbina-di-condensazione-a-estrazione-di-vapore': t`Turbina di condensazione a estrazione di vapore`,
    'turbina-a-gas': t`Turbina a gas`,
    microturbina: t`Microturbina`,
    'motore-a-combustione-interna': t`Motore a combustione interna`,
    'motore-stirling': t`Motore Stirling`,
    'pile-a-combustibile': t`Pile a combustibile`,
    orc: t`ORC`
  },
  'tipologia-di-caldaia': {
    'a-condensazione': t`A condensazione`,
    'standard-non-a-condensazione': t`Standard (non a condensazione)`
  },
  'tipologia-di-macchina-frigorifera': {
    'a-compressione': t`A compressione`,
    'ad-assorbimento': t`Ad assorbimento`,
    'a-espansione-diretta': t`A espansione diretta`
  },
  'tipologia-di-condensazione': {
    'ad-aria': t`Ad aria`,
    'in-torre-evaporativa-chiusa': t`In torre evaporativa chiusa`,
    'in-torre-evaporativa-aperta': t`In torre evaporativa aperta`,
    'ad-acqua': t`Ad acqua`
  },
  'fluido-di-lavoro-distribuzione-freddo-alle-utenze': {
    acqua: t`Acqua`,
    'acqua-glicolata': t`Acqua glicolata`
  },
  'tipologia-di-compressori': {
    'a-vite': t`A vite`,
    scroll: t`Scroll`,
    centrifughi: t`Centrifughi`
  },
  'tipo-di-regolazione-dei-compressori': {
    'vsd-inverter': t`VSD (inverter)`,
    'on--off': t`On / off`,
    'on-off': t`On / off`,
    'regolazione-a-gradini': t`Regolazione a gradini`
  },
  'tipologia-di-compressore': {
    'alternativo-a-stantuffo': t`Alternativo a stantuffo`,
    'rotativo-a-vite': t`Rotativo a vite`,
    'rotativo-a-palette': t`Rotativo a palette`,
    'altra-tipologia': t`Altra tipologia`
  },
  'tipo-di-lampade': {
    incandescenza: t`Incandescenza`,
    alogene: t`Alogene`,
    'alogene-migliorate': t`Alogene migliorate`,
    fluorescenti: t`Fluorescenti`,
    led: t`LED`
  },
  'tipologia-di-terminali': {
    radiatori: t`Radiatori`,
    'ventilconvettori-fan-coil': t`Ventilconvettori (fan coil)`,
    aerotermi: t`Aerotermi`,
    'travi-calde': t`Travi calde`,
    'travi-fredde': t`Travi fredde`
  },
  'tipologia-di-ventilatore': {
    'centrifugo-pale-avanti': t`Centrifugo pale avanti`,
    'centrifugo-pale-indietro': t`Centrifugo pale indietro`,
    'centrifugo-pale-radiali': t`Centrifugo pale radiali`,
    assiale: t`Assiale`
  },
  'classe-di-efficienza-del-motore-elettrico': {
    ie1: t`IE1`,
    ie2: t`IE2`,
    ie3: t`IE3`,
    ie4: t`IE4`,
    eff3: t`eff3`,
    eff2: t`eff2`,
    eff1: t`eff1`
  },
  'meccanismo-di-regolazione': {
    serranda: t`Serranda`,
    'by-pass': t`By - pass`,
    vigv: t`VIGV`,
    'vsd-inverter': t`VSD (inverter)`,
    'valvola-di-strozzamento': t`Valvola di strozzamento`,
    'on--off': t`On / off`,
    'on-off': t`On / off`
  },
  'tipologia-di-pompa': {
    'ad-ingranaggi': t`Ad ingranaggi`,
    'a-lobi': t`A lobi`,
    'a-palette': t`A palette`,
    'a-vite': t`A vite`,
    'a-pistoni': t`A pistoni`,
    centrifuga: t`Centrifuga`,
    lineare: t`Lineare`,
    'magneto-fluidodinamica': t`Magneto - fluidodinamica`,
    'ad-ariete-idraulico': t`Ad ariete idraulico`
  },
  'classe-di-efficienza-del-motore': {
    ie1: t`IE1`,
    ie2: t`IE2`,
    ie3: t`IE3`,
    ie4: t`IE4`,
    eff3: t`eff3`,
    eff2: t`eff2`,
    eff1: t`eff1`
  },
  'meccanismo-di-regolazione-del-motore': {
    'vsd-inverter': t`VSD (inverter)`,
    altro: t`Altro`
  },
  'velocita-nominale-numero-di-poli': {
    '3000-rpm-2-poli': t`3000 rpm - 2 poli`,
    '1500-rpm-4-poli': t`1500 rpm - 4 poli`,
    '100-rpm-6-poli': t`100 rpm - 6 poli`,
    '>1000-rpm-piu-di-6-poli': t`>1000 rpm - più di 6 poli`,
    'piu-di-1000-rpm-piu-di-6-poli': t`>1000 rpm - più di 6 poli`
  },
  'tipo-di-impianto': {
    'ad-aria': t`Ad aria`,
    'ad-acqua': t`Ad acqua`,
    'torre-evaporativa': t`Torre evaporativa`
  },
  'distribuzione-caldofreddo-tipologia-di-terminali': {},
  'tipologia-di-inverter': {
    'per-fotovoltaico': t`Per fotovoltaico`,
    'per-sistema-di-accumulo': t`Per sistema di accumulo`,
    'ibrido': t`Ibrido`
  },
  'tipologia-di-linea-elettrica': {
    'monofase': t`Monofase`,
    'trifase': t`Trifase`
  },
  'tipologia-di-linea-elettrica-stazione-ricarica': {
    'monofase': t`Monofase`,
    'trifase': t`Trifase`
  },
  'modalita-di-ricarica': {
    'CA': t`CA`,
    'CC': t`CC`,
    'CA-CC': t`CA-CC`
  }
};

export const dinamycParametersNames = {
  'consumo-energia-elettrica-prelievo-dalla-rete': t`Consumo energia elettrica (prelievo dalla rete)`,
  'potenza-attiva-prelevata': t`Potenza attiva prelevata`,
  'energia-reattiva-prelevata': t`Energia reattiva prelevata`,
  'potenza-reattiva-prelevata': t`Potenza reattiva prelevata`,
  'immissione-energia-elettrica-in-rete': t`Immissione energia elettrica in rete`,
  'potenza-attiva-immessa-in-rete': t`Potenza attiva immessa in rete`,
  'energia-reattiva-immessa-in-rete': t`Energia reattiva immessa in rete`,
  'potenza-reattiva-immessa-in-rete': t`Potenza reattiva immessa in rete`,
  'consumo-gas-naturale-prelievo-dalla-rete': t`Consumo gas naturale (prelievo dalla rete)`,
  'consumo-gasolio-in-massa': t`Consumo gasolio (in massa)`,
  'consumo-gasolio-in-volume': t`Consumo gasolio (in volume)`,
  'consumo-gpl': t`Consumo GPL`,
  'consumo-combustibile-in-massa': t`Consumo combustibile (in massa)`,
  'consumo-combustibile-in-volume': t`Consumo combustibile (in volume)`,
  'consumo-in-energia-termica': t`Consumo (in energia termica)`,
  'consumo-in-volume': t`Consumo (in volume)`,
  'consumo-idrico': t`Consumo idrico`,
  'temperatura-ambiente': t`Temperatura ambiente`,
  'temperatura-pannelli': t`Temperatura pannelli`,
  'irraggiamento-solare': t`Irraggiamento solare`,
  'irraggiamento-pannelli': t`Irraggiamento pannelli`,
  'energia-prodotta-CA': t`Energia prodotta (in CA)`,
  'energia-termica-assorbita-ingresso': t`Energia termica assorbita (ingresso)`,
  'energia-termica-prelevata-uscita': t`Energia termica prelevata (uscita)`,
  'energia-termica-stoccata': t`Energia termica stoccata`,
  'temperatura-di-stoccaggio': t`Temperatura di stoccaggio`,
  'pressione-serbatoio': t`Pressione serbatoio`,
  'aria-compressa-stoccata': t`Aria compressa stoccata`,
  'aria-compressa-in-ingresso': t`Aria compressa in ingresso`,
  'aria-compressa-uscita': t`Aria compressa in uscita`,
  'energia-elettrica-accumulata': t`Energia elettrica accumulata`,
  'energia-elettrica-assorbita-carica': t`Energia elettrica assorbita (carica)`,
  'energia-elettrica-prelevata-scarica': t`Energia elettrica prelevata (scarica)`,
  'temperatura-di-produzione-acqua-calda-laddove-applicabile': t`Temperatura di produzione acqua calda (laddove applicabile)`,
  'temperatura-di-mandata-ai-circuiti-di-riscaldamento': t`Temperatura di mandata ai circuiti di riscaldamento`,
  'temperatura-di-ritorno-dai-terminali-di-riscaldamento': t`Temperatura di ritorno dai terminali di riscaldamento`,
  'potenza-el-totale-assorbita-dalla-uta': t`Potenza el. totale (assorbita dalla UTA)`,
  'potenza-el-assorbita-dai-ventilatori': t`Potenza el. assorbita dai ventilatori`,
  'temperatura-aria-di-mandata-uta': t`Temperatura aria di mandata UTA`,
  'umidita-relativa-aria-di-mandata-uta': t`Umidità relativa aria di mandata UTA`,
  'portata-aria-di-mandata-uta': t`Portata aria di mandata UTA`,
  'temperatura-aria-di-ripresa-uta': t`Temperatura aria di ripresa UTA`,
  'umidita-relativa-aria-di-ripresa-uta': t`Umidità relativa aria di ripresa UTA`,
  'portata-aria-di-ripresa-uta': t`Portata aria di ripresa UTA`,
  'concentrazione-co2-aria-di-ripresa-uta': t`Concentrazione CO2 aria di ripresa UTA`,
  'temperatura-aria-in-uscita-dalla-sez-di-miscela': t`Temperatura aria in uscita dalla sez. di miscela`,
  'umidita-relativa-aria-in-uscita-dalla-sez-di-miscela': t`Umidità relativa aria in uscita dalla sez. di miscela`,
  'portata-aria-in-uscita-dalla-sez-di-miscela': t`Portata aria in uscita dalla sez. di miscela`,
  'temperatura-aria-post-batteria-fredda-saturazione': t`Temperatura aria post batteria fredda (saturazione)`,
  'apertura-valvola-batteria-di-raffreddamento': t`Apertura valvola batteria di raffreddamento`,
  'temperatura-dellaria-esterna-della-uta': t`Temperatura dell’aria esterna della UTA`,
  'umidita-relativa-dellaria-esterna-della-uta': t`Umidità relativa dell’aria esterna della UTA`,
  'portata-aria-esterna-della-uta': t`Portata aria esterna della UTA`,
  'stato-di-accensione-del-cogeneratore': t`Stato di accensione del cogeneratore`,
  'potenza-el-totale-assorbita-dalla-macchina': t`Potenza el. totale (assorbita dalla macchina)`,
  'potenza-termica-di-refrigerazione': t`Potenza termica di refrigerazione`,
  'temperatura-di-mandata-del-fluido-refrigerato': t`Temperatura di mandata del fluido refrigerato`,
  'temperatura-di-ritorno-del-fluido-refrigerato': t`Temperatura di ritorno del fluido refrigerato`,
  'portata-volumetrica-del-fluido-refrigerato': t`Portata volumetrica del fluido refrigerato`,
  'temperatura-acqua-di-raffreddamento-del-circuito-di-condensazione-laddove-presente': t`Temperatura acqua di raffreddamento del circuito di condensazione (laddove presente)`,
  'energia-el-reattiva-assorbita': t`Energia el. reattiva assorbita`,
  'potenza-el-reattiva-assorbita': t`Potenza el. reattiva assorbita`,
  'fattore-di-potenza': t`Fattore di potenza`,
  'portata-volumetrica': t`Portata volumetrica`,
  accensione: t`accensione`,
  'temperatura-aria-di-mandata-set-point': t`Temperatura aria di mandata (set-point)`,
  'umidita-relativa-aria-di-mandata-set-point': t`Umidità relativa aria di mandata (set-point)`,
  'portata-aria-di-mandata-set-point': t`Portata aria di mandata (set-point)`,
  'apertura-valvola-batteria-di-raffreddamento-set--point': t`Apertura valvola batteria di raffreddamento (set-point)`,
  'controllo-potenza-cogeneratore': t`Controllo potenza cogeneratore`,
  'temperatura-di-mandata-fluido-refrigerato-set-point': t`Temperatura di mandata fluido refrigerato (set-point)`,
  'attivazione-inseguimento-carico-elettrico': t`Attivazione inseguimento carico elettrico`,
  'potenza-massima-erogabile-istantaneamente': t`​Potenza massima erogabile istantaneamente`,
  'stato-di-remotizzazione-del-cogeneratore': t`​Disabilitazione controllo da remoto del cogeneratore (0 = in remoto; 1 = in locale)`,
  'energia-prodotta-CC': t`Energia prodotta (in CC)`,
  'stato-operativo': t`Stato operativo`,
  'energia-elettrica-assorbita-in-CA': t`Energia elettrica assorbita in CA`,
  'energia-elettrica-erogata-in-CA': t`Energia elettrica erogata in CA`,
  'forecast-producibilita-in-ca-da-fotovoltaico': t`Forecast producibilità in CA (da fotovoltaico)`,
  'livello-di-carica-percent': t`Livello di carica %`,
  'stato-di-carica-della-batteria': t`Stato di carica della batteria (0 = in scarica; 1 = in carica)`,
  'temperatura-esterna': t`Temperatura esterna`,
  'temperatura-utenza': t`Temperatura utenza o ambiente `,
  'umidita-relativa-utenza': t`Umidità relativa utenza o ambiente `,
  'modalita-di-funzionamento-riscaldamento-raffreddamento': t`Modalità di funzionamento (Riscaldamento; Raffrescamento)`,
  'temperatura-utenza-set-point': t`Set-point temperatura utenza o ambiente`,
  'consumo-energia-elettrica-linea': t`Consumo energia elettrica linea`,
  'produzione-massa-linea': t`Produzione in massa linea`,
  'produzione-pezzi-linea': t`Produzione in pezzi linea`,
  'potenza-el-totale-assorbita-dalla-stazione': t`Potenza el. totale assorbita`,
  'potenza-el-totale-erogata-dalla-stazione': t`Potenza el. totale erogata`,
  'energia-elettrica-erogata-dalla-stazione': t`Energia elettrica erogata`,
  'numero-di-prese-libere-della-stazione': t`Numero di prese disponibili`,
  'stato-presa-stazione-di-ricarica': t`Stato presa (0 = libera; 1 = occupata)`,
  'stato-rifornimento-presa-stazione-di-ricarica': t`Stato rifornimento presa (0 = non in corso; 1 = in corso)`,
  'funzionamento-prese-di-ricarica': t`Funzionamento prese di ricarica`,
};

export const otherStaticParametersNames = {
  OM_Cost: t`Costo variabile O&M (per kW termico prodotto)`,
  min_uptime: t`Tempo minimo di uptime della macchina (ore)`,
  min_downtime: t`Tempo minimo di downtime della macchina (ore)`,
  startup_cost: t`Costo di accensione della macchina`,
  shutdown_cost: t`Costo di spegnimento della macchina`,
  OM_W: t`Costo variabile O&M (per kW prodotto)`,
  OM_G: t`Costo variabile O&M (per ora di funzionamento)`,
  'ignition-priority': t`ignition-priority`,
  'numero-utenze-termiche-servite': t`N° di utenze termiche o ambienti serviti dalla macchina`,
  'priorita-di-accensione': t`Priorità di accensione della macchina (1 = max; 9 = min)`
};

export const actuationParametersNames = {};

export const optionsNames = {
  'tipo-di-misura': t`Tipo di misura`,
  immissione: t`Immissione`,
  'immissione-e-prelievo': t`Immissione e prelievo`,
  prelievo: t`Prelievo`,
  'prelievo-e-immissione': t`Prelievo e immissione`
};

export const aggregationRates = {
  '1m': t`1'`,
  '5m': t`5'`,
  '10m': t`10'`,
  '15m': t`15'`,
  '30m': t`30'`,
  '1h': t`1h`,
  '1dy': t`1g`,
  '1wk': t`1s`,
  '1mo': t`1m`,
  '1yr': t`1a`
};

export const otherNames = {
  'tipo-di-misura': t`Tipo di misura`,
  immissione: t`Immissione`,
  'immissione-e-prelievo': t`Immissione e prelievo`,
  'prelievo-e-immissione': t`Prelievo ed immissione`
};

export const assetNames = {
  'Contatore energia elettrica (POD)': t`Contatore energia elettrica (POD)`,
  'Contatore energia elettrica (POD) bidirezionale': t`Contatore energia elettrica (POD) bidirezionale`,
  'Contatore gas naturale (PDR)': t`Contatore gas naturale (PDR)`,
  'Contatore gasolio': t`Contatore gasolio`,
  'Contatore GPL': t`Contatore GPL`,
  'Punto di approvvigionamento altri combustibili': t`Punto di approvvigionamento altri combustibili`,
  'Contatore acqua calda': t`Contatore acqua calda`,
  'Contatore acqua surriscaldata': t`Contatore acqua surriscaldata`,
  'Contatore vapore': t`Contatore vapore`,
  'Contatore idrico': t`Contatore idrico`,
  Fotovoltaico: t`Fotovoltaico`,
  Eolico: t`Eolico`,
  'Solare termico': t`Solare termico`,
  'Quadro elettrico': t`Quadro elettrico`,
  Inverter: t`Inverter`,
  Trasformatore: t`Trasformatore`,
  Stabilizzatore: t`Stabilizzatore`,
  'SPI (Sistema Protezione Interfaccia)': t`SPI (Sistema Protezione Interfaccia)`,
  'Gruppo di continuità (UPS)': t`Gruppo di continuità (UPS)`,
  'Centralina di distribuzione / Collettore': t`Centralina di distribuzione / Collettore`,
  'Sistema recupero calore / Scambiatore termico': t`Sistema recupero calore / Scambiatore termico`,
  'Serbatoio / Accumulatore termico': t`Serbatoio / Accumulatore termico`,
  'Serbatoio aria compressa': t`Serbatoio aria compressa`,
  'Batterie di accumulo energia elettrica': t`Batteria di accumulo en. elettrica (ricaricabile)`,
  'Batteria': t`Batteria di accumulo en. elettrica (ad esaurimento)`,
  'Pompa di calore': t`Pompa di calore`,
  'Unità Trattamento Aria (UTA)': t`Unità Trattamento Aria (UTA)`,
  Cogeneratore: t`Cogeneratore`,
  'Altro generatore elettrico': t`Altro generatore elettrico`,
  'Caldaia / Centrale termica': t`Caldaia / Centrale termica`,
  'Macchina frigorifera': t`Macchina frigorifera`,
  'Compressore produzione aria compressa': t`Compressore produzione aria compressa`,
  'Altro impianto di trasformazione': t`Altro impianto di trasformazione`,
  Illuminazione: t`Illuminazione`,
  'Terminali di climatizzazione / condizionamento': t`Terminali di climatizzazione / condizionamento`,
  Ventilatore: t`Ventilatore`,
  Pompa: t`Pompa`,
  'Motore elettrico': t`Motore elettrico`,
  'Torre di raffreddamento': t`Torre di raffreddamento`,
  'Sistema ICT': t`Sistema ICT`,
  'Impianto produttivo': t`Impianto produttivo`,
  'Utenza idrica': t`Utenza idrica`,
  'Altro impianto di consumo': t`Altro impianto di consumo`
};
