/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { saveTab } from 'ducks/actions/databrowsing';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import PropTypes from 'prop-types';
import { formatNumber } from 'app/utils/formatNumber';
import {
  excelFormatNumber,
  OP_TYPE_INSTANT,
  CHART_TYPE_TIME_SERIES_VAR,
  CHART_TYPE_TIME_SERIES_TIME,
  CHART_TYPE_CURVA,
  CHART_TYPE_SCATTER,
  CHART_TYPE_PIE_VAR,
  CHART_TYPE_PIE_TIME,
  CHART_TYPE_CUSUM,
  CHART_TYPE_DISTRBUZIONE,
  CHART_TYPE_CARPET,
  roundFloat,
  DATA_FOR_TABLE,
  DATA_FOR_CSV_DATA,
  DATA_FOR_CSV_HEADER,
  getSequenceData,
  getPieData,
  getCusumData,
  getDistribuzioneValue,
  getHeatMapData,
  getScatterData,
  getCurvaCaricoData
} from './Functions';

const DataWrapper = styled.div`
  padding-top: 10px;
`;

const ReactDataSheetStyled = styled(ReactDataSheet)`
  width: 100%;
`;

const ThHeader = styled.th`
  text-align: right;
`;

const TrWrapper = styled.tr`
  &&&& td {
    padding: 3px;
    text-align: right;
  }
  &&&& input {
    height: 23px;
  }
  &&&& td.read-only {
    background-color: #ffffff;
    color: #000000;
  }
`;

class TableData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  renderSequenceTable = tab => {
    console.log('renderSequenceTable');
    const sequenceDataNaturalOrder = getSequenceData(tab, DATA_FOR_TABLE);
    const sequenceData = sequenceDataNaturalOrder && sequenceDataNaturalOrder.reverse();
    const headerData = getSequenceData(tab, DATA_FOR_CSV_HEADER);
    return (
      <DataWrapper>
        {sequenceData && headerData && (
          <ReactDataSheetStyled
            data={sequenceData.map(row => row.values.map(det => ({ value: det, readOnly: true })))}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    {headerData.map(det => (
                      <ThHeader key={det.label}>{det.label}</ThHeader>
                    ))}
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderPieTable = tab => {
    const PieData = getPieData(tab, DATA_FOR_TABLE);

    return (
      <DataWrapper>
        {PieData && PieData.labels && (
          <ReactDataSheetStyled
            data={PieData.labels.map((label, key) => [
              { value: label.label },
              {
                value: `${excelFormatNumber(
                  roundFloat(formatNumber((PieData.values[key] / PieData.totals) * 100), 3),
                  0,
                  1,
                  '',
                  OP_TYPE_INSTANT
                )} %`
              }
            ])}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderCusumTable = tab => {
    const cusumData = getCusumData(tab, DATA_FOR_TABLE);
    return (
      <DataWrapper>
        {cusumData && (
          <ReactDataSheetStyled
            data={cusumData.map(valueX => [{ value: valueX.timestamp }, { value: valueX.value }])}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderDestribuzioneValoriTable = tab => {
    const series = getDistribuzioneValue(tab, DATA_FOR_CSV_DATA);
    const headerData = getDistribuzioneValue(tab, DATA_FOR_CSV_HEADER);
    return (
      <DataWrapper>
        {series && headerData && (
          <ReactDataSheetStyled
            data={series.map(serie => [
              { value: excelFormatNumber(serie.intervallo) },
              ...tab.seriesActive.map(y => ({ value: excelFormatNumber(serie[y.id]) }))
            ])}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    {headerData.map(det => (
                      <th key={det.label}>{det.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderHeatMapTable = tab => {
    const data = getHeatMapData(tab, DATA_FOR_TABLE);
    return (
      <DataWrapper>
        {data && (
          <ReactDataSheetStyled
            data={data.map(({ x, y, z }) => [
              { value: x },
              { value: y },
              { value: z ? excelFormatNumber(z) : z }
            ])}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    <th>X</th>
                    <th>Y</th>
                    <th>Z</th>
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderScatterTable = tab => {
    const data = getScatterData(tab, DATA_FOR_TABLE);

    return (
      <DataWrapper>
        {data && (
          <ReactDataSheetStyled
            data={data.x.map((x, keyRow) => [
              { value: excelFormatNumber(data.x[keyRow]) },
              { value: excelFormatNumber(data.y[keyRow]) }
            ])}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    <th>X</th>
                    <th>Y</th>
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  renderCurvaTable = tab => {
    const dataCurve = getCurvaCaricoData(tab, DATA_FOR_CSV_DATA);
    const headerData = getCurvaCaricoData(tab, DATA_FOR_CSV_HEADER);

    return (
      <DataWrapper>
        {dataCurve && headerData && (
          <ReactDataSheetStyled
            data={dataCurve}
            valueRenderer={cell => cell.value}
            sheetRenderer={props => (
              <table className={`${props.className}`}>
                <thead>
                  <tr>
                    {headerData.map(x => (
                      <th key={x.label}>{x.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            )}
            rowRenderer={props => <TrWrapper>{props.children}</TrWrapper>}
          />
        )}
      </DataWrapper>
    );
  };

  render() {
    const { tab } = this.props;

    if (tab && 'seriesActive' in tab) {
      return (
        <div>
          {(tab.chartType === CHART_TYPE_TIME_SERIES_VAR ||
            tab.chartType === CHART_TYPE_TIME_SERIES_TIME) &&
            this.renderSequenceTable(tab)}
          {(tab.chartType === CHART_TYPE_PIE_VAR || tab.chartType === CHART_TYPE_PIE_TIME) &&
            this.renderPieTable(tab)}
          {tab.chartType === CHART_TYPE_CUSUM && this.renderCusumTable(tab)}
          {tab.chartType === CHART_TYPE_DISTRBUZIONE && this.renderDestribuzioneValoriTable(tab)}
          {tab.chartType === CHART_TYPE_CARPET && this.renderHeatMapTable(tab)}
          {tab.chartType === CHART_TYPE_SCATTER && this.renderScatterTable(tab)}
          {tab.chartType === CHART_TYPE_CURVA && this.renderCurvaTable(tab)}
        </div>
      );
    }

    return <div />;
  }
}

TableData.propTypes = {
  tab: PropTypes.object.isRequired // from Redux
};

const mapStateToProps = (state, ownProps) => ({
  tab: state.databrowsing.tabs.find(tab => tab.offlineId === ownProps.tabId),
  units: state.catalogs.units,
  databrowsing: state.databrowsing,
  selectedDomain: state.navigation.selectedDomain
});

const mapDispatchToProps = dispatch => ({
  saveTab: (tab, reload) => dispatch(saveTab(tab, reload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableData);
