import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GenericDatePicker from './GenericDatePicker';
import { isEndMinorThanStart } from './common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledGenericDatePicker = styled(GenericDatePicker)`
  flex-grow: 1;
`;

const Separator = styled.div`
  width: 1rem;
`;

const GenericDateRangePicker = (props) => {
  const { start, end, onChange, minTime, maxTime, invalid, className, ...rest } = props;

  const setStartDate = (value) => {
    const isValid = !isEndMinorThanStart(value, end);
    onChange({ start: value, end, isValid });
  };

  const setEndDate = (value) => {
    const isValid = !isEndMinorThanStart(start, value);
    onChange({ start, end: value, isValid });
  };

  return (
    <Wrapper className={className}>
      <StyledGenericDatePicker value={start} onChange={setStartDate} invalid={invalid} {...rest} />
      <Separator />
      <StyledGenericDatePicker value={end} onChange={setEndDate} invalid={invalid} {...rest} />
    </Wrapper>
  );
};

GenericDateRangePicker.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  gap: PropTypes.number,
  invalid: PropTypes.bool,
  separator: PropTypes.string,
  className: PropTypes.string,
};

GenericDateRangePicker.defaultProps = {
  minTime: '00:00',
  maxTime: '24:00',
  gap: 1,
  invalid: false,
  separator: ':',
  className: undefined,
};

export default GenericDateRangePicker;
