export default [
  { name: 'timestat' },
  { name: 'IF' },
  { name: 'min' },
  { name: 'max' },
  { name: 'year' },
  { name: 'month' },
  { name: 'day' },
  { name: 'hour' },
  { name: 'minute' },
  { name: 'ln' },
  { name: 'exp' },
  { name: 'log' },
  { name: 'xʸ' },
  { name: '10ˣ' },
  { name: '√' },
  { name: 'cos' },
  { name: 'arccos' },
  { name: 'sin' },
  { name: 'arcsin' },
  { name: 'tan' },
  { name: 'arctan' },
];
