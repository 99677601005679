import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'app/common';
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import screenfull from 'screenfull';
import ifvisible from 'ifvisible';
import { Quill } from 'react-quill';
import PropTypes from 'prop-types';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { Roles } from 'app/utils/auth';
import Grid from 'app/pages/dashboards/Dashboard/Grid';
import { OPWidgetForm, ImageWidgetForm, TextWidgetForm } from 'app/pages/dashboards/Dashboard/WidgetForm';
import ReportHeaderForm from 'app/pages/dashboards/Dashboard/WidgetForm/ReportHeaderForm';
import ReportFooterForm from 'app/pages/dashboards/Dashboard/WidgetForm/ReportFooterForm';
import { getNewKey, widgetTypes, initialSizes } from 'app/pages/dashboards/Dashboard/common';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import {
  StyledModal,
  Toolbar,
  StyledDropdownToggle,
  GridContainer,
  ErrorMessage,
  Item,
} from 'app/common/Widgets/WidgetStyledComponents';

import { addFolder } from 'ducks/actions/folders';

import { getWidgetReportTypesOptions } from 'app/common/Widgets/WidgetUtils';
import { getGridRowNum, getDocumentTotalPages } from '../ReportHelper';

const QuillLink = Quill.import('formats/link');

class MyLink extends QuillLink {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', this.sanitize(value));
    node.removeAttribute('target');
    return node;
  }
}

const ReportWidgets = (props) => {
  const {
    readOnly,
    userCurrentRoles,
    onSaveReportWidgets,
    headerWidget,
    isHeaderWidgetVisible,
    onUpdateHeaderWidget,
    footerWidget,
    isFooterWidgetVisible,
    onUpdateFooterWidget,
    widgets,
    period,
    report,
    tempValues,
    orientation,
    layout,
    onGenerateReportPreview,
    onGeneratePdf,
    selectedDomain,
    selectedCompany,
    selectedSite,
    folders,
    addFolder
  } = props;

  const containerStyle = {
    backgroundColor: '#efefef',
    width: '100%',
    padding: '1% 0 1% 0',
    marginTop: '1%'
  };

  const headerStyle = {
    width: '99%',
    backgroundColor: '#fff',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowY: 'auto'
  };

  const mainWidgetsStylesDefault = {
    width: '99%',
    height: '700px',
    backgroundColor: '#fff',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowY: 'auto',
    position: 'relative',
  };

  const pdfMap = [
    {
      layout: 'A4',
      ratio: 0.707070,
    },
  ];

  const separatorsStyle = {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    border: '1px dashed #333333',
    // top: *px
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [widgetModal, setWidgetModal] = useState({ isOpen: false, period: null, widget: undefined });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [shouldUpdateWidgets, setShouldUpdateWidgets] = useState(true);
  const [mainWidgetsStyles, setMainWidgetsStyles] = useState(mainWidgetsStylesDefault);
  const [pageSeparators, setPageSeparators] = useState([]);
  const [pageNotifications, setPageNotifications] = useState([]);
  const [error] = useState();

  const widgetTypesOptions = getWidgetReportTypesOptions(true);

  const cardRef = useRef(null);
  const headerRef = useRef(null);
  const mainContainerRef = useRef(null);
  const footerRef = useRef(null);
  const prevPageNotifications = useRef();

  useEffect(() => {

    Quill.register(MyLink);

    if (screenfull.enabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }
    ifvisible.on('blur', () => setShouldUpdateWidgets(false));
    ifvisible.on('focus', () => setShouldUpdateWidgets(true));

    addPageSeparators(cardRef.current);

    // Set the line separators again after the window resize
    window.addEventListener('resize', () => {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(() => {
        addPageSeparators(cardRef.current);
      }, 250);
    });

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('resize', () => {});
    }
  
  }, []);

  useEffect(() => {
    // TODO if orientation changes, redesign line separators
  }, [orientation, layout]);

  useEffect(() => {
    if ( !isEqual(pageNotifications, prevPageNotifications.current) ) {
      prevPageNotifications.current = pageNotifications;
	  console.log("current aggiornato --> ", prevPageNotifications.current);
      pageNotifications.map(wdgt => {
        toast.warning(`Il widget ${wdgt.title} si trova fra una pagina e l'altra`);
        return null;
      });
    }
  }, [pageNotifications]);

  const addPageSeparators = (containerRef) => {
    if (!containerRef || !containerRef instanceof HTMLElement) {
      return; // error \ no operations
    }
    const computedStyle = getComputedStyle(containerRef);
    const computedWidth = parseInt(computedStyle.width.match(/\d+/)[0]);
    const rowSingleHeightPx = computedWidth / 12;
    const paddingV = 10; // semi-padding previsto per il widget Grid
    const rowNum = getGridRowNum(); // righe griglia react-grid-layout
    const singlePageHeight = rowSingleHeightPx * rowNum + ( (rowNum - 1) * paddingV );
    if (widgets) {
      const totPages = getDocumentTotalPages(widgets);
      const totalGridHeight = singlePageHeight * totPages;
      const totLineSeparators = totPages - 1;

      // Linea con top singlePageHeight * n numero pagina
      const separators = [];
      for(let i=1; i<=totLineSeparators; i++) {
        separators.push({ ...separatorsStyle, top: (singlePageHeight * i) + 'px'  });
      }
      setPageSeparators(separators);
      setMainWidgetsStyles({ ...mainWidgetsStyles, height: totalGridHeight });

    } else {
      /**
       * No widgets (new report or empty report)
       * Setta altezza main widget pari a N px per in base al formato selezionato
       */
      const emptyWidgetPageHeight = getSinglePageHeight(orientation, layout);
      if (emptyWidgetPageHeight) {
        setMainWidgetsStyles({ ...mainWidgetsStyles, height: emptyWidgetPageHeight });
      }
    }
  }

  const getSinglePageHeight = (orientation, layout) => {
    const pdfLayoutOpts = pdfMap.find(mp => mp.layout === layout);
    if (cardRef && cardRef.current) {
      const computedStyle = getComputedStyle(cardRef.current);
      const computedWidth = parseInt(computedStyle.width.match(/\d+/)[0]);
      // const elementsArray = document.getElementsByClassName('react-grid-layout');
      if (orientation === 'orizzontale') {
        return computedWidth * pdfLayoutOpts.ratio;
      } else {
        return computedWidth / pdfLayoutOpts.ratio;
      }
    }
    return;
  }

  const getDefaultWidgetData = (widgetType) => {
    const { value, label } = widgetType;
    const widget = { title: i18n._(label), titlePeriod: null, widgetType: value };
    if (value === widgetTypes.SERIES) {
      widget.legend = 'bottom';
      widget.grid = 'none';
    }
    if (value === widgetTypes.PIE) {
      widget.legend = 'bottom';
      widget.format = 'value';
      widget.showTotal = false;
      widget.title = i18n._(t`Grafico di confronto`);
    }
    if (value === widgetTypes.IMAGE) {
      widget.fit = 'scale-down';
    }
    if (value === widgetTypes.TEXT) {
      widget.content = '';
    }
    if (value === widgetTypes.LED || value === widgetTypes.SWITCH) {
      widget.properties = { iconSize: 1, showLabel: true, askConfirm: true };
    }
    if (value === widgetTypes.SLIDER) {
      widget.properties = { askConfirm: true };
    }
    return widget;
  };

  const openNewWidgetWindow = (widgetType) => {
    setWidgetModal({
      isOpen: true,
      period: period,
      isReport: true,
      widgetType: widgetType.value,
      widget: getDefaultWidgetData(widgetType)
    });
  };

  const openEditWidgetWindow = (widget) => {
    setWidgetModal({
      isOpen: true,
      period: period,
      isReport: true,
      widgetType: widget.widgetType,
      widget: widget
    });
  };

  const widgetModalToggle = () => {
    setWidgetModal({ isOpen: !widgetModal.isOpen, period: period, widget: undefined });
  };

  const saveWidget = (widget) => {
    // Update Header
    if (widget.widgetType === widgetTypes.REPORT_HEADER) {
      onUpdateHeaderWidget(widget);
      return;
    }

    // Update Footer
    if (widget.widgetType === widgetTypes.REPORT_FOOTER) {
      onUpdateFooterWidget(widget);
      return;
    }

    // Create a new object with the object list and call the save widget utility
    let widgetsObjectList = [];
    if (!widget.key) {
      // Add new widget
      widget.key = getNewKey();
      const { w, h } = initialSizes[widget.widgetType];
      widget.layout = { x: 0, y: 0, w, h };

      widgetsObjectList = (widgets) ? [...widgets, widget] : [widget];

    } else {
      // Update existing widget
      const widgetsNew = [...widgets];
      const index = widgetsNew.findIndex(x => x.key === widget.key);
      widgetsNew[index] = widget;

      widgetsObjectList = widgetsNew;
    }

    // Call save widgets event
    if (onSaveReportWidgets && widgetsObjectList) {
      onSaveReportWidgets(widgetsObjectList);
    }

  };

  const deleteWidget = (widget) => {
    const newWidgetList = widgets.filter(x => x.key !== widget.key);

    if (onSaveReportWidgets) {
      onSaveReportWidgets(newWidgetList);

      // Update page line separators
      if (cardRef.current) {
        addPageSeparators(cardRef.current);
      }
    }
  };

  const toggleFullscreen = () => {
    if (screenfull.enabled) {
      screenfull.toggle(mainContainerRef.current);
    }
  };

  const updateLayout = (layout) => {
    if (widgets) {
      let notificationMessages = [];

      // Search widget and override layout
      layout.forEach((l) => {
        const w = widgets.find(x => x.key === l.i);
        if (w) {
          w.layout = l;

          // Update page line separators
          addPageSeparators(cardRef.current);

          // Controlla se ci sono widget fra una pagina e l'altra
          const pageStart = parseInt(l.y / 14);
          const pageEnd = parseInt((l.y + l.h - 1) / 14);
          if (pageEnd > pageStart) {
            notificationMessages.push({...w});
          }

        }
      });

      setPageNotifications(notificationMessages);

    }
    return true;
  };

  const updateSingleWidgetLayout = (widget, layout) => {
    if (widget && Array.isArray(layout) && layout.length === 1) {
      widget.layout = layout[0];
      saveWidget(widget);
    }
    return true;
  };

  const updateHeaderWidgetLayout = (layout) => {
    updateSingleWidgetLayout(headerWidget, layout);
    return true;
  };

  const updateFooterWidgetLayout = (layout) => {
    updateSingleWidgetLayout(footerWidget, layout);
    return true;
  };

  const renderWidgetModal = () => {
    let widgetForm;
    if (widgetModal.widget) {
      if (widgetModal.widgetType === widgetTypes.IMAGE) {
        widgetForm = <ImageWidgetForm widget={widgetModal.widget} onClose={widgetModalToggle} onSave={saveWidget} selectedDomain={selectedDomain} selectedCompany={selectedCompany} selectedSite={selectedSite} folders={folders} addFolder={addFolder}/>;
      } else if (widgetModal.widgetType === widgetTypes.TEXT) {
        widgetForm = <TextWidgetForm widget={widgetModal.widget} onClose={widgetModalToggle} onSave={saveWidget} />;
      } else if (widgetModal.widgetType === widgetTypes.REPORT_HEADER) {
        widgetForm = <ReportHeaderForm period={period} widget={widgetModal.widget} onClose={widgetModalToggle} onSave={saveWidget} selectedDomain={selectedDomain} selectedCompany={selectedCompany} selectedSite={selectedSite} />;
      } else if (widgetModal.widgetType === widgetTypes.REPORT_FOOTER) {
        widgetForm = <ReportFooterForm period={period} widget={widgetModal.widget} onClose={widgetModalToggle} onSave={saveWidget} selectedDomain={selectedDomain} selectedCompany={selectedCompany} selectedSite={selectedSite} />;
      } else {
        widgetForm = <OPWidgetForm currentModule={'report'} period={period} widget={widgetModal.widget} onClose={widgetModalToggle} onSave={saveWidget} />;
      }
    }

    return (
      <StyledModal isOpen={widgetModal.isOpen} period={period} toggleModal={widgetModalToggle}>
        {widgetForm}
      </StyledModal>
    );
  };

  const userCanSendCommand = userCurrentRoles.includes(Roles.OPE_CMD);

  return (
    <>
      {renderWidgetModal()}
      <div ref={cardRef} className="row" style={{ width: '100%' }}>
        {!readOnly && (
          <div className="col-12">
            <Toolbar>
              <div className="row">
                <div className="col-8">
                  { !isFullscreen && (
                  <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                    <StyledDropdownToggle caret><Trans>Aggiungi widget</Trans></StyledDropdownToggle>
                    <DropdownMenu>
                      {widgetTypesOptions.map(widgetType => (
                        <DropdownItem key={widgetType.value} onClick={() => openNewWidgetWindow(widgetType)}>
                          <Item>
                            <img src={widgetType.icon} alt={widgetType.label} />
                            <span>{i18n._(widgetType.label)}</span>
                          </Item>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                  )}
                </div>
                <div className="col-4 text-right">
                  <IconButton icon={isFullscreen ? 'compress' : 'expand'} onClick={toggleFullscreen} />
                  <IconButton icon="file-pdf" onClick={() => onGeneratePdf(null, true, onGenerateReportPreview)} />
                </div>
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Toolbar>
          </div>
        )}

        <div className="col-12" style={containerStyle}>
          {/* Header widget */}
          { isHeaderWidgetVisible && headerWidget && (
            <GridContainer style={headerStyle} ref={headerRef}>
              <Grid currentModule={'report'} additionalData={(report && tempValues) ? {...report, ...tempValues} : tempValues} widgets={ [headerWidget] } edit={!readOnly} onEdit={openEditWidgetWindow} onDelete={deleteWidget} onLayoutChange={updateHeaderWidgetLayout} shouldUpdateWidgets={shouldUpdateWidgets} userCanSendCommand={userCanSendCommand} />
            </GridContainer>
          )}

          {/* Main widgets */}
          <GridContainer style={mainWidgetsStyles} ref={mainContainerRef} isFullscreen={isFullscreen}>
            {/* Page separators */}
            { pageSeparators.length > 0 && 
              pageSeparators.map(sep => <div style={sep}></div>)
            }
            <Grid currentModule={'report'} widgets={ (widgets) ? widgets : [] } edit={!readOnly} onEdit={openEditWidgetWindow} onDelete={deleteWidget} onLayoutChange={updateLayout} shouldUpdateWidgets={shouldUpdateWidgets} userCanSendCommand={userCanSendCommand} />
          </GridContainer>

          {/* Footer widget */}
          { isFooterWidgetVisible && footerWidget && (
          <GridContainer style={headerStyle} ref={footerRef}>
            <Grid currentModule={'report'} widgets={ [footerWidget] } edit={!readOnly} onEdit={openEditWidgetWindow} onLayoutChange={updateFooterWidgetLayout} shouldUpdateWidgets={shouldUpdateWidgets} userCanSendCommand={userCanSendCommand} />
          </GridContainer>
          )}
        </div>

      </div>
    </>
  );

};

ReportWidgets.propTypes = {
  isHeaderWidgetVisible: PropTypes.bool,
  isFooterWidgetVisible: PropTypes.bool,
  readOnly: PropTypes.bool,
  headerWidget: PropTypes.object,
  footerWidget: PropTypes.object,
  widgets: PropTypes.object,
  report: PropTypes.object,
  userCurrentRoles: PropTypes.string,
  period: PropTypes.string,
  orientation: PropTypes.string,
  layout: PropTypes.string,
  onSaveReportWidgets: PropTypes.func,
  onUpdateHeaderWidget: PropTypes.func,
  onUpdateFooterWidget: PropTypes.func,
  onGeneratePdf: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { navigation, domain } = state;
  const { type, id, subtype, subid, selectedDomain, selectedCompany, selectedSite } = navigation;
  const { folders } = domain;
  const { userCurrentRoles } = state.auth;

  return { type, id, subtype, subid, userCurrentRoles, selectedDomain, selectedCompany, selectedSite, folders };
};

const mapDispatchToProps = dispatch => ({
  addFolder: (folder) => dispatch(addFolder(folder))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportWidgets);
