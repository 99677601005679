import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';
import TreeContext from './TreeContext';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;

  & > li {
    margin-left: 0;
  }
`;

const TreeView = ({ data, selectedItems, disabledItems, options, toggle, filter, compareFunction, render }) => {
  selectedItems = selectedItems || [];
  const keyProperty = options.keyProperty || 'name';

  const [visibleNodes, setVisibleNodes] = useState({});

  const updateVisible = (key, visible) => {
    setVisibleNodes(prevVisibleNodes => ({ ...prevVisibleNodes, [key]: visible }));
  };


  const filtered = Object.values(visibleNodes).some(x => x === false);

  const nodes = useMemo(() => {
    return data && Array.isArray(data) ? data.map(x => ({ ...x, key: x[keyProperty] && x[keyProperty].toString() })) : [{ ...data, key: data[keyProperty] && data[keyProperty].toString() }];
  }, [data]);

  console.log('tree nodes ',nodes)
  const toggleItem = (node, checked, parent) => {
    const { maxChecked, onMaxChecked } = options;
    if (maxChecked === 1) {
      if (selectedItems.length > 0) {
        toggle(selectedItems[0], false, parent);
      }
      toggle(node, true, parent);
      return checked;
    }

    if (checked && maxChecked) {
      if (selectedItems.length >= maxChecked) {
        if (onMaxChecked) {
          onMaxChecked();
        }
        return null;
      }
    }
    toggle(node, checked, parent);
    return checked;
  };

  const contextValue = { options, filter, filtered, visibleNodes, selectedItems, disabledItems, updateVisible, toggleItem, compareFunction, render };

  return (
    <TreeContext.Provider value={contextValue}>
      <Ul>
        {nodes.map(node => <TreeNode key={node.key} node={node} />)}
      </Ul>
    </TreeContext.Provider>
  );
};

TreeView.propTypes = {
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  disabledItems: PropTypes.array,
  options: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  filter: PropTypes.func,
  compareFunction: PropTypes.func.isRequired,
  render: PropTypes.func,
};

TreeView.defaultProps = {
  disabledItems: [],
  options: {},
  filter: undefined,
  render: undefined,
};

export default TreeView;
