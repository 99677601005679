import React, { Component, forwardRef } from 'react';
import { OPSelector } from 'app/common';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { generateColor } from 'app/pages/databrowsing/Functions';
import memoize from 'memoize-one';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import PeriodSelector from './PeriodSelector';
import PeriodSelectorMulti from './PeriodSelectorMulti';
import { widgetTypes } from '../common';
import logger from 'app/common/log';
import api from 'api';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  max-height: 70vh;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #f00;
`;

class DataSelector extends Component {
  orphaned = [];
  isLoading = false;

  constructor(props) {
    super(props);

    this.periodRef = React.createRef();

    const { widget, periodCompetence } = props;
    const variables = widget.variables || [];

    let wgtPeriod = null;
    if (widget.period && widget.period.interval) wgtPeriod = widget.period.interval;

    this.state = {
      checkedNodes: variables,
      error: '',
      periodCompetence: wgtPeriod || periodCompetence || null
    };
  }

  componentDidMount() {
    this.findDeletedOp();
  }

  getFilters = memoize((widget, checkedNodes) => {
    const { widgetType } = widget;

    const filters = {};

    if (checkedNodes.length > 0) {
      const { physicalQuantity } = checkedNodes[0];
      if (widgetType === widgetTypes.PIE) {
        // Filter for physical quantity
        filters.physicalQuantity = physicalQuantity;
      }
    }

    if (widgetType === widgetTypes.SWITCH) {
      filters.physicalQuantity = 'state';
      filters.function = item => item.writeable === true;
    }

    if (widgetType === widgetTypes.SLIDER) {
      filters.function = item => item.physicalQuantity !== 'state' && item.writeable === true;
    }

    return filters;
  });

  updateCheckedNodes = (node, checked) => {
    const { widget, virtualmeters } = this.props;
    const { widgetType } = widget;
    const maxChecked = ['circle', 'gauge'].includes(widgetType)
      ? 1
      : ['series', 'pie', 'table'].includes(widgetType)
      ? 14
      : 7;
    const { type, id, item } = node;
    const vm = virtualmeters.find(vm => vm.id === item.virtualMeterId);
    const rate = vm && vm.rate;
    const { valueType, physicalQuantity, customQuantityId, name } = item;
    if (maxChecked === 1) {
      this.setState({
        checkedNodes: [{ type, id, valueType, physicalQuantity, customQuantityId, name, rate }]
      });
    } else {
      this.setState(prevState => ({
        checkedNodes: checked
          ? prevState.checkedNodes.concat({
              type,
              id,
              valueType,
              physicalQuantity,
              customQuantityId,
              name,
              rate
            })
          : prevState.checkedNodes.filter(x => !(x.type === node.type && x.id === node.id))
      }));
    }
  };

  getAdditionalProperties = node => {
    const { widget } = this.props;
    const { widgetType } = widget;
    const properties = node.properties || {};
    switch (widgetType) {
      case widgetTypes.SERIES:
        return { seriesType: properties.seriesType || 'spline' };
      case widgetTypes.CIRCLE:
      case widgetTypes.GAUGE:
        return { min: properties.min || 0, max: properties.max || undefined };
      case widgetTypes.LED:
      case widgetTypes.SWITCH:
        return { states: properties.states || [] };
      case widgetTypes.SLIDER:
        return {
          min: properties.min || 0,
          max: properties.max || undefined,
          step: properties.step || 1
        };
      default:
        return {};
    }
  };

  validate = async () => {
    try {
      this.setState({ error: '' });
      const { checkedNodes } = this.state;
      const { isTableWidget, currentStep } = this.props;

      if (checkedNodes.length === 0) {
        throw new Error(i18n._(t`Selezionare almeno una variabile`));
      }

      if (isTableWidget) {
        // Validate multiple periods
        if (currentStep === 2) {
          const multiPeriods = await this.periodRef.current.getValues();

          if (!multiPeriods.rows) {
            throw new Error(i18n._(t`Errore ricezione periodi multipli`));
          }
        }
      } else {
        const period = await this.periodRef.current.getValues();
        if (!period) {
          throw new Error(i18n._(t`Errore di validazione dati`));
        }
      }

      return true;
    } catch (e) {
      this.setState({ error: e.message });
      return false;
    }
  };

  getValues = async () => {
    const { physicalQuantities } = this.props;
    const { checkedNodes } = this.state;

    const assignedColors = checkedNodes.filter(x => x.color !== undefined).map(x => x.color);
    const variables = checkedNodes.map(node => {
      const {
        type,
        id,
        valueType,
        physicalQuantity,
        customQuantityId,
        name,
        rate,
        decimalDigits
      } = node;
      let { unit, color, label } = node;
      if (!unit) {
        const pq = physicalQuantities.find(x => x.naturalKey === physicalQuantity);
        unit = pq.defaultVisualizationUnit || pq.units[0];
      }
      if (!color) {
        color = generateColor(assignedColors);
        assignedColors.push(color);
      }
      if (!label) {
        label = name;
      }
      const properties = this.getAdditionalProperties(node);
      const result = {
        type,
        id,
        valueType,
        physicalQuantity,
        customQuantityId,
        unit,
        color,
        name,
        rate,
        label,
        properties,
        decimalDigits: decimalDigits == null ? -1 : decimalDigits
      };
      logger.debug('variable DataSelector:getValues ', result);
      return result;
    });

    if (this.periodRef && this.periodRef.current) {
      const period = await this.periodRef.current.getValues();
      return { variables, period };
    }

    return { variables };
  };

  onMaxChecked = () => {
    const { widget } = this.props;
    const { widgetType } = widget;
    const number = ['series', 'pie', 'table'].includes(widgetType) ? 14 : 7;
    toast.warn(i18n._(t`E' possibile aggiungere al massimo ${number} variabili`));
  };

  //Find deleted op and create toast
  findDeletedOp = async () => {
    const { isDomainLoaded } = this.props;
    if(!isDomainLoaded){
      return;
    }
    await this.loadOrphanedObservedProperties();
    const { observableproperties } = this.props;
    const { checkedNodes } = this.state;
    const orphaned = this.orphaned;
    this.setState({
      checkedNodes: checkedNodes.map(node =>{
        if(!(observableproperties.find(f => f.id.toString() === node.id.toString())) && !(orphaned.find(f => f.id.toString() == node.id.toString()))){
          const name = node.name;
          toast.error(i18n._(t`La variabile ${name} risulta eliminata e sarà rimossa al salvataggio`));
          return null;
        }else{
          return node;
        };
      }).filter(node => node)
    })
  }

  //Load orphaned op that are selected and not yet loaded
  loadOrphanedObservedProperties = async () => {
    const { observableproperties, selectedDomain, selectedCompany, selectedSite, selectedSitegroup, selectedAsset } = this.props;
    const { checkedNodes } = this.state;
    const orphanedList = this.orphaned;
    const orphanedId = checkedNodes.filter(op => {
      return !observableproperties.find(f => f.id.toString() === op.id.toString());
    }).filter(id => !orphanedList.some(orphaned => orphaned.id.toString() === id.toString()));
    //Check if there are any orphaned to load and if it's already loading them
    if(orphanedId.length > 0 && !this.isLoading){
      this.isLoading = true;
      let apiCall = '';
      if (selectedAsset) {
        apiCall = `/Sites/${selectedSite.id}/orphanedObservedProperties`;
      } else if (selectedSite) {
        apiCall = `/Sites/${selectedSite.id}/orphanedObservedProperties`;
      } else if (selectedSitegroup) {
        apiCall = `/Companies/${selectedCompany.id}/orphanedObservedProperties`;
      } else if (selectedCompany) {
        apiCall = `/Companies/${selectedCompany.id}/orphanedObservedProperties`;
      } else if (selectedDomain) {
        apiCall = `/Domains/${selectedDomain.id}/orphanedObservedProperties`;
      }
      
      const res = await api.get(apiCall + `?filter={"where":{"id":{"inq":${JSON.stringify(orphanedId.map(op => op.id))}}}}`);
      //Add new orphaned to the old ones
      this.orphaned = [...this.orphaned, ...res.data];
      this.isLoading = false;
    }
  }

  render() {
    const { widget, isTableWidget, isAddPeriodsStep, currentModule } = this.props;
    const { widgetType } = widget;
    const { checkedNodes, error } = this.state;

    // const valueTypes = checkedNodes.map(x => (x.type === 'op' ? x.valueType : x.type));
    const valueTypes = checkedNodes.map(x => x.valueType);
    const maxChecked = ['circle', 'gauge'].includes(widgetType)
      ? 1
      : ['series', 'pie', 'table'].includes(widgetType)
      ? 14
      : 7;

    const filters = this.getFilters(widget, checkedNodes);

    const periodSelectorElement = () => {
      if (isTableWidget && !isAddPeriodsStep) {
        // No period selection for table widget
        return;
      } else if (isTableWidget && isAddPeriodsStep) {
        return (
          <PeriodSelectorMulti
            currentModule={currentModule}
            scroll
            ref={this.periodRef}
            widgetType={widgetType}
            isTableWidget={isTableWidget}
            valueTypes={valueTypes}
            periodCompetence={this.state.periodCompetence}
            period={widget.period}
          />
        );
      }

      return (
        <PeriodSelector
          currentModule={currentModule}
          ref={this.periodRef}
          widgetType={widgetType}
          valueTypes={valueTypes}
          periodCompetence={this.state.periodCompetence}
          period={widget.period}
        />
      );
    };

    return (
      <Wrapper>
        {!isAddPeriodsStep && (
          <OPSelector
            scroll
            maxChecked={maxChecked}
            onMaxChecked={this.onMaxChecked}
            onCheck={this.updateCheckedNodes}
            checkedNodes={checkedNodes}
            filters={filters}
            physicalQuantity={filters.physicalQuantity}
            updateOrphaned={orphaned => this.orphaned = orphaned}
          />
        )}
        {periodSelectorElement()}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Wrapper>
    );
  }
}

DataSelector.propTypes = {
  widget: PropTypes.object.isRequired,
  physicalQuantities: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  const { companies, domains, sites, assets, selectedDomain, selectedCompany, selectedSite, selectedSitegroup, selectedAsset, isDomainLoaded } = state.navigation;
  const { observableproperties } = state.domain;
  return { companies, domains, sites, assets, selectedDomain, selectedCompany, selectedSite, selectedSitegroup, selectedAsset, observableproperties, isDomainLoaded };
};

//WizardStep uses ref to access DataSelector, using it with a component connected to redux doesn't give access to the actual component,
//but to an Higher-Order Component. This is solved using forwardRef to forward the ref to the actual component
export default connect(mapStateToProps, null, null, { forwardRef: true })(forwardRef((props, ref) => <DataSelector {...props} ref={ref} />));
