import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadSelectedDomain } from 'ducks/actions/navigation';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Trans } from '@lingui/macro';

const ModalStyled = styled(Modal)`
  top: 15%;
`

const Domain = styled.div`
  cursor: pointer;
  padding: .2rem;
  margin-left: .5rem;

  &:hover {
    background-color: transparent !important;
    background-image: linear-gradient(to right,rgba(31,113,183,0.2),transparent);
    color: #1F71B7;
  }
`;


const ChooseDomain = (props) => {

  const handleClick = (id) => {
    props.loadSelectedDomain(id);
  };

  const [idExist, setIdExist] = useState(false);

  //Controllo se l'id esiste e valorizzo idExist
  useEffect(() => {
    if(props.id){
      switch(props.type){
        case 'domains':
          setIdExist(props.domains.find((domain) => domain.id === props.id));
        break;
        case 'companies':
          setIdExist(props.companies.find((company) => company.id === props.id));
        break;
        case 'sites':
          setIdExist(props.sites.find((site) => site.id === props.id));
        break;
        case 'sitegroups':
          setIdExist(props.sitegroups.find((sitegroup) => sitegroup.id === props.id));
        break;
        default:
        break;
      }
    }
  }, [props.id, props.type, props.domains, props.companies, props.sites, props.sitegroups]);

  const domains = [...props.domains].sort((a,b) => a.name.localeCompare(b.name));

  
  return (
    <>
        {((domains && domains.length > 1) && ((!props.id) || (props.id && domains && props.defaultType === 'domains' && !(idExist) ))) && (
      <>
      <ModalStyled isOpen={true}>
          <ModalHeader><Trans>Seleziona un dominio</Trans></ModalHeader>
          <ModalBody>
          <div>
            {domains && domains && domains.length > 0 && domains.map((domain) => (
              <Domain key={domain.id} onClick={() => handleClick(domain.id)}>{domain.name}</Domain>
            ))}
          </div>
        </ModalBody>
      </ModalStyled>
      </>
    )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { domains, companies, sites, sitegroups, defaultDomain, id, defaultType, type } = state.navigation;
  return { domains, companies, sites, sitegroups, defaultDomain, id, defaultType, type };
};

const mapDispatchToProps = dispatch => ({
  loadSelectedDomain: (defaultId) => dispatch(loadSelectedDomain(defaultId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDomain);
