import React, { useState, useEffect, useMemo, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getMinutesAsString, getTimeAsMinutes } from './common';
import { Dropdown, DropdownItem } from '../Dropdown';

const Wrapper = styled.div`
  position: relative;
  padding: 0;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    max-width: 6rem;
    min-width: 6rem;
  }
`;

const TimePicker = (props) => {
  const { allDayLabel, allDayClick, minTime, disabled, maxTime, gap, value, onChange, invalid, separator, className, ...rest } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputText, setInputText] = useState(value);
  const [highlightedOption, setHighlightedOption] = useState(null);

  const componentRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputText(value);
  }, [value]);


  const availableTimes = useMemo(() => {
    const times = [];
    const start = getTimeAsMinutes(minTime, separator);
    const end = getTimeAsMinutes(maxTime, separator);
    for (let minutes = start; minutes <= end; minutes += gap) {
      times.push({ value: minutes, label: getMinutesAsString(minutes, separator) });
    }
    return times;
  }, [minTime, maxTime, gap]);


  const findOption = (text) => {
    if (text) {
      return availableTimes.find(x => x.label.startsWith(text));
    }
    return null;
  };

  const highlightItem = (item) => {
    setHighlightedOption(item);
    const element = document.getElementById(item.value);
    element.scrollIntoView();
  };

  useEffect(() => {
    if (dropdownOpen) {
      if (inputText) {
        const bestOption = findOption(inputText);
        if (bestOption) {
          highlightItem(bestOption);
        }
      }
    } else {
      setHighlightedOption(null);
    }
  }, [dropdownOpen, inputText]);


  const closeDropdown = () => {
    setDropdownOpen(false);
    if (!availableTimes.find(x => x.label === inputText)) {
      setInputText(value);
    }
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useOnClickOutside(componentRef, () => {
    closeDropdown();
  });


  const selectItem = (time) => {
    setInputText(time.label);
    closeDropdown();
    onChange(time.label, time);
  };

  const changeText = (e) => {
    let newInputText = e.target.value;
    const regEx = new RegExp(`^\\d{0,2}${separator}?\\d{0,2}$`);
    if (regEx.test(newInputText)) {
      const [hours, minutes] = newInputText.split(separator);
      if (minutes !== undefined && hours.length < 2) {
        const leadingZeros = new Array(3 - hours.length).join('0');
        const newHours = `${leadingZeros}${hours}`;
        newInputText = `${newHours}${separator}${minutes}`;
      } else if (hours.length > 2) {
        const newHours = hours.slice(0, 2);
        const newMinutes = hours.slice(2);
        newInputText = `${newHours}${separator}${newMinutes}`;
      }
      setInputText(newInputText);
    }
    setDropdownOpen(true);
  };

  const handleKey = (e) => {
    const { length } = availableTimes;
    const index = availableTimes.indexOf(highlightedOption);

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        highlightItem(index >= 0 ? availableTimes[(index + length - 1) % length] : availableTimes[length - 1]);
        break;
      case 'ArrowDown':
        e.preventDefault();
        highlightItem(index >= 0 ? availableTimes[(index + 1) % length] : availableTimes[0]);
        break;
      case 'Tab':
      case 'Enter':
        if (highlightedOption) {
          selectItem(highlightedOption);
          closeDropdown();
        }
        break;
      case 'Escape':
        closeDropdown();
        break;
      default:
    }
  };

  const focus = () => {
    setDropdownOpen(true);
    inputRef.current.select();
  };


  return (
    <Wrapper ref={componentRef} className={className}>
      <input ref={inputRef} disabled={disabled} value={inputText} onChange={changeText} onFocus={focus} onKeyDown={handleKey} className={`form-control ${invalid ? 'is-invalid' : ''}`} {...rest} />
      {dropdownOpen && (
        <StyledDropdown className="dropdown-menu show" ref={dropdownRef}>
          {availableTimes.map(time => (
            <DropdownItem
              id={time.value}
              type="button"
              className="dropdown-item"
              key={time.value}
              value={time.value}
              onClick={() => selectItem(time)}
              onMouseOver={() => setHighlightedOption(time)}
              onFocus={() => {}}
              active={highlightedOption === time}
            >
              {time.label}
            </DropdownItem>
          ))}
        </StyledDropdown>
      )}
    </Wrapper>
  );
};

TimePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  gap: PropTypes.number,
  invalid: PropTypes.bool,
  separator: PropTypes.string,
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  minTime: '00:00',
  maxTime: '24:00',
  gap: 1,
  invalid: false,
  separator: ':',
  className: undefined,
};

export default TimePicker;
