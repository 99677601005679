/* eslint-disable import/prefer-default-export */
import api, { getAxiosError } from 'api';
import logger from 'app/common/log';

// EventTags

async function getSystemEventTags() {
  let data;
  let err;
  const url = `/EventTags`;
  const filter = { include: 'updater', where: { companyId: null, active: true } };
  const params = {
    filter
  };
  try {
    const res = await api.get(url, { params });
    data = res && res.data;
    logger.debug('getSystemEventTags Success data: ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('getSystemEventTags %o', err);
  }
  return { data, err };
}

async function getEventTagsBySelectedNodeId({selectedDomain, selectedCompany}) {
  let id;
  let url;
  if (selectedCompany) {
    id = selectedCompany.id;
    url = `/Companies/${id}/eventTags`;
  }
  else if (selectedDomain) {
    id = selectedDomain.id;
    url = `/Domains/${id}/eventTags`;
  }
  let data = {};
  let err;
  const filter = { include: 'updater', where: { active: true } };
  const params = {
    filter
  };
  if (id != null) {
    try {
      const res = await api.get(url, { params });
      data = res && res.data;
      logger.debug('getEventTagsBySelectedNodeId Success data: ', data);
    } catch (ex) {
      err = getAxiosError(ex);
      logger.error('getEventTagsBySelectedNodeId %o', err);
    }
  }
  return { data, err };
}

export async function getEventTagsByEntity({
  domains,
  companies,
  sites,
  selectedDomain,
  selectedCompany,
  selectedSite
}) {
  let data = [];
  let err;
  logger.debug('getEventTagsByEntity ', arguments);
  const systemTags = await getSystemEventTags();
  if (systemTags.err) {
    err = err || systemTags.err;
    logger.error('getEventTagsByEntity systemTags ', systemTags.err);
  } else {
    data.push(...systemTags.data);
  }

  if (selectedCompany || selectedDomain) {
    const nodeTags = await getEventTagsBySelectedNodeId({selectedCompany, selectedDomain});
    if (nodeTags.err) {
      err = err || nodeTags.err;
      logger.error('getEventTagsByEntity nodeTags %o %o %o', selectedDomain, selectedCompany, nodeTags.err);
    } else {
      data.push(...nodeTags.data);
    }
  } 
  logger.debug('getEventTagsByEntity ', data);

  return { data, err };
}

export async function createEventTag({ companyId, name, naturalKey, translations }) {
  let data;
  let err;
  const url = companyId == null ? `/EventTags` : `/Companies/${companyId}/eventTags`;
  try {
    const res = await api.post(url, { companyId, name, naturalKey, translations });
    data = res && res.data;
    logger.debug('createEventTag Success data: ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('createEventTag %o', err);
  }
  return { data, err };
}

export async function editCompanyEventTag({ id, companyId, name, naturalKey, translations }) {
  let data;
  let err;
  const url = `/EventTags/${id}`;
  try {
    const res = await api.patch(url, { companyId, name, naturalKey, translations });
    data = res && res.data;
    logger.debug('editCompanyEventTag Success data: ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('editCompanyEventTag %o', err);
  }
  return { data, err };
}

export async function deleteCompanyEventTag(id) {
  let data;
  let err;
  const url = `/EventTags/${id}`;
  try {
    const res = await api.patch(url, { active: false });
    data = res && res.data;
    logger.debug('deleteCompanyEventTag Success data: ', data);
  } catch (ex) {
    err = getAxiosError(ex);
    logger.error('deleteCompanyEventTag %o', err);
  }
  return { data, err };
}
