import React from 'react';

import Selector from '../Helpers/Selector';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import i18n from 'app/i18n';

const Divider = styled.div`
  clear: both;
  border-bottom: 1px solid #ccc;
`;

const DeleteNote = styled.svg`
  margin-right: 20px;
  margin-top: 15px;
  cursor: pointer;
`;

const SecondSelector = ({ nodeDataArray, assetMouseEnter, assetMouseOut, onSelect, firstItem, deleteFirstItem, undoAddNode }) => {


  const outVectorAvaibles = [];
  const { vectors } = firstItem.item.outFlow;
  vectors.forEach((vector) => {
    if (vector.vector === undefined || vector.instances === undefined || vector.instances === null || vector.instances > vector.vector.length) {
      outVectorAvaibles.push(vector.id);
    }
  });

  const items = [];

  nodeDataArray.forEach((nda) => {
    let added = false;
    const { vectors } = nda.item.inFlow;
    let vectorsArray = [];
    if (vectors !== null && vectors !== undefined) {
      vectorsArray = Object.values(vectors);
    }
    vectorsArray.forEach((vector) => {
      if (vector.vector === undefined || vector.instances === undefined || vector.instances === null || vector.instances > vector.vector.length) {
        const vectorId = vector.id;
        if (!added && outVectorAvaibles.indexOf(vectorId) >= 0 && firstItem.key !== nda.key) {
          items.push(nda);
        }
        added = true;
      }
    });
  });

  if (items.length === 1) {
    onSelect(items[0]);
  }
  let i = null;
  const productor = firstItem.property !== undefined && firstItem.property.productor !== undefined ? firstItem.property.productor : null;
  const model = firstItem.property !== undefined && firstItem.property.model !== undefined ? firstItem.property.model : null;
  if (productor !== null && model !== null) {
    i = (
      <i>
        {productor}
        &nbsp;-&nbsp;
        {model}
      </i>
    );
  } else if (productor != null) {
    i = (
      <i>
        {productor}
      </i>
    );
  } else if (model != null) {
    i = (
      <i>
        {model}
      </i>
    );
  }

  return (
    <div className="selection show node">
      <div>
        <div style={{ marginLeft: '15px' }}>
          <div style={{ float: 'left' }}>
            <strong><Trans>Primo nodo selezionato</Trans>:</strong>
            <br />
            <span>
              {firstItem.name}
            </span>
            {i}
          </div>
          <div
            style={{ float: 'right' }}
            onClick={() => { deleteFirstItem() }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <DeleteNote
              aria-hidden="true"
              data-prefix="far"
              data-icon="times-circle"
              className="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
              />
            </DeleteNote>
          </div>
        </div>
        <Divider />
        <div>
          <strong style={{ marginLeft: '15px' }}><Trans>Seleziona il secondo energy assets</Trans></strong>
          <Selector
            style={{ paddingTop: '0px' }}
            items={items}
            onMouseIn={assetMouseEnter}
            onMouseOut={assetMouseOut}
            onSelect={onSelect}
            onBlur={() => {}}
            searchText={i18n._(t`Cerca il secondo energy assets per il link`)}
          />
        </div>
      </div>
    </div>
  );
}
export default SecondSelector;
