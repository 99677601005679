import React from 'react';
import { BeaDomainPlantsSelected } from './helpers';
import styled from 'styled-components';

const StyledDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
const SelectedPlantsList = props => {
  const { domainSelectedPlants, getPlantName } = props;
  const beaDomainPlants = BeaDomainPlantsSelected(domainSelectedPlants);

  const namePlants = (type, star) => {
    return beaDomainPlants
      .getPlantList(type, star)
      .map(id => ({ name: getPlantName(id, type), star: type === 'site' || type === 'sitegroup' ? true: star }));
  };

  const allPlants = [
    ...namePlants('domain', true),
    ...namePlants('domain', false),
    ...namePlants('company', true),
    ...namePlants('company', false),
    ...namePlants('site', false),
    ...namePlants('sitegroup', true)
  ];

  function name2string({ star, name = '' }, index) {
    const last = index === this.length - 1;
    return `${name}${star ? name && '*' : ''}${last ? '' : name && ', '}`;
  }

  return <StyledDiv>{allPlants.map(name2string.bind(allPlants))}</StyledDiv>;
};

export default SelectedPlantsList;
