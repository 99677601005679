import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

const H5 = styled.h5`
    && {
        color: ${props => props.file ? props.theme.common.brand.primaryColor : props.theme.sidebar.color};
        margin: auto 1rem;
    }
`;
const Button = styled.button`
    && {
        background-color: unset;
        border: 0;
        position: absolute;
        z-index: 9;
        font-size: 2rem;
        color: ${props => props.theme.common.brand.primaryColor};
        right: 1rem;
        height: calc(100% - 2rem);
        font-weight: 700;
    }
`;
const Flex = styled.div`
    && {
        display: flex;
        height: 100%;
    }
`;
const Wrapper = styled.div`
    && { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%
    }
    && .invalid-feedback {
            display: block;
    }
`;
const DisplayBox = styled.div`
    && {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;
const UploadText = styled.div`
    && {
        text-align: center;
        padding: 0.6rem;
        background-color: ${props => props.file ? '#fafafa' : ''};
        width: ${props => props.file ? 'calc(100% - 0.5rem)' : ''};
        height: ${props => props.file ? 'calc(100% - 0.5rem)' : ''};
        border-radius: ${props => props.file ? '0.8rem' : ''};
    }
`;
const TextBox = styled.div`
    && {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: dashed;
        width: 100%;
        height: 5rem;
        text-shadow: 0px 0px 0 #999;
        transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;
    }
`;
const FileInput = styled.input`
    && {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       opacity: 0;
       cursor: pointer;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    height: 100%;
    width: 3rem !important;
    color: ${props => props.theme.common.brand.primaryColor};
`;

const DragAndDrop = (props) => {
    const { onDrop, onDragEnter, onDragOver, onDragLeave, onChange, handleCancelUpload, file, isCsv, fieldInfo } = props;

    const uploadText = file
        ? <Flex>
            <Icon icon={isCsv ? 'file-csv' : 'file-excel'} />
            <H5 file={file}>{file.name}
            </H5>
            <Button onClick={handleCancelUpload}>&times;</Button>
        </Flex>
        : <div>
            <H5><Trans>Trascina qui il file CSV o Excel con i dati da caricare</Trans>,</H5>
            <H5><Trans>oppure clicca qui per selezionarlo da una cartella</Trans></H5>
        </div>;

    return (
        <Wrapper>
            <DisplayBox>
                <TextBox>
                    <UploadText file={file}>
                        {uploadText}
                    </UploadText>
                </TextBox>
                <FileInput
                    type='file'
                    id='upload-input'
                    onDrop={onDrop}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onChange={onChange}
                />
            </DisplayBox>
            {fieldInfo.invalid && <div class="invalid-feedback">{fieldInfo.error}</div>}
        </Wrapper>
    );
}

export default DragAndDrop;
