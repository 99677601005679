import React, { useState } from 'react';
import { ToastContainer, toast } from '../../../../../node_modules/react-toastify/index';
import { DynamicForm } from 'app/common';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loadDomains } from 'ducks/actions/navigation';

const CreateDomain = (props) => {

    const { history, url, loadDomains } = props;
    const [redirect, setRedirect] = useState(false);

    const submit = async (values) => {

        const body = {
            "name": values['name'],
            "enableFunctionalities": {},
        }

        const res = await api.post('/Domains', body, { validateStatus: () => true });
        if (res.status >= 200 && res.status < 300) {
            loadDomains();
            toast.success("Dominio creato");
            setTimeout(() => {
                setRedirect(true);
            }, 2000);

        } else if (res.status >= 300 && res.status < 400) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 400 && res.status < 500) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 500) {
            toast.warn(res.data.error.message);
        }

    };

    const cancel = () => {
        history.goBack();
    };

    return (
        <div>
            <ToastContainer></ToastContainer>

            {!redirect ? (<DynamicForm
                card
                title={<Trans>Crea dominio</Trans>}
                onSubmit={submit}
                onCancel={cancel}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        label: <Trans>Nome</Trans>,
                        validation: {
                            required: true, minLength: 3
                        },
                    }
                ]}
            >
            </DynamicForm>) : (<Redirect to={`${url}`} ></Redirect>)}
        </div>
    );
}

CreateDomain.propTypes = {
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { domains } = state.navigation;
    return {
        domains,
    };
};

const mapDispatchToProps = dispatch => ({
    loadDomains: () => dispatch(loadDomains()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDomain);