import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './progress-bar.css';

const Container = styled.div`
    display: inline-block;
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    border-radius: 5rem;
    height: max-content;
    background-color: #afbacd;
`;

const Progress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: ${props => props.color};
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    width: ${props => `${props.percentage}%`}
`;

const StyledSpan = styled.span`
    padding: 0.3rem;    
`;

const getProgressInfo = statusValue => {
    let result = { color: '#007bff', percentage: 0, label: '' };
    switch (statusValue && statusValue.toLowerCase()) {
        case 'running':
            result.label = 'Ricalcolo valori';
            result.percentage = 30;
            result.color = '#007bff';
            break;
        case 'saving':
            result.label = 'Ricalcolo valori';
            result.percentage = 50;
            result.color = '#007bff';
            break;
        case 'aggregating':
            result.label = 'Aggregazione valori';
            result.percentage = 70;
            result.color = '#007bff';
            break;
        case 'completed':
            result.label = 'Ricalcolo completato';
            result.percentage = 100;
            result.color = '#00aa00';
            break;
        case 'done':
            result.label = 'Ricalcolo completato';
            result.percentage = 100;
            result.color = '#00aa00';
            break;
        case 'error':
            result.label = 'Error';
            result.percentage = 100;
            result.color = '#dc3545';
            break;
        default:
            result.percentage = 100;
            result.color = '#007bff';
    }
    return result;
};

const ProgressBar = ({ status }) => {
    const { value, details } = status;
    const { color, percentage, label } = getProgressInfo(value);
    return value ? (
        <Container>
            <Progress percentage={percentage} color={color}>
                <StyledSpan>
                    {value === 'error' ? `Errore: ${details}` || 'Errore' : label}
                </StyledSpan>
            </Progress>
        </Container>
    ) : null;
};

ProgressBar.propTypes = {
    status: PropTypes.shape({
        value: PropTypes.string,
        details: PropTypes.string
    }),
};

ProgressBar.defaultProps = {
    status: {}
};

export default ProgressBar;
