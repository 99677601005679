import React from 'react';

const GatewaysContext = React.createContext({
  supportedDevices: [],
  fieldGateways: [],
  onEditGateway: () => {},
  onAddGateway: () => {},
  onAddDevice: () => {},
  onEditDevice: () => {},
  onAddSupportedDevice: () => {}
});

export default GatewaysContext;
