import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import Varie from '../Helpers/Varie';
import { Trans } from '@lingui/macro';
import { getTranslatedString } from '../TranslateData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const InfoDiv  = styled.div`
  position: absolute;
  right: -470px;
  width: 450px;
  transition: right 0.5s;
  top:-20px;
  z-index: 9;
  min-height: 100%;
  box-shadow: -4px 0px 4px 0px rgba(158,158,158,0.4);
  &.show{
    right: -16px;
  }
`;

const InfoHead = styled.div`
    background-color: ${props => props.theme.common.brand.primaryColor};
    color: white;
    padding: 15px;
`;

const HeadImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: white;
  float:left;
  padding: 5px;
  img{
    width: 100%;
  }
`

const HrHead = styled.hr`
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
`

const HeadName = styled.div`
  width: 330px;
  float: left;
  padding-left:10px;
  h4{
    color: white;
    t-weight: 700;
    font-size: 1.5rem
  }
`

const CloseIcon = styled.div`
  float: right;
  background-color:rgba(255, 255, 255, 0.2);
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
`
const LabelFlow = styled.div `
  color: white;
  display: inline-block;
  float: left;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 11px;
  margin-top: 3px;
  border-radius: 2px;
`;

const HeadInfo = styled.div`
  line-height: 1;
  .id{
    float: left;
    width: 126px;
  }
  .tipo{
    float: left;
    margin-left: 5px;
    width: 289px;
  }
  
  .marca-modello{
    float: left;
    margin-right:5px
    width: 126px;
  }
  .info-title
  {
    font-size: .75rem;
    opacity: 0.6; 
  }

`;

const ContentSideData = styled.div`
  overflow: auto;
  padding: 15px;
`;

const ParamName = styled.div`
     float: left;
     font-weight: bold;
     font-size: .85rem; 
`;


const ParamValue = styled.div`
  float: right;
  color: #999;
`;

class NodeInfo extends React.Component {
  state = {
    height: 0,
  };

  constructor(props) {
    super(props);
    this.lastSelectedNodeKeys = null;
  }

  componentDidUpdate(){
    this.setHeight();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.varie = new Varie();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setHeight = (element) => {
    if (element === null || element === undefined) {
      element = document.getElementById("containerSide");
      if (element === null || element === undefined) {
        return;
      }
    }
    let h2 = 0;
    if (document.getElementById("headInfo") !== null) {
      h2 = window.innerHeight - document.getElementById("headInfo").offsetHeight + 107;
      element.style.minHeight = `${h2}px`

      let fh =  element.offsetHeight + document.getElementById("headInfo").offsetHeight - 35;
      let container = document.getElementsByClassName("container-fluid")[0];
      container.style.minHeight = `${fh}px`;     
    }
  }

  updateWindowDimensions = () => {
    const h = window.innerHeight - 125;
   
    
    this.setState({ height: `${h}px` });
  }

  getMeasureUnitFromUnitNaturalKey = (unitNaturalKey) => {
    const { catalogs } = this.props;
    const { units } = catalogs;
    const unit = units.find(un => un.naturalKey === unitNaturalKey);
    if (unit == null) {
      return null;
    }
    return unit.symbol;
  }

  getMeasureUnitFromPhysicalQuantity = (phdNaturalKey) => {
    const { catalogs } = this.props;
    const { physicalQuantities, units } = catalogs;
    const physicalQuantity = physicalQuantities.find(pq => pq.naturalKey === phdNaturalKey);
    if (physicalQuantity == null) {
      return null;
    }

    const phunit = physicalQuantity.defaultVisualizationUnit?physicalQuantity.defaultVisualizationUnit:units[0];
    const unit = units.find(un => un.naturalKey === phunit);
    if (unit == null) {
      if (physicalQuantity.units[0]) {
        return this.getMeasureUnitFromUnitNaturalKey(physicalQuantity.units[0]);
      }
      return null;
    }
    return unit.symbol;
  }

  getPhysicalQuantity = (phdNaturalKey) => {
    const { catalogs } = this.props;
    const { physicalQuantities } = catalogs;
    const physicalQuantity = physicalQuantities.find(pq => pq.naturalKey === phdNaturalKey);
    if (physicalQuantity == null) {
      return null;
    }
    return physicalQuantity;
  }

  renderParameters = () => {
    const { nodeDataArray, linkDataArray } = this.props;
    let { selectedNodeKeys } = this.props;
    if (selectedNodeKeys && selectedNodeKeys.length === 1) {
      ([selectedNodeKeys] = selectedNodeKeys);
    } else {
      ([selectedNodeKeys] = this.lastSelectedNodeKeys);
    }
    const index = nodeDataArray.findIndex(node => node.key === selectedNodeKeys);
    const node = nodeDataArray[index];
    let technicalData = node.property !== undefined && node.property.technicalData !== undefined ? node.property.technicalData : '';
    const parametersHTML = [];
    let { staticParameters, otherStaticParameters } = node.item;

    let otherNodeKey = null;
    if (node.addedTo !== undefined) {
      otherNodeKey = node.addedTo;
    }
    if (node.addon !== undefined) {
      otherNodeKey = node.addon;
    }
    if (otherNodeKey !== null) {
      const node2 = nodeDataArray.find(node => node.key === otherNodeKey);
      const technicalData2 = node2.property !== undefined && node2.property.technicalData !== undefined ? node2.property.technicalData : {};
      const staticParameters2 = node2.item.staticParameters;
      staticParameters = staticParameters.concat(staticParameters2);
      technicalData = Object.assign({}, technicalData,technicalData2);
    }

    
    staticParameters.forEach((parameter) => {
      if (parameter.group !== undefined || parameter.perFlow !== undefined){
        linkDataArray.forEach((flow) => {
          let flowOK = false;
          if (flow.vectorItem.category === parameter.perFlow.category){
            flowOK = (parameter.perFlow.direction === "in" && flow.to === node.key) || (parameter.perFlow.direction === "out" && flow.from === node.key)
          }
          if (flowOK) {
            parameter.group.forEach((elem) => {
              let oldValue = '';
              if (technicalData[parameter.name] && technicalData[parameter.name][elem.name] && technicalData[parameter.name][elem.name][flow.id] && technicalData[parameter.name][elem.name][flow.id] !== '') {
                oldValue = getTranslatedString('values', technicalData[parameter.name][elem.name][flow.id], parameter.name);
                
                if (elem.type === 'multiselect') {
                  const oldValueData = [];
                  for (let i = 0; i < oldValue.length; i += 1) {
                    oldValueData.push(getTranslatedString('values', oldValue[i].value, elem.name));
                  }
                  oldValue = oldValueData.join(', ');
                }
                const paramName = getTranslatedString('staticParameters', elem.name);
                let um = null;
                if (elem.physicalQUantityNK === 'custom') {
                  um = elem.customUnit;
                  const { graphData, catalogs  } = this.props;
                  const { assets, assetFlows } = graphData;
                  const { vectors } = catalogs;
                  let combustibile = "?";
                  const currentAsset = assets.find(ass => ass.id === selectedNodeKeys);
                  if(currentAsset && currentAsset.inFlowIds){
                    for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
                      const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
                      if (flow) {
                        const vector = vectors.find(vec => vec.naturalKey === flow.vector);
                        if((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili" ){
                          const naturalkey = vector.variables && vector.variables[0] ? vector.variables[0] : false;
                          if(naturalkey){
                            combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                          }
                        }
                      }
                    }
                  }
                  um = um.replace("{combUM}", combustibile);
                } else if (elem.unitNK !== undefined) {
                  um = this.getMeasureUnitFromUnitNaturalKey(elem.unitNK);  
                } else {
                  um = this.getMeasureUnitFromPhysicalQuantity(elem.physicalQUantityNK);  
                }
                const div = (
                <div>
                  <ParamName>
                    {paramName}
                  </ParamName>
                  <ParamValue>
                  {oldValue}
                  &nbsp;
                  {um}

                   </ParamValue>
                  <div className='clearfix' />
                  <hr />
                </div>

              );
              parametersHTML.push(div);
              }
            });
          }
        });
      }else{
        let oldValue = '';
        if (technicalData[parameter.name] && technicalData[parameter.name] !== '') {
          oldValue = getTranslatedString('values', technicalData[parameter.name], parameter.name);

          if (parameter.type === 'multiselect') {
            const oldValueData = [];
            for (let i = 0; i < oldValue.length; i += 1) {
              oldValueData.push(getTranslatedString('values', oldValue[i].value, parameter.name));
            }
            oldValue = oldValueData.join(', ');
          }
          const paramName = getTranslatedString('staticParameters', parameter.name);
          let um = null;
          if (parameter.physicalQUantityNK) {
            if (parameter.physicalQUantityNK === 'custom') {
              um = parameter.customUnit;
              const { graphData, catalogs  } = this.props;
              const { assets, assetFlows } = graphData;
              const { vectors } = catalogs;
              let combustibile = "?";
              const currentAsset = assets.find(ass => ass.id === selectedNodeKeys);
              if(currentAsset && currentAsset.inFlowIds){
                for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
                  const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
                  if (flow) {
                    const vector = vectors.find(vec => vec.naturalKey === flow.vector);
                    if((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili" ){
                      const naturalkey = vector.variables && vector.variables[0] ? vector.variables[0] : false;
                      if(naturalkey){
                        combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                      }
                    }
                  }
                }
              }
              um = um.replace("{combUM}", combustibile);
            } else {
              if (parameter.unitNK !== undefined) {
                um = this.getMeasureUnitFromUnitNaturalKey(parameter.unitNK);  
                if (um == null) {
                  um = parameter.unitNK
                }
              }else{
                um = this.getMeasureUnitFromPhysicalQuantity(parameter.physicalQUantityNK);  
              }
            }
          }
          const div = (
            <div>
                  <ParamName>
                    {paramName}
                  </ParamName>
                  <ParamValue>
                  {oldValue}
                  &nbsp;
                  {um}

                   </ParamValue>
                  <div className='clearfix' />
                  <hr />
                </div>
          );
          parametersHTML.push(div);
        }
      }
    });
    if(otherStaticParameters && otherStaticParameters != null && otherStaticParameters.length > 0 ){
      otherStaticParameters.forEach((parameter) => {
        if (parameter.group !== undefined || parameter.perFlow !== undefined){
          linkDataArray.forEach((flow) => {
            let flowOK = false;
            if (flow.vectorItem.category === parameter.perFlow.category){
              flowOK = (parameter.perFlow.direction === "in" && flow.to === node.key) || (parameter.perFlow.direction === "out" && flow.from === node.key)
            }
            if (flowOK) {
              parameter.group.forEach((elem) => {
                let oldValue = '';
                if (technicalData[parameter.name] && technicalData[parameter.name][elem.name] && technicalData[parameter.name][elem.name][flow.id] && technicalData[parameter.name][elem.name][flow.id] !== '') {
                  oldValue = getTranslatedString('values', technicalData[parameter.name][elem.name][flow.id], parameter.name);
                  
                  if (elem.type === 'multiselect') {
                    const oldValueData = [];
                    for (let i = 0; i < oldValue.length; i += 1) {
                      oldValueData.push(getTranslatedString('values', oldValue[i].value, elem.name));
                    }
                    oldValue = oldValueData.join(', ');
                  }
                  const paramName = getTranslatedString('otherStaticParameters', elem.name);
                  let um = null;
                  if (elem.physicalQUantityNK === 'custom') {
                    um = elem.customUnit;
                    const { graphData, catalogs  } = this.props;
                    const { assets, assetFlows } = graphData;
                    const { vectors } = catalogs;
                    let combustibile = "?";
                    const currentAsset = assets.find(ass => ass.id === selectedNodeKeys);
                    if(currentAsset && currentAsset.inFlowIds){
                      for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
                        const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
                        if (flow) {
                          const vector = vectors.find(vec => vec.naturalKey === flow.vector);
                          if((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili" ){
                            const naturalkey = vector.variables && vector.variables[0] ? vector.variables[0] : false;
                            if(naturalkey){
                              combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                            }
                          }
                        }
                      }
                    }
                    um = um.replace("{combUM}", combustibile);
                  }else  if (elem.unitNK !== undefined) {
                    um = this.getMeasureUnitFromUnitNaturalKey(elem.unitNK);  
                  }else{
                    um = this.getMeasureUnitFromPhysicalQuantity(elem.physicalQUantityNK);  
                  }
                  const div = (
                  <div>
                  <ParamName>
                    {paramName}
                  </ParamName>
                  <ParamValue>
                  {oldValue}
                  &nbsp;
                  {um}

                   </ParamValue>
                  <div className='clearfix' />
                  <hr />
                </div>
                );
                parametersHTML.push(div);
                }
              });
            }
          });
        }else{
          let oldValue = '';
          if (technicalData[parameter.name] && technicalData[parameter.name] !== '') {
            oldValue = getTranslatedString('values', technicalData[parameter.name], parameter.name);

            if (parameter.type === 'multiselect') {
              const oldValueData = [];
              for (let i = 0; i < oldValue.length; i += 1) {
                oldValueData.push(getTranslatedString('values', oldValue[i].value, parameter.name));
              }
              oldValue = oldValueData.join(', ');
            }
            const paramName = getTranslatedString('otherStaticParameters', parameter.name);
            let um = null;
            if (parameter.physicalQUantityNK === 'custom') {
              um = parameter.customUnit;
              const { graphData, catalogs  } = this.props;
              const { assets, assetFlows } = graphData;
              const { vectors } = catalogs;
              let combustibile = "?";
              const currentAsset = assets.find(ass => ass.id === selectedNodeKeys);
              if(currentAsset && currentAsset.inFlowIds){
                for (let i = 0; i <= currentAsset.inFlowIds; i += 1) {
                  const flow = assetFlows.find(af => af.id === currentAsset.inFlowIds[i]);
                  if (flow) {
                    const vector = vectors.find(vec => vec.naturalKey === flow.vector);
                    if((vector && (vector.category === "Altri Combustibili")) || vector.category === "Combustibili" ){
                      const naturalkey = vector.variables && vector.variables[0] ? vector.variables[0] : false;
                      if(naturalkey){
                        combustibile = this.getMeasureUnitFromPhysicalQuantity(naturalkey);
                      }
                    }
                  }
                }
              }
              um = um.replace("{combUM}", combustibile);
            }else if (parameter.unitNK !== undefined) {
              um = this.getMeasureUnitFromUnitNaturalKey(parameter.unitNK);  
            } else {
              um = this.getMeasureUnitFromPhysicalQuantity(parameter.physicalQUantityNK);  
            }
            const div = (
              <div>
                  <ParamName>
                    {paramName}
                  </ParamName>
                  <ParamValue>
                  {oldValue}
                  &nbsp;
                  {um}

                   </ParamValue>
                  <div className='clearfix' />
                  <hr />
                </div>
            );
            parametersHTML.push(div);
          }
        }
      });
    }
    return parametersHTML;
  }

  renderComplesso = () => {
    const { nodeDataArray, siteId } = this.props;
    let { selectedNodeKeys } = this.props;
    let complesso = null;
    if (selectedNodeKeys && selectedNodeKeys.length === 1) {
      ([selectedNodeKeys] = selectedNodeKeys);
    } else {
      ([selectedNodeKeys] = this.lastSelectedNodeKeys);
    }
    const index = nodeDataArray.findIndex(node => node.key === selectedNodeKeys);
    if (nodeDataArray[index].isComplex) {
      const link = `/sites/${siteId}/management/sub/${selectedNodeKeys}`;
      complesso = <div><a href={link}><Trans>Visualizza il grafo complesso</Trans></a></div>;
    }
    return complesso;
  }

  renderGruppi() {
    const { assets, nodeDataArray, assetGroups } = this.props;
    let { selectedNodeKeys } = this.props;
    if (selectedNodeKeys && selectedNodeKeys.length === 1) {
      ([selectedNodeKeys] = selectedNodeKeys);
    } else {
      ([selectedNodeKeys] = this.lastSelectedNodeKeys);
    }
    const node = nodeDataArray.find(node => node.key === selectedNodeKeys);
    const gruppiHTML = [];
    if (assets) {
      const asset = assets.find(ass => ass.id === node.key);
      if (asset) {
        const { groupIds } = asset;
        if (groupIds) {
          groupIds.forEach((id) => {
            let group = assetGroups.find(gr => gr.id === id)
            const { name } = group;
            gruppiHTML.push(<Badge color="primary" style={{ marginRight: '5px' }}>{name}</Badge>);
          });
        }
      }
    }
    return gruppiHTML;
  }

  renderMeter = () => {
    const retHtml = [];
    const { nodeDataArray, linkDataArray, graphData } = this.props;
    let { selectedNodeKeys } = this.props;
    if (selectedNodeKeys && selectedNodeKeys.length === 1) {
      ([selectedNodeKeys] = selectedNodeKeys);
    } else {
      ([selectedNodeKeys] = this.lastSelectedNodeKeys);
    }
    const { assets, assetFlows } = graphData;
    const asset = assets.find(ass => ass.id === selectedNodeKeys);
    const node = nodeDataArray.find(nd => nd.key === selectedNodeKeys);
    let vars = node.item.dynamicParameters;
    if (vars === undefined) {
      vars = [];
    }
    let vars2 = node.item.actuationParameters;
    if(vars2 != null)
    {
      vars = vars.concat(vars2);
    }

    const { measures } = asset;
    for (let i = 0; i < measures.length; i += 1) {

      if (measures[i].display) {
        const { value, vName } = measures[i];

        //const vr = vars[variableIndex];
        const vr = vars.find(v => v.name === vName);
        if(vr !== undefined){
          let { name } = vr;
          name = getTranslatedString('dinamycParameters', name);
          retHtml.push(
            <div>
              <ParamName>{name}</ParamName>
              <ParamValue>{value || '---'}</ParamValue>
              <div className='clearfix' />
              <hr />
            </div>,
          );
        }
      }
    }
    /*if (htmlAsset.length > 0) {
      retHtml.push(htmlAsset);
    }*/


    const inFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.to === selectedNodeKeys);

    inFlowIndex.forEach((ofIndex) => {
      const flow = linkDataArray[ofIndex];

      const assetFlow = assetFlows.find(af => af.id === flow.id);

      let vars = flow.vectorItem.variables;
      if (!Array.isArray(vars)) {
        vars = [];
        vars.push(flow.vectorItem.variables);
      }
      const flowMeasures = assetFlow.destinationMeasures;
      const otherNode = nodeDataArray.find(nd => nd.key === flow.from);
      flowMeasures.forEach((measure) => {
        const { vNk, value } = measure;
        const vr = this.getPhysicalQuantity(vNk);
        if (measure.display) {
          retHtml.push(
            <div>
              <ParamName>
                <LabelFlow
                  style={{backgroundColor: flow.color}}
                >
                  <Trans>IN</Trans>
                </LabelFlow>&nbsp;
            
                {otherNode.name}
                <br />
                {vr.name}
              </ParamName>
              <ParamValue style={{ marginTop: "7px" }}>{value || '---'}</ParamValue>
              <div className='clearfix' />
              <hr />
            </div>,
          );
        }
      });
    });


    /*if (htmlIn.length > 0) {
      retHtml.push(htmlIn);
    }*/
    const outFlowIndex = this.varie.findIndexes(linkDataArray, nd => nd.from === selectedNodeKeys);
    outFlowIndex.forEach((ofIndex) => {
      const flow = linkDataArray[ofIndex];

      const assetFlow = assetFlows.find(af => af.id === flow.id);

      let vars = flow.vectorItem.variables;
      if (!Array.isArray(vars)) {
        vars = [];
        vars.push(flow.vectorItem.variables);
      }
      const flowMeasures = assetFlow.sourceMeasures;
      const otherNode = nodeDataArray.find(nd => nd.key === flow.to);
      flowMeasures.forEach((measure) => {
        const { vNk, value } = measure;
        const vr = this.getPhysicalQuantity(vNk);
        if (measure.display) {
          retHtml.push(
            <div>
              <ParamName>

                <div style={{float: "right"}}>
                <LabelFlow
                  style={{ color:"white", display: 'inline-block', backgroundColor: flow.color, float:"left"}}
                >
                  <Trans>OUT</Trans>
                </LabelFlow>&nbsp;
                  {otherNode.name}
                  <br />
                  
                  {vr.name}
                </div>
              </ParamName>
              <ParamValue style={{ marginTop: "7px" }}>{value || '---'}</ParamValue>
              <div className='clearfix' />
              <hr />
            </div>,
          );
        }
      });
    });

    /*if (htmlOut.length > 0) {
      retHtml.push(htmlOut);
    }*/
    return retHtml;
  }

  render() {
    const { nodeDataArray, parentGraphId } = this.props;
    let { selectedNodeKeys } = this.props;
    const infoClassName = selectedNodeKeys.length === 1 ? "show" : null;
    if ((selectedNodeKeys && selectedNodeKeys.length === 1) || (this.lastSelectedNodeKeys  && this.lastSelectedNodeKeys.length === 1)) {
     
      if (selectedNodeKeys && selectedNodeKeys.length === 1) {
        this.lastSelectedNodeKeys  = JSON.parse(JSON.stringify(selectedNodeKeys));
        ([selectedNodeKeys] = selectedNodeKeys);
      } else {
        ([selectedNodeKeys] = this.lastSelectedNodeKeys);
      }
      
      const node = nodeDataArray.find(node => node.key === selectedNodeKeys);
      const htmlMeter = this.renderMeter();
      let nodeId = JSON.parse(JSON.stringify(node.key));
      if (parentGraphId) {
        nodeId = `${parentGraphId}-${node.key}`;
      }
      let nodeName = JSON.parse(JSON.stringify(node.item.name));
      if(node.addedTo !== undefined)
      {
        const node2 = nodeDataArray.find(nd => nd.key === node.addedTo);
        nodeId = JSON.parse(JSON.stringify(node2.key));
        nodeName = JSON.parse(JSON.stringify(node2.item.name));
      }
      nodeName = getTranslatedString('asset', nodeName);
      const gruppiHTML = this.renderGruppi();
      const make = node.property !== undefined && node.property.make !== undefined ? node.property.make : '-';
      const inventory = node.property !== undefined && node.property.inventory !== undefined ? node.property.inventory : '-';
      const model = node.property !== undefined && node.property.model !== undefined ? node.property.model : '-';
      const parametersHTML = this.renderParameters();
      const complesso = this.renderComplesso();
      return (
        <InfoDiv className={infoClassName} style={{ paddingRight: '0px' }}>
      
        <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
            <InfoHead id="headInfo">
              <HeadImage>
                <img src={node.source} />
              </HeadImage>
              <HeadName>
                  <CloseIcon onClick={this.props.onClose}><FontAwesomeIcon icon={faTimes} /></CloseIcon>
                  <div className='clearfix' />
                  <h4> {node.name}</h4>
              </HeadName>
              <div className='clearfix' />
              <HrHead />
              <HeadInfo>
                <div className='id'>
                  <div className='info-title'><Trans>Id</Trans></div>
                  {nodeId}
                </div>
                <div className='tipo'>
                  <div className='info-title'><Trans>Tipo</Trans></div>
                  {nodeName}
                </div>
                <div className='clearfix' />
              </HeadInfo>
              {make !== '' || model !== '' || inventory !== '' ? (
              <br />
              ) : ''}
              <HeadInfo>
                {make !== '' ? (
                  <div className='marca-modello'>
                    <div className='info-title'><Trans>Produttore</Trans></div>
                    {make}
                  </div>
                ) : '' }

                {model !== '' ? (
                  <div className='marca-modello'>
                    <div className='info-title'><Trans>Modello</Trans></div>
                    {model}
                  </div>
                ) : '' }

                {inventory !== '' ? (
                  <div className='marca-modello'>
                    <div className='info-title'><Trans>N. inventario</Trans></div>
                    {inventory}
                  </div>
                ) : '' }

                <div className='clearfix' />

              </HeadInfo>

              <div className='clearfix' />
              {gruppiHTML}
              <div className='clearfix' />
            </InfoHead>
          <ContentSideData id='containerSide' ref={this.setHeight}>
            {parametersHTML}
            {htmlMeter}
            {complesso}
          </ContentSideData>
        </div>
        </InfoDiv>
      );
    }
    return (
      <InfoDiv style={{ paddingRight: '0px',    backgroundColor: 'white' }} />
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

export default connect(mapStateToProps)(NodeInfo);
