import React from "react";
import { InputWithIcon } from 'app/common';
import i18n from 'app/i18n';
import { t } from '@lingui/macro';

const searchStyle = {
    borderRadius: '0', 
    borderTop: '0', 
    borderRight: '0', 
    borderLeft: '0', 
    borderBottom: '1px solid #ddd',
    height: '3rem'
 };

const ViewerSearch = ({ onChange }) => {
    return (
        <InputWithIcon
            fullWidth
            autoFocus
            placeholder={i18n._(t`Cerca`)}
            onChange={onChange}
            righticon="search"
            style={searchStyle} />
    );
};


export default ViewerSearch;