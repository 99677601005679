import React from 'react';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';

const ViewerSelectAll = (props) => {
    const CheckBox = styled(FontAwesomeIcon)`
      margin: 0 .7rem 0 .3rem;
      min-width: 1.2rem;
      color: ${props => (props.checked ? `${props.theme.common.brand.primaryColor}` : '#aaa')};
    `;

    const Item = styled.div`
        display: inline-block;
        padding: .2rem .6rem;
        ${props => props.isAllSelected && 'color: #1F71B7'};
        cursor: pointer;

        &:hover {
          span, svg {
            color: #03c3cc;
          }
        }
    `;

    const { onClick, isAllSelected } = props;
    return (
        <Item onClick={onClick}>
            <CheckBox icon={isAllSelected  ? 'check-square' : ['far', 'square']} checked={isAllSelected } />
            <Label>{isAllSelected ? <Trans>Deseleziona tutto</Trans> : <Trans>Seleziona tutto</Trans>}</Label>
        </Item>
    )
};

export default ViewerSelectAll;
