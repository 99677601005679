/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EditInline extends Component {
    state = {
      editing: false,
    }

    toggleEdit = (e) => {
      e.stopPropagation();
      if (this.state.editing) {
        this.cancel();
      } else {
        this.edit();
      }
    };

    edit = () => {
      this.setState({
        editing: true,
      }, () => {
        this.domElm.focus();
        document.execCommand('selectAll', false, null);
      });
    };

    save = () => {
      this.setState({
        editing: false,
      }, () => {
        if (this.props.onSave && this.isValueChanged()) {
          this.props.onSave(this.domElm.textContent);
        }
      });
    };

    cancel = () => {
      this.setState({
        editing: false,
      });
    };

    isValueChanged = () => this.props.value !== this.domElm.textContent;

    handleKeyDown = (e) => {
      const { key } = e;
      switch (key) {
        case 'Enter':
        case 'Escape':
          this.save();
          break;
        default:
          break;
      }
    };

    render() {
      const { editing } = this.state;
      let { editonclick } = this.props;
      if (editonclick === undefined) editonclick = true;

      return (
        <span
          className={editing ? 'editing' : ''}
          suppressContentEditableWarning
          onDoubleClick={editonclick ? this.toggleEdit : undefined}
          contentEditable={editing}
          ref={(domNode) => {
            this.domElm = domNode;
          }}
          onBlur={this.save}
          onKeyDown={this.handleKeyDown}
        >
          {this.props.value}
        </span>
      );
    }
}

EditInline.propTypes = {
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  editonclick: PropTypes.func,
};

EditInline.defaultProps = {
  editonclick: undefined,
};


export default EditInline;
