import React from 'react';
import styled from 'styled-components';
import TreeNode from './TreeNode';
import TreeContext from './TreeContext';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const TreeView = ({ structure, leaves, totalLeaves, options, showAssets, toggle, filter, nLeaves, checkedNodes, openNodes, open, selectedAsset, isAlarm, onLink }) => {
  const contextValue = { options, filter, leaves, totalLeaves, toggle, nLeaves, openNodes, openNode: open, checkedNodes, showAssets, selectedAsset, isAlarm, onLink };

  return (
    <TreeContext.Provider value={contextValue}>
      <Ul>
        <TreeNode key={structure.key} node={structure} toggle={toggle} isAlarm onLink={onLink} />
      </Ul>
    </TreeContext.Provider>
  );
};

export default TreeView;
