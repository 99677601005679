import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Card, Alert } from 'reactstrap';
import i18n from 'app/i18n';
import api from 'api';
import moment from 'moment';
import {
  getNewKey,
  widgetTypes,
  getStartDate,
  getEndDate,
  getIntervalOptions
} from 'app/pages/dashboards/Dashboard/common';
import Spinner from 'app/common/Spinner/Spinner';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ReportLoading = () => {
  return (
    <Card>
      <Spinner />
    </Card>
  );
}

export const ReportError = (props) => {
  return (
    <Card className="p-4">
      <Alert color="danger">
        <h3><Trans>{ props.error }</Trans></h3>
      </Alert>
    </Card>
  );
}

export const getEndpointType = (type) => {
  let endpointType = null;
  if (type === 'sitegroups') {
    endpointType = 'SiteGroups';
  } else if (type === 'assetgroups') {
    endpointType = 'AssetGroups';
  } else {
    endpointType = capitalizeFirstLetter(type);
  }

  return endpointType;
};

export const getReportsByTypeAndId = (type, id) => {
  const endpointType = getEndpointType(type);

  let filter = '';
  if (type === 'domains') {
    filter = `?filter={ "where": {"domainId":"${id}"}, "order": "id DESC"}`;
  } else if (type === 'companies') {
    filter = `?filter={ "where": {"companyId":"${id}"}, "order": "id DESC"}`;
  } else if (type === 'sites') {
    filter = `?filter={ "where": {"siteId":"${id}"}, "order": "id DESC"}`;
  } else if (type === 'assets') {
    filter = `?filter={ "where": {"assetId":"${id}"}, "order": "id DESC"}`;
  }

  return api.get(`/${endpointType}/${id}/Reports${filter}`);
}

export const getReportsHistory = (report) => {
  const filter = `?filter={"order": "id DESC"}`;
  return api.get(`/Reports/${report.id}/documents${filter}`);
}

export const getReportHistoryPeriodsList = (responses, reports) => {
  const reportPeriods = [];
  responses.map((response, index) => {
    const historyList = response.data;

    if (Array.isArray(historyList) && historyList.length > 0) {
      // Take first (last in the list) history report
      const hasTargetPeriod = (historyList[0] && historyList[0].otherMetadata && historyList[0].otherMetadata.reportTargetPeriod);
      const firstRreportTargetPeriod = (hasTargetPeriod) ? historyList[0].created : null;

      const currentReport = reports[index];
      const period = getReferencePeriodFromReport(currentReport);
      if (period && firstRreportTargetPeriod) {
        const periodReference = getPeriodReference(firstRreportTargetPeriod, period.reference, period.dateFrom);
        reportPeriods.push({
          lastDateEmission: firstRreportTargetPeriod,
          periodReference: periodReference,
        });

      } else {
        reportPeriods.push(undefined);
      }

    } else {
      reportPeriods.push(undefined);
    }
    return null;
  });

  return reportPeriods;
}

export const getBeaUsersEndpoint = (type, modelId) => {
  const modelMap = {
    domains: 'Domain',
    companies: 'Company',
    sites: 'Site',
    sitegroups: 'SiteGroup'
  };

  const model = modelMap[type] || null;
  if (model && modelId) {
    return `/UserAuthorizations/byNode/${model}/${modelId}?filter={"where": {"role": {"neq": "VWR"}}, "include": "user"}`;
  }

  return null; // Error
};

export const getBeaUsersTags = (beaUsers) => {
  let internalUsers = [];
  if (Array.isArray(beaUsers)) {
    // Filter users by userInfoId
    const filterdUsersByInfoId = beaUsers.filter((v, i, a) => a.findIndex(t => (t.userInfoId === v.userInfoId)) === i);
    // Sort by name
    filterdUsersByInfoId.sort((a, b) => (a.user.name > b.user.name) ? 1 : -1);

    const unique = [];
    const duplicates = filterdUsersByInfoId.filter(o => {
      if (unique.find(i => i.user.name === o.user.name)) {
        return true;
      }
      unique.push(o);
      return false;
    });
    const duplicateNames = duplicates.map(n => n.user.name);

    /**
     * Create a new array with only needed key and data
     * Duplicated users have <id>
     */
    internalUsers = filterdUsersByInfoId.map((v) => {
      return {
        userInfoId: v.userInfoId,
        name: duplicateNames.includes(v.user.name) ? v.user.name+' (Id: '+v.userInfoId+')' : v.user.name
      };
    });
  }
  return internalUsers.map(x => ({ ...x, _label: i18n._(x.name) }));
}

export const getPostSubmitEndPoint = (endpointType, id) => {
  let endpoint = null;
  if (endpointType && id && /^-{0,1}\d+$/.test(id)) { // ID must be a number
    endpoint = `/${endpointType}/${id}/Reports`;
  } else if (endpointType) {
    endpoint = `/${endpointType}/Reports`;
  }

  return endpoint;
};

export const getPeriodOptions = () => {
  const { items } = getIntervalOptions('', 'report');
  return items;
};

export const getPeriodFromAggregation = (aggregation) => {
  const periodOptions = getPeriodOptions();
  return periodOptions.find(p => p.value === aggregation);
}

export const getRollingPeriod = (period, periodDate) => {
  if (period === 'from' && periodDate instanceof moment) {
    return periodDate.format("DD/MM/YYYY");
  }

  const startDate = getStartDate(null, period);
  if (!startDate) {
    return null; // unvalid or no date period
  }

  // Formatted Date object to string as dd/mm/yyyy
  const startDateFormat = [startDate.getDate(), startDate.getMonth()+1, startDate.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('/');

  return startDateFormat;
};

export const getEmptyCell = (name, size) => {
  if (!size) {
    size = 6;
  }
  return {
    name: name,
    type: 'custom',
    xs: size,
    sm: size,
    md: size,
    lg: size,
    value: '',
    render: () => <></>
  };
};

export const getGenerationDay = (value, onChangeGenerationDay) => {
  const fieldName = 'generationDay';
  const fieldLabel = <Trans>Report elaborato ogni</Trans>;
  let generationDay = {
    id: fieldName,
    name: fieldName,
    type: 'select',
    label: fieldLabel,
    xs: 6,
    sm: 6,
    md: 6,
    lg: 6,
    options: [],
    validation: { required: true },
    hidden: false,
    nullable: false,
    canSearch: false,
    disabled: false,
    onChange: (val, formValues) => onChangeGenerationDay(val, formValues)
  };

  switch(value) {
    case("weekly"):
      generationDay.options = [
        { value: 'monday', label: i18n._(t`Lunedì`) },
        { value: 'thursday', label: i18n._(t`Martedì`) },
        { value: 'wednesday', label: i18n._(t`Mercoledì`) },
        { value: 'tuesday', label: i18n._(t`Giovedì`) },
        { value: 'friday', label: i18n._(t`Venerdì`) },
        { value: 'saturday', label: i18n._(t`Sabato`) },
        { value: 'sunday', label: i18n._(t`Domenica`) }
      ];
    break;

    case('monthly'):
      const monthDays = [];
      for(let i=1; i<29; i++) {
        monthDays.push({ value: 'day_of_month_'+i, label: i+'° '+i18n._(t`giorno del mese`) });
      }
      monthDays.push(
        { value: 'last_but_two_day_month', label: i18n._(t`Terzultimo giorno del mese`) },
        { value: 'penultimate_day_month', label: i18n._(t`Penultimo giorno del mese`) },
        { value: 'last_day_month', label: i18n._(t`Ultimo giorno del mese`) },
      );

      generationDay.options = monthDays;
    break;

    case('bimonthly'):
      generationDay.options = [
        { value: 'day_of_month_1', label: '1° '+i18n._(t`giorno del bimestre`) },
        { value: 'day_of_month_2', label: '2° '+i18n._(t`giorno del bimestre`) },
        { value: 'day_of_month_3', label: '3° '+i18n._(t`giorno del bimestre`) },
        { value: 'last_but_two_day_month', label: i18n._(t`Terzultimo giorno del bimestre`) },
        { value: 'penultimate_day_month', label: i18n._(t`Penultimo giorno del bimestre`) },
        { value: 'last_day_month', label: i18n._(t`Ultimo giorno del bimestre`) },
      ];
      break;
    case('quarterly'):
      generationDay.options = [
        { value: 'day_of_month_1', label: '1° '+i18n._(t`giorno del trimestre`) },
        { value: 'day_of_month_2', label: '2° '+i18n._(t`giorno del trimestre`) },
        { value: 'day_of_month_3', label: '3° '+i18n._(t`giorno del trimestre`) },
        { value: 'last_but_two_day_month', label: i18n._(t`Terzultimo giorno del trimestre`) },
        { value: 'penultimate_day_month', label: i18n._(t`Penultimo giorno del trimestre`) },
        { value: 'last_day_month', label: i18n._(t`Ultimo giorno del trimestre`) },
      ];
      break;
    case('halfyearly'):
      generationDay.options = [
        { value: 'day_of_month_1', label: '1° '+i18n._(t`giorno del semestre`) },
        { value: 'day_of_month_2', label: '2° '+i18n._(t`giorno del semestre`) },
        { value: 'day_of_month_3', label: '3° '+i18n._(t`giorno del semestre`) },
        { value: 'last_but_two_day_month', label: i18n._(t`Terzultimo giorno del semestre`) },
        { value: 'penultimate_day_month', label: i18n._(t`Penultimo giorno del semestre`) },
        { value: 'last_day_month', label: i18n._(t`Ultimo giorno del semestre`) },
      ];
      break;

    case('yearly'):
      generationDay = {
        name: fieldName,
        type: 'date-day-month',
        label: fieldLabel,
        xs: 6,
        sm: 6,
        md: 6,
        lg: 6,
        validation: { required: true },
        nullable: false,
        canSearch: false,
        disabled: false
      };
    break;

    default:
      generationDay = getEmptyCell(fieldName);
    break;
  }

  return generationDay;
};

// Get a valid date from value from generationStartValue
export const getFirstGenerationDay = (generationStartValue, startDate) => {
  startDate = (startDate && startDate instanceof moment) ? startDate : moment();

  // Day of week N
  const weekDaysRange = {
    monday: 7,
    thursday: 6,
    wednesday: 5,
    tuesday: 4,
    friday: 3,
    saturday: 2,
    sunday: 1
  };

  if (generationStartValue in weekDaysRange) {
    return startDate.startOf('isoWeek').subtract( weekDaysRange[generationStartValue], 'days');
  }

  // Day of month N
  if ( generationStartValue.startsWith('day_of_month_') ) {
    const dayMonthNumber = generationStartValue.replace( /^\D+/g, '');
    if (dayMonthNumber > 1) {
      return startDate.startOf('month').add(dayMonthNumber - 1, 'days');
    }
    return startDate.startOf('month');
  }

  // Last days of month
  switch(generationStartValue) {
    case('last_but_two_day_month'):
      return startDate.endOf('month').subtract(1, 'month').subtract(2, 'days');
    case('penultimate_day_month'):
    return startDate.endOf('month').subtract(1, 'month').subtract(1, 'days');
    case('last_day_month'):
      return startDate.endOf('month').subtract(1, 'month');
    default:
      return startDate;
  }
}

export const getReferencePeriodFromReport = (report) => {
  if (report && report.configuration && report.configuration.period) {
    return report.configuration.period;
  }
  return null;
}

export const getPeriodReference = (reportLastUpdate, interval, dateFrom) => {
  let stringToReturn = '-';

  const dt = moment(reportLastUpdate).toDate();
  const startDate = getStartDate(dt, interval);
  const endDate = getEndDate(dt, interval);

  // Temporary assign period from aggregation
  const periodFromAggregation = getPeriodFromAggregation(interval);
  if (periodFromAggregation) {
    stringToReturn = periodFromAggregation.label;
  }

  // Customize label
  switch(interval) {
    case('lastweek'):
      const startDayStr = moment(startDate).format('D/M/YYYY');
      const endDayStr = moment(endDate).subtract(1, 'days').format('D/M/YYYY');
      stringToReturn = startDayStr + ' - ' + endDayStr;
      break;

    case('7d'): 
    case('24h'):
    case('30d'):
    case('yesterdayandtoday'):
    case('thisweek'):
      stringToReturn = moment(startDate).format('D/M/YYYY') + ' - ' + moment(endDate).format('D/M/YYYY');
      break;

    case('yesterday'):
    case('today'):
      stringToReturn = capitalizeFirstLetter( moment(startDate).format('ddd DD/MM/YYYY') );
      break;

    case('lastmonth'):
      // Subtract days because the period ends at the beginning of month
      stringToReturn = capitalizeFirstLetter( moment(endDate).subtract(1, 'days').format('MMM YYYY') );
      break;

    case('thismonth'):
      stringToReturn = capitalizeFirstLetter( moment(endDate).format('MMM YYYY') );
      break;

    case('thisbimester'):
    case('thisthreemester'):
    case('thissemester'):
      const startMonthStr = capitalizeFirstLetter( moment(startDate).format('MMM YYYY') );
      const endMonthStr = capitalizeFirstLetter( moment( getEndDate(startDate, interval) ).subtract(1, 'days').format('MMM YYYY') );
      stringToReturn = startMonthStr + ' - ' + endMonthStr;
      break;

    case('lastbimester'):
    case('lastthreemester'):
    case('lastsemester'):
      const lastStartMonthStr = capitalizeFirstLetter( moment(startDate).format('MMM YYYY') );
      const lastEndMonthStr = capitalizeFirstLetter( moment(endDate).subtract(1, 'days').format('MMM YYYY') );
      stringToReturn = lastStartMonthStr + ' - ' + lastEndMonthStr;
      break;

    case('thisyear'):
      stringToReturn = moment(startDate).format('YYYY');
      break;

    case('lastyear'):
      stringToReturn = moment(endDate).subtract(1, 'days').format('YYYY');
      break;

    case('lastyearlast'):
      const startYear = capitalizeFirstLetter( moment(startDate).format('YYYY') );
      const endYear = capitalizeFirstLetter( moment(endDate).format('YYYY') );
      stringToReturn = startYear + ' - ' + endYear;
      break;

    case('from'):
      stringToReturn = i18n._(t`Dal`) + ' ' + moment(dateFrom).format('DD/MM/YYYY');
      break;

    default:
      break;
  }

  return stringToReturn;
};

export const defaultHeaderWidget = {
  key: getNewKey(),
  layout: { x: 0, y: 0, w: 12, h: 2 },
  showTitle: true,
  showDatepub: true,
  showImage: false,
  showPeriod: true,
  showCreator: false,
  note: null,
  widgetType: widgetTypes.REPORT_HEADER
};

export const defaultFooterWidget = {
  key: getNewKey(),
  layout: { x: 0, y: 0, w: 12, h: 1 },
  showImage: false,
  showCreator: false,
  showPageNumber: true,
  showPageNumberTotal: true,
  note: null,
  widgetType: widgetTypes.REPORT_FOOTER
};

export const getGridRowNum = () => {
  return 14;
}

export const getDocumentTotalPages = (widgets) => {
  const rowNum = getGridRowNum();
  const greatestLayoutY = Math.max.apply(Math, widgets.map(o => o.layout.y + o.layout.h - 1));
  const totPages = parseInt( (greatestLayoutY / rowNum) + 1 );
  return totPages;
}
