import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/macro';
import { TreeView, Button } from 'app/common';
import logger from 'app/common/log';
import {
  retrieveSelectedPlants,
  BeaDomainPlantsSelected,
  getTreeNodes,
  pickUpDomainTreeSelectedItems
} from './helpers';

const Label = styled.div`
  flex: 1 0 auto;
`;

const userName = 'Stefano Brega';
//
//
const compareFunction = item => currentItem =>
  item._type === currentItem._type && item._id === currentItem._id;

const toggle = (setSelectedItemsFn, node, checked) => {
  const visited = getTreeNodes(node);
  logger.trace('toggle visited ', visited);
  setSelectedItemsFn(items => {
    let newItems = items;
    const subItems =
      node._type === 'company' || node._type === 'domain' ? getTreeNodes(node) : [node];
    if (checked) {
      // on click select the whole subtree
      const newItemsSet = new Set([...subItems, ...items]);
      newItems = [...newItemsSet];
    } else {
      newItems = [
        ...items.filter(
          item => !subItems.find(({ _id, _type }) => _id === item._id && _type === item._type)
        )
      ];
    }
    return newItems;
  });
  logger.debug('toggle %o %o', node, checked);
};

const PlantSelection = props => {
  logger.debug('PlantSelection props ', props);
  const { match, history, domainPlantsTree, onSubmit, userPreferences } = props;
  const { params } = match;
  const { channelKey, tagKey } = params;
  const { notifications: notificationPreferences } = userPreferences || {};
  const domainPlantsTreeRootNode = domainPlantsTree && domainPlantsTree[0];
  const domainId = domainPlantsTreeRootNode && domainPlantsTreeRootNode._id;

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (domainPlantsTreeRootNode) {
      const itemPreferences = channelKey
        ? notificationPreferences &&
          notificationPreferences.channels &&
          notificationPreferences.channels[channelKey]
        : notificationPreferences &&
          notificationPreferences.tags &&
          notificationPreferences.tags[tagKey];
      const inputDomainPlants =
        itemPreferences && itemPreferences.allowedFrom && itemPreferences.allowedFrom[domainId];
      const beaDomainPlants = BeaDomainPlantsSelected(inputDomainPlants);
      const newSelectedItems = pickUpDomainTreeSelectedItems(
        domainPlantsTreeRootNode,
        beaDomainPlants
      );
      logger.debug('newSelectedItems ', newSelectedItems);
      setSelectedItems(newSelectedItems);
    }
  }, [domainPlantsTreeRootNode, userPreferences]);

  const renderNode = (node, checked) => {
    //  logger.debug('renderNode %o', node);
    return <Label>{node.name}</Label>;
  };

  const cancel = () => history.goBack();
  const save = selectedItems => {
    logger.debug('PlaintSelection save ', domainId, selectedItems);
    const beaStructure = BeaDomainPlantsSelected();
    retrieveSelectedPlants(domainPlantsTree[0], selectedItems, beaStructure);
    logger.debug('PlaintSelection save beaStructure ', beaStructure.value);
    const itemNotificationPreferences = channelKey
      ? notificationPreferences.channels[channelKey]
      : notificationPreferences.tags[tagKey];
    itemNotificationPreferences.allowedFrom[domainId] = beaStructure.value;
    if (!selectedItems || selectedItems.length === 0) {
      itemNotificationPreferences.email = false;
      itemNotificationPreferences.toast = false;
      itemNotificationPreferences.telegram = false;
    }
    onSubmit(notificationPreferences).then(result => {
      if (result.err) {
        toast.error(result.err);
      } else {
        history.goBack();
      }
    });
  };

  return (
    <Card>
      <CardHeader>
        <Trans>Impianti abilitati per l'invio delle notifiche</Trans>
      </CardHeader>
      <CardBody>
        <TreeView
          userName={userName}
          options={{
            keyProperty: 'name',
            checked: true
          }}
          data={domainPlantsTree}
          toggle={toggle.bind(null, setSelectedItems)}
          compareFunction={compareFunction}
          selectedItems={selectedItems}
          render={renderNode}
        />
      </CardBody>
      <CardFooter>
        <Button marginRight color="primary" onClick={() => save(selectedItems)}>
          <Trans>Salva</Trans>
        </Button>
        <Button color="link" onClick={cancel}>
          <Trans>Annulla</Trans>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PlantSelection;
