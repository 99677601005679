import React, { Component } from 'react';
import { DynamicForm } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import VectorBlock from './VectorBlock';

const typeOptions = [
  { value: 1, tLabel: t`Caricamento manuale dei dati` },
  { value: 2, tLabel: t`Elaborazione automatica (predittore)` },
  { value: 3, tLabel: t`Elaborazione semi-automatica (predittore + correzioni manuali)` },
];

const granularityOptions = [
  { value: '1h', tLabel: t`1 ora` },
];

class ForecastForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  getValues = async () => {
    try {
      const { vector } = this.props;
      const values = await this.form.current.validateAndGetValues();
      return { ...values, vector: vector.naturalKey };
    } catch (e) {
      return null;
    }
  };

  getDefaultInitialValues = vector => ({
    type: 1,
    rate: '1h',
    name: i18n._(t`Previsione consumo di ${vector._label}`),
    physicalQuantity: vector.variables[0],
    unit: vector._defaultUnit.naturalKey,
  })

  isHidden = values => !values._enabled;

  render() {
    const { readOnly, vector, initialValues } = this.props;

    return (
      <VectorBlock vector={vector}>
        <DynamicForm
          hideButtons
          hideRequiredLabel
          readOnly={readOnly}
          ref={this.form}
          fields={[
            { name: '_enabled', type: 'checkbox', label: <Trans>Configura forecast</Trans>, md: 12 },
            { name: 'id', type: 'number', hidden: true },
            { name: 'name', type: 'text', md: 6, hidden: this.isHidden, label: <Trans>Nome</Trans>, validation: { conditional: (value, values) => (values._enabled ? { required: true } : {}) } },
            { name: 'type', type: 'select', md: 6, hidden: this.isHidden, label: <Trans>Modalità di gestione</Trans>, options: typeOptions.map(x => ({ ...x, label: i18n._(x.tLabel) })), disabled: true },
            { name: 'physicalQuantity', type: 'select', md: 4, hidden: this.isHidden, label: <Trans>Grandezza</Trans>, options: vector._physicalQuantities, valueProperty: 'naturalKey', labelProperty: '_label', disabled: true },
            { name: 'unit', type: 'select', md: 4, hidden: this.isHidden, label: <Trans>Unità</Trans>, options: vector._units, valueProperty: 'naturalKey', labelProperty: 'symbol' },
            { name: 'rate', type: 'select', md: 4, hidden: this.isHidden, label: <Trans>Granularità</Trans>, options: granularityOptions.map(x => ({ ...x, label: i18n._(x.tLabel) })), disabled: true },
          ]}
          initialValues={initialValues || this.getDefaultInitialValues(vector)}
        />
      </VectorBlock>
    );
  }
}

ForecastForm.propTypes = {
  initialValues: PropTypes.object,
};

ForecastForm.defaultProps = {
  initialValues: undefined,
};

export default ForecastForm;
