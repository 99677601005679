import React from 'react';

// set the defaults
const CalendarsContext = React.createContext({
  calendars: [],
  holidayCalendars: [],
  timeDefinitions: [],
  filteredPlants: [],
  onEditCalendar: () => {},
  onAddCalendar: () => {},
});

export default CalendarsContext;
