import React, { useState } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import i18n from 'app/i18n';
import { InputWithIcon } from 'app/common';
import logger from 'app/common/log';

const StyledItem = styled.div`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  padding: 0.5rem 0 0.5rem 1rem;
  &:hover {
    background-color: ${props => props.theme.common.background.secondaryColor};
  }
`;

const TagSelectionButton = props => {
  const { title, itemList, itemSelection } = props;
  logger.debug('TagSelectionButton props: ', props);
  const [optionListOpen, setOptionListOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const toggle = () => setOptionListOpen(open => !open);

  const Wrapper = styled.div`
    margin: 1rem 0rem;  
    & .exacto-btn {
      max-width: 20rem;
      overflow: hidden;
      border-radius: 0.5rem;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      font-weight: 500;
    }
  `;

  return (
    <Wrapper>
      <Dropdown
        direction="down"
        isOpen={optionListOpen}
        toggle={toggle}
        style={{ color: 'primary' }}
      >
        <DropdownToggle caret color="primary" className="exacto-btn">
          {i18n._(t`${title}`)}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setWidthHeight: {
              enabled: true,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    minWidth: '22rem',
                    overflow: 'auto',
                    overflowX: 'hidden',
                    maxHeight: '20rem'
                  }
                };
              }
            }
          }}
        >
          <div
            style={{
              width: '95%',
              padding: '0.5rem 0 0.5rem 1rem'
            }}
          >
            <InputWithIcon
              onBlur={() => setSearchText('')}
              autoFocus
              placeholder={i18n._(t`Cerca`)}
              onChange={({ target }) =>
                setSearchText(target && target.value && target.value.toLowerCase())
              }
              righticon="search"
            />
          </div>
          {itemList &&
            itemList
              .filter(item => item && item.name && item.name.toLowerCase().includes(searchText))
              .map(item => (
                <StyledItem
                  key={item.key}
                  onClick={() => {
                    toggle();
                    itemSelection(item.key);
                  }}
                  bold={item.marked}
                >
                  {(item && item.name) || '---'}
                </StyledItem>
              ))}
        </DropdownMenu>
      </Dropdown>
    </Wrapper>
  );
};

export default TagSelectionButton;
