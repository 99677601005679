import React, { PureComponent } from 'react';
import { Button, Checkbox, DeleteModal, DynamicForm, ConfirmActionModal } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logger from 'app/common/log';
import { reorderArrayForDragAndDrop } from 'app/utils/array';
import { MAX_MODBUS_REGISTER_ADDRESS, MAX_NUMBER_OF_OBSERVED_PROPERTIES } from 'app/constants';
import ModbusOP from './ModbusOP';
import {
  getPromise as getConfirmPromise,
  clearPromise as clearConfirmPromise
} from './singlePromise';

const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: #f00;
  margin-bottom: 1rem;
`;

class ModbusCfg extends PureComponent {
  constructor(props) {
    super(props);
    const { fieldDeviceName } = props;
    this.state = {
      templateRef: React.createRef(),
      error: null,
      warning: null,
      saveAsTemplate: false,
      selectedTemplate: null,
      op2remove: null,
      showDecimalAddress: true,
      fieldDeviceName,
      observedPropertiesLoaded: false,
      observedProperties: [],
      deletedObservedProperties: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.observedPropertiesLoaded) {
      logger.debug('getDerivedStateFromProps skipped');
      return state;
    }
    logger.debug('getDerivedStateFromProps ', props);
    const { readOnly, isNew, templateList, fieldDeviceName, supportedDevice } = props;

    let observedProperties = [];
    let showDecimalAddress = true;
    if (!isNew || readOnly) {
      observedProperties = props.observedProperties.map(op => ({
        ...op,
        key: op.id,
        configuration: {
          ...op.configuration,
          startAddressDecimal:
            op.configuration &&
            op.configuration.startAddress != null &&
            op.configuration.startAddress.toString(),
          startAddressHexadecimal:
            op.configuration &&
            op.configuration.startAddress != null &&
            op.configuration.startAddress.toString(16)
        }
      }));
    } else if (props.template && props.template.template) {
      const observedPropertiesConfig = props.template.template.observedProperties.sort(
        (a, b) => a.order - b.order
      );
      ({ showDecimalAddress } = props.template.template);
      observedProperties = observedPropertiesConfig.map(op => {
        return {
          configuration: {
            ...op,
            name: `${props.fieldDeviceName} - ${op.name}`,
            startAddressDecimal: op.startAddress != null && op.startAddress.toString(),
            startAddressHexadecimal: op.startAddress != null && op.startAddress.toString(16)
          },
          key: Math.random().toString(),
          _new: true
        };
      });
    }

    return {
      templateList: templateList.map(({ id, name }) =>
        id < 0 || id == null ? { id: -1, name: 'Nuovo template' } : { id, name }
      ),
      supportedDevice,
      fieldDeviceName,
      observedPropertiesLoaded: observedProperties.length > 0,
      observedProperties: observedProperties.map(item => ({ item, ref: React.createRef() })),
      deletedObservedProperties: [],
      showDecimalAddress
    };
  }

  componentDidMount() {
    const { isNew, template } = this.props;
    logger.debug('componentDidMount ', this.props);
    if (isNew && !template) {
      this.addItem();
    }
  }

  // Get updated values from all forms and store them in observedProperties (used in drag&drop and delete to update state)
  updateObservedProperties = () => {
    const { observedProperties } = this.state;
    const newOPs = observedProperties.map(op => {
      const { item, ref } = op;
      if (ref.current) {
        const opModbusValues = ref.current.getValues();
        const newItem = {
          ...item,
          ...opModbusValues,
          configuration: { ...item.configuration, ...opModbusValues }
        };
        return {
          ref,
          item: newItem
        };
      }
      return op;
    });
    logger.debug('updateObservedProperties ', newOPs);
    this.setState({ observedProperties: newOPs });
  };

  addItem = () => {
    const { observedProperties, fieldDeviceName } = this.state;
    if (observedProperties.length < MAX_NUMBER_OF_OBSERVED_PROPERTIES) {
      this.setState(prevState => ({
        observedPropertiesLoaded: true,
        observedProperties: [
          ...prevState.observedProperties,
          {
            item: {
              key: Math.random().toString(),
              _new: true,
              configuration: {},
              name: `${fieldDeviceName} - `
            },
            ref: React.createRef()
          }
        ]
      }));
    } else {
      console.error('too many OP: ', observedProperties.length);
    }
  };

  removeItem = removedOp => () => {
    logger.debug('Removing Item ', removedOp);
    this.updateObservedProperties();
    this.setState(prevState => {
      return {
        op2remove: null,
        observedProperties: prevState.observedProperties.filter(op => op.ref !== removedOp.ref),
        deletedObservedProperties: [...prevState.deletedObservedProperties, removedOp]
      };
    });
  };

  getValues = async data => {
    const {
      observedProperties,
      showDecimalAddress,
      deletedObservedProperties,
      selectedTemplate,
      newTemplateName,
      supportedDevice,
      saveAsTemplate,
      fieldDeviceName,
      isSystemTemplate,
      templateRef
    } = this.state;
    const isFormValid = await templateRef.current.validate();
    const promises = observedProperties.map(item => item.ref.current.validateAndGetValues(data));
    try {
      const values = await Promise.all(promises);
      const outFormatObservedProperties = observedProperties.map(({ item }, index) => {
        return {
          ...item,
          ...values[index],
          configuration: { ...item.configuration, ...values[index] }
        };
      });
      if (saveAsTemplate && !isFormValid) {
        throw new Error('Template data missing');
      }
      logger.debug('ModbusCfg getValues newObservedProperties', outFormatObservedProperties);
      const templateIdName =
        selectedTemplate && selectedTemplate.id && selectedTemplate.id >= 0
          ? selectedTemplate
          : { name: newTemplateName };
      const templateObservedProperties = values.map((op, order) => {
        const deviceSuffix = `${fieldDeviceName} -`;
        const opNameIndex = op.name.indexOf(deviceSuffix);
        const filteredName = op.name.substring(opNameIndex + deviceSuffix.length).trim();
        return { ...op, name: filteredName, order };
      });
      return {
        saveAsTemplate,
        observedProperties: outFormatObservedProperties,
        delendaOPs: deletedObservedProperties.filter(({ item }) => item.id).map(op => op.item),
        isSystemTemplate,
        template: {
          domainId: supportedDevice && supportedDevice.domainId,
          supportedDeviceId: supportedDevice && supportedDevice.id,
          type: 'Modbus',
          ...templateIdName,
          numberOfObservedProperties: values.length,
          template: {
            observedProperties: templateObservedProperties,
            showDecimalAddress
          }
        }
      };
    } catch (e) {
      logger.debug('Error ', e);
      throw e;
    }
  };

  onDragStart = e => {
    const sourceIndex = +e.target.dataset.index;
    this.updateObservedProperties();
    e.dataTransfer.setData('text/plain', sourceIndex);
  };

  onDragOver = e => {
    e.preventDefault();
  };

  onDrop = e => {
    e.preventDefault();
    const sourceIndex = +e.dataTransfer.getData('text/plain');
    const targetIndex = +e.currentTarget.dataset.index;

    const { observedProperties } = this.state;
    const newObs = reorderArrayForDragAndDrop(observedProperties, sourceIndex, targetIndex);
    this.setState({ observedProperties: newObs });
  };

  fillBits = (op, bits) => {
    let error;
    let warning;
    ;
    const { name, startAddress, dataSize, bit } = op;
    const startBit = 16 * startAddress + (+bit || 0);
    const bitSize = dataSize === 'bit' ? 1 : +dataSize.match(/[0-9]+/g);
    const endBit = startBit + bitSize; // escluso
    if (endBit > 16 * MAX_MODBUS_REGISTER_ADDRESS + 1) {
      error = i18n._(
        t`Errore indirizzi di registro: la variabile ${name} va oltre il massimo indirizzo disponibile`
      );
    }
    for (let i = startBit; i < endBit && !error; i++) {
      if (bits.includes(i) && bitSize !== 8) {
        ;
        const warningMsg = `L'indirizzo e la dimensione variabile ${name} sono in sovrapposizione con quelli di un'altra variabile`;
        logger.warn(i + ' ' + warningMsg);
        warning = warningMsg;
      }
      bits.push(i);
    }
    return { error, warning };
  };

  validateAddresses = observedProperties => {
    let error, warning;
    try {
      const coil = [];
      const holding = [];
      const input = [];
      const status = [];
      /* eslint-disable-next-line no-restricted-syntax */
      let data;
      for (let i = 0; i < observedProperties.length; i++) {
        const op = observedProperties[i];
        switch (op.dataType) {
          case 'di_r':
            data = status;
            break;
          case 'c_r':
          case 'c_w':
          case 'c_wo':
            data = coil;
            break;
          case 'hr_r':
          case 'hr_w':
          case 'hr_wo':
            data = holding;
            break;
          case 'ir_r':
            data = input;
            break;
          default:
        }
        const { error: opError, warning: opWarning } = this.fillBits(op, data);
        error = opError;
        warning = opWarning;
      }
    } catch (e) {
      return { error: e.message };
    }

    logger.debug('validateAddresses ', { error, warning });
    return { error, warning };
  };

  validate = async () => {
    let isValid = true;
    const { observedProperties } = this.state;
    isValid = isValid && observedProperties && observedProperties.length > 0;
    if (!isValid) {
      this.setState({ errorMessage: <Trans>Inserire almeno una variabile</Trans> });
    }
    const maxOfOpNumberCondition = observedProperties.length <= MAX_NUMBER_OF_OBSERVED_PROPERTIES;
    isValid = isValid && maxOfOpNumberCondition;
    if (!maxOfOpNumberCondition) {
      this.setState({
        errorMessage: (
          <Trans>
            Ogni template può contenere al massimo {MAX_NUMBER_OF_OBSERVED_PROPERTIES} variabili
          </Trans>
        )
      });
    }

    // Address overlap or overflow
    const currentValues = observedProperties.map(op => op.ref.current.getValues(true));
    const { error, warning } = this.validateAddresses(currentValues);
    isValid = isValid && !error && !warning;
    this.setState({ errorMessage: error });
    this.setState({ warning });
    let choice = true;
    if (!error && warning) {
      logger.debug('confirmation before confirmationPromise');
      const { promise: promiseConfirm } = getConfirmPromise();
      choice = await promiseConfirm;
      clearConfirmPromise();
      logger.debug('confirmation after confirmationPromise choice', choice);
    }
    return !error && (!warning || choice);
  };

  render() {
    const { catalogs, readOnly, isNew, isSYS } = this.props;
    const {
      observedProperties,
      showDecimalAddress,
      errorMessage,
      op2remove,
      saveAsTemplate,
      templateList,
      selectedTemplate,
      error,
      templateRef,
      warning
    } = this.state;
    const numberOfObservedProperties = observedProperties ? observedProperties.length : 0;
    const OpDeletion = props => {
      const { op } = props;
      const title = `Conferma cancellazione ${op && op.name}`;
      logger.debug('title ', title);
      return (
        <DeleteModal
          isOpen={op}
          toggleModal={() => {
            logger.debug('toggleModal ', op);
            this.setState({ op2remove: null });
          }}
          header={<Trans>{`Conferma cancellazione ${op && op.item && op.item.name}`}</Trans>}
          onDelete={this.removeItem(op)}
        >
          <Trans>Conferma cancellazione OP modbus</Trans>
        </DeleteModal>
      );
    };

    const onCancelOpConfirmationModal = () => {
      logger.debug('confirmation Cancel Pressed');
      this.setState({ warning: null });
      const { resolve, promise } = getConfirmPromise();
      logger.debug('confirmation Cancel Pressed resolve promise', resolve, promise);
      resolve(false);
    };
    const onConfirmOpConfirmationModal = () => {
      this.setState({ warning: null });
      const { resolve, promise } = getConfirmPromise();
      logger.debug('confirmation OK Pressed resolve promise', resolve, promise);
      resolve(true);
    };
    return (
      <div>
        <ConfirmActionModal
          isOpen={warning != null}
          toggle={onCancelOpConfirmationModal}
          onConfirm={onConfirmOpConfirmationModal}
          title={<Trans>Attenzione</Trans>}
        >
          <>
            <h6>{i18n._(t`${warning}`)}</h6>
            <h6>
              <Trans>Desideri procedere ugualmente?</Trans>
            </h6>
          </>
        </ConfirmActionModal>
        {!isNew && <OpDeletion op={op2remove != null && op2remove} />}
        <CheckboxContainer>
          <Checkbox
            name="showDecimalAddress"
            checked={showDecimalAddress}
            onChange={e => this.setState({ showDecimalAddress: e.target.checked })}
            label={<Trans>Mostra indirizzi in formato decimale</Trans>}
          />
        </CheckboxContainer>
        {observedProperties.map((op, index) => (
          <div
            key={op.item.key}
            data-index={index}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          >
            {op.item && op.item.configuration && !op.item._delete ? (
              <ModbusOP
                isNew={op.item._new}
                index={index}
                readOnly={readOnly}
                showDecimalAddress={showDecimalAddress}
                op={{ ...op.item, ...op.item.configuration }}
                onDelete={isNew ? this.removeItem(op) : () => this.setState({ op2remove: op })}
                key={op.item.key}
                ref={op.ref}
                opNumber={index + 1}
                catalogs={catalogs}
                onDragStart={this.onDragStart}
              />
            ) : null}
          </div>
        ))}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <p>
          <Trans>
            Numero di variabili inserite: {numberOfObservedProperties} (max{' '}
            {MAX_NUMBER_OF_OBSERVED_PROPERTIES} variabili per template)
          </Trans>
        </p>
        {!readOnly && (
          <>
            <Button
              color="secondary"
              disabled={numberOfObservedProperties >= MAX_NUMBER_OF_OBSERVED_PROPERTIES}
              onClick={this.addItem}
            >
              <Trans>Aggiungi variabile</Trans>
            </Button>
            <CheckboxContainer>
              <Checkbox
                name="saveAsTemplate"
                checked={saveAsTemplate}
                onChange={e => {
                  logger.debug('saveAsTemplate ', this.state);
                  this.setState({ saveAsTemplate: e.target.checked });
                }}
                label={
                  <>
                    <strong>
                      <Trans>Salva come template</Trans>
                    </strong>
                    &nbsp;
                    <Trans>(utile per configurare rapidamente i prossimi dispositivi)</Trans>
                  </>
                }
              />
            </CheckboxContainer>
            <DynamicForm
              hideButtons
              hidden={!saveAsTemplate}
              initialValues={[{ templateName: 'nuovoTemplate', _systemTemplate: false }]}
              edit
              ref={templateRef}
              error={error}
              fields={[
                {
                  name: 'templateList',
                  type: 'select',
                  label: <Trans>Template</Trans>,
                  options: templateList,
                  valueProperty: 'id',
                  labelProperty: 'name',
                  validation: {
                    required: true
                  },
                  md: 6,
                  onChange: (value, values) => {
                    const newSelectedTemplate = templateList.find(tmpl => tmpl.id === value);
                    logger.debug('select template ', newSelectedTemplate);
                    this.setState({ selectedTemplate: newSelectedTemplate });
                  }
                },
                {
                  name: 'templateName',
                  type: 'text',
                  hidden:
                    !selectedTemplate || selectedTemplate.id == null || selectedTemplate.id >= 0,
                  label: <Trans>Nome template</Trans>,
                  validation: { required: true },
                  canEdit: true,
                  canSearch: true,
                  md: 6,
                  onChange: (value, values) => {
                    logger.debug('new template name ', value, values);
                    this.setState({ newTemplateName: value });
                  }
                },
                {
                  name: '_systemTemplate',
                  type: 'checkbox',
                  label: <Trans>Template predefinito</Trans>,
                  hidden: !(isSYS && selectedTemplate && selectedTemplate.id < 0),
                  onChange: value => {
                    logger.debug('isSystemTemplate ', value);
                    this.setState({ isSystemTemplate: value });
                  }
                }
              ]}
            />
          </>
        )}
      </div>
    );
  }
}

ModbusCfg.propTypes = {
  template: PropTypes.object.isRequired
};

export default ModbusCfg;
