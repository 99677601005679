import { DynamicForm, IconButton } from 'app/common';
import { isHexNumber } from 'app/utils/validation';
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { MAX_MODBUS_REGISTER_ADDRESS } from 'app/constants';
import logger from 'app/common/log';

const BorderContainer = styled(Container)`
  && {
    border: 1px solid #ddd;
    padding: 0 1rem 0.5rem 1rem;
    background-color: #fefefe;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${props => props.theme.common.background.secondaryColor};
  border-radius: 0.5rem 0.5rem 0 0;

  color: ${props => props.theme.common.icons.color};
  cursor: grab;
  transition: all 0.2s;

  & div {
    margin-bottom: 0;
  }

  &:hover {
    color: ${props => props.theme.common.icons.hoverColor};
  }
`;

class ModbusOP extends PureComponent {
  constructor(props) {
    super(props);
    // console.log('Modbus OP ', props);
    this.opform = React.createRef();

    const { isNew } = this.props;
    this.state = {
      unit: { items: [], disabled: true, show: false },
      valueType: { items: [], disabled: true, show: false },
      hidden: false,
      aggregationRule: { items: [], disabled: true, show: false },
      scaleFactor: { items: [], disabled: true, show: false, validation: null },
      dataType: { items: [], disabled: true, show: false },
      dataSize: { items: [], disabled: true, show: false },
      bit: { items: [], disabled: true, show: false },
      byteOrder: { items: [], disabled: true, show: false },
      wordOrder: { items: [], disabled: true, show: false },
      doubleWordOrder: { items: [], disabled: true, show: false },
      byte: {
        items: [
          { value: 'hi', label: 'Hi' },
          { value: 'lo', label: 'Low' }
        ],
        disabled: false,
        show: false,
        forceDisabled: isNew
      }
    };
  }

  componentDidMount() {
    const { op, catalogs } = this.props;
    if (op) {
      const { physicalQuantity, dataType, dataSize } = op;
      const { physicalQuantities } = catalogs;
      logger.log('physicalQuantities %j', physicalQuantities);
      const quantityId = (physicalQuantities.find(x => x.naturalKey === physicalQuantity) || {}).id;
      this.setUnits(quantityId);
      this.setScale(physicalQuantity);
      this.setDataType(physicalQuantity);
      this.setValuetype(physicalQuantity);
      this.setAggregationRule(physicalQuantity);
      this.setDataSize(physicalQuantity);
      this.setBits(physicalQuantity, dataType);
      this.setOrders(physicalQuantity, dataSize);
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { op } = this.props;
  // }

  setUnits = async quantityId => {
    if (!quantityId) {
      this.setState({ unit: { items: [], disabled: true, show: false } });
      return;
    }
    try {
      const { catalogs } = this.props;
      const { physicalQuantities, units } = catalogs;
      const physicalQuantity = physicalQuantities.find(x => x.id === +quantityId);
      const selectedUnits = units.filter(x => physicalQuantity.units.includes(x.naturalKey));
      if (+quantityId === 1 || +quantityId === 62) {
        // event or state
        this.setState({ unit: { items: selectedUnits, disabled: true, show: false } });
      } else {
        // physical quantity
        this.setState({ unit: { items: selectedUnits, disabled: false, show: true } });
      }
    } catch (e) {
      this.setState({ unit: { items: [], disabled: true, show: true } });
    }
  };

  setValuetype = physicalQuantity => {
    if (!physicalQuantity || physicalQuantity === 'event' || physicalQuantity === 'state') {
      this.setState({ valueType: { items: [], disabled: true, show: false } });
    } else {
      const { catalogs } = this.props;
      const valueTypes = catalogs.valueTypes.filter(x => x.current && x.active);
      this.setState({ valueType: { items: valueTypes, disabled: false, show: true } });
    }
  };

  setAggregationRule = physicalQuantity => {
    if (!physicalQuantity || physicalQuantity === 'event' || physicalQuantity === 'state') {
      this.setState({ aggregationRule: { items: [], disabled: true, show: false } });
    } else {
      const { catalogs } = this.props;
      const aggregationRules = catalogs.aggregationRules.filter(x => x.current && x.active);
      this.setState({ aggregationRule: { items: aggregationRules, disabled: false, show: true } });
    }
  };

  setDataType = physicalQuantity => {
    const { catalogs } = this.props;
    const registerkinds = catalogs['modbus/registerkinds'].filter(x => x.current && x.active);
    if (!physicalQuantity) {
      this.setState({ dataType: { items: [], disabled: true, show: false } });
    } else if (physicalQuantity === 'event' || physicalQuantity === 'state') {
      this.setState({ dataType: { items: registerkinds, disabled: false, show: true } });
    } else {
      this.setState({
        dataType: {
          items: registerkinds.filter(x =>
            ['ir_r', 'hr_r', 'hr_w', 'hr_wo'].includes(x.naturalKey)
          ),
          disabled: false,
          show: true
        }
      });
    }
  };

  setBits = (physicalQuantity, dataType) => {
    if (
      !physicalQuantity ||
      !['event', 'state'].includes(physicalQuantity) ||
      !dataType ||
      ['di_r', 'c_r', 'c_w', 'c_wo'].includes(dataType)
    ) {
      this.setState({ bit: { items: [], disabled: true, show: false } });
    } else {
      this.setState({
        bit: {
          items: [...Array(16).keys()].map(x => ({ value: x, label: x.toString() })),
          disabled: false,
          show: true
        }
      });
    }
  };

  setDataSize = physicalQuantity => {
    const { catalogs } = this.props;
    const datakinds = catalogs['modbus/datakinds'].filter(x => x.current && x.active);
    if (!physicalQuantity || physicalQuantity === 'event' || physicalQuantity === 'state') {
      this.setState({ dataSize: { items: [], disabled: true, show: false } });
    } else {
      this.setState({ dataSize: { items: datakinds, disabled: false, show: true } });
    }
  };

  setOrders = (physicalQuantity, dataSize) => {
    let byteOrder = { items: [], disabled: true, show: false };
    let wordOrder = { items: [], disabled: true, show: false };
    let doubleWordOrder = { items: [], disabled: true, show: false };
    let byteShow = false;

    if (physicalQuantity !== 'event' && physicalQuantity !== 'state' && dataSize) {
      if (dataSize.includes('16') || dataSize.includes('32') || dataSize.includes('64')) {
        byteOrder = {
          items: [
            { value: 'le', label: t`Little Endian` },
            { value: 'be', label: t`Big Endian` }
          ],
          disabled: false,
          show: true
        };
      }
      if (dataSize.includes('32') || dataSize.includes('64')) {
        wordOrder = {
          items: [
            { value: 'le', label: t`Little Endian` },
            { value: 'be', label: t`Big Endian` }
          ],
          disabled: false,
          show: true
        };
      }
      if (dataSize.includes('64')) {
        doubleWordOrder = {
          items: [
            { value: 'le', label: t`Little Endian` },
            { value: 'be', label: t`Big Endian` }
          ],
          disabled: false,
          show: true
        };
      }
      if (dataSize.includes('int8')) {
        byteShow = true;
      }
    }

    this.setState(({ byte }) => ({
      byteOrder,
      wordOrder,
      doubleWordOrder,
      byte: { ...byte, show: byteShow }
    }));
  };

  setScale = physicalQuantity => {
    if (!physicalQuantity || physicalQuantity === 'event' || physicalQuantity === 'state') {
      this.setState({ scaleFactor: { items: [], disabled: true, show: false, validation: null } });
    } else {
      this.setState({
        scaleFactor: {
          items: ['0.01', '0.1', '1', '10', '100'],
          disabled: false,
          show: true,
          validation: { required: true, number: true, minStrictVal: 0 }
        }
      });
    }
  };

  getDefaultUnit = physicalQuantityId => {
    const { catalogs } = this.props;
    const { physicalQuantities } = catalogs;
    const physicalQuantity = physicalQuantities.find(x => x.id === +physicalQuantityId);
    if (physicalQuantity.units.length === 1) {
      return physicalQuantity.units[0];
    }
    return undefined;
  };

  getDefaultAggregation = valueType => {
    switch (valueType) {
      case 'instant':
        return 'average';
      case 'cumulated':
        return 'sum';
      default:
        return undefined;
    }
  };

  sanitizeOP = op => {
    if (op.physicalQuantity === 'event' || op.physicalQuantity === 'state') {
      op.scaleFactor = 1;
      op.valueType = 'binary';
      op.aggregationRule = 'none';
      op.dataSize = 'bit';
      op.order = null;
      if (['di_r', 'c_r', 'c_w', 'c_wo'].includes(op.dataType)) {
        op.bit = null;
      }
      op.byteOrder = null;
      op.wordOrder = null;
      op.doubleWordOrder = null;
    } else {
      op.bit = null;
      const { byteOrder, wordOrder, doubleWordOrder } = this.state;
      if (byteOrder.disabled) op.byteOrder = null;
      if (wordOrder.disabled) op.wordOrder = null;
      if (doubleWordOrder.disabled) op.doubleWordOrder = null;
    }
    op.writeable = ['c_w', 'hr_w', 'hr_wo'].includes(op.dataType);
    op.readable = op.dataType !== 'hr_wo';
    return op;
  };

  getValues = (sanitize = false) => {
    const op = this.opform.current.getValues();
    if (sanitize) {
      return this.sanitizeOP(op);
    }
    return op;
  };

  validateAndGetValues = async () => {
    const op = await this.opform.current.validateAndGetValues();
    return this.sanitizeOP(op);
  };

  convertAddressToNumber = addressString => {
    const { showDecimalAddress } = this.props;
    if (showDecimalAddress) {
      return +addressString;
    }
    return parseInt(addressString, 16);
  };

  convertAddressToString = addressNumber => {
    const decimal = addressNumber.toString();
    const hexadecimal = addressNumber.toString(16);
    return { decimal, hexadecimal };
  };

  validateAddressDecimal = (address, showDecimalAddress) => {
    if (!showDecimalAddress) return true;

    const addressDecimal = Number(address);
    if (!Number.isNaN(addressDecimal)) {
      const minAddress = 0;
      const maxAddress = MAX_MODBUS_REGISTER_ADDRESS;
      if (addressDecimal < minAddress || addressDecimal > maxAddress) {
        return new Error(
          i18n._(t`L'indirizzo deve essere compreso tra ${minAddress} e ${maxAddress}`)
        );
      }
      return true;
    }
    return new Error(i18n._(t`Indirizzo decimale non valido`));
  };

  validateAddressHexadecimal = (address, showDecimalAddress) => {
    if (showDecimalAddress) return true;
    if (isHexNumber(address)) {
      const addressHexa = parseInt(address, 16);
      const minAddress = 0;
      const maxAddress = MAX_MODBUS_REGISTER_ADDRESS;
      if (addressHexa < 0 || addressHexa > MAX_MODBUS_REGISTER_ADDRESS) {
        return new Error(
          i18n._(
            t`L'indirizzo deve essere compreso tra ${minAddress.toString(
              16
            )} e ${maxAddress.toString(16)}`
          )
        );
      }
      return true;
    }
    return new Error(i18n._(t`Indirizzo esadecimale non valido`));
  };

  validateName = name => {
    const minNameLength = 3;
    let result = name != null;
    const firstLetter = name && name.charAt(0);
    const startWithDigit = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(firstLetter);
    if (startWithDigit) {
      result = new Error(i18n._(t`Il nome della variabile deve iniziare con una lettera`));
    } else if (name && name.trim().length < 3) {
      result = new Error(
        i18n._(t`Il nome della variabile deve essere composto da almeno ${minNameLength} caratteri`)
      );
    }
    return result;
  };

  render() {
    const {
      op,
      catalogs,
      onDelete,
      showDecimalAddress,
      readOnly,
      index,
      onDragStart,
      isNew
    } = this.props;
    const {
      unit,
      scaleFactor,
      dataType,
      dataSize,
      bit,
      valueType,
      aggregationRule,
      byteOrder,
      wordOrder,
      doubleWordOrder,
      byte,
      deleted
    } = this.state;
    const isDigital = physicalQuantity =>
      physicalQuantity === 'state' || physicalQuantity === 'event';
    const { physicalQuantities } = catalogs;
    const physicalQuantitiesOptions =
      isNew || readOnly
        ? physicalQuantities
        : physicalQuantities.filter(
            phyQ => phyQ.naturalKey !== 'event' && phyQ.naturalKey !== 'state'
          );

    if (deleted) return null;

    return (
      <DynamicForm
        readOnly={readOnly}
        hideButtons
        hideRequiredLabel
        ref={this.opform}
        fields={[
          {
            name: 'name',
            type: 'text',
            label: <Trans>Nome variabile</Trans>,
            validation: {
              required: true,
              func: name => this.validateName(name)
            }
          },
          {
            name: 'physicalQuantity',
            type: 'select',
            options: values =>
              !isDigital(values.physicalQuantity) ? physicalQuantitiesOptions : physicalQuantities,
            valueProperty: 'naturalKey',
            labelProperty: '_label',
            canSearch: true,
            label: <Trans>Grandezza</Trans>,
            validation: { required: true },
            disabled: value => (!isNew && isDigital(value)),           
            onChange: (value, values, item) => {
              const physicalQuantity = value;
              const { dataType, dataSize } = values;
              const physicalQuantityId = item.id;
              this.setUnits(physicalQuantityId);
              this.setScale(physicalQuantity);
              this.setDataType(physicalQuantity);
              this.setValuetype(physicalQuantity);
              this.setAggregationRule(physicalQuantity);
              this.setDataSize(physicalQuantity);
              this.setBits(physicalQuantity, dataType);
              this.setOrders(physicalQuantity, dataSize);
              values.unit = this.getDefaultUnit(physicalQuantityId);
              if (isNew) {
                if (physicalQuantity === 'event' || physicalQuantity === 'state') {
                  values.dataSize = 'bit';
                  values.valueType = 'binary';
                  values.byteOrder = undefined;
                  values.wordOrder = undefined;
                  values.doubleWordOrder = undefined;
                } else {
                  values.bit = null;
                  values.scaleFactor = '1';
                }
                values.dataType = undefined;                  
              }
            },
            props: { maxHeight: '11rem' },
            maxHeight: "15rem",

          },
          {
            name: 'unit',
            type: 'select',
            options: unit.items,
            valueProperty: 'naturalKey',
            labelProperty: 'symbol',
            disabled: unit.disabled,
            validation: { required: true },
            label: <Trans>Unità di misura</Trans>,
            props: { maxHeight: '11rem' },
            maxHeight: "15rem"

          },
          {
            name: 'valueType',
            type: 'select',
            options: valueType.items,
            valueProperty: 'naturalKey',
            labelProperty: '_label',
            disabled: valueType.disabled || !isNew,
            validation: { required: true },
            hidden: values => values.physicalQuantity === 'event',
            onChange: (value, values) => {
              values.aggregationRule = this.getDefaultAggregation(value);
            },
            maxHeight: "15rem",
            label: <Trans>Tipo di misura</Trans>
          },
          {
            name: 'aggregationRule',
            type: 'select',
            options: aggregationRule.items,
            valueProperty: 'naturalKey',
            labelProperty: '_label',
            disabled: aggregationRule.disabled,
            maxHeight: "15rem",
            label: <Trans>Aggregazione</Trans>,
            hidden: values => values.physicalQuantity === 'event'
          },
          {
            name: 'scaleFactor',
            type: 'number',
            suggestions: scaleFactor.items,
            disabled: scaleFactor.disabled,
            validation: scaleFactor.validation,
            label: <Trans>Fattore di scala</Trans>
          },
          {
            name: 'dataType',
            type: 'select',
            options: dataType.items,
            valueProperty: 'naturalKey',
            labelProperty: '_label',
            disabled: dataType.disabled || !isNew,
            validation: { required: true },
            maxHeight: "15rem",
            label: <Trans>Tipo di registro</Trans>,
            onChange: (value, values) => {
              const { physicalQuantity, dataSize } = values;
              this.setOrders(physicalQuantity, dataSize);
              this.setBits(physicalQuantity, value);
            },
            props: { maxHeight: '11rem' },
            maxHeight: "15rem",
            canSearch: true
          },
          {
            name: 'startAddressDecimal',
            type: 'text',
            label: <Trans>Indirizzo registro</Trans>,
            validation: {
              required: true,
              func: address => this.validateAddressDecimal(address, showDecimalAddress)
            },
            onChange: (value, values) => {
              const addressNumber = this.convertAddressToNumber(value);
              values.startAddress = addressNumber;
              values.startAddressHexadecimal = this.convertAddressToString(
                addressNumber
              ).hexadecimal;
            }
          },
          {
            name: 'startAddressHexadecimal',
            type: 'text',
            label: <Trans>Indirizzo registro</Trans>,
            validation: {
              required: true,
              func: address => this.validateAddressHexadecimal(address, showDecimalAddress)
            },
            onChange: (value, values) => {
              const addressNumber = this.convertAddressToNumber(value);
              values.startAddress = addressNumber;
              values.startAddressDecimal = this.convertAddressToString(addressNumber).decimal;
            }
          },
          { name: 'startAddress', type: 'number' },
          {
            name: 'dataSize',
            type: 'select',
            options: dataSize.items,
            valueProperty: 'naturalKey',
            labelProperty: 'naturalKey',
            disabled: dataSize.disabled || !isNew,
            canSearch: true,
            validation: { required: true },
            label: <Trans>Dimensione</Trans>,
            props: { maxHeight: '11rem' },
            maxHeight: "15rem",
            onChange: (value, values) => this.setOrders(values.physicalQuantity, value)
          },
          {
            name: 'bit',
            type: 'select',
            options: bit.items,
            disabled: bit.disabled || !isNew,
            canSearch: true,
            validation: bit.disabled ? {} : { required: true },
            maxHeight: "15rem",
            label: <Trans>Bit</Trans>
          },
          {
            name: 'byteOrder',
            type: 'select',
            options: byteOrder.items.map(({ value, label }) => ({ value, label: i18n._(label) })),
            disabled: byteOrder.disabled || !isNew,
            validation: byteOrder.disabled ? {} : { required: true },
            maxHeight: "15rem",
            label: <Trans>Byte order</Trans>
          },
          {
            name: 'wordOrder',
            type: 'select',
            options: wordOrder.items.map(({ value, label }) => ({ value, label: i18n._(label) })),
            disabled: wordOrder.disabled || !isNew,
            validation: wordOrder.disabled ? {} : { required: true },
            maxHeight: "15rem",
            label: <Trans>Word order</Trans>
          },
          {
            name: 'doubleWordOrder',
            type: 'select',
            options: doubleWordOrder.items.map(({ value, label }) => ({
              value,
              label: i18n._(label)
            })),
            disabled: doubleWordOrder.disabled || !isNew,
            validation: doubleWordOrder.disabled ? {} : { required: true },
            maxHeight: "15rem",
            label: <Trans>Doubleword order</Trans>
          },
          {
            name: 'byte',
            type: 'select',
            options: byte.items,
            disabled: byte.disabled || !isNew,
            validation: { required: byte.show },
            maxHeight: "15rem",
            label: <Trans>Byte selection</Trans>
          },
          {
            name: 'hidden',
            type: 'checkbox',
            label: 'Nascosta'
          }
        ]}
        initialValues={op}
        layout={fields => (
          <BorderContainer fluid>
            {!readOnly && (
              <Header draggable data-index={index} onDragStart={onDragStart}>
                {fields.hidden.field}
                <IconButton icon="times" color="#c00" size="1.8rem" onClick={onDelete} />
              </Header>
            )}
            <Row form>
              <Col lg={3} sm={6}>
                {fields.name.field}
              </Col>
              <Col lg={3} sm={6}>
                {fields.physicalQuantity.field}
              </Col>
              {unit.show && (
                <Col lg={2} sm={4}>
                  {fields.unit.field}
                </Col>
              )}
              {scaleFactor.show && (
                <Col lg={2} sm={4}>
                  {fields.scaleFactor.field}
                </Col>
              )}
              {valueType.show && (
                <Col lg={2} sm={4}>
                  {fields.valueType.field}
                </Col>
              )}
              {/* {aggregationRule.show && (
                <Col lg={2} sm={4}>
                  {fields.aggregationRule.field}
                </Col>
              )} */}
              <Col lg={2} sm={4}>
                {showDecimalAddress
                  ? fields.startAddressDecimal.field
                  : fields.startAddressHexadecimal.field}
              </Col>
              {dataType.show && (
                <Col lg={2} sm={4}>
                  {fields.dataType.field}
                </Col>
              )}
              {bit.show && (
                <Col lg={2} sm={4}>
                  {fields.bit.field}
                </Col>
              )}
              {dataSize.show && (
                <Col lg={2} sm={4}>
                  {fields.dataSize.field}
                </Col>
              )}
              {byteOrder.show && (
                <Col lg={2} sm={4}>
                  {fields.byteOrder.field}
                </Col>
              )}
              {wordOrder.show && (
                <Col lg={2} sm={4}>
                  {fields.wordOrder.field}
                </Col>
              )}
              {doubleWordOrder.show && (
                <Col lg={2} sm={4}>
                  {fields.doubleWordOrder.field}
                </Col>
              )}
              {byte.show && (
                <Col lg={2} sm={4}>
                  {fields.byte.field}
                </Col>
              )}
            </Row>
          </BorderContainer>
        )}
      />
    );
  }
}

export default ModbusOP;
