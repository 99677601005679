import React from 'react';
import { connect } from 'react-redux';
import { updateAssetGraph } from 'ducks/actions/navigation';
import { Button, DataTable, IconButton } from 'app/common';
import { ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getUrl } from 'app/utils/navigation';
import Popup from '../../Helpers/Popup'
import i18n from 'app/i18n';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import AddGroup from './AddGroup';


const pages = {
  LIST: 'lista',
  ADD: 'nuovo'
};


class GroupList extends React.Component {

  state = {
    page: pages.LIST,
    groupId: null,
    deleteGroupId: null,
  }

  componentDidMount() {

  }

  getParsedData = () => {
    const { graphData, energyAsset } = this.props;
    const { assets, assetGroups } = graphData;
    let data = [];
    assets.forEach((asset) => {
      const { groupIds } = asset;
      groupIds.forEach((id) => {
        const group = assetGroups.find(ag => ag.id === id);
        if(group != null){
          const nAsset = JSON.parse(JSON.stringify(asset));
          const assetType = energyAsset.find(at => at.id === nAsset.supportedAssetId);
          nAsset.assetType = assetType.name;
          if (nAsset.qualifier !== undefined) {
            nAsset.assetType = `${assetType.name} - ${nAsset.qualifier}`;
          }
          nAsset.group = group;
          data.push(nAsset);
        }
      });
      if(groupIds.length === 0){
        const nAsset = JSON.parse(JSON.stringify(asset));
        const assetType = energyAsset.find(at => at.id === nAsset.supportedAssetId);
        nAsset.assetType = assetType.name;
        if (nAsset.qualifier !== undefined) {
          nAsset.assetType = `${assetType.name} - ${nAsset.qualifier}`;
        }
        data.push(nAsset);
      }
    });
    return data;
  }

  saveGroup = (assetGrpah) => {
     const { onChangeGroup } = this.props;
     this.setState({ page: pages.LIST })
     onChangeGroup(assetGrpah);
  }

  renderAddGruppo = () => {
    const { graphData, energyAsset } = this.props;
    const { groupId } = this.state;
    return (<AddGroup groupId={groupId} graphData={graphData} energyAsset={energyAsset} onUndo={()=>this.setState({page: pages.LIST})} onSave={this.saveGroup} />);
  }

  deleteGroup = () => {
    const { deleteGroupId } = this.state;
    const { graphData, onChangeGroup } = this.props;
    const { assets } = graphData;
    assets.forEach((asset) => {
      const groupIds = [];
      asset.groupIds.forEach((thisId) => {
         if (thisId !== parseInt(deleteGroupId,10)) {
          groupIds.push(thisId);
        }
      });
      asset.groupIds = groupIds;
    });
    const groupIndex = graphData.assetGroups.findIndex(grp => grp.id === parseInt(deleteGroupId,10));
    graphData.assetGroups.splice(groupIndex, 1);
    this.setState({ deleteGroupId: null })
    onChangeGroup(graphData);
  }

  renderList = () => {
    const { graphData, showCommand, siteId, history } = this.props;
    const { assetGroups } = graphData;
    const editButton = showCommand ? null : <ButtonGroup style={{marginTop: '3px', marginLeft: '3px', marginRight: '10px', float: 'left'}}><Link to={getUrl('/management/editgraph/group', 'sites', siteId)}><button className="btn btn-primary"><svg aria-hidden="true" data-prefix="fas" data-icon="save" class="svg-inline--fa fa-save fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg></button></Link></ButtonGroup>;
    return (
      <DataTable
        keyField="id"
        title={ <>{editButton}<span style={{float: 'left', marginTop: '9px'}}><Trans>Gruppi di asset</Trans></span><div style={{clear: 'both'}} /></> }
        search
        edit
        buttons={showCommand ? (
          <>

            <Button size="sm" color="primary" onClick={()=>this.setState({ page: pages.ADD, groupId: null })}><Trans>Nuovo gruppo</Trans></Button>
          </>
        ): null }
        width="100%"
        sortby="name"
        headers={[
          { title: <Trans>Nome</Trans>, property: 'name', index: 1, dataType: 'string', canSearch: true },
          { title: <Trans>Tipologia</Trans>, property: 'assetType', index: 2, dataType: 'string', canSearch: true },
        ]}
        rowButtons={asset => [
          {
            icon: "level-down-alt",
            label: <Trans>Apri</Trans>,
            onClick: () => history.push(getUrl('/management', 'sites', siteId, 'assets', asset.id))
          }
        ]}
        data={this.getParsedData()}
        noData={<Trans>Nessun asset</Trans>}
        groupby= {{
          field: 'group',
          name: 'name',
          order: 'asc',
          groups: assetGroups,
          noGroupHeader: <Trans>Nessun gruppo</Trans>,
          buttons: showCommand ? group => ([
            {
              icon: "level-down-alt",
              label: <Trans>Apri</Trans>,
              onClick: () => history.push(getUrl('/management', 'sites', siteId, 'assetgroups', group.id))
            },
            {
              icon: "pencil-alt",
              label: <Trans>Modifica</Trans>,
              onClick: () => this.setState({ page: pages.ADD, groupId: group.id })
            },
            {
              icon: "trash",
              label: <Trans>Elimina</Trans>,
              onClick: () => this.setState({ deleteGroupId: group.id })
            }
          ]
          ) :  group => ([
            {
              icon: "level-down-alt",
              label: <Trans>Apri</Trans>,
              onClick: () => history.push(getUrl('/management', 'sites', siteId, 'assetgroups', group.id))
            }
          ]
          ) 
        }}
      />
    );
  
  }

  render (){
    const { page, deleteGroupId } = this.state;
    

    return (
        <div>
          {deleteGroupId === null ? null:(
            <Popup onSave={this.deleteGroup} onSaveText={i18n._(t`Si, elminia`)} saveColor="danger" undoColor="link" onUndo={()=>{this.setState({deleteGroupId: null})}} modalTitle={i18n._(t`Elimina gruppo di Asset`)} modalContainer={(<div><Trans>Sei sicuro di voler eliminare questo gruppo di assets?</Trans></div>)} />
          )}
          {page === pages.LIST ? this.renderList(): null}
          {page === pages.ADD ? this.renderAddGruppo(): null}
        </div>
    )
  }
}


const mapStateToProps = (state) => {
  const { catalogs } = state;
  return {
    catalogs,
  };
};

const mapDispatchToProps = dispatch => ({
  updateAssetGraph: assetGraph => dispatch(updateAssetGraph(assetGraph)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
