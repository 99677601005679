import React from 'react';
import { Trans, t } from '@lingui/macro';
import i18n from 'app/i18n';
import styled from 'styled-components';
import { Input, Alert } from 'reactstrap';

const ModalP = styled.p`
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;
`;

const ModalLi = styled.li`
  font-size: 0.95rem;
  line-height: 1.2;
`;

const StyledRow = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content  : flex-start;
`;

const PhoneEdit = props => {
  const { onChange, phoneNumber, error } = props;

  return (
    <div>
      <ModalP>
        <Trans>
          Con le notifiche Telegram puoi ricevere gli allarmi di interesse sugli impianti monitorati
          da EXACTO, direttamente sul tuo smartphone o tablet.
        </Trans>
      </ModalP>
      <ModalP>
        <Trans>
          Inserisci di seguito il numero di telefono cellulare utilizzato con l’app Telegram e
          clicca su CONFERMA.
        </Trans>
      </ModalP>

      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <label>
          <Trans>Numero di telefono (cellulare)</Trans>
        </label>
      </div>
      <StyledRow>
        <div>
          <select className="form-control">
            <option>Italia (+39)</option>
          </select>
        </div>
        <div style={{marginLeft: '.5rem'}}>
          <Input
            invalid={error['phone'] != null}
            placeholder={i18n._(t`Inserisci il tuo numero di telefono`)}
            value={phoneNumber}
            name="phone"
            onChange={onChange}
          />
        </div>
      </StyledRow>
      <div>{error['phone'] != null ? <Alert color="danger">{error['phone']}</Alert> : null}</div>
      <ModalP>
        <Trans>
          Questo numero di telefono verrà aggiunto come numero di contatto del tuo account EXACTO
        </Trans>
      </ModalP>
      <ModalP>
        <Trans>
          Per iniziare a ricevere le notifiche Telegram da EXACTO, segui esattamente i passi
          seguenti:
        </Trans>
      </ModalP>
      <ol>
        <ModalLi>
          <Trans>Installa l’app</Trans>{' '}
          <a href="https://telegram.org/apps" target="_blank">
            Telegram
          </a>{' '}
          <Trans>
            sul tuo smartphone o tablet, registrandola con il numero di telefono cellulare sul quale
            desideri ricevere notifiche.
          </Trans>
        </ModalLi>
        <ModalLi>
          <Trans>
            Apri l’app Telegram e clicca sul pulsante di ricerca (icona a lente di ingrandimento, in
            alto a destra).
          </Trans>
        </ModalLi>
        <ModalLi>
          <Trans>Digita</Trans>{' '}
          <b>
            {process.env.REACT_APP_NODE_ENV === 'development' ? 'PHPower Test' : 'EXACTO'}{' '}
            Notifications
          </b>{' '}
          <Trans>per cercare il bot delle notifiche di EXACTO.</Trans>
        </ModalLi>
        <ModalLi>
          <Trans>Seleziona il bot</Trans>{' '}
          <i>
            {process.env.REACT_APP_NODE_ENV === 'development' ? 'PHPower Test' : 'EXACTO'}{' '}
            Notifications
          </i>{' '}
          <Trans>
            (è quello con tre barrette colorate all'interno di un monitor{' '}
            {process.env.REACT_APP_NODE_ENV === 'development' ? 'nero' : 'blu'}).
          </Trans>
        </ModalLi>
        <ModalLi>
          <Trans>Nella nuova schermata dell’app, clicca sul pulsante</Trans>{' '}
          <b>
            <Trans>AVVIA</Trans>
          </b>{' '}
          <Trans>per avviare il bot.</Trans>
        </ModalLi>
        <ModalLi>
          <Trans>Dopo aver ricevuto il messaggio</Trans>{' '}
          <i>
            <Trans>“Per completare l’identificazione, condividi il tuo numero di telefono”</Trans>
          </i>
          , clicca sul pulsante{' '}
          <b>
            <Trans>Condividi</Trans>
          </b>{' '}
          <Trans>visualizzato in fondo alla schermata.</Trans>
        </ModalLi>
        <ModalLi>
          <Trans>Ti apparirà la domanda</Trans>{' '}
          <i>
            <Trans>“Condividere il tuo numero di telefono?”</Trans>
          </i>
          . Clicca su{' '}
          <b>
            <Trans>CONDIVIDI CONTATTO.</Trans>
          </b>
        </ModalLi>
        <ModalLi>
          <Trans>
            Sei alla fine: dopo aver ricevuto il messaggio con la conferma del tuo numero di
            telefono, sei pronto a ricevere i nuovi allarmi da EXACTO!
          </Trans>
        </ModalLi>
      </ol>
    </div>
  );
};

export default PhoneEdit;
