import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlusCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { i18nMark } from "@lingui/react"

const StyledDropDownToggle = styled(DropdownToggle)`
  border-radius: 0.5rem !important;
`;
const StyledButton = styled(Button)`
  border-radius: 0.5rem !important;
`;
const StyledSimpleButton = styled.button`
  border-radius: 0.5rem !important;
`;


const DiagramButton = ({ selectedNodeKeys, onListAssetMeter, onSave, onShowDefinitivo, onGeneraComplesso, nodeDataArray, showComplexGraphButton, onShowPrincipale, isComplexGraph, assetSelect }) => {

  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);

  let buttonListAssetmeter = null;
  let buttonAssetComplesso = null;

  if (selectedNodeKeys.length === 1) {
    buttonListAssetmeter = (
      <ButtonGroup>
      &nbsp;
      <StyledSimpleButton
        title="Associazione misure"
        className="btn btn-primary"
        type="button"
        onClick={onListAssetMeter}
      >
        <svg
          aria-hidden="true"
          data-prefix="fas"
          data-icon="save"
          className="svg-inline--fa fa-save fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm102.77 119.59l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36z"
          />
        </svg>
      </StyledSimpleButton>
      </ButtonGroup>
    );
  }

  if (showComplexGraphButton) {
    let textAssetComplesso = i18nMark('Genera asset complesso');
    if (selectedNodeKeys.length === 1) {
      const index = nodeDataArray.findIndex(node => node.key === selectedNodeKeys[0]);
      if (index >= 0 && nodeDataArray[index].isComplex) {
        textAssetComplesso = i18nMark('Modifica asset complesso');
      }
    }
    buttonAssetComplesso = (
	    <span>
	   	  &nbsp;
	      <StyledSimpleButton
	        title={textAssetComplesso}
	        className="btn btn-secondary"
	        type="button"
	        onClick={onGeneraComplesso}
	      >
	        <svg
	          aria-hidden="true"
	          data-prefix="fas"
	          data-icon="save"
	          className="svg-inline--fa fa-save fa-w-14"
	          role="img"
	          xmlns="http://www.w3.org/2000/svg"
	          viewBox="0 0 448 512"
	        >
	          <path
	            fill="currentColor"
	            d="M576 192c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zM64 240c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm449.6-37.2l-19.2-25.6-48 36 19.2 25.6 48-36zM576 384c-14.4 0-27.6 5-38.3 13l-96-57.6c3.8-11.2 6.3-23 6.3-35.5 0-61.9-50.1-112-112-112-8.4 0-16.6 1.1-24.4 2.9l-40.8-87.4C281.4 96 288 80.8 288 64c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304c0 61.9 50.1 112 112 112 32.1 0 60.8-13.7 81.2-35.3l95.8 57.5c-.5 3.2-1 6.5-1 9.8 0 35.3 28.7 64 64 64s64-28.7 64-64-28.7-64-64-64zm-240-32c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-184-32h48v-32h-48v32z"
	          />
	        </svg>
	      </StyledSimpleButton>
	     </span>
    );
  }
  let showDefinitivo = (
   
     <ButtonGroup>
       &nbsp;
        <StyledButton
          onClick={onShowDefinitivo}
          title={i18nMark('Ultimo grafo definitivo')}
          color="secondary"
        >
          <FontAwesomeIcon 
            icon={faSignOutAlt}
          />
        </StyledButton>
    </ButtonGroup>
  );
  let saveText = i18nMark("Salva come definitivo");
  if (isComplexGraph) {
    saveText = i18nMark("Salva asset complesso");
    showDefinitivo = (
     <ButtonGroup>
        &nbsp;
        <Button
          title={i18nMark("Torna al grafo principale")}
          onClick={onShowPrincipale}
          color="secondary"
          type="Button"
        >
          <FontAwesomeIcon 
            icon={faSignOutAlt}
          />
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <div id="diagramButtonBar">
    <ButtonGroup>
        <Dropdown isOpen={isAddDropdownOpen} toggle={() => setIsAddDropdownOpen(!isAddDropdownOpen)}>
          <StyledDropDownToggle color="primary" title="Aggiungi asset">
            <FontAwesomeIcon
              icon={faPlusCircle}
            />

          </StyledDropDownToggle>
          <DropdownMenu>
            {assetSelect}
          </DropdownMenu>
        </Dropdown>
      </ButtonGroup>

      {buttonListAssetmeter}
    
       &nbsp;
      <ButtonGroup>
        <StyledButton
          title={saveText}
          onClick={onSave}
          color="primary"
        >
          <FontAwesomeIcon
            icon={faSave}
          />
        </StyledButton>
      </ButtonGroup>
      {buttonAssetComplesso}
      {showDefinitivo}
    </div>
  );
};

export default DiagramButton;
