import PropTypes from 'prop-types';
import React from 'react';
import { widgetTypes } from '../common';
import BarChart from './BarChart';
import CircleChart from './CircleChart';
import DonutChart from './DonutChart';
import Gauge from './Gauge';
import MultiWidget from './MultiWidget';
import TableWidget from './TableWidget';
import TimeProfileChart from './TimeProfileChart';

const WidgetBody = props => {
  const {
    widget,
    dataToPass,
    edit,
    width,
    height,
    units,
    physicaldimensions,
    shouldUpdateWidgets,
    canSendCommand,
    currentModule,
    setTableData,
    toggleFullscreen,
    isFullscreen
  } = props;

  let body;

  if (!units) {
    return null;
  }

  switch (widget.widgetType) {
    case widgetTypes.SERIES: {
      body = (
        <TimeProfileChart
          shouldUpdate={shouldUpdateWidgets}
          widget={widget}
          data={dataToPass}
          width={width}
          height={height}
          showLegend={widget.legend}
        />
      );
      break;
    }
    case widgetTypes.PIE: {
      if (widget.type && widget.type.indexOf('bar') > -1) {
        let barData = dataToPass;
        if (widget.format === 'ratio') {
          const total = dataToPass.map(data => data.value.v).reduce((a, b) => a + b, 0);
          barData = dataToPass.map(data => {
            return {
              ...data,
              unit: '%',
              value: { ...data.value, r: (data.value.v / total) * 100 }
            };
          });
        }
        body = (
          <BarChart
            shouldUpdate={shouldUpdateWidgets}
            widget={widget}
            data={barData}
            width={width}
            height={height}
            showLegend={widget.legend}
            period={widget.period}
          />
        );
      } else {
        body = (
          <DonutChart
            shouldUpdate={shouldUpdateWidgets}
            widget={widget}
            data={dataToPass}
            width={width}
            height={height}
            showLegend={widget.legend}
            period={widget.period}
          />
        );
      }
      break;
    }
    case widgetTypes.CIRCLE: {
      body = (
        <CircleChart
          shouldUpdate={shouldUpdateWidgets}
          data={dataToPass}
          width={width}
          height={height}
          widget={widget}
        />
      );
      break;
    }
    case widgetTypes.GAUGE: {
      body = (
        <Gauge
          shouldUpdate={shouldUpdateWidgets}
          data={dataToPass}
          width={width}
          height={height}
          period={widget.period}
          widget={widget}
        />
      );
      break;
    }
    case widgetTypes.TABLE: {
      body = (
        <TableWidget
          setTableData={setTableData}
          isEdit={edit}
          currentModule={currentModule}
          widget={widget}
          shouldUpdate={shouldUpdateWidgets}
          data={dataToPass}
          width={width}
          height={height}
          canSendCommand={canSendCommand}
          units={units}
          physicaldimensions={physicaldimensions}
        />
      );
      break;
    }
    case widgetTypes.DISPLAY:
    case widgetTypes.SWITCH:
    case widgetTypes.SLIDER:
    case widgetTypes.LED: {
      body = (
        <MultiWidget
          widget={widget}
          shouldUpdate={shouldUpdateWidgets}
          data={dataToPass}
          width={width}
          height={height}
          canSendCommand={canSendCommand}
          period={widget.period}
          toggleFullscreen={() => toggleFullscreen()}
          isFullscreen={isFullscreen}
        />
      );
      break;
    }
    default:
  }
  return body;
};

WidgetBody.propTypes = {
  dataToPass: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  initialData: PropTypes.object.isRequired,
  lastValues: PropTypes.object,
  shouldUpdateWidgets: PropTypes.bool,
  widget: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
};

WidgetBody.defaultProps = {
  lastValues: {},
  shouldUpdateWidgets: true
};

export default WidgetBody;
