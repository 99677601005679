import React, { useState } from 'react';
import { Tag } from 'app/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import api from 'api';
import useInterval from 'app/hooks/useInterval';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

const STATUS = Object.freeze({ RUNNING: 'running', STOPPED: 'stopped', WAITING_CONFIG: 'waiting_configuration', PENDING: 'pending' });
const COLORS = Object.freeze({ NEUTRAL: '#bbb', OK: '#0a0', WARNING: '#da0', ERROR: '#d00' });

const SpinnerIcon = styled(FontAwesomeIcon)`
  color: #999;
`;

const GatewayStatus = (props) => {
  const { gatewayId } = props;

  const [gatewayStatus, setGatewayStatus] = useState();
  const [delay, setDelay] = useState(300);

  const refreshStatus = async () => {
    // setGatewayStatus({ connected: true, lastSeen: '2019-08-06T14:58:31.828Z', status: 'running' });
    try {
      const req = await api.get(`fieldgateways/${gatewayId}/status`);
      const status = req.data;
      setGatewayStatus(status);
    } catch (error) {
      setGatewayStatus({ error });
    }
  };

  useInterval(() => {
    refreshStatus();
    setDelay(60000);
  }, delay);


  if (!gatewayStatus) {
    return <SpinnerIcon icon="spinner" spin />;
  }

  const { connected, status, error } = gatewayStatus;
  if (error) {
    return null;
  }

  const color = '#fff';
  let backgroundColor = COLORS.NEUTRAL;
  let text = '...';

  if (connected === false) {
    backgroundColor = COLORS.ERROR;
    text = <Trans>Disconnesso</Trans>;
  } else {
    switch (status) {
      case STATUS.RUNNING:
        backgroundColor = COLORS.OK;
        text = <Trans>Connesso</Trans>;
        break;
      case STATUS.STOPPED:
        backgroundColor = COLORS.WARNING;
        text = <Trans>Interrotto</Trans>;
        break;
      case STATUS.PENDING:
      case STATUS.WAITING_CONFIG:
        backgroundColor = COLORS.WARNING;
        text = <Trans>Attesa config</Trans>;
        break;
      default:
        return null;
    }
  }
  return <Tag color={color} backgroundColor={backgroundColor}>{text}</Tag>;
};

GatewayStatus.propTypes = {
  gatewayId: PropTypes.number.isRequired,
};

export default GatewayStatus;
