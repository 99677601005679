import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import ForecastForm from './ForecastForm';

const ErrorMessage = styled.span`
  display: block;
  color: red;
`;

class Forecasts extends Component {
  constructor(props) {
    super(props);

    const { vectors } = props;
    this.state = {
      vectors: vectors.map(item => ({ item, ref: React.createRef() })),
      error: '',
    };
  }

  validate = async (data) => {
    this.setState({ error: '' });
    const { vectors } = this.state;
    const promises = vectors.map(item => item.ref.current.getValues(data));
    try {
      const values = await Promise.all(promises);
      if (values.some(x => x === null)) {
        throw new Error(i18n._(t`Sono presenti errori di validazione`));
      }
      const maxNumber = 3;
      if (values.filter(x => x._enabled && x.vector !== 'energia-elettrica').length > maxNumber) {
        throw new Error(i18n._(t`E' possibile inserire il forecast per un massimo di ${maxNumber} vettori termici`));
      }
      return true;
    } catch (e) {
      this.setState({ error: e.message });
      return false;
    }
  }

  getValues = async (data) => {
    const { vectors } = this.state;
    const promises = vectors.map(item => item.ref.current.getValues(data));
    try {
      const values = await Promise.all(promises);
      return values.filter(x => x._enabled);
    } catch (e) {
      this.setState({ error: e.message });
      return null;
    }
  }


  render() {
    const { readOnly, isNew, initialValues } = this.props;
    const { vectors, error } = this.state;

    return (
      <div>
        <p><Trans>Seleziona i forecast da creare in relazione al sito</Trans></p>
        {vectors.map((vector) => {
          const forecastValues = initialValues.find(x => x.vector === vector.item.naturalKey);
          return <ForecastForm ref={vector.ref} readOnly={readOnly} isNew={isNew} key={vector.item.forecastLabel} vector={vector.item} initialValues={forecastValues} />;
        })}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  }
}

Forecasts.propTypes = {
  vectors: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  isNew: PropTypes.bool,
};

Forecasts.defaultProps = {
  readOnly: false,
  isNew: true,
};

export default Forecasts;
