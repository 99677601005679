/* Author: Stefano Chessa */

import React from 'react';
import { Roles } from 'app/utils/auth';
import { AuthControl } from 'app/common';
import UUIDList from './UUIDList'

const GetUUID = (props) => {

  return (
    <AuthControl systemRole={Roles.SSYS}>
      <UUIDList {...props} />
    </AuthControl>
  );
}

export default GetUUID;