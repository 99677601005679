import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { AuthControl, Button, DataTable, IconButton } from 'app/common';
import { Roles } from 'app/utils/auth';

const ManageReports = ({ reports, url, onDelete, onShare, onGeneratePdf, history }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("filter");
  return (
    <Card>
      <CardHeader>
        <Trans>Report configurati</Trans>
      </CardHeader>
      <CardBody>
        <DataTable
          keyField="id"
          search
          filter={filter}
          sortby="name"
          buttons={<AuthControl domainRole={Roles.OPE_Commissioning} systemRole={Roles.SYS}><Button size="sm" color="primary" tag={Link} to={`${url}/new`}><Trans>Nuovo report</Trans></Button></AuthControl>}
          width="100%"
          headers={[
            { index: 1, title: <Trans>Nome</Trans>, property: 'name', dataType: 'string', canSearch: true, cell: (report, parent, filter) => <Link to={`${url}/${report.id}/history`+ (filter ? ("?filter=" + filter) : "")}>{report.name}</Link>, addFilter: true },
            { index: 2, title: <Trans>Visibilità</Trans>, property: 'personalInstance', dataType: 'string', canSearch: true, cell: (report) => (report.personalInstance) ? <Trans>Privato</Trans> : <Trans>Condiviso</Trans> },
            { index: 3, title: <Trans>Periodicità</Trans>, property: 'generationPeriodicity', dataType: 'generationperiodicity', canSearch: true },
            { index: 4, title: <Trans>Ultima modifica</Trans>, property: 'updatedInfo', dataType: 'lastupdate', canSearch: true, disableSort: true },
            { index: 5, title: <Trans>Ultima emissione</Trans>, property: 'lastDateEmission', dataType: 'date', canSearch: true, cell: report => report.lastDateEmission ? moment(report.lastDateEmission).format('DD/MM/YYYY HH:mm') : '-' },
            { index: 6, title: <Trans>Periodo di competenza</Trans>, property: '', dataType: 'string', canSearch: true, cell: report => report.periodReference ? report.periodReference : '-' },
          ]}
          rowButtons={(report) => [
            {
              icon: "file-pdf",
              label: <Trans>Genera Pdf</Trans>,
              onClick: () => onGeneratePdf(report, false)
            },
            {
              icon: "external-link-alt",
              label: <Trans>Condividi</Trans>,
              onClick: () => onShare(report)
            },
            {
              icon: "pencil-alt",
              label: <Trans>Modifica</Trans>,
              onClick: (id, filter) => history.push(`${url}/${report.id}/edit` + (filter ? ("?fromFilter=" + filter) : ""))
            },
            {
              icon: "trash",
              label: <Trans>Elimina</Trans>,
              onClick: () => onDelete(report)
            },
          ]
          }
          data={reports}
          noData={<Trans>Nessun report</Trans>}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { userCurrentRoles, userSystemRoles } = state.auth;
  return { userCurrentRoles, userSystemRoles };
};

export default connect(mapStateToProps)(ManageReports);
