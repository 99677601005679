import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import i18n from 'app/i18n';
import {
  DataTable,
  Switch,
  Select,
  RoundSelect,
  Button,
  DynamicForm,
  Spinner,
  MultiSelectTag
} from 'app/common';
import { Roles } from 'app/utils/auth';
import { saveAlarm } from './alarmSave';
import {
  mapSeverityColor,
  translateAlarmEventName,
  getPersistencyDataFromGranularity,
  alarmDurationLabel
} from '../alarmsCommon';
import log from 'app/common/log';
import EventTagModals from '../EventTagModals';
import { getUrlFromPath } from 'app/utils/navigation';
import { loadObservablePropertiesSuccess } from 'ducks/actions/observableproperties';
import { connect } from 'react-redux';


const granularityList = [
  { value: 'raw', label: t`Raw data` },
  { value: '1m', label: t`${1} minuto` },
  { value: '5m', label: t`${5} minuti` },
  { value: '10m', label: t`${10} minuti` },
  { value: '15m', label: t`${15} minuti` },
  { value: '30m', label: t`${30} minuti` },
  { value: '1h', label: t`${1} ora` },
  { value: '1dy', label: t`${1} giorno` }
];

const Error = styled.span`
  color: red;
`;

const LeftButtons = styled.div`
  flex: 1;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const createPersistencyLabel = alarm => {
  const { label } = getPersistencyDataFromGranularity(alarm.granularity);
  let result = alarm && label ? label : '';
  result = alarm.isBoolean ? '-' : result;
  result = alarm.type === 'comparison1' || alarm.type === 'comparison2' ? '-' : result;
  return result;
};

const createConditionText = alarm => {
  const i18nValore = i18n._(t`Valore`);
  const durationLabel = alarmDurationLabel(alarm);

  const labelMap = {
    noData: `${i18nValore}${durationLabel} ${i18n._(t`non disponibile`)}`,
    zero: `${i18nValore}${durationLabel} = 0 ${alarm.unit}`,
    notZero: `${i18nValore}${durationLabel} ≠ 0 ${alarm.unit}`,
    comparison1: `${i18nValore}${durationLabel} ${alarm.operator}`,
    comparison2: `${i18nValore}${durationLabel} ${alarm.operator}`,
    binaryOnOff: `${i18nValore} = ${alarm.value ? 'On' : 'Off'}`
  };
  return <div>{alarm && labelMap[alarm.type] ? labelMap[alarm.type] : ''}</div>;
};

const createOperatorSelector = (alarm, onChange, disabled) => (
  <div style={{ width: '50%' }}>
    <Select
      directionUp
      disabled={!alarm.state || disabled}
      options={[
        { label: '>', value: '>' },
        { label: '≥', value: '≥' },
        { label: '=', value: '=' },
        { label: '≠', value: '≠' },
        { label: '<', value: '<' },
        { label: '≤', value: '≤' }
      ]}
      value={alarm.operator}
      onChange={onChange}
    />
  </div>
);

const createConditionOnThreshold = (alarm, onChange, disabled) => (
  <div style={{ marginLeft: '0.5rem' }}>
    <InputGroup>
      <NumberFormat
        invalid={!alarm.validValue}
        inputMode="numeric"
        customInput={Input}
        value={alarm.value}
        disabled={!alarm.state || disabled}
        onChange={onChange}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>{alarm.unit}</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </div>
);

const createConditionBinary = (alarm, onChange, disabled) => (
  <Select
    options={[
      { value: 1, label: 'Off -> On' },
      { value: 0, label: 'On -> Off' }
    ]}
    value={alarm.value || 0}
    disabled={disabled}
    keyProperty="value"
    labelProperty="label"
    onChange={onChange}
  />
);

const createConditionComponent = (alarm, onValueChange, onOperatorChange, disabled) => {
  let result;
  switch (alarm.type) {
    case 'noData':
    case 'zero':
    case 'notZero':
      result = createConditionText(alarm);

      break;
    case 'binaryOnOff':
      result = createConditionBinary(alarm, onValueChange, disabled);
      break;
    case 'comparison1':
    case 'comparison2':
      result = (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: `space-between` }}>
            <div style={{ marginRight: '0.5rem' }}>
              {i18n._(t`Valore`)}
              {alarmDurationLabel(alarm)}
            </div>
            {createOperatorSelector(alarm, onOperatorChange, disabled)}
            {createConditionOnThreshold(alarm, onValueChange, disabled)}
          </div>
        </>
      );

      break;
    default:
      result = <div />;
  }
  return result;
};

const translateTag = (tag, language) => {
  return (tag && tag.translations && tag.translations[language]) || tag.name;
};

const EditAlarm = props => {
  const {
    match,
    getAlarmData,
    history,
    goBack,
    switchButtons,
    tags,
    language,
    getEventTagNameByKey,
    userSystemRoles,
    companyList,
    forceTagsReload,
    readOnly,
    isModal,
    closeModal
  } = props;
  const { params } = match;

  const { alarmId, intAlarmId = +alarmId } = params;

  const [opAlarmData, setOpAlarmData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newEventTagData, setNewEventTagData] = useState({
    show: false,
    name: '',
    type: 'new',
    predefinedGrant: userSystemRoles.includes(Roles.SYS)
  });
  const [temporarySelectedEventTags, setTemporarySelectedEventTags] = useState();

  const tagOptions =
    tags &&
    tags.map(tag => ({
      label: translateTag(tag, language),
      naturalKey: tag.naturalKey,
      marked: tag.companyId == null
    }));
  function showGranularityList() {
    const filterAllButRawValues = ({ value }) => value !== 'raw';
    let actualFilter = () => true;
    actualFilter =
      opAlarmData[0] && opAlarmData[0].isVirtualMeter && !opAlarmData[0].isBoolean
        ? filterAllButRawValues
        : actualFilter;
    const granularitySubList = granularityList
      .filter(actualFilter)
      .map(({ value, label }) => ({ value, label: i18n._(label) }));
    return granularitySubList;
  }
  let error;

  const getNamedEventTag = tagKey => ({
    naturalKey: tagKey,
    label: getEventTagNameByKey(tagKey)
  });

  useEffect(() => {
    setLoading(true);
    const newAlarmData = getAlarmData(intAlarmId).map(alarm => ({
      ...alarm,
      observationPeriodLabel: i18n._(t`Sempre`),
      persistencyLabel: createPersistencyLabel(alarm),
      eventName: alarm.eventName,
      validName: true,
      validValue: true
    }));
    setOpAlarmData(newAlarmData);
    setLoading(false);
  }, [props]);

  useEffect(() => {
    log.debug('opAlarmData changed to: ', opAlarmData);
  }, [opAlarmData]);

  const isThresholdValueValid = value => {
    try {
      const intValue = parseInt(value, 10);
      return !Number.isNaN(intValue) && Number.isFinite(intValue);
    } catch {
      return false;
    }
  };

  const isAlarmNameValid = eventName =>
    !eventName ||
    (eventName != null && typeof eventName === 'string' && eventName.trim().length > 0);

  const changeSeverity = (item, alarmItem) => {
    const alarmIndex = opAlarmData.findIndex(alarm => alarm === alarmItem); // reference equality

    if (alarmIndex >= 0) {
      const newFeAlarm = { ...opAlarmData[alarmIndex], severity: item.value };
      const newOpAlarmData = [...opAlarmData];
      newOpAlarmData[alarmIndex] = newFeAlarm;
      setOpAlarmData(newOpAlarmData);
    }
  };

  const changeEventName = (item, alarmItem) => {
    const alarmIndex = opAlarmData.findIndex(alarm => alarm === alarmItem); // reference equality
    if (alarmIndex >= 0) {
      const validName = isAlarmNameValid(item.value);
      const newFeAlarm = { ...opAlarmData[alarmIndex], eventName: item.value, validName };
      const newOpAlarmData = [...opAlarmData];
      newOpAlarmData[alarmIndex] = newFeAlarm;
      setOpAlarmData(newOpAlarmData);
    }
  };

  const changeGranularity = value => {
    const newOpAlarmData = opAlarmData
      .map(opAlarm => ({
        ...opAlarm,
        granularity: value
      }))
      .map(opAlarm => ({
        ...opAlarm,
        persistencyLabel: createPersistencyLabel(opAlarm),
        persistencySec:
          opAlarm.type === 'zero' || opAlarm.type === 'notZero' || opAlarm.type === 'noData'
            ? getPersistencyDataFromGranularity(value).sec
            : 0
      }));
    setOpAlarmData(newOpAlarmData);
  };

  const changeTemporaryEventTags = (eventTags, alarmItem) => {
    const newTemporaryAlarmTag = { [alarmItem.type]: eventTags.map(tag => tag.naturalKey) };
    setTemporarySelectedEventTags(tags => ({ ...tags, ...newTemporaryAlarmTag }));
    log.debug('changeTemporaryEventTags %o %o', eventTags, alarmItem, newTemporaryAlarmTag);
  };

  const changeState = alarmItem => {
    const alarmIndex = opAlarmData.findIndex(alarm => alarm.type === alarmItem.type); // reference equality

    if (alarmIndex >= 0) {
      const newFeAlarm = {
        ...opAlarmData[alarmIndex],
        state: !alarmItem.state
      };
      newFeAlarm.validName = newFeAlarm.state
        ? newFeAlarm.validName
        : isAlarmNameValid(newFeAlarm.eventName);
      if (newFeAlarm.type === 'comparison') {
        if (!newFeAlarm.state) {
          if (!isThresholdValueValid(newFeAlarm.value)) {
            newFeAlarm.value = '';
            newFeAlarm.validValue = true;
          }
        } else {
          newFeAlarm.validValue = isThresholdValueValid(newFeAlarm.value);
        }
      }
      const newOpAlarmData = [...opAlarmData];
      newOpAlarmData[alarmIndex] = newFeAlarm;
      setOpAlarmData(newOpAlarmData);
    }
  };

  const changeConditionValue = alarmItem => ({ target }) => {
    const alarmIndex = opAlarmData.findIndex(alarm => alarm.type === alarmItem.type); // reference equality
    if (alarmIndex >= 0) {
      const newFeAlarm = { ...opAlarmData[alarmIndex], value: target && target.value };
      const newOpAlarmData = [...opAlarmData];
      if (newFeAlarm.type === 'binaryOnOff') {
        newFeAlarm.eventName = `Passaggio allo stato ${target && target.value ? 'On' : 'Off'}`;
        newFeAlarm.validValue = true;
      } else {
        newFeAlarm.validValue = isThresholdValueValid(newFeAlarm.value);
      }
      newOpAlarmData[alarmIndex] = newFeAlarm;
      setOpAlarmData(newOpAlarmData);
    }
  };

  const changeConditionOperator = alarmItem => ({ target }) => {
    const alarmIndex = opAlarmData.findIndex(alarm => alarm.type === alarmItem.type); // reference equality
    if (alarmIndex >= 0) {
      const newFeAlarm = { ...opAlarmData[alarmIndex], operator: target && target.value };
      const newOpAlarmData = [...opAlarmData];
      newOpAlarmData[alarmIndex] = newFeAlarm;
      setOpAlarmData(newOpAlarmData);
    }
  };

  const cancel = () => {
    if(alarmId){
      if(isModal){
        closeModal();
      }else{
        const queryParameters = new URLSearchParams(window.location.search);
        const filter = queryParameters.get("fromFilter");
        history.push(getUrlFromPath('/alarms/configuration' + (filter ? ('?filter=' + filter) : "") + '#'+ alarmId));
      }
    }else{
      const queryParameters = new URLSearchParams(window.location.search);
      const filter = queryParameters.get("fromFilter");
      history.push(getUrlFromPath('/alarms/configuration' + (filter ? ('?filter=' + filter) : "")));
    }
  };

  const save = async () => {
    log.debug('Action data saving...');
    setLoading(true);
    let isValid = opAlarmData.reduce(
      (acc, alarm) => acc && alarm.validName && alarm.validValue,
      true
    );
    const comparisonAlarms = opAlarmData.filter(
      alarm => alarm.type === 'comparison1' || alarm.type === 'comparison2'
    );
    const sameThresholds =
      comparisonAlarms &&
      comparisonAlarms[0] &&
      comparisonAlarms[1] &&
      comparisonAlarms[0].state &&
      comparisonAlarms[1].state &&
      comparisonAlarms[0].value === comparisonAlarms[1].value;
    isValid = isValid && !sameThresholds;
    if (sameThresholds) {
      toast.error(<Trans>I valori delle 2 soglie non possono coincidere</Trans>);
    }
    if (!isValid) {
      setLoading(false);
      return;
    }
    try {
      const newOpAlarmData = opAlarmData.map(alarm => {
        const tempTags = temporarySelectedEventTags && temporarySelectedEventTags[alarm.type];
        return { ...alarm, event_tags: tempTags ? tempTags : alarm.event_tags };
      });
      // const newOpAlarmData.event_tags = temporarySelectedEventTags && temporarySelectedEventTags[opAlarmData.type] ? temporarySelectedEventTags[opAlarmData.type] :  opAlarmData.event_tags;
      const updatedAlarm = await saveAlarm(newOpAlarmData);
      saveUpdatedAlarm(updatedAlarm);
      console.log('Save alarm successful ', opAlarmData);
      setLoading(false);
      if(isModal){
        closeModal();
      }else{
        const queryParameters = new URLSearchParams(window.location.search);
        const filter = queryParameters.get("fromFilter");
        history.push(getUrlFromPath('/alarms/configuration' + (filter ? ('?filter=' + filter) : "") + '#'+ (newOpAlarmData && newOpAlarmData.length > 0 && newOpAlarmData[0].id)));
      }
    } catch (ex) {
      setLoading(false);
      toast.error(ex);
    }
  };

  const saveUpdatedAlarm = newAlarm => {
    const { observableproperties, loadObservablePropertiesSuccess } = props;
    const dataFlowAlerts = newAlarm.map(el => el.createdDataFlowAlert);
    const dataFlowFilters = newAlarm.map(el => el.createdDataFlowFilter);
    const newObservedProperties = observableproperties.map(op => op.id.toString() === alarmId ? {...op, dataFlowAlerts: dataFlowAlerts, dataFlowFilters: dataFlowFilters} : op);
    loadObservablePropertiesSuccess(newObservedProperties);
  }

  const severityItems = [
    { color: mapSeverityColor('critical'), value: 'critical', text: 'Alta' },
    { color: mapSeverityColor('medium'), value: 'medium', text: 'Media' },
    { color: mapSeverityColor('low'), value: 'low', text: 'Bassa' }
  ];

  const createPlantCell = field => {
    const siteName = field && field.value && field.value;
    return <Input value={siteName} disabled />;
  };

  const openView = () => {
    const { url } = match;
    history.push(url + "/edit");
  }

  return (
    <div>
      {loading && <Spinner />}
      <Card>
        <CardHeader>
          <Trans>Impostazione allarmi predefiniti</Trans>
          {opAlarmData[0] ? ` per ${opAlarmData[0].name} ` : ''}
          {switchButtons}
        </CardHeader>
        <CardBody>
          <DynamicForm
            error={error}
            hideButtons
            initialValues={opAlarmData && opAlarmData[0]}
            readOnly={readOnly}
            fields={[
              {
                name: 'siteName',
                type: 'custom',
                label: <Trans>Impianto</Trans>,
                disabled: true,
                md: 6,
                render: field => createPlantCell(field)
              },
              {
                name: 'entity',
                type: 'text',
                label: <Trans>Entità</Trans>,
                disabled: true,
                md: 6
              },
              {
                name: 'granularity',
                disabled: (opAlarmData && opAlarmData[0] && opAlarmData[0].isBoolean) || loading,
                type: 'select',
                label: <Trans>Granularità dei dati</Trans>,
                options: showGranularityList(),
                validation: { required: true },
                onChange: value => {
                  changeGranularity(value);
                },
                md: 6
              },
              {
                name: 'unit',
                type: 'text',
                label: <Trans>Unità di misura</Trans>,
                disabled: true,
                md: 6
              }
            ]}
          />
          <DataTable
            keyField="alarmId"
            edit
            width="100%"
            readOnly={readOnly}
            headers={[
              {
                title: <Trans>Allarme</Trans>,
                property: 'alarmId',
                index: 0,
                draggable: false,
                dataType: 'string',
                hidden: true
              },
              {
                title: <Trans>Criticità</Trans>,
                property: 'severity',
                index: 1,
                draggable: false,
                width: '100px',
                dataType: 'custom',
                canSearch: true,
                cell: row => (
                  <RoundSelect
                    name="image"
                    value={row.severity}
                    items={severityItems}
                    disabled={readOnly}
                    onChange={e => changeSeverity(e.target, row)}
                  />
                )
              },
              {
                title: <Trans>Allarme</Trans>,
                property: 'eventName',
                index: 2,
                draggable: false,
                dataType: 'custom',
                canSearch: true,
                cell: row => (
                  <>
                    <Input
                      disabled={readOnly}
                      value={translateAlarmEventName(row)}
                      onChange={e => changeEventName(e.target, row)}
                      className={row.validName ? '' : 'is-invalid'}
                    />
                  </>
                )
              },
              {
                title: <Trans>Abilitato</Trans>,
                property: 'state',
                index: 3,
                draggable: false,
                width: '10rem',
                dataType: 'custom',
                cell: row => (
                  <Switch
                    id={row.type}
                    checked={row.state}
                    disabled={readOnly}
                    labelOn=" "
                    labelOff=" "
                    onChange={() => changeState(row)}
                  />
                )
              },
              {
                title: <Trans>Condizione</Trans>,
                property: 'value',
                index: 4,
                draggable: false,
                dataType: 'string',
                canSearch: true,
                width: '20rem',
                cell: row =>
                  createConditionComponent(
                    row,
                    changeConditionValue(row),
                    changeConditionOperator(row),
                    readOnly
                  )
              },
              {
                title: <Trans>Tag</Trans>,
                property: 'tags',
                index: 5,
                draggable: false,
                dataType: 'custom',
                cell: opAlarm => (
                  <MultiSelectTag
                    showCreate={i18n._(t`Nuovo tag`)}
                    name={'nome'}
                    value={
                      temporarySelectedEventTags && temporarySelectedEventTags[opAlarm.type]
                        ? temporarySelectedEventTags[opAlarm.type].map(getNamedEventTag)
                        : opAlarm.event_tags && opAlarm.event_tags.map(getNamedEventTag)
                    }
                    disabled={loading || readOnly}
                    options={tagOptions}
                    keyProperty={'naturalKey'}
                    labelProperty={'label'}
                    onChange={({ target }) => changeTemporaryEventTags(target.value, opAlarm)}
                    onCreate={p =>
                      setNewEventTagData(data => {
                        const { tag } = data;
                        const newTag = { ...tag, name: p };
                        return { ...data, alarmItem: opAlarm, tag: newTag, show: true };
                      })
                    }
                  />
                )
              },
              {
                title: <Trans>Persistenza condizione</Trans>,
                property: 'persistencyLabel',
                index: 7,
                draggable: false,
                dataType: 'custom',
                canSearch: true,
                width: '10rem',
                cell: opAlarm => (
                  <>
                    <div>{opAlarm.persistencyLabel}</div>
                    <div>({opAlarm.observationPeriodLabel})</div>
                  </>
                )
              }
            ]}
            data={opAlarmData || []}
            noData=""
          />
        </CardBody>
        <CardFooter>
          <ButtonGroup>
          <LeftButtons>
          {!readOnly && (<Button marginRight color="primary" onClick={save}>
            <Trans>Salva</Trans>
          </Button>)}
          <Button color="link" onClick={cancel}>
            <Trans>Annulla</Trans>
          </Button>
          {error && <Error>{error}</Error>}
          </LeftButtons>
          {readOnly && (<Button color="primary" onClick={openView}>
            <Trans>Modifica</Trans>
          </Button>)}
          </ButtonGroup>
        </CardFooter>
      </Card>
      <EventTagModals
        afterSubmit={tag => {
          const { naturalKey } = tag;
          const { alarmItem } = newEventTagData;
          setNewEventTagData(t => ({ ...t, tag }));
          const newOptionTag = getNamedEventTag(naturalKey);
          log.debug(
            'afterSubmit tag: %o newEventTagData: %o newOptionTag: %o',
            tag,
            newEventTagData,
            newOptionTag
          );
          setTemporarySelectedEventTags(alarmsTags => {
            const thisAlarmTags =
              alarmsTags && alarmsTags[alarmItem.type]
                ? [...alarmsTags[alarmItem.type], naturalKey]
                : [...alarmItem.event_tags, naturalKey];
            return {
              ...thisAlarmTags,
              [alarmItem.type]: thisAlarmTags
            };
          });
        }}
        tags={tags}
        isOpen={newEventTagData.show}
        toggle={() => setNewEventTagData(data => ({ ...data, show: false }))}
        data={newEventTagData}
        companyList={companyList}
        language={language}
        reload={forceTagsReload}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { observableproperties } = state.domain;
  return {
    observableproperties
  };
};

const mapDispatchToProps = dispatch => ({
  loadObservablePropertiesSuccess: (op) => dispatch(loadObservablePropertiesSuccess(op))
});

export default connect(mapStateToProps,mapDispatchToProps)(EditAlarm);
