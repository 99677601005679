/* eslint-disable import/prefer-default-export */
import api from 'api';

export const getAssetGraphByNode = async nodeInfo => {
  const { siteId, companyId, siteGroupId, _type, name } = nodeInfo;
  let apiCall;
  let result = {};
  const params = { filter: { include: ['assetGraph'] } };
  try {
    if (_type === 'company') {
      apiCall = `/Companies/${companyId}/sites?`;
    } else if (_type === 'site') {
      apiCall = `/Sites/${siteId}`;
    } else if (_type === 'sitegroup') {
      apiCall = `/SiteGroups/${siteGroupId}/sites`;
    } else {
      result.err = `getAssetGraphByNode - nor site, nor company, nor sitegroup: ${_type}`;
      console.error(result.err);
      return result;
    }
    const getResult = await api.get(apiCall, { params });
    const data = getResult.data && getResult.data.length ? getResult.data : [getResult.data];
    result.data = data.map(site => ({
      id: site.assetGraph.id,
      label: site.name,
      group: name
    }));
  } catch (ex) {
    console.error(`getAssetGraphByNode ${apiCall}`, ex);
    result.err = ex;
  }
  return result;
};
