import React, { Component } from 'react';
import mqtt from 'mqtt';
import api from 'api';
import { isEqual } from 'lodash';
import RealTimeContext from './RealTimeContext';
import logger from 'app/common/log';

class RealTimeProvider extends Component {
  state = {
    status: {},
    lastValues: {}, // object containing the last received value for every opId_aggregation (id of observed property)
    // lastValuesRaw: {},
    error: null
  };

  componentDidMount() {
    this.connections = []; // a list containing a client and a topic for every subscribed {idOp, aggregation}
    this.pollings = []; // a list containing the simulated connections for polled data
    this.subscriptions = []; // the list of current subscriptions, containing subscription data (unique key, idOp, aggregation, topic) and the function to call when a new message is received

    this.checkConnectionsInterval = setInterval(() => {
      console.log(this.connections, this.pollings, this.subscriptions, this.state.status);
      this.checkConnections();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.checkConnectionsInterval);
    this.reset();
  }

  // Closes all connections and deletes all subscription. Passed to children as props but not included in context provider. Use carefully
  reset = () => {
    try {
      this.connections.forEach(connection => connection.client.end());
      this.connections = [];
      this.pollings.forEach(connection => clearInterval(connection.interval));
      this.pollings = [];
      this.subscriptions = [];
    } catch (error) {
      this.setState({ error });
    }
  };

  parse = message => {
    try {
      return JSON.parse(message);
    } catch (e) {
      return message.toString();
    }
  };

  checkConnections = () => {
    this.connections.forEach(connection => {
      const { id, aggregation } = connection;
      const op = { id, aggregation };
      if (
        !this.subscriptions.some(s =>
          s.observedProperties.some(o => o.id === id && o.aggregation === aggregation)
        )
      ) {
        // orphaned client
        logger.debug('RTP checkConnections orphaned client %s', id);
        this.closeConnection(op);
      } else if (connection.error) {
        this.closeConnection(op);
        this.createConnection(op);
      } else {
        // Update wrong op status
        this.upgradeStatus(connection, op, connection.status, connection.error);
      }
      // else if (!connection.client) {
      //   console.log('reset connection', id);
      //   this.closeConnection({ id, aggregation });
      //   this.createConnection({ id, aggregation });
      // }
    });
  };

  // Updates the last values object, then call user defined function for every subscription to the topic
  handleMessage = (id, aggregation, message, mqtt = true) => {
    try {
      const parsedMessage = mqtt ? this.parse(message) : message;
      logger.debug('RTP handleMessage %o, is mqtt ? %s', parsedMessage, mqtt);

      // todo Rimuovere in seguito
      // if (aggregation === 'raw') {
      //   this.setState((prevState) => {
      //     if (!prevState.lastValuesRaw[id] || parsedMessage.t > prevState.lastValuesRaw[id].t) {
      //       newData = true;
      //       return { lastValuesRaw: { ...prevState.lastValuesRaw, [id]: parsedMessage } };
      //     }
      //   });
      // }
      const key = `${id}_${aggregation}`;
      let newData = false;
      this.setState(prevState => {
        if (!prevState.lastValues[key] || parsedMessage.t > prevState.lastValues[key].t) {
          newData = true;
          return { lastValues: { ...prevState.lastValues, [key]: parsedMessage } };
        }
      });
      if (newData) {
        const subs = this.getSubscriptionsToOP({ id, aggregation });
        subs.forEach(sub => {
          const { key, onMessage } = sub;
          if (onMessage) {
            const header = { id, aggregation, key };
            try {
              onMessage(header, parsedMessage);
            } catch (e) {
              logger.error('RTP handleMessage %o', e);
            }
          }
        });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  getNewKey = () =>
    (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();

  // addTopic = (topic) => {
  //   this.client.subscribe(topic, (error) => {
  //     if (error) {
  //       this.setState({ error });
  //     }
  //     this.setState(prevState => ({ topics: prevState.topics.concat(topic), data: { ...prevState.data, [topic]: [] } }));
  //   });
  // }

  getConnectionData = async ({ id, aggregation = 'raw' }) => {
    const connectionData = await api.get(`/ObservedProperties/${id}/${aggregation}/realTimeAccess`);
    return connectionData.data;
  };

  getSubscriptionsToOP = op => {
    logger.debug('RTP getSubscriptionsToOP %o', op);
    const { id, aggregation } = op;
    const ret = this.subscriptions.filter(sub =>
      sub.observedProperties.some(x => x.id === id && x.aggregation === aggregation)
    );
    return ret;
  };

  getWorstStatus = observedProperties => {
    const allStatus = observedProperties.map(x => x.status);
    if (allStatus.some(x => x === 'error')) {
      return 'error';
    }
    if (allStatus.some(x => x === 'offline')) {
      return 'offline';
    }
    if (allStatus.some(x => x === 'closed')) {
      return 'closed';
    }
    if (allStatus.some(x => x === 'reconnect')) {
      return 'reconnect';
    }
    if (allStatus.some(x => x === 'connecting')) {
      return 'connecting';
    }
    return 'connected';
  };

  upgradeStatus = (connection, op, status, error) => {
    if (!connection) {
      return;
    }
    const { id, aggregation } = op;
    connection.status = status;
    connection.error = error;

    this.setState(prevState => {
      const subs = this.getSubscriptionsToOP(op);

      const newStatus = {};
      subs.forEach(sub => {
        const { key } = sub;
        const { observedProperties } = prevState.status[key] || {};
        if (observedProperties) {
          const selectedOP = observedProperties.find(
            x => x.id === id && x.aggregation === aggregation
          );
          selectedOP.status = status;
          selectedOP.error = error;
          const subscriptionStatus = this.getWorstStatus(observedProperties);
          const numberConnected = observedProperties.filter(x => x.status === 'connected').length;
          newStatus[key] = { status: subscriptionStatus, observedProperties, numberConnected };
        }
      });
      const updatedStatus = { ...prevState.status, ...newStatus };
      if (!isEqual(updatedStatus, prevState.status)) {
        return { status: updatedStatus };
      }
      return prevState;
    });
  };

  createMQTTConnection = async op => {
    logger.debug('RTP createMQTTConnection %o', op);
    const { id, aggregation } = op;
    const connection = this.connections.find(x => x.id === id && x.aggregation === aggregation);

    if (!connection) {
      // If there is no connection to the required data, create it and subscribe to the topic
      const newConnection = { ...op };
      this.connections.push(newConnection);
      try {
        const { brokerUrl, clientId, token, topics } = await this.getConnectionData(op);
        const mqttOptions = {
          clientId,
          username: clientId,
          password: token
        };
        const [topic] = topics;
        const client = mqtt.connect(brokerUrl, mqttOptions);
        client.on('error', error => {
          this.upgradeStatus(newConnection, op, 'error', error);
          // this.closeConnection(op);

          // setTimeout(() => { console.log('Reconnecting after error', op.id); this.createConnection(op); }, 2000);
        });
        client.on('connect', () => {
          logger.debug('MQTT Connected: %o', newConnection);
          this.upgradeStatus(newConnection, op, 'connected');
        });
        client.on('reconnect', () => this.upgradeStatus(newConnection, op, 'reconnect'));
        client.on('close', () => this.upgradeStatus(newConnection, op, 'closed'));
        client.on('offline', () => this.upgradeStatus(newConnection, op, 'offline'));

        newConnection.client = client;

        client.subscribe(topic, error => {
          if (error) {
            logger.error('RTP MQTT client.subscribe error %s %o', topic, error);
            this.upgradeStatus(newConnection, op, 'error', error);
            return { error };
          }
          client.on('message', (topic, message) => {
            this.handleMessage(id, aggregation, message);
          });
        });
      } catch (error) {
        logger.error('RTP createConnection %o %o', op, error);
        this.upgradeStatus(newConnection, op, 'error', error);
        return { error };
      }
    }
  };

  getData = async (id, aggregation) => {
    let connection;
    try {
      connection = this.pollings.find(x => x.id === id && x.aggregation === aggregation);
      const res = await api.get(`ObservedProperties/${id}/${aggregation}/lastSample`);
      const d = res.data;
      this.handleMessage(id, aggregation, d, false);
      this.upgradeStatus(connection, { id, aggregation }, 'connected');
    } catch (error) {
      this.upgradeStatus(connection, { id, aggregation }, 'error', error);
    }
  };

  createLastSamplePolling = async op => {
    const { id, aggregation } = op;
    const connection = this.pollings.find(x => x.id === id && x.aggregation === aggregation);

    if (!connection) {
      logger.debug('RTP createLastSamplePolling %o', op);
      // If there is no connection to the required data, create it and subscribe to the topic
      const newConnection = { ...op };
      this.pollings.push(newConnection);
      try {
        this.getData(id, aggregation);
        newConnection.interval = setInterval(() => {
          this.getData(id, aggregation);
        }, 60000);
        this.upgradeStatus(newConnection, op, 'connected');
      } catch (error) {
        logger.error('createConnection error %o %o', op, error);
        this.upgradeStatus(newConnection, op, 'error', error);
        return { error };
      }
    }
  };

  createConnection = op => {
    // const { aggregation } = op;
    const { aggregation, otherDataSource } = op;
    const { dataClass } = otherDataSource || {};
    logger.debug('createConnection op %o', op);
    if (
      !['raw', '1m'].includes(aggregation) ||
      (otherDataSource != null && otherDataSource.class === 'user') ||
      dataClass === 'forecast'
    ) {
      this.createLastSamplePolling(op);
    } else {
      this.createMQTTConnection(op);
    }
  };

  closeMQTTConnection = op => {
    const connection = this.connections.find(
      x => x.id === op.id && x.aggregation === op.aggregation
    );
    if (connection) {
      if (connection.client) {
        connection.client.end();
      }
      this.connections = this.connections.filter(x => x !== connection);
    }
  };

  removeLastSamplePolling = op => {
    const connection = this.pollings.find(x => x.id === op.id && x.aggregation === op.aggregation);
    if (connection) {
      if (connection.interval) {
        clearInterval(connection.interval);
      }
      this.pollings = this.pollings.filter(x => x !== connection);
    }
  };

  closeConnection = op => {
    const { aggregation } = op;
    if (aggregation === 'raw') {
      this.closeMQTTConnection(op);
    } else {
      this.removeLastSamplePolling(op);
    }
  };

  subscribe = (observedProperties, onMessage) => {
    try {
      const observedPropertiesArray = Array.isArray(observedProperties)
        ? observedProperties
        : [observedProperties];
      const key = this.getNewKey();
      this.subscriptions.push({ key, observedProperties: observedPropertiesArray, onMessage });

      const subscriptionStatus = {
        status: 'connecting',
        observedProperties: observedPropertiesArray.map(x => ({ ...x, status: 'connecting' })),
        numberConnected: 0
      };
      this.setState(prevState => ({ status: { ...prevState.status, [key]: subscriptionStatus } }));

      observedPropertiesArray.forEach(this.createConnection);

      return key;
    } catch (error) {
      this.setState({ error });
    }
  };

  unsubscribe = keys => {
    try {
      const keysArray = Array.isArray(keys) ? keys : [keys];

      keysArray.forEach(key => {
        const subscription = this.subscriptions.find(x => x.key === key);
        this.subscriptions = this.subscriptions.filter(x => x.key !== key);
        // if there are no more subscriptions to the same topic close the connection
        if (subscription) {
          const { observedProperties } = subscription;
          observedProperties.forEach(op => {
            const subscriptionsSameOP = this.getSubscriptionsToOP(op);
            if (!subscriptionsSameOP || subscriptionsSameOP.length === 0) {
              // Close connection
              this.closeConnection(op);
            }
          });
        }

        // Update status
        this.setState(prevState => {
          const newStatus = prevState.status;
          delete newStatus[key];
          return { status: newStatus };
        });
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  // addClient = async (opId, onMessage) => {
  //   const { brokerUrl, clientId, token, topics } = await this.getConnectionData(opId);
  //   const mqttOptions = {
  //     clientId,
  //     username: clientId,
  //     password: token,
  //   };

  //   const { ops } = this.state;
  //   if (ops[opId].count > 0) {
  //     ops[opId].client.on('message', (topic, message) => {
  //       const parsedMessage = this.parse(message);
  //       this.handleMessage(topic, parsedMessage);
  //       if (onMessage) {
  //         onMessage(opId, parsedMessage);
  //       }
  //     });
  //     return;
  //   }

  //   const client = mqtt.connect(brokerUrl, mqttOptions);
  //   client.on('message', (topic, message) => {
  //     const parsedMessage = this.parse(message);
  //     this.handleMessage(topic, parsedMessage);
  //     if (onMessage) {
  //       onMessage(opId, parsedMessage);
  //     }
  //   });
  //   const topic = topics[0];
  //   client.subscribe(topic, (error) => {
  //     if (error) {
  //       this.setState({ error });
  //     }
  //     this.setState(prevState => ({ topics: { ...prevState.topics, [topic]: opId }, lastValues: { ...prevState.lastValues, [opId]: null }, clients: { ...prevState.clients, [opId]: client } }));
  //   });
  //   // this.clients.push(client);
  //   // console.log(this.clients)
  // }

  // removeClient = (opId) => {
  //   const { clients } = this.state;
  //   if (clients[opId]) {
  //     clients[opId].end();
  //     this.setState(prevState => ({ clients: { ...prevState.clients, [opId]: undefined } }));
  //   }
  // }

  render() {
    const { children } = this.props;
    const childrenArray = Array.isArray(children) ? children : [children];
    const { status, lastValues, error } = this.state;
    const contextValue = {
      status,
      lastValues,
      error,
      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe
    };
    return (
      <RealTimeContext.Provider value={contextValue}>
        {childrenArray.map(x => ({
          ...x,
          props: {
            ...x.props,
            status,
            lastValues,
            error,
            subscribe: this.subscribe,
            unsubscribe: this.unsubscribe,
            reset: this.reset
          }
        }))}
      </RealTimeContext.Provider>
    );
  }
}

export default RealTimeProvider;
