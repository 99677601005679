import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Spinner from './Spinner';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 10%; 
  left: 50%; 
`;

const PageSpinner = props => {
  const { isSpinnerVisible, isDomainLoaded } = props;
  return (
    <> {(isSpinnerVisible || !isDomainLoaded) && 
        <Overlay>
            <SpinnerContainer>
                <Spinner loading={isSpinnerVisible || !isDomainLoaded} />
            </SpinnerContainer>
        </Overlay>}
    </>
    
  );
};

const mapStateToProps = (state) => {
  const { isSpinnerVisible,isDomainLoaded } = state.navigation;
  return { isSpinnerVisible, isDomainLoaded };
}

export default connect(mapStateToProps)(PageSpinner);
