import { t } from '@lingui/macro';
import { widgetTypes } from 'app/pages/dashboards/Dashboard/common';

const allWidgets = [
  { value: widgetTypes.SERIES, label: t`Profilo temporale`, icon: '/assets/icons/charts/profilo-temporale-variabili.svg' },
  { value: widgetTypes.TABLE, label: t`Tabella`, icon: '/assets/icons/charts/display.svg' },
  { value: widgetTypes.PIE, label: t`Grafico di confronto (torta/ciambella/barre)`, icon: '/assets/icons/charts/donut-pie.svg' },
  { value: widgetTypes.DISPLAY, label: t`Display`, icon: '/assets/icons/charts/display.svg' },
  { value: widgetTypes.GAUGE, label: t`Indicatore a lancetta`, icon: '/assets/icons/charts/gauge2.svg' },
  { value: widgetTypes.CIRCLE, label: t`Indicatore circolare`, icon: '/assets/icons/charts/circular.svg' },
  { value: widgetTypes.IMAGE, label: t`Immagine`, icon: '/assets/icons/charts/image1.svg' },
  { value: widgetTypes.TEXT, label: t`Testo`, icon: '/assets/icons/charts/text-box2.svg' },
  { value: widgetTypes.LED, label: t`Led`, icon: '/assets/icons/charts/led2.svg' },
  { value: widgetTypes.SWITCH, label: t`Interruttori`, icon: '/assets/icons/charts/switch3.svg' },
  { value: widgetTypes.SLIDER, label: t`Cursori`, icon: '/assets/icons/charts/slider2.svg' },
];

export const getWidgetTypesOptions = () => {
  return allWidgets;
};

export const getWidgetReportTypesOptions = () => {
  const widgetsToExclude = [
    widgetTypes.SWITCH,
    widgetTypes.SLIDER,
    widgetTypes.GAUGE,
  ];
  return allWidgets.filter((widget) => {
    return widgetsToExclude.indexOf(widget.value) === -1;
  });
};
