import moment from 'moment';
import { t } from '@lingui/macro';
import { isEqual, isNil } from 'lodash';
import i18n from 'app/i18n';
import { getStartDate, getEndDate, adjustDate, getIntervalOptions } from '../common';
import { widgetTypes } from 'app/pages/dashboards/Dashboard/common';
import { formatNumber } from 'app/utils/formatNumber';

/**
 * Get first period \ variable association with API request and aggregation 
 * @param {object} period 
 * @param {object} periods 
 */
export const getAssociations = (variables, periods) => {
  const associations = [];
  variables.map((variable) => {
    const id = variable.id;
    periods.map((period) => {
      const start = period.interval === 'from' ? moment(period.dateFrom).startOf('day').format() : getStartDate(new Date(), period.interval);
      const end = getEndDate(new Date(), period.interval,period.aggregation);

      let aggregation = period.aggregation;
      // correggo le date se necessario
      const dates = adjustDate(start, end, period.interval, aggregation);

      const endpoint = `ObservedProperties/${id}/${aggregation}/timeSeries/${dates[0]}/${dates[1]}`;

      associations.push({
        variable: variable,
        period: period,
        aggregation: aggregation,
        interval: period.interval,
        request: endpoint,
        response: null, // populated when all requests will be processed correctly
      });
      return null;
    });
    return null;
  });
  return associations;
}

/**
 * Get period label
 * @param {Object} period 
 * @param {string} currentModule 
 */
export const getPeriodLabel = (period, currentModule) => {
  const options = getIntervalOptions(widgetTypes.TABLE, currentModule);
  const items = options.items;
  const obj = items.find(item => item.value === period.interval);
  if (!obj) {
    return ''; // Error: no label were found!
  }
  if (period.interval === 'from') {
    return obj.label.replace('...', ' ' + moment(period.dateFrom).format('DD/MM/YYYY'));
  }
  return obj.label;
}

/**
 * Get widget period string (table subtitle)
 * @param {Array} period 
 * @param {string} widgetType 
 * @param {string} currentModule 
 * @returns {string}
 */
export const getWidgetPeriod = (periods, widgetType, currentModule) => {
  if (periods.length === 1 && periods[0].periodValue !== 'period') {
    return getPeriodLabel(periods[0], widgetType, currentModule);
  }
  return null;
}

/**
 * Given an HTML color string, returns true is the color is light, false if dark
 * @param {string} color 
 * @returns {boolean}
 */
export const isLightColor = (color) => {
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    ));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // return (hsp > 127.5);
  return (hsp > 150);
}

export const sum = list => list.reduce((prev, curr) => prev + curr, 0);
export const average = list => sum(list) / list.length;

export const formatCellValue = (cellValue) => {
  // round value
  const cellValueFormatted = cellValue;
  //const cellValueFormatted = parseFloat(cellValue);
  return parseFloat(cellValueFormatted);
}

export const getRowSum = (singleRow) => {
  let rowSum = 0;
  if (Array.isArray(singleRow) && singleRow.length > 0) {
    for (let i = 0; i < singleRow.length; i++) {
      const rowParsed = parseFloat(singleRow[i]);
      if (!isNaN(rowParsed)) {
        rowSum = rowSum + rowParsed;
      }
    }
  }
  return rowSum;
}

export const getRowAverage = (singleRow) => {
  let row = [];
  if (Array.isArray(singleRow) && singleRow.length > 0) {
    for (let i = 0; i < singleRow.length; i++) {
      const rowValue = parseFloat(singleRow[i]);
      if (!isNaN(rowValue)) {
        row.push(rowValue);
      }
    }
    return average(row);
  }
  return 0;
}

export const addRowStats = (propStats, dataRows, otherPropStats) => {
  const isValidDataRows = Array.isArray(dataRows) && dataRows.length > 0;
  let rowStats = null;
  if (propStats === 'total' && isValidDataRows) {
    rowStats = calculateColumnSum(dataRows);
  } else if (propStats === 'medium' && isValidDataRows) {
    rowStats = calculateColumnAverage(dataRows);
  }
  // Se ci sono 2 statistiche diverse (totale e media) mostra un trattino
  if (Array.isArray(rowStats) && rowStats.length > 0 && propStats !== 'notshow' && otherPropStats !== 'notshow' && otherPropStats !== 'show' && propStats !== otherPropStats) {
    rowStats[rowStats.length - 1] = '-';
  }
  return rowStats;
}

export const calculateColumnSum = (allRows) => {
  // Get a new row sum statistics
  let rowStats = [];
  for (let i = 0; i < allRows.length; i++) {
    for (let j = 0; j < allRows[i].length; j++) {
      rowStats[j] = [...(rowStats[j] || []), allRows[i][j]];
    }
  }
  return (rowStats.length > 0) ?
    rowStats.map(r => r.filter(row => row !== '-').length > 0 ? r.filter(row => row !== '-').reduce((acc, currVal) => acc + currVal) : '-') : null;
}

export const calculateColumnAverage = (allRows) => {
  // Gather all numbers in an array
  let rowsMedium = [];
  for (let i = 0; i < allRows.length; i++) {
    for (let j = 0; j < allRows[i].length; j++) {
      if (!rowsMedium[j]) {
        rowsMedium[j] = [];
      }
      rowsMedium[j].push(allRows[i][j] !== '-' ? parseFloat(allRows[i][j]) : '-');
    }
  }

  // Calculate average
  if (rowsMedium.length > 0) {
    let rowStats = [];
    rowsMedium.map(column => {
      const columnAverage = column.every(val => val === '-') ? '-' : parseFloat(average(column.filter(val => val !== '-')), null);
      rowStats.push(columnAverage);
      return null;
    });
    return rowStats;
  }

  return null;
}

export const getTimeSerieLabels = (timeSeries, label, dateFormat) => {
  if (!dateFormat) {
    dateFormat = 'DD/MM/YYYY';
  }
  return timeSeries.map(timeSerie => {
    const timeDate = moment(timeSerie.t);
    if (label) {
      return label + ' ' + timeDate.format(dateFormat);
    }
    return timeDate.format(dateFormat);
  });
}

export const getMonthlyTimeSerieLabels = (timeSeries) => {
  return timeSeries.map(timeSerie => {
    const timeDate = moment(timeSerie.t);
    const monthName = timeDate.format('MMMM');
    const monthNameUcFirst = monthName.charAt(0).toUpperCase() + monthName.slice(1);
    return monthNameUcFirst + ' ' + timeDate.format('YYYY');
  });
}

export const getVariablesLabels = (variables) => {
  if (Array.isArray(variables)) {
    const labels = variables.map(variable => {
      const unitMeasure = (variable._unit) ? variable._unit : variable.unit;
      return variable.label + ' [' + unitMeasure + ']';
    });
    return labels;
  }
  return [];
}

export const getPeriods = (rows) => {
  const mainArray = [...rows];

  const periodsRows = [];
  for (let i = 0; i < mainArray.length; i++) {
    const row = {
      interval: mainArray[i].interval,
      aggregation: mainArray[i].aggregation,
      periodValue: mainArray[i].periodValue
    };
    if (mainArray[i].interval === 'from') {
      row.dateFrom = mainArray[i].period;
    }
    periodsRows.push(row);
  }
  return periodsRows;
}

export const getPeriodsLabels = (periods, currentModule, timeSerieSample) => {
  if (!Array.isArray(periods)) {
    return [];
  }

  if (periods.length === 1) {
    if (periods[0].periodValue && periods[0].periodValue === 'period') {
      return [];
    }
    // Single periods
    return getSinglePeriodLabels(periods[0], timeSerieSample, currentModule);
  }

  // Multi periods
  return getMultiPeriodsLabels(periods, periods[0].aggregation, currentModule);
}

export const getMultiPeriodsLabels = (periods, currentModule) => {
  const columns = periods.map((period) => getPeriodLabel(period, currentModule));
  return columns;
}

export const getSinglePeriodLabels = (period, timeSerieSample, currentModule) => {
  let periodLabels = [];
  const isValidTimeSerieSample = timeSerieSample && Array.isArray(timeSerieSample) && timeSerieSample.length > 0;
  switch (period.aggregation) {
    case ('raw'):
    case ('lastvalue'):
    case ('1m'):
    case ('5m'):
    case ('10m'):
    case ('15m'):
    case ('30m'):
    case ('1h'):
    case ('3h'):
    case ('12h'):
    case ('24h'):
      if (isValidTimeSerieSample) {
        periodLabels = timeSerieSample.map(timeSerie => {
          const timeDate = moment(timeSerie.t).format('D/MM/YYYY HH:mm');
          return timeDate;
        });
      }
      break;

    case ('1wk'):
      if (isValidTimeSerieSample) {
        periodLabels = timeSerieSample.map(timeSerie => {
          let timeDate = moment(timeSerie.t);
          const txtStart = timeDate.format('D/MM/YYYY');

          timeDate.add(7, 'days');
          const txtEnd = timeDate.format('D/MM/YYYY');

          return txtStart + " - " + txtEnd;
        });
      }
      break;

    case ('1dy'):
    case ('7d'):
    case ('30d'):
      if (isValidTimeSerieSample) {
        periodLabels = timeSerieSample.map(timeSerie => {
          const timeDate = moment(timeSerie.t);
          const dayOfWeek = timeDate.format('ddd');
          const dayOfWeekUcFirst = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
          return dayOfWeekUcFirst + ' ' + timeDate.format('D/MM/YYYY');
        });
      }
      break;

    case ('1mo'):
      if (isValidTimeSerieSample) {
        periodLabels = getMonthlyTimeSerieLabels(timeSerieSample);
      }
      break;

    case ('1yr'):
      if (isValidTimeSerieSample) {
        periodLabels = getMonthlyTimeSerieLabels(timeSerieSample);
      }
      break;

    default:
      break;
  }

  if (!periodLabels.length || periodLabels.length === 0) {
    const currentPeriodLabel = getPeriodLabel(period, currentModule);
    periodLabels = [currentPeriodLabel];
  }

  return periodLabels;
}

export const getStatisticsLabel = (statistics, unitMeasure) => {
  if (statistics === 'medium') {
    return i18n._(t`Media`) + ((unitMeasure) ? ' [' + unitMeasure + ']' : '');
  } else if (statistics === 'total') {
    return i18n._(t`Totale`) + ((unitMeasure) ? ' [' + unitMeasure + ']' : '');
  } else if (statistics === 'show') {
    return i18n._(t`Intero periodo`);
  }
  return undefined;
}

export const getMatchingAssociation = (associations, variable, period) => {
  for (let i = 0; i < associations.length; i++) {
    const association = associations[i];
    if (isEqual(association.variable, variable) && isEqual(association.period, period)) {
      return association;
    }
  }
  return undefined;
}

export const getTimeSeriresMaxLength = (associations, variables, period) => {
  let timeSeriesMaxLength = 0;
  variables.map((variable) => {
    const association = getMatchingAssociation(associations, variable, period);
    const timeSeries = (association && association.response) ? association.response.timeSeries : [];

    if (timeSeries.length > timeSeriesMaxLength) {
      timeSeriesMaxLength = timeSeries.length;
    }
    return null;
  });

  return timeSeriesMaxLength;
}

export const getSinglePeriodColrowData = (associations, variables, period, timeSeriesMaxLength, propStats) => {
  const allRows = [];

  const timestamps = [];
  const timeSeriesList = [];
  variables.map((variable) => {
    const association = getMatchingAssociation(associations, variable, period);
    const timeSeries = (association && association.response) ? association.response.timeSeries : [];
    timestamps.push(...timeSeries.map(ts => ts.t));
    timeSeriesList.push(timeSeries);
    return null;
  });

  timestamps.sort((a, b) => a > b ? 1 : -1).filter((ts, i) => timestamps.findIndex(t => t === ts) === i).forEach(ts => {
    const singleRow = [];
    timeSeriesList.map((timeSeries, i) => {
      timeSeries.forEach(timeSerie => {
        if (timeSerie.t === ts) {
          singleRow.push(timeSerie.v);
        }
      });
      if (isNil(singleRow[i])) {
        singleRow.push('-');
      }
      return null;
    });
    // Variables statistics last row cell value
    const stats = getStatistics(singleRow, propStats);
    if (!isNaN(stats) && stats !== null) {
      singleRow.push(stats);
    }

    allRows.push(singleRow);
  });

  return allRows;
}

export const getDataRows = (associations, variables, periods, properties) => {
  let dataRows = [];
  if (periods.length === 1) {
    // Single period data
    if (properties.disposition === 'colrow') { // Periodi su righe, Variabili su 
      if (periods.length !== 1 || periods[0].periodValue !== 'period') {
        const timeSeriesMaxLength = getTimeSeriresMaxLength(associations, variables, periods[0]);
        dataRows = getSinglePeriodColrowData(
          associations,
          variables,
          periods[0],
          timeSeriesMaxLength,
          properties.varStats
        );
      }

      // const rowStats = addRowStats(properties.varPeriod, dataRows, properties.varStats);
      // if (rowStats) {
      //   dataRows.push(rowStats);
      // }
      if (periods.length === 1 && periods[0].periodValue !== 'values') {
        const stats = associations.map(a => a.cellData);
        if (properties.varStats === 'total' && (variables.every(v => v.statistic === 'sum') || periods[0].periodValue === 'period')) {
          const total = stats.reduce((acc, curr) => acc + curr);
          stats.push(total);
        } else if (properties.varStats === 'medium' && (variables.every(v => v.statistic === 'average') || periods[0].periodValue === 'period')) {
          const average = stats.reduce((acc, curr) => acc + curr) / stats.length;
          stats.push(average);
        }
        else stats.push(NaN)
        dataRows.push(stats);
      }

    } else if (properties.disposition === 'rowcol') { // Variabili su righe, Periodi su colonne
      // Per tutte le timeSerie, preleva i singoli valori
      const onlyFullTimeSeries = associations.filter(assoc => (Array.isArray(assoc.response.timeSeries) && assoc.response.timeSeries.length > 0));
      const allTimeSeries = associations.map(assoc => assoc.response.timeSeries);
      const timeSerieSample = getTimeSerieSample(allTimeSeries);

      /**
       * Controlla se TUTTE le timeserie sono vuote, salta il processo (niente dati)
       * Con almeno una timeSerie piena, stampa serie di zeri per quelle vuote e i dati per quelle piene
       */
      if (Array.isArray(allTimeSeries) && Array.isArray(onlyFullTimeSeries) && onlyFullTimeSeries.length > 0) {
        allTimeSeries.map((timeSerie, i) => {
          let singleRow = timeSerie.map(tm => formatCellValue(tm.v));

          // For each timeSerieeSample, add zeros in case of empty timeSerie
          const isCurrentTimeSerieUnValid = (!timeSerie || !Array.isArray(timeSerie) || (Array.isArray(timeSerie) && timeSerie.length === 0));
          if (isCurrentTimeSerieUnValid && (Array.isArray(timeSerieSample) && timeSerieSample.length > 0)) {
            singleRow = timeSerieSample.map(() => 0);
          }

          // Add statistic value
          // const stats = getStatistics(singleRow, properties.varPeriod);
          const stats = associations[i].cellData;
          if (!isNaN(stats) && stats !== null) {
            if (associations[i].period.periodValue === 'values-period') {
              singleRow.push(stats);
            } else if (associations[i].period.periodValue === 'period') {
              singleRow = [stats];
            }
          }

          dataRows.push(singleRow);
          return null;
        });
      }

      //  Add rows statistics
      const rowStats = addRowStats(properties.varStats, dataRows, properties.varPeriod);
      if (rowStats) {
        dataRows.push(rowStats);
      }
    }

  } else {
    // Multi period data
    dataRows = getMultiPeriodDataRows(associations, variables, periods, properties);
  }
  const maxDigits = Math.max(...dataRows[0].map( (val,i) => variables[i] ? variables[i].decimalDigits : -1))
  const numberFormatDataRows = dataRows.map( row => row.map((val,i)=> {
    const variable = variables[i];
    const type = (variable && variable.valueType) || 'none';
    const decimalDigits = variable ? variable.decimalDigits : maxDigits;
    return formatNumber(val,0,1,type, decimalDigits);
  }))

  return numberFormatDataRows;
}


export const getStatistics = (singleRow, varStats) => {
  if (varStats === 'total') {
    return getRowSum(singleRow);
  } else if (varStats === 'medium') {
    return getRowAverage(singleRow);
  }
  return null;
}

export const getMultiPeriodDataRows = (associations, variables, periods, properties) => {
  const allRows = [];

  // Periodi su righe, Variabili su colonne
  if (properties.disposition === 'colrow') {
    periods.map(period => {
      const singleRow = variables.map(variable => {
        const assoc = getMatchingAssociation(associations, variable, period);
        return assoc.cellData;
      });

      // Column statistics cell value
      const stats = getStatistics(singleRow, properties.varStats);
      if (!isNaN(stats) && stats !== null) {
        singleRow.push(stats);
      }

      // Push single row data
      allRows.push(singleRow);
      return null;
    });

    // Row statistics
    const rowStats = addRowStats(properties.varPeriod, allRows, properties.varStats);
    if (rowStats) {
      allRows.push(rowStats);
    }

  }

  // Varibili su righe, periodi su colonne
  if (properties.disposition === 'rowcol') {
    variables.map(variable => {
      const singleRow = periods.map(period => {
        const assoc = getMatchingAssociation(associations, variable, period);
        return assoc.cellData;
      });

      // Column statistics cell value
      const stats = getStatistics(singleRow, properties.varPeriod);
      if (!isNaN(stats) && stats !== null) {
        singleRow.push(stats);
      }

      // Push single row data
      allRows.push(singleRow);
      return null;
    });

    // Row statistics
    const rowStats = addRowStats(properties.varStats, allRows, properties.varPeriod);
    if (rowStats) {
      allRows.push(rowStats);
    }
  }

  return allRows;
}

export const getTimeSerieSample = (allTimeSeries) => {
  if (!Array.isArray(allTimeSeries)) {
    return null;
  }
  // Get not empty timeSerie
  let timeSeriesLength = 0;
  let timeSeriesIndex;
  for (let i = 0; i < allTimeSeries.length; i++) {
    if (allTimeSeries[i].length > timeSeriesLength) {
      timeSeriesLength = allTimeSeries[i].length;
      timeSeriesIndex = i;
    }
  }
  return timeSeriesLength > 0 ? allTimeSeries[timeSeriesIndex] : null;
}

export const hasColumnStats = (properties) => {
  if (properties.disposition === 'colrow') {
    return (properties.varStats !== 'notshow' && properties.varStats !== null);
  } else if (properties.disposition === 'rowcol') {
    return (properties.varPeriod !== 'notshow' && properties.varPeriod !== null);
  }
  return undefined;
}

export const hasRowStats = (properties) => {
  if (properties.disposition === 'colrow') { // Periodi su righe, Variabili su colonne
    return (properties.varPeriod !== 'notshow' && properties.varPeriod !== null);
  } else if (properties.disposition === 'rowcol') {
    return (properties.varStats !== 'notshow' && properties.varStats !== null);
  }
  return undefined;
}
