import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Switch, Select, Spinner } from 'app/common';
import api from 'api';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import TreeView from './TreeView';
import { Badge } from 'reactstrap';

const Container = styled.div`
  background-color: #fff;
  min-height: 25rem;
  max-height: 70vh;
  position: relative;
  overflow-y: scroll;

  ${props =>
    props.scroll &&
    `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: .4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
      background: ${props.theme.navbar.borderColor}; 
    }
  `}
`;

const FilterContainer = styled.div`
  background-color: ${props => props.theme.common.background.secondaryColor};
  padding: 1rem;
  margin-right:1rem;
  border-radius: 5px;
  width: 20rem;

  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Field = styled.div`
  margin: 1rem 0;
`;

const EmptyLabel = styled.p`
  padding: 0.2rem;
  margin-left: 1.5rem;
  color: ${props => props.theme.common.text.secondaryColor};
`;

const options = {
  keyProperty: 'name'
};

const StyledBadge = styled(Badge)`
  && {
    margin-left: .5rem;
    color: ${props => props.color || props.theme.common.buttons.primary.color};
    background-color: ${props => props.bgcolor || props.theme.common.buttons.primary.background};
    border-radius: 15px;
    min-width: 1.3rem;
  }
`;

/*
  Il componente si popola automaticamente in base al contesto (azienda selezionata, sito selezionato, ecc) e supporta le seguenti props opzionali:
  - maxChecked: il numero massimo di nod i foglia selezionabili
  - onMaxChecked: funzione chiamata quando si prova a selezionare un numero di nodi superiore a quello indicato in maxChecked
  - checkedNodes: array dei nodi foglia selezionati. Ognuno è un oggetto del tipo {type, id} dove type attualmente è "op" o "vm" e id è l'id dell'observed property
  - onCheck: funzione chiamata alla selezione/deselezione del nodo (nota: se è stato impostato maxChecked e i nodi selezionati sono >= di maxChecked, questa funzione non viene chiamata)
  - openNodes: array dei nodi padre aperti. Ognuno è del tipo {type, id}, dove type può essere "company", "site" o "asset"
  - onOpen: funzione chiamata all'apertura/chiusura di un nodo padre.

  Le proprietà openNodes e checkedNodes sono i valori iniziali e vengono considerati solo durante la costruzione del componente. Il valore corrente dei nodi aperti e selezionati è contenuto nello stato del componente.

  La proprietà opClass predefinisce la classe iniziale di Op nella select. La proprietà onlyOpClasses permette di filtrare sul campo value la lista di opClasses.

  Esempio d'uso (nota: tutte le props sono opzionali):

  <OPSelector
    maxChecked={4}
    onMaxChecked={() => console.log('MAX!')}
    checkedNodes={[{ type:'op', id: 3 }]}
    onCheck={(node, checked) => console.log('onCheck', node, checked)}
    openNodes={[{ type:'company', id: 3 }]}
    onOpen={(node, open) => console.log('onOpen', node, open)}
    opClass='field'
    onlyOpClasses={['field']}
  />

*/

const OpSelector = props => {
  const {
    observedProperties,
    selectedAsset,
    selectedAssetgroup,
    selectedSite,
    selectedSitegroup,
    selectedCompany,
    selectedDomain,
    companies,
    sites,
    sitegroups,
    assets,
    filters,
    physicalQuantities,
    customPhysicalQuantities,
    scroll,
    className,
    root,
    globalLoading,
    updateOrphaned
  } = props;
  const opClasses = [
    {
      label: i18n._(t`Variabili da tutte le sorgenti`),
      value: ''
    },
    {
      label: i18n._(t`Da dispositivi di campo`),
      value: 'field'
    },
    {
      label: i18n._(t`Da misuratori virtuali`),
      value: 'vm'
    },
    {
      label: i18n._(t`Da caricamento manuale`),
      value: 'user'
    },
    {
      label: i18n._(t`Da ottimizzatori e predittori`),
      value: 'automaton'
    },
    {
      label: i18n._(t`Da importatori automatici`),
      value: 'import-system'
    },
    {
      label: i18n._(t`Da allarmi sulle variabili`),
      value: 'alias'
    }
  ];

  const [filterText, setFilterText] = useState('');
  const [physicalQuantity, setPhysicalQuantity] = useState('');
  const [opClass, setOpClass] = useState(props.opClass || '');
  const [physicalQuantityDisabled, setPhysicalQuantityDisabled] = useState(false);
  const [showAssets, setShowAssets] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedOPs, setDeletedOPs] = useState(undefined);

  const [checkedNodes, setCheckedNodes] = useState(props.checkedNodes || []);
  const [openNodes, setOpenNodes] = useState(props.openNodes || []);

  useEffect(() => {
    setPhysicalQuantity(props.physicalQuantity ? props.physicalQuantity : '');
    setPhysicalQuantityDisabled(props.physicalQuantity !== undefined);
  }, [props.physicalQuantity]);

  useEffect(() => {
    setCheckedNodes(props.checkedNodes || []);
  }, [props.checkedNodes]);

  const getOPIds = asset => {
    const { assetGraph } = sites.find(x => x.id === asset.siteId);
    const { assetFlows } = assetGraph;
    const { measures, inFlowIds, outFlowIds } = asset;
    const measuresIds = (measures || []).map(x => x.observedPropertyId);
    const inFlows = (inFlowIds || []).map(id => assetFlows.find(y => y.id === id));
    const destinationMeasuresIds = inFlows
      .map(flow => flow.destinationMeasures.map(x => x.observedPropertyId))
      .flat();
    const outFlows = (outFlowIds || []).map(id => assetFlows.find(y => y.id === id));
    const sourceMeasuresIds = outFlows
      .map(flow => flow.sourceMeasures.map(x => x.observedPropertyId))
      .flat();
    return measuresIds.concat(destinationMeasuresIds).concat(sourceMeasuresIds);
  };

  const getNodeKey = (type, item) => {
    switch (type) {
      case 'asset': {
        const site = sites.find(x => x.id === item.siteId);
        return `domain_${selectedDomain.id}/company_${site.companyId}/site_${site.id}/asset_${item.id}`;
      }
      case 'assetgroup': {
        const site = sites.find(x => x.id === item.siteId);
        return `domain_${selectedDomain.id}/company_${site.companyId}/site_${site.id}`; //assetgroup_${item.id}`;
      }
      case 'site': {
        const site = sites.find(x => x.id === item.id);
        return `domain_${selectedDomain.id}/company_${site.companyId}/site_${item.id}`;
      }
      case 'sitegroup': {
        return `domain_${selectedDomain.id}/company_${item.companyId}`;
      }
      case 'company':
        return `domain_${selectedDomain.id}/company_${item.id}`;
      case 'domain':
        return `domain_${selectedDomain.id}`;
      default:
    }
  };

  const { groupedLeaves, nLeaves, totalGroupedLeaves } = useMemo(() => {
    const lowerCaseFilterText = filterText.toLowerCase();
    let leaves = showDeleted && deletedOPs ? deletedOPs : observedProperties;
    const filterForClass = leaf => {
      let leafGotClass = true;
      switch (opClass) {
        case '':
          break;
        case 'field':
          leafGotClass = !isNil(leaf.fieldDeviceId);
          break;
        case 'vm':
          leafGotClass = !isNil(leaf.virtualMeterId);
          break;
        case 'user':
          leafGotClass = leaf.otherDataSource && leaf.otherDataSource.class === 'user';
          break;
        case 'import-system':
          leafGotClass = leaf.otherDataSource && leaf.otherDataSource.class === 'import-system';
          break;
        case 'automaton':
          leafGotClass = leaf.otherDataSource && leaf.otherDataSource.class === 'automaton';
          break;
        case 'alias':
          leafGotClass = leaf.otherDataSource && leaf.otherDataSource.class === 'alias';
          break;
        default:
          break;
      }
      return leafGotClass;
    };
    const getOpType = leaf => {
      if (leaf.virtualMeterId) {
        return 'vm';
      }
      if (leaf.fieldDeviceId) {
        return 'field';
      }
      if (leaf.otherDataSource && leaf.otherDataSource.class) {
        return leaf.otherDataSource.class;
      }
      return 'op';
    };
    if (filters) {
      Object.entries(filters)
        .filter(x => x[1])
        .forEach(
          ([prop, value]) =>
          (leaves = leaves.filter(x =>
            prop === 'function' ? value(x) === true : x[prop] === value
          ))
        );
    }
    const groupedLeaves = {};
    const totalGroupedLeaves = {};
    let nLeaves = 0;

    leaves.forEach(leaf => {
      if (
        !leaf.hidden &&
        (!physicalQuantity ||
          physicalQuantity === leaf.physicalQuantity ||
          (leaf.physicalQuantity === 'custom' && leaf.customQuantityId === physicalQuantity)) &&
        leaf.name.toLowerCase().includes(lowerCaseFilterText) &&
        filterForClass(leaf)
      ) {
        let key;
        if (leaf.siteId) {
          const site = sites.find(x => x.id === leaf.siteId);
          key = `domain_${selectedDomain.id}/company_${site.companyId}/site_${leaf.siteId}`;
        } else if (leaf.siteGroupId) {
          const siteGroup = sitegroups.find(x => x.id === leaf.siteGroupId);
          if (selectedAssetgroup) {
            key = `domain_${selectedDomain.id}/company_${siteGroup.companyId}/sitegroup_${leaf.siteGroupId}`;
          } else {
            key = `domain_${selectedDomain.id}/company_${siteGroup.companyId}`;
          }
        } else if (leaf.companyId) {
          key = `domain_${selectedDomain.id}/company_${leaf.companyId}`;
        } else if (leaf.domainId) {
          key = `domain_${leaf.domainId}`;
        }
        if (!groupedLeaves[key]) {
          groupedLeaves[key] = [];
        }
        groupedLeaves[key].push({
          id: leaf.id,
          item: leaf,
          type: leaf.virtualMeterId !== null ? 'vm' : 'op',
          subtype: getOpType(leaf)
        });
      }

      let key;
      if (
        !leaf.hidden){
        if (leaf.siteId) {
          const site = sites.find(x => x.id === leaf.siteId);
          key = `domain_${selectedDomain.id}/company_${site.companyId}/site_${leaf.siteId}`;
        } else if (leaf.siteGroupId) {
          const siteGroup = sitegroups.find(x => x.id === leaf.siteGroupId);
          if (selectedAssetgroup) {
            key = `domain_${selectedDomain.id}/company_${siteGroup.companyId}/sitegroup_${leaf.siteGroupId}`;
          } else {
            key = `domain_${selectedDomain.id}/company_${siteGroup.companyId}`;
          }
        } else if (leaf.companyId) {
          key = `domain_${selectedDomain.id}/company_${leaf.companyId}`;
        } else if (leaf.domainId) {
          key = `domain_${leaf.domainId}`;
        }
        if (!totalGroupedLeaves[key]) {
          totalGroupedLeaves[key] = [];
        }
        totalGroupedLeaves[key].push({
          id: leaf.id,
          item: leaf,
          type: leaf.virtualMeterId !== null ? 'vm' : 'op',
          subtype: getOpType(leaf)
        });
        nLeaves += 1;
      }
    });

    return { groupedLeaves, nLeaves, totalGroupedLeaves };
  }, [observedProperties, filterText, physicalQuantity, filters, showDeleted, deletedOPs, opClass]);

  const toggle = (node, checked) => {
    const { maxChecked, onMaxChecked, onCheck, isLoadingData } = props;
    const { type, id, item } = node;

    if (maxChecked === 1) {
      setCheckedNodes([{ type, id }]);
      if (onCheck) {
        onCheck({ type, id, item }, isLoadingData ? checked : true);
      }
      return true;
    }

    if (checked) {
      if (maxChecked && checkedNodes.length >= maxChecked) {
        if (onMaxChecked) {
          onMaxChecked();
        }
        return null;
      }
      if (!checkedNodes.some(x => x.type === type && x.id === id)) {
        setCheckedNodes(checkedNodes.concat({ type, id }));
      }
    } else {
      setCheckedNodes(checkedNodes.filter(x => x.type !== type || x.id !== id));
    }

    if (onCheck) {
      onCheck({ type, id, item }, checked);
    }
    return checked;
  };

  const openNode = (node, open) => {
    if (!node) return;
    const { type, id, item } = node;
    // if (open) {
    //   if (!openNodes.some(x => x.type === type && x.id === id)) {
    //     setOpenNodes(prevState => prevState.concat({ type, id }));
    //   }
    // } else {
    //   setOpenNodes(prevState => prevState.filter(x => x.type !== type || x.id !== id));
    // }
    setOpenNodes(prevState =>
      prevState.filter(x => x.type !== type || x.id !== id).concat({ type, id, open })
    );

    const { onOpen } = props;
    if (onOpen) {
      onOpen({ type, id, item }, open);
    }
  };

  const createNode = (item, type) => {
    const { id, name } = item;
    const node = { id, name, type, item, showLeafChildren: true };

    let children;
    let childrenType;
    switch (type) {
      case 'domain':
        children = companies.filter(x => x.domainId === id);
        childrenType = 'company';
        break;
      case 'company':
        children = sites.filter(x => x.companyId === id);
        childrenType = 'site';
        break;
      case 'sitegroup':
        node.companyId = item.companyId;
        children = sites.filter(x => x.siteGroups && x.siteGroups.map(y => y.id).includes(id));
        childrenType = 'site';
        break;
      case 'site':
        if (showAssets) {
          children = assets.filter(x => x.siteId === id);
          childrenType = 'asset';
        }
        break;
      case 'assetgroup':
        node.siteId = item.siteId;
        children = assets.filter(x => x.siteId === item.siteId && item.assetIds.includes(x.id));
        childrenType = 'asset';
        break;
      case 'asset':
        node.siteId = item.siteId;
        node.opIds = getOPIds(item);
        break;
      default:
    }

    node.key = getNodeKey(type, item);

    if (children) {
      node.children = children.map(x => createNode(x, childrenType, node.key));

      // if show assets create a fake asset containing all the variables not assigned to any asset
      if (type === 'site' && showAssets) {
        node.showLeafChildren = false;
        const assignedIds = node.children.map(x => x.opIds).flat();
        const allSiteLeaves = (groupedLeaves[node.key] || []).map(x => x.id);
        const notAssignedIds = allSiteLeaves.filter(x => !assignedIds.includes(x));
        if (notAssignedIds.length > 0) {
          const notAssignedAsset = {
            key: `${node.key}/asset_unassigned_${id}`,
            type: 'asset',
            id: `unassigned_${id}`,
            name: i18n._(t`Non associati ad alcun asset`),
            siteId: id,
            opIds: notAssignedIds,
            item: null,
            showLeafChildren: true
          };
          node.children.push(notAssignedAsset);
        }
      }
    }

    return node;
  };

  const structure = useMemo(() => {
    let rootNode;
    if (root) {
      const { type, item } = root;
      rootNode = createNode(item, type);
    } else if (selectedAsset) {
      rootNode = createNode(selectedAsset, 'asset');
    } else if (selectedAssetgroup) {
      rootNode = createNode(selectedAssetgroup, 'assetgroup');
    } else if (selectedSite) {
      rootNode = createNode(selectedSite, 'site');
    } else if (selectedSitegroup) {
      rootNode = createNode(selectedSitegroup, 'sitegroup');
    } else if (selectedCompany) {
      rootNode = createNode(selectedCompany, 'company');
    } else if (selectedDomain) {
      rootNode = createNode(selectedDomain, 'domain');
    }
    openNode(rootNode, true);
    if (rootNode && (rootNode.type === 'asset' || rootNode.type === 'assetgroup')) {
      setShowAssets(true);
    }
    return rootNode;
  }, [
    root,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedAsset,
    companies,
    sites,
    assets,
    showAssets
  ]);

  useEffect(() => {
    const loadDeletedOps = async root => {
      const { type, id, siteId, companyId } = root;
      let reqType;
      let reqId;
      switch (type) {
        case 'domain':
          reqType = 'domains';
          reqId = id;
          break;
        case 'company':
          reqType = 'companies';
          reqId = id;
          break;
        case 'site':
          reqType = 'sites';
          reqId = id;
          break;
        case 'sitegroup':
          reqType = 'companies';
          reqId = companyId;
          break;
        case 'assetgroup':
        case 'asset':
          reqType = 'sites';
          reqId = siteId;
          break;
        default:
      }
      const res = await api.get(`/${reqType}/${reqId}/orphanedObservedProperties`);
      updateOrphaned && updateOrphaned(res.data);
      setDeletedOPs(res.data);
    };

    if (showDeleted && !deletedOPs) {
      loadDeletedOps(structure);
    }
  }, [showDeleted]);

  //removedVariablesSelected = total selected nodes - selected nodes found in observedProperties
  const removedVariablesSelected = observedProperties && checkedNodes ? checkedNodes.length - observedProperties.filter(op => checkedNodes.map(variable => variable.id).includes(op.id)).length : 0;

  return structure ? (
    <>
      {globalLoading && <Spinner overlay={false} />}
      <Container scroll={scroll} className={className}>
        {nLeaves > 0 ? (
          <TreeView
            options={options}
            structure={structure}
            leaves={groupedLeaves}
            totalLeaves={totalGroupedLeaves}
            showAssets={showAssets}
            nLeaves={nLeaves}
            toggle={toggle}
            openNodes={openNodes}
            open={openNode}
            checkedNodes={checkedNodes}
            selectedAsset={selectedAsset}
            filterText={filterText}
          />
        ) : (
          <EmptyLabel>
            <Trans>Nessuna variabile trovata</Trans>
          </EmptyLabel>
        )}
        <FilterContainer>
          <h6>
            <Trans>Filtri</Trans>
          </h6>
          <Field>
            <Input
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
              placeholder={i18n._(t`Cerca per nome`)}
            />
          </Field>
          <Field>
            <Select
              nullable
              nullableLabel={i18n._(t`Tutte le grandezze fisiche`)}
              canSearch
              disabled={physicalQuantityDisabled}
              placeholder={i18n._(t`Grandezza`)}
              value={physicalQuantity}
              onChange={e => setPhysicalQuantity(e.target.value)}
              options={physicalQuantities.concat(customPhysicalQuantities)}
              valueProperty="naturalKey"
              labelProperty="_label"
            />
          </Field>
          <Field>
            <Select
              canSearch
              placeholder={i18n._(t`Cerca per class`)}
              value={opClass}
              onChange={e => setOpClass(e.target.value)}
              options={props.onlyOpClasses ? opClasses.filter( e => props.onlyOpClasses.includes(e.value)) : opClasses}
            />
          </Field>
          <Field>
            <Switch
              name="showAssets"
              checked={showAssets}
              disabled={structure.type === 'asset' || structure.type === 'assetgroup'}
              onChange={e => setShowAssets(e.target.checked)}
              label={<Trans>Mostra gli asset</Trans>}
            />
          </Field>
          <Field>
            <Switch
              name="showDeleted"
              checked={showDeleted}
              onChange={e => setShowDeleted(e.target.checked)}
              label={<><Trans>Mostra le variabili eliminate</Trans>{removedVariablesSelected ? <StyledBadge>{removedVariablesSelected}</StyledBadge> : ""}</> }
            />
          </Field>
        </FilterContainer>
      </Container>
    </>
  ) : null;
};

OpSelector.defaultProps = {
  fixedPhysicalQuantity: undefined
};

OpSelector.propTypes = {
  fixedPhysicalQuantity: PropTypes.string
};

const mapStateToProps = state => {
  const {
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup
  } = state.navigation;
  const {
    observableproperties: observedProperties,
    physicaldimensions,
    globalLoading
  } = state.domain;
  const { physicalQuantities } = state.catalogs;
  const customPhysicalQuantities = (physicaldimensions || []).map(x => ({
    ...x,
    naturalKey: x.id,
    _label: x.name
  }));
  return {
    companies,
    sites,
    sitegroups,
    assets,
    assetgroups,
    selectedDomain,
    selectedCompany,
    selectedSite,
    selectedSitegroup,
    selectedAsset,
    selectedAssetgroup,
    observedProperties,
    physicalQuantities,
    customPhysicalQuantities,
    globalLoading
  };
};

export default connect(mapStateToProps)(OpSelector);
