import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import EventPreferences from './EventPreferences';
import TagPreferences from './TagPreferences';

const StyledCard = styled(Card)`
  margin: 1rem 0rem;
  h4 {
    display: 'flex';
    font-weight: 500;
    padding: 0.8rem 1.5rem;
  }
`;

const NotificationPreferences = props => {
  return (
    <StyledCard>
      <CardHeader>
        <Trans>Configurazione notifiche</Trans>
      </CardHeader>
      <CardBody>
        <EventPreferences {...props} />
      </CardBody>
      <h4>
        <Trans>Configurazione per tag</Trans>
      </h4>
      <CardBody>
        <Trans>
          In aggiunta alle configurazioni precedenti, puoi attivare la ricezione di notifiche anche
          in base al tag di classificazione di un evento.
        </Trans>
        <TagPreferences {...props} />
      </CardBody>
    </StyledCard>
  );
};

export default NotificationPreferences;
