import logger from 'app/common/log';
import { getChainEventTypeList } from './preferences.api';
import { translateType } from '../helpers/CatalogsTranslate.js';

const formatSite = site => {
  const { id, name } = site;
  return {
    name,
    _type: 'site',
    _id: id
  };
};

const formatSitegroup = sitegroup => {
  const { id, name } = sitegroup;
  return {
    name,
    _type: 'sitegroup',
    _id: id
  };
};

const formatCompanyO1 = (formatSiteFn, formatSiteGroupFn) => company => {
  const { id, name, siteGroups, sites } = company;
  const childrenSitegroups = siteGroups.map(formatSiteGroupFn);
  const childrenSites = sites.map(formatSiteFn);

  return {
    name,
    _type: 'company',
    _id: id,
    children: [...childrenSitegroups, ...childrenSites],
    open: true
  };
};

const formatDomainO1 = formatCompanyFn => domain => {
  const { id, name, companies } = domain;
  const childrenCompanies = companies.map(formatCompanyFn);
  return {
    name,
    _type: 'domain',
    _id: id,
    children: childrenCompanies,
    open: true
  };
};

const getCompleteEventTypeList = async catalogs => {
  const types =
    (catalogs &&
      catalogs['event/types'] &&
      catalogs['event/types'].map(type => ({
        ...type,
        key: type.naturalKey,
        description: translateType(type.naturalKey, catalogs)
      }))) ||
    [];

  const { data: chainEventTypes, err } = await getChainEventTypeList();
  logger.trace('getCompleteEventTypeList ', types, chainEventTypes);
  if (err) return types;
  else {
    const completeEventTypeList = [
      ...types,
      ...chainEventTypes
        .filter(type => !types.map(t => t.key).includes(type))
        .map(dt => {
          return {
            key: dt,
            description: dt
          };
        })
    ];
    logger.debug('getCompleteEventTypeList ', completeEventTypeList);
    return completeEventTypeList;
  }
};

const formatCompany = formatCompanyO1(formatSite, formatSitegroup);
const formatDomain = formatDomainO1(formatCompany);

//
// NotificationPreferences utility functions
//

const createDefaultDomainAllowFrom = domainId => {
  const body = BeaDomainPlantsSelected();
  body.addWholeBranch({ _id: domainId, _type: 'domain' });
  return {
    [domainId]: body.value
  };
};
const fixNotificationChannel = (domainId, channelKey, channel) => {
  const newChannel = channel ? { [channelKey]: channel } : createDefaultChannel(channelKey);
  if (newChannel && newChannel[channelKey] && !newChannel[channelKey].allowedFrom) {
    newChannel[channelKey].allowedFrom = createDefaultDomainAllowFrom(domainId);
  } else if (
    newChannel &&
    newChannel[channelKey] &&
    !newChannel[channelKey].allowedFrom[domainId]
  ) {
    newChannel[channelKey].allowedFrom = {
      ...newChannel[channelKey].allowedFrom,
      ...createDefaultDomainAllowFrom(domainId)
    };
  }
  return newChannel;
};

const fixNotificationEventTag = (domainId, tagKey, tag) => {
  const newTag = tag ? { [tagKey]: tag } : createDefaultEventTag(tagKey);
  if (newTag && newTag[tagKey] && !newTag[tagKey].allowedFrom) {
    newTag[tagKey].allowedFrom = createDefaultDomainAllowFrom(domainId);
  } else if (newTag && newTag[tagKey] && !newTag[tagKey].allowedFrom[domainId]) {
    newTag[tagKey].allowedFrom = {
      ...newTag[tagKey].allowedFrom,
      ...createDefaultDomainAllowFrom(domainId)
    };
  }
  return newTag;
};

const createDefaultNotifications = (user, eventTypes) => {
  return {
    email: user && user.email ? user.email : user && user.username,
    phone: null,
    channels: createDefaultChannels(eventTypes),
    tags: {}
  };
};

const createDefaultChannel = eventType => {
  if (eventType == null) {
    return {};
  }
  return { [eventType]: { email: false, toast: false, telegram: false } };
};

const createDefaultEventTag = tagKey => {
  if (tagKey == null) {
    return {};
  }
  return { [tagKey]: { email: false, toast: false, telegram: false } };
};

const createDefaultChannels = (eventTypes = []) => {
  return eventTypes.reduce(
    (result, eventType) => ({ ...result, ...createDefaultChannel(eventType.key) }),
    {}
  );
};

const fixDefaultNotificationPreferences = ({
  domainId,
  user = {},
  eventTypes = [],
  notifications
}) => {
  if (!domainId) return notifications;
  if (!notifications) {
    const result = createDefaultNotifications(user, eventTypes);
    return result;
  }
  if (!notifications.email) {
    notifications.email = user.email || user.username;
  }
  const { channels, tags } = notifications;
  if (!channels || Object.keys(channels).length === 0) {
    return {
      ...notifications,
      channels: createDefaultChannels(eventTypes)
    };
  }
  const newChannels = eventTypes
    .map(et => et.key)
    .reduce((result, channelKey) => {
      const newChannel = fixNotificationChannel(domainId, channelKey, channels[channelKey]);
      return { ...result, ...newChannel };
    }, {});
  const newTags = tags
    ? tags &&
      Object.keys(tags).reduce((result, tagKey) => {
        const newChannel = fixNotificationEventTag(domainId, tagKey, tags[tagKey]);
        return { ...result, ...newChannel };
      }, {})
    : {};
  const result = {
    ...notifications,
    channels: newChannels,
    tags: newTags
  };
  logger.debug('fixDefaultUserPreferences ', result);
  return result;
};

const getPlantName = ({ companies, domains, sites, sitegroups, type, id }) => {
  const mapType2set = {
    domain: domains,
    company: companies,
    site: sites,
    sitegroup: sitegroups
  };
  const group = mapType2set[type] || [];
  const result = (group.find(item => item.id === id) || {}).name;
  return result;
};
//
// PlantSelection TREE utility functions
//
const BeaDomainPlantsSelected = inputStructure => ({
  structureTypesMap: {
    domain: 'Domain',
    company: 'Company',
    site: 'Site',
    sitegroup: 'SiteGroup'
  },
  structure: inputStructure
    ? inputStructure
    : {
        Domain: {
          nodeOnly: [],
          wholeBranch: []
        },
        Company: {
          nodeOnly: [],
          wholeBranch: []
        },
        SiteGroup: {
          wholeBranch: []
        },
        Site: {
          nodeOnly: []
        }
      },
  addWholeBranch: function({ _id, _type }) {
    switch (_type) {
      case 'domain':
        this.structure.Domain.wholeBranch.push(_id);
        break;
      case 'company':
        this.structure.Company.wholeBranch.push(_id);
        break;
      case 'sitegroup':
        this.structure.SiteGroup.wholeBranch.push(_id);
        break;
      default:
        break;
    }
  },
  addNodeOnly: function({ _id, _type }) {
    switch (_type) {
      case 'domain':
        this.structure.Domain.nodeOnly.push(_id);
        break;
      case 'company':
        this.structure.Company.nodeOnly.push(_id);
        break;
      case 'site':
        this.structure.Site.nodeOnly.push(_id);
        break;
      default:
        break;
    }
  },
  getType: function({ id, type } = {}) {
    let result;
    switch (type) {
      case 'domain':
        result = this.structure.Domain.nodeOnly.includes(id) && 'nodeOnly';
        result = result || (this.structure.Domain.wholeBranch.includes(id) && 'wholeBranch');
        break;
      case 'company':
        result = this.structure.Company.nodeOnly.includes(id) && 'nodeOnly';
        result = result || (this.structure.Company.wholeBranch.includes(id) && 'wholeBranch');
        break;
      case 'site':
        result = this.structure.Site.nodeOnly.includes(id) && 'nodeOnly';
        break;
      case 'sitegroup':
        result = this.structure.SiteGroup.wholeBranch
          ? this.structure.SiteGroup.wholeBranch.includes(id) && 'wholeBranch'
          : this.structure.SiteGroup.nodeOnly &&
            this.structure.SiteGroup.nodeOnly.includes(id) &&
            'wholeBranch';
        break;
      default:
        break;
    }
    return result;
  },
  getPlantList: function(plantType, isWholeBranch) {
    const plantKey = this.structureTypesMap[plantType];
    const nodeType = isWholeBranch ? 'wholeBranch' : 'nodeOnly';
    let result = [];
    if (plantKey) {
      result = (this.structure[plantKey] && this.structure[plantKey][nodeType]) || [];
    }
    return result;
  },
  get value() {
    return { ...this.structure };
  }
});

const isNodeSelected = (rootNode, selectedItems = []) =>
  selectedItems.findIndex(item => item._id === rootNode._id && item._type === rootNode._type) >= 0;
const retrieveSelectedPlants = (rootNode, selectedItems, beaPlants) => {
  logger.debug(
    'retrieveSelectedPlants node %o selectedItems %s %o',
    rootNode,
    selectedItems,
    beaPlants
  );
  const selected = isNodeSelected(rootNode, selectedItems);
  if (rootNode._type === 'domain' || rootNode._type === 'company') {
    const childrensPlus = rootNode.children.map(node =>
      retrieveSelectedPlants(node, selectedItems, beaPlants)
    );
    logger.trace('childrensPlus ', rootNode, childrensPlus);

    const starred =
      childrensPlus.reduce((result, { node, starred }) => result && starred, true) && selected;

    if (rootNode._type === 'domain') {
      if (starred) {
        beaPlants.addWholeBranch(rootNode);
      } else if (selected) {
        beaPlants.addNodeOnly(rootNode);
      }
    }
    if (!starred) {
      logger.trace(
        'childrensPlus filter',
        rootNode,
        childrensPlus.filter(({ selected }) => selected)
      );
      childrensPlus
        .filter(({ selected }) => selected)
        .forEach(({ node, starred }) => {
          if (starred && node._type !== 'site') {
            beaPlants.addWholeBranch(node);
          } else {
            beaPlants.addNodeOnly(node);
          }
        });
    }
    return { node: rootNode, starred, selected };
  } else {
    // site or sitegroup
    return { node: rootNode, starred: selected, selected };
  }
};
const getTreeNodes = rootNode => {
  logger.trace('getTreeNodes ', rootNode);
  const { children } = rootNode;
  if (children && children.length > 0) {
    return [rootNode, ...children.map(node => getTreeNodes(node)).flat()];
  } else {
    return [rootNode];
  }
};

const pickUpDomainTreeSelectedItems = (rootNode, beaDomainPlants) => {
  logger.debug('pickUpDomainTreeSelectedItems ', rootNode, beaDomainPlants);
  if (!rootNode) return [];
  const nodeType = beaDomainPlants.getType({ type: rootNode._type, id: rootNode._id });
  if (nodeType && nodeType === 'wholeBranch') {
    return getTreeNodes(rootNode);
  }
  let result = nodeType && nodeType === 'nodeOnly' ? [rootNode] : [];
  result = rootNode.children
    ? rootNode.children.reduce(
        (total, node) => [...total, ...pickUpDomainTreeSelectedItems(node, beaDomainPlants)],
        result
      )
    : result;

  return result;
};
export {
  formatCompany,
  formatDomain,
  formatSite,
  formatSitegroup,
  getCompleteEventTypeList,
  getPlantName,
  retrieveSelectedPlants,
  BeaDomainPlantsSelected,
  fixDefaultNotificationPreferences,
  getTreeNodes,
  pickUpDomainTreeSelectedItems
};
