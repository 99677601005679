// const localeToCurrency = (lang) => {
//   const lang2Currency = {
//     'it-it': 'EUR',
//     'en-us': 'USD',
//     'en-gb': 'GBP',
//     'it-ch': 'CHF',
//     'fr-ch': 'CHF',
//     'de-ch': 'CHF',
//     // 'es-pa': 'PAB', // panama
//   };
//   const lowercase = (lang) ? lang.toLowerCase() : null;
//   return (lang2Currency[lowercase]) ? lang2Currency[lowercase] : 'EUR';
// };
//
// export const currency = () => {
//   const localeLang = navigator.language || navigator.systemLanguage;
//   if (localeLang) {
//     return localeToCurrency(localeLang);
//   }
//   return null;
// };

/* il simbolo è la notazione da mostrare in presentation per la um misurata sul campo */
export const getCatalogSymbol = (naturalKey, units) => {
  if (!units) return naturalKey;

  const unitDet = units.find(x => x.naturalKey === naturalKey);
  return unitDet !== undefined && unitDet.symbol ? unitDet.symbol : naturalKey;
};

export const getUnitByPhysicalQuantity = (physicalQuantity) => {
  let unit = physicalQuantity.defaultVisualizationUnit ? physicalQuantity.defaultVisualizationUnit : (physicalQuantity.units ? physicalQuantity.units.join(', ') : '');
  // Commentato il 13/12/2019, in seguito all'introduzione di quantità ad hoc per la gestione delle diverse currency
  // if (physicalQuantity.naturalKey && physicalQuantity.naturalKey === 'currency-amount') {
  //   unit = currency();
  // }
  return unit;
};

export const getUnitSymbolByPhysicalQuantity = (physicalQuantity, units) => {
  const unit = getUnitByPhysicalQuantity(physicalQuantity);
  return getCatalogSymbol(unit, units);
};

/**
 * Get Physical Quantity Label.
 * @param {Object} physicalQuantity
 * @return {string} Physical Quantity Label.
 */
export const getPhysicalQuantityLabel = (physicalQuantity, units) => {
  if (!physicalQuantity) {
    return '';
  }

  // custom quantity
  if (physicalQuantity.unitSymbol) {
    return `${physicalQuantity.name} [${getCatalogSymbol(physicalQuantity.unitSymbol, units)}]`;
  }

  // physical quantity
  return `${physicalQuantity._label} [${getUnitSymbolByPhysicalQuantity(physicalQuantity, units)}]`;
};

export const getPhysicalQuantityByVariableLabel = (variable, physicaldimensions, units) => {
  if (!variable || !variable.physicalQuantityDetails) {
    return '';
  }
  const physicalQuantity = variable.physicalQuantityDetails;
  // custom quantity
  if (physicalQuantity.naturalKey === 'custom') {
    const customQuantity = physicaldimensions.find(pd => pd.id === variable.customQuantityId);
    if (customQuantity) {
      return `${customQuantity.name} [${getCatalogSymbol(customQuantity.unitSymbol, units)}]`;
    }
    return '';
  }

  // physical quantity
  return `${physicalQuantity.name} [${getUnitSymbolByPhysicalQuantity(physicalQuantity, units)}]`;
};

export const getVariableLabel = (variable, physicaldimensions, units) => {
  if (!variable || !variable.physicalQuantityDetails) {
    return '';
  }
  const physicalQuantity = variable.physicalQuantityDetails;

  // custom quantity
  if (physicalQuantity.naturalKey === 'custom') {
    const customQuantity = physicaldimensions.find(pd => pd.id === variable.customQuantityId);
    if (customQuantity) {
      return `${variable.name} [${getCatalogSymbol(customQuantity.unitSymbol, units)}]`;
    }
    return '';
  }

  return `${variable.name} [${getUnitSymbolByPhysicalQuantity(physicalQuantity, units)}]`;
};

export const getVariableUnit = (variable, physicaldimensions, units) => {
  if (!variable || !variable.physicalQuantityDetails) {
    return '';
  }
  const physicalQuantity = variable.physicalQuantityDetails;

  // custom quantity
  if (physicalQuantity.naturalKey === 'custom') {
    const customQuantity = physicaldimensions.find(pd => pd.id === variable.customQuantityId);
    if (customQuantity) {
      return customQuantity.unitSymbol;
    }
    return '';
  }

  return physicalQuantity.defaultVisualizationUnit ? physicalQuantity.defaultVisualizationUnit : (physicalQuantity.units ? physicalQuantity.units.join(', ') : '');
};

const replaceRegexCharacter = (stringToReplace) => stringToReplace.replace(new RegExp('((?:\\&)|(?:\\~)|(?:\\?)|(?:\\$)|(?:\\+)|(?:\\*)|(?:\\|)|(?:\\?)|(?:\\!)|(?:\\^)|(?:\\.)|(?:\\:)|(?:\\%)|(?:\\[)|(?:\\])|(?:\\()|(?:\\))|(?:\\{)|(?:\\}))', 'g'), '\\$1');

export const getVariableRegexLabel = (variable, physicaldimensions, units) => {
  if (!variable || !variable.physicalQuantityDetails) {
    return '';
  }
  const physicalQuantity = variable.physicalQuantityDetails;
  // custom quantity
  if (physicalQuantity.naturalKey === 'custom') {
    const customQuantity = physicaldimensions.find(pd => pd.id === variable.customQuantityId);
    if (customQuantity) {
      return `${variable.name} \\[${replaceRegexCharacter(getCatalogSymbol(customQuantity.unitSymbol, units))}\\]`;
    }
    return '';
  }

  return `${variable.name} \\[${replaceRegexCharacter(getUnitSymbolByPhysicalQuantity(physicalQuantity, units))}\\]`;
};
