import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from 'api';
import { Card, CardBody, CardHeader, CardFooter, Input } from 'reactstrap';
import styled from 'styled-components';
import i18n from 'app/i18n';
import { Trans } from '@lingui/macro';
import { opeOptions } from 'app/utils/auth';
import { Button, Select, TreeView, MultiSelectTag, IconButton } from 'app/common';
import ViewerAuth from './ViewerAuth';
import { getUrlFromPath } from 'app/utils/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Node = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Label = styled.div`
  flex: 1 0 auto;
`;

const StyledSelect = styled(Select)`
  && {
    margin-left: 1rem;
    height: 2rem;
    min-width: 5rem;
    max-width: 5rem;

    & > div {
      padding: 0.2rem 0.5rem;
    }

    & span {
      font-size: 0.9rem;
    }
  }
`;

const StyledMultiSelect = styled(MultiSelectTag)`
  && {
    margin-left: 1rem;
    height: 2rem;
    min-width: 20rem;

    & .form-control {
      padding: 0 0.5rem;
    }
  }
`;

const Error = styled.span`
  color: red;
`;

const CheckBoxDisabled = styled(FontAwesomeIcon)`
  margin-left: .3rem;
  min-width: 1.2rem;
`;

const DisabledTopLabel = styled.div`
  font-weight: 500;
  font-size: 1.3rem;
  color: red;
`;

const DisabledBottomLabel = styled.div`
  font-weight: 400;
  font-size: 1rem;
`;

const UserDetails = props => {
  const { match, history } = props;
  const { userid, intUserId = +userid } = match.params;
  const { userData } = props;
  const thisUserData = userData && userData.filter(user => user.id === intUserId);
  const userName = thisUserData && thisUserData[0] && thisUserData[0].name;

  const [userPreferences, setUserPreferences] = useState();
  const [newDashboardList, setNewDashboardList] = useState();
  const [dashboardVwrList, setDashboardVwrList] = useState();
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [originalSelectedItems, setOriginalSelectedItems] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [error, setError] = useState('');
  const [showViewerDetails, setShowViewerDetails] = useState(false);
  const [viewerNodeInfo, setViewerNodeInfo] = useState({});
  const [domainNameList, setDomainNameList] = useState([]);
  const [companyNameList, setCompanyNameList] = useState([]);
  const [siteNameList, setSiteNameList] = useState([]);
  const [siteGroupNameList, setSiteGroupNameList] = useState([]);
  const [isUserDisabled, setIsUserDisabled] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("fromFilter");
  const isEnabled = queryParameters.get("enabled");

  const options = {
    keyProperty: 'name',
    checked: true
  };

  const loadUserAuthorizations = async () => {
    const req = await api.get(`/UserAuthorizations?filter={"where":{"userInfoId":${userid}}}`);
    const sel = req.data.map(auth => {
      let _type;
      let _id;
      if (auth.siteId) {
        _type = 'site';
        _id = auth.siteId;
      } else if (auth.siteGroupId) {
        _type = 'sitegroup';
        _id = auth.siteGroupId;
      } else if (auth.companyId) {
        _type = 'company';
        _id = auth.companyId;
      } else if (auth.domainId) {
        _type = 'domain';
        _id = auth.domainId;
      }
      return { ...auth, _type, _id };
    });
    setOriginalSelectedItems(sel);
    setSelectedItems(sel);
  };

  const loadUserPreferences = async () => {
    const result = await api.get(`/UserPreferences/${userid}`);
    const { data } = result;
    setUserPreferences(data.preferences);
  }

  const loadTree = async () => {
    const domainNames = [];
    const companyNames = [];
    const siteNames = [];
    const siteGroupNames = [];
    const req = await api.get('/Domains?filter={"include":{"companies":["siteGroups", "sites"]}}');
    const tree = req.data.map(domain => {
      const { id, name, companies } = domain;
      domainNames[id] = name;
      const childrenCompanies = companies.map(company => {
        const { id, name, siteGroups, sites } = company;
        companyNames[id] = name;
        const childrenSitegroups = siteGroups.map(sitegroup => {
          const { id, name } = sitegroup;
          siteGroupNames[id] = name;
          return {
            name,
            _type: 'sitegroup',
            _id: id,
            domainId: domain.id,
            companyId: company.id,
            siteGroupId: id
          };
        });
        const childrenSites = sites.map(site => {
          const { id, name } = site;
          siteNames[id] = name;
          return {
            name,
            _type: 'site',
            _id: id,
            domainId: domain.id,
            companyId: company.id,
            siteId: id
          };
        });

        return {
          name,
          _type: 'company',
          _id: id,
          domainId: domain.id,
          companyId: id,
          children: [...childrenSitegroups, ...childrenSites],
          open: true
        };
      });
      return {
        name,
        _type: 'domain',
        _id: id,
        domainId: id,
        children: childrenCompanies,
        open: true
      };
    });
    setData(tree);
    setDomainNameList(domainNames);
    setCompanyNameList(companyNames);
    setSiteGroupNameList(siteGroupNames);
    setSiteNameList(siteNames);
  };

  useEffect(() => {
    loadTree();
  }, []);

  useEffect(() => {
    loadUserAuthorizations();
    loadUserPreferences();
    getUserDisabled();
  }, [userid]);

  useEffect(() => {
    if (userPreferences && userPreferences.dashboardVwrList) {
      setDashboardVwrList(Object.values(userPreferences.dashboardVwrList));
    }
  }, [userPreferences]);

  const filterFunction = node => node.name.includes(filterText);

  const compareFunction = item => currentItem =>
    item._type === currentItem._type && item._id === currentItem._id;

  const diff = (a, b) => a.filter(item => !b.find(compareFunction(item)));

  const common = (a,b) => a.filter(item => b.find(compareFunction(item))).map(item => ({...item, id: b.find(compareFunction(item)).id})).map(item => manageOPE(item));

  const manageOPE = item => {
    if(item.role === 'OPE'){
      return item;
    }
    return {...item, opeCMD: false, opeCommissioning: false, opeVM: false}
  }

  const save = async () => {
    try {
      setError('');

      const cloneButVWRDetails = ({ VWRDetails, ...rest }) => rest;
      const pruneVMRDetails = item => (item.role !== 'VWR' ? cloneButVWRDetails(item) : item);

      const toDelete = diff(originalSelectedItems, selectedItems).map(pruneVMRDetails);
      const toAdd = diff(selectedItems, originalSelectedItems).map(pruneVMRDetails);
      const toUpdate = common(selectedItems, originalSelectedItems).map(pruneVMRDetails);

      const deleteCalls = toDelete.map(x => api.delete(`/UserAuthorizations/${x.id}`));
      await Promise.all(deleteCalls);
      const updateCalls = toUpdate.map(x => api.patch(`/UserAuthorizations/${x.id}`, x));
      await Promise.all(updateCalls);
      const addCalls = toAdd.map(x => api.post('/UserAuthorizations', x));
      await Promise.all(addCalls);
      await updateUserPreferences(toDelete);
      history.push(getUrlFromPath('/users') + (isEnabled ? ('?enabled=true') : "") + (filter ? (isEnabled ? ('&filter=' + filter) : ('?filter=' + filter)) : "") + "#" + userid);
    } catch (e) {
      setError(e.message);
    }
  };

  const getUserDisabled = async () => {
    const res = await api.get('/UserInfos/' + userid + '/disabled', { validateStatus: () => true });
    if (res.status >= 200 && res.status < 300){
      setIsUserDisabled(res.data.disabled);
    }
  }

  const updateUserPreferences = (toDelete) => {
    let preferences;
    let updatedList;
    if (dashboardVwrList) {
      let filteredDash = [...dashboardVwrList];
      if (toDelete) {
        const dashboardsToDel = dashboardVwrList.filter(e => toDelete.find(d => e.nodeId === d._id && e.nodeType === d._type));
        filteredDash = dashboardVwrList.filter(dash => !dashboardsToDel.find(dashToDel => dashToDel === dash));
      }
      const index = newDashboardList ? filteredDash.findIndex(d => d.nodeId === newDashboardList.nodeId && d.nodeType === newDashboardList.nodeType) : -1;
      updatedList = index > -1 || !newDashboardList ? filteredDash.slice() : [...filteredDash, newDashboardList];
      if (index > -1) { // if node is already present in userPreferences
        updatedList[index] = newDashboardList;
      }
      preferences = { ...userPreferences, dashboardVwrList: updatedList };
      setDashboardVwrList(updatedList);
    } else if (newDashboardList) {
      preferences = { ...userPreferences, dashboardVwrList: [newDashboardList] };
      setDashboardVwrList(newDashboardList);
    }
    if (newDashboardList || updatedList !== dashboardVwrList) {
      preferences.dashboardVwrList = preferences.dashboardVwrList.filter(d => d.dashboardList.length > 0);
      if (preferences.dashboardVwrList.length === 0) {
        delete preferences.dashboardVwrList;
      }
      setUserPreferences(preferences);
      api.patch(`/UserPreferences/${userid}`, { 'preferences': preferences });
    }
  }

  const cancel = () => {
    history.push(getUrlFromPath('/users') + (isEnabled ? ('?enabled=true') : "") + (filter ? (isEnabled ? ('&filter=' + filter) : ('?filter=' + filter)) : "") + "#" + userid);
  };

  const createAuth = node => {
    const { domainId, companyId, siteId, siteGroupId, _type, _id } = node;
    const auth = {
      userInfoId: +userid,
      domainId,
      companyId,
      siteId,
      siteGroupId,
      role: 'GUE',
      _type,
      _id,
      _updated: true
    };
    return auth;
  };

  const toggle = (node, checked) => {
    let newSelectedItems;
    if (checked) {
      newSelectedItems = selectedItems.concat(createAuth(node));
    } else {
      const selectedItem = selectedItems.find(compareFunction(node));
      newSelectedItems = selectedItems.filter(x => x !== selectedItem);
    }
    setSelectedItems(newSelectedItems);
  };

  const updateRole = (node, role) => {
    const selectedItem = selectedItems.find(compareFunction(node));
    setSelectedItems(
      selectedItems.map(x => (x !== selectedItem ? x : { ...selectedItem, role, _updated: true }))
    );
  };

  const roles = {
    domain: [
      { value: 'SUP', label: 'SUP' },
      { value: 'GUE', label: 'GUE' },
      { value: 'VWR', label: 'VWR' }
    ],
    company: [
      { value: 'ADM', label: 'ADM' },
      { value: 'GUE', label: 'GUE' },
      { value: 'VWR', label: 'VWR' }
    ],
    site: [
      { value: 'OPE', label: 'OPE' },
      { value: 'GUE', label: 'GUE' },
      { value: 'VWR', label: 'VWR' }
    ],
    sitegroup: [
      { value: 'OPE', label: 'OPE' },
      { value: 'GUE', label: 'GUE' },
      { value: 'VWR', label: 'VWR' }
    ]
  };

  const getVWRDetails = node => {
    const auth = selectedItems.find(compareFunction(node));
    return auth ? auth.VWRDetails : {};
  };

  const getRole = node => {
    const auth = selectedItems.find(compareFunction(node));
    return auth ? auth.role : null;
  };

  const getOpeOptions = node => {
    const auth = selectedItems.find(compareFunction(node));
    const selectedOptions = [];
    opeOptions
      .map(x => x.key)
      .forEach(key => {
        if (auth[key] === true) {
          const item = opeOptions.find(x => x.key === key);
          selectedOptions.push({ ...item, _label: i18n._(item.label) });
        }
      });
    return selectedOptions;
  };

  const changeOpeOptions = (node, value) => {
    const keys = value.map(x => x.key);
    const selectedOpeOptions = {};
    opeOptions
      .map(x => x.key)
      .forEach(key => {
        selectedOpeOptions[key] = keys.includes(key);
      });
    const selectedItem = selectedItems.find(compareFunction(node));
    setSelectedItems(
      selectedItems.map(x =>
        x !== selectedItem ? x : { ...selectedItem, ...selectedOpeOptions, _updated: true }
      )
    );
  };

  const okVWR = (_dashList) => {
    setNewDashboardList(_dashList);
    setShowViewerDetails(false);
  };

  const cancelVWR = node => {
    if (!node) {
      console.log('Error cancelVWR ', node);
      return;
    }
    const selectedItem = selectedItems.find(compareFunction(node));
    if (selectedItem) {
      const originalSelectedItem = originalSelectedItems.find(compareFunction(node));
      if (originalSelectedItem) {
        console.log('cancelVWR - item was NOT selected, original:', originalSelectedItem);
        const origIndex = originalSelectedItems.indexOf(originalSelectedItem);
        const selectedIndex = selectedItems.indexOf(selectedItem);
        const newSelectedItems = [...selectedItems];
        newSelectedItems[selectedIndex] = originalSelectedItems[origIndex];
        setSelectedItems(newSelectedItems);
      } else {
        const selectedItemsWithoutLastSelected = selectedItems.filter(
          item => item !== selectedItem
        );
        setSelectedItems(selectedItemsWithoutLastSelected);
      }
    } else {
      console.log('weird! cancelVWR - item was NOT selected ', selectedItem);
    }
    setShowViewerDetails(false);
  };

  const setVWRDetails = (node, VWRDetails) => {
    console.log('setVWRDetails ', node, VWRDetails);
    const selectedItem = selectedItems.find(compareFunction(node));
    const newSelectedItems = selectedItems.map(x =>
      x !== selectedItem ? x : { ...selectedItem, VWRDetails, _updated: true }
    );
    setSelectedItems(newSelectedItems);
  };

  const showViewerAuth = nodeInfo => {
    setShowViewerDetails(true);
    setViewerNodeInfo({ ...nodeInfo, userName });
  };

  const renderVWRDetails = node => {
    const VWRDetails = getVWRDetails(node);
    console.log('VWRDetails ', VWRDetails);
    console.log('Node ', node);
    return VWRDetails ? (
      <div style={{ display: 'flex' }}>
        <div>
          <span style={{ marginLeft: '1rem' }}>
            OP: {VWRDetails.ObservedProperty && VWRDetails.ObservedProperty.length}
          </span>
          <span style={{ marginLeft: '1rem' }}>
            Dashboard: {VWRDetails.Dashboard && VWRDetails.Dashboard.length}
          </span>
          <span style={{ marginLeft: '1rem' }}>
            EFM: {VWRDetails.AssetGraph && VWRDetails.AssetGraph.length}
          </span>
        </div>
        <Link onClick={() => showViewerAuth(node)}>
          <IconButton icon="pencil-alt" />
        </Link>
      </div>
    ) : null;
  };
  console.log('tree userName',userName);
  console.log('tree options',options);
  console.log('tree data',data);
  console.log('tree selectedItems',selectedItems);

  const toggleEnable = (node, disabled) => {
    const selectedItem = selectedItems.find(compareFunction(node));
    const newSelectedItems = selectedItems.map(item => {
      if(item === selectedItem){
        return {...item, disabled: !disabled, _updated: true}
      }else{
        return item;
      }
    })
    setSelectedItems(newSelectedItems);
  }

  const renderNode = (node, checked, disabled) => {
    const role = getRole(node);
    const selectedItem = selectedItems.find(compareFunction(node));
    const VWRDetailsJSX = role === 'VWR' ? renderVWRDetails(node) : null;
    return (
      <Node>
        <Label className="label">{node.name}</Label>
        {checked && (
          <StyledSelect
            value={role}
            onChange={e => updateRole(node, e.target.value)}
            options={roles[node._type]}
          />
        )}
        {checked && role === 'OPE' && (
          <StyledMultiSelect
            value={getOpeOptions(node)}
            options={opeOptions.map(x => ({ ...x, _label: i18n._(x.label) }))}
            keyProperty="key"
            labelProperty="_label"
            placeholder={<Trans>Selezionare le funzioni abilitate</Trans>}
            onChange={e => changeOpeOptions(node, e.target.value)}
          />
        )}
        {checked && role === 'VWR' && (
          <>{!VWRDetailsJSX && !showViewerDetails ? showViewerAuth(node) : VWRDetailsJSX}</>
        )}
        {checked && (<><CheckBoxDisabled className="checkbox" icon={!selectedItem.disabled ? 'check-square' : ['far', 'square']} checked={!selectedItem.disabled} onClick={() => toggleEnable(node, selectedItem.disabled)} /><div className="label">Abilitata</div></>)}
      </Node>
    );
  };
  return showViewerDetails ? (
    <ViewerAuth
      userData={userData}
      nodeInfo={viewerNodeInfo}
      domainNames={domainNameList}
      siteNames={siteNameList}
      siteGroupNames={siteGroupNameList}
      companyNames={companyNameList}
      dashboardVwrList={dashboardVwrList}
      goBackOk={okVWR}
      goBackCancel={cancelVWR}
      getVWRDetails={getVWRDetails}
      setVWRDetails={setVWRDetails}
    />
  ) : (  
      <Card>
        <CardHeader>
          <Trans>Gestione permessi dell'utente {userName}</Trans>
          {isUserDisabled && <><DisabledTopLabel><Trans>ATTENZIONE: l’utente è disabilitato e non può comunque accedere alla piattaforma</Trans></DisabledTopLabel>
            <DisabledBottomLabel><Trans>L’abilitazione può essere gestita dalla lista utenti</Trans></DisabledBottomLabel></>}
        </CardHeader>
        <CardBody>
          <Input value={filterText} onChange={e => setFilterText(e.target.value)} />
          <TreeView
            userName={userName}
            options={options}
            data={data}
            toggle={toggle}
            filter={filterFunction}
            compareFunction={compareFunction}
            selectedItems={selectedItems}
            render={renderNode}
          />
        </CardBody>
        <CardFooter>
          <Button marginRight color="primary" onClick={save}>
            <Trans>Salva</Trans>
          </Button>
          <Button color="link" onClick={cancel}>
            <Trans>Annulla</Trans>
          </Button>
          {error && <Error>{error}</Error>}
        </CardFooter>
      </Card>
    );
};

export default UserDetails;
