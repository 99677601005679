import React, { useState } from 'react';
import { Button, DataTable, Tag, Spinner, IconButton, CalendarOptions } from 'app/common';
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  UncontrolledTooltip,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { concatUrl } from 'app/utils/navigation';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

const COLORS = Object.freeze({ NEUTRAL: '#bbb', OK: '#0a0', ERROR: '#d00', UPLOADED: '#007bff' });

const StyledButton = styled(Button)`
  && {
    font-size: 0.8rem;
    width: 8.33%;
    min-width: 7.7rem;
  }
`;

const StyledBadge = styled(Badge)`
  && {
    margin-left: 0.5rem;
    color: ${props => props.theme.common.buttons.primary.color};
    background-color: ${props => props.theme.common.buttons.primary.background};
    border-radius: 1rem;
  }
`;

const CustomTooltip = styled(UncontrolledTooltip)`
  && .custom-tooltip {
    background-color: rgba(31, 113, 183, 0.8);
  }
  && .error-tooltip {
    background-color: #e87979;
  }
`;

const DateInput = styled(Input)`
  max-width: 8.33%;
  min-width: 7.7rem;
  cursor: pointer;
`;
const DateInputGroup = styled(InputGroup)`
  justify-content: flex-end;
  margin-right: 1rem;
`;
const DateInputGroupAddon = styled(InputGroupAddon)`
  margin-left: 1rem;
`;

const getBgColor = _status => {
  switch (_status) {
    case 'error':
      return COLORS.ERROR;
    case 'uploaded':
      return COLORS.UPLOADED;
    case 'done':
      return COLORS.OK;
    default:
      return COLORS.NEUTRAL;
  }
};
const getStatusLabel = (_status, _detail) => {
  switch (_status) {
    case 'uploaded':
      return 'Dati acquisiti';
    case 'done':
      return 'Dati acquisiti ed aggregati';
    case 'error':
      return _detail && _detail.step === 'aggregating'
        ? 'Dati acquisiti, non aggregati'
        : 'Dati non acquisiti';
    default:
      return 'File caricato';
  }
};

const getErrorLabel = (_status, _code, line) => {
  if (_status === 'error') {
    switch (_code) {
      case 'pe_corrupted_data':
        return i18n._(t`File con dati corrotti o non validi nella riga`) + ` ${line}`;
      case 'pe_column_lookup':
        return i18n._(t`Errore nelle impostazioni di caricamento dati: errore posizione colonne`);
      case 'pe_empty_row':
        return i18n._(t`File senza dati nella riga`) + ` ${line}`;
      case 'pe_empty_value':
        return i18n._(t`File senza indicazione del valore nella riga`) + ` ${line}`;
      case 'pe_value_conversion':
        return i18n._(t`Errore di conversione del valore nella riga`) + ` ${line}`;
      case 'pe_infinity_value':
        return i18n._(t`File con dati corrotti o non validi nella riga`) + ` ${line}`;
      case 'pe_invalid_format':
        return i18n._(t`File con formato data-orario non valido nella riga`) + ` ${line}`;
      case 'pe_invalid_timezone':
        return i18n._(t`Errore nelle impostazioni di caricamento dati: fuso orario non valido`);
      case 'pe_unsupported_option':
        return i18n._(
          t`Errore nelle impostazioni di caricamento dati: presenza di opzioni non supportate`
        );
      case 'pe_invalid_cell':
        return i18n._(
          t`File con data non valida (errore nella formattazione della cella) nella riga`) + ` ${line}`;
      default:
        return i18n._(t`Errore generico nel file o nelle impostazioni di caricamento dati`);
    }
  }
};

const LoadedDataList = props => {
  const {
    match,
    data,
    loading,
    arrowClicked,
    downloadFile,
    loadFilteredList,
    filteredDates /*, onScroll, dataLength*/,
    pageAllowed,
    history,
    openRows
  } = props;
  const { url } = match;

  const [showCalendar, setShowCalendar] = useState(false);

  const onArrowClick = (row, isOpen) => arrowClicked(row, isOpen);

  return (
    <>
      {loading && <Spinner />}
      {showCalendar && (
        <CalendarOptions
          saveAction={(startDate, endDate) => {
            loadFilteredList(startDate, endDate);
             setShowCalendar(false);
          }}
          dismissAction={() => setShowCalendar(false)}
          rangeCalendar={true}
          token={{endDate: filteredDates.showEndDate, startDate: filteredDates.showStartDate }}
          resetLabel={<Trans>Reset filtro date</Trans>}
        />
      )}
      <Card>
        <CardHeader>
          <Trans>Registro operazioni di caricamento dati</Trans>
        </CardHeader>
        <CardBody>
          <DataTable
            keyField="id"
            edit
            search
            collapsed
            openRows={openRows}
            buttonsGroup={
              <>
                {pageAllowed && (
                  <StyledButton size="sm" color="primary" tag={Link} to={concatUrl(url, 'new')}>
                    <Trans>Carica dati</Trans>
                  </StyledButton>
                )}
                <DateInputGroup>
                  <DateInputGroupAddon addonType="prepend" onClick={() => setShowCalendar(true)}>
                    <InputGroupText>
                      <Trans>Da:</Trans>
                    </InputGroupText>
                  </DateInputGroupAddon>
                  <DateInput type="text" value={filteredDates.showStartDate} readonly onClick={() => setShowCalendar(true)} />
                  <DateInputGroupAddon addonType="prepend" onClick={() => setShowCalendar(true)}>
                    <InputGroupText>
                      <Trans>A:</Trans>
                    </InputGroupText>
                  </DateInputGroupAddon>
                  <DateInput type="text" value={filteredDates.showEndDate} onClick={() => setShowCalendar(true)} />
                </DateInputGroup>
              </>
            }
            width="100%"
            onArrowClick={onArrowClick}
            sortby="variableName"
            headers={[
              {
                title: <Trans>Variabile</Trans>,
                property: 'variableName',
                index: 1,
                dataType: 'string',
                cell: row => <span>{row.variableName}</span>,
                canSearch: true
              },
              {
                title: <Trans>Grandezza</Trans>,
                property: 'quantity',
                index: 2,
                dataType: 'string',
                canSearch: true
              },
              {
                title: <Trans>Impianto</Trans>,
                property: 'site',
                index: 3,
                dataType: 'string',
                cell: row => (
                  <span>
                    <div>{row.site}</div>
                  </span>
                ),
                canSearch: true
              },
              {
                title: <Trans>Registro operazioni caricamento</Trans>,
                property: 'count',
                index: 4,
                dataType: 'custom',
                cell: row => {
                  const count =
                    row.uploadedFiles && row.uploadedFiles.length < 100
                      ? row.uploadedFiles.length
                      : '99+';
                  return count > 0 && <StyledBadge>{count}</StyledBadge>;
                }
              },
              {
                title: <Trans>Periodo dei dati caricati</Trans>,
                property: '_period',
                index: 5,
                dataType: 'string'
              },
              {
                title: <Trans>Stato</Trans>,
                property: '_status',
                index: 6,
                dataType: 'string'
              }
            ]}
            data={data}
            nestedData={{
              field: 'uploadedFiles',
              headers: [
                {
                  title: <Trans>Variabile</Trans>,
                  property: 'fileName',
                  index: 1,
                  dataType: 'string'
                },
                {
                  title: <Trans>Grandezza</Trans>,
                  property: '_quantity',
                  index: 2,
                  dataType: 'string'
                },
                {
                  title: <Trans>Impianto</Trans>,
                  property: '_site',
                  index: 3,
                  dataType: 'string'
                },
                {
                  title: <Trans>Registro operazioni caricamento</Trans>,
                  property: 'operation',
                  index: 4,
                  dataType: 'string'
                },
                {
                  title: <Trans>Periodo dei dati caricati</Trans>,
                  property: 'period',
                  index: 5,
                  dataType: 'string'
                },
                {
                  title: <Trans>Stato</Trans>,
                  property: 'status',
                  index: 6,
                  dataType: 'custom',
                  cell: row => {
                    const errorLabel =
                      row.statusDetail &&
                      getErrorLabel(row.status, row.statusDetail.code, row.statusDetail.line);
                    return (
                      <>
                        <Tag
                          color="#fff"
                          backgroundColor={getBgColor(row.status)}
                          id={`error-${row.id}`}
                        >
                          {getStatusLabel(row.status, row.statusDetail)}
                        </Tag>
                        {errorLabel && (
                          <CustomTooltip
                            innerClassName="error-tooltip"
                            placement="top"
                            target={`error-${row.id}`}
                          >
                            {errorLabel}
                          </CustomTooltip>
                        )}
                      </>
                    );
                  }
                }
              ],
              noData: <Trans>Nessun file associato</Trans>,
              nestedRowButtons: pageAllowed ? row => [{
                icon: row.fileName && row.fileName.toLowerCase().endsWith('csv')
                          ? 'file-csv'
                          : 'file-excel',
                label: <Trans>Scarica</Trans>,
                onClick: () => downloadFile(row.id, row.fileName)
              }]
                : null,
              iconRow: ["far", "file"]
            }}
            rowButtons={
              pageAllowed ? row =>[{
              icon: "cloud-upload-alt",
                label: <Trans>Carica</Trans>,
                onClick: () => {
                  history.push({pathname: concatUrl(url, 'new'),
                      state: { checkedNode: { name: row.variableName, id: row.variableId } }});
                }
              }]
              : null
            }
            noData={<Trans>Nessun dato presente</Trans>}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default LoadedDataList;
