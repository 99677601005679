import i18n from 'app/i18n';
import { t } from '@lingui/macro';

export const translateDetail = (detail, alarm, catalogs) => {

  if (!catalogs['event/details']) {
    return detail;
  }
  const cd = catalogs['event/details'];
  const foundDetail = cd.find(item => item.naturalKey === detail);
  if (foundDetail) {
    return alarm === false ? foundDetail._endLabel : foundDetail._label;
  }
  return detail;
}


export const translateDetailDescription = (detail, catalogs) => {
  if (!catalogs['event/details']) {
    return detail;
  }
  const cd = catalogs['event/details'];
  const foundDetail = cd.find(item => item.naturalKey === detail);
  if (foundDetail) {
    return foundDetail._description;
  }
  return detail;
}

export const translateWkStatus = (status_name, catalogs) => {
  const ct = [
    { name: 'WK-1', label: i18n._(t`Attivo`) },
    { name: 'WK-2', label: i18n._(t`Chiuso`) },
  ];
  const foundStatus = ct.find(item => item.name === status_name);
  if (foundStatus) {
    return foundStatus.label;
  }
  return status_name;
}

export const translateTags = (tag, catalogs) => {
  if (!catalogs['event/tags']) {
    return tag;
  }
  const ct = catalogs['event/tags']
  const foundTag = ct.find(item => item.naturalKey === tag);
  if (foundTag) {
    return foundTag._label;
  }
  return tag;
}

export const translateType = (type, catalogs) => {
  if (!catalogs['event/types']) {
    return type;
  }
  const ct = catalogs['event/types']
  const foundType = ct.find(item => item.naturalKey === type);
  if (foundType) {
    return foundType._label;
  }
  return type;
}

export const translateSeverity = (severity, catalogs) => {
  if (!catalogs['event/severities']) {
    return severity;
  }
  const ct = catalogs['event/severities']
  const foundSeverity = ct.find(item => item.naturalKey === severity);
  return foundSeverity ? foundSeverity._label : severity;
}