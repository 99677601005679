import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, DynamicForm } from 'app/common';
import api from 'api';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';

class SiteGroupModal extends Component {
  constructor(props) {
    super(props);

    this.siteGroupForm = React.createRef();
  }

  isSitegroupNameValid = async (name) => {
    const { selectedCompany } = this.props;
    const res = await api.get(`/Companies/${selectedCompany.id}/sitegroups?filter={"where":{"name":"${name}"}}`);
    if (res.data.length > 0) {
      throw new Error(i18n._(t`Esiste già un gruppo di siti con questo nome`));
    }
    return true;
  }

  render() {
    const { siteGroup, isOpen, toggle, onSubmit } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          <Trans>Nuovo gruppo di siti</Trans>
        </ModalHeader>
        <ModalBody>
          <DynamicForm
            ref={this.siteGroupForm}
            hideButtons
            initialValues={siteGroup}
            fields={[
              { name: 'name', type: 'text', label: <Trans>Nome</Trans>, validation: { required: true, func: name => this.isSitegroupNameValid(name) } },
              { name: 'description', type: 'text', label: <Trans>Descrizione</Trans> },
            ]}
            onSubmit={onSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button marginright="true" color="primary" onClick={() => this.siteGroupForm.current.submit()}><Trans>Salva</Trans></Button>
          <Button color="secondary" onClick={toggle}><Trans>Annulla</Trans></Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SiteGroupModal;
