import React, { Component } from 'react';
import * as d3 from 'd3';
import { isEqual } from 'lodash';
import moment from 'moment';
import { lastValueUpdated, rateGreaterThanAggregation } from '../common';
import { numberToLocaleString } from 'app/utils/formatNumber';


class CircleChart extends Component {
  constructor(props) {
    super(props);

    this.svgRef = React.createRef();
    this.chartRef = React.createRef();

    this.radians = 1.5;
  }


  componentDidMount() {
    this.create();
    this.updateSize();
  }

  componentDidUpdate(prevProps) {
    const { width, height, data, shouldUpdate } = this.props;

    if (shouldUpdate) {
      if (width !== prevProps.width || height !== prevProps.height) {
        this.updateSize();
      }

      if (width !== prevProps.width || height !== prevProps.height || !isEqual(data, prevProps.data) || shouldUpdate !== prevProps.shouldUpdate) {
        this.updateData();
      }
    }
  }

  create = () => {
    const { width, height } = this.props;

    d3.select(this.svgRef.current)
      .attr('width', width)
      .attr('height', height);

    const chart = d3.select(this.chartRef.current)
    chart.append('path')
      .attr('class', 'background')
      .datum({ startAngle: 0, endAngle: Math.PI * this.radians })
      .style('fill', '#ddd')
      .attr('transform', `rotate(${-90 * this.radians})`)

    chart.append('path')
      .attr('class', 'value')
      .datum({ startAngle: 0, endAngle: 0 })
      .style('fill', '#ddd')
      .attr('transform', `rotate(${-90 * this.radians})`)

    chart.append('text')
      .attr('class', 'label')
      .attr('text-anchor', 'middle')
      .style('fill', '#ddd');

    chart.append('text')
      .attr('class', 'unit')
      .attr('text-anchor', 'middle')
      .style('fill', '#ddd');

    // svg.select('.title')
    //   .attr('fill', '#333')
    //   .attr('text-anchor', 'middle');
  }

  updateSize = () => {
    const { width, height } = this.props;
    const margins = { top: 10, right: 20, bottom: 0, left: 20 };
    const chartWidth = width - margins.left - margins.right;
    const chartHeight = height - margins.top - margins.bottom;

    d3.select(this.svgRef.current)
      .attr('width', width)
      .attr('height', height);

    const radius = Math.min(chartWidth / 2, chartHeight / 2);

    const chart = d3.select(this.chartRef.current)
      .attr('transform', `translate(${width / 2}, ${margins.top + chartHeight / 2})`)
      .attr('width', chartWidth)
      .attr('height', chartHeight);


    this.arcPath = d3.arc()
      .outerRadius(radius * 0.9)
      .innerRadius(radius * 0.7);

    chart.select('.background')
      .attr('d', this.arcPath);

    chart.select('.value')
      .attr('d', this.arcPath);

    chart.select('.label')
      .attr('x', 0)
      .attr('y', radius * 0.1)
      .attr('font-size', radius * 0.4);

    chart.select('.unit')
      .attr('x', 0)
      .attr('y', radius * 0.5)
      .attr('font-size', radius * 0.2);

    // svg.select('.title')
    //   .attr('transform', `translate(${width / 2}, ${chartHeight})`);

    // const gr = Math.PI*1.4;
    // chart.append('circle')
    //   .attr('cx', -radius*Math.sin(gr) )
    //   .attr('cy', radius*Math.cos(gr))
    //   .attr('r', 5)

    // chart.append('text')
    //   .attr('text-anchor', 'middle')
    //   .attr('x', -radius *Math.sin(gr) )
    //   .attr('y', radius*Math.cos(gr))
    //   .text('prova')

  }

  arcTween = (newAngle) => {
    return (d) => {
      var interpolate = d3.interpolate(d.endAngle || 0, newAngle);
      return (t) => {
        d.endAngle = interpolate(t);
        return this.arcPath(d);
      };
    };
  }


  updateData = () => {
    const data = this.props.data[0] || {};
    const { widget } = this.props;
    const { period, variables } = widget;
    const { interval, aggregation } = period;


    const value = data.value || { v: 0 };
    const { color, _unit } = data;
    const { max } = data.properties;
    const { t, v } = value;
    const { valueType, physicalQuantity } = data;
    const isBool = valueType === 'binary' || (physicalQuantity === 'state' || physicalQuantity === 'event');
    const greaterBtwRateAndAgg = data.rate ? rateGreaterThanAggregation(data.rate, aggregation) : aggregation;
    const oldValue = !isBool && lastValueUpdated(interval, greaterBtwRateAndAgg, value);
    const decimalDigits = variables && variables[0] && variables[0].decimalDigits;
    const valueLabel = numberToLocaleString(v,decimalDigits);


    const svg = d3.select(this.svgRef.current);
    const chart = d3.select(this.chartRef.current);

    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('background-color', '#fff')
      .style('box-shadow', '2px 2px 4px gray')
      .style('color', '#666')
      .style('font-weight', '600')
      .style('font-size', '0.8rem')
      .style('max-width', '20rem')
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));

    svg.select('.label').style('fill', color).text(oldValue ? `*${valueLabel}` : valueLabel)
      .on('mouseover', () => tooltip.style('visibility', 'visible'))
      .on('mousemove', () => tooltip.style('top', (window.event.pageY - 40) + 'px').style('left', (window.event.pageX + 10) + 'px')
        .style('padding', '0.4rem')
        .text(`Valore del ${moment(t).format('DD/MM/YYYY, HH:mm:ss')}`))
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));
    svg.select('.unit').style('fill', color).text(_unit);

    const newAngle = this.radians * Math.PI * v / max;
    chart.select('.value')
      .style('fill', color)
      .transition()
      .attrTween('d', this.arcTween(newAngle));

    // svg.select('.title').text(label);
  }

  render() {
    return (
      <svg ref={this.svgRef}>
        <text className="title" />
        <g ref={this.chartRef} />
      </svg>
    );
  }
}

export default CircleChart;
