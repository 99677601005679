import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import cn from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import dates from './utils/dates';

const EventCell = (props) => {
  const componentRef = useRef(null);
  //const [eventDetailOpen, setEventDetailOpen] = useState(false);

  const {
    style,
    className,
    event,
    selected,
    isAllDay,
    onSelect,
    onDoubleClick,
    localizer,
    continuesPrior,
    continuesAfter,
    accessors,
    getters,
    children,
    components: { event: Event, eventWrapper: EventWrapper },
    slotStart,
    slotEnd,
  } = props;

  const title = accessors.title(event);
  const tooltip = accessors.tooltip(event);
  const end = accessors.end(event);
  const start = accessors.start(event);
  const allDay = accessors.allDay(event);

  const showAsAllDay = isAllDay || allDay || dates.diff(start, dates.ceil(end, 'day'), 'day') > 1;

  const userProps = getters.eventProp(event, start, end, selected);

  const content = (
    <div className="rbc-event-content" title={tooltip || undefined}>
      {Event ? (
        <Event
          event={event}
          continuesPrior={continuesPrior}
          continuesAfter={continuesAfter}
          title={title}
          isAllDay={allDay}
          localizer={localizer}
          slotStart={slotStart}
          slotEnd={slotEnd}
        />
      ) : (
        <div className="event-title-container">
          <span className="event-title">{title}</span>
          <span className="event-subtitle">{event.plantName}</span>
        </div>
      )}
    </div>
  );

  const handleOnClick = (e) => {
    if (onSelect) {
      onSelect(event, e);
    }
    //setEventDetailOpen(!eventDetailOpen);
  };

  useOnClickOutside(componentRef, () => {
    //setEventDetailOpen(false);
  });

  return (
    <EventWrapper {...props} type="date">
      <div ref={componentRef}>
        <button
          id={`eventid-${event.id}`}
          {...props}
          style={{ ...userProps.style, ...style }}
          className={cn('rbc-event', className, userProps.className, {
            'rbc-selected': selected,
            'rbc-event-allday': showAsAllDay,
            'rbc-event-continues-prior': continuesPrior,
            'rbc-event-continues-after': continuesAfter,
          })}
          onClick={e => handleOnClick(e)}
          onDoubleClick={e => onDoubleClick && onDoubleClick(event, e)}
        >
          {typeof children === 'function' ? children(content) : content}
        </button>
      </div>
    </EventWrapper>
  );
};

EventCell.propTypes = {
  event: PropTypes.object.isRequired,
  slotStart: PropTypes.instanceOf(Date),
  slotEnd: PropTypes.instanceOf(Date),

  selected: PropTypes.bool,
  isAllDay: PropTypes.bool,
  continuesPrior: PropTypes.bool,
  continuesAfter: PropTypes.bool,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object,

  onSelect: PropTypes.func,
  onDoubleClick: PropTypes.func,
}

export default EventCell;
