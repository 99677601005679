import React, { useEffect, useState } from 'react';
import { CardHeader, Card, CardBody } from 'reactstrap';
import { Trans } from '@lingui/macro';
import { DataTable, Button, IconButton } from 'app/common';
import logger from 'app/common/log';
import { Roles } from 'app/utils/auth';
import EventTagModals from '../EventTagModals';
import { translateEventTag } from '../alarmsCommon';

const tableHeaders = [
  {
    property: 'id',
    index: 1,
    dataType: 'string',
    hidden: true
  },
  {
    title: <Trans>Tag</Trans>,
    property: 'name',
    index: 2,
    dataType: 'custom',
    canSearch: true,
    cell: row => (row.predefined ? <strong>{row.name}</strong> : row.name)
  },
  {
    title: <Trans>Azienda</Trans>,
    property: 'companyName',
    index: 3,
    dataType: 'string',
    canSearch: true
  },
  {
    title: <Trans>Ultima modifica</Trans>,
    property: 'updated',
    index: 4,
    dataType: 'string',
    canSearch: true,
    disableSort: true
  }
];

const CompanyEventTags = props => {
  const {
    switchButtons,
    currentRole,
    companies,
    language,
    userSystemRoles,
    userCurrentRoles,
    tags,
    forceReload,
    companyList
  } = props;
  logger.debug('CompanyEventTags props %o', props);
  const [tagsData, setTagsData] = useState();
  const [showTagModal, setShowTagModal] = useState(false);
  const [tagsModalData, setTagsModalData] = useState({});

  const getCompanyById = (companies, id) => companies.find(company => company.id === id) || {};

  const formatTagData = (companies, language) => tag => ({
    ...tag,
    name: translateEventTag(tag, language),
    companyName: getCompanyById(companies, tag.companyId).name,
    predefined: tag.companyId == null,
    updated:
      tag.updater && tag.updated
        ? `${tag.updater.name} ${new Date(tag.updated).toLocaleString()}`
        : ''
  });

  const createNewButton = (currentRole, userSystemRoles, userCurrentRoles) => {
    const showButton =
      userSystemRoles.includes(Roles.SYS) ||
      currentRole === Roles.SUP ||
      currentRole === Roles.ADM ||
      (currentRole === Roles.OPE && userCurrentRoles.includes(Roles.OPE_Commissioning));
    const predefinedGrant = userSystemRoles.includes(Roles.SYS);
    return (
      showButton && (
        <>
          <Button
            size="sm"
            color="primary"
            marginright="true"
            onClick={() => {
              logger.debug('CompanyEventTags newButton click');
              setTagsModalData({ tag: {}, type: 'new', predefinedGrant });
              setShowTagModal(true);
            }}
          >
            <Trans>Nuovo Tag</Trans>
          </Button>
        </>
      )
    );
  };

  const createCRUDButtons = (tag, currentRole, userSystemRoles, userCurrentRoles) => {
    let showEditButton = false;
    let showDeleteButton = false;
    if (tag.predefined) {
      showEditButton = userSystemRoles.includes(Roles.SYS);
      showDeleteButton = userSystemRoles.includes(Roles.SYS);
    } else {
      showEditButton =
        userSystemRoles.includes(Roles.SYS) ||
        currentRole === Roles.SUP ||
        currentRole === Roles.ADM ||
        userCurrentRoles.includes(Roles.OPE_Commissioning);
      showDeleteButton =
        userSystemRoles.includes(Roles.SYS) ||
        currentRole === Roles.SUP ||
        currentRole === Roles.ADM ||
        userCurrentRoles.includes(Roles.OPE_Commissioning);
    }
    const rowButtons = [];
    rowButtons.push({
        icon: "pencil-alt",
        label: <Trans>Modifica</Trans>,
        onClick: () => {
          setTagsModalData({ tag, type: 'edit' });
          setShowTagModal(true);
        }
    });
    rowButtons.push({
      icon: "trash",
      label: <Trans>Elimina</Trans>,
      onClick: () => {
        setTagsModalData({ tag, type: 'delete' });
          setShowTagModal(true);
      }
    });
 
    return showEditButton || showDeleteButton ? (
      rowButtons
    ) : null;
  };

  useEffect(() => {
    logger.debug('CompanyEventTags useEffect ', tags);
    const newTagsData =
      tags &&
      tags.map(formatTagData(companies, language)).sort((a, b) => {
        const aCompanyName = a.companyName ? a.companyName : '0';
        const bCompanyName = b.companyName ? b.companyName : '0';
        const aPlus = `${aCompanyName}${a.name}`.toLowerCase();
        const bPlus = `${bCompanyName}${b.name}`.toLowerCase();
        return bPlus > aPlus ? -1 : 1;
      });
    setTagsData(newTagsData);
    logger.debug('CompanyEventTags setTagsData', newTagsData);
  }, [tags, companies, language]);

  return (
    <div>
      <Card>
        <CardHeader>
          <Trans>Tag di classificazione degli allarmi</Trans>
          {switchButtons}
        </CardHeader>
        <CardBody>
          <DataTable
            keyField="id"
            search
            headers={tableHeaders}
            data={tagsData}
            sortby="name"
            buttons={createNewButton(currentRole, userSystemRoles, userCurrentRoles)}
            rowButtons={row =>
              createCRUDButtons(row, currentRole, userSystemRoles, userCurrentRoles)
            }
            noData={<Trans>Nessun tag.</Trans>}
          />
        </CardBody>
      </Card>
      <EventTagModals
        tags={tagsData}
        isOpen={showTagModal}
        toggle={() => setShowTagModal(false)}
        data={tagsModalData}
        companyList={companyList}
        language={language}
        reload={forceReload}
      />
    </div>
  );
};

export default CompanyEventTags;
