import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from '../../../../../node_modules/react-toastify/index';
import { DynamicForm } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadDomains } from 'ducks/actions/navigation';
import { getUrlFromPath } from 'app/utils/navigation';

const UpdateDomain = (props) => {
    const { history, url, domains, match, loadDomains } = props;
    const { domainid } = match.params;
    const [redirect, setRedirect] = useState(false);
    const [title, setTitle] = useState("");
    const [domainData, setDomainData] = useState({});

    const queryParameters = new URLSearchParams(window.location.search);
    const filter = queryParameters.get("fromFilter");

    const submit = async (values) => {
        const body = {
            "id": domainid,
            "name": values['name'],
            "enableFunctionalities": {},
        }

        const res = await api.patch(`/Domains/${domainid}`, body, { validateStatus: () => true });
        if (res.status >= 200 && res.status < 300) {
            loadDomains();
            toast.success("Dominio aggiornato");
            setTimeout(() => {
                setRedirect(true);
            }, 2000);

        } else if (res.status >= 300 && res.status < 400) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 400 && res.status < 500) {
            toast.warn(res.data.error.message);
        } else if (res.status >= 500) {
            toast.warn(res.data.error.message);
        }
    };

    const cancel = () => {
        history.push(getUrlFromPath('/managedomains') + (filter ? ('?filter=' + filter) : ""));
    };

    useEffect(() => {
        if(domains.length > 0){
            const domain = domains.find(domain => domain.id == domainid);
            if(domain){
                setDomainData(domain);
                setTitle(i18n._(t`Modifica dominio`) + " " + domain.name);
            }else{
                setRedirect(true);
            }
        }
      }, [domains]); 

    if (!domainData || !title)
        return null;

    return (
        <div>
            <ToastContainer></ToastContainer>
            
            { !redirect ? 
                (domainData ? (<DynamicForm
                    card
                    title={title}
                    onSubmit={submit}
                    onCancel={cancel}
                    initialValues={domainData}
                    fields={[
                        {
                            name: 'name',
                            type: 'text',
                            label: <Trans>Nome</Trans>,
                            validation: {
                                required: true, minLength: 3
                            },
                        }
                    ]}
                >
                </DynamicForm>) : null) : (<Redirect to={`${url}` + (filter ? ('?filter=' + filter) : "")} ></Redirect>)}
        </div>
    );
}

UpdateDomain.propTypes = {
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { domains } = state.navigation;
    return {
        domains,
    };
};

const mapDispatchToProps = dispatch => ({
    loadDomains: () => dispatch(loadDomains()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDomain);