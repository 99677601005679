/* eslint-disable no-param-reassign */
/* eslint-disable brace-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-else-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import api from "api";
import moment from "moment-timezone";
import i18n from "app/i18n";
import { t } from "@lingui/macro";

import {
  calcRollingDate,
  getCorrectTextColor,
  getChartTypes,
  CHART_TYPE_CARPET,
  CHART_TYPE_PIE_TIME,
  getRateOptions,
  getDerivataType,
  DATE_FORMAT,
  DATE_REST_SERVER,
  DATE_CHART_TIMESERIES,
  DATE_CHART_BASE,
  min,
  average,
  max,
  sum,
  getPhysicalQuantityFromNaturalKey,
  isDynamicUnit,
  getDefaultPresentationUnit
} from "../../app/pages/databrowsing/Functions";
import { formatNumber } from "../../app/utils/formatNumber";

export const ADD_TAB_SUCCESS = "databrowsing/ADD_TAB_SUCCESS";
export const ADD_TAB_FAILURE = "databrowsing/ADD_TAB_FAILURE";
export const SET_SELECTED_TAB_SUCCESS = "databrowsing/SET_SELECTED_TAB_SUCCESS";
export const SET_SELECTED_TAB_FAILURE = "databrowsing/SET_SELECTED_TAB_FAILURE";
export const DELETE_TAB_SUCCESS = "databrowsing/DELETE_TAB_SUCCESS";
export const DELETE_TAB_FAILURE = "databrowsing/DELETE_TAB_FAILURE";
export const SAVE_TAB_SUCCESS = "databrowsing/SAVE_TAB_SUCCESS";
export const SAVE_TAB_FAILURE = "databrowsing/SAVE_TAB_FAILURE";
export const LOAD_TABS_SUCCESS = "databrowsing/LOAD_TABS_SUCCESS";
export const LOAD_TABS_FAILURE = "databrowsing/LOAD_TABS_FAILURE";

function visibilityOptions(tab, reload) {
  const CHART_TYPES = getChartTypes();
  const selectedChart = CHART_TYPES.find(item => item.id === tab.chartType);

  /* inizializzo variabili */
  if (!tab.configuration) tab.configuration = {};
  if (!tab.seriesVisible) tab.seriesVisible = [];

  /* visibilità variabili basata sulla selezione del grafico */
  let countVariable = 0;
  for (const x of tab.variableSelection) {
    if (x) {
      countVariable += 1;
      x.disable = false;
      if (selectedChart) {
        x.disable = !(
          selectedChart.selectVariableMode === null ||
          countVariable <= selectedChart.selectVariableMode
        );
      }
    }
  }

  /* visibilità del periodo basata sulla selezione del grafico */
  let countDate = 0;
  for (const x of tab.dateSelection) {
    if (x) {
      countDate += 1;
      x.disable = false;
      if (selectedChart) {
        x.disable = !(
          selectedChart.selectDateMode === null ||
          countDate <= selectedChart.selectDateMode
        );
      }
    }
  }

  /* predispongo le varibili attive per facilitare l'utilizzo in reactjs */
  tab.activeVariable = tab.variableSelection.filter(
    x => x && x.disable === false
  );
  tab.activeData = tab.dateSelection.filter(x => x && x.disable === false);

  /* inizializzo l'array di gestione visibilità labels */
  tab.variableSelection.forEach((x, keyVar) => {
    if (tab.seriesVisible[keyVar] === undefined) tab.seriesVisible[keyVar] = [];
    tab.activeData.forEach((y, keyData) => {
      if (tab.seriesVisible[keyVar][keyData] === undefined) {
        tab.seriesVisible[keyVar][keyData] = true;
      }
    });
  });

  /* visibilità granularità */
  if (tab.activeData.length === 0) tab.timeframeEnable = false;
  // sarà possibile selezionare una granularità solo in presenza di un range di date
  else
    tab.timeframeEnable = selectedChart
      ? selectedChart.timeframeSelectable
      : true;

  /* anticipa la compilazione delle options dell'heatMap, l'opeazione non può essere posticipata in quanto l'heatmap necessità di una logica differente di caricamenti dati definita
  sul range temporale che va ad incidere sulla selezione del timefreame selezionato */
  if (selectedChart.id === CHART_TYPE_CARPET) {
    tab.HeatMapXAxisOptions = [];
    tab.HeatMapYAxisOptions = [];
    if (tab.dateSelection && tab.dateSelection.length > 0) {
      const primaryDate = tab.dateSelection[0];
      const endDate = moment(primaryDate.endDate, DATE_FORMAT);
      const startDate = moment(primaryDate.startDate, DATE_FORMAT);
      const days = endDate.diff(startDate, "days");

      if (days >= 6 && days <= 31) {
        tab.HeatMapXAxisOptions = [
          { id: "DP", label: i18n._(t`Giorni`), visible: true }
        ];
        tab.HeatMapYAxisOptions = [
          { id: "1H", label: i18n._(t`Oraria`), visible: true },
          { id: "15M", label: i18n._(t`Quartoraria`), visible: true }
        ];
        tab.heatX = "DP";
        const mapedVisible = tab.HeatMapYAxisOptions.filter(x => x.visible).map(
          x => x.id
        );
        tab.heatY =
          tab.heatY && mapedVisible.indexOf(tab.heatY) >= 0 ? tab.heatY : "1H";
      } else if (days > 30 && days <= 365) {
        tab.HeatMapXAxisOptions = [
          { id: "DP", label: i18n._(t`Giorni`), visible: true },
          { id: "WP", label: i18n._(t`Settimanale`), visible: true }
        ];
        tab.heatX =
          tab.heatX &&
            tab.HeatMapXAxisOptions.filter(x => x.visible)
              .map(x => x.id)
              .indexOf(tab.heatX) >= 0
            ? tab.heatX
            : "DP";
        tab.HeatMapYAxisOptions = [
          { id: "1H", label: i18n._(t`Oraria`), visible: tab.heatX === "DP" },
          {
            id: "D7",
            label: i18n._(t`Giornaliera`),
            visible: tab.heatX === "WP"
          }
        ];
        const mapedVisible = tab.HeatMapYAxisOptions.filter(x => x.visible).map(
          x => x.id
        );
        tab.heatY =
          tab.heatY && mapedVisible.indexOf(tab.heatY) >= 0
            ? tab.heatY
            : mapedVisible.pop();
      } else if (days > 365) {
        tab.HeatMapXAxisOptions = [
          { id: "WP", label: i18n._(t`Settimanale`), visible: true },
          { id: "MP", label: i18n._(t`Mensile`), visible: true }
        ];
        tab.heatX =
          tab.heatX &&
            tab.HeatMapXAxisOptions.filter(x => x.visible)
              .map(x => x.id)
              .indexOf(tab.heatX) >= 0
            ? tab.heatX
            : "WP";
        tab.HeatMapYAxisOptions = [
          {
            id: "D7",
            label: i18n._(t`Giorno settimana`),
            visible: tab.heatX === "WP"
          },
          {
            id: "D31",
            label: i18n._(t`Giorno mese`),
            visible: tab.heatX === "MP"
          }
        ];
        const mapedVisible = tab.HeatMapYAxisOptions.filter(x => x.visible).map(
          x => x.id
        );
        tab.heatY =
          tab.heatY && mapedVisible.indexOf(tab.heatY) >= 0
            ? tab.heatY
            : mapedVisible.pop();
      } else {
        tab.HeatMapXAxisOptions = [];
        tab.heatX = null;
        tab.HeatMapYAxisOptions = [];
        tab.heatY = null;
      }

      /* determina il timeframe relativo all'asse delle Y selezionato */
      switch (tab.heatY) {
        case "15M":
          tab.configuration.timeframeSelected = "15m";
          break;
        case "1H":
          tab.configuration.timeframeSelected = "1h";
          break;
        default:
          tab.configuration.timeframeSelected = "1dy";
          break;
      }
    }
  }

  return reload;
}

function getDefaultTimeframeSelected(startDate, endDate) {
  const a = moment(endDate, DATE_FORMAT);
  const b = moment(startDate, DATE_FORMAT);
  const days = a.diff(b, "days");

  if (days >= 0 && days <= 1) {
    return { days, aggr: "5m" };
  } else if (days > 1 && days <= 7) {
    return { days, aggr: "1h" };
  } else if (days > 7 && days <= 31) {
    return { days, aggr: "1dy" };
  } else if (days > 31 && days <= 365) {
    return { days, aggr: "1wk" };
  } else if (days > 365) {
    return { days, aggr: "1mo" };
  } else {
    return { days: 0, aggr: "raw" };
  }
}

function rebuiltTimeframe(tab, reload) {
  if (tab.dateSelection.length > 0) {
    /* prendi il periodo di riferimento per valutare la sua ampiezza */
    const primaryDate = tab.dateSelection[0];
    const defTimeFrames = getDefaultTimeframeSelected(
      primaryDate.startDate,
      primaryDate.endDate
    );

    /* elabora timeframe selezionabili in base ai giorni del periodo di riferimento */
    const rateFiltered = getRateOptions()
      .filter(el => el.chartAvaible.indexOf(tab.chartType) >= 0)
      .map(el => ({ ...el })); // Clona costante per evitare passagio per riferimento
    if (tab.chartType === CHART_TYPE_PIE_TIME)
      tab.timeframeAvaible = rateFiltered.filter(
        x =>
          defTimeFrames.days >= x.dayPieRange[0] &&
          (x.dayPieRange[1] === null || defTimeFrames.days <= x.dayPieRange[1])
      );
    else
      tab.timeframeAvaible = rateFiltered.filter(
        x =>
          defTimeFrames.days >= x.dayRange[0] &&
          (x.dayRange[1] === null || defTimeFrames.days <= x.dayRange[1])
      );

    /* controlla integrità della selezione oppure cambia valore di default */
    if (
      tab.configuration.timeframeSelected === undefined ||
      !tab.timeframeAvaible.find(
        f => f.id === tab.configuration.timeframeSelected
      )
    ) {
      tab.configuration.timeframeSelected =
        tab.chartType === CHART_TYPE_PIE_TIME ? "none" : defTimeFrames.aggr;
      reload = true;
    }
    const rate = getRateOptions().find(
      g => g.id === tab.configuration.timeframeSelected
    );
    tab.xFormat = rate ? rate.xFormat : "";
  } else {
    tab.timeframeAvaible = [];
  }

  return reload;
}

function createAggregatedSequence(
  timeSeries,
  data,
  rateSelected,
  aggrCb,
  valueType,
  needPrevPeriod = false
) {
  const lowDataSeries = data.map(value => ({
    t: moment(value.t, DATE_REST_SERVER),
    v: value.v
  }));
  const groupedData = [];
  timeSeries.forEach(Mdata => {
    let tStart;
    let tEnd;

    /* la forma aggregata per i massimi e minimi devono tener conto del periodo precedente sotto la granularità giornaliera, tutte le altre lavorano sullo stesso timestamp */
    if (rateSelected.mins >= 1440 || !needPrevPeriod) {
      tStart = moment(Mdata, DATE_REST_SERVER);
      tEnd = moment(Mdata, DATE_REST_SERVER).add(
        rateSelected.seriesStep,
        rateSelected.seriesType
      );
    } else {
      tStart = moment(Mdata, DATE_REST_SERVER).subtract(
        rateSelected.seriesStep,
        rateSelected.seriesType
      );
      tEnd = moment(Mdata, DATE_REST_SERVER);
    }

    const tmpGroupRecord = { tStart, v: [] };

    lowDataSeries.forEach(value => {
      if (value.t.isSameOrAfter(tStart) && value.t.isBefore(tEnd)) {
        tmpGroupRecord.v.push(value.v);
      }
    });

    groupedData.push(tmpGroupRecord);
  });

  /* applico algoritmo di aggregazione associato alla serie, nel caso di granularità giornaliera in poi ritorna la serie aggregat, altrimenti slitta avanati di un perido per le serie calcolate sul peridoo precedente */
  if (rateSelected.mins >= 1440 || !needPrevPeriod)
    return groupedData.map(det => ({
      x: det.tStart.format(DATE_CHART_TIMESERIES),
      y: aggrCb(det.v, valueType)
    }));
  else
    return groupedData.map(det => ({
      x: det.tStart
        .add(rateSelected.seriesStep, rateSelected.seriesType)
        .format(DATE_CHART_TIMESERIES),
      y: aggrCb(det.v, valueType)
    }));
}

function normalizeSequence(data) {
  return data.map(det => ({ x: det.t, y: det.v }));
}

async function executeAllPromises(promises) {
  const resolvingPromises = promises.map(
    promise =>
      new Promise(resolve => {
        const payload = new Array(2);
        promise
          .then(result => {
            payload[0] = result;
          })
          .catch(error => {
            payload[1] = error;
          })
          .then(() => {
            resolve(payload);
          });
      })
  );

  const errors = [];
  const results = [];

  return Promise.all(resolvingPromises).then(items => {
    items.forEach((payload, idx) => {
      if (payload[1]) {
        errors[idx] = payload[1];
      } else {
        results[idx] = payload[0];
      }
    });

    return {
      errors,
      results
    };
  });
}

async function loadRawDataFunc(dispatch, domain, catalogs, tab) {
  console.log('loadRawDataFunc ');
  const DERIVATE_TYPE = getDerivataType();
  const rawdatalist = [];

  /* determino il timeframe con granularità più bassa a livello di tab.  */
  const avaibleTF = tab.timeframeAvaible;
  const minRateWorckspace = avaibleTF.length > 0 ? avaibleTF[0].mins : 15;

  /* determino la granularità che mi serverià per definire l'aggregazione */
  const rateSelected = tab.timeframeAvaible.find(
    x => x.id === tab.configuration.timeframeSelected
  );
  if (rateSelected === undefined || tab.variableSelection === undefined)
    return [];

  /* aggiungi i parametri necessarei alla configurazione della variabile */
  tab.variableSelection.forEach(v => {
    // console.log(v);
    // const selectedOp = domain.observableproperties.find(f => f.id == v.id);
    // console.log(domain);
  });

  /* richiedi i dati diretti + le granularità più basse per eventuali variabili derivate */
  const RATE = getRateOptions();
  const compileDataList = (idSerie, idCorrente, timeframe, timezone) => {
    tab.dateSelection
      .filter(f => !!f && !f.disable)
      .forEach(p => {
        /* se è un periodo rolling adegua le date di riferimento */
        const { startDate, endDate } = calcRollingDate(p);
        p.refStartDate = startDate;
        p.refEndDate = endDate;
        const finded = rawdatalist.find(
          f =>
            f.id === idSerie &&
            f.timeframe === timeframe &&
            f.startDate === startDate &&
            f.endDate === endDate
        );
        if (finded) finded.listIds.push(idCorrente);
        else {
          let timeframeDet;

          /* seleziona la granularità di default in base al range temporale se il timeframe non è stato specificato */
          if (timeframe === "none") {
            const defTimeFrames = getDefaultTimeframeSelected(
              startDate,
              endDate
            );
            timeframeDet = RATE.find(g => g.id === defTimeFrames.aggr);
          } else {
            timeframeDet = RATE.find(g => g.id === timeframe);
          }

          const usedTimezone =
            moment.tz.zone(timezone) !== null ? timezone : "Europe/rome";
          const start = moment.tz(startDate, DATE_FORMAT, usedTimezone);
          const end = moment.tz(endDate, DATE_FORMAT, usedTimezone);

          /* per le granularità inferiori al giorno aggiungere un campione in quanto contengono l'informazione aggregata del campione precedente e il giorno successivo */
          const timeframeMins =
            timeframeDet && timeframeDet.mins ? timeframeDet.mins : 0;
          if (
            timeframeDet &&
            timeframeDet.mins >= 0 &&
            timeframeDet.mins < 1440
          ) {
            start.add(timeframeDet.mins, "minutes");
            end.add(1, "days");
          }

          rawdatalist.push({
            id: idSerie,
            listIds: [idCorrente],
            timezone: usedTimezone,
            timeframe,
            timeframeMins,
            startDate,
            endDate,
            url: api.get(
              `/ObservedProperties/${idSerie}/${timeframeDet.id
              }/timeSeries/${start.format()}/${end.format()}`
            )
          });
        }
      });
  };

  /* Per ciascuna variabile identifica granularità data da, data a */
  tab.variableSelection.forEach(v => {
    if (v.derivataType != undefined) {
      const detDerivata = DERIVATE_TYPE.find(x => x.id === v.derivataType);

      /* se la variabile variabile derivata richiede una serie numerica con granularità più piccola procedi con quanto segue */
      if (detDerivata.needLowTF) {
        try {
          const variabileSorgente = tab.variableSelection.find(
            x => x.id == v.serieRiferimento.value
          );
          const filterVar = RATE.find(g => g.id === variabileSorgente.rate)
            .mins;
          v.timeframe = RATE.find(
            g =>
              g.mins ===
              (filterVar > minRateWorckspace ? filterVar : minRateWorckspace)
          ).id;
          v.valueType = variabileSorgente.valueType;
          compileDataList(
            variabileSorgente.id,
            v.id,
            v.timeframe,
            variabileSorgente.timezone
          );
        } catch (err) {
          console.error(`Errore nel calcolo di granularità inferirori: ${err}`);
        }
      } else if (v.serieRiferimento && !Array.isArray(v.serieRiferimento)) {
        try {
          const variabileSorgente = tab.variableSelection.find(
            x => x.id == v.serieRiferimento.value
          );
          v.timeframe = rateSelected.id;
          v.valueType = variabileSorgente.valueType;
          compileDataList(
            variabileSorgente.id,
            v.id,
            v.timeframe,
            variabileSorgente.timezone,
            v.param1 || 0
          );
        } catch (err) {
          console.error(
            `Errore nell'elaborazione della variabile derivata con un serie di riferimento: ${err}`
          );
        }
      } else if (v.serieRiferimento && Array.isArray(v.serieRiferimento)) {
        v.timeframe = rateSelected.id;
        v.serieRiferimento.forEach(s => {
          try {
            const variabileSorgente = tab.variableSelection.find(
              x => x.id == s.value
            );
            v.valueType = variabileSorgente.valueType;
            compileDataList(
              variabileSorgente.id,
              v.id,
              v.timeframe,
              variabileSorgente.timezone
            );
          } catch (err) {
            console.error(`Errore nell'elaborazione della variabile derivata con più serie di riferimento: ${err}`);
          }
        });
      }

      if (v.serieComparata) {
        v.serieComparata.timeframe = rateSelected.id;
      }
    } else {
      v.timeframe = rateSelected.id;
      compileDataList(v.id, v.id, v.timeframe, v.timezone);
    }
  });

  const addDinamicUM = async (v, dataset) => {
    if (isDynamicUnit(catalogs, v.physicalQuantity)) {

      const dataList = dataset.dataAggr
        .filter(s => s.y != null && !Number.isNaN(s.y))
        .map(s => s.y);

      const maxRef = Math.round(max(dataList) * 100) / 100;
      const minRef = Math.round(min(dataList) * 100) / 100;
      const maxValue = Math.max(Math.abs(maxRef), Math.abs(minRef));

      /* loggica conversione dinamica tempo */
      if (v.unit === "s") {
        v.unit = "s";
        if (Math.abs(maxValue) > 900 && Math.abs(maxValue) <= 3600) {
          v.unit = "min";
        } else if (Math.abs(maxValue) > 3600) {
          v.unit = "h";
        }
        v.exactoConversionFactor = getConvertionFactorFrmUnit(catalogs, v.unit);
      }

      /* loggica conversione dinamica massa */
      if (v.unit === "kg") {
        v.unit = "g";
        if (Math.abs(maxValue) > 1 && Math.abs(maxValue) <= 1000) {
          v.unit = "kg";
        } else if (Math.abs(maxValue) > 1000) {
          v.unit = "t";
        }
        v.exactoConversionFactor = getConvertionFactorFrmUnit(catalogs, v.unit);
      }
    }
  };

  const addQuickStats = (v, dataset) => {
    const dataList = dataset.dataAggr
      .filter(s => s.y != null && !Number.isNaN(s.y))
      .map(s => s.yScaled);
    dataset.QsTotale = v.totale ? sum(dataList) : false;
    dataset.QsAvg = v.media ? average(dataList) : false;
    dataset.QsMax = v.massimo ? max(dataList) : false;
    dataset.QsMin = v.minimo ? min(dataList) : false;
  };

  const addFormattedNumber = (unit, dataset, scaleFactor, valueType) => {
    dataset.forEach(det => {
      /* aggiungi i valori offset a unità specifiche */
      let offset = 0;
      if (unit === "K") offset = 273.15;
      else if (unit === "°F") offset = 32;

      det.yScaled = formatNumber(det.y, offset, scaleFactor, valueType);
      det.yFormatted = formatNumber(det.y, offset, scaleFactor, valueType);
    });
  };

  const myPromises = rawdatalist.map(x => x.url);
  return executeAllPromises(myPromises).then(items => {
    const seriesReturn = [];

    rawdatalist.forEach((rawDet, key) => {
      if (
        items.results[key] &&
        items.results[key].data.timeSeries &&
        Array.isArray(items.results[key].data.timeSeries)
      ) {
        rawDet.timeseriesUnit = items.results[key].data.unit; // riporta la unit con il quale riceviamo i dati dal campo
        rawDet.timeseriesNKey = items.results[key].data.unitNKey; // riporta la natural key con il quale riceviamo i dati dal campo
        rawDet.timeSeries = items.results[key].data.timeSeries.map(det => ({
          t:
            rawDet.timeframeMins < 1440
              ? moment.tz(det.t, rawDet.timezone).format(DATE_CHART_TIMESERIES)
              : moment.tz(det.t, rawDet.timezone).format(DATE_CHART_BASE),
          v: det.v
        }));
      } else {
        rawDet.timeSeries = [];
      }
      /* adeguamento della timseries all'orario locale dell'utente */
      rawDet.timeSeriesErrors = items.errors[key]
        ? "Errore nella richiesta della variabile, probile indisponibilità di dati per il periodo e aggregazione selezionata"
        : null;
    });

    /* Le VM, OP possono riportare direttamente i valori verso il Chart */
    tab.variableSelection
      .filter(d => d.derivataType === undefined)
      .forEach(v => {
        rawdatalist
          .filter(s => s.id === v.id && s.timeframe == v.timeframe)
          .forEach(rawdet => {
            /* valuto integrità sulle grandezze tra timeseries e op seleizonata */
            // const listPhisicalQuantity = getPhysicalQuantityFromUnit(catalogs, rawdet.timeseriesUnit);
            const listPhisicalQuantity = getPhysicalQuantityFromNaturalKey(
              catalogs,
              rawdet.timeseriesNKey
            );
            v.unit =
              listPhisicalQuantity &&
                listPhisicalQuantity.indexOf(v.physicalQuantity) >= 0
                ? v.symbol
                : rawdet.timeseriesUnit;

            /* ATTENZIONE, NON VARIARE LA SERIE DEGLI STEP */

            /* STEP1: crea la serie aggregata */
            rawdet.dataAggr = rawdet.timeSeries.map(det => ({
              x: det.t,
              y: det.v
            }));

            /* STEP2: valuta un moedica dinamica della UNIT e FATTORE di conversione per la variabile */
            addDinamicUM(v, rawdet);

            /* STEP3: aggiungi la notazione all'array di richiesta informazioni al backend per essere ricuparati nella riposta della promise verso il BE */
            rawdet.exactoConversionFactor = v.exactoConversionFactor;
            rawdet.unit = v.unit; // serve per i calcoli sulla serie numerica
            rawdet.customYlabel = v.unit; // serve all corretta notazione dell'asse Y
            rawdet.valueType = v.valueType;

            /* STEP4: aggiungi formattazione e quickstats */
            addFormattedNumber(
              rawdet.unit,
              rawdet.dataAggr,
              rawdet.exactoConversionFactor,
              rawdet.valueType
            );
            addQuickStats(v, rawdet);
            seriesReturn.push(rawdet);
          });
      });

    /* creo le serie riferite alle variabili derivate on-fly basate sulla serie corrente */
    tab.variableSelection
      .filter(d => d.derivataType !== undefined)
      .forEach(v => {
        const detDerivata = DERIVATE_TYPE.find(x => x.id === v.derivataType);
        if (detDerivata === undefined) return;

        switch (detDerivata.id) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 14:
            try {
              const data = tab.activeData[0];
              const serieRiferimento = rawdatalist.find(
                s =>
                  s.id === v.serieRiferimento.value &&
                  s.startDate === data.refStartDate &&
                  s.endDate === data.refEndDate
              );
              if (!serieRiferimento) break;

              /* applico la logica riferita alla selezione di una singola variabile ereditando tutte le sue informazioni e modificando il dato aggregato della variabile d'interesse */
              const rawdet = rawdatalist.find(
                s =>
                  s.listIds.indexOf(v.id) >= 0 &&
                  s.timeframe == v.timeframe &&
                  s.startDate === data.refStartDate &&
                  s.endDate === data.refEndDate
              );
              if (!rawdet) break;

              const derivatedSerie = {
                ...rawdet,
                id: v.id,
                customYlabel: detDerivata.customYlabel
                  ? detDerivata.customYlabel
                  : v.unit,
                unit: detDerivata.customUnit ? detDerivata.customUnit : v.unit
              };

              /* tutti i calcoli verrano elaborati sull'unica variabile di riferimento e corrispettiva timeseries */
              const timeSeries = serieRiferimento.timeSeries.map(det => det.t);

              /* creo un'aggregazione solamente se riechiesta dalla tipologia di Variabile derivata da una granularità inferiore altrimenti il dato aggregato equivale al dato della serie */
              if (detDerivata.id == 1 || detDerivata.id == 2) {
                derivatedSerie.dataAggr = detDerivata.postCb(
                  createAggregatedSequence(
                    timeSeries,
                    derivatedSerie.timeSeries,
                    rateSelected,
                    detDerivata.aggrCb,
                    serieRiferimento.valueType,
                    true
                  )
                );
              } else if (detDerivata.id == 14) {
                derivatedSerie.dataAggr = detDerivata.postCb(
                  normalizeSequence(derivatedSerie.timeSeries),
                  v.param1
                );
              } else {
                derivatedSerie.dataAggr = detDerivata.postCb(
                  normalizeSequence(derivatedSerie.timeSeries)
                );
              }
              addDinamicUM(v, derivatedSerie);

              /* aggiungi formattazione valori */
              if (detDerivata.formatNumber) {
                addFormattedNumber(
                  derivatedSerie.unit,
                  derivatedSerie.dataAggr,
                  serieRiferimento.exactoConversionFactor,
                  serieRiferimento.valueType
                );
              }
              addQuickStats(v, derivatedSerie);

              /* aggiungi la nuova serie alla lista di serie da tornare */
              seriesReturn.push(derivatedSerie);
            } catch (err) {
              console.warn(err);
            }
            break;

          case 7:
          case 8:
          case 16:
            /* applico la logica per una selezione multipla di variabili */
            /* è necessario creare N serie quanti sono i periodi selezionati e aggregare le variabili delle serie secondo la logica definita */
            tab.dateSelection
              .filter(f => !!f && !f.disable)
              .forEach(data => {
                try {
                  /* definisci la unit basandoti sulle unit delle variabili di riferimento */
                  const varUnits = v.serieRiferimento
                    .map(rif =>
                      tab.variableSelection.find(d => d.id === rif.value) !=
                        undefined
                        ? tab.variableSelection.find(d => d.id === rif.value)
                          .unit
                        : ""
                    )
                    .filter((v, i, a) => a.indexOf(v) === i && v !== "");

                  const allUnits = v.serieRiferimento.map(rif => tab.variableSelection.find(d => d.id === rif.value) != undefined ?
                    tab.variableSelection.find(d => d.id === rif.value).unit : "").filter(v => v !== "");

                  /* prendi la prima variabile di riferimento per estrarre la timeline di riferimento, si deduce che le altre abbiano lo stesso range temporale */
                  const serieRiferimento = rawdatalist.find(
                    s =>
                      s.id === v.serieRiferimento[0].value &&
                      s.startDate === data.refStartDate &&
                      s.endDate === data.refEndDate &&
                      s.timeframe == v.timeframe
                  );

                  const derivatedSerie = {
                    ...serieRiferimento,
                    id: v.id,
                    startDate: data.refStartDate,
                    endDate: data.refEndDate,
                    timeSeries: [],
                    dataAggr: [],
                    unit: detDerivata.id === 16 ? allUnits.join(" * ") : varUnits.join("/"),
                    customYlabel: detDerivata.customYlabel
                      ? detDerivata.customYlabel
                      : detDerivata.id === 16 ? allUnits.join(" * ") : varUnits.join("/")
                  };

                  /* aggiungi in maniera indescriminata tutti i valori al set di data facente parte della selezione multipla della variabile derivata */
                  rawdatalist
                    .filter(
                      s =>
                        v.serieRiferimento
                          .map(rif => rif.value)
                          .indexOf(s.id) >= 0 &&
                        s.startDate === data.refStartDate &&
                        s.endDate === data.refEndDate
                    )
                    .forEach(rawdet => {
                      rawdet.timeSeries.forEach(serieData => {
                        derivatedSerie.timeSeries.push(serieData);
                      });
                    });

                  /* crea dato aggregato delle serie originale (le opzioni di selezione multipla non presentano mai logiche di TF più)
            TODO: RIMNUOVERE IL CONTROLLO CUMULATIVE INSTANTNAEEE */
                  const aggregated = createAggregatedSequence(
                    serieRiferimento.timeSeries.map(det => det.t),
                    derivatedSerie.timeSeries,
                    rateSelected,
                    detDerivata.aggrCb,
                    serieRiferimento.valueType
                  );
                  derivatedSerie.dataAggr = detDerivata.postCb(aggregated);
                  addDinamicUM(v, derivatedSerie);

                  /* aggiungi formattazione valori */
                  if (detDerivata.formatNumber) {
                    let convFactor = serieRiferimento.exactoConversionFactor;
                    if (detDerivata.id === 16) {
                      let product = 1;
                      allUnits.forEach(unit => {
                        product *= getConvertionFactorFrmUnit(catalogs, unit);
                      });
                      convFactor = product;
                    }
                    addFormattedNumber(
                      derivatedSerie.unit,
                      derivatedSerie.dataAggr,
                      convFactor,
                      serieRiferimento.valueType
                    );
                  }
                  addQuickStats(v, derivatedSerie);

                  /* aggiungi la nuova serie alla lista di serie da tornare */
                  seriesReturn.push(derivatedSerie);
                } catch (err) {
                  console.warn(err);
                }
              });
            break;

          case 15:
            /* applico la logica per una selezione multipla di variabili */
            /* è necessario creare N serie quanti sono i periodi selezionati e aggregare le variabili delle serie secondo la logica definita */
            tab.dateSelection
              .filter(f => !!f && !f.disable)
              .forEach(data => {
                try {
                  /* definisci la unit basandoti sulle unit delle variabili di riferimento */
                  const varUnits = v.serieRiferimento
                    .map(rif =>
                      tab.variableSelection.find(d => d.id === rif.value) !=
                        undefined
                        ? tab.variableSelection.find(d => d.id === rif.value)
                          .unit
                        : ""
                    )
                    .filter((v, i, a) => a.indexOf(v) === i && v !== "");

                  /* prendi la prima variabile di riferimento per estrarre la timeline di riferimento, si deduce che le altre abbiano lo stesso range temporale */
                  const serieRiferimento = rawdatalist.find(
                    s =>
                      s.id === v.serieRiferimento[0].value &&
                      s.startDate === data.refStartDate &&
                      s.endDate === data.refEndDate &&
                      s.timeframe == v.timeframe
                  );

                  const derivatedSerie = {
                    ...serieRiferimento,
                    id: v.id,
                    startDate: data.refStartDate,
                    endDate: data.refEndDate,
                    timeSeries: [],
                    dataAggr: [],
                    unit: varUnits.join("/"),
                    customYlabel: detDerivata.customYlabel
                      ? detDerivata.customYlabel
                      : varUnits.join("/")
                  };

                  /* aggiungi in maniera indescriminata tutti i valori al set di data facente parte della selezione multipla della variabile derivata */
                  rawdatalist
                    .filter(
                      s =>
                        v.serieRiferimento
                          .map(rif => rif.value)
                          .indexOf(s.id) >= 0 &&
                        s.startDate === data.refStartDate &&
                        s.endDate === data.refEndDate
                    )
                    .forEach(rawdet => {
                      rawdet.timeSeries.forEach(serieData => {
                        derivatedSerie.timeSeries.push(serieData);
                      });
                    });

                  /* aggiungi i valori negativi per la seconda seriele */
                  rawdatalist
                    .filter(
                      s =>
                        v.serieComparata.map(rif => rif.value).indexOf(s.id) >=
                        0 &&
                        s.startDate === data.refStartDate &&
                        s.endDate === data.refEndDate
                    )
                    .forEach(rawdet => {
                      rawdet.timeSeries.forEach(serieData => {
                        derivatedSerie.timeSeries.push({
                          t: serieData.t,
                          v: -serieData.v
                        });
                      });
                    });

                  /* crea dato aggregato delle serie originale (le opzioni di selezione multipla non presentano mai logiche di TF più)  */
                  const aggregated = createAggregatedSequence(
                    serieRiferimento.timeSeries.map(det => det.t),
                    derivatedSerie.timeSeries,
                    rateSelected,
                    detDerivata.aggrCb,
                    serieRiferimento.valueType
                  );
                  derivatedSerie.dataAggr = detDerivata.postCb(aggregated);
                  addDinamicUM(v, derivatedSerie);

                  /* aggiungi formattazione valori */
                  if (detDerivata.formatNumber) {
                    addFormattedNumber(
                      derivatedSerie.unit,
                      derivatedSerie.dataAggr,
                      serieRiferimento.exactoConversionFactor,
                      serieRiferimento.valueType
                    );
                  }
                  addQuickStats(v, derivatedSerie);

                  /* aggiungi la nuova serie alla lista di serie da tornare */
                  seriesReturn.push(derivatedSerie);
                } catch (err) {
                  console.warn(err);
                }
              });

            break;

          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
            /* Applico la logica per una selezione comparata delle variabili */
            tab.dateSelection
              .filter(f => !!f)
              .forEach(data => {
                try {
                  /* aggiungi in maniera indescriminata tutti i valori al set di data facente parte della selezione multipla della variabile derivata */
                  const serieRiferimento = rawdatalist.find(
                    s =>
                      s.id === v.serieRiferimento.value &&
                      s.startDate === data.refStartDate &&
                      s.endDate === data.refEndDate &&
                      s.timeframe == v.timeframe
                  );
                  const serieComparata = rawdatalist.find(
                    s =>
                      s.id === v.serieComparata.value &&
                      s.startDate === data.refStartDate &&
                      s.endDate === data.refEndDate &&
                      s.timeframe == v.timeframe
                  );

                  if (detDerivata.formatNumber) {
                    addFormattedNumber(
                      serieRiferimento.unit,
                      serieRiferimento.dataAggr,
                      serieRiferimento.exactoConversionFactor,
                      serieRiferimento.valueType
                    );
                    addFormattedNumber(
                      serieComparata.unit,
                      serieComparata.dataAggr,
                      serieComparata.exactoConversionFactor,
                      serieComparata.valueType
                    );
                  }

                  /* definisci la unit */
                  let unit;
                  if (serieRiferimento.unit !== serieComparata.unit) {
                    unit = `${serieRiferimento.unit} / ${serieComparata.unit}`;
                  } else {
                    unit = serieRiferimento.unit;
                  }

                  const derivatedSerie = {
                    id: v.id,
                    unit: detDerivata.customUnit
                      ? detDerivata.customUnit
                      : unit,
                    startDate: data.refStartDate,
                    endDate: data.refEndDate,
                    timeSeries: [],
                    dataAggr: [],
                    customYlabel: detDerivata.customYlabel
                      ? detDerivata.customYlabel
                      : unit
                  };

                  /* crea dato aggregato delle serie originale (le opzioni di selezione multipla non presentano mai logiche di TF più) */
                  if (serieRiferimento && serieComparata) {
                    derivatedSerie.dataAggr = detDerivata.postCb(
                      serieComparata.dataAggr,
                      serieRiferimento.dataAggr
                    );
                    addDinamicUM(v, derivatedSerie);
                    addQuickStats(v, derivatedSerie);
                    seriesReturn.push(derivatedSerie);
                  }
                } catch (err) {
                  console.warn(err);
                }
              });

            break;
          default:
            break;
        }
      });

    tab.seriesActive = seriesReturn
      .filter(x => {
        const findVariabile = tab.activeVariable.findIndex(
          detVar => detVar.id === x.id
        );
        const findDate = tab.activeData.findIndex(
          detVar =>
            detVar.refStartDate === x.startDate &&
            detVar.refEndDate === x.endDate
        );
        return findVariabile >= 0 && findDate >= 0;
      })
      .sort((a, b) => {
        const posA = tab.variableSelection.findIndex(f => f.id === a.id);
        const posB = tab.variableSelection.findIndex(f => f.id === b.id);

        if (posA < posB) return -1;
        if (posA > posB) return 1;
        return 0;
      });

    return tab;
  });
}

async function compileTabData(dispatch, domain, catalogs, tab, reload = true) {
  reload = visibilityOptions(tab, reload);
  reload = rebuiltTimeframe(tab, reload);
  return reload && tab.activeData.length > 0 && tab.activeVariable.length > 0
    ? loadRawDataFunc(dispatch, domain, catalogs, tab)
    : true;
}

/* ===== ADD TAB ACTIONS ===== */

export const addTabSuccess = (
  selectedAsset,
  selectedAssetgroup,
  selectedSitegroup,
  selectedSite,
  selectedCompany,
  selectedDomain,
  name,
  variableSelection,
  dateSelection,
  configuration,
  order,
  offlineId
) => ({
  type: ADD_TAB_SUCCESS,
  selectedAsset,
  selectedAssetgroup,
  selectedSitegroup,
  selectedSite,
  selectedCompany,
  selectedDomain,
  name,
  variableSelection,
  dateSelection,
  configuration,
  order,
  offlineId
});

export const addTabFailure = error => ({
  type: ADD_TAB_FAILURE,
  payload: error
});

export const addTab = (
  name,
  variableSelection = [],
  dateSelection = [],
  configuration = {}
) => async (dispatch, getState) => {
  const {
    selectedAsset,
    selectedAssetgroup,
    selectedCompany,
    selectedDomain,
    selectedSitegroup,
    selectedSite
  } = getState().navigation;
  const offlineId = new Date().getTime();
  dispatch(
    addTabSuccess(
      selectedAsset,
      selectedAssetgroup,
      selectedSitegroup,
      selectedSite,
      selectedCompany,
      selectedDomain,
      name,
      variableSelection,
      dateSelection,
      configuration,
      getState().databrowsing.tabs.length,
      offlineId
    )
  );
  return getState().databrowsing.tabs.find(x => x.offlineId == offlineId);
};

export const addTabData = tabId => async (dispatch, getState) => {
  const {
    domain,
    catalogs,
    databrowsing,
    selectedAsset,
    selectedAssetgroup,
    selectedCompany,
    selectedDomain,
    selectedSite,
    selectedSitegroup
  } = getState();
  const tab = databrowsing.tabs.find(f => f.offlineId === tabId);
  dispatch(setSelectedTab(tabId));

  if (tab) {
    await compileTabData(dispatch, domain, catalogs, tab);
    return dispatch(
      saveTabSuccess(
        tab.offlineId,
        tab,
        selectedAsset,
        selectedAssetgroup,
        selectedCompany,
        selectedDomain,
        selectedSite,
        selectedSitegroup
      )
    );
  } else {
    return false;
  }
};

/* ===== SET SELECTED TAB ACTIONS ===== */

export const setSelectedTabSuccess = tabId => ({
  type: SET_SELECTED_TAB_SUCCESS,
  tabId
});

export const setSelectedTabFailure = error => ({
  type: SET_SELECTED_TAB_FAILURE,
  payload: error
});

export const setSelectedTab = tabId => async dispatch => {
  dispatch(setSelectedTabSuccess(tabId));
};

/* ===== SAVE TAB ACTIONS ===== */

export const saveTabSuccess = (
  offilineId,
  tab,
  selectedAsset,
  selectedAssetgroup,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
) => ({
  type: SAVE_TAB_SUCCESS,
  offilineId,
  tab,
  selectedAsset,
  selectedAssetgroup,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
});

export const saveTabFailure = error => ({
  type: SAVE_TAB_FAILURE,
  payload: error
});

export const saveTab = (tab, reload = true) => async (dispatch, getState) => {
  const {
    selectedAsset,
    selectedAssetgroup,
    selectedCompany,
    selectedDomain,
    selectedSite,
    selectedSitegroup
  } = getState().navigation;

  console.log('saveTab ', tab, reload);
  const { domain, catalogs } = getState();
  const { selectedTabId } = getState().databrowsing;

  /* aggiungi elaborazione timeframe avviabili prima di notificare il caricamento */
  await compileTabData(dispatch, domain, catalogs, tab, reload);
  dispatch(
    saveTabSuccess(
      tab.offlineId,
      tab,
      selectedAsset,
      selectedAssetgroup,
      selectedCompany,
      selectedDomain,
      selectedSite,
      selectedSitegroup
    )
  );

  /* logica di salvataggio tab */
  if (tab.lock === true) {
    let urlTab = "";
    if (selectedAsset) urlTab = `/Sites/${selectedSite.id}/dataBrowsingTabs`;
    else if (selectedAssetgroup)
      urlTab = `/Sites/${selectedSite.id}/dataBrowsingTabs`;
    else if (selectedSitegroup)
      urlTab = `/SiteGroups/${selectedSitegroup.id}/dataBrowsingTabs`;
    else if (selectedSite)
      urlTab = `/Sites/${selectedSite.id}/dataBrowsingTabs`;
    else if (selectedCompany)
      urlTab = `/Companies/${selectedCompany.id}/dataBrowsingTabs`;
    else if (selectedDomain)
      urlTab = `/Domains/${selectedDomain.id}/dataBrowsingTabs`;

    if (!tab.id) {
      api
        .post(urlTab, {
          name: tab.name,
          chartType: tab.chartType,
          variableSelection: tab.variableSelection || [],
          dateSelection: tab.dateSelection || [],
          configuration: tab.configuration || {},
          domainId: tab.domainId || undefined,
          companyId: tab.companyId || undefined,
          siteId: tab.siteId || undefined,
          siteGroupId: tab.siteGroupId || undefined,
          assetId: tab.assetId || undefined,
          assetGroupId: tab.assetGroupId || undefined
        })
        .then(resp => {
          if (resp.status === 200) {
            const oldOfflineId = tab.offlineId;

            tab.id = resp.data.id;
            tab.offlineId = resp.data.id;
            dispatch(
              saveTabSuccess(
                oldOfflineId,
                tab,
                selectedAsset,
                selectedAssetgroup,
                selectedCompany,
                selectedDomain,
                selectedSite,
                selectedSitegroup
              )
            );

            /* se il tab selezionato era riferito alla vecchia notazione dell'offlineId aggiornalo al nuovo id */
            if (selectedTabId === oldOfflineId) {
              dispatch(setSelectedTab(tab.offlineId));
            }
          }
        })
        .catch(e => {
          dispatch(saveTabFailure(e.response.status));
          console.error(e);
        });
    } else {
      api
        .patch(`/DataBrowsingTabs/${tab.id}`, {
          id: tab.id,
          name: tab.name,
          chartType: tab.chartType,
          order: tab.order || 0,
          variableSelection: tab.variableSelection || [],
          dateSelection: tab.dateSelection || [],
          configuration: tab.configuration || {},
          domainId: tab.domainId || undefined,
          companyId: tab.companyId || undefined,
          siteId: tab.siteId || undefined,
          siteGroupId: tab.siteGroupId || undefined,
          assetId: tab.assetId || undefined,
          assetGroupId: tab.assetGroupId || undefined
        })
        .catch(e => {
          dispatch(saveTabFailure(e.response.status));
          console.error(e);
        });
    }
  } else if (tab.id) {
    /* esegui lofica di unpin */
    const deleteUrl = `/DataBrowsingTabs/${tab.id}`;
    const resp = await api.delete(deleteUrl);
    if (resp.status === 200) tab.id = undefined;
  }
};

/* ===== DELETE TAB ACTIONS ===== */

export const deleteTabSuccess = (
  offlineId,
  selectedAsset,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
) => ({
  type: DELETE_TAB_SUCCESS,
  offlineId,
  selectedAsset,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
});

export const deleteTabFailure = error => ({
  type: DELETE_TAB_FAILURE,
  payload: error
});

export const deleteTab = tab => async (dispatch, getState) => {
  const {
    selectedAsset,
    selectedCompany,
    selectedDomain,
    selectedSite,
    selectedSitegroup
  } = getState().navigation;
  const { tabs } = getState().databrowsing;

  /* esegui lofica di unpin */
  if (tab.id) {
    const deleteUrl = `/DataBrowsingTabs/${tab.id}`;
    const resp = await api.delete(deleteUrl);
    if (resp.status === 200) tab.id = undefined;
  }

  /* dispatch dell'azione di eliminazione */
  dispatch(
    deleteTabSuccess(
      tab.offlineId,
      selectedAsset,
      selectedCompany,
      selectedDomain,
      selectedSite,
      selectedSitegroup
    )
  );

  /* esegui logica di decremento order per tutti i tab con ordinamento superiore */
  const nextTabs = tabs.filter(f => f.order > tab.order);
  nextTabs.forEach(tab2order => {
    tab2order.order -= 1;
    dispatch(saveTab(tab2order, false));
  });

  return true;
};

/* ===== LAD TABS ACTIONS ===== */

export const loadTabsSuccess = (
  tabs,
  selectedAsset,
  selectedAssetgroup,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
) => ({
  type: LOAD_TABS_SUCCESS,
  tabs,
  selectedAsset,
  selectedAssetgroup,
  selectedCompany,
  selectedDomain,
  selectedSite,
  selectedSitegroup
});

export const loadTabsSuccessFailure = error => ({
  type: LOAD_TABS_FAILURE,
  payload: error
});

export const loadTabs = () => async (dispatch, getState) => {
  const {
    selectedAsset,
    selectedAssetgroup,
    selectedCompany,
    selectedDomain,
    selectedSite,
    selectedSitegroup
  } = getState().navigation;

  let execLoad = true;
  let filter = "";
  if (selectedAsset) filter = `{"assetId":"${selectedAsset.id}"}`;
  else if (selectedAssetgroup)
    filter = `{"assetGroupId":"${selectedAssetgroup.id}"}`;
  else if (selectedSitegroup)
    filter = `{"siteGroupId":"${selectedSitegroup.id}"}`;
  else if (selectedSite) filter = `{"siteId":"${selectedSite.id}"}`;
  else if (selectedCompany) filter = `{"companyId":"${selectedCompany.id}"}`;
  else if (selectedDomain) filter = `{"domainId":"${selectedDomain.id}"}`;
  else execLoad = false;

  if (execLoad) {
    api
      .get(
        `/DataBrowsingTabs?filter={"where":${filter ||
        "{}"}, "order":"order ASC"}`
      )
      .then(async data => {
        const tabs = data.data.map(async tab => {
          tab.offlineId = tab.id; // fai concidere l'id offline con quello generato dal backend, in questo metodo possono coesistere le due logiche di persistenza di tab
          tab.lock = true; // trattandosi un tab proveniente da BK inizializza il lock a true
          return tab;
        });

        Promise.all(tabs).then(resp => {
          /* la risposta potrebbe arrivare dopo una variazione dello stato, quindi effettua un nuovo controllo sull'integrità */
          const {
            selectedAsset,
            selectedAssetgroup,
            selectedCompany,
            selectedDomain,
            selectedSite,
            selectedSitegroup
          } = getState().navigation;

          const updatedResp = resp.map(tab => ({...tab, variableSelection: tab.variableSelection.map(op => (updateVar(getState, op)))}));

          if (
            JSON.stringify(selectedAsset) ==
            JSON.stringify(getState().navigation.selectedAsset) &&
            JSON.stringify(selectedAssetgroup) ==
            JSON.stringify(getState().navigation.selectedAssetgroup) &&
            JSON.stringify(selectedCompany) ==
            JSON.stringify(getState().navigation.selectedCompany) &&
            JSON.stringify(selectedDomain) ==
            JSON.stringify(getState().navigation.selectedDomain) &&
            JSON.stringify(selectedSite) ==
            JSON.stringify(getState().navigation.selectedSite) &&
            JSON.stringify(selectedSitegroup) ==
            JSON.stringify(getState().navigation.selectedSitegroup)
          ) {
            dispatch(
              loadTabsSuccess(
                updatedResp,
                selectedAsset,
                selectedAssetgroup,
                selectedCompany,
                selectedDomain,
                selectedSite,
                selectedSitegroup
              )
            );
          }
        });
      })
      .catch(error => dispatch(loadTabsSuccessFailure(error)));
  } else {
    loadTabsSuccessFailure(
      "Seleziona almeno un elemento della navigazione del dominio per visualizzare i tab"
    );
  }
};

const updateVar = (getState, op) => {
  try {
    const updatedOp = getState().domain.observableproperties.find(opFind => opFind.id.toString() === op.id);
    if(!updatedOp){
      return op;
    }
    return {...op,
      label: updatedOp.name, 
      valueType: updatedOp.valueType,
      physicalQuantity: updatedOp.physicalQuantity,
      aggregation: updatedOp.aggregation,
    };
  } catch (err) {
    console.warn(err);
  }
}


/* ==== GET physicalQuantity */

export const getPhisicalQuantity = async (id, unitsCatalog) => {
  try {
    const resp = await api.get(
      `/ObservedProperties/${id}/physicalQuantityDetails`
    );
    if (resp.status === 200) {
      const data = resp.data.units.map(naturalKey => {
        const finded = unitsCatalog.find(
          unitDet => unitDet.naturalKey === naturalKey
        );
        return finded ? finded.symbol : naturalKey;
      });

      /* ne caso in cui si tratti di s o kg aggiungere l'opzione di seleziona dinamica della UM */
      if (
        resp.data.defaultVisualizationUnit === "s" ||
        resp.data.defaultVisualizationUnit === "kg"
      ) {
        data.push("");
      }

      return data;
    }
  } catch (e) {
    console.error(e);
  }

  return [];
};

export const getConvertionFactor = naturalKey => async (dispatch, getState) => {
  const { catalogs } = getState();
  const unitDet = catalogs.units.find(x => x.naturalKey === naturalKey);
  return unitDet !== undefined && unitDet.exactoConversionFactor
    ? unitDet.exactoConversionFactor
    : 1;
};

export const getConvertionFactorFrmUnit = (catalogs, symbol) => {
  const unitDet = catalogs.units.find(x => x.symbol === symbol);
  return unitDet !== undefined && unitDet.exactoConversionFactor
    ? unitDet.exactoConversionFactor
    : 1;
};

/* il simbolo è la notazione da mostrare in presentation per la um misurata sul campo */
export const createVariable = (
  background,
  id,
  label,
  valueType,
  physicalQuantity,
  symbol,
  exactoConversionFactor,
  timezone,
  path,
  aggregation,
  rate,
  seriesType
) => ({
  id: id.toString(),
  label,
  valueType,
  physicalQuantity,
  symbol,
  exactoConversionFactor,
  timezone,
  path,
  aggregation,
  rate,
  seriesType,
  background,
  textcolor: getCorrectTextColor(background)
});
