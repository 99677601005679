import React from 'react';
import { DataTable, Spinner, Button } from 'app/common';
import { Card, CardHeader, CardFooter, ButtonGroup, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { connect } from 'react-redux';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButton = styled(Button)`
  && {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;


const DomainList = (props) => {

  const queryParameters = new URLSearchParams(window.location.search);
  const filter = queryParameters.get("filter");

  let { url } = props
  const { domains } = props;
  //rimuovo l'eventuale '/' a fine url 
  url = url.charAt(url.length -1) === '/' ? url.slice(0,url.length-1) : url;

  return (
    <Card>
      <CardHeader>
        <ButtonContainer>
          <Trans>Gestione domini</Trans>
        </ButtonContainer>
      </CardHeader>
      <CardFooter>
        {
          !domains ? <Spinner overlay={false} />
            :
            <DataTable
              keyField="id"
              edit
              search
              width="100%"
              filter={filter}
              buttons={
                <StyledButton size="sm" color="primary" tag={Link} to={`${url}/createdomain`}>
                  <Trans>Nuovo dominio</Trans>
                </StyledButton>}
              headers={[
                {
                  title: <Trans>Nome</Trans>,
                  property: 'name',
                  canSearch: true,
                  index: 1,
                  dataType: 'custom',
                  cell: (row, parent, filter) => <Link to={`${url}/updatedomain/${row.id}` + (filter ? ("?fromFilter=" + filter) : "")}>{row.name}</Link>,
                  addFilter: true
                }
              ]}
              data={domains}
              noData={<Trans>Nessun dominio presente</Trans>}
            />
        }
      </CardFooter>
    </Card >
  );
};

const mapStateToProps = (state) => {
    const { domains } = state.navigation;
    return {
      domains,
    };
  };

export default connect(mapStateToProps)(DomainList);
