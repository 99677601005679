import React from 'react';
import onClickOutside from 'react-onclickoutside';

import { getDisplayName } from '../utils';

/**
 * Higher-order component that encapsulates Token behaviors, allowing them to
 * be easily re-used.
 */
const tokenContainer = (Component) => {
  class WrappedComponent extends React.Component {
    render() {
      const {
        disableOnClickOutside,
        enableOnClickOutside,
        eventTypes,
        outsideClickIgnoreClass,
        preventDefault,
        stopPropagation,
        onCursorIndexChange,
        ...tokenProps
      } = this.props;

      return (
        <Component
          {...tokenProps}
          {...this.state}
          activeTokens={this.props.activeTokens}
          index={this.props.index}
          //onBlur={this._handleBlur}
          onClick={this._handleClick}
          onFocus={this._handleFocus}
        />
      );
    }

    _handleBlur = (e) => {
      // Commentato per permettere la selezione multipla di token
      //this.setState({active: false});
    }

    /**
     *} from 'onClickOutside' HOC.
     */
    handleClickOutside = (e) => {
      this._handleBlur();
    }

    _handleFocus = (e) => {
      e.stopPropagation();

      //this.setState({active: true});
    }

    _handleClick = (e) => {
      e.stopPropagation();
      //e.nativeEvent.stopImmediatePropagation()

      const { activeTokens, inputNode, cntrlispressed } = this.props;

      // Al primo click il token viene attivato, ricliccando viene disattivato
      if (cntrlispressed) {
        if (e.target.className.includes('rbt-token-active')) {
          let cursorIndex = 0;
          let previousElementSibling = e.target.previousElementSibling;
          while (previousElementSibling) {
            if (previousElementSibling.className.includes('rbt-input-hint-container')) {
              previousElementSibling = previousElementSibling.previousElementSibling;
              continue;
            }
            cursorIndex = cursorIndex+1;
            previousElementSibling = previousElementSibling.previousElementSibling;
          }
          this.props.onCursorIndexChange(cursorIndex);
          const newActiveTokens = activeTokens.filter(ele => ele !== cursorIndex);
          this.props.onActiveTokensChange(newActiveTokens);

        } else { // Tenendo premuto il tasto ctrl, il sistema permette di selezionare più token
          let cursorIndex = 0;
          let previousElementSibling = e.target.previousElementSibling;
          while (previousElementSibling) {
            if (previousElementSibling.className.includes('rbt-input-hint-container')) {
              previousElementSibling = previousElementSibling.previousElementSibling;
              continue;
            }
            cursorIndex = cursorIndex+1;
            previousElementSibling = previousElementSibling.previousElementSibling;
          }
          this.props.onCursorIndexChange(cursorIndex);

          activeTokens.push(cursorIndex);
          this.props.onActiveTokensChange(activeTokens);
        }
      } else {
        let cursorIndex = 0;
        let previousElementSibling = e.target.previousElementSibling;
        while (previousElementSibling) {
          if (previousElementSibling.className.includes('rbt-input-hint-container')) {
            previousElementSibling = previousElementSibling.previousElementSibling;
            continue;
          }
          cursorIndex = cursorIndex+1;
          previousElementSibling = previousElementSibling.previousElementSibling;
        }
        this.props.onCursorIndexChange(cursorIndex);
      }

      inputNode.focus();
    }
  }

  WrappedComponent.displayName = `TokenContainer(${getDisplayName(Component)})`;

  return onClickOutside(WrappedComponent);
};

export default tokenContainer;
