import React, { useState, useEffect } from 'react';
import api from 'api';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
`;

const ReportFooter = (props) => {
  const {
    user,
    widget
  } = props;

  const {
    showPageNumber,
    showPageNumberTotal,
    showImage,
    showCreator,
    image,
    content,
    documentId,
    note,
    documentPages
  } = widget;

  const maxWidthHeight = {
    width: '100%',
    height: '100%',
  };

  const [cont, setCont] = useState(content);

  useEffect(() => {
    if (documentId) {
      api.get(`documents/${documentId}/download`, { responseType: 'arraybuffer' }).then(res => {
        const { data, headers } = res;
        const arr = new Uint8Array(data);
        // Convert the int array to a binary string
        // We have to use apply() as we are converting an *array*
        // and String.fromCharCode() takes one or more single values, not
        // an array.
        const CHUNK_SZ = 0x8000;
        let raw = [];
        for (let i = 0; i < arr.length; i += CHUNK_SZ) {
          raw.push(String.fromCharCode.apply(null, arr.subarray(i, i + CHUNK_SZ)));
        }
        const b64 = btoa(raw.join(""));
        setCont(`data:${headers['content-type']};base64,${b64}`);
      }).catch(err => console.error(err));
    }
  }, [documentId])

  const docPage = (!isNaN(documentPages)) ? documentPages : 1;

  return (
    <div className="row m-0" style={maxWidthHeight}>
      <div className="col-8 pt-2" style={maxWidthHeight}>
        {showCreator && (
          <div><strong><Trans>Creato da:</Trans></strong> { user.name}</div>
        )}

        <div>
          {showPageNumber && (
            <div><strong><Trans>Pagina</Trans>:</strong> [#]{ (showPageNumberTotal) ? ' / ' + docPage : ''}</div>
          )}

          {showPageNumberTotal && !showPageNumber && (
            <div><strong><Trans>Totale pagine:</Trans></strong> {docPage}</div>
          )}

          {note && (
            <div>{note}</div>
          )}
        </div>
      </div>

      <div className="col-4" style={{ ...maxWidthHeight, textAlign: 'right', padding: 0 }}>
        {showImage && cont &&
          <Image src={cont} alt={image} />
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(ReportFooter);
