import React from 'react';

// set the defaults
const HolidaysContext = React.createContext({
  holidays: [],
  onEditHoliday: () => {},
  onAddHoliday: () => {},
});

export default HolidaysContext;
