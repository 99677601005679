import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { Trans } from '@lingui/macro';

import { DynamicForm, SelectTree } from 'app/common';
import { getPhysicalQuantityLabel } from '../../tools/virtualmeters/commonUtils';

const CreateOp = (props) => {
  const { selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies, sitegroups, sites,
    assets, physicalQuantities, physicaldimensions, units, onUpdate, newVariable } = props;

  const [selectedPlantOption, setSelectedPlantOption] = useState(newVariable && newVariable.selectedPlant ? newVariable.selectedPlant : null);

  const allowedPhysicalQuantities = physicalQuantities.filter(f => f.active)
    .map(element => ({
      label: getPhysicalQuantityLabel(element, units),
      naturalKey: element.naturalKey,
      id: `def-${element.id}`
    }));
  const allowedCustomQuantities = physicaldimensions ? physicaldimensions.map(element => ({
    label: getPhysicalQuantityLabel(element, units),
    naturalKey: `cus-${element.id}`,
    id: element.id,
    symbol: element.unitSymbol
  })) : [];

  const title = <Trans>Configurazione della nuova variabile</Trans>;
  const labelPhysicalQuantities = <Trans>Grandezze standard</Trans>;
  const labelCustomQuantities = <Trans>Grandezze personalizzate</Trans>;

  const timeZones = moment.tz.names().map(x => ({ value: x, label: x }));
  const dailyAggregations = [
    { value: 'standard', label: 'Standard (dalle 00:00 alle 00:00+1)' }
  ];

  const [initialValues, setInitialValues] = useState({
    dailyAggregation: 'standard',
    timeZone: selectedSite ? selectedSite.timeZone : moment.tz.guess(),
    ...newVariable
  });

  const handleChangePlant = item => {
    setSelectedPlantOption(item);
    if (item.type === 'Site') {
      setInitialValues({ ...initialValues, timeZone: item.timeZone })
    }
    onUpdate({ node: { type: item.type, id: item.id, siteId: item.type === 'Asset' || item.type === 'AssetGroup' ? item.siteId : undefined }, selectedPlant: item });
  }

  useEffect(() => {
    onUpdate({ ...newVariable, timeZone: selectedSite ? selectedSite.timeZone : moment.tz.guess() })
  }, [])

  const getFilteredPlants = (selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies, sitegroups, sites, assets) => {
    let filteredPlants = [];

    // Le company sono comunque filtrate per dominio
    const companyIds = selectedCompany ? [selectedCompany.id] : companies.filter(c => c.domainId === selectedDomain.id).map(x => x.id);
    let filteredCompanies = selectedCompany ? [selectedCompany] : companies.filter(c => c.domainId === selectedDomain.id);
    let filteredSites = [];
    if (selectedSitegroup) {
      filteredSites = sites.filter(site => companyIds.includes(site.companyId) && (site.siteGroups && site.siteGroups.findIndex(s => s.id === selectedSitegroup.id) !== -1));
    } else if (selectedSite) {
      filteredSites = [selectedSite];
    } else {
      filteredSites = sites.filter(site => companyIds.includes(site.companyId));
    }

    const getAssets = (site, assetgroup) => {
      let children = [];
      if (assetgroup.assetIds) {
        assetgroup.assetIds.forEach(assetId => {
          const asset = assets.find(a => a.siteId === site.id && a.id === assetId);
          children.push({ type: 'Asset', id: asset.id, name: asset.name, siteId: site.id, key: `Asset-${asset.id}` });
        });
      }
      return children;
    };

    const getAssetgroups = ((s) => {
      let children = [];
      if (s.assetGraph) {
        if (s.assetGraph.assetGroups) {
          children = children.concat(s.assetGraph.assetGroups.map(ag => ({ type: 'AssetGroup', id: ag.id, name: ag.name, siteId: s.id, key: `AssetGroup-${ag.id}` })));
        }
        if (s.assetGraph.assets) {
          children = children.concat(s.assetGraph.assets.map(a => ({ type: 'Asset', id: a.id, name: a.name, siteId: s.id, key: `Asset-${a.id}` })));
        }
      }
      return children;
    });

    if (selectedAsset) {
      const s = filteredSites.find(site => site.id === selectedAsset.siteId);
      filteredPlants.push({ type: 'Asset', id: selectedAsset.id, name: selectedAsset.name, siteId: s.id, key: `Asset-${selectedAsset.id}` });
      return filteredPlants;
    }
    if (selectedAssetgroup) {
      const s = filteredSites.find(site => site.id === selectedAssetgroup.siteId);
      const children = getAssets(s, selectedAssetgroup);
      const open = children.length > 0;
      filteredPlants.push({ type: 'AssetGroup', id: selectedAssetgroup.id, name: selectedAssetgroup.name, siteId: s.id, key: `AssetGroup-${selectedAssetgroup.id}`, open, children });
      return filteredPlants;
    }

    filteredSites = filteredSites.map((s) => {
      const children = selectedCompany && getAssetgroups(s);
      const open = children ? children.length > 0 : null;
      return selectedCompany ? { type: 'Site', id: s.id, name: s.name, siteId: s.id, companyId: s.companyId, key: `Site-${s.id}`, timeZone: s.timeZone, open, children }
        : { type: 'Site', id: s.id, name: s.name, siteId: s.id, companyId: s.companyId, key: `Site-${s.id}`, timeZone: s.timeZone };
    });
    filteredPlants = filteredSites;

    if (!selectedSite) {
      filteredCompanies = filteredCompanies.map(c => {
        let filteredSiteGroups = selectedSitegroup ? [selectedSitegroup].map(sg => {
          return { type: 'SiteGroup', id: sg.id, name: sg.name, siteId: sg.id, key: `SiteGroup-${sg.id}` }
        }) : [];
        if (!selectedSite && !selectedSitegroup) {
          filteredSiteGroups = sitegroups.filter(sitegroup => sitegroup.companyId === c.id).map(sg => {
            return { type: 'SiteGroup', id: sg.id, name: sg.name, siteId: sg.id, key: `SiteGroup-${sg.id}` }
          })
        }
        const children = [...filteredSites.filter(s => s.companyId === c.id), ...filteredSiteGroups];
        const open = children.length > 0;
        return { type: 'Company', id: c.id, name: c.name, companyId: c.id, key: `Company-${c.id}`, open, children };
      });
      filteredPlants = filteredCompanies;
      if (!selectedCompany) {
        const domainChildren = filteredCompanies;
        const domainOpen = domainChildren.length > 0;
        filteredPlants = [{
          type: 'Domain', id: selectedDomain.id, name: selectedDomain.name, companyId: selectedDomain.id, key: `Domain-${selectedDomain.id}`, open: domainOpen, children: domainChildren
        }];
      }
    }

    return filteredPlants;
  }

  const canEditTimeZone = () => {
    if (selectedSite) {
      return false;
    }
    return !(selectedPlantOption && selectedPlantOption.type === 'Site');
  }

  const fields = [
    {
      label: <Trans>Nome</Trans>,
      name: 'name',
      type: 'text',
      md: 12,
      onChange: val => onUpdate({ name: val }),
      validation: { required: true }
    },
    {
      label: <Trans>Impianto</Trans>,
      name: 'company',
      type: 'custom',
      md: 6,
      validation: { required: true },
      render: () => <SelectTree
        name="plantId"
        canSearch
        items={getFilteredPlants(selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies,
          sitegroups, sites, assets)}
        options={{ keyProperty: 'key', labelProperty: 'name' }}
        selectedOption={selectedPlantOption}
        compareFunction={item =>
          currentItem =>
            item.type === currentItem.type && item.id === currentItem.id && item.siteId === currentItem.siteId
        }
        onSelect={handleChangePlant}
        maxHeight='15rem'
      />
    },
    {
      label: <Trans>Grandezza</Trans>,
      name: 'physicalQuantity',
      type: 'select',
      valueProperty: 'naturalKey',
      onChange: val => onUpdate({ physicalQuantity: val }),
      md: 6,
      options: [
        { group: labelPhysicalQuantities, options: allowedPhysicalQuantities },
        { group: labelCustomQuantities, options: allowedCustomQuantities }
      ],
      validation: { required: true },
      canSearch: true,
      maxHeight: '15rem'
    },
    {
      label: <Trans>Aggregazione Giornaliera</Trans>,
      name: 'dailyAggregation',
      onChange: val => onUpdate({
        additionalAggregations: [
          {
            aggregator: val
          }
        ],
      }),
      type: 'select',
      md: 6,
      options: dailyAggregations,
      validation: { required: true },
      canEdit: false
    },
    {
      label: <Trans>Fuso Orario</Trans>,
      name: 'timeZone',
      type: 'select',
      onChange: val => onUpdate({ timeZone: val }),
      md: 6,
      options: timeZones,
      validation: { required: true },
      canEdit: canEditTimeZone()
    }
  ];

  return (
    <DynamicForm
      hideButtons
      title={title}
      card
      fields={fields}
      initialValues={initialValues}
      edit={true}
      error={'error'}
    />
  )
}

const mapStateToProps = (state) => {
  const { selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies,
    sites, sitegroups, assets } = state.navigation;
  const { physicalQuantities, units } = state.catalogs;
  const { physicaldimensions } = state.domain;
  return {
    selectedDomain, selectedCompany, selectedSitegroup, selectedSite, selectedAssetgroup, selectedAsset, companies,
    sites, sitegroups, assets, physicalQuantities, physicaldimensions, units
  };
};

export default connect(mapStateToProps)(CreateOp);