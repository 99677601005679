import React from 'react';
import Api from '../Helpers/Api';
import { Button, InputWithIcon } from 'app/common';
import styled from 'styled-components';
import cGraph from '../ConfigGraph';
import { ButtonGroup } from 'reactstrap';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import api from 'api';
import { getTranslatedString } from '../TranslateData';

const ItemWrapper = styled.div`
  padding: .5rem;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;


const Name = styled.span`
  margin-left: .8rem;
`;

const Icon = styled.img`
  height: 24px;
  color: #333;
`;

const Item = (props) => {
  const { item, onItemClick } = props;
  const { icon, name } = item;
  const url = `${cGraph.symbol_path}${icon}`;
  const translatedName = getTranslatedString('asset',name);
  return (
    <ItemWrapper onClick={() => { onItemClick(item); }} item={item}>
      <Icon alt="" src={url} />
      <Name>{translatedName}</Name>
    </ItemWrapper>
  );
};

const Wrapper = styled.div`
  max-width: 30rem;
  min-height: 20rem;
  min-width: 480px;
  padding: 0 1rem 1rem 1rem;
`;

const ResultsContainer = styled.div`
  overflow-y: auto;
  max-height: 20rem;
  margin-top: 1rem;

  &::-webkit-scrollbar {
    width: .4rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent; 
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.navbar.borderColor}; 
  }
`;

class AssetsList extends React.Component {
  state= {
    filterText: '',
    type: null,
  };

  componentDidMount() {
    this.Api = new Api();
    const { onAddNode, pushVectorElems } = this.props;
    this.onAddNode = onAddNode;
    this.pushVectorElems = pushVectorElems;
    this.getEnergyAssetsList();
  }

  setFilter = (filterText) => {
    this.setState({ filterText });
  }

  setType = (type) => {
    this.setState({ type });
  }

  setEnergyAssetList = (jsons) => {
    const energyAsset2 = {};
    jsons.forEach((json) => {
      if (!energyAsset2[json.assetTypology.code]) {
        energyAsset2[json.assetTypology.code] = {};
        energyAsset2[json.assetTypology.code].code = json.assetTypology.code;
        energyAsset2[json.assetTypology.code].description = json.assetTypology.description;
        energyAsset2[json.assetTypology.code].children = [];
      }
      energyAsset2[json.assetTypology.code].children.push(json);
    });

    const energyAsset = Object.values(energyAsset2);
    this.setState({ energyAsset });
  }

  getEnergyAssetsList = () => {
    let jsonVector = [];
    api.get('SupportedAssets').then((result) => {
      const jsonEas = result.data;
      api.get('Catalogs/Vectors').then((result) => {
        jsonVector = result.data;
        let cnt = 0;
        jsonEas.forEach((jsonEa) => {
          jsonEa.index = cnt;
          cnt += 1;
          const inVectors = jsonEa.inFlow.vectors ? jsonEa.inFlow.vectors : [];
          inVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
          const outVectors = jsonEa.outFlow.vectors ? jsonEa.outFlow.vectors : [];
          outVectors.forEach((vector) => {
            if (vector.vNK) {
              const jv = jsonVector.find(v => v.naturalKey === vector.vNK);
              vector.detail = jv;
            } else if (vector.vNKs) {
              const { vNKs } = vector;
              vector.details = [];
              vNKs.forEach((vNK) => {
                const jv = jsonVector.find(v => v.naturalKey === vNK);
                vector.details.push(jv);
              });
            }
          });
        });
        const { callbackAssetList } = this.props;
        callbackAssetList(jsonEas, jsonVector);
        this.setEnergyAssetList(jsonEas);
      });
    });
  }

  filterEnergyAsset = (energyAsset) => {
    const { isEmpty, matchAsset } = this.props;
    if (!isEmpty) {
      return this.filterTextEnergyAsset(energyAsset);
    }
    let energyAsset2 = [];
    if (matchAsset === null || matchAsset === undefined) {
      energyAsset.forEach((ea) => {
        if (ea.code === 'AA') {
          const { children } = ea;
          energyAsset2 = Object.values(children);
        }
      });
    } else {
      energyAsset.forEach((ea) => {
        const { children } = ea;
        children.forEach((ch) => {
          let add = false;
          const eaVectors = ch.inFlow.vectors;
          const matchVectors = matchAsset.inFlow;
          if (eaVectors) {
            eaVectors.forEach((eaVector) => {
              const { vectors } = matchVectors;
              for (let i = 0; i < vectors.length; i += 1) {
                if (vectors[i].vNK && vectors[i].vNK === eaVector.vNK) {
                  add = true;
                } else if (vectors[i].vNKs && vectors[i].vNKs.findIndex(vnk => vnk.vNK === eaVector.vNK) >= 0) {
                  add = true;
                }
              }
            });
          }
          if (add) {
            energyAsset2.push(ch);
          }
        });
      });
    }
    return this.filterTextEnergyAsset(energyAsset2);
  }

  orderEnergyAsset = (energyAsset) => {
    const energyAsset2 = [];
    let index = energyAsset.findIndex(ea => ea.code === 'AA');
    if (index > -1) {
      energyAsset2.push(energyAsset[index]);
    }
    index = energyAsset.findIndex(ea => ea.code === 'AT');
    if (index > -1) {
      energyAsset2.push(energyAsset[index]);
    }

    energyAsset.forEach((ea) => {
      index = energyAsset2.findIndex(e => e.code === ea.code);
      if (index === -1) {
        energyAsset2.push(ea);
      }
    });
    return energyAsset2;
  }

  filterTextEnergyAsset = (energyAsset) => {
    const { filterText, type } = this.state;
    const search = filterText ? filterText.toLowerCase().trim() : '';
    const energyAsset2 = [];
    energyAsset.forEach((ea) => {
      if (ea.assetTypology === undefined) {
        const { children } = ea;
        if (type === null || type === ea.code) {
          children.forEach((child) => {
            if (child.name.toLowerCase().indexOf(search) > -1) {
              energyAsset2.push(child);
            }
          });
        }
      } else if (ea.name.toLowerCase().indexOf(search) > -1) {
        energyAsset2.push(ea);
      }
    });
    return energyAsset2;
  }


  render() {
    let energyAssets = [];
    const { energyAsset } = this.state;
    const { isEmpty } = this.props;
    if (this.state != null && energyAsset !== undefined) {
      energyAssets = this.filterEnergyAsset(energyAsset);
    }

    const { type } = this.state;

    return (
      <Wrapper>
        {isEmpty ? null : (
          <ButtonGroup>
            <Button color="link" active={type === null} onClick={() => this.setType(null)}><Trans>Tutti</Trans></Button>
            <Button color="link" active={type === 'AA'} onClick={() => this.setType('AA')}><Trans>Approvvigionamento</Trans></Button>
            <Button color="link" active={type === 'AT'} onClick={() => this.setType('AT')}><Trans>Trasformazione</Trans></Button>
            <Button color="link" active={type === 'AC'} onClick={() => this.setType('AC')}><Trans>Consumo</Trans></Button>
          </ButtonGroup>
        )}
        <InputWithIcon autoFocus autoComplete="off" placeholder={i18n._(t`Cerca`)} onChange={e => this.setFilter(e.target.value.toLowerCase())} righticon="search" />

        <ResultsContainer>
          {energyAssets.map((item, i) => <Item item={item} onItemClick={this.onAddNode} key={i} />)}
        </ResultsContainer>
      </Wrapper>
    );
  }
}
export default AssetsList;
