import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'app/common';
import i18n from 'app/i18n';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import api from 'api/api';
import { ToastContainer, toast } from '../../../../../../../node_modules/react-toastify/index';


const DisableUser = (props) => {

    const { userData, userid, closeModal, onSuccess, onError, setUserData } = props;
    const thisUserData = userData && userData.filter(user => user.id === userid);
    const userName = thisUserData && thisUserData[0] && thisUserData[0].name;

    const [disabled, setDisabled] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        getUserStatus();
    }, []);

    const submit = async () => {

        let text = '';
        
        switch (!disabled){
            case true:
                text = i18n._(t`Utente disabilitato`);
                break;
            case false:
                text = i18n._(t`Utente riabilitato`);
                break;
            default:
                break;
        }

        const body = {
            'disabled': !disabled
        };

        const res = await api.patch('/UserInfos/' + userid + '/disabled', body, { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setButtonDisabled(true);
            setUserData(userData.map(user => user.id === userid ? {...user, disabled:!disabled} : user));
            onSuccess(userName + " - " + text)
        } else if (res.status >= 300 ){
            onError(userName + " - " + res.data.error.message);
        }
    }

    const getUserStatus = async () => {
        const res = await api.get('/UserInfos/' + userid + '/disabled', { validateStatus: () => true });

        if (res.status >= 200 && res.status < 300) {
            setDisabled(res.data.disabled);
        } else if (res.status >= 300) {
            toast.warn(res.data.error.message);
        }
    }

    const userStatus = () => {
        switch (disabled) {
            case true:
                return <Trans>disabilitato</Trans>;
            case false:
                return <Trans>abilitato</Trans>;
            default:
                return <Trans>indefinito</Trans>;
        }
    }

    const cancel = () => {
        closeModal();
    };

    if (!thisUserData || !userName)
        return null;

    return (<>
        <ToastContainer></ToastContainer>
        {
            disabled != null ? (
                <Modal size="xl" centered isOpen={true}>
                    <ModalHeader toggle={closeModal}>
                        <Trans>Disabilitazione/Riabilitazione dell'utente</Trans>&nbsp;{userName}
                    </ModalHeader>
                    <ModalBody>
                        <p><Trans>L'utente è al momento nello stato</Trans>&nbsp;<b>{userStatus()}</b></p>
                        {disabled ? (<Trans>Vuoi riabilitare l'utente?</Trans>) : (<Trans>Vuoi disabilitare l'utente?</Trans>)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={submit} disabled={buttonDisabled}><Trans>Conferma</Trans></Button>
                        <Button color="link" onClick={cancel}><Trans>Annulla</Trans></Button>
                    </ModalFooter>
                </Modal>
            ) : (
                <Modal size="xl" centered isOpen={true}>
                    <ModalHeader toggle={closeModal}>
                        <Trans>Disabilitazione/Riabilitazione dell'utente</Trans>&nbsp;{userName}
                    </ModalHeader>
                    <ModalBody>
                        <Trans>Caricamento dati in corso...</Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={cancel}><Trans>Annulla</Trans></Button>
                    </ModalFooter>
                </Modal>

                
            )
        }
    </>)
}

DisableUser.propTypes = {
    history: PropTypes.object.isRequired,
};

export default DisableUser;