import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: ${props => `1px solid ${props.color}`};
  background-color: #fcfcff;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
  border-radius: .5rem;
`;

const Title = styled.h5`
  margin-bottom: 1rem;
`;

const VectorBlock = (props) => {
  const { className, vector, children } = props;

  return (
    <Wrapper className={className} color={vector.type.color}>
      <Title>{vector._label}</Title>
      {children}
    </Wrapper>
  );
};

export default VectorBlock;
