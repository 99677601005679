import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import Popup from '../helpers/Popup';
import { DataTable, Switch, IconButton, Spinner } from 'app/common';
import { Row, Col } from 'reactstrap';
import { Trans } from '@lingui/macro';
import SelectedPlantsList from './SelectedPlantsList';
import logger from 'app/common/log';
import PhoneEdit from './PhoneEdit';

const Header = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.common.text.headerColor};
  font-weight: bold;
`;

const InfoContainer = styled.div`
  margin: -${props => props.theme.card.padding};
  margin-bottom: 1rem;
  margin-top: 0;
  padding: 1rem ${props => props.theme.card.padding};
  background-color: rgba(31, 113, 183, 0.05);
`;

const StarExplanation = styled.div`
  font-size: 0.8rem;
`;

const EventPreferences = (props = {}) => {
  const { selectedDomain, userPreferences, eventTypes, onSubmit, match, getPlantName } = props;
  const { url } = match;
  logger.debug('EventPreferences props ', props);
  const [error, setError] = useState([]);
  const [showPhoneEdit, setShowPhoneEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const { notifications: notificationPreferences } = userPreferences || {};

  const onPhoneChange = event => {
    const value = event.target.value;
    setPhoneNumber(value);
  };

  const submitNotifications = notificationPreferences => {
    setLoading(true);
    onSubmit(notificationPreferences).then(result => {
      setLoading(false);
      if (result && result.err) {
        const str = result.err.msg || JSON.stringify(result.err);
        toast.error(str);
      }
    });
  };

  const changeOptionHO = (channel, eventType) => ({ target }) => {
    const newNotificationPreferences = cloneDeep(notificationPreferences);
    const { checked } = target;
    logger.debug(
      'changeOption channel %s, eventType %s, target: %o, userPreferences %o, notificationPreferences %o, newNotificationPreferences %o',
      channel,
      eventType,
      userPreferences,
      notificationPreferences,
      newNotificationPreferences
    );
    if (newNotificationPreferences.channels && newNotificationPreferences.channels[eventType]) {
      newNotificationPreferences.channels[eventType][channel] = checked;
      submitNotifications(newNotificationPreferences);
    }
  };

  const validatePhone = phone => {
    if (phone === '' || phone == null) {
      return true;
    }
    const expression = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;

    return expression.test(String(phone).toLowerCase());
  };

  const getChannelPlantsList = (notificationPreferences, domainId, channelKey) => {
    logger.trace('getChannelPlantsList ', notificationPreferences, domainId, channelKey);
    const channelPreferences =
      notificationPreferences &&
      notificationPreferences.channels &&
      notificationPreferences.channels[channelKey];
    const channelDomainSelectedPlants =
      channelPreferences &&
      channelPreferences.allowedFrom &&
      channelPreferences.allowedFrom[domainId];
    return channelDomainSelectedPlants;
  };

  const generaData = (types, notificationPreferences) => {
    logger.debug('generaData ', types, notificationPreferences);
    if (!notificationPreferences) {
      return null;
    }
    let data = [];
    for (let i in types) {
      data.push({
        id: types[i].id || parseInt(i) + 1,
        tipo: types[i].description,
        plants: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SelectedPlantsList
              getPlantName={getPlantName}
              domainSelectedPlants={getChannelPlantsList(
                notificationPreferences,
                selectedDomain && selectedDomain.id,
                types[i].key
              )}
            />
            <Link to={`${url}/event/${types[i].key}`}>
              <IconButton icon="pencil-alt" />
            </Link>
          </div>
        ),
        email: (
          <Switch
            labelOn=" "
            labelOff=" "
            id={`email|${types[i].key}`}
            onChange={changeOptionHO('email', types[i].key)}
            checked={
              notificationPreferences &&
              notificationPreferences.channels[types[i].key] &&
              notificationPreferences.channels[types[i].key].email
            }
          />
        ),
        toast: (
          <Switch
            labelOn=" "
            labelOff=" "
            id={`toast|${types[i].key}`}
            onChange={changeOptionHO('toast', types[i].key)}
            checked={
              notificationPreferences &&
              notificationPreferences.channels[types[i].key] &&
              notificationPreferences.channels[types[i].key].toast
            }
          />
        ),
        telegram: (
          <span onClick={checkPhone}>
            <Switch
              labelOn=" "
              labelOff=" "
              id={`telegram|${types[i].key}`}
              onChange={changeOptionHO('telegram', types[i].key)}
              disabled={
                !notificationPreferences ||
                notificationPreferences.phone == null ||
                notificationPreferences.phone === ''
              }
              checked={
                notificationPreferences &&
                notificationPreferences.channels[types[i].key] &&
                notificationPreferences.channels[types[i].key].telegram
              }
            />
          </span>
        )
      });
    }
    return data;
  };

  const getHeader = () => {
    return [
      { title: <Trans>Tipo di eventi</Trans>, property: 'tipo', index: 1, dataType: 'string' },
      {
        title: <Trans>Impianti abilitati</Trans>,
        property: 'plants',
        index: 2,
        dataType: 'string'
      },
      { title: <Trans>Toast</Trans>, property: 'toast', index: 3, dataType: 'string' },
      { title: <Trans>Email</Trans>, property: 'email', index: 4, dataType: 'string' },
      { title: <Trans>Telegram</Trans>, property: 'telegram', index: 5, dataType: 'string' }
    ];
  };

  const checkPhone = () => {
    let phoneNumber = notificationPreferences ? notificationPreferences.phone : null;
    console.log('Check Phone', phoneNumber);
    if (!phoneNumber) {
      setShowPhoneEdit(true);
      setError({});
    }
  };

  const setPhone = () => {
    console.log('setPhone ');
    let phoneNumber = notificationPreferences ? notificationPreferences.phone : null;
    if (phoneNumber != null) {
      phoneNumber = phoneNumber.replace('+39 ', '');
    }
    setError({});
    setPhoneNumber(phoneNumber);
    setShowPhoneEdit(true);
  };

  const savePhone = async () => {
    if (!validatePhone(phoneNumber)) {
      setError({ phone: <Trans>Il numero di telefono inserito non è valido</Trans> });
      return;
    }
    const oldPhone = notificationPreferences.phone;
    if (phoneNumber == null || phoneNumber === '') {
      notificationPreferences.phone = null;
    } else {
      notificationPreferences.phone = `+39 ${phoneNumber}`;
    }
    const result = await onSubmit(notificationPreferences);
    const { err } = result || {};
    const { msg, codes } = err || {};
    logger.debug('phone msg ', msg, codes);
    if (codes && codes.preferences && codes.preferences[0] === 'custom.nonUniquePhone') {
      setError({
        phone: (
          <>
            <Trans>Il numero di telefono inserito non è valido.</Trans>&nbsp;
            <Trans>Ti preghiamo di contattare il Supporto EXACTO</Trans> (email:{' '}
            <a href="mailto:supportoexacto@elettrainvestimenti.it" target="_blank">
              supportoexacto@elettrainvestimenti.it
            </a>
            )
          </>
        )
      });
      notificationPreferences.phone = oldPhone;
      return;
    } else if (msg) {
      toast.error(msg);
    }

    setShowPhoneEdit(false);
  };

  return (
    <>
      {showPhoneEdit && (
        <Popup
          modalTitle={<Trans>Conferma ricezione notifiche Telegram</Trans>}
          modalContainer={
            <PhoneEdit error={error} onChange={onPhoneChange} phoneNumber={phoneNumber} />
          }
          onSave={savePhone}
          onSaveText={<Trans>Conferma</Trans>}
          undoColor="link"
          onUndo={setShowPhoneEdit.bind(null, false)}
        />
      )}
      <Spinner loading={loading} overlay />
      <div>
        <InfoContainer>
          <Row>
            <Col>
              <Header>Email</Header>
              <div>{notificationPreferences && notificationPreferences.email}</div>
            </Col>
            <Col>
              <Header>Numero di telefono (cellulare)</Header>
              <div>
                <span style={{ float: 'left' }}>
                  {notificationPreferences && notificationPreferences.phone ? (
                    notificationPreferences.phone
                  ) : (
                    <i>Numero non impostato</i>
                  )}
                </span>
                <IconButton style={{ float: 'left' }} onClick={setPhone} icon="pencil-alt" />
              </div>
            </Col>
          </Row>
        </InfoContainer>
        <h4
          style={{
            display: 'flex',
            fontWeight: 500,
            padding: '0.8rem 0rem'
          }}
        >
          Configurazione per tipo di evento
        </h4>
        <div style={{ margin: '1rem 0rem' }}>
          <Trans>
            Configura le notifiche che desideri ricevere per i vari tipi di allarme o evento,
            attivando gli avvisi sui canali desiderati. Puoi attivare le notifiche per tutti gli
            impianti, oppure solo per alcuni impianti di interesse.
          </Trans>
        </div>
        <br />
        <Row>
          <Col>
            <DataTable
              keyField="id"
              title=""
              width="100%"
              headers={getHeader()}
              data={generaData(eventTypes, notificationPreferences)}
              noData="No data"
            />
          </Col>
        </Row>
      </div>
      <StarExplanation>
        *&nbsp;
        <Trans>
          L'asterisco mostrato in corrispondenza di un impianto indica che l'abilitazione include
          anche i nodi di gerarchia inferiore appartenenti all'impianto stesso.
        </Trans>
      </StarExplanation>
    </>
  );
};

export default EventPreferences;
