import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Button } from 'app/common';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const StyledQuill = styled(ReactQuill)`
  & .ql-editor {
    min-height: 30vh;
  }
`;

const TextWidgetForm = props => {
  const { onClose, onSave } = props;
  const [widget, setWidget] = useState(props.widget);
  const title = widget ? widget.title : '';

  const submitWidget = () => {
    onSave(widget);
    onClose();
  };

  const handleChange = content => {
    setWidget(state => ({ ...state, content }));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['link', 'image']
    ]
  };

  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'align',
    'color',
    'background',
    'link',
    'image'
  ];

  return (
    <Card>
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <StyledQuill
          modules={modules}
          formats={formats}
          value={widget.content}
          onChange={handleChange}
        />
      </CardBody>
      <CardFooter>
        <Button color="primary" onClick={submitWidget}>
          <Trans>Salva</Trans>
        </Button>
        <Button color="link" onClick={onClose}>
          <Trans>Annulla</Trans>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default TextWidgetForm;
