import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DynamicForm, Wizard, WizardStep, DragAndDrop, Spinner } from 'app/common';
import i18n from 'app/i18n';
import { Trans, t } from '@lingui/macro';
import api from 'api';
import moment from 'moment';
import Selector from './Selector';
import {
    columnsList, granularityList, valuesList, dateFormatsList,
    scaleSuggestions, columnsTypeList, delimitersList, dataFormatsList
} from './Lists';
import './toast.css';
import { invalidateOPsCache } from 'ducks/actions/observableproperties';


const Loading = (props) => {
    const { history, physicalQuantities, observableproperties, physicaldimensions, units, reload, invalidateOPsCache } = props;
    let selectedNode = props.location && props.location.state ? observableproperties.find(op => op.id === props.location.state.checkedNode.id) : null;
    selectedNode = selectedNode ? {
        ...selectedNode,
        type: selectedNode.virtualMeterId !== null || selectedNode.formerVirtualMeterId !== null ? 'vm' : 'op',
        label: selectedNode.name
    } : null;
    const [checkedNode, setCheckeNode] = useState(selectedNode);
    const [fileFieldInfo, setFileFieldInfo] = useState({ invalid: false, error: i18n._(t`Campo obbligatorio`) });
    const [loading, setLoading] = useState(false);
    const [isCsv, setIsCsv] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [noTimeCol, setNoTimeCol] = useState(false);
    const [tabOption, setTabOption] = useState('old_variable');
    const [isEnelFile, setIsEnelFile] = useState(false);
    const [isActiveElectricEnergy, setIsActiveElectricEnergy] = useState(false);
    const [variableData, setVariableData] = useState({
        name: '',
        physicalQuantity: '',
        meterUnits: [],
        defaultUnit: {},
        timeZone: '',
        valueType: '',
        timeZones: moment.tz.names().map(x => ({ value: x, label: x })),
        granularityList: granularityList,
        file: null,
        otherMetadata: {
            importConfiguration: {
                importOptions: {},
                parseOptions: {}
            }
        }
    });
    const [newOpData, setNewOpData] = useState();

    const cancel = () => {
        history.goBack();
    };
    /**
       Drag Event Handlers
    **/
    const handleDragEnter = (event) => {
        event.preventDefault();
    }
    const handleDragOver = (event) => {
        event.preventDefault();
        if (!dragOver) {
            setDragOver(true);
        }
    }
    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    }

    /**
       Handle Manually (File Input) Added Files - Handle Drop event
    **/
    const handleAddFile = (event) => {
        event.preventDefault();
        setFileFieldInfo({ invalid: false, error: i18n._(t`Campo obbligatorio`) });
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        const extension = file.name.split('.').pop().toLowerCase();

        // Validate file if it is csv or xls
        if (extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
            setVariableData({
                ...variableData,
                file: null
            })
            setFileFieldInfo({ invalid: true, error: i18n._(t`Estensione del file errata`) });
        }

        setIsCsv(extension === 'csv');

        setVariableData({
            ...variableData,
            file: file,
            otherMetadata: {
                importConfiguration: {
                    ...variableData.otherMetadata.importConfiguration
                }
            }
        })
    };

    const handleCancelUpload = (event) => {
        event.preventDefault();
        setIsCsv(false);
        setVariableData({
            ...variableData,
            file: null
        })
    }

    const setOpData = async (data) => {
        let varData;
        if (data.newOp) {
            setNewOpData(data.newOp.physicalQuantity.substring(0, 4) === 'cus-' ?
                {
                    ...data.newOp,
                    physicalQuantity: 'custom',
                    customQuantityId: data.newOp.physicalQuantity.substring(4)
                }
                : data.newOp);
        }
        const tZone = data.variables && data.variables[0].timeZone ? data.variables[0].timeZone : moment.tz.guess();
        const dataPq = data.variables ? data.variables[0].physicalQuantity : data.newOp.physicalQuantity;
        setIsActiveElectricEnergy(dataPq === 'active-electric-energy');
        let pq;
        let mUnits;
        if (dataPq.substring(0, 4) === 'cus-') {
            pq = physicaldimensions.find(p => `cus-${p.id}` === dataPq);
            mUnits = [{ label: pq.unitSymbol, value: 'adim' }];
        } else if (dataPq === 'custom') {
            pq = physicaldimensions.find(p => p.id === data.variables[0].customQuantityId);
            mUnits = [{ label: pq.unitSymbol, value: 'adim' }];
        } else {
            pq = physicalQuantities.find(p => p.naturalKey === dataPq);
            mUnits = units.filter(u => pq.units.indexOf(u.naturalKey) > -1).map(un => { return { label: un.symbol, value: un.naturalKey } });
        }
        const defUnit = pq.defaultVisualizationUnit ? pq.defaultVisualizationUnit : mUnits[0].value;
        let granularityListClone = [...granularityList];
        let index;
        if (!data.variables) {
            // granularityList prende tutte gli elementi di default
        } else if (data.variables[0].entryRate) {
            index = granularityList.findIndex(g => g.value === data.variables[0].entryRate);
            granularityListClone = granularityList.filter((g, i) => i >= index);
        } else if (data.variables[0].fieldDeviceId) {
            // granularityList prende tutte gli elementi di default
        } else if (data.variables[0].virtualMeterId) {
            const vm = await api.get(`/virtualMeters/${data.variables[0].virtualMeterId}`)
            if (vm.data.rate) {
                index = granularityList.findIndex(g => g.value === vm.data.rate);
                granularityListClone = granularityList.filter((g, i) => i >= index);
            }
        }
        varData = {
            ...variableData,
            name: data.variables ? data.variables[0].name : data.newOp.name,
            physicalQuantity: pq.name,
            meterUnits: mUnits,
            defaultUnit: defUnit,
            granularityList: granularityListClone,
            timeZone: tZone,
            valueType: data.variables ? data.variables[0].valueType : 'instant',
            otherMetadata: {
                importConfiguration: {
                    ...variableData.otherMetadata.importConfiguration,
                    importOptions: {
                        ...variableData.otherMetadata.importConfiguration.importOptions,
                        opId: data.variables ? data.variables[0].id : null
                    }
                }
            }
        }
        setVariableData(varData);
    }

    const setData = data => {
        setVariableData({
            ...variableData,
            otherMetadata: {
                importConfiguration: {
                    ...variableData.otherMetadata.importConfiguration,
                    parseOptions: isEnelFile ? {
                        ...variableData.otherMetadata.importConfiguration.parseOptions,
                        parser: 'enel',
                        timeZone: data.timeZone
                    } : {
                        ...variableData.otherMetadata.importConfiguration.parseOptions,
                        delimiter: data.delimiter === 'tab' ? decodeURI('%09') : data.delimiter,
                        skipFirstRows: data.skipFirstRows,
                        skipLastRows: data.skipLastRows,
                        dayFirst: data.dateFormat.indexOf('%d') < data.dateFormat.indexOf('%m'),
                        yearFirst: data.dateFormat.indexOf('%Y') < data.dateFormat.indexOf('%d'),
                        dateColumn: data.dateColumn,
                        timeColumn: !noTimeCol ? data.timeColumn : null,
                        dateFormat: /*data.dateFormat*/ null,
                        timeZone: data.timeZone
                    },
                    importOptions: {
                        ...variableData.otherMetadata.importConfiguration.importOptions,
                        dataUnit: data.dataUnit,
                        dataScaleFactor: data.dataScaleFactor,
                        dataKind: data.dataKind,
                        granularity: isEnelFile ? 'raw' : data.granularity,
                        valueColumn: data.valueColumn || 0
                    }
                }
            }
        });
    };

    const fields = [
        {
            label: <Trans>Variabile</Trans>,
            name: 'variable',
            type: 'text',
            md: 6,
            validation: { required: true },
            canEdit: false,
        },
        {
            label: <Trans>Grandezza</Trans>,
            name: 'physicalQuantity',
            type: 'text',
            md: 6,
            validation: { required: true },
            canEdit: false
        },
        {
            label: <Trans>Formato dei dati</Trans>,
            name: 'dataFormat',
            type: 'select',
            md: 12,
            options: dataFormatsList,
            valueProperty: 'value',
            labelProperty: 'label',
            onChange: (value, values) => setDataFields(value, values),
            hidden: !isActiveElectricEnergy,
            validation: { required: !isActiveElectricEnergy }
        },
        {
            label: <Trans>U.M. dei valori</Trans>,
            name: 'dataUnit',
            type: 'select',
            md: 3,
            options: variableData.meterUnits,
            disabled: isEnelFile,
            validation: { required: true }
        },
        {
            label: <Trans>Fattore di scala</Trans>,
            name: 'dataScaleFactor',
            suggestions: scaleSuggestions,
            type: 'number',
            md: 3,
            disabled: isEnelFile,
            validation: { required: true, minStrictVal: 0 }
        },
        {
            label: <Trans>Tipo di valori</Trans>,
            name: 'dataKind',
            type: 'select',
            valueProperty: 'value',
            labelProperty: 'label',
            md: 6,
            options: valuesList,
            disabled: isEnelFile,
            validation: { required: true }
        },
        {
            label: <Trans>Granularità dei dati</Trans>,
            name: 'granularity',
            type: 'select',
            valueProperty: 'value',
            labelProperty: 'label',
            md: 6,
            options: variableData.granularityList.map(({ value, label, hidden }) => ({ value, label: i18n._(label), hidden })),
            disabled: isEnelFile,
            validation: { required: true }
        },
        {
            label: <Trans>Fuso orario</Trans>,
            name: 'timeZone',
            type: 'select',
            valueProperty: 'value',
            labelProperty: 'label',
            canSearch: true,
            md: 6,
            options: variableData.timeZones,
            disabled: isEnelFile,
            validation: { required: true }
        },
        {
            label: <Trans>File dati *</Trans>,
            name: 'file',
            type: 'custom',
            render: () => <DragAndDrop
                file={variableData.file}
                isCsv={isCsv}
                onDrop={handleAddFile}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onChange={handleAddFile}
                handleCancelUpload={handleCancelUpload}
                fieldInfo={fileFieldInfo}
            />,
        },
        {
            label: <Trans>N° di righe iniziali da trascurare nel file</Trans>,
            name: 'skipFirstRows',
            type: 'number',
            md: 6,
            validation: { required: true, minVal: 0, integer: true }
        },
        {
            label: <Trans>N° di righe finali da trascurare nel file</Trans>,
            name: 'skipLastRows',
            type: 'number',
            md: 6,
            validation: { required: !isEnelFile, minVal: 0, integer: true }
        },
        {
            label: <Trans>Incolonnamento data e orario</Trans>,
            name: 'columns',
            type: 'select',
            valueProperty: 'id',
            labelProperty: 'name',
            md: 6,
            onChange: (value, values) => setColumnsValue(value, values),
            options: (values) => {
                const arr = values.granularity !== '1dy' ? columnsTypeList.filter(t => t.id !== 'noTime') : columnsTypeList;
                return arr.map(({ id, name }) => ({ id, name: i18n._(name) }))
            },
            validation: { required: !isEnelFile }
        },
        {
            label: <Trans>Posizione data</Trans>,
            name: 'dateColumn',
            type: 'select',
            valueProperty: 'id',
            labelProperty: 'name',
            md: 6,
            onChange: (value, values) => setTimeColumn(value, values),
            options: columnsList.map(({ id, name }) => ({ id, name: i18n._(name) })),
            validation: { required: !isEnelFile, func: (value, values) => validateCol(value, values, 'date') }
        },
        {
            label: <Trans>Posizione orario</Trans>,
            name: 'timeColumn',
            type: 'select',
            valueProperty: 'id',
            labelProperty: 'name',
            md: 6,
            onChange: (value, values) => setDateColumn(value, values),
            options: columnsList.map(({ id, name }) => ({ id, name: i18n._(name) })),
            validation: { required: !noTimeCol && !isEnelFile, func: (value, values) => validateCol(value, values, 'time') },
            canEdit: !noTimeCol,
        },
        {
            label: <Trans>Posizione valori</Trans>,
            name: 'valueColumn',
            type: 'select',
            valueProperty: 'id',
            labelProperty: 'name',
            md: 6,
            options: columnsList.map(({ id, name }) => ({ id, name: i18n._(name) })),
            validation: { required: !isEnelFile, func: (value, values) => validateCol(value, values, 'value') }
        },
        {
            label: <Trans>Formato Date</Trans>,
            name: 'dateFormat',
            type: 'select',
            valueProperty: 'id',
            labelProperty: 'name',
            md: 6,
            options: dateFormatsList.map(({ id, name }) => ({ id, name: i18n._(name) })),
            validation: { required: !isEnelFile }
        },
        {
            label: <Trans>Carattere di separazione dei campi del CSV</Trans>,
            name: 'delimiter',
            type: 'select',
            hidden: !isCsv,
            valueProperty: 'value',
            labelProperty: 'label',
            md: 6,
            options: delimitersList.map(({ value, label }) => ({ value, label: i18n._(label) })),
            validation: { required: !isEnelFile }
        },
    ];

    const initialValues = {
        variable: variableData.name,
        physicalQuantity: variableData.physicalQuantity,
        dataUnit: variableData.defaultUnit,
        dataFormat: 'default',
        dataScaleFactor: 1,
        timeZone: variableData.timeZone,
        dataKind: variableData.valueType,
        dateFormat: '%d/%m/%Y',
        skipFirstRows: 0,
        skipLastRows: 0
    };

    const validateCol = (value, values, field) => {
        if (!isEnelFile) {
            if (field === 'value' && (value === values.dateColumn || value === values.timeColumn)) {
                return new Error(i18n._(t`Posizione valori non può coincidere con quella della data e dell'orario`));
            } else if (values.columns === 'separate') {
                switch (field) {
                    case 'date':
                        if (value === values.timeColumn || value === values.valueColumn) {
                            return new Error(i18n._(t`Posizione non valida`));
                        }
                        break;
                    case 'time':
                        if (value === values.dateColumn || value === values.valueColumn) {
                            return new Error(i18n._(t`Posizione non valida`));
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const setDataFields = (value, values) => {
        setIsEnelFile(value === 'enel');
        if (value === 'enel') {
            values.dataUnit = 'kWh';
            values.dataScaleFactor = 1;
            values.dataKind = 'delta';
            values.granularity = '15m';
            values.timeZone = values.timeZone || moment.tz.guess();
        } else {
            values.granularity = '';
        }
    }

    const setColumnsValue = (value, values) => {
        setNoTimeCol(value === 'noTime');
        values.dateColumn = '1';
        values.timeColumn = value === 'noTime' ? '' : value === 'separate' ? '2' : values.dateColumn;
        values.valueColumn = value === 'separate' ? '3' : '2';
    };

    const setTimeColumn = (value, values) => {
        values.timeColumn = values.columns === 'same' ? value : values.timeColumn;
    };
    const setDateColumn = (value, values) => {
        values.dateColumn = values.columns === 'same' ? value : values.dateColumn;
    };

    const load = () => {
        if (!variableData.file || fileFieldInfo.invalid) {
            return setFileFieldInfo(fileFieldInfo.invalid ? fileFieldInfo : { invalid: true, error: i18n._(t`Campo obbligatorio`) });
        }
        setLoading(true);
        const otherMetadata = {
            importConfiguration: {
                ...variableData.otherMetadata.importConfiguration,
            }
        };
        const formData = new FormData();
        isCsv ? formData.append('file', new Blob([variableData.file], { type: 'text/csv' }), variableData.file.name)
            : formData.append('file', variableData.file);
        formData.append('otherMetadata', JSON.stringify(otherMetadata));
        const name = variableData.file.name;
        let opId;
        if (newOpData) {
            postOp(newOpData).then(resp => {
                if (resp && resp.data && resp.data.id) {
                    opId = resp.data.id;
                    apiCall(formData, name, opId).then(res => {
                        setLoading(false);
                        reload(resp.data);
                        history.goBack();
                    }).catch(e => {
                        toast.error(i18n._(t`Non è stato possibile estrarre ed acquisire i date della variabile ${name}.`) + `\n ${e.message}`);
                        setLoading(false);
                    });
                } else {
                    toast.error(i18n._(t`Non è stato possibile salvare la nuova OP.`));
                    setLoading(false);
                }
            }).catch(err => {
                toast.error(i18n._(t`Non è stato possibile salvare la nuova OP.`));
                setLoading(false);
            })
        } else {
            apiCall(formData, name).then(res => {
                setLoading(false);
                reload();
                history.goBack();
            }).catch(e => {
                toast.error(i18n._(t`Non è stato possibile estrarre ed acquisire i date della variabile ${name}.`) + `\n ${e.message}`);
                setLoading(false);
            });
        }
    };

    const postOp = async (newOp) => {
        let node;
        let nodeId;
        let apiNodeId;
        switch (newOp.node.type) {
            case 'AssetGroup':
                node = 'Sites';
                nodeId = { assetGroupId: newOp.node.id };
                apiNodeId = newOp.node.siteId;
                break;
            case 'Asset':
                node = 'Sites';
                nodeId = { assetId: newOp.node.id };
                apiNodeId = newOp.node.siteId;
                break;
            case 'Site':
                node = 'Sites';
                nodeId = { siteId: newOp.node.id };
                apiNodeId = newOp.node.id;
                break;
            case 'SiteGroup':
                node = 'SiteGroups';
                nodeId = { siteGroupId: newOp.node.id };
                apiNodeId = newOp.node.id;
                break;
            case 'Company':
                node = 'Companies';
                nodeId = { companyId: newOp.node.id };
                apiNodeId = newOp.node.id;
                break;
            case 'Domain':
                node = 'Domains';
                nodeId = { domainId: newOp.node.id };
                apiNodeId = newOp.node.id;
                break;
            default:
                break;
        };
        const body = {
            ...nodeId,
            ...newOp,
            unit: variableData.defaultUnit,
            valueType: variableData.otherMetadata.importConfiguration.importOptions.dataKind,
            aggregationRule: getAggregationRule(variableData.otherMetadata.importConfiguration.importOptions.dataKind),
            otherDataSource: {
                name: newOp.name,
                class: 'user',
                shared: false
            }
        }
        delete body.node;
        const op = await api.post(`${node}/${apiNodeId}/observedProperties`, body);
        invalidateOPsCache();
        return op;
    }

    const getAggregationRule = (_type) => {
        if (_type === 'instant') {
            return 'average';
        }
        return 'sum'; // if type === 'cumulated' or 'sum'
    }

    const apiCall = async (formData, name, _opId = undefined) => {
        const opId = _opId ? _opId : variableData.otherMetadata.importConfiguration.importOptions.opId;
        const postFile = await api.post(`/ObservedProperties/${opId}/dataUploadFiles`, formData);
        const options = {
            className: `toast-class`,
            progressClassName: `toast-progress`
        }
        toast(i18n._(t`Il file ${name} è stato caricato correttamente.`), options);
        if (postFile) {
            await api.post(`/ObservedProperties/${opId}/dataUploadFiles/${postFile.data.id}/imports`);
            // toast.success(`I dati della variabile ${name} sono stati estratti dal file ed acquisiti in piattaforma.`);
        }
        return;
    }

    return (
        <div>
            {loading && <Spinner />}
            <Wizard
                card
                title={<Trans>Caricamento dati</Trans>}
                edit={false}
                readOnly={false}
                onSave={load}
                onCancel={cancel}
                error={'error'}
                buttonsDisabled={false}
                fileUpload={true}
                value={variableData.otherMetadata.importConfiguration.importOptions.opId}
            >
                <WizardStep
                    title={<Trans>Variabile</Trans>}
                    label={<Trans>Seleziona o definisci la variabile di interesse</Trans>}
                    onSubmit={data => setOpData(data)}
                >
                    <Selector
                        physicalQuantities={physicalQuantities}
                        checkedNode={checkedNode}
                        newVariable={newOpData}
                        tabOption={tabOption}
                        setTabOption={option => setTabOption(option)}
                        setCheckeNode={node => setCheckeNode(node)} />
                </WizardStep>
                <WizardStep
                    title={<Trans>Dati</Trans>}
                    label={<Trans>Configura e seleziona i dati da caricare in piattaforma</Trans>}
                    onSubmit={data => setData(data)}
                >
                    <DynamicForm
                        hideButtons
                        title={null}
                        card
                        fields={fields}
                        initialValues={initialValues}
                        edit={true}
                        sections={
                            isEnelFile ?
                                [
                                    { label: '', fields: ['variable', 'physicalQuantity'] },
                                    { label: <Trans>Caratteristiche dei dati da caricare</Trans>, fields: ['dataFormat', 'dataUnit', 'dataScaleFactor', 'dataKind', 'granularity', 'timeZone'] },
                                    { label: <Trans>Selezione e formato del file</Trans>, fields: ['file'] }
                                ]
                                :
                                [
                                    { label: '', fields: ['variable', 'physicalQuantity'] },
                                    { label: <Trans>Caratteristiche dei dati da caricare</Trans>, fields: ['dataFormat', 'dataUnit', 'dataScaleFactor', 'dataKind', 'granularity', 'timeZone'] },
                                    { label: <Trans>Selezione e formato del file</Trans>, fields: ['file'] },
                                    { label: '', fields: ['skipFirstRows', 'skipLastRows', 'columns', 'dateColumn', 'timeColumn', 'valueColumn', 'dateFormat', 'delimiter'] },
                                ]}
                        error={'error'}
                    />
                </WizardStep>
            </Wizard>
        </div>
    )
};
const mapStateToProps = (state) => {
    const { physicalQuantities, units } = state.catalogs;
    const { observableproperties, physicaldimensions } = state.domain;
    return { physicalQuantities, units, observableproperties, physicaldimensions };
};
const mapDispatchToProps = dispatch => ({
    invalidateOPsCache: () => dispatch(invalidateOPsCache())
  });

export default connect(mapStateToProps, mapDispatchToProps)(Loading);