import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';


const SpinnerIcon = styled(FontAwesomeIcon)`
  && {
    margin: .5rem;
    cursor: default;
    color: #666;
    width: ${props => props.size};
    height: ${props => props.size};
  }
`;

const Spinner = (props) => {
  return <SpinnerIcon {...props} icon="spinner" spin />;
};

export default Spinner;
